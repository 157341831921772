import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { handleError, ProductType, Toteparams, TotePurpose } from './useValidateTote';
import { Notifications } from '@jds/core';
import { urls } from '../../utils/urls';

export type DropTotes = { tote_code: string; quantity: number };
export interface ToteDropOptions {
    jio_code: string;
    tote_purpose: TotePurpose;
    totes: DropTotes[];
    product_type: ProductType;
    location_id: string;
    lot_id: number;
    tote_params: Toteparams | null;
}

const defaultErrorMsg = 'Failed to drop tote. Please try again';
export function useDropTote() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, ToteDropOptions>(
        async (options) => {
            const response = await axios.post(urls.DROP_TOTE(), {
                totes: options.totes,
                product_type: options.product_type,
                jio_code: options.jio_code,
                lot_id: options.lot_id,
                tote_purpose: options.tote_purpose,
                location_id: options.location_id,
                tote_params: options.tote_params,
            });
            return response.data;
        },
        {
            onSuccess() {
                Notifications.toast.success({
                    title: 'Success',
                    description: 'Tote dropped successfully',
                });
            },
            onError: (error) => handleError(error, defaultErrorMsg),
        }
    );
}

interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}
