import { cn } from '../../utils/tailwind';
import { useNavigate } from 'react-router-dom';
import { PageHeaderV2 } from '../../components/shared/PageHeaderV2';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { pageHeaderStyle } from '../../styles/common';
import { useCubiscanToteDetails } from '../../hooks/cubiscan/useCubiscanToteDetails';
import { useContext, useEffect } from 'react';
import { useFetchUserCubiscanState } from '../../hooks/cubiscan/useFetchUserCubiscanState';
import { CubiscanContext } from '../../provider/inbound/cubiscan/CubiscanProvider';
import { ToteInfo } from '../../provider/inbound/cubiscan/CubiscanReducer';

const manualInputProps = { name: 'cubiscan-tote', label: 'Cubiscan Tote', type: 'text' };
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ScanSourceTote() {
    const navigate = useNavigate();
    const cubiscanToteDetails = useCubiscanToteDetails();
    const { cubiscanStateDetails } = useFetchUserCubiscanState();
    const { dispatch } = useContext(CubiscanContext);
    const toteInfoState: ToteInfo = {
        tote_code: '',
        mapped_zone: '',
        mapped_product_type: '',
        tote_purpose: '',
        products: [],
    };

    useEffect(() => {
        if (cubiscanStateDetails === undefined) return;
        const defaultPathname = `${cubiscanStateDetails?.toteCode}/${cubiscanStateDetails?.machineId}/${cubiscanStateDetails?.lotId}/${cubiscanStateDetails?.jioCode}`;
        switch (cubiscanStateDetails?.cubiscanStatus) {
            case 'COMPLETED':
                navigate(defaultPathname + '/scan-jio-code');
                break;
            default:
                navigate('');
        }
    }, [cubiscanStateDetails, navigate]);

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Cubiscan"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <main className={cn('mx-6 mt-3 mb-20 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        purpose="CUBISCAN_MACHINE_IN"
                        type="TOTE_CODE"
                        onSuccess={(value) => {
                            cubiscanToteDetails.mutate(value, {
                                onSuccess: (res) => {
                                    dispatch({
                                        type: 'SET_TOTE_DETAILS',
                                        payload: res?.data ?? toteInfoState,
                                    });

                                    navigate(value);
                                },
                            });
                        }}
                    />
                </main>
            </div>
        </div>
    );
}
