import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../utils/urls';
import { LoginSchema } from '../../pages/LoginPage';
import { RFC } from '../../types/shared';
import { Notifications } from '@jds/core';

export function useLoginUser() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, LoginSchema>(
        async (data) => {
            const body = { ...data, userName: data.username };
            const response = await axios.post<SuccessResponse>(urls.AUTHENTICATE_USER(), body);
            return response.data;
        },
        {
            onSuccess(data) {
                saveDetailsToSessionStorage(data);
                Notifications.toast.success({
                    title: 'Login Successful!',
                    description: 'You have successfully logged in',
                });
            },
            onError(error) {
                const defaultMessage = 'Something went wrong';
                const message = error?.response?.data?.message ?? defaultMessage;
                Notifications.toast.error({
                    title: 'Login Failed!',
                    description: message,
                });
            },
        }
    );
}

function saveDetailsToSessionStorage(data: SuccessResponse) {
    sessionStorage.setItem('user-auth-token', data.jwtToken);
    sessionStorage.setItem('user-role', data.role);
    sessionStorage.setItem('username', data.userName);
    sessionStorage.setItem('rfc-code', data.rfc.rfcCode);
    sessionStorage.setItem('rfc-id', String(data.rfc.rfcId));
    sessionStorage.setItem('rfcId', String(data.rfc.rfcId));
    sessionStorage.setItem('rfc-name', String(data.rfc.rfcName));
}

interface SuccessResponse {
    jwtToken: string;
    role: 'ROLE_ADMIN' | 'ROLE_USER';
    userName: string;
    rfc: RFC;
}

interface ErrorResponse {
    timestamp: number;
    message: string;
    path: string;
    status: number;
    error: string;
}
