import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface SubmitStockTakeSchema {
    stock_take_id: string;
    location_id: string;
    storage_zone_id: string;
    operator: string;
    rfc_id: number;
    stock_take_products: StockTakeProductsSchema[];
}

export interface StockTakeProductsSchema {
    attributes_mis_match: number;
    damaged_actual: number;
    excess_jio_code: number;
    ideal_items: number;
    jio_code: string;
    lot_id: number;
    non_damaged_actual: number;
    sku: string;
    stock_take_id: number;
    wrong_item: number;
}

export function useSubmitStockTake() {
    return useMutation(async (data: SubmitStockTakeSchema) => {
        const rfcId = sessionStorage.getItem('rfcId');
        const body = {
            stock_take_id: data.stock_take_id,
            location_id: data.location_id,
            storage_zone_id: data.storage_zone_id,
            operator: data.operator,
            rfc_id: rfcId || 1,
            stock_take_products: data.stock_take_products,
        };
        const response = await axios.patch(urls.SUBMIT_STOCK_TAKE(data.stock_take_id), body);
        return response.data;
    });
}
