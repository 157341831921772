import { useState } from 'react';
import { cn } from '../../utils/tailwind';
import { Container, Divider, Icon, Text } from '@jds/core';
import { IcChevronDown, IcChevronUp } from '@jds/core-icons';

interface TextAccordionProps {
    title: string;
    description: string[];
}
export function TextAccordion({ title, description }: TextAccordionProps) {
    const [expanded, setExpanded] = useState(false);
    return (
        <div>
            <button
                aria-label={expanded ? 'Collapse' : 'Expand'}
                className={cn('w-full')}
                type="button"
                onClick={() => setExpanded(!expanded)}
            >
                <Container background="primary-20" rounded="small">
                    <div className={cn('flex items-center justify-between py-2.5 px-5')}>
                        <Text>{title}</Text>
                        <Icon ic={expanded ? <IcChevronUp /> : <IcChevronDown />} />
                    </div>
                </Container>
            </button>
            {expanded && (
                <div className={cn('mt-3')}>
                    <ul className={cn('ml-5 list-[square]')}>
                        {description.map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                    <div className={cn('-mx-6')}>
                        <Divider padding="m" />
                    </div>
                </div>
            )}
        </div>
    );
}
