import { createContext, Dispatch, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import { UnloadingActions, unloadingReducer, ReturnsUnloadingState } from './UnloadingReducer';

export const initialReturnsUnloadingState: ReturnsUnloadingState = {
    completedAirwayBills: [],
    completedProducts: [],
    appointmentType: null,
    products: [],
};

interface ReceivingContextType {
    state: ReturnsUnloadingState;
    dispatch: Dispatch<UnloadingActions>;
}

export const UnloadingContext = createContext<ReceivingContextType>({
    state: initialReturnsUnloadingState,
    dispatch: () => {},
});

export function UnloadingProvider() {
    const [state, dispatch] = useReducer(unloadingReducer, initialReturnsUnloadingState);

    return (
        <UnloadingContext.Provider value={{ state, dispatch }}>
            <Outlet />
        </UnloadingContext.Provider>
    );
}
