import { cn } from '../../utils/tailwind';
import { Container, Text } from '@jds/core';
import { format } from 'date-fns';
import { useState } from 'react';

interface ProductCardProps {
    jioCode?: string;
    EAN?: string;
    MRP?: number;
    MFD?: number;
    BBD?: number;
    colorSize?: string;
    title?: string;
    image?: string;
    quantity?: number;
}

export function ProductCardV2({
    jioCode = '',
    BBD,
    colorSize,
    EAN,
    MFD,
    MRP,
    title = '',
    image,
    quantity = 0,
}: ProductCardProps) {
    const [showMore, setShowMore] = useState(false);
    return (
        <Container
            background="primary-grey-20"
            className={cn('flex justify-between space-x-2.5')}
            pad="xs"
            rounded="large"
        >
            <div className={cn('flex')}>
                <img
                    alt="Product Image"
                    className={cn(
                        `${showMore ? 'h-[88px] w-[93px]' : 'h-[39px] w-[39px]'} object-cover`
                    )}
                    src={image ? image : 'https://via.placeholder.com/88'}
                />
                <div className={cn('flex flex-col items-start space-y-0.5 pl-5')}>
                    <Text
                        appearance="body-xxs-bold"
                        className={cn('line-clamp-2')}
                        color="primary-grey-80"
                        title={jioCode}
                    >
                        {`Jio Code ${jioCode}`}
                    </Text>
                    {showMore ? (
                        <>
                            <Text appearance="body-xxs" color="primary-grey-80">
                                Title- {title}
                            </Text>
                            <Text appearance="body-xxs" color="primary-grey-80">
                                EAN {EAN}
                            </Text>
                            <Text appearance="body-xxs" color="primary-grey-80">
                                MRP {MRP}
                            </Text>
                            <Text appearance="body-xxs" color="primary-grey-80">
                                MFD {MFD ? format(MFD, 'dd-MM-yyyy') : '_'}
                            </Text>
                            <Text appearance="body-xxs" color="primary-grey-80">
                                BBD {BBD ? format(BBD, 'dd-MM-yyyy') : '_'}
                            </Text>
                            <Text appearance="body-xxs" color="primary-grey-80">
                                Color & Size {colorSize}
                            </Text>
                            <Text
                                appearance="body-xxs"
                                as="span"
                                color="primary-50"
                                onClick={() => setShowMore(false)}
                            >
                                See less
                            </Text>
                        </>
                    ) : (
                        <Text appearance="body-xxs" color="primary-grey-80">
                            {`Title- ${title?.substring(0, 70)}..`}
                            <Text as="span" color="primary-50" onClick={() => setShowMore(true)}>
                                See more
                            </Text>
                        </Text>
                    )}
                </div>
            </div>
            {!!quantity && (
                <Text
                    appearance="body-xxs"
                    className={cn('absolute right-1 rounded-3xl border bg-white p-2')}
                >
                    {`X ${quantity}`}
                </Text>
            )}
        </Container>
    );
}
