import { useParams } from 'react-router-dom';
import { urls } from '../../utils/urls';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';
import { useContext } from 'react';
import { ReceivingContext } from '../../provider/inbound/receiving';
import { QuantityStatus } from '../../provider/inbound/receiving/ReceivingReducer';
import { useNavigate } from 'react-router-dom';

export interface DropListQuantityResponse {
    data: QuantityDetails[];
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface QuantityDetails {
    primary_case_id: string;
    secondary_case_id: string;
    jio_code: string;
    str_no: string;
    appointment_no: number;
    tote_drop_status: string;
    total_quantity: number;
    tote_drop_quantity: number;
    product_type: string;
    tote_list: ToteList[] | null;
}

export interface ToteList {
    toteCode: string;
    quantity: number;
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useFetchDropListQuantity() {
    const { jioCode, secondaryCaseId, primaryCaseId } = useParams();
    const packageId = Number(secondaryCaseId) === -1 ? primaryCaseId || '' : secondaryCaseId || '';
    const { data, ...rest } = useQuery<DropListQuantityResponse>({
        queryKey: ['dropListQuantity'],
        queryFn: async () => {
            const response = await axios.get(
                urls.GET_DROPLIST_QUANTITIES(primaryCaseId || '', packageId, jioCode || '')
            );
            localStorage.setItem('refetch', 'false');
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
    return { dropListQuantity: data?.data ?? [], ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
