import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { ResponseType } from '../../../types/fetch';
import { Notifications } from '@jds/core';

export function useVRNStatus() {
    return useMutation<ResponseType<Response>, AxiosError<ResponseType<never>>, string>({
        mutationFn: async (appointmentNumber) => {
            const response = await axios.get(urls.GET_VRN_STATUS(Number(appointmentNumber)));
            return response.data;
        },
        onError: (error) => {
            const defaultMessage = 'Something went wrong';
            const messages = error?.response?.data?.meta?.errors ?? [defaultMessage];
            messages.forEach((description) => {
                Notifications.toast.error({ title: 'Error', description });
            });
        },
    });
}

interface Response {
    appointmentId: number;
    rfcId: number;
    dockOperationStatus: 'Dock Assigned' | 'Dock Freed' | 'Pending';
    appointmentType: 'C-Returns' | 'Inbound';
    vrnInStatus: string;
}
