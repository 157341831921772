import { createContext, Dispatch, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import { ReceivingAction, receivingReducer, ReceivingState } from './ReceivingReducer';

export const initialReceivingState: ReceivingState = {
    completedJioCodes: [],
    completedPrimaryCases: [],
    storageZoneId: '',
    quantityStatus: {
        batchIssue: 0,
        damaged: 0,
        excess: 0,
        expired: 0,
        good: 0,
        lowFreshness: 0,
        short: 0,
        wrong: 0,
        failed: 0,
        cubiscan: 0,
    },
};

interface ReceivingContextType {
    state: ReceivingState;
    dispatch: Dispatch<ReceivingAction>;
}

export const ReceivingContext = createContext<ReceivingContextType>({
    state: initialReceivingState,
    dispatch: () => {},
});

export function ReceivingProvider() {
    const [state, dispatch] = useReducer(receivingReducer, initialReceivingState);

    return (
        <ReceivingContext.Provider value={{ state, dispatch }}>
            <Outlet />
        </ReceivingContext.Provider>
    );
}
