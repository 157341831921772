import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { urls } from '../../utils/urls';
import { ErrorResponse, handleError, SuccessResponse } from './useGetGtlTaskList';

export interface GenerateGtlPutListSchema {
    user_name: string;
    totes: string[];
}

const defaultErrorMsg = 'Something went wrong.';
export function GenerateGtlPutList() {
    const { locationId } = useParams();
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, GenerateGtlPutListSchema>(
        async (body: GenerateGtlPutListSchema) => {
            const response = await axios.post(urls.GENERATE_GTL_PUTLIST(locationId ?? ''), body);
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
