import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Container, Icon, Input, Notifications, Text, debounce } from '@jds/core';
import { IcKiranaStore } from '@jds/extended-icons';
import axios from 'axios';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { useVRNStatus } from '../../../hooks/returns/unloading/useVRNStatus';
import { useUnloadingVerification } from '../../../hooks/unloading/useUnloadingVerification';
import { pageHeaderStyle } from '../../../styles/common';
import { cn } from '../../../utils/tailwind';
import { urls } from '../../../utils/urls';

const appointmentSchema = yup.object({
    appointmentNumber: yup
        .number()
        .typeError('Appointment Number should be numeric')
        .required('Appointment Number is required'),
});

export type AppointmentSchema = yup.InferType<typeof appointmentSchema>;

function withForwardRef({ ref, ...fieldAttributes }: UseFormRegisterReturn) {
    return { ...fieldAttributes, forwardRef: ref as unknown as RefObject<HTMLElement> };
}

export function AppointmentForm() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<AppointmentSchema>({
        resolver: yupResolver(appointmentSchema),
    });
    const navigate = useNavigate();
    const { stationId } = useParams();
    const unloadingVerification = useUnloadingVerification();
    const vrnStatus = useVRNStatus();

    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(true);

    const getAutoSuggestions = (value: string) => {
        fetch(urls.GET_INBOUND_AUTO_SUGGESTIONS('APPOINTMENT_ID', value, 'INBOUND_UNLOADING'), {
            headers: {
                rfc_code: sessionStorage.getItem('rfc-code') ?? 'SKAB',
            },
        })
            .then((res) => res.json())
            .then((data: any) => {
                if (data?.data) {
                    const resultKeys = data.data.suggestions
                        .slice(0, 5)
                        .map((item: { id: string }) => ({
                            result: item.id,
                        }));
                    setSuggestions([...resultKeys]);
                }
            });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optimizedSearch = useCallback(debounce(getAutoSuggestions, 500), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeValue = (value: number) => {
        if (value) {
            optimizedSearch(value);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const validateAppointmentUnloading = (data: AppointmentSchema) => {
        unloadingVerification.mutate(data, {
            onSuccess(res: any) {
                const unloadingData = res.data;
                const isPending = unloadingData.unloading_verification_details.some(
                    (item: any) => item.status === 'Pending'
                );
                localStorage.setItem('sellerName', unloadingData.seller_name);
                localStorage.setItem('strNumber', unloadingData.str_number);
                localStorage.setItem('invoiceNumber', unloadingData.invoice_number);
                localStorage.setItem('isBoxed', unloadingData.is_boxed);
                localStorage.setItem(
                    'expectedSecondaryPackages',
                    JSON.stringify(unloadingData.unloading_verification_details)
                );
                if (isPending) {
                    navigate(`/unloading/${stationId}/${data.appointmentNumber}/scan-secondary`);
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: 'Unloading already completed',
                    });
                }
            },
        });
    };
    const onSubmit = (appointmentData: AppointmentSchema) => {
        vrnStatus.mutate(`${appointmentData.appointmentNumber}`, {
            onSuccess: ({ data }) => {
                if (data?.vrnInStatus === 'Pending') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: 'VRN IN is pending',
                    });
                } else if (data?.dockOperationStatus !== 'Dock Assigned') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: 'Dock not assigned',
                    });
                } else if (data?.appointmentType !== 'Inbound') {
                    Notifications.toast.error({
                        title: 'Invalid appointment type',
                        description: 'Please try with a valid appointment number',
                    });
                } else {
                    validateAppointmentUnloading(appointmentData);
                }
            },
        });
    };

    useEffect(() => {
        const subscription = watch(({ appointmentNumber }) => {
            onChangeValue(appointmentNumber || 0);
        });
        return () => subscription.unsubscribe();
    }, [onChangeValue, watch]);

    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Appointment Number"
                    onBackIconClick={() => navigate(-1)}
                />
                <div className={cn('max-w-screen-md px-9 py-5 md:mx-auto')}>
                    <form id="application-form" onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            aria-invalid={errors.appointmentNumber ? 'true' : 'false'}
                            aria-required="true"
                            autoComplete="appointmentNumber"
                            color="primary"
                            inputMode="numeric"
                            label="Enter Appointment Number"
                            state={errors.appointmentNumber ? 'error' : undefined}
                            stateConfig={{
                                errorText: errors.appointmentNumber?.message,
                            }}
                            {...withForwardRef(register('appointmentNumber'))}
                        />
                    </form>
                    {showSuggestions &&
                        suggestions?.map((suggestion: any) => (
                            <Container
                                key={suggestion.result}
                                className={cn(
                                    'my-2 flex border-b border-solid border-[#D1D1ED] p-4'
                                )}
                                onClick={() => {
                                    setShowSuggestions(false);
                                    onSubmit({ appointmentNumber: suggestion.result });
                                }}
                            >
                                <Text color="primary-30">{suggestion.result} </Text>
                            </Container>
                        ))}
                </div>
            </div>
            <Container
                className={cn(
                    'my-4 mx-6 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-4'
                )}
            >
                <Icon
                    className={cn('absolute left-4 bg-white')}
                    ic={<IcKiranaStore color="#000093" />}
                />
                <Text appearance="body-s"> Station ID </Text>
                <Text appearance="body-s-bold"> {stationId} </Text>
            </Container>
            <span className={cn('fixed inset-x-2 bottom-4')}>
                <Button fullWidth form="application-form" type="submit">
                    Submit
                </Button>
            </span>
        </>
    );
}
