import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../utils/urls';
import { useParams } from 'react-router-dom';
import { Notifications } from '@jds/core';
import { ResponseType } from '../../types/fetch';

export function useUpdateAsnStatus() {
    const { appointmentNumber, primaryCaseId, jioCode } = useParams();
    if (!primaryCaseId) throw new Error('primaryCaseId is required to print labels');
    return useMutation<unknown, AxiosError<ResponseType<unknown>>>({
        mutationFn: async () => {
            const data = {
                status: 'LABELS_PRINTING_DONE',
                appointmentId: appointmentNumber,
                primaryCase: primaryCaseId,
                jioCode: jioCode,
            };
            const response = await axios.patch(
                urls.UPDATE_ASN_STATUS(Number(appointmentNumber)),
                data
            );
            return response.data;
        },
        onError: (error) => {
            const defaultError = 'Something went wrong';
            const messages = error.response?.data?.meta?.errors ?? [defaultError];
            messages.forEach((description) => {
                Notifications.toast.error({ title: 'Error', description });
            });
        },
    });
}
