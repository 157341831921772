import { Notifications } from '@jds/core';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { ResponseType } from '../../types/fetch';
import { urls } from '../../utils/urls';
import { ProductInfo } from '../returns/unloading/useReturnProducts';

type ProductDetails = { jioCode: string; lotId: string };
export function useProductDetails() {
    return useMutation<ResponseType<ProductInfo>, AxiosError<ErrorResponse>, ProductDetails>(
        async (data: ProductDetails) => {
            const response = await axios.get(
                urls.PRODUCT_DETAILS(data.jioCode, Number(data.lotId))
            );
            return response.data;
        },
        {
            onError(error) {
                const defaultMsg = 'Something went wrong';
                const serverError = error.response?.data?.meta?.errors?.at(0);
                Notifications.toast.error({
                    title: 'Error',
                    description: serverError ?? defaultMsg,
                });
            },
        }
    );
}

interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}
