import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Notifications, Space } from '@jds/core';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { differenceInDays, format, isValid } from 'date-fns';
import { ProductDetailsHeader } from '../../../components/receiving/ProductDetailsHeader';
import { ControlledInput, ControlledDateInput } from '../../../components/shared/jds';
import { ProductImage } from '../../../components/receiving/ProductImage';
import { useSaveProductDetails } from '../../../hooks/receiving/useSaveProductDetails';
import { useProductDetails } from '../../../hooks/cubiscan/useProductDetails';

const productFormSchema = yup.object({
    ean: yup.string(),
    image: yup.string(),
    description: yup.string(),
    mrp: yup.number().required().min(0).typeError('MRP must be a number'),
    mfd: yup
        .string()
        .required()
        .test({
            test(dateString) {
                const date = new Date(dateString);
                // JDS input widget throws error if date is before 1970
                return isValid(date) && Number(format(date, 'yyyy')) >= 1970;
            },
            message: 'MFD must be a valid date',
        }),
    bestBefore: yup
        .string()
        .required()
        .test({
            test(dateString) {
                const date = new Date(dateString);
                // JDS input widget throws error if date is before 1970
                return isValid(date) && Number(format(date, 'yyyy')) >= 1970;
            },
            message: 'BBD must be a valid date',
        }),
    color: yup
        .string()
        .matches(/^[A-Za-z]+$/, { message: 'Enter valid color' }) // match only alphabets
        .required(),
    size: yup.string().required(),
    expectedQuantity: yup.number(),
});

function getDefaultValue(product?: any): ProductFormSchema | undefined {
    if (!product) return;
    return {
        ean: product.ean,
        image: product.image_urls?.at(0) ?? 'https://via.placeholder.com/125',
        description: product.description ?? '',
        mfd: product.manufacturedDate ? format(product.manufacturedDate, 'yyyy-MM-dd') : '',
        bestBefore: product.bestBefore ? format(product.bestBefore, 'yyyy-MM-dd') : '',
        mrp: product.mrp,
        color: product.colour ?? '',
        size: product.size ?? '',
        expectedQuantity: product.itemQuantity,
    };
}

export type ProductFormSchema = yup.InferType<typeof productFormSchema>;
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ProductDetails() {
    const navigate = useNavigate();
    const { dropZoneId, toteId, jioCode, lotId } = useParams();
    const saveProductDetails = useSaveProductDetails();
    const [isEditMode, setIsEditMode] = useState(false);
    const { data: currentPackage } = useProductDetails(jioCode ?? '', lotId ?? '');
    const { handleSubmit, control, watch, reset } = useForm<ProductFormSchema>({
        resolver: yupResolver(productFormSchema),
        defaultValues: getDefaultValue(currentPackage),
    });

    function onFormSubmit(formValues: ProductFormSchema) {
        saveProductDetails.mutate(formValues, {
            onSuccess() {
                Notifications.toast.success({
                    title: 'Product details saved',
                    description: 'Product details saved successfully',
                });
                setIsEditMode(false);
            },
        });
    }

    useEffect(() => {
        if (currentPackage) {
            reset(currentPackage);
        }
    }, [currentPackage, reset]);

    return (
        <form className={cn(layoutStyles, 'py-3')} onSubmit={handleSubmit(onFormSubmit)}>
            <PageHeader
                subTitle={jioCode}
                title="Receiving"
                onBackIconClick={() => {
                    navigate(-1);
                }}
            />
            <div className={cn('mx-3 mt-3 mb-20')}>
                <fieldset disabled={!isEditMode}>
                    <Space value="m" />
                    <Container as="div" background="primary-20" pad="base" rounded="large">
                        <ControlledInput
                            control={control}
                            disabled={isEditMode}
                            label="EAN"
                            name="ean"
                            placeholder="Enter EAN"
                            type="text"
                        />
                    </Container>
                </fieldset>
                <Space value="m" />
                <ProductImage image={watch('image')} />
                <fieldset disabled={!isEditMode}>
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        disabled={isEditMode}
                        label="Description"
                        name="description"
                        placeholder="Enter Description"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="MRP"
                        name="mrp"
                        placeholder="Enter MRP"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledDateInput
                        control={control}
                        label="MFD Date"
                        name="mfd"
                        placeholder="Enter MFD Date"
                    />
                    <Space value="m" />
                    <ControlledDateInput
                        control={control}
                        label="Best Before"
                        name="bestBefore"
                        placeholder="Enter BBD Date"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="Color"
                        name="color"
                        placeholder="Enter color of the product"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="Size"
                        name="size"
                        placeholder="Enter size of the product"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        disabled={isEditMode}
                        label="Expected quantity"
                        name="expectedQuantity"
                        placeholder="Enter quantity"
                        type="number"
                    />
                </fieldset>
            </div>
            {!isEditMode && (
                <div className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}>
                    <Button
                        fullWidth
                        size="large"
                        type="button"
                        onClick={() => {
                            navigate(
                                `../${dropZoneId}/${toteId}/${jioCode}/${lotId}/select-inventory`
                            );
                        }}
                    >
                        Continue
                    </Button>
                </div>
            )}
        </form>
    );
}
