import { Text } from '@jds/core';
import { cn } from '../utils/tailwind';

export function NotFoundPage() {
    return (
        <div className={cn('flex h-screen items-center justify-center')}>
            <Text>404 Not Found</Text>
        </div>
    );
}
