import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Notifications } from '@jds/core';
import { useContext } from 'react';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';
import { useConsolidationStateSave } from '../../../hooks/outbound/consolidation/useConsolidationStateSave';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'jio-code', label: 'Jio Code', type: 'text' };
export function MarkDamagedPage() {
    const navigate = useNavigate();
    const { stationId, toteCode } = useParams();
    const saveConsolidationToteDropactivity = useConsolidationStateSave();
    const { state } = useContext(ConsolidationContext);
    const totalQuantity = state.consolidationToteTotalQuantity;
    const quantityCompletedTillNow = state.consolidationToteScannedQuantity;
    const consolidateJioCodesList = state.consolidationJioCodesList;
    const currentDamagedToteCode = state.consolidationCurrentDamagedToteCode;

    const jioCodeKeys = state?.consolidationJioCodesList?.map((item) => ({
        result: item.jioCode,
    }));

    const validateJioCode = (jioCode: string) => {
        const val = consolidateJioCodesList.findIndex(
            (item: { jioCode: string; scannedQuantity: number; quantity: number }) => {
                return (
                    item.jioCode === jioCode &&
                    (item.scannedQuantity === undefined || item.scannedQuantity < item.quantity)
                );
            }
        );
        return val;
    };

    const handleJioCodeScan = (
        jioCode: string,
        shipmentId: string,
        orderId: string,
        lotId: number
    ) => {
        saveConsolidationToteDropactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_TOTE_CONSOLIDATION',
                status: 'IN_PROGRESS',
                shipmentId: shipmentId,
                orderId: orderId,
                jioCode: jioCode,
                toteId: toteCode || '',
                productReceivingSource: 'CONSOLIDATION_OUTBOUND_QC',
                productType: 'DAMAGED',
                quantity: 1,
                lotId: Number(lotId),
                locationId: stationId,
            },
            {
                onSuccess: () => {
                    const navigateUrl = `/outbound/consolidation/${stationId}/${toteCode}/${jioCode}/${shipmentId}/${lotId}`;
                    if (currentDamagedToteCode) navigate(navigateUrl + '/drop-damaged-item');
                    else navigate(navigateUrl + '/scan-new-damaged-tote');
                },
            }
        );
    };

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    showBackIcon={true}
                    title="Scan Damaged Jio Code"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <main className={cn('mx-3 mt-4 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        searchableList={jioCodeKeys}
                        onSuccess={(value) => {
                            const val = validateJioCode(value);
                            if (val !== -1 && quantityCompletedTillNow < totalQuantity) {
                                handleJioCodeScan(
                                    value,
                                    consolidateJioCodesList[val].shipmentId,
                                    consolidateJioCodesList[val].orderId,
                                    consolidateJioCodesList[val].lotId
                                );
                            } else {
                                Notifications.toast.error({
                                    title: 'Error',
                                    description: 'Jio Code Not Found',
                                });
                            }
                        }}
                    />
                </main>
            </div>
        </div>
    );
}
