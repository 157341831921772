import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Notifications } from '@jds/core';

const manualInputProps = { name: 'bin-code', label: 'Bin Code', type: 'text' };
export function StockTakeHomePage() {
    const navigate = useNavigate();
    const { stockTakeId, binlocation } = useParams();
    return (
        <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
            <PageHeader
                showBackIcon={true}
                title="Stock take"
                onBackIconClick={() => navigate(-1)}
            />
            <main className={cn('mx-3 mt-6')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount={true}
                    inputProps={manualInputProps}
                    onSuccess={(value) => {
                        if (value === binlocation) {
                            navigate(`/inventory/stock-take/${stockTakeId}/${value}/main`);
                        } else {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Invalid Bin location',
                            });
                        }
                    }}
                />
            </main>
        </div>
    );
}
