import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate } from 'react-router-dom';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Notifications } from '@jds/core';
import { useValidateSegregationTote } from '../../../hooks/inventory/stock-take-segregation/useValidateSegregationTote';

export function ScanTote() {
    const navigate = useNavigate();
    const validateTote = useValidateSegregationTote();

    const onScanningTote = (toteId: string) => {
        validateTote.mutate(toteId, {
            onSuccess(res) {
                const { data } = res;
                localStorage.setItem('sourceToteData', JSON.stringify(data));
                navigate(`./../${toteId}/scan-jiocode`);
            },
        });
    };

    return (
        <div className={cn('mt-3 max-w-screen-md md:mx-auto')}>
            <PageHeader
                showBackIcon
                title="Stock take- Drop flow"
                onBackIconClick={() => navigate(-1)}
            />
            <main className={cn('mx-6 mt-6')}>
                <ScanOrEnterManuallyV2
                    inputProps={{ label: 'Tote ID', name: 'tote-id' }}
                    onSuccess={(toteId) => {
                        if (toteId) onScanningTote(toteId);
                        else {
                            Notifications.toast.error({
                                title: 'Tote ID is required',
                                description: 'Please scan or enter a valid Tote ID',
                            });
                        }
                    }}
                />
            </main>
        </div>
    );
}
