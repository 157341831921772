import { initialConsolidationState } from './ConsolidationProvider';

export interface ConsolidationState {
    consolidationToteScannedQuantity: number;
    consolidationToteTotalQuantity: number;
    consolidationCurrentDamagedToteCode: string;
    consolidationCurrentUnknownItemsToteCode: string;
    consolidationDamagedJioCodes: {
        jioCode: string;
        lotId: number;
    }[];
    consolidationJioCodesList: {
        jioCode: string;
        quantity: number;
        scannedQuantity: number;
        lotId: number;
        orderId: string;
        shipmentId: string;
    }[];
    consolidationParkedCrateCodesList: {
        crateIdList: string[];
        shipmentId: string;
    }[];
    consolidatedOrdersDetails: {
        orderId: string;
        consolidationStatus: string;
        orderStatus: string;
        crateId: string;
        crateIdList: string[];
        ptwlocation: string;
    }[];

    quantityStatus: QuantityStatus;
}

export interface QuantityStatus {
    damaged: number;
    short: number;
}

export type ConsolidationAction =
    | { type: 'SET_CONSOLIDATION_SCANNED_TOTE_QUANTITY'; payload: number }
    | { type: 'RESET_CONSOLIDATION_SCANNED_TOTE_QUANTITY' }
    | { type: 'SET_CONSOLIDATION_TOTAL_TOTE_QUANTITY'; payload: number }
    | { type: 'RESET_CONSOLIDATION_TOTAL_TOTE_QUANTITY' }
    | { type: 'SET_CONSOLIDATION_DAMAGED_TOTE_CODE'; payload: string }
    | { type: 'RESET_CONSOLIDATION_DAMAGED_TOTE_CODE' }
    | { type: 'SET_CONSOLIDATION_UNKNOWN_ITEMS_TOTE_CODE'; payload: string }
    | { type: 'RESET_CONSOLIDATION_UNKNOWN_ITEMS_TOTE_CODE' }
    | {
          type: 'SET_CONSOLIDATION_JIOCODES_LIST';
          payload: {
              jioCode: string;
              quantity: number;
              scannedQuantity: number;
              lotId: number;
              orderId: string;
              shipmentId: string;
          }[];
      }
    | { type: 'RESET_CONSOLIDATION_JIOCODES_LIST' }
    | {
          type: 'SET_CONSOLIDATION_DAMAGED_JIOCODES';
          payload: {
              jioCode: string;
              lotId: number;
          }[];
      }
    | { type: 'RESET_CONSOLIDATION_DAMAGED_JIOCODES' }
    | {
          type: 'SET_CONSOLIDATION_PARKED_CRATECODES';
          payload: {
              crateIdList: string[];
              shipmentId: string;
          }[];
      }
    | { type: 'RESET_CONSOLIDATION_PARKED_CRATECODES' }
    | {
          type: 'SET_CONSOLIDATED_ORDERS_DETAILS';
          payload: {
              orderId: string;
              consolidationStatus: string;
              orderStatus: string;
              crateId: string;
              crateIdList: string[];
              ptwlocation: string;
          }[];
      }
    | { type: 'RESET_CONSOLIDATED_ORDERS_DETAILS' }
    | { type: 'SET_QUANTITY_STATUS'; payload: QuantityStatus }
    | { type: 'RESET_QUANTITY_STATUS' }
    | { type: 'RESET_ALL' };

export function consolidationReducer(
    state: ConsolidationState,
    action: ConsolidationAction
): ConsolidationState {
    switch (action.type) {
        case 'SET_CONSOLIDATION_SCANNED_TOTE_QUANTITY':
            return { ...state, consolidationToteScannedQuantity: action.payload };
        case 'RESET_CONSOLIDATION_SCANNED_TOTE_QUANTITY':
            return { ...state, consolidationToteScannedQuantity: 0 };
        case 'SET_CONSOLIDATION_TOTAL_TOTE_QUANTITY':
            return { ...state, consolidationToteTotalQuantity: action.payload };
        case 'RESET_CONSOLIDATION_TOTAL_TOTE_QUANTITY':
            return { ...state, consolidationToteTotalQuantity: 0 };
        case 'SET_CONSOLIDATION_DAMAGED_TOTE_CODE':
            return { ...state, consolidationCurrentDamagedToteCode: action.payload };
        case 'RESET_CONSOLIDATION_DAMAGED_TOTE_CODE':
            return { ...state, consolidationCurrentDamagedToteCode: '' };
        case 'SET_CONSOLIDATION_UNKNOWN_ITEMS_TOTE_CODE':
            return { ...state, consolidationCurrentUnknownItemsToteCode: action.payload };
        case 'RESET_CONSOLIDATION_UNKNOWN_ITEMS_TOTE_CODE':
            return { ...state, consolidationCurrentUnknownItemsToteCode: '' };
        case 'SET_CONSOLIDATION_JIOCODES_LIST':
            return { ...state, consolidationJioCodesList: action.payload };
        case 'RESET_CONSOLIDATION_JIOCODES_LIST':
            return { ...state, consolidationJioCodesList: [] };
        case 'SET_CONSOLIDATION_DAMAGED_JIOCODES':
            return { ...state, consolidationDamagedJioCodes: action.payload };
        case 'RESET_CONSOLIDATION_DAMAGED_JIOCODES':
            return { ...state, consolidationDamagedJioCodes: [] };
        case 'SET_CONSOLIDATION_PARKED_CRATECODES':
            return { ...state, consolidationParkedCrateCodesList: action.payload };
        case 'RESET_CONSOLIDATION_PARKED_CRATECODES':
            return { ...state, consolidationParkedCrateCodesList: [] };
        case 'SET_CONSOLIDATED_ORDERS_DETAILS':
            return { ...state, consolidatedOrdersDetails: action.payload };
        case 'RESET_CONSOLIDATED_ORDERS_DETAILS':
            return { ...state, consolidatedOrdersDetails: [] };
        case 'SET_QUANTITY_STATUS':
            return { ...state, quantityStatus: action.payload };
        case 'RESET_QUANTITY_STATUS':
            return { ...state, quantityStatus: initialConsolidationState.quantityStatus };
        case 'RESET_ALL':
            return initialConsolidationState;
        default:
            throw new Error('Unhandled action type');
    }
}
