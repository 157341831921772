import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../utils/urls';

export function useToteProductsList() {
    const putListId = Number(localStorage.getItem('PutListId') || 0);
    return useMutation(async (toteId: string) => {
        const response = await axios.get(urls.GET_TOTES_PRODUCTS_LIST(toteId, putListId));
        return response.data;
    });
}
