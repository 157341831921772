import { initialReceivingState } from './ReceivingProvider';
import { UnsalableReasons } from '../../../pages/returns/receiving';

export type CompletedQuantities = Array<'good' | 'liquidation' | 'scrap'>;
export interface ReceivingState {
    quantityStatus: QuantityStatus;
    assignedTotes: { id: string; quantity: number }[];
    completedQuantities: CompletedQuantities;
    completedJioCodes: string[];
    storageZoneId: string;
}

export interface QuantityStatus extends Record<UnsalableReasons, number> {
    goodQuantity: number;
    liquidationQuantity: number;
    scrapQuantity: number;
}

export type ReceivingAction =
    | { type: 'SET_QUANTITY_STATUS'; payload: QuantityStatus }
    | { type: 'RESET_QUANTITY_STATUS' }
    | { type: 'SET_ASSIGNED_TOTES'; payload: { id: string; quantity: number }[] }
    | { type: 'RESET_ASSIGNED_TOTE' }
    | { type: 'SET_COMPLETED_QUANTITIES'; payload: CompletedQuantities }
    | { type: 'RESET_COMPLETED_QUANTITIES' }
    | { type: 'SET_COMPLETED_JIO_CODES'; payload: string[] }
    | { type: 'RESET_COMPLETED_JIO_CODES' }
    | { type: 'SET_STORAGE_ZONE_ID'; payload: string }
    | { type: 'RESET_ALL' };

export function receivingReducer(state: ReceivingState, action: ReceivingAction): ReceivingState {
    switch (action.type) {
        case 'SET_QUANTITY_STATUS':
            return { ...state, quantityStatus: action.payload };
        case 'RESET_QUANTITY_STATUS':
            return { ...state, quantityStatus: initialReceivingState.quantityStatus };
        case 'SET_ASSIGNED_TOTES':
            return { ...state, assignedTotes: action.payload };
        case 'RESET_ASSIGNED_TOTE':
            return { ...state, assignedTotes: [] };
        case 'SET_COMPLETED_QUANTITIES':
            return { ...state, completedQuantities: action.payload };
        case 'RESET_COMPLETED_QUANTITIES':
            return { ...state, completedQuantities: [] };
        case 'SET_COMPLETED_JIO_CODES':
            return { ...state, completedJioCodes: action.payload };
        case 'RESET_COMPLETED_JIO_CODES':
            return { ...state, completedJioCodes: [] };
        case 'SET_STORAGE_ZONE_ID':
            return { ...state, storageZoneId: action.payload };
        case 'RESET_ALL':
            return initialReceivingState;
        default:
            throw new Error('Unhandled action type');
    }
}
