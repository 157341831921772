import { Button } from '@jds/core';
import { cn } from '../../utils/tailwind';

export interface PickListHelpItemProps {
    id?: number;
    label: string;
    onItemClick?: () => void;
}
export function PickListHelpItem({ label, onItemClick }: PickListHelpItemProps) {
    return (
        <div className={cn('my-4')}>
            <Button fullWidth kind="secondary" onClick={onItemClick}>
                {label}
            </Button>
        </div>
    );
}
