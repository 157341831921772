import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Icon, Input, InputDate, Space, Spinner, Text } from '@jds/core';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { IcKiranaStore } from '@jds/extended-icons';
import { useContext, useState } from 'react';
import { PackingContext } from '../../../provider/outbound/packing';
import { useProductDetails } from '../../../hooks/cubiscan/useProductDetails';
import { usePackingStateSave } from '../../../hooks/outbound/packing/usePackingStateSave';
import { ProductImage } from '../../../components/receiving/ProductImage';
import {
    UpdateScannedQuantitySchema,
    useUpdateScannedOrderQuantity,
} from '../../../hooks/outbound/packing/useUpdateScannedOrderQuantity';
import { useQueryClient } from '@tanstack/react-query';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function VerifyDetailsPage() {
    const navigate = useNavigate();
    const { stationCode, ContainerCode, jioCode, orderId, shipmentId, packingType } = useParams();
    const savePackingactivity = usePackingStateSave();
    const { state, dispatch } = useContext(PackingContext);
    const packingOrderList = state.packingOrderList;
    const isOrderQuantityCompleted = state.isOrderQuantityCompleted;
    const productData = packingOrderList.find((item) => item.jiocode === jioCode);
    const { data: productObj, isLoading } = useProductDetails(
        jioCode ?? '',
        String(productData?.lotId) ?? '0'
    );
    const [quantityCompletedTillNow, setQuantityCompletedTillNow] = useState(
        state.PackingOrderQuantityTillNow
    );

    const getTotalOrderedQuantity = (data: any[]) => {
        let orderedQty = 0;
        data?.map((item) => {
            const qty = item?.tobePackedQuantity ?? 0;
            orderedQty += qty;
        });
        return orderedQty;
    };

    const scannedOrderList = state.scannedOrderList;
    const packingOrderInfo = useQueryClient().getQueryData<any>(['packing-order-info']) || [];
    const multiPackingOrderInfo = packingOrderInfo?.[0];
    const totalQuantity =
        packingType === 'Multi'
            ? getTotalOrderedQuantity(multiPackingOrderInfo?.packingOrderItemDetails)
            : packingOrderInfo?.length;
    const validateJioCode = (jioCode: string) => {
        let val = -1;
        if (packingType === 'Multi') {
            val = packingOrderList.findIndex((item) => item.jiocode === jioCode);
        } else {
            val = packingOrderInfo.findIndex((data: { packingOrderItemDetails: any[] }) => {
                const found = data.packingOrderItemDetails.find((item: { jiocode: string }) => {
                    return item.jiocode === jioCode;
                });
                return found?.jiocode === jioCode;
            });
        }
        return val;
    };

    const handleScannedJioCode = (value: string) => {
        const val = validateJioCode(value);
        if (val >= 0 && quantityCompletedTillNow < totalQuantity) {
            if (packingType === 'Multi') {
                if (quantityCompletedTillNow + 1 === totalQuantity) {
                    dispatch({ type: 'SET_IS_ORDER_QUANTITY_COMPLETED', payload: true });
                }
                packingOrderList[val].scannedQuantity =
                    packingOrderList[val]?.scannedQuantity ??
                    packingOrderList[val]?.tobePackedQuantity -
                        packingOrderList[val]?.processedQuantity;
                if (
                    packingOrderList[val].scannedQuantity <
                    packingOrderList[val]?.tobePackedQuantity
                ) {
                    setQuantityCompletedTillNow(quantityCompletedTillNow + 1);
                    packingOrderList[val].scannedQuantity += 1;
                    dispatch({
                        type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
                        payload: quantityCompletedTillNow + 1,
                    });
                    dispatch({ type: 'SET_PACKING_ORDER_LIST', payload: packingOrderList });
                }
            } else if (packingType === 'Single') {
                if (quantityCompletedTillNow + 1 === totalQuantity) {
                    dispatch({ type: 'SET_IS_TOTE_QUANTITY_COMPLETED', payload: true });
                }
                packingOrderList[val].scannedQuantity =
                    packingOrderList[val]?.scannedQuantity ??
                    packingOrderList[val]?.tobePackedQuantity -
                        packingOrderList[val]?.processedQuantity;
                if (
                    packingOrderList[val].scannedQuantity <
                    packingOrderList[val]?.tobePackedQuantity
                ) {
                    setQuantityCompletedTillNow(quantityCompletedTillNow + 1);
                    packingOrderList[val].scannedQuantity += 1;
                    scannedOrderList.push(packingOrderList[val]?.orderId);
                    dispatch({
                        type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
                        payload: quantityCompletedTillNow + 1,
                    });
                    dispatch({ type: 'SET_PACKING_ORDER_LIST', payload: packingOrderList });
                    dispatch({ type: 'SET_SCANNED_ORDER_LIST', payload: scannedOrderList });
                }
            }
        }
    };

    function handleVerificationDetailsState(
        verificationState: 'VERIFY_JIOCODE_SUCCESS_PACKING' | 'VERIFY_JIOCODE_FAILURE_PACKING'
    ) {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: verificationState,
                status: 'COMPLETED',
                shipmentId: shipmentId,
                orderId: orderId,
                containerId: ContainerCode,
                containerType: packingType?.toUpperCase() === 'MULTI' ? 'crate' : 'tote',
                quantity: 1,
                jiocode: jioCode,
                shipmentType: packingType?.toUpperCase(),
                packingStation: stationCode,
                locationId: stationCode,
                lotId: productData?.lotId ?? 0,
            },
            {
                onSuccess: () => {
                    if (verificationState === 'VERIFY_JIOCODE_SUCCESS_PACKING') {
                        if (
                            (isOrderQuantityCompleted === true && packingType === 'Multi') ||
                            packingType === 'Single'
                        ) {
                            navigate(
                                `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/packaging-suggestion`
                            );
                        } else {
                            navigate(
                                `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/main`
                            );
                        }
                    } else {
                        navigate(
                            `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${productData?.lotId}/verification-failed`
                        );
                    }
                },
            }
        );
    }

    const updateScannedQuantity = useUpdateScannedOrderQuantity();
    const handleScannedQuantityUpdate = (
        data: UpdateScannedQuantitySchema,
        verificationState: 'VERIFY_JIOCODE_SUCCESS_PACKING' | 'VERIFY_JIOCODE_FAILURE_PACKING'
    ) => {
        updateScannedQuantity.mutate(data, {
            onSuccess() {
                handleVerificationDetailsState(verificationState);
            },
        });
    };

    return (
        <main className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Verification"
                    onBackIconClick={() => navigate(-1)}
                />
                <Container
                    className={cn(
                        'm-1 mx-5 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-3'
                    )}
                >
                    <Icon
                        className={cn('bg-radial-gradient absolute left-2')}
                        ic={<IcKiranaStore color="#000093" />}
                    />
                    <Text appearance="body-s"> EAN </Text>
                    <Text appearance="body-s-bold" className={cn('flex justify-center')}>
                        {productObj?.ean}
                    </Text>
                </Container>
                <Space value="m" />
            </div>
            {isLoading && <Spinner className={cn('flex justify-center')} size="small" />}
            {!isLoading && (
                <div className={cn('mx-5 mb-20')}>
                    <Space value="m" />
                    <ProductImage
                        image={productObj?.image_urls?.[0] ?? 'https://via.placeholder.com/88'}
                    />
                    <fieldset disabled={true}>
                        <Space value="m" />
                        <Input
                            label="Description"
                            name="description"
                            placeholder="Enter Description"
                            type="text"
                            value={productObj?.description}
                        />
                        <Space value="m" />
                        <Input
                            label="MRP"
                            name="mrp"
                            placeholder="Enter MRP"
                            type="text"
                            value={productObj?.mrp}
                        />
                        <Space value="m" />
                        <InputDate
                            label="MFD Date"
                            placeholder="Enter MFD Date"
                            value={productObj ? new Date(productObj.mfg) : undefined}
                        />
                        <Space value="m" />
                        <InputDate
                            label="Best Before"
                            placeholder="Enter BBD Date"
                            value={productObj ? new Date(productObj.expiry) : undefined}
                        />
                        <Space value="m" />
                        <Input
                            label="Color"
                            name="color"
                            placeholder="Enter color of the product"
                            type="text"
                            value={productObj?.color}
                        />
                        <Space value="m" />
                        <Input
                            label="Size"
                            name="size"
                            placeholder="Enter size of the product"
                            type="text"
                            value={productObj?.size}
                        />
                        <Space value="m" />
                        <Input
                            label="Expected Quantity"
                            name="expectedQuantity"
                            placeholder="Enter Expected Quantity of the product"
                            type="text"
                            value={productData?.tobePackedQuantity}
                        />
                        <Space value="m" />
                    </fieldset>
                </div>
            )}
            <span
                className={cn('fixed inset-x-0 bottom-0 mt-3 flex space-x-2 bg-white py-2.5 px-6')}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    kind="secondary"
                    onClick={() => {
                        handleScannedJioCode(jioCode || '');
                        handleVerificationDetailsState('VERIFY_JIOCODE_FAILURE_PACKING');
                    }}
                >
                    Fail
                </Button>
                <Button
                    onClick={() => {
                        handleScannedJioCode(jioCode || '');
                        handleScannedQuantityUpdate(
                            {
                                shipmentId: shipmentId || '',
                                jiocode: jioCode || '',
                                quantity: 1,
                            },
                            'VERIFY_JIOCODE_SUCCESS_PACKING'
                        );
                    }}
                >
                    Verify
                </Button>
            </span>
        </main>
    );
}
