import { useQuery } from '@tanstack/react-query';
import { useReturnOrders } from './useReturnOrders';
import { ReturnProduct } from './useFetchReturnOrders';
import axios from 'axios';
import { urls } from '../../../utils/urls';

type ProductBulkDetails = { jioCode: string; lotId: number }[];
async function fetchProductDetails(details: ProductBulkDetails) {
    const promises = details.map(({ jioCode, lotId }) => {
        return axios.get(urls.PRODUCT_DETAILS(jioCode, lotId));
    });
    const result = await Promise.all(promises);
    return result.map(({ data }) => data.data);
}

export interface ProductInfo {
    image_urls: string[] | null;
    brand: string;
    color: string;
    description: string;
    ean: string;
    expiry: number;
    jio_code: string;
    lot_id: number;
    mfg: number;
    mrp: number;
    product_title: string;
    seller_code: string;
    size: string;
    sku_code: string;
    length: number;
    breadth: number;
    height: number;
    weight: number | null;
}

export function useReturnProducts() {
    const { data: returnOrders } = useReturnOrders(false);
    const productDetailsPayload =
        returnOrders?.products?.map(({ jio_code, lot_id }) => ({
            jioCode: jio_code,
            lotId: lot_id,
        })) ?? [];

    const rfcId = sessionStorage.getItem('rfc-id');
    const keys = productDetailsPayload.map(({ jioCode }) => jioCode);
    return useQuery<ProductDetails[]>({
        queryKey: ['products', rfcId, keys],
        queryFn: async () => {
            const productInfo = await fetchProductDetails(productDetailsPayload);
            return combineProductDetail(productInfo, returnOrders?.products ?? []);
        },
        staleTime: Infinity,
        cacheTime: Infinity,
    });
}

export interface ProductDetails extends ProductInfo {
    expectedQuantity: number;
    receivedQuantity: number;
}

function combineProductDetail(productDetails: ProductInfo[], returnProducts: ReturnProduct[]) {
    return productDetails.map((product) => {
        const targetReturnProduct = returnProducts.find(
            ({ jio_code }) => jio_code === product.jio_code
        );
        const expectedQuantity = targetReturnProduct?.expected_quantity ?? 0;
        const receivedQuantity = targetReturnProduct?.received_quantity ?? 0;
        return {
            ...product,
            expectedQuantity,
            receivedQuantity,
        };
    });
}
