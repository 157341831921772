import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { Notifications } from '@jds/core';
import axios from 'axios';
import { urls } from '../../../utils/urls';
import { useCallback, useEffect } from 'react';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';

const manualInputProps = { name: 'station-id', label: 'Station ID', type: 'text' };
export function UnloadingHome() {
    const navigate = useNavigate();
    const validateInventory = useInventoryDetails();
    const onScanSuccess = (stationId: string) => {
        validateInventory.mutate(
            { inventoryCode: stationId, type: 'RECEIVING_STATION' },
            {
                onSuccess: () => navigate(`/unloading/${stationId}`),
            }
        );
    };
    const getUnloadingDetails = useCallback(async () => {
        try {
            const res = await axios.get(urls.UNLOADING_VERIFICATION_BY_USER_NAME());
            if (res?.data) {
                const { data } = res.data;
                const {
                    appointment_id,
                    excess_quantity,
                    is_boxed,
                    invoice_number,
                    seller_name,
                    station_id,
                    str_number,
                    unloading_flow_status,
                    unloading_verification_details,
                } = data;
                if (
                    unloading_flow_status !== null &&
                    unloading_flow_status !== 'UNLOADING_NOT_STARTED' &&
                    unloading_flow_status !== 'POD_UPLOADED'
                ) {
                    localStorage.setItem('sellerName', seller_name);
                    localStorage.setItem('invoiceNumber', invoice_number);
                    localStorage.setItem('strNumber', str_number);
                    localStorage.setItem('excessPackages', excess_quantity);
                    localStorage.setItem('isBoxed', is_boxed);
                    const unloadingPackages = unloading_verification_details?.map((item: any) => {
                        const label = is_boxed ? item.box_label : item.package_label;
                        const caseId = is_boxed ? item.box_id : item.package_id;
                        return {
                            case_id: caseId,
                            case_label: label,
                            unloading_status: item.status,
                            started_at: item.started_at,
                            station_id: station_id,
                        };
                    });
                    const scannedPackages = unloadingPackages?.filter(
                        (item: any) =>
                            !(
                                item.unloading_status === 'Missing' ||
                                item.unloading_status === 'Pending'
                            )
                    );
                    const shortPackages = unloadingPackages?.filter(
                        (item: any) => item.unloading_status == 'Missing'
                    );
                    localStorage.setItem(
                        'expectedSecondaryPackages',
                        JSON.stringify(unloading_verification_details)
                    );
                    localStorage.setItem('scannedPackages', JSON.stringify(scannedPackages));
                    localStorage.setItem('shortPackages', JSON.stringify(shortPackages));
                    if (unloading_flow_status === 'UNLOADING_IN_PROGRESS') {
                        navigate(`${station_id}/${appointment_id}/scan-secondary`);
                    } else if (unloading_flow_status === 'UNLOADING_COMPLETED') {
                        navigate(`${station_id}/${appointment_id}/mark-short-excess`);
                    } else if (unloading_flow_status === 'EXCESS_QUANTITY_CAPTURED') {
                        navigate(`${station_id}/${appointment_id}/generate-pod`);
                    } else if (unloading_flow_status === 'POD_GENERATED') {
                        navigate(`${station_id}/${appointment_id}/upload-pod`);
                    }
                }
            }
        } catch (error: any) {
            Notifications.toast.error({
                title: error?.response?.data?.meta?.errors[0] ?? 'Something went wrong',
            });
        }
    }, [navigate]);
    useEffect(() => {
        getUnloadingDetails();
    }, [getUnloadingDetails]);

    return (
        <div className={cn(pageHeaderStyle)}>
            <PageHeaderV2 title="Station ID" onBackIconClick={() => navigate(-1)} />
            <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount
                    autoSuggest={true}
                    inputProps={manualInputProps}
                    type="RECEIVING_STATION"
                    onSuccess={onScanSuccess}
                />
            </div>
        </div>
    );
}
