import { cn } from '../../../utils/tailwind';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Icon, ProgressBar, Spinner, Text } from '@jds/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { initialReceivingState, ReceivingContext } from '../../../provider/returns/receiving';
import {
    PrintBody,
    PrintProductInfo,
    usePrintLabels,
} from '../../../hooks/returns/receiving/usePrintLabels';
import { useReturnsReceiving } from '../../../hooks/returns/receiving/useReturnsReceiving';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { IncrementDecrementSectionV3 } from '../../../components/shared/IncrementDecrementSectionV3';
import { IncrementDecrementSectionV2 } from '../../../components/shared/IncrementDecrementSectionV2';
import { IcWarningColored } from '@jds/core-icons';
import { reasonsDescription } from './DescriptionDetailsPage';
import { DetailsAccordion } from '../../../components/returns/receiving/DetailsAccordion';
import { ProductDetailsModal } from '../../../components/receiving/ProductDetailsModal';
import { useProductDetails } from '../../../hooks/cubiscan/useProductDetails';
import { PrintLabelModals } from '../../../components/returns/receiving/PrintLabelModals';
import { useReturnOrders } from '../../../hooks/returns/unloading/useReturnOrders';

export interface FormValues {
    goodQuantity: number;
    unsealable: { reason: UnsalableReasons; quantity: number }[];
}

export const unsaleableReasons = [
    { value: 'DAMAGE_A_PLUS', label: 'Damage A+', editMode: false },
    { value: 'DAMAGE_A', label: 'Damage A', editMode: false },
    { value: 'DAMAGE_B', label: 'Damage B', editMode: false },
    { value: 'LOW_FRESHNESS', label: 'Low Freshness', editMode: false },
    { value: 'DAMAGE_C', label: 'Damage C', editMode: false },
    { value: 'IR_STAR', label: 'IR*', editMode: false },
    { value: 'FAKE_PRODUCT', label: 'Fake Product', editMode: false },
    { value: 'EXPIRED_PRODUCT', label: 'Expired Product', editMode: false },
];

export type UnsalableReasons = (typeof unsaleableReasons)[number]['value'];

export function VerifyQuantitiesPage() {
    const [navigate, { jioCode, stationId }] = [useNavigate(), useParams()];
    const { dispatch } = useContext(ReceivingContext);
    const { data: returnOrders, isLoading } = useReturnOrders(false);
    const [printLabels, returnsReceiving] = [usePrintLabels(), useReturnsReceiving()];
    const product = returnOrders?.products.find(({ jio_code }) => jio_code === jioCode);
    const [productModal, setProductModal] = useState(false);
    const { data: currentProductDetails } = useProductDetails(
        jioCode ?? '',
        String(product?.lot_id) ?? 0
    );
    const initialQuantityState = unsaleableReasons.map((reason) => ({ ...reason }));
    const returnsflowStatus = localStorage.getItem('returns_receiving_state');
    const [showSuccessModal, setSuccessModal] = useState(
        returnsflowStatus === 'QC_COMPLETED' ?? false
    );

    const [unsaleableQuantities, setUnsaleableQuantities] = useState(initialQuantityState);
    const intitalReceivingPayload: PrintBody = {
        jio_code: '',
        products: [{ quantity: 0, qc_reason_code: '', qc_status: 'SALEABLE' }],
        return_order_id: '',
    };
    const totalQuantities = product?.received_quantity ?? 0;
    const [receivingPayload, setReceivingPayload] = useState(intitalReceivingPayload);
    const [totalFormQuantity, setTotalFormQuantity] = useState(0);
    const { control, handleSubmit } = useForm<FormValues>({
        defaultValues: { goodQuantity: 0 },
    });
    const { fields, append, remove } = useFieldArray({ control, name: 'unsealable' });

    function onFormSubmit(formValues: FormValues) {
        const products: PrintProductInfo[] = formValues.unsealable.map(({ reason, quantity }) => ({
            quantity,
            qc_reason_code: reason,
            qc_status: 'UNSALEABLE',
        }));

        if (formValues.goodQuantity > 0) {
            products.push({
                quantity: formValues.goodQuantity,
                qc_reason_code: 'GOOD',
                qc_status: 'SALEABLE',
            });
        }

        const body = {
            jio_code: jioCode ?? '',
            products,
            station_id: stationId,
            return_order_id: returnOrders?.return_order_id ?? '',
        };

        setReceivingPayload(body);

        returnsReceiving.mutate(body, {
            onSuccess() {
                printLabels.mutate(body, {
                    onSuccess: () => onQuantityUpdateSuccess(formValues),
                    onError: () => onQuantityUpdateSuccess(formValues),
                });
            },
        });
    }

    function onQuantityUpdateSuccess(formValues: FormValues) {
        const unsealableQuantity = formValues.unsealable.reduce(
            (acc, { reason, quantity }) => {
                return { ...acc, [reason]: Number(quantity) };
            },
            { ...initialReceivingState.quantityStatus } as Record<UnsalableReasons, number>
        );

        const liquidation =
            unsealableQuantity['DAMAGE_A'] +
            unsealableQuantity['DAMAGE_A_PLUS'] +
            unsealableQuantity['DAMAGE_B'] +
            unsealableQuantity['LOW_FRESHNESS'];
        const scrap =
            unsealableQuantity['DAMAGE_C'] +
            unsealableQuantity['IR_STAR'] +
            unsealableQuantity['FAKE_PRODUCT'] +
            unsealableQuantity['EXPIRED_PRODUCT'];

        dispatch({
            type: 'SET_QUANTITY_STATUS',
            payload: {
                ...unsealableQuantity,
                goodQuantity: Number(formValues.goodQuantity),
                liquidationQuantity: liquidation,
                scrapQuantity: scrap,
            },
        });

        setSuccessModal(true);
    }

    function onAddButtonClick(reason: string, index: number) {
        unsaleableQuantities[index].editMode = true;
        setUnsaleableQuantities(unsaleableQuantities);
        append({ reason: reason, quantity: 0 });
    }

    const handleTotalFormQuantity = () => {
        let totalQuantity = control._formValues.goodQuantity
            ? Number(control._formValues.goodQuantity)
            : 0;
        if (control._formValues.unsealable) {
            for (const item of control._formValues.unsealable) {
                const quantity = item?.quantity ? Number(item.quantity) : 0;
                totalQuantity += quantity;
            }
        }
        setTotalFormQuantity(totalQuantity);
    };

    return (
        <>
            <form className={cn(layoutStyles, 'pb-3')} onSubmit={handleSubmit(onFormSubmit)}>
                <div className={cn(pageHeaderStyle)}>
                    <PageHeaderV2
                        isExtended
                        imageUrl={
                            currentProductDetails?.image_urls?.[0] ??
                            'https://i.ibb.co/whKwM3C/51-Zjm7a-DMa-L-SY450.jpg'
                        }
                        title="EANs"
                        onBackIconClick={() => {
                            navigate(-1);
                        }}
                        onImageClick={() => setProductModal(true)}
                    />
                    <div className={cn('mx-6 mt-3 space-y-2.5 pb-5')}>
                        <ProgressBar
                            label="Packages"
                            value={totalFormQuantity / totalQuantities}
                            valueLabel={`${totalFormQuantity} / ${totalQuantities}`}
                        />
                    </div>
                </div>
                <main className={cn('mx-4 mt-4 mb-20')}>
                    <div className={cn('items-center gap-y-6')}>
                        <div className={cn('my-3')}>
                            <IncrementDecrementSectionV2
                                borderColour="--color-primary-60"
                                defaultInputValue={control._formValues['goodQuantity']}
                                successCallBack={() => {}}
                                title="Good Quantity"
                                type="good"
                                onChangeCallBack={(val: any) => {
                                    control._formValues['goodQuantity'] = val;
                                    handleTotalFormQuantity();
                                }}
                                onEditCallBack={() => {}}
                            />
                        </div>
                        <div
                            className={cn(
                                'mx-3 mt-4 grid grid-cols-[180px_max-content] items-center justify-between gap-y-6'
                            )}
                        >
                            <label className={cn('flex')} htmlFor="failed-quantity">
                                <Icon
                                    aria-label="edit"
                                    color="primary"
                                    ic={<IcWarningColored />}
                                    size="m"
                                    onClick={() => {}}
                                />
                                <Text appearance="body-s" as="span" color="primary-grey-100">
                                    Unsaleable
                                </Text>
                            </label>
                            <Text appearance="body-xxs" color="primary-grey-80">
                                <Link to="description">
                                    <span className={cn('flex items-center space-x-1')}>
                                        <span className={cn('underline underline-offset-4')}>
                                            Description Details
                                        </span>
                                        <span>{'>'}</span>
                                    </span>
                                </Link>
                            </Text>
                        </div>
                        <Container
                            background="primary-background"
                            className={cn('mt-3  !rounded-xl  border-solid', {
                                [`border border-[var(--color-primary-60)]`]: true,
                            })}
                            pad="s"
                            rounded="medium"
                        >
                            <div>
                                {unsaleableQuantities.map(({ label, value, editMode }, index) => {
                                    const fieldIndex = fields.findIndex(
                                        ({ reason }) => value === reason
                                    );
                                    return editMode === false ? (
                                        <Container
                                            key={value}
                                            className={cn('rounded-xl bg-[#F5F5F5] px-5 pb-2')}
                                        >
                                            <Container
                                                key={value}
                                                className={cn(
                                                    'mt-4 mb-2 grid h-[27px] grid-cols-2 justify-between'
                                                )}
                                            >
                                                <Text
                                                    appearance="body-s-bold"
                                                    className={cn(
                                                        'flex items-center justify-start text-center'
                                                    )}
                                                >
                                                    {label}
                                                </Text>
                                                <Button
                                                    fullWidth
                                                    className={cn('!text-end')}
                                                    kind="tertiary"
                                                    type="button"
                                                    onClick={() => onAddButtonClick(value, index)}
                                                >
                                                    Add
                                                </Button>
                                            </Container>
                                            <DetailsAccordion
                                                key={label}
                                                description={reasonsDescription[index].description}
                                                title="See Description"
                                            />
                                        </Container>
                                    ) : (
                                        <Container key={value} className={cn('my-4')}>
                                            <IncrementDecrementSectionV3
                                                borderColour={undefined}
                                                defaultInputValue={control._formValues[value]}
                                                description={reasonsDescription[index].description}
                                                inEditMode={true}
                                                showDescription={true}
                                                showEditIcon={true}
                                                showRemoveIcon={true}
                                                successCallBack={() => {}}
                                                title={label}
                                                type="failed"
                                                onChangeCallBack={(val: any) => {
                                                    if (fieldIndex !== -1) {
                                                        control._formValues.unsealable[
                                                            fieldIndex
                                                        ].quantity = val;
                                                        handleTotalFormQuantity();
                                                    }
                                                }}
                                                onEditCallBack={() => {}}
                                                onRemoveCallBack={() => {
                                                    remove(fieldIndex);
                                                    unsaleableQuantities[index].editMode = false;
                                                    setUnsaleableQuantities(unsaleableQuantities);
                                                }}
                                            />
                                        </Container>
                                    );
                                })}
                            </div>
                        </Container>
                    </div>
                </main>
                <div
                    className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                    style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
                >
                    <Button
                        fullWidth
                        disabled={totalFormQuantity !== totalQuantities}
                        size="large"
                        type="submit"
                    >
                        Submit and Print Labels
                    </Button>
                </div>
            </form>
            {(isLoading || printLabels.isLoading || returnsReceiving.isLoading) && (
                <Container
                    className={cn(
                        'fixed inset-0 flex h-full  min-h-screen items-center justify-center bg-gray-200/50'
                    )}
                >
                    <Spinner />
                </Container>
            )}
            <PrintLabelModals
                printLabelValues={receivingPayload}
                setSuccessModal={setSuccessModal}
                showSuccessModal={showSuccessModal}
            />
            {productModal && (
                <ProductDetailsModal
                    BBD={currentProductDetails?.expiry || 0}
                    EAN={currentProductDetails?.ean || ''}
                    MFD={currentProductDetails?.mfg || 0}
                    MRP={currentProductDetails?.mrp || 0}
                    colorSize={
                        '- ' + currentProductDetails?.color + ' & ' + currentProductDetails?.size
                    }
                    image={currentProductDetails?.image_urls?.at(0)}
                    jioCode={currentProductDetails?.jio_code || ''}
                    title={currentProductDetails?.product_title || ''}
                    onSuccess={() => {
                        setProductModal(false);
                    }}
                />
            )}
        </>
    );
}
