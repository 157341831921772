import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../utils/urls';
import { useParams } from 'react-router-dom';
import { Notifications } from '@jds/core';
import { ResponseType } from '../../types/fetch';

export function useReceivingStationDrop() {
    const { toteId } = useParams();
    if (!toteId) throw new Error('Missing stationId or toteId');

    return useMutation<ResponseType<unknown>, AxiosError<ResponseType<never>>, string>({
        mutationFn: async (stationId) => {
            const response = await axios.get(urls.CUBISCAN_TO_RECEIVING(stationId, toteId));
            return response.data;
        },
        onSuccess: () => {
            Notifications.toast.success({
                title: 'Success',
                description: 'Successfully dropped tote at receiving station',
            });
        },
        onError: (error) => {
            const defaultErrorMsg = 'Something went wrong while dropping tote at receiving station';
            const messages = error.response?.data?.meta?.errors ?? [defaultErrorMsg];
            messages.forEach((message) => {
                Notifications.toast.error({
                    title: 'Error',
                    description: message,
                });
            });
        },
    });
}
