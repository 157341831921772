import { Route } from 'react-router-dom';
import {
    AssignCubiscanTotePage,
    AssignTotesPage,
    EnterAppointmentNumberPage,
    MarkShortPage,
    ProductDetailsPage,
    ReceivingHomePage,
    CompatibleSKUsPage,
    ScanCubiscanTotePage,
    ScanDropTotePage,
    ScanEANPage,
    ScanPrimaryCasePage,
    SearchFromProductListPage,
    SummaryPage,
    VerifyQuantitiesPage,
    ScanSecondaryCasePage,
    SuccessPage,
} from '../../../../pages/inbound/receiving';
import { DropListPage } from '../../../../pages/inbound/receiving/DropListPage';

export const ReceivingRoutes = [
    <Route key="1" element={<ReceivingHomePage />} path="" />,
    <Route key="2" element={<EnterAppointmentNumberPage />} path=":stationId" />,
    <Route key="3" path=":stationId/:appointmentNumber">
        <Route element={<ScanSecondaryCasePage />} path="" />
        <Route element={<ScanPrimaryCasePage />} path=":secondaryCaseId" />
        <Route element={<ScanEANPage />} path=":secondaryCaseId/:primaryCaseId" />
        <Route path=":secondaryCaseId/:primaryCaseId">
            <Route element={<SearchFromProductListPage />} path="product-list" />
            <Route path=":jioCode">
                <Route element={<ProductDetailsPage />} path="" />
                <Route element={<VerifyQuantitiesPage />} path="verify-quantities" />
                <Route element={<SummaryPage />} path="verify-quantities/summary" />
                <Route element={<DropListPage />} path="drop-list" />
                <Route path="assign-tote">
                    <Route path="cubiscan-quantity/:quantity">
                        <Route element={<ScanCubiscanTotePage />} path="scan" />
                        <Route element={<AssignCubiscanTotePage />} path="assign" />
                    </Route>
                    <Route path=":quantityType/:reason/:quantity">
                        <Route element={<ScanDropTotePage />} path="scan" />
                        <Route element={<AssignTotesPage />} path="assign" />
                    </Route>
                </Route>
            </Route>
            <Route element={<CompatibleSKUsPage />} path="compatible-skus" />
        </Route>
        <Route element={<SuccessPage />} path="success" />
    </Route>,
];
