import { cn } from '../../../utils/tailwind';
import { Container, Divider, Icon, Text } from '@jds/core';
import { IcPassbook } from '@jds/extended-icons';
import { useState } from 'react';
import { IcChevronDown, IcChevronUp } from '@jds/core-icons';

export function AirwayBillCard({ item, key }: any) {
    const [showRemainingItems, setShowRemainingItems] = useState(false);
    return (
        <Container
            key={key}
            className={cn('rounded-lg border-[1.4px] border-[var(--color-primary-60)] p-4')}
        >
            <Icon
                className={cn('absolute left-3 mt-2 bg-white')}
                ic={<IcPassbook color="#000093" />}
            />
            <Container className={cn('grid grid-cols-2 gap-x-3 space-x-3')}>
                <Text appearance="body-xs" className={cn('ml-3')} color="primary-grey-80">
                    Airway bill number
                </Text>
                <Text appearance="body-xs" color="primary-grey-80">
                    Quantity
                </Text>
                <Text appearance="body-s-bold" className={cn('break-words')}>
                    {item.awb_no}
                </Text>
                <div className={cn('flex justify-around')}>
                    <Text appearance="body-s-bold">{item?.products?.length}</Text>
                    <Icon
                        ic={showRemainingItems ? <IcChevronUp /> : <IcChevronDown />}
                        onClick={() => setShowRemainingItems(!showRemainingItems)}
                    />
                </div>
            </Container>
            {showRemainingItems && (
                <>
                    <Divider className={cn('my-4')} />
                    <Container as="div" className={cn('ml-2 grid grid-cols-2 gap-y-3 break-words')}>
                        <Text appearance="body-s" color="secondary-grey-100">
                            JioCode
                        </Text>
                        <Text appearance="body-s" className={cn('ml-6')} color="secondary-grey-100">
                            Quantity
                        </Text>
                        {item.products.map((product: any) => (
                            <>
                                <Text appearance="body-s" color="primary-grey-100">
                                    {product.jio_code}
                                </Text>
                                <Text
                                    appearance="body-s"
                                    className={cn('ml-9')}
                                    color="primary-grey-100"
                                >
                                    {product.quantity}
                                </Text>
                            </>
                        ))}
                    </Container>
                </>
            )}
        </Container>
    );
}
