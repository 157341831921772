import { Notifications } from '@jds/core';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { StockTakelistSchema } from '../../../pages/inventory/stocktake/StockTakeListPage';
import { urls } from '../../../utils/urls';

export interface StockTakelistResponse {
    data: StockTakelistSchema[];
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure' | 'FAILURE';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useGetStockTakeList() {
    const { data, ...rest } = useQuery<StockTakelistResponse>({
        queryKey: ['stockTakeList'],
        queryFn: async () => {
            const response = await axios.get(urls.GET_STOCK_TAKE_LIST());
            return response.data;
        },
        retry: false,
        cacheTime: Infinity,
        staleTime: Infinity,
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });

    return { stocktakeList: filterStockTakeProducts(data?.data) ?? [], ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}

const filterStockTakeProducts = (data: StockTakelistSchema[] | undefined) => {
    if (!data) return [];
    const filteredProducts = data.filter((product: any) => product.status === 'Created');
    return filteredProducts;
};
