import { Route } from 'react-router-dom';
import AddNewGtlTote from '../../../pages/gtl/AddNewGtlTote';
import GtlHelp from '../../../pages/gtl/GtlHelp';
import GtlHomePage from '../../../pages/gtl/GtlHomePage';
import GtlMarkDamage from '../../../pages/gtl/GtlMarkDamage';
import GtlMarkShort from '../../../pages/gtl/GtlMarkShort';
import SelectGtlPutawayLocation from '../../../pages/gtl/SelectGtlPutawayLocation';
import ScanGtlJioCode from '../../../pages/gtl/ScanGtlJioCode';
import ScanGtlLocation from '../../../pages/gtl/ScanGtlLocation';
import ScanGtlTote from '../../../pages/gtl/ScanGtlTote';
import GtlDropzoneCompleted from '../../../pages/gtl/Dropzone/GtlDropzoneCompleted';
import ScanToteAndDropzone from '../../../pages/gtl/Dropzone/ScanToteAndDropzone';
import GtlTaskListStatus from '../../../pages/gtl/GtlTaskListStatus';
import { ScanJiocode } from '../../../pages/returns/putaway/ScanJiocode';
import { LocationAndProductDetails } from '../../../pages/returns/putaway/LocationAndProductDetails';
import { PutawayJiocodeQuantity } from '../../../pages/returns/putaway/PutawayJiocodeQuantity';
import { AddNewLocation } from '../../../pages/returns/putaway/AddNewLocation';
import { TotePutawaySuccess } from '../../../pages/returns/putaway/TotePutawaySuccess';
import { PutawaySuccess } from '../../../pages/returns/putaway/PutawaySuccess';
import ScanPutawayTote from '../../../pages/gtl/Putaway/ScanPutawayTote';

export const GtlRoutes = [
    <Route key="1" element={<GtlHomePage />} path="" />,
    <Route key="2" element={<ScanGtlLocation />} path=":locationId/scan-gtl-location" />,
    <Route key="3" element={<ScanGtlTote />} path=":locationId/scan-gtl-tote" />,
    <Route key="4" element={<ScanGtlJioCode />} path=":locationId/scan-gtl-jioCode" />,
    <Route key="5" element={<GtlHelp />} path=":locationId/gtl-help" />,
    <Route key="6" element={<GtlMarkShort />} path=":locationId/gtl-mark-short" />,
    <Route key="7" element={<GtlMarkDamage />} path=":locationId/gtl-mark-damage" />,
    <Route key="8" element={<AddNewGtlTote />} path=":locationId/gtl-add-tote" />,
    <Route
        key="9"
        element={<SelectGtlPutawayLocation />}
        path=":locationId/gtl-putaway-location"
    />,
    <Route key="10" element={<ScanToteAndDropzone />} path=":locationId/scan-drop-zone" />,
    <Route key="11" element={<GtlDropzoneCompleted />} path="gtl-task-completed" />,
    <Route key="12" element={<GtlTaskListStatus />} path="gtl-task-status" />,
    <Route key="13" element={<ScanPutawayTote />} path=":locationId/scan-gtl-putaway-tote" />,
    <Route key="14" element={<ScanJiocode />} path=":stationId/:toteId/scan-jiocode" />,
    <Route
        key="15"
        element={<LocationAndProductDetails />}
        path=":stationId/:toteId/:jioCode/:locationId/scan-location"
    />,
    <Route
        key="16"
        element={<PutawayJiocodeQuantity />}
        path=":stationId/:toteId/:jioCode/:locationId/putaway-quantity"
    />,
    <Route
        key="17"
        element={<AddNewLocation />}
        path=":stationId/:toteId/:jioCode/:locationId/new-location"
    />,
    <Route key="18" element={<TotePutawaySuccess />} path=":stationId/:toteId/success" />,
    <Route key="19" element={<PutawaySuccess />} path=":stationId/success" />,
];
