import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../utils/urls';
import { TotesValidationSchema } from './useGeneratePutList';

export function useTotesValidation() {
    return useMutation(async (data: TotesValidationSchema) => {
        const response = await axios.get(urls.VALIDATE_TOTE(data.stationId, data.toteId));
        return response.data;
    });
}
