import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { urls } from '../../utils/urls';
import { ErrorResponse, handleError, SuccessResponse } from './useGetGtlTaskList';

export interface UpdateGtlTaskStatusSchema {
    taskId: string;
    status: string;
}

const defaultErrorMsg = 'Something went wrong.';
export function useUpdateGtlTaskStatus() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, UpdateGtlTaskStatusSchema>(
        async (body: UpdateGtlTaskStatusSchema) => {
            const response = await axios.patch(
                urls.UPDATE_GTL_TASK_STATUS(body.status, body.taskId)
            );
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
