import { cn } from '../../../utils/tailwind';
import { useNavigate } from 'react-router-dom';
import { Button, SearchBox, Spinner, Text } from '@jds/core';
import { useState } from 'react';
import { usePackagesByPrimaryCase } from '../../../hooks/receiving/usePackages';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { ProductListItem } from '../../../components/returns/receiving/ProductListItem';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';

export function SearchFromProductListPage() {
    const [navigate] = [useNavigate()];
    const [activeJioCode, setActiveJioCode] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const { packages, isLoading } = usePackagesByPrimaryCase();
    const uncompletedPackages = packages
        .filter((packageItem) => packageItem.asnValidationStatus === 'Pending')
        .map((packageItem) => packageItem);
    const matchedProducts = uncompletedPackages.filter(({ ean }) => ean.includes(searchQuery));

    return (
        <div className={cn('flex min-h-screen max-w-screen-md flex-col pb-3 md:mx-auto')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Product list"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <div className={cn('mx-3 mt-3 pb-6')}>
                    <SearchBox
                        label="Search Product List"
                        name="search-box"
                        onChange={(event) => {
                            setSearchQuery((event.target as HTMLInputElement).value);
                        }}
                    />
                </div>
            </div>
            <main className={cn('mx-3')}>
                <div className="mt-4 space-y-4">
                    {isLoading && <Spinner className={cn('flex justify-center')} size="small" />}
                    {matchedProducts.length === 0 && !isLoading && (
                        <Text className={cn('text-center')}>No products found!</Text>
                    )}
                    {matchedProducts.map((product) => (
                        <ProductListItem
                            key={product.jioCode}
                            activeJioCode={activeJioCode}
                            bestBefore={product.bestBefore}
                            color={product.colour}
                            ean={product.ean}
                            image={product.imageUrls?.at(0)}
                            jioCode={product.jioCode}
                            manufacturedDate={product.manufacturedDate}
                            mrp={product.mrp}
                            setActiveJioCode={setActiveJioCode}
                            size={product.size}
                        />
                    ))}
                </div>
            </main>
            <div
                className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    disabled={activeJioCode === null}
                    kind="primary"
                    size="large"
                    type="button"
                    onClick={() => {
                        const eanScannedTime = new Date();
                        localStorage.setItem('EAN_START_TIME', JSON.stringify(eanScannedTime));
                        navigate(`./../${activeJioCode}`);
                    }}
                >
                    Confirm
                </Button>
            </div>
        </div>
    );
}
