import { urls } from '../../../utils/urls';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';

export interface ReturnReceivingDetails {
    return_order_id: string;
    station_id: string;
    shipmentId: string;
    jio_code: string;
    quantities_list_by_reject_reason: rejectReasonDetails[];
    quantities_list_by_zone: toteDropDetails[];
    qc_done_by: string;
    appointment_id: number;
    return_product_status: string;
    return_receiving_status: 'RETURN_QC_DONE' | 'LABELS_PRINTING_DONE';
    lot_id: number;
    validation_start_time: string;
}

export interface rejectReasonDetails {
    rejectReasonCodeEnum: string;
    quantity: number;
}
export interface toteDropDetails {
    toteDropProductType: 'LIQUIDATION' | 'SCRAP' | 'GOOD';
    quantity: number;
}

export interface UserReturnsReceivingStateResponse {
    data: ReturnReceivingDetails;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useFetchUserReturnsReceivingState() {
    const { data, ...rest } = useQuery<UserReturnsReceivingStateResponse>({
        queryKey: ['returnsReceivingStateDetails'],
        queryFn: async () => {
            const response = await axios.get(urls.GET_USER_RETURNS_RECEIVING_STATE());
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
    return { returnsReceivingStateDetails: data?.data, ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
