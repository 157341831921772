import { cn } from '../../utils/tailwind';
import { Button, Spinner, Text } from '@jds/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useProductDetails } from '../../hooks/cubiscan/useProductDetails';
import { ProductCard } from '../../components/shared/ProductCard';
import { useState } from 'react';
import { CubiscanVerificationModal } from '../../components/cubiscan/CubiscanVerificationModal';
import { PageHeaderV2 } from '../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../styles/common';
import { IncrementDecrementSectionV2 } from '../../components/shared/IncrementDecrementSectionV2';
import { useOverrideDetails } from '../../hooks/cubiscan/useOverrideDetails';

const verificationSchema = yup.object({
    length: yup.number().typeError('').min(0),
    breadth: yup.number().typeError('').min(0),
    height: yup.number().typeError('').min(0),
    weight: yup.number().typeError('').min(0),
});

type VerificationSchema = yup.InferType<typeof verificationSchema>;

const defaultValues: Record<keyof VerificationSchema, number> = {
    breadth: 0,
    height: 0,
    length: 0,
    weight: 0,
};

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function CubiscanVerificationPage() {
    const [showDialog, setShowDialog] = useState(false);
    const navigate = useNavigate();
    const overrideDetails = useOverrideDetails();
    const { cubiscanLabel, lotId } = useParams();
    const { data: product, isLoading } = useProductDetails(cubiscanLabel ?? '', lotId ?? '');
    const { control, handleSubmit, watch } = useForm<VerificationSchema>({
        resolver: yupResolver(verificationSchema),
        defaultValues,
    });

    const [editModes, setEditModes] = useState({
        height: false,
        weight: false,
        length: false,
        breadth: false,
    });

    function onSubmit(formValues: VerificationSchema) {
        const { breadth, height, length, weight } = formValues;
        const isCorrectDimension =
            Number(breadth) === product?.breadth &&
            Number(height) === product?.height &&
            Number(length) === product?.length &&
            Number(weight) === Number(product?.weight);

        if (isCorrectDimension) {
            overrideDetails.mutate(
                {
                    breadth: breadth ?? 0,
                    length: length ?? 0,
                    height: height ?? 0,
                    weight: weight ?? 0,
                },
                {
                    onSuccess: () => navigate('scan-jio-code?isCorrectDimension=true'),
                }
            );
        } else setShowDialog(true);
    }

    return (
        <form className={cn(layoutStyles)} onSubmit={handleSubmit(onSubmit)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    title="Cubiscan Verification"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
            </div>
            {/*
                Make sure that the margin-bottom is greater than the height of the fixed bottom bar.
                Otherwise, the bottom bar will hide the main content
            */}
            <main className={cn('mx-5 mb-24 mt-6')}>
                {product && (
                    <ProductCard
                        BBD={product.expiry}
                        EAN={product.ean}
                        MFD={product.mfg}
                        MRP={product.mrp}
                        colorSize={` ${product.color ?? '_'}, ${product.size ?? '_'}`}
                        image={product.image_urls?.at(0)}
                    />
                )}
                <div className={cn('mt-6')}>
                    <Text appearance="body-s-bold" className={cn('ml-3')}>
                        Dimension details - Cubiscan
                    </Text>
                    <div className={cn('mt-5 items-center gap-y-6')}>
                        <div className={cn('my-3')}>
                            <IncrementDecrementSectionV2
                                borderColour="--color-primary-60"
                                defaultInputValue={control._formValues['length']}
                                inEditMode={editModes.length}
                                successCallBack={() => {}}
                                title="Length"
                                onChangeCallBack={(val) => {
                                    control._formValues['length'] = val;
                                }}
                                onEditCallBack={() => {
                                    setEditModes({
                                        height: false,
                                        weight: false,
                                        length: true,
                                        breadth: false,
                                    });
                                }}
                            />
                        </div>
                        <div className={cn('my-3')}>
                            <IncrementDecrementSectionV2
                                borderColour="--color-primary-60"
                                defaultInputValue={control._formValues['breadth']}
                                inEditMode={editModes.breadth}
                                successCallBack={() => {}}
                                title="Breadth"
                                onChangeCallBack={(val) => {
                                    control._formValues['breadth'] = val;
                                }}
                                onEditCallBack={() => {
                                    setEditModes({
                                        height: false,
                                        weight: false,
                                        length: false,
                                        breadth: true,
                                    });
                                }}
                            />
                        </div>
                        <div className={cn('my-3')}>
                            <IncrementDecrementSectionV2
                                borderColour="--color-primary-60"
                                defaultInputValue={control._formValues['height']}
                                inEditMode={editModes.height}
                                successCallBack={() => {}}
                                title="Height"
                                onChangeCallBack={(val) => {
                                    control._formValues['height'] = val;
                                }}
                                onEditCallBack={() => {
                                    setEditModes({
                                        height: true,
                                        weight: false,
                                        length: false,
                                        breadth: false,
                                    });
                                }}
                            />
                        </div>
                        <div className={cn('my-3')}>
                            <IncrementDecrementSectionV2
                                borderColour="--color-primary-60"
                                defaultInputValue={control._formValues['weight']}
                                inEditMode={editModes.weight}
                                successCallBack={() => {}}
                                title="Weight"
                                onChangeCallBack={(val) => {
                                    control._formValues['weight'] = val;
                                }}
                                onEditCallBack={() => {
                                    setEditModes({
                                        height: false,
                                        weight: true,
                                        length: false,
                                        breadth: false,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </main>
            <CubiscanVerificationModal
                details={{
                    length: watch('length') ?? 0,
                    breadth: watch('breadth') ?? 0,
                    height: watch('height') ?? 0,
                    weight: watch('weight') ?? 0,
                }}
                setShowDialog={setShowDialog}
                showDialog={showDialog}
            />
            <div
                className={cn('fixed inset-x-0 bottom-0 bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button fullWidth size="large">
                    Verify details
                </Button>
            </div>
            {isLoading && (
                <div
                    className={cn(
                        'flex items-center justify-center',
                        'absolute inset-0 h-full min-h-screen bg-gray-200/50'
                    )}
                >
                    <Spinner />
                </div>
            )}
        </form>
    );
}
