import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { useParams } from 'react-router-dom';
import { ErrorResponse, handleError } from './useGetPickList';

export interface PicklistLocationItemProps {
    color: string;
    expiry_date: number;
    image_url: string;
    jio_code: string;
    location_id: string;
    lot_id: number;
    manufacture_date: number;
    mrp: number;
    order_id: string;
    pick_zone_id: string;
    quantity: number;
    size: string;
    product_name: string;
    ean: string;
    dimensions: string;
    shipment_id: string;
    storage_zone_id: string;
}

export interface PicklistLocationItemData {
    item_details: PicklistLocationItemProps[];
    picking_complete: boolean;
    next_activity: string;
    message: string;
    success: boolean;
}

export interface PickListLocationResponse {
    data: PicklistLocationItemData;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useGetPicklistLocation() {
    const { pickingId } = useParams();
    const { data, ...rest } = useQuery<PickListLocationResponse>({
        queryKey: ['pickListLocationsData'],
        queryFn: async () => {
            const response = await axios.get(urls.GET_PICKLIST_LOCATION(pickingId || ''), {
                headers: {
                    instanceCode: sessionStorage.getItem('rfc-code'),
                },
            });
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });

    return { pickingLocationsList: data?.data?.item_details ?? [], ...rest };
}

export function usePicklistPendingLocationStatus() {
    return useMutation<PickListLocationResponse, AxiosError<ErrorResponse>, string>(
        async (pickingId: string) => {
            const response = await axios.get(urls.GET_PICKLIST_LOCATION(pickingId || ''), {
                headers: {
                    instanceCode: sessionStorage.getItem('rfc-code'),
                },
            });
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
