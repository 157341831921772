import { useParams } from 'react-router-dom';
import { usePackagesBySecondaryCase } from './usePackages';
import { useContext } from 'react';
import { ReceivingContext } from '../../provider/inbound/receiving';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { ErrorResponse } from './useVerifyQuantities';
import { urls } from '../../utils/urls';
import { Notifications } from '@jds/core';
import { ASNValidationSchema } from '../../pages/inbound/receiving';
import { useQueryParams } from '../shared/useQueryParams';

type Required<T> = {
    [P in keyof T]-?: T[P];
};
export function createFormBody(formValues: ASNValidationSchema): Required<ASNValidationSchema> {
    return {
        shortQuantity: formValues.shortQuantity ?? 0,
        excessQuantity: formValues.excessQuantity ?? 0,
        damagedQuantity: formValues.damagedQuantity ?? 0,
        goodQuantity: formValues.goodQuantity ?? 0,
        batchIssueQuantity: formValues.batchIssueQuantity ?? 0,
        expiredQuantity: formValues.expiredQuantity ?? 0,
        lowFreshnessQuantity: formValues.lowFreshnessQuantity ?? 0,
        wrongQuantity: formValues.wrongQuantity ?? 0,
    };
}

export function useMarkShort() {
    const { appointmentNumber, stationId } = useParams();
    const { packages } = usePackagesBySecondaryCase();
    const username = sessionStorage.getItem('username');
    const queryParams = useQueryParams();
    const { completedPrimaryCases } = useContext(ReceivingContext).state;
    const uncompletedPrimaryCases = packages.filter(
        ({ packageLabel }) => !completedPrimaryCases.includes(packageLabel)
    );

    const uncompletedPrimaryCaseBody = uncompletedPrimaryCases.map(
        ({ jioCode, packageLabel, itemQuantity }) => ({
            jioCode,
            packageLabel,
            itemQuantity,
        })
    );

    return useMutation<unknown, AxiosError<ErrorResponse>>({
        mutationFn: async () => {
            const promises = uncompletedPrimaryCaseBody.map(
                ({ jioCode, packageLabel, itemQuantity }) => {
                    const body = {
                        ...createFormBody({ shortQuantity: itemQuantity }),
                        primaryCase: packageLabel,
                        qcDoneBy: username,
                        appointmentId: appointmentNumber,
                        jioCode,
                        isCubiscanRequired: queryParams.get('cubiscanRequired') === 'true',
                        stationId: stationId,
                        validationStartTime: new Date(),
                        serialOrIMEINumberMissing: 0,
                        eyeBallCheckStatus: 'Passed',
                    };
                    return axios.patch(
                        urls.ASN_VALIDATION(Number(appointmentNumber), jioCode),
                        body
                    );
                }
            );
            const responses = await Promise.all(promises);
            return responses.map(({ data }) => data);
        },
        onError: (error) => {
            const defaultErrorMsg = 'An error occurred while validating quantities';
            const message = error?.response?.data?.meta?.errors?.at(0) ?? defaultErrorMsg;
            Notifications.toast.error({
                title: 'ASN Validation Error',
                description: message,
            });
        },
    });
}
