import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export function useUnloadingByUser() {
    return useMutation<unknown, any>({
        mutationFn: async () => {
            const response = await axios.get<any>(urls.GET_RETURNS_UNLOADING_BY_USERNAME());
            const { data } = response.data;
            return data;
        },
    });
}
