import { cn } from '../../../utils/tailwind';
import { Icon, Text } from '@jds/core';
import { ProductDetails } from '../../../hooks/returns/unloading/useReturnProducts';
import { IcSuccess } from '@jds/core-icons';
import { useState } from 'react';

interface ReturnItemProps {
    product: ProductDetails;
    showCompletedIcon?: boolean;
}

export function ReturnItem({ product, showCompletedIcon = false }: ReturnItemProps) {
    const [showFullDescription, setShowFullDescription] = useState(false);
    return (
        <div className={cn('flex space-x-2')}>
            <img
                alt="product"
                className={cn('max-h-[71px] max-w-[71px] rounded-2xl object-cover')}
                height={71}
                src={product.image_urls?.at(0) ?? 'https://via.placeholder.com/71'}
                width={71}
            />
            <div className={cn('flex-1')}>
                <Text appearance="body-xxs" color="primary-grey-80">
                    EAN {product.ean}
                </Text>
                <button
                    aria-label={showFullDescription ? 'Show less' : 'Show more'}
                    type="button"
                    onClick={() => setShowFullDescription(!showFullDescription)}
                >
                    <Text
                        appearance="body-xxs"
                        className={cn('text-left', [!showFullDescription && 'line-clamp-3'])}
                        color="primary-grey-80"
                    >
                        {product.description}
                    </Text>
                </button>
            </div>
            <div className={cn('!mr-3 flex space-x-5')}>
                <div>
                    <Text appearance="body-xs" color="primary-grey-80">
                        Qty
                    </Text>
                    <Text appearance="body-s">{product.expectedQuantity}</Text>
                </div>
                {showCompletedIcon && <Icon color="success" ic={<IcSuccess />} />}
            </div>
        </div>
    );
}
