import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { ErrorResponse, handleError, SuccessResponse } from './useGetPickList';

export interface UpdatePickListStatusSchema {
    pickListId: number;
    status: string;
    dropZoneId: string;
}

const defaultErrorMsg = 'Something went wrong.';
export function useUpdatePickListStatus() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, UpdatePickListStatusSchema>(
        async (body: UpdatePickListStatusSchema) => {
            const rfcId = sessionStorage.getItem('rfcId') || '';
            const response = await axios.patch(urls.UPDATE_PICKLIST_STATUS(), body, {
                headers: {
                    instanceCode: sessionStorage.getItem('rfc-code'),
                },
            });
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
