import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../utils/tailwind';
import { Button, Input, Notifications, ProgressBar, Text } from '@jds/core';
import { useQueryClient } from '@tanstack/react-query';

import { PageHeader } from '../../components/shared/PageHeader';
import { useUpdateGtlShortDamageItemStatus } from '../../hooks/gtl/useUpdateGtlShortDamageItemStatus';
import { GtlTaskListResponse } from '../../hooks/gtl/useGetGtlTaskList';

const layoutStyles = 'max-w-screen-md md:mx-auto';

const GtlMarkShort = () => {
    const navigate = useNavigate();
    const [shortQuantity, setShortQuantity] = useState(0);
    const [error, setError] = useState(false);
    const { locationId } = useParams();
    const gtlTaskList = useQueryClient().getQueryData<GtlTaskListResponse>(['gtlTaskList']);
    const gtlLocationsList: any = gtlTaskList?.data.filter(
        (item) => item.sourceLocationId === locationId
    );
    const pickedGtlJioCodeIndex = Number(
        JSON.parse(localStorage.getItem('pickedGtlJioCodeIndex') || '0')
    );
    const currentJioCodeData: any =
        gtlLocationsList &&
        gtlLocationsList.length > pickedGtlJioCodeIndex &&
        gtlLocationsList[pickedGtlJioCodeIndex];
    const scannedItemQuantityTillNow = Number(
        JSON.parse(localStorage.getItem('scannedGtlItemQuantityTillNow') || '0')
    );
    const updateMarkShortItems = useUpdateGtlShortDamageItemStatus();
    const onShorItemClick = () => {
        const updatedTaskObj = { damagedQuantity: 0, shortQuantity };
        updateMarkShortItems.mutate(updatedTaskObj, {
            onSuccess() {
                const totalItems = scannedItemQuantityTillNow + shortQuantity;
                localStorage.setItem('scannedGtlItemQuantityTillNow', JSON.stringify(totalItems));
                if (totalItems === currentJioCodeData?.quantity) {
                    const pickedJioCodeIndex =
                        Number(localStorage.getItem('pickedGtlJioCodeIndex')) + 1;
                    if (pickedJioCodeIndex === gtlLocationsList?.length) {
                        localStorage.setItem('gtlLocationCompleted', JSON.stringify(true));
                    } else {
                        localStorage.setItem(
                            'pickedGtlJioCodeIndex',
                            JSON.stringify(pickedJioCodeIndex)
                        );
                        localStorage.setItem('gtlJioCodeCompleted', JSON.stringify(true));
                    }
                }
                navigate(`../${locationId}/scan-gtl-jioCode`);
            },
        });
    };
    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <PageHeader
                title="Mark Short"
                onBackIconClick={() => {
                    navigate(-1);
                }}
            ></PageHeader>
            <main className={cn('mx-2 mt-4 space-y-8')}>
                <ProgressBar
                    className={cn('mt-6')}
                    label=" Order quantity"
                    state="error"
                    value={shortQuantity / currentJioCodeData?.quantity}
                    valueLabel={`${shortQuantity}/${currentJioCodeData?.quantity}`}
                />
                <div className={cn('mr-10 flex items-center justify-between')}>
                    <Text appearance="body-m" color="primary-grey-100">
                        Short
                    </Text>

                    <Input
                        className={cn('max-w-[51px]')}
                        inputMode="numeric"
                        label=""
                        name="assigned-quantity"
                        state={error ? 'error' : 'success'}
                        type="number"
                        value={shortQuantity || ''}
                        onChange={(event) => {
                            if (
                                Number(event.target.value) <=
                                currentJioCodeData?.quantity - scannedItemQuantityTillNow
                            ) {
                                setError(false);
                                setShortQuantity(Number(event.target.value));
                            } else {
                                setError(true);
                                Notifications.toast.error({
                                    title: 'Error',
                                    description: 'Wrong number of short quantity entered.',
                                });
                            }
                        }}
                    />
                </div>

                <div className={cn('fixed inset-x-2 bottom-8 mx-4')}>
                    <Button fullWidth kind="primary" onClick={() => onShorItemClick()}>
                        Confirm Short
                    </Button>
                </div>
            </main>
        </div>
    );
};

export default GtlMarkShort;
