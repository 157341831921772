import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Button, Container, Divider, Icon, Notifications, Text } from '@jds/core';
import { useEffect, useState } from 'react';
import { useReturnOrders } from '../../../hooks/returns/unloading/useReturnOrders';
import { IcChevronUp, IcChevronDown } from '@jds/core-icons';
import { IcOrder } from '@jds/extended-icons';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';

export function ScanJioCodePage() {
    const [navigate] = [useNavigate(), useParams()];
    const { data: returnOrders } = useReturnOrders(false);
    const inCompletedJioCodes = returnOrders?.products
        .filter((packageItem) => packageItem.product_status === 'RETURN_UNLOADING_COMPLETED')
        .map((packageItem) => packageItem);
    const jioCodeKeys = inCompletedJioCodes?.map((product) => ({ result: product.jio_code }));
    const [showApplicationDetails, setShowApplicationDetails] = useState(false);

    function onScanSuccess(jioCode: string) {
        const isValidJioCode = inCompletedJioCodes?.some((product) => product.jio_code === jioCode);
        if (isValidJioCode) navigate(`./../${jioCode}/details`);
        else {
            Notifications.toast.error({
                title: 'Invalid Jio Code',
                description: 'Please scan or enter a valid Jio Code',
            });
        }
    }

    useEffect(() => {
        if (inCompletedJioCodes?.length == 0) {
            localStorage.setItem('PRIMARY_CASE_SCANNED_LIST', 'true');
            navigate(`../${returnOrders?.awb_no}/success`);
        }
    }, [inCompletedJioCodes, inCompletedJioCodes?.length, navigate, returnOrders?.awb_no]);

    return (
        <div className={cn('flex min-h-screen max-w-screen-md flex-col pb-3 md:mx-auto')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Jio Code"
                    onBackIconClick={() => {
                        localStorage.getItem('EAN_SCANNED_LIST')
                            ? Notifications.toast.error('Unable to navigate back')
                            : navigate(-1);
                    }}
                />
                <div className={cn('mx-3  py-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={{ name: 'JioCode', label: 'Jio Code' }}
                        searchableList={jioCodeKeys}
                        onSuccess={onScanSuccess}
                    />
                </div>
            </div>
            <main className={cn('mx-6 mt-6')}>
                <Button
                    fullWidth
                    kind="secondary"
                    type="button"
                    onClick={() => navigate('./../reprint-jio-code')}
                >
                    Search from Product list
                </Button>
                <Container
                    className={cn(
                        'mx-2 my-5 rounded-lg border border-[var(--color-primary-60)] p-4'
                    )}
                >
                    <Icon
                        className={cn('absolute left-4 bg-white py-1')}
                        ic={<IcOrder color="#000093" />}
                        size="l"
                    />
                    <Container className={cn('ml-2 flex justify-between ')}>
                        <Text appearance="body-xs-bold"> Shipment ID </Text>
                        <div className={cn('flex justify-between')}>
                            <Text
                                appearance="body-xs-bold"
                                className={cn('w-[116px]  break-words text-center')}
                            >
                                {returnOrders?.shipment_id}
                            </Text>
                            <Icon
                                ic={showApplicationDetails ? <IcChevronUp /> : <IcChevronDown />}
                                onClick={() => setShowApplicationDetails(!showApplicationDetails)}
                            />
                        </div>
                    </Container>
                    {showApplicationDetails && (
                        <>
                            <Divider className={cn('my-4')} />

                            <Container className={cn('mx-2 grid grid-cols-2 break-words')}>
                                <Text appearance="body-s" color="primary-grey-100">
                                    Jio code
                                </Text>
                                <Text
                                    appearance="body-s"
                                    className={cn('text-center')}
                                    color="primary-grey-100"
                                >
                                    Quantity
                                </Text>
                            </Container>
                            <Container className={cn('mx-2 grid grid-cols-2 break-words')}>
                                {inCompletedJioCodes?.map(
                                    ({ received_quantity, jio_code }, index) => (
                                        <>
                                            <div key={jio_code}>
                                                <Container
                                                    className={cn('my-4 flex justify-between')}
                                                >
                                                    <Text
                                                        appearance="body-xs"
                                                        color="primary-grey-100"
                                                    >
                                                        {jio_code}
                                                    </Text>
                                                </Container>
                                            </div>
                                            <Container
                                                className={cn(
                                                    'my-4 flex justify-center text-center'
                                                )}
                                            >
                                                <Text appearance="body-xs" color="primary-grey-100">
                                                    {received_quantity}
                                                </Text>
                                            </Container>
                                        </>
                                    )
                                )}
                            </Container>
                        </>
                    )}
                </Container>
            </main>
        </div>
    );
}
