import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { downloadFile } from '../../../utils/fetchHelper';
import { Notifications } from '@jds/core';

interface Options {
    jioCode: string;
    noOfCopies: number;
}

interface PrintJioCodeProps {
    showSuccessMessage?: boolean;
}
export function usePrintJioCodes({ showSuccessMessage = true }: PrintJioCodeProps) {
    return useMutation<unknown, AxiosError<ErrorResponse>, Options>({
        mutationFn: async ({ noOfCopies, jioCode }) => {
            const response = await axios({
                url: urls.PRINT_Jio_CODE(jioCode, noOfCopies),
                responseType: 'blob',
            });
            // downloadFile(response.data, 'jio-codes.pdf'); todo: print instead of download
            return null;
        },
        onError: (error) => {
            Notifications.toast.error({
                title: 'Error',
                description: error.response?.data.message ?? 'Something went wrong',
            });
        },
        onSuccess: () => {
            if (showSuccessMessage)
                Notifications.toast.success({
                    title: 'Success',
                    description: 'Labels printed successfully',
                });
        },
    });
}

interface ErrorResponse {
    timestamp: number;
    status: number;
    error: string;
    message: string;
    path: string;
}
