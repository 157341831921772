import { cn } from '../../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { useValidateTote, ValidateToteOptions } from '../../../../hooks/receiving/useValidateTote';
import { useCurrentPackage } from '../../../../hooks/receiving/usePackages';
import { useQueryParams } from '../../../../hooks/shared/useQueryParams';
import { PageHeaderV2 } from '../../../../components/shared/PageHeaderV2';
import { ScanOrEnterManuallyV2 } from '../../../../components/shared/ScanOrEnterManuallyV2';
import { pageHeaderStyle } from '../../../../styles/common';
import { Heading, Modal, Notifications, Text } from '@jds/core';
import { useState } from 'react';

const manualInputProps = { name: 'cubiscan-tote', label: 'Cubiscan Tote', type: 'text' };
export function ScanCubiscanTotePage() {
    const [navigate, queryParams] = [useNavigate(), useQueryParams()];
    const validateTote = useValidateTote();
    const currentPackage = useCurrentPackage();
    const { stationId, jioCode, primaryCaseId, secondaryCaseId } = useParams();
    const [showModal, setShowModal] = useState(true);

    function onScanSuccess(tote_code: string) {
        if (!jioCode) throw new Error('No jio code found');
        if (!currentPackage) throw new Error('Current package is not defined');

        const validateToteOptions: ValidateToteOptions = {
            tote_code,
            quantity: 1,
            product_type: 'CUBISCAN',
            jio_code: jioCode ?? '',
            location_id: stationId ?? '',
            tote_purpose: 'ABOUT_TO_CUBISCAN',
            lot_id: currentPackage.lotId,
            tote_params: {
                primaryId: primaryCaseId || '',
                secondaryId:
                    Number(secondaryCaseId) === -1 ? primaryCaseId || '' : secondaryCaseId || '',
            },
        };

        queryParams.set('cubiscanToteId', tote_code);
        validateTote.mutate(validateToteOptions, {
            onSuccess: () => {
                navigate({ pathname: '../assign', search: queryParams.toString() });
            },
        });
    }
    return (
        <div className={cn('max-w-screen-md pb-3 md:mx-auto')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Assign Cubiscan Tote"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                <main className={cn('mx-6 mt-3 mb-20 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        type="TOTE_CODE"
                        onSuccess={onScanSuccess}
                    />
                </main>
            </div>
            <Modal
                className={cn('flex justify-center')}
                closed={!showModal}
                color="primary-grey-100"
                header={{
                    top: (
                        <div className={cn('text-center')}>
                            <Heading
                                appearance="heading-xs"
                                className={cn('flex justify-center text-center')}
                            >
                                Cubiscan Item
                            </Heading>
                        </div>
                    ),
                }}
                primaryCTA={{
                    title: 'Okay',
                    onClick: () => setShowModal(false),
                }}
                onCloseCallback={() => setShowModal(false)}
            >
                <Text
                    appearance="body-s"
                    className={cn('flex justify-center text-center')}
                    color="primary-grey-80"
                >
                    <Text>
                        Take{' '}
                        <Text appearance="body-s-bold" as="span" color="primary-grey-100">
                            1 Good Quantity, put Label{' '}
                        </Text>
                        and start Cubiscan drop
                    </Text>
                </Text>
            </Modal>
        </div>
    );
}
