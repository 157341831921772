import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface UpdateDamagedProductsSchema {
    cancelled_quantity: number;
    cancellation_reason: string;
    order_id: string;
    jio_code: string;
    shipment_id?: string;
    cancellation_stage?: 'CANCELLED_DURING_PACKING';
    cancelledDuringPacking?: boolean;
}

export function useUpdateDamagedProducts() {
    return useMutation(async (data: UpdateDamagedProductsSchema[]) => {
        const response = await axios.post(urls.UPDATE_DAMAGED_PRODUCTS(), data);
        return response.data;
    });
}
