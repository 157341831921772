import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Button, Notifications } from '@jds/core';
import { usePackagesByPrimaryCase } from '../../../hooks/receiving/usePackages';
import { useContext, useEffect } from 'react';
import { ReceivingContext } from '../../../provider/inbound/receiving';
import { useCompatibleSKUs } from '../../../hooks/returns/receiving/useCompatibleSKUs';
import { CompatibleSKUsSection } from '../../../components/receiving/CompatibleSKUsSection';
import { Package } from '../../../types/receiving';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';

/**
 * This page is used twice in the inbound receiving flow
 * 1. In normal flow, after scanning the primary case
 * 2. When receiving is done for a jio code but there are more jio codes left in the primary case
 */
export function ScanEANPage() {
    const { packages } = usePackagesByPrimaryCase();
    const { storageZoneId } = useContext(ReceivingContext).state;
    const [navigate, { secondaryCaseId }] = [useNavigate(), useParams()];
    const completedJioCodes = packages
        .filter((packageItem) => packageItem.asnValidationStatus !== 'Pending')
        .map((packageItem) => packageItem);
    const uncompletedJioCodes = packages
        .filter((packageItem) => packageItem.asnValidationStatus === 'Pending')
        .map((packageItem) => packageItem);

    const eanKeys = uncompletedJioCodes?.map((item: any) => ({
        result: item.ean,
    }));

    const { data: skuList, isLoading } = useCompatibleSKUs(storageZoneId, uncompletedJioCodes);

    function onScanSuccess(ean: string) {
        const product = packages.find(({ ean: eanCode }) => eanCode === ean);
        if (product) {
            const val = completedJioCodes.findIndex(
                (data: Package) => data.jioCode === product.jioCode
            );
            if (val !== -1) {
                Notifications.toast.error({
                    title: 'Error',
                    description: 'Product already completed',
                });
            } else {
                const eanScannedTime = new Date();
                localStorage.setItem('EAN_START_TIME', JSON.stringify(eanScannedTime));
                navigate(`${product.jioCode}`);
            }
        } else {
            Notifications.toast.error({
                title: 'Error',
                description: 'Product not found for given EAN in this primary case',
            });
        }
    }

    useEffect(() => {
        if (completedJioCodes.length > 0 || uncompletedJioCodes.length > 0) {
            if (uncompletedJioCodes?.length === 0) {
                localStorage.setItem('PRIMARY_CASE_SCANNED_LIST', 'true');
                navigate(`../${secondaryCaseId}`);
            }
        }
    }, [
        navigate,
        secondaryCaseId,
        packages,
        completedJioCodes.length,
        completedJioCodes,
        uncompletedJioCodes,
    ]);

    return (
        <div className={cn('flex min-h-screen max-w-screen-md flex-col pb-3 md:mx-auto')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="EAN"
                    onBackIconClick={() => {
                        localStorage.getItem('EAN_SCANNED_LIST')
                            ? Notifications.toast.error('Unable to navigate back')
                            : navigate(-1);
                    }}
                />
                <div className={cn('mx-3  py-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={{ name: 'EAN', label: 'EANs' }}
                        searchableList={eanKeys}
                        onSuccess={onScanSuccess}
                    />
                </div>
            </div>
            <main
                className={cn(
                    'mx-3 mt-3 pt-3 pb-5',
                    completedJioCodes.length === 0 && 'flex flex-1 flex-col '
                )}
            >
                {uncompletedJioCodes.length > 0 && (
                    <Button
                        color="primary-50"
                        kind="secondary"
                        type="button"
                        onClick={() => navigate('product-list')}
                    >
                        Search From Product List
                    </Button>
                )}
                {completedJioCodes.length > 0 && storageZoneId !== '' && (
                    <CompatibleSKUsSection
                        enableShowMore={true}
                        isLoading={isLoading}
                        skuList={skuList ?? []}
                    />
                )}
            </main>
        </div>
    );
}
