import { initialReceivingState } from './ReceivingProvider';

export interface ReceivingState {
    quantityStatus: QuantityStatus;
    completedJioCodes: string[];
    completedPrimaryCases: string[];
    storageZoneId: string;
}

export interface QuantityStatus {
    good: number;
    damaged: number;
    short: number;
    batchIssue: number;
    excess: number;
    lowFreshness: number;
    expired: number;
    wrong: number;
    failed: number;
    cubiscan: number;
}

export type ReceivingAction =
    | { type: 'SET_QUANTITY_STATUS'; payload: QuantityStatus }
    | { type: 'RESET_QUANTITY_STATUS' }
    | { type: 'SET_COMPLETED_JIO_CODES'; payload: string[] }
    | { type: 'SET_COMPLETED_PRIMARY_CASES'; payload: string[] }
    | { type: 'SET_STORAGE_ZONE_ID'; payload: string }
    | { type: 'RESET_ALL' };

export function receivingReducer(state: ReceivingState, action: ReceivingAction): ReceivingState {
    switch (action.type) {
        case 'SET_QUANTITY_STATUS':
            return { ...state, quantityStatus: action.payload };
        case 'RESET_QUANTITY_STATUS':
            return { ...state, quantityStatus: initialReceivingState.quantityStatus };
        case 'SET_COMPLETED_JIO_CODES':
            return { ...state, completedJioCodes: action.payload };
        case 'SET_COMPLETED_PRIMARY_CASES':
            return { ...state, completedPrimaryCases: action.payload };
        case 'SET_STORAGE_ZONE_ID':
            return { ...state, storageZoneId: action.payload };
        case 'RESET_ALL':
            return { ...initialReceivingState };
        default:
            throw new Error('Unhandled action type');
    }
}
