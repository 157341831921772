import { ProductDetails } from '../../../hooks/returns/unloading/useReturnProducts';

export interface ReturnsUnloadingState {
    /**
     * List of airway bills that have been completed
     */
    completedAirwayBills: any[];
    /**
     * List of jio-codes that have been completed for a waybill
     */
    completedProducts: string[];
    appointmentType: string | null;
    products: ProductDetails[];
}

export type UnloadingActions =
    | { type: 'SET_COMPLETED_AIRWAY_BILLS'; payload: string[] }
    | { type: 'SET_COMPLETED_PRODUCTS'; payload: string[] }
    | { type: 'RESET_COMPLETED_PRODUCTS' }
    | { type: 'SET_APPOINTMENT_TYPE'; payload: string | null }
    | { type: 'RESET_COMPLETED_AIRWAY_BILLS' }
    | { type: 'SET_ALL_PRODUCTS'; payload: ProductDetails[] }
    | { type: 'RESET_ALL_PRODUCTS' };

export function unloadingReducer(
    state: ReturnsUnloadingState,
    action: UnloadingActions
): ReturnsUnloadingState {
    switch (action.type) {
        case 'SET_COMPLETED_AIRWAY_BILLS':
            return { ...state, completedAirwayBills: action.payload };
        case 'SET_COMPLETED_PRODUCTS':
            return { ...state, completedProducts: action.payload };
        case 'SET_APPOINTMENT_TYPE':
            return { ...state, appointmentType: action.payload };
        case 'SET_ALL_PRODUCTS':
            return { ...state, products: action.payload };
        case 'RESET_COMPLETED_PRODUCTS':
            return { ...state, completedProducts: [] };
        case 'RESET_COMPLETED_AIRWAY_BILLS':
            return { ...state, completedAirwayBills: [] };
        case 'RESET_ALL_PRODUCTS':
            return { ...state, products: [] };
        default:
            throw new Error('Unhandled action type');
    }
}
