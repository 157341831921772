import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Notifications } from '@jds/core';
import { cn } from '../../../utils/tailwind';

import { PageHeader } from '../../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useUpdateGtlTaskStatus } from '../../../hooks/gtl/useUpdateGtlTaskStatus';
import { useMapGtlPutawayStation } from '../../../hooks/gtl/useMapGtlPutawayStation';
import { toteSchema } from '../../../hooks/gtl/useUpdateGtlJioCodeStatus';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputToteProps = { name: 'tote-code', label: 'Tote Code', type: 'text' };
const manualInputDropProps = { name: 'drop-zone', label: 'Drop Zone', type: 'text' };

const ScanToteAndDropzone = () => {
    const navigate = useNavigate();
    const [toteId, setToteId] = useState('');
    const [noOfTotesScanned, setNoOfTotesScanned] = useState(0);
    const [dropzoneId, setDropzoneId] = useState('');
    const [updateTaskStatus, mapGtlPutawayStation] = [
        useUpdateGtlTaskStatus(),
        useMapGtlPutawayStation(),
    ];
    const taskId = JSON.parse(localStorage.getItem('gtlTaskId') || '');

    const toteList: toteSchema[] = JSON.parse(localStorage.getItem('gtlToteIds') || '[]');
    const toteIds: string[] = toteList.map((item) => item.tote_code);

    const handleClick = () => {
        if (toteList.length > 0 && toteIds.includes(toteId)) {
            mapGtlPutawayStation.mutate(
                {
                    locationId: dropzoneId ?? '',
                    toteId,
                },
                {
                    onSuccess: () => {
                        const toteCount = noOfTotesScanned + 1;
                        setNoOfTotesScanned(toteCount);

                        if (toteIds.length === toteCount) {
                            updateTaskStatus.mutate(
                                { taskId, status: 'COMPLETED' },
                                {
                                    onSuccess: () => {
                                        setNoOfTotesScanned(0);
                                        navigate('../gtl-task-completed');
                                    },
                                }
                            );
                        }
                    },
                }
            );
        } else {
            Notifications.toast.error({
                title: 'Error',
                description: 'Wrong tote.',
            });
        }
    };
    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <PageHeader
                showHelpIcon
                showBackIcon={true}
                title="Drop zone"
                onBackIconClick={() => {
                    navigate(-1);
                }}
            />
            <main className={cn('mx-2 mt-4 space-y-6')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount={false}
                    inputProps={manualInputToteProps}
                    onSuccess={(value) => {
                        setToteId(value);
                    }}
                />
                <ScanOrEnterManuallyV2
                    inputProps={manualInputDropProps}
                    onSuccess={(value) => {
                        setDropzoneId(value);
                    }}
                />
                <div className={cn('fixed inset-x-2 bottom-8 mx-4 space-y-2')}>
                    <Button
                        fullWidth
                        disabled={!(toteId && dropzoneId)}
                        kind="primary"
                        onClick={() => handleClick()}
                    >
                        Submit
                    </Button>
                </div>
            </main>
        </div>
    );
};

export default ScanToteAndDropzone;
