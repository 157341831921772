import { cn } from '../../../utils/tailwind';
import { Divider, Icon, Notifications, Spinner, Text } from '@jds/core';
import { IcOrder } from '@jds/extended-icons';
import { usePackagesBySecondaryCase } from '../../../hooks/receiving/usePackages';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductCard } from '../../../components/shared/ProductCard';
import { useContext } from 'react';
import { ReceivingContext } from '../../../provider/inbound/receiving';
import { useCompatibleSKUs } from '../../../hooks/returns/receiving/useCompatibleSKUs';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';

const enterManuallyInputProps = { name: 'primary-case', label: 'Primary Case ID', type: 'text' };
export function CompatibleSKUsPage() {
    const navigate = useNavigate();
    const { completedPrimaryCases, storageZoneId } = useContext(ReceivingContext).state;
    const packagesBySecondaryCase = usePackagesBySecondaryCase().packages ?? [];
    const uncompletedPrimaryCase =
        packagesBySecondaryCase.filter(
            ({ packageLabel }) => !completedPrimaryCases.includes(packageLabel)
        ) ?? [];
    const { data: skuList, isLoading } = useCompatibleSKUs(storageZoneId, uncompletedPrimaryCase);
    const { stationId, appointmentNumber, secondaryCaseId } = useParams();

    function onScanSuccess(primaryCaseId: string) {
        const pathname = `/inbound/receiving/${stationId}/${appointmentNumber}/${secondaryCaseId}/${primaryCaseId}`;
        const isAlreadyCompleted = completedPrimaryCases.includes(primaryCaseId);
        const primaryCase = uncompletedPrimaryCase.find(
            ({ packageLabel }) => packageLabel === primaryCaseId
        );

        if (isAlreadyCompleted) {
            Notifications.toast.error({
                title: 'Error',
                description: 'Primary case already completed',
            });
        } else if (!primaryCase) {
            Notifications.toast.error({
                title: 'Error',
                description: 'Primary case not found',
            });
        } else navigate(pathname);
    }

    return (
        <div className={cn('mx-6 max-w-screen-md py-3 md:mx-auto')}>
            <div className={cn('mb-8 flex items-center space-x-3')}>
                <Icon color="grey-80" ic={<IcOrder />} />
                <Text appearance="body-m" color="primary-grey-80">
                    Receiving
                </Text>
            </div>
            <ScanOrEnterManuallyV2 inputProps={enterManuallyInputProps} onSuccess={onScanSuccess} />
            <div className={cn('-mx-6')}>
                <Divider padding="m" />
            </div>
            <div className={cn('space-y-4')}>
                <div className={cn('flex items-center justify-between')}>
                    <Text appearance="body-s-bold" color="primary-grey-80">
                        Compatible SKUs
                    </Text>
                    <Text appearance="body-xxs" color="primary-grey-80">
                        {stationId}
                    </Text>
                </div>
                {isLoading && (
                    <div className={cn('flex justify-center')}>
                        <Spinner size="small" />
                    </div>
                )}
                <ul className={cn('space-y-4')}>
                    {skuList?.map(
                        ({ bestBefore, ean, manufacturedDate, mrp, colour, size, imageUrls }) => (
                            <li key={ean}>
                                <ProductCard
                                    BBD={bestBefore}
                                    EAN={ean}
                                    MFD={manufacturedDate}
                                    MRP={mrp}
                                    colorSize={`${colour ?? '_'}, ${size ?? '_'}`}
                                    image={imageUrls?.at(0)}
                                />
                            </li>
                        )
                    )}
                    {skuList?.length === 0 && !isLoading && (
                        <li>
                            <Text appearance="body-xs" color="primary-grey-80">
                                No SKUs found
                            </Text>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}
