import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Icon, Notifications, Text } from '@jds/core';
import { useQueryClient } from '@tanstack/react-query';
import { CompatibleListSchema } from '../../../hooks/putaway/useCompatibleTotesList';
import { cn } from '../../../utils/tailwind';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useToteProductsList } from '../../../hooks/putaway/useToteProductsList';
import { IcOrder } from '@jds/extended-icons';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';

const manualInputProps = { name: 'tote', label: 'Tote Code', type: 'text' };
export function ScanTote() {
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname.split('/', 3).join('/');
    const isTotePutawayComplete = localStorage.getItem('TotePutawayComplete');
    const { stationId } = useParams();
    const queryClient = useQueryClient();

    const compatibleTotes =
        queryClient.getQueryData<Array<CompatibleListSchema>>(['compatibletotes-list']) || []; // change this any later
    const inCompatibleTotes =
        queryClient.getQueryData<Array<CompatibleListSchema>>(['incompatibletotes-list']) || [];

    const remainingTotes = compatibleTotes.concat(inCompatibleTotes);

    const totesList =
        remainingTotes?.length > 0
            ? remainingTotes
            : queryClient.getQueryData<any>(['generated-totes-list']);
    const toteCodeKeys = totesList?.map((item: { tote_code: string }) => ({
        result: item.tote_code,
    }));
    const toteProductsList = useToteProductsList();

    const handleScanTote = (toteId: string) => {
        toteProductsList.mutate(toteId, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    const items = res.data;
                    const remainingItems = items.filter((item: any) => item.status === 'ACTIVE');
                    const completedItems = items.filter((item: any) => item.status === 'INACTIVE');
                    queryClient.setQueryData(['tote-active-products'], remainingItems);
                    queryClient.setQueryData(['tote-completed-products'], completedItems);
                    localStorage.removeItem('JioCodePutawayComplete');
                    navigate(`${routePrefix}/${stationId}/${toteId}/scan-jiocode`);
                }
            },
            onError(err: any) {
                Notifications.toast.error({
                    title: 'Error',
                    description: err?.response?.data?.meta?.errors
                        ? err.response.data.meta.errors.join(', ')
                        : err.message,
                });
            },
        });
    };

    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Scan Tote"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        inputProps={manualInputProps}
                        searchableList={toteCodeKeys}
                        onSuccess={(value) => handleScanTote(value)}
                    />
                </div>
            </div>
            <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                <main className={cn('mt-4')}>
                    {isTotePutawayComplete === 'true' && (
                        <div>
                            <div className={cn('mx-3 mt-3')}>
                                {compatibleTotes.length >= 0 && (
                                    <>
                                        <div className={cn('flex items-center space-x-4')}>
                                            <Text
                                                appearance="body-s-bold"
                                                color="secondary-grey-100"
                                            >
                                                Compatible Totes
                                            </Text>
                                            <Text
                                                appearance="body-s-bold"
                                                color="secondary-grey-100"
                                            >
                                                {compatibleTotes.length}
                                            </Text>
                                        </div>
                                        <div className={cn('mt-2 space-y-4')}>
                                            {compatibleTotes.map((id) => (
                                                <Container
                                                    key={id.tote_code}
                                                    className={cn(
                                                        'grid grid-cols-2 space-x-3 rounded-lg border border-[var(--color-secondary-grey-60)] p-4'
                                                    )}
                                                >
                                                    <Icon
                                                        className={cn(
                                                            'absolute left-4 mt-2 bg-white'
                                                        )}
                                                        color="grey-60"
                                                        ic={<IcOrder />}
                                                    />
                                                    <Text appearance="body-xs">Tote ID</Text>
                                                    <Text
                                                        appearance="body-xs"
                                                        className={cn('!ml-6 !text-[#FFAA66]')}
                                                    >
                                                        Storage Zone
                                                    </Text>
                                                    <Text
                                                        appearance="body-s-bold"
                                                        className={cn('ml-5')}
                                                    >
                                                        {id.tote_code}
                                                    </Text>
                                                    <Text
                                                        appearance="body-s-bold"
                                                        className={cn('!ml-6 !text-[#FFAA66]')}
                                                    >
                                                        {id.storage_zone}
                                                    </Text>
                                                    <div
                                                        className={cn(
                                                            'absolute right-6 mt-[-17px] h-[78px] w-[11px] rounded-r-lg bg-[#FFAA66]'
                                                        )}
                                                    />
                                                </Container>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={cn('mx-3 mt-10')}>
                                {inCompatibleTotes.length >= 0 && (
                                    <>
                                        <div className={cn('flex items-center space-x-4')}>
                                            <Text
                                                appearance="body-s-bold"
                                                color="secondary-grey-100"
                                            >
                                                Incompatible Totes
                                            </Text>
                                            <Text
                                                appearance="body-s-bold"
                                                color="secondary-grey-100"
                                            >
                                                {inCompatibleTotes.length}
                                            </Text>
                                        </div>
                                        <div className={cn('mt-2 space-y-4')}>
                                            {inCompatibleTotes.map((id) => (
                                                <Container
                                                    key={id.tote_code}
                                                    className={cn(
                                                        'grid grid-cols-2 space-x-3 rounded-lg border border-[var(--color-secondary-grey-60)] p-4'
                                                    )}
                                                >
                                                    <Icon
                                                        className={cn(
                                                            'absolute left-4 mt-2 bg-white'
                                                        )}
                                                        color="grey-60"
                                                        ic={<IcOrder />}
                                                    />
                                                    <Text appearance="body-xs">Tote ID</Text>
                                                    <Text
                                                        appearance="body-xs"
                                                        className={cn('!ml-6 !text-[#FFAA66]')}
                                                    >
                                                        Storage Zone
                                                    </Text>
                                                    <Text
                                                        appearance="body-s-bold"
                                                        className={cn('ml-5')}
                                                    >
                                                        {id.tote_code}
                                                    </Text>
                                                    <Text
                                                        appearance="body-s-bold"
                                                        className={cn('!ml-6 !text-[#FFAA66]')}
                                                    >
                                                        {id.storage_zone}
                                                    </Text>
                                                    <div
                                                        className={cn(
                                                            'absolute right-6 mt-[-17px] h-[78px] w-[11px] rounded-r-lg bg-[#FFAA66]'
                                                        )}
                                                    />
                                                </Container>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </main>
            </div>
        </>
    );
}
