import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate } from 'react-router-dom';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Notifications } from '@jds/core';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';

export function ScanDropZone() {
    const navigate = useNavigate();

    const validateInventory = useInventoryDetails();

    const onSubmit = (dropZoneId: string) => {
        validateInventory.mutate(
            { inventoryCode: dropZoneId, type: 'STOCK_TAKE_DROP_ZONE' },
            {
                onSuccess: () => navigate(`${dropZoneId}/scan-tote`),
            }
        );
    };

    return (
        <div className={cn('mt-3 max-w-screen-md md:mx-auto')}>
            <PageHeader
                showBackIcon
                title="Stock take- Drop flow"
                onBackIconClick={() => navigate(-1)}
            />
            <main className={cn('mx-6 mt-6')}>
                <ScanOrEnterManuallyV2
                    inputProps={{ label: 'Drop Zone ID', name: 'drop-zone-id' }}
                    onSuccess={(dropZoneId) => {
                        if (dropZoneId) onSubmit(dropZoneId);
                        else {
                            Notifications.toast.error({
                                title: 'Drop Zone ID is required',
                                description: 'Please scan or enter a valid Drop Zone ID',
                            });
                        }
                    }}
                />
            </main>
        </div>
    );
}
