import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { urls } from '../../../utils/urls';
import { ErrorResponse, SuccessResponse } from './useGetPickList';

export interface TrolleyAndToteValidationSchema {
    trolleyCode: string;
    toteCode: string;
    trolleyToteStatus: string;
    lastUpdatedAt: string;
    lastScannedAt: string;
}

export function useTrolleyAndToteValidation() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, TrolleyAndToteValidationSchema>(
        async (body: TrolleyAndToteValidationSchema) => {
            const response = await axios.post(urls.VALIDATE_TROLLEY_AND_TOTE_STATUS(), body);
            return response.data;
        }
    );
}
