import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useScanner } from '../../hooks/EB/useScanner';
import { Container, debounce, Icon, Input, Text } from '@jds/core';
import { IcKeyboard } from '@jds/extended-icons';
import ScannerGif from '../../assets/scan_barcode.png';
import { cn } from '../../utils/tailwind';
import { urls } from '../../utils/urls';
import { IcClose } from '@jds/core-icons';

interface ScanOrEnterManuallyV2Props {
    onSuccess: (value: string) => void;
    inputProps: { label: string; name: string; disabled?: boolean };
    enableScannerOnMount?: boolean;
    type?: string;
    purpose?: string;
    stage?: string;
    searchableList?: { result: string }[];
    autoSuggest?: boolean;
    optionalValues?: { station_id: string };
}
const MOUNT_SCANNER_TIMEOUT = 15000;
export function ScanOrEnterManuallyV2({
    onSuccess,
    inputProps,
    enableScannerOnMount = false,
    type = '',
    stage = 'ADMIN',
    purpose = '',
    searchableList = [],
    autoSuggest = false,
    optionalValues = { station_id: '' },
}: ScanOrEnterManuallyV2Props) {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(true);
    const isRendered = useRef(false);
    const scanner = useScanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getAutoSuggestions = (value: string) => {
        switch (stage) {
            case 'ADMIN':
                fetch(urls.GET_ADMIN_AUTO_SUGGESTIONS(type, value, purpose, optionalValues))
                    .then((res) => res.json())
                    .then((data: any) => {
                        if (data?.data) {
                            setSuggestions([...data.data.result_list.slice(0, 5)]);
                        }
                    });
                break;
            case 'INBOUND':
                fetch(urls.GET_INBOUND_AUTO_SUGGESTIONS(type, value, purpose))
                    .then((res) => res.json())
                    .then((data: any) => {
                        if (data?.data) {
                            const resultKeys = data.data.suggestions
                                .slice(0, 5)
                                .map((item: { id: string }) => ({
                                    result: item.id,
                                }));
                            setSuggestions([...resultKeys]);
                        }
                    });
                break;
            case 'RETURNS':
                fetch(urls.GET_RETURNS_AUTO_SUGGESTIONS(type, value))
                    .then((res) => res.json())
                    .then((data: any) => {
                        if (data?.data) {
                            const resultKeys = data.data.suggestions
                                .slice(0, 5)
                                .map((item: { text: string }) => ({
                                    result: item.text,
                                }));
                            setSuggestions([...resultKeys]);
                        }
                    });
                break;
            case 'OUTBOUND':
                if (purpose === 'CONSOLIDATION' || (value?.length > 4 && value?.[4] === '_')) {
                    const containerType =
                        value?.[5] === 'T' ? 'TOTE' : value?.[5] === 'C' ? 'CRATE' : '';
                    if (purpose === 'CONSOLIDATION' || containerType !== '') {
                        fetch(urls.GET_OUTBOUND_AUTO_SUGGESTIONS(), {
                            method: 'post',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                container_id: value,
                                rfc_code: sessionStorage.getItem('rfc-code'),
                                container_type: containerType,
                                process_name: purpose,
                                limit: 5,
                            }),
                        })
                            .then((res) => res.json())
                            .then((data: any) => {
                                if (data?.data) {
                                    const resultKeys = data.data
                                        .slice(0, 5)
                                        .map((item: string) => ({
                                            result: item,
                                        }));
                                    setSuggestions([...resultKeys]);
                                }
                            });
                    }
                }
                break;
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optimizedSearch = useCallback(debounce(getAutoSuggestions, 500), []);
    const onChangeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setInputValue(value.toUpperCase());
        if (autoSuggest) {
            if (value && type !== '') {
                optimizedSearch(event.target.value.toUpperCase());
                setShowSuggestions(true);
            } else if (value && searchableList?.length !== 0) {
                handleSearchResults(event.target.value.toUpperCase());
                setShowSuggestions(true);
            }
        } else {
            setShowSuggestions(false);
        }
    };

    const handleSearchResults = (target: string) => {
        if (target) {
            const filteredOptions = searchableList.filter((option) =>
                option?.result?.toLowerCase()?.startsWith(target?.toLowerCase())
            );
            setSuggestions([...filteredOptions.slice(0, 5)]);
        } else {
            setSuggestions([]);
        }
    };

    // useEffect(() => {
    //     if (enableScannerOnMount && !isRendered.current) {
    //         isRendered.current = true;
    //         scanner.scan((value) => {
    //             onSuccess(value.toUpperCase());
    //             setInputValue(value.toUpperCase());
    //         }, MOUNT_SCANNER_TIMEOUT);
    //     }
    // }, [onSuccess, enableScannerOnMount, scanner]);

    return (
        <Container>
            <div
                className={cn('relative grid !bg-[length:20px_20px] !bg-no-repeat p-1')}
                style={{
                    background:
                        'linear-gradient(to right, white 1.5px, transparent 1.5px) 0 0, linear-gradient(to right, white 1.5px, transparent 1.5px) 0 100%,linear-gradient(to left, white 1.5px, transparent 1.5px) 100% 0,linear-gradient(to left, white 1.5px, transparent 1.5px) 100% 100%,linear-gradient(to bottom, white 1.5px, transparent 1.5px) 0 0,linear-gradient(to bottom, white 1.5px, transparent 1.5px) 100% 0,linear-gradient(to top, white 1.5px, transparent 1.5px) 0 100%,linear-gradient(to top, white 1.5px, transparent 1.5px) 100% 100%',
                }}
            >
                <img src={ScannerGif} />
                <div
                    className={cn(
                        'animate-scan bg-scanner-gradient absolute w-full text-center text-white'
                    )}
                >
                    Scan
                </div>
            </div>
            <Input
                className={cn('m-4 !mt-8')}
                suffix={
                    <Icon
                        aria-label="manual-entry"
                        ic={
                            inputValue ? (
                                <IcClose
                                    className={cn(
                                        '!h-[20px] !w-[20px] rounded-3xl border bg-[#00000054] p-0.5'
                                    )}
                                    color="white"
                                    onClick={() => {
                                        setInputValue('');
                                        setShowSuggestions(false);
                                    }}
                                />
                            ) : (
                                <IcKeyboard className={cn('text-[var(--color-primary-40)]')} />
                            )
                        }
                    />
                }
                value={inputValue}
                onChange={onChangeValue}
                // onFocus={() => {
                //     scanner.scan((value) => {
                //         onSuccess(value);
                //         setInputValue(value);
                //     });
                // }}
                onKeyDown={(event) => {
                    if (event.keyCode === 13) onSuccess(inputValue);
                }}
                // onSuffixClick={() => {
                //     scanner.scan((value) => {
                //         onSuccess(value);
                //         setInputValue(value);
                //     });
                // }}
                {...inputProps}
            />
            {showSuggestions &&
                suggestions?.map((suggestion: any) => (
                    <Container
                        key={suggestion.result}
                        className={cn('my-2 flex border-b border-solid border-[#D1D1ED] p-4')}
                        onClick={() => {
                            setInputValue(suggestion.result);
                            setShowSuggestions(false);
                            onSuccess(suggestion.result);
                        }}
                    >
                        <Text color="primary-30">{suggestion.result} </Text>
                    </Container>
                ))}
        </Container>
    );
}
