import { createContext, Dispatch, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import { ReceivingAction, receivingReducer, ReceivingState } from './ReceivingReducer';

export const initialReceivingState: ReceivingState = {
    quantityStatus: {
        goodQuantity: 0,
        DAMAGE_A: 0,
        DAMAGE_A_PLUS: 0,
        DAMAGE_B: 0,
        DAMAGE_C: 0,
        EXPIRED_PRODUCT: 0,
        FAKE_PRODUCT: 0,
        IR_STAR: 0,
        LOW_FRESHNESS: 0,
        liquidationQuantity: 0,
        scrapQuantity: 0,
    },
    assignedTotes: [],
    completedQuantities: [],
    completedJioCodes: [],
    storageZoneId: '',
};

interface ReceivingContextType {
    state: ReceivingState;
    dispatch: Dispatch<ReceivingAction>;
}

export const ReceivingContext = createContext<ReceivingContextType>({
    state: initialReceivingState,
    dispatch: () => {},
});

export function ReceivingProvider() {
    const [state, dispatch] = useReducer(receivingReducer, initialReceivingState);

    return (
        <ReceivingContext.Provider value={{ state, dispatch }}>
            <Outlet />
        </ReceivingContext.Provider>
    );
}
