import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface UpdateDefectedProductsSchema {
    defected_quantity: number;
    reason: string;
    order_id: string;
    jio_code: string;
    shipment_id?: string;
    defectedDuringPacking?: boolean;
}

export function useUpdateDefectedProducts() {
    return useMutation(async (data: UpdateDefectedProductsSchema) => {
        const response = await axios.post(urls.UPDATE_DEFECTED_PRODUCTS(), data);
        return response.data;
    });
}
