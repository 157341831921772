import { ComponentPropsWithoutRef, RefObject } from 'react';
import { Input } from '@jds/core';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type JDSInputProps = ComponentPropsWithoutRef<typeof Input>;
interface ControlledInputProps<T extends FieldValues> extends JDSInputProps {
    control: Control<T>;
    name: Path<T>;
    rules?: ComponentPropsWithoutRef<typeof Controller<T>>['rules'];
}
export function ControlledInput<T extends FieldValues>({
    control,
    name,
    rules,
    ...inputProps
}: ControlledInputProps<T>) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Input
                    forwardRef={ref as unknown as RefObject<HTMLInputElement>}
                    state={error ? 'error' : undefined}
                    stateConfig={{ errorText: error?.message }}
                    {...inputProps}
                    {...field}
                />
            )}
            rules={rules}
        />
    );
}
