import { cn } from '../../../utils/tailwind';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Icon, Notifications, Space, Text } from '@jds/core';
import { useState } from 'react';
import { useCurrentPackage } from '../../../hooks/receiving/usePackages';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Package } from '../../../types/receiving';
import { differenceInDays, format, isValid } from 'date-fns';
import { ProductDetailsHeader } from '../../../components/receiving/ProductDetailsHeader';
import { ControlledInput, ControlledDateInput } from '../../../components/shared/jds';
import { ProductImage } from '../../../components/receiving/ProductImage';
import { useSaveProductDetails } from '../../../hooks/receiving/useSaveProductDetails';
import { useCubiscanCheck } from '../../../hooks/receiving/useCubiscanCheck';
import { IcKiranaStore } from '@jds/extended-icons';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';

const productFormSchema = yup.object({
    ean: yup.string(),
    image: yup.string(),
    description: yup.string(),
    mrp: yup.number().required().min(0).typeError('MRP must be a number'),
    mfd: yup
        .string()
        .required()
        .test({
            test(dateString) {
                const date = new Date(dateString);
                // JDS input widget throws error if date is before 1970
                return isValid(date) && Number(format(date, 'yyyy')) >= 1970;
            },
            message: 'MFD must be a valid date',
        }),
    bestBefore: yup
        .string()
        .required()
        .test({
            test(dateString) {
                const date = new Date(dateString);
                // JDS input widget throws error if date is before 1970
                return isValid(date) && Number(format(date, 'yyyy')) >= 1970;
            },
            message: 'BBD must be a valid date',
        }),
    color: yup
        .string()
        .matches(/^[A-Za-z]+$/, { message: 'Enter valid color' }) // match only alphabets
        .required(),
    size: yup.string().required(),
    expectedQuantity: yup.number(),
});

export type LowFreshnessOrExpired = 'LOW_FRESHNESS' | 'EXPIRED' | 'NONE';
function isLowFreshnessOrExpired({ manufacturedDate, bestBefore }: Package): LowFreshnessOrExpired {
    const FRESHNESS_THRESHOLD = 80;
    const shelfLifeInDays = differenceInDays(bestBefore, manufacturedDate);
    const currentDate = new Date();
    const totalDaysFromExpiry = differenceInDays(bestBefore, currentDate);
    if (totalDaysFromExpiry < 0) return 'EXPIRED';
    const freshnessPercentage = (totalDaysFromExpiry / shelfLifeInDays) * 100;
    if (freshnessPercentage < FRESHNESS_THRESHOLD) return 'LOW_FRESHNESS';
    return 'NONE';
}

function getDefaultValue(product?: Package): ProductFormSchema | undefined {
    if (!product) return;
    return {
        ean: product.ean,
        image: product.imageUrls?.at(0) ?? 'https://via.placeholder.com/125',
        description: product.description ?? '',
        mfd: format(product.manufacturedDate, 'yyyy-MM-dd'),
        bestBefore: format(product.bestBefore, 'yyyy-MM-dd'),
        mrp: product.mrp,
        color: product.colour ?? '',
        size: product.size ?? '',
        expectedQuantity: product.itemQuantity,
    };
}

export type ProductFormSchema = yup.InferType<typeof productFormSchema>;
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ProductDetailsPage() {
    const navigate = useNavigate();
    const saveProductDetails = useSaveProductDetails();
    const [isEditMode, setIsEditMode] = useState(false);
    const currentPackage = useCurrentPackage();
    const cubiscanCheck = useCubiscanCheck();
    const { handleSubmit, control, watch } = useForm<ProductFormSchema>({
        resolver: yupResolver(productFormSchema),
        defaultValues: getDefaultValue(currentPackage),
    });

    function onFormSubmit(formValues: ProductFormSchema) {
        saveProductDetails.mutate(formValues, {
            onSuccess() {
                Notifications.toast.success({
                    title: 'Product details saved',
                    description: 'Product details saved successfully',
                });
                setIsEditMode(false);
            },
        });
    }

    function onCubiscanCheckSuccess(isRequired: boolean) {
        if (!currentPackage) return;
        const lowFreshnessOrExpired = isLowFreshnessOrExpired(currentPackage);
        const queryPrams = new URLSearchParams();
        queryPrams.append('cubiscanRequired', isRequired.toString());
        queryPrams.append('isLowFreshnessOrExpired', lowFreshnessOrExpired);
        navigate(`verify-quantities?${queryPrams.toString()}`);
    }

    return (
        <form className={cn(layoutStyles, 'pb-3')} onSubmit={handleSubmit(onFormSubmit)}>
            <div className={cn([isEditMode ? 'rounded-b-2xl bg-zinc-100' : pageHeaderStyle])}>
                <PageHeaderV2
                    isExtended
                    title="EANs"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <div className={cn('mx-7 mt-3 pb-3')}>
                    <ProductDetailsHeader
                        isEditMode={isEditMode}
                        isLoading={saveProductDetails.isLoading}
                        setIsEditMode={setIsEditMode}
                    />
                </div>
            </div>
            <Space value="m" />
            <div className={cn('mx-5 mt-2 mb-20')}>
                {isEditMode && (
                    <fieldset disabled={!isEditMode}>
                        <Container as="div" background="primary-grey-20" pad="base" rounded="large">
                            <ControlledInput
                                control={control}
                                disabled={isEditMode}
                                label="EAN"
                                name="ean"
                                placeholder="Enter EAN"
                                type="text"
                            />
                        </Container>
                    </fieldset>
                )}
                {!isEditMode && (
                    <Container
                        className={cn(
                            'm-1 grid grid-cols-2 space-x-4 rounded-lg border-2 border-[var(--color-primary-60)] p-3'
                        )}
                    >
                        <Icon
                            className={cn('absolute left-3 bg-white')}
                            ic={<IcKiranaStore color="#000093" />}
                        />
                        <Text appearance="body-s"> EAN </Text>
                        <Text appearance="body-s-bold" className={cn('flex justify-center')}>
                            {' '}
                            {control._formValues['ean']}{' '}
                        </Text>
                    </Container>
                )}
                <Space value="m" />
                <ProductImage image={watch('image')} />
                <fieldset disabled={!isEditMode}>
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        disabled={isEditMode}
                        label="Description"
                        name="description"
                        placeholder="Enter Description"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="MRP"
                        name="mrp"
                        placeholder="Enter MRP"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledDateInput
                        control={control}
                        editMode={isEditMode}
                        label="MFD Date"
                        name="mfd"
                        placeholder="Enter MFD Date"
                    />
                    <Space value="m" />
                    <ControlledDateInput
                        control={control}
                        editMode={isEditMode}
                        label="Best Before"
                        name="bestBefore"
                        placeholder="Enter BBD Date"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="Color"
                        name="color"
                        placeholder="Enter color of the product"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="Size"
                        name="size"
                        placeholder="Enter size of the product"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        disabled={isEditMode}
                        inputMode="numeric"
                        label="Expected Quantity"
                        name="expectedQuantity"
                        placeholder="Enter quantity"
                        type="number"
                    />
                </fieldset>
            </div>
            {!isEditMode && (
                <div
                    className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                    style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
                >
                    <Button
                        fullWidth
                        size="large"
                        type="button"
                        onClick={() => {
                            cubiscanCheck.mutate(undefined, {
                                onSuccess: onCubiscanCheckSuccess,
                            });
                        }}
                    >
                        All Units are Verified
                    </Button>
                </div>
            )}
        </form>
    );
}
