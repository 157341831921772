import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { PickListItemProps } from '../../../components/picklist/PickListItem';
import { Notifications } from '@jds/core';

export interface PickListResponse {
    data: PickListItemProps[];
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useGetPickList() {
    const { data, ...rest } = useQuery<PickListResponse>({
        queryKey: ['pickList'],
        queryFn: async () => {
            const response = await axios.get(urls.GET_PICK_LIST(), {
                headers: {
                    instanceCode: sessionStorage.getItem('rfc-code'),
                },
            });
            return response.data;
        },
        retry: false,
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });

    return { pickList: data?.data ?? [], ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
