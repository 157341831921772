import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface RequireAuthProps {
    /**
     * Should the user be logged in to access this route?
     */
    shouldUserBeLoggedIn?: boolean;
}
export function RequireAuth({ shouldUserBeLoggedIn = true }: RequireAuthProps) {
    const token = sessionStorage.getItem('user-auth-token');
    const location = useLocation();
    if (shouldUserBeLoggedIn && !token) return <Navigate state={{ from: location }} to="/login" />;
    if (!shouldUserBeLoggedIn && token) return <Navigate state={{ from: location }} to="/" />;
    return <Outlet />;
}
