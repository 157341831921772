import { cn } from '../../utils/tailwind';
import { Heading, Button, Icon } from '@jds/core';
import { IcSuccess } from '@jds/core-icons';

const layoutStyles = 'max-w-screen-md md:mx-auto';

interface SuccessPageProps {
    /**
     * Callback function that will be called onsuccess
     */
    onSuccess: (value: string) => void;
    /**
     * Success text for the page that will be displayed on top of the page
     */
    successMessage: string;
    /**
     * Button Text that will be displayed on the button of the modal
     */
    buttonText: string;
}

export function SuccessPage({ onSuccess, successMessage, buttonText }: SuccessPageProps) {
    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <div className={cn('mx-4 mt-3 flex')}>
                <Icon color="primary" ic={<IcSuccess />} size="l" />
                <Heading appearance="heading-xs" className={cn('ml-2')} color="primary-50">
                    {successMessage}
                </Heading>
            </div>
            <main className={cn('mx-3 mt-2 mb-20')}>
                <span className={cn('inset-x-2 bottom-4 mx-4')}>
                    <Button
                        fullWidth
                        kind="secondary"
                        onClick={() => {
                            onSuccess('clicked');
                        }}
                    >
                        {buttonText}
                    </Button>
                </span>
            </main>
        </div>
    );
}
