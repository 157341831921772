import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface AssignCrateSchema {
    ptw_location: string;
    order_id: string;
    crate_id: string;
    type: string;
    shipment_id?: string;
}

export function useAssignCrate() {
    return useMutation(async (data: AssignCrateSchema) => {
        const body = {
            ptw_location: data.ptw_location,
            order_id: data.order_id,
            crate_id: data.crate_id,
            type: data.type,
            shipment_id: data.shipment_id,
        };
        const response = await axios.post(urls.ASSIGN_CRATE(), body);
        return response.data;
    });
}
