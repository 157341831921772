import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { ResponseType } from '../../../types/fetch';
import axios, { AxiosError } from 'axios';
import { ProductDetails } from './useReturnProducts';
import { urls } from '../../../utils/urls';
import { Notifications } from '@jds/core';
import { useReturnOrders } from './useReturnOrders';

interface MutationVariables {
    completedProducts: ProductDetails[];
    uncompletedProducts: ProductDetails[];
}

export function useReturnsUnloading() {
    const { data: returnOrders } = useReturnOrders(false);
    const { appointmentNumber } = useParams();
    if (!appointmentNumber) throw new Error('Appointment ID is not found');
    return useMutation<ResponseType<unknown>, AxiosError<ResponseType<never>>, MutationVariables>({
        mutationFn: async ({ completedProducts, uncompletedProducts }) => {
            const completedPayload = completedProducts.map(({ jio_code, expectedQuantity }) => ({
                jio_code: jio_code,
                quantity: Number(expectedQuantity),
            }));
            const uncompletedPayload = uncompletedProducts.map(({ jio_code }) => ({
                jio_code: jio_code,
                quantity: 0,
            }));
            const body = {
                products: [...completedPayload, ...uncompletedPayload],
                return_order_id: returnOrders?.return_order_id,
            };
            const response = await axios.post(urls.RETURNS_UNLOADING(appointmentNumber), body);
            return response.data;
        },
        onSuccess: () => {
            Notifications.toast.success({
                title: 'Success',
                description: 'Products are submitted successfully',
            });
        },
        onError: (error) => {
            const defaultErrorMessage = 'Something went wrong';
            const messages = error?.response?.data?.meta?.errors ?? [defaultErrorMessage];
            messages.forEach((message) => {
                Notifications.toast.error({
                    title: 'Error',
                    description: message,
                });
            });
        },
    });
}
