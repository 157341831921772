import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface UpdatePackagingSuggestionSchema {
    shipmentId: string;
    packageId: number;
    awbRefNumber: string;
    containerId?: string;
    containerType?: string;
}

export function useUpdatePackingSuggestion() {
    return useMutation(async (data: UpdatePackagingSuggestionSchema) => {
        const body = {
            shipmentId: data.shipmentId,
            packageId: data.packageId,
            awbRefNumber: data.awbRefNumber,
            containerId: data?.containerId,
            containerType: data?.containerType,
            rfcId: sessionStorage.getItem('rfcId'),
        };
        const response = await axios.post(urls.UPDATE_PACKAGING_SUGGESTION(), body);
        return response.data;
    });
}
