import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Text, Space, Heading, Button, Notifications, Spinner, Icon } from '@jds/core';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { useContext, useState } from 'react';
import {
    AssignJioCodeSchema,
    useAssignJioCode,
} from '../../../hooks/outbound/consolidation/useAssignJioCode';
import {
    AssignCrateSchema,
    useAssignCrate,
} from '../../../hooks/outbound/consolidation/useAssignCrate';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ProductCardV2 } from '../../../components/shared/ProductCardV2';
import { IcKiranaStore } from '@jds/extended-icons';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';
import { useProductDetails } from '../../../hooks/cubiscan/useProductDetails';
import { useConsolidationStateSave } from '../../../hooks/outbound/consolidation/useConsolidationStateSave';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'crate-code', label: 'Crate Code', type: 'text' };
export function ConsolidationLocationPage() {
    const navigate = useNavigate();
    const { stationId, toteCode, jioCode, ptwLocation, shipmentId, crateId } = useParams();
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [consolidationStatus, setConsolidationStatus] = useState('');
    const { state, dispatch } = useContext(ConsolidationContext);
    const saveConsolidationactivity = useConsolidationStateSave();
    const queryParams = useQueryParams();

    const parkedCrateCodesTillNow = state.consolidationParkedCrateCodesList;
    const consolidateJioCodesList = state.consolidationJioCodesList;
    const consolidatedQuantityTillNow = state.consolidationToteScannedQuantity;
    const finalParkedCrateCodeIndex = parkedCrateCodesTillNow?.findIndex(
        (item) => item.shipmentId === shipmentId
    );
    const jioCodeDetailsIndex = consolidateJioCodesList?.findIndex(
        (item) => item.shipmentId === shipmentId && item.jioCode === jioCode
    );
    const { data: product, isLoading } = useProductDetails(
        jioCode ?? '',
        String(consolidateJioCodesList[jioCodeDetailsIndex]?.lotId) ?? ''
    );

    const assignJioCode = useAssignJioCode();
    const assignJioCodeOnSubmit = (data: AssignJioCodeSchema) => {
        assignJioCode.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    const quantity =
                        consolidateJioCodesList[jioCodeDetailsIndex].scannedQuantity ?? 0;
                    consolidateJioCodesList[jioCodeDetailsIndex].scannedQuantity = quantity + 1;
                    dispatch({
                        type: 'SET_CONSOLIDATION_JIOCODES_LIST',
                        payload: consolidateJioCodesList,
                    });
                    dispatch({
                        type: 'SET_CONSOLIDATION_SCANNED_TOTE_QUANTITY',
                        payload: consolidatedQuantityTillNow + 1,
                    });
                    handleCrateStateSave(data);
                    setConsolidationStatus(res.data.consolidationStatus);
                    if (
                        res.data.consolidationStatus === 'CONSOLIDATED' &&
                        parkedCrateCodesTillNow.length > 0
                    ) {
                        if (finalParkedCrateCodeIndex === -1)
                            parkedCrateCodesTillNow.push({
                                crateIdList: [data.crate_id],
                                shipmentId: shipmentId || '',
                            });
                        else
                            parkedCrateCodesTillNow[finalParkedCrateCodeIndex].crateIdList.push(
                                data.crate_id
                            );
                        dispatch({
                            type: 'SET_CONSOLIDATION_PARKED_CRATECODES',
                            payload: parkedCrateCodesTillNow,
                        });
                        navigate(
                            `/outbound/consolidation/${stationId}/${toteCode}/${res.data.shipmentId}/success`
                        );
                    } else if (res.data.consolidationStatus === 'CONSOLIDATED') {
                        setShowSuccessPopup(true);
                    } else {
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
                    }
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', '),
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const assignCrate = useAssignCrate();
    const assignCrateOnSubmit = (data: AssignCrateSchema) => {
        assignCrate.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    if (finalParkedCrateCodeIndex === -1)
                        parkedCrateCodesTillNow.push({
                            crateIdList: [data.crate_id],
                            shipmentId: shipmentId || '',
                        });
                    else
                        parkedCrateCodesTillNow[finalParkedCrateCodeIndex].crateIdList.push(
                            data.crate_id
                        );
                    dispatch({
                        type: 'SET_CONSOLIDATION_PARKED_CRATECODES',
                        payload: parkedCrateCodesTillNow,
                    });

                    setConsolidationStatus('');
                    setShowSuccessPopup(true);
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', '),
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    function handleCrateStateSave(data: AssignJioCodeSchema) {
        saveConsolidationactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_CRATE_CONSOLIDATION',
                status: 'COMPLETED',
                toteId: toteCode,
                shipmentId: data.shipment_id,
                jioCode: data.jio_code,
                quantity: 1,
                lotId: data.lotId,
                locationId: stationId,
                crate_id: data.crate_id,
                ptw_location: data.ptw_location,
            },
            {
                onSuccess: () => {},
            }
        );
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Consolidation Location"
                    onBackIconClick={() => {
                        if (queryParams.get('pendingActivity') === 'true') {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Unable to navigate back',
                            });
                        } else {
                            navigate(`../${stationId}/${toteCode}/main`);
                        }
                    }}
                />
                <div className={cn(layoutStyles, 'mx-3 space-y-2 py-3 pl-8')}>
                    <Text appearance="body-xs" color="primary-grey-80">
                        Crate ID
                    </Text>
                    <Text appearance="body-m-bold" color="primary-grey-100">
                        {crateId}
                    </Text>
                    <Text appearance="body-xs" color="primary-grey-80">
                        Location ID
                    </Text>
                    <Text appearance="body-m-bold" color="primary-60">
                        {ptwLocation}
                    </Text>
                </div>
            </div>

            <main className={cn('mx-3 mt-2')}>
                <Space value="s" />
                <>
                    <Text appearance="body-xs-bold" className={cn('ml-1 mb-2')}>
                        Scanned Jio Code
                    </Text>
                    {isLoading && <Spinner className={cn('flex justify-center')} size="small" />}
                    <div className={cn('my-5')}>
                        <ProductCardV2
                            BBD={product?.expiry}
                            EAN={product?.ean ?? '-'}
                            MFD={product?.mfg}
                            MRP={product?.mrp ?? 0}
                            colorSize={`${product?.color ?? '-'}, ${product?.size ?? '-'}`}
                            image={product?.image_urls?.[0]}
                            jioCode={product?.jio_code}
                            quantity={product?.expectedQuantity}
                            title={product?.product_title ?? '-'}
                        />
                    </div>
                </>
                <div className={cn('mb-0')}>
                    <Container
                        className={cn('bg-cronical-gradient mb-6 px-5 pt-6 pb-3')}
                        rounded="medium"
                    >
                        <ScanOrEnterManuallyV2
                            autoSuggest={true}
                            enableScannerOnMount={false}
                            inputProps={manualInputProps}
                            searchableList={[{ result: crateId ?? '' }]}
                            onSuccess={(value) => {
                                if (value === crateId) {
                                    assignJioCodeOnSubmit({
                                        jio_code: jioCode || '',
                                        request_type: 'ASSIGN_ORDER',
                                        crate_id: crateId,
                                        ptw_location: ptwLocation || '',
                                        order_id:
                                            consolidateJioCodesList[jioCodeDetailsIndex]?.orderId,
                                        tote_id: toteCode || '',
                                        shipment_id:
                                            consolidateJioCodesList[jioCodeDetailsIndex]
                                                ?.shipmentId,
                                        index: 0,
                                    });
                                } else {
                                    Notifications.toast.error({
                                        title: 'Invalid Crate Code',
                                        description: 'Please enter a valid crate code',
                                    });
                                }
                            }}
                        />
                    </Container>
                    {parkedCrateCodesTillNow.length > 0 && (
                        <div className={cn('mx-1 mt-2')}>
                            <Text
                                appearance="body-s-bold"
                                className={cn('mx-1 mb-2')}
                                color="primary-grey-100"
                            >
                                Parked crates for this order
                            </Text>
                            <div className={cn('mb-5 pt-0')}>
                                {parkedCrateCodesTillNow[
                                    finalParkedCrateCodeIndex
                                ].crateIdList?.map((crateId) => (
                                    <Container
                                        key={crateId}
                                        className={cn(
                                            'm-1 mb-3 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-3'
                                        )}
                                    >
                                        <Icon
                                            className={cn('absolute left-2 bg-white')}
                                            ic={<IcKiranaStore color="#000093" />}
                                        />
                                        <Text appearance="body-s">Crate ID</Text>
                                        <Text
                                            appearance="body-s-bold"
                                            className={cn('text-center')}
                                            color="primary-grey-80"
                                        >
                                            {crateId}
                                        </Text>
                                    </Container>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <Container
                    background="primary-grey-20"
                    className={cn(
                        'inset-x-0 bottom-0 mx-1 mb-4 space-y-2.5 py-2.5 px-6',
                        layoutStyles
                    )}
                    pad="m"
                    rounded="large"
                >
                    <Text className={cn('mb-4 flex justify-center')}>Is the Crate full ?</Text>
                    <Button
                        fullWidth
                        kind="secondary"
                        size="large"
                        onClick={() =>
                            assignCrateOnSubmit({
                                ptw_location: ptwLocation || '',
                                order_id: consolidateJioCodesList[jioCodeDetailsIndex]?.orderId,
                                crate_id: crateId || '',
                                type: 'FULL',
                                shipment_id:
                                    consolidateJioCodesList[jioCodeDetailsIndex]?.shipmentId || '',
                            })
                        }
                    >
                        Tag new crate
                    </Button>
                </Container>
                {showSuccessPopup && (
                    <InfoModalSection
                        ModalHeader={
                            consolidationStatus === 'CONSOLIDATED'
                                ? 'Order Consolidation completed, Send to pack station'
                                : 'Park this crate and wait for all crates of the order to be completed'
                        }
                        buttonText={consolidationStatus === 'CONSOLIDATED' ? 'Continue' : 'Okay'}
                        infoText=""
                        infoType={consolidationStatus === 'CONSOLIDATED' ? 'SUCCESS' : 'WARNING'}
                        showCloseIcon={false}
                        onSuccess={() => {
                            setShowSuccessPopup(false);
                            if (consolidationStatus === 'CONSOLIDATED')
                                navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
                            else
                                navigate(
                                    `/outbound/consolidation/${stationId}/${toteCode}/${jioCode}/${shipmentId}/${ptwLocation}/new-crate?lotId=${consolidateJioCodesList[jioCodeDetailsIndex]?.lotId}&orderId=${consolidateJioCodesList[jioCodeDetailsIndex]?.orderId}`
                                );
                        }}
                    />
                )}
            </main>
        </div>
    );
}
