import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { urls } from '../../../utils/urls';
import { ErrorResponse, handleError, SuccessResponse } from './useGetPickList';

export interface UpdateJioCodeStatusSchema {
    bin_code: string;
    jio_code: string;
    lot_id: number;
    quantity: number;
    operation_type: string;
}

const defaultErrorMsg = 'Something went wrong.';
export function useUpdateJioCodeStatus() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, UpdateJioCodeStatusSchema>(
        async (body: UpdateJioCodeStatusSchema) => {
            const response = await axios.post(urls.UPDATE_JIOCODE_STATUS(), body);
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
