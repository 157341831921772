import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Notifications, ProgressBar, Text } from '@jds/core';
import { Fragment, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledInput } from '../../../components/shared/jds';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import {
    useUpdateDamagedProducts,
    UpdateDamagedProductsSchema,
} from '../../../hooks/outbound/packing/UseUpdateDamagedProducts';
import { PackingContext } from '../../../provider/outbound/packing';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export const possibleReasons: { label: string; value: keyof MarkShortValidationSchema }[] = [
    { label: 'Short Qty', value: 'shortQuantity' },
];

const markShortValidationSchema = yup.object({
    shortQuantity: yup.number().typeError('').min(0),
});
export type MarkShortValidationSchema = yup.InferType<typeof markShortValidationSchema>;

export function PackingMarkShortPage() {
    const navigate = useNavigate();
    const { stationCode, ContainerCode, packingType } = useParams();
    const { state, dispatch } = useContext(PackingContext);
    const packingOrderInfo = useQueryClient().getQueryData<any>(['packing-order-info']) || [];
    const multiPackingOrderInfo = packingOrderInfo[0];
    const getTotalOrderedQuantity = (data: any[]) => {
        let orderedQty = 0;
        data?.map((item) => {
            const qty = item?.tobePackedQuantity ?? 0;
            orderedQty += qty;
        });
        return orderedQty;
    };
    const totalQuantity =
        packingType === 'Multi'
            ? getTotalOrderedQuantity(multiPackingOrderInfo?.packingOrderItemDetails)
            : 0;

    const { handleSubmit, watch, control } = useForm<MarkShortValidationSchema>({
        resolver: yupResolver(markShortValidationSchema),
    });
    const PackingOrderQuantityTillNow = state.PackingOrderQuantityTillNow;
    const [totalFormQuantity, setTotalFormQuantity] = useState(PackingOrderQuantityTillNow);

    useEffect(() => {
        const subscription = watch(({ shortQuantity }) => {
            const finalQuantity = PackingOrderQuantityTillNow + Number(shortQuantity);
            const isNotValid =
                Number.isNaN(finalQuantity) || finalQuantity < 0 || finalQuantity > totalQuantity;
            setTotalFormQuantity(isNotValid ? 0 : finalQuantity);
        });
        return () => subscription.unsubscribe();
    }, [PackingOrderQuantityTillNow, totalQuantity, watch]);

    const getShortItemsList = () => {
        const cancelledOrderList: {
            cancelled_quantity: number;
            cancellation_reason: string;
            order_id: string;
            jio_code: string;
            shipment_id: string;
            cancelledDuringPacking: boolean;
        }[] = [];
        const packingOrderList = state.packingOrderList;
        packingOrderList.map((item: any) => {
            const leftOverQuantity = item?.scannedQuantity
                ? item.tobePackedQuantity - item.scannedQuantity
                : item?.processedQuantity;
            if (leftOverQuantity > 0) {
                cancelledOrderList.push({
                    cancelled_quantity: leftOverQuantity,
                    cancellation_reason: 'not available',
                    order_id: multiPackingOrderInfo.orderId,
                    jio_code: item.jiocode,
                    shipment_id: multiPackingOrderInfo.shipmentId,
                    cancelledDuringPacking: true,
                });
            }
        });
        return cancelledOrderList;
    };

    function onFormSubmit() {
        handleUpdateDamagedProducts(getShortItemsList());
    }

    const updateDamagedProducts = useUpdateDamagedProducts();
    const handleUpdateDamagedProducts = (data: UpdateDamagedProductsSchema[]) => {
        updateDamagedProducts.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    dispatch({
                        type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
                        payload: totalFormQuantity,
                    });
                    dispatch({ type: 'SET_IS_ORDER_QUANTITY_COMPLETED', payload: true });
                    navigate(
                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/main`
                    );
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    return (
        <form className={cn(layoutStyles)} onSubmit={handleSubmit(onFormSubmit)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2 isExtended title="Mark Short" onBackIconClick={() => navigate(-1)} />

                <main className={cn('mx-6 mt-3 mb-20 pb-12')}>
                    <div className={cn('space-y-2.5')}>
                        <ProgressBar
                            label="Quantity"
                            value={totalFormQuantity / totalQuantity}
                            valueLabel={`${totalFormQuantity} / ${totalQuantity}`}
                        />
                    </div>
                    <div className={cn('mt-6 grid grid-cols-2 items-center gap-y-6')}>
                        {possibleReasons.map(({ label, value }) => (
                            <Fragment key={label + value}>
                                <label htmlFor={label}>
                                    <Text appearance="body-l-bold" as="span" color="primary-60">
                                        {label}
                                    </Text>
                                </label>
                                <div className={cn('w-full')}>
                                    {/*
                                    Using important here to override the default margin given by JDS Input
                                    because we do not have a label in the figma design and JDS Input has a default margin-top
                                    set for the label.
                                */}
                                    <div className={cn('mx-auto max-w-[52px]')}>
                                        <ControlledInput
                                            className={cn('!mt-1')}
                                            control={control}
                                            id="numberWithBigFont"
                                            inputMode="numeric"
                                            label=""
                                            name={value}
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </main>
            </div>
            <div
                className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    disabled={totalFormQuantity !== totalQuantity}
                    size="large"
                    type="submit"
                >
                    Confirm Short
                </Button>
            </div>
        </form>
    );
}
