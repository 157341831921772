import {
    ScanAirWayBillPage,
    GeneratePOR,
    EnterAppointmentNumberPage,
    ProductsToReturnPage,
    VerifyQuantity,
    ReturnsUnloadingSuccessPage,
    MarkShortPage,
    ScanStation,
} from '../../../../pages/returns/unloading';
import { Route } from 'react-router-dom';
import { SuccessPageV2 } from '../../SuccessPageV2';

export const UnloadingRoutes = [
    <Route key="1" element={<ScanStation />} path="" />,
    <Route key="2" element={<EnterAppointmentNumberPage />} path=":stationId" />,
    <Route key="3" path=":stationId/:appointmentNumber">
        <Route element={<ScanAirWayBillPage />} path="scan-air-way-bill" />
        <Route element={<GeneratePOR />} path="generate-por" />
        <Route element={<SuccessPageV2 message="POR generated" />} path="success" />
        <Route path=":shipmentId">
            <Route element={<ProductsToReturnPage />} path="products-to-return" />
            <Route element={<VerifyQuantity />} path=":ean/verify-quantity" />
            <Route element={<MarkShortPage />} path="mark-short" />
            <Route element={<ReturnsUnloadingSuccessPage />} path="returns-unloading-success" />
        </Route>
    </Route>,
];
