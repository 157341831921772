import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { pageHeaderStyle } from '../../../styles/common';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import {
    ProductType,
    useValidateTote,
    ValidateToteOptions,
} from '../../../hooks/receiving/useValidateTote';
import { useReturnOrders } from '../../../hooks/returns/unloading/useReturnOrders';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';
import { ReceivingContext } from '../../../provider/returns/receiving';
import { useContext, useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/helper';
import { InfoModalSection } from '../../../components/putaway/InfoModal';

const manualInputProps = { name: 'empty-tote', label: 'Empty Tote', type: 'text' };
export function AssignNewTotePage() {
    const { jioCode, reason, stationId, quantity, shipmentId } = useParams();
    const [navigate, queryParams] = [useNavigate(), useQueryParams()];
    const validateTote = useValidateTote();
    const { data: returnOrders } = useReturnOrders(false);
    const currentProduct = returnOrders?.products.find(({ jio_code }) => jio_code === jioCode);
    const { dispatch } = useContext(ReceivingContext);
    const [showToteValidateErrorModal, setShowToteValidateErrorModal] = useState(false);
    const product_type = reason?.toUpperCase() as ProductType;

    function onScanSuccess(tote_code: string) {
        if (!currentProduct) throw new Error('Product not found');

        const validateToteOptions: ValidateToteOptions = {
            tote_code,
            quantity: Number(quantity),
            product_type: product_type,
            jio_code: jioCode ?? '',
            location_id: stationId ?? '',
            tote_purpose: 'RETURNS',
            lot_id: currentProduct.lot_id,
            tote_params: {
                returnOrderId: shipmentId,
            },
        };

        queryParams.set('activeToteId', tote_code);
        validateTote.mutate(validateToteOptions, {
            onSuccess: ({ data }) => {
                if (reason?.toLowerCase() === 'good')
                    dispatch({ type: 'SET_STORAGE_ZONE_ID', payload: data.storage_zone });
                navigate({ pathname: '../assign', search: queryParams.toString() });
            },
            onError: () => {
                setShowToteValidateErrorModal(true);
            },
        });
    }

    return (
        <div className={cn('max-w-screen-md pb-3 md:mx-auto')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title={`${capitalizeFirstLetter(reason || '') as ProductType} Quantity- Tote`}
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <main className={cn('mx-6 mt-3 mb-20 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        optionalValues={{
                            station_id: stationId ?? '',
                        }}
                        purpose={product_type}
                        type="TOTE_CODE"
                        onSuccess={onScanSuccess}
                    />
                </main>
                {showToteValidateErrorModal && (
                    <InfoModalSection
                        ModalHeader="Tote is not compatible"
                        buttonText="Select another tote"
                        infoText=""
                        infoType="WARNING"
                        isToteInfo={true}
                        showCloseIcon={true}
                        onSuccess={() => {
                            setShowToteValidateErrorModal(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
}
