import { cn } from '../../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Icon, Text } from '@jds/core';
import { useQueryParams } from '../../../../hooks/shared/useQueryParams';
import { useContext, useState } from 'react';
import { ReceivingContext } from '../../../../provider/inbound/receiving';
import { ToteDropOptions, useDropTote } from '../../../../hooks/receiving/useDropTote';
import { useCurrentPackage } from '../../../../hooks/receiving/usePackages';
import { QuantityStatus } from '../../../../provider/inbound/receiving/ReceivingReducer';
import { PageHeaderV2 } from '../../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../../styles/common';
import { IcConfirm } from '@jds/core-icons';
import { IcOrder } from '@jds/extended-icons';
import { ProductCard } from '../../../../components/shared/ProductCard';
import { InfoModalSection } from '../../../../components/putaway/InfoModal';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function AssignCubiscanTotePage() {
    const { stationId, jioCode, primaryCaseId, secondaryCaseId } = useParams();
    const [navigate, queryParams] = [useNavigate(), useQueryParams()];
    const dropTote = useDropTote();
    const currentPackage = useCurrentPackage();
    const toteId = useQueryParams().get('cubiscanToteId');
    const { state, dispatch } = useContext(ReceivingContext);
    const [showDropSuccessModal, setShowDropSuccessModal] = useState(false);

    if (!toteId) throw new Error('Tote code is not provided');
    if (!currentPackage) throw new Error('Current package not found');

    const options: ToteDropOptions = {
        tote_purpose: 'ABOUT_TO_CUBISCAN',
        product_type: 'CUBISCAN',
        totes: [{ tote_code: toteId, quantity: 1 }],
        jio_code: jioCode ?? '',
        location_id: stationId ?? '',
        lot_id: currentPackage.lotId,
        tote_params: {
            primaryId: primaryCaseId || '',
            secondaryId:
                Number(secondaryCaseId) === -1 ? primaryCaseId || '' : secondaryCaseId || '',
        },
    };

    function onDropComplete() {
        const path = '../../../drop-list';
        const updatedFormValues: QuantityStatus = state.quantityStatus;
        updatedFormValues.cubiscan = 0;
        dispatch({ type: 'SET_QUANTITY_STATUS', payload: updatedFormValues });

        queryParams.delete('cubiscanToteId');
        localStorage.setItem('refetch', 'true');
        navigate(path);
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    title="Cubiscan Verification"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
            </div>
            {/*
                Make sure that the margin-bottom is greater than the height of the fixed bottom bar.
                Otherwise, the bottom bar will hide the main content
            */}
            <main className={cn('mx-6 mt-3 mb-40')}>
                <div className={cn('mt-6 ')}>
                    <ProductCard
                        BBD={currentPackage.bestBefore}
                        EAN={currentPackage.ean}
                        MFD={currentPackage.manufacturedDate}
                        MRP={currentPackage.mrp}
                        colorSize={`${currentPackage.colour ?? '_'}, ${currentPackage.size ?? '_'}`}
                        image={currentPackage.imageUrls?.at(0)}
                    />
                </div>
                <div className={cn('mt-6 items-center')}>
                    <Text appearance="body-s" className={cn('mt-4')}>
                        Details
                    </Text>
                    <Container
                        className={cn(
                            'mt-2 grid grid-cols-2 space-x-4 rounded-lg border-2 border-[var(--color-primary-60)] p-4'
                        )}
                    >
                        <Icon
                            className={cn('absolute left-3 bg-white')}
                            ic={<IcOrder color="#000093" />}
                        />
                        <div className={cn('flex flex-col')}>
                            <Text appearance="body-xs">Tote ID </Text>
                            <Text appearance="body-xxs-bold">{toteId}</Text>
                        </div>

                        <div className={cn('flex flex-row justify-around')}>
                            <div className={cn('flex flex-col items-center')}>
                                <Text appearance="body-xs"> Quantity </Text>
                                <Text appearance="body-xxs-bold">1</Text>
                            </div>
                            <div className={cn('flex items-center')}>
                                <Icon ic={<IcConfirm color="#1E7B74" />} />
                            </div>
                        </div>
                    </Container>
                </div>
            </main>
            <div
                className={cn('fixed inset-x-0 bottom-0 bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    isLoading={dropTote.isLoading}
                    size="large"
                    type="button"
                    onClick={() => {
                        dropTote.mutate(options, {
                            onSuccess: () => {
                                setShowDropSuccessModal(true);
                            },
                        });
                    }}
                >
                    Drop Complete
                </Button>
            </div>
            {showDropSuccessModal && (
                <InfoModalSection
                    ModalHeader="Drop complete for Cubiscan Quantity"
                    buttonText="Continue"
                    infoText=""
                    infoType="SUCCESS"
                    showCloseIcon={true}
                    onSuccess={() => {
                        setShowDropSuccessModal(false);
                        onDropComplete();
                    }}
                />
            )}
        </div>
    );
}
