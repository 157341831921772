import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Text, Button, Input, Container, Notifications } from '@jds/core';
import { ChangeEvent, Key, useCallback, useEffect, useState } from 'react';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { usePackingSuggestionList } from '../../../hooks/outbound/packing/usePackingSuggestionList';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { usePackingStateSave } from '../../../hooks/outbound/packing/usePackingStateSave';
export interface PackagingDetails {
    packingId: number;
    packingMaterialLabel: string;
    packingMaterialDescription: string;
}

export interface PackingSuggestionType {
    hazardous: boolean;
    coldStorage: boolean;
    liquid: boolean;
    hazmat: boolean;
    bulky: boolean;
    heavy: boolean;
    fragile: boolean;
    hvi: boolean;
    fast: boolean;
    pallet: boolean;
    sioc: boolean;
}

export interface PackingSuggestionListResponseSchema extends PackingSuggestionType {
    packingMaterialList: PackagingDetails[];
    recommendedPacking: PackagingDetails;
}

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function PackagingSuggestionPage() {
    const navigate = useNavigate();
    const { stationCode, ContainerCode, packingType, orderId, shipmentId, jioCode } = useParams();
    const [itemType, setItemType] = useState({
        showSuggestion: false,
        suggestionType: [''],
    });
    const savePackingactivity = usePackingStateSave();

    const suggestPackingType = useCallback(
        (data: PackingSuggestionListResponseSchema | undefined) => {
            const suggestedTypes = [];
            if (data?.fragile) {
                if (data.liquid) {
                    suggestedTypes.push(getSuggestPackingText('bubble'));
                    suggestedTypes.push(getSuggestPackingText('liquid'));
                } else {
                    suggestedTypes.push(getSuggestPackingText('bubble'));
                }
                suggestedTypes.push(getSuggestPackingText('fragile'));
            } else {
                if (data?.liquid) {
                    suggestedTypes.push(getSuggestPackingText('liquid'));
                }
            }
            setItemType({
                showSuggestion: suggestedTypes?.length > 0,
                suggestionType: suggestedTypes,
            });
            return suggestedTypes?.length > 0;
        },
        []
    );

    const { packingMaterials } = usePackingSuggestionList({
        order_id: orderId || '',
        jio_code: jioCode || '',
        order_type: packingType?.toUpperCase() || '',
        shipment_id: shipmentId || '',
    });
    const otherPackaging = packingMaterials?.packingMaterialList;
    const recommendedPackaging = packingMaterials?.recommendedPacking;

    const getSuggestPackingText = (type: string) => {
        switch (type) {
            case 'fragile':
                return 'Put a Fragile sticker';
            case 'liquid':
                return 'Pick a Liquid pouch for this item';
            case 'bubble':
                return 'Pick a Bubble wrap for this item';
            default:
                return 'Pick a Bubble wrap for this item';
        }
    };

    const handleNextSuggestion = () => {
        const suggestiontypes = itemType.suggestionType;
        suggestiontypes.shift();
        setItemType({
            showSuggestion: suggestiontypes?.length > 0,
            suggestionType: suggestiontypes,
        });
        return suggestiontypes?.length > 0;
    };

    const radioButtonClicked = (
        event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        setRadioButtonValue(Number(event.target.value));
    };

    const [radioButtonValue, setRadioButtonValue] = useState(
        packingType === 'Single' && recommendedPackaging ? recommendedPackaging.packingId : -1
    );

    function handleSuggestPackagingSelectState() {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SAVE_PACKING_ID',
                status: 'COMPLETED',
                shipmentId: shipmentId,
                orderId: orderId,
                containerId: ContainerCode,
                containerType: packingType?.toUpperCase() === 'MULTI' ? 'crate' : 'tote',
                quantity: 1,
                jiocode: jioCode,
                shipmentType: packingType?.toUpperCase(),
                packingStation: stationCode,
                locationId: stationCode,
                packingId: radioButtonValue,
            },
            {
                onSuccess: () => {
                    navigate(
                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${radioButtonValue}/scan-packaging-id`
                    );
                },
            }
        );
    }

    useEffect(() => {
        suggestPackingType(packingMaterials);
    }, [packingMaterials, suggestPackingType]);

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Packaging suggestion"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
            </div>
            <main className={cn('mx-3 mt-5 mb-20')}>
                {packingType === 'Single' && recommendedPackaging && (
                    <div>
                        <Text appearance="body-s" color="primary-grey-100">
                            Suggested packaging
                        </Text>
                        <div className={cn('my-4')}>
                            <Container
                                as="div"
                                background="primary-20"
                                className={cn(
                                    'mb-4 flex items-center justify-between rounded-lg p-3'
                                )}
                                pad="base"
                                rounded="large"
                            >
                                <Input
                                    checked={radioButtonValue === recommendedPackaging.packingId}
                                    className={cn('my-5')}
                                    helperText={`${recommendedPackaging.packingMaterialDescription} , ID - ${recommendedPackaging.packingId}`}
                                    label={recommendedPackaging.packingMaterialLabel}
                                    name={recommendedPackaging.packingMaterialLabel}
                                    type="radio"
                                    value={recommendedPackaging.packingId}
                                    onChange={(e) => radioButtonClicked(e)}
                                />
                            </Container>
                        </div>
                    </div>
                )}
                <Text appearance="body-s" className={cn('mx-4 mt-6')} color="primary-grey-100">
                    {packingType === 'Single' ? 'Other Packaging type' : 'Available Packaging type'}
                </Text>
                <div className={cn('mt-2 mb-20')}>
                    {otherPackaging
                        ?.filter(
                            (value: { packingId: number | undefined }) =>
                                packingType === 'Multi' ||
                                (packingType === 'Single' &&
                                    value.packingId !== recommendedPackaging?.packingId)
                        )
                        .map(
                            (item: {
                                packingId: Key | null | undefined;
                                packingMaterialDescription: any;
                                packingMaterialLabel: string;
                            }) => (
                                <Container
                                    key={item.packingId}
                                    as="div"
                                    background="primary-grey-20"
                                    className={cn(
                                        'mb-4 flex items-center justify-between rounded-lg p-3'
                                    )}
                                    pad="base"
                                    rounded="large"
                                >
                                    <Input
                                        checked={radioButtonValue === item.packingId}
                                        className={cn('my-5')}
                                        helperText={`${item.packingMaterialDescription} , ID - ${item.packingId}`}
                                        label={item.packingMaterialLabel}
                                        name={item.packingMaterialLabel}
                                        type="radio"
                                        value={item?.packingId?.toString()}
                                        onChange={(e) => radioButtonClicked(e)}
                                    />
                                </Container>
                            )
                        )}
                </div>
                <span
                    className={cn('fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6')}
                    style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
                >
                    <Button
                        fullWidth
                        disabled={radioButtonValue === -1}
                        onClick={() => {
                            handleSuggestPackagingSelectState();
                        }}
                    >
                        Print shipping Label and Invoice
                    </Button>
                </span>
            </main>
            {itemType.showSuggestion && (
                <InfoModalSection
                    ModalHeader={itemType.suggestionType[0]}
                    buttonText="Okay"
                    infoText=""
                    infoType="WARNING"
                    showCloseIcon={true}
                    showModalBool={itemType.suggestionType?.length > 1}
                    onSuccess={() => {
                        handleNextSuggestion();
                    }}
                />
            )}
        </div>
    );
}
