import { createContext, Dispatch, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import {
    ConsolidationAction,
    consolidationReducer,
    ConsolidationState,
} from './ConsolidationReducer';

export const initialConsolidationState: ConsolidationState = {
    consolidationDamagedJioCodes: [],
    consolidationJioCodesList: [],
    consolidationParkedCrateCodesList: [],
    consolidatedOrdersDetails: [],
    consolidationToteScannedQuantity: 0,
    consolidationToteTotalQuantity: 0,
    consolidationCurrentDamagedToteCode: '',
    consolidationCurrentUnknownItemsToteCode: '',
    quantityStatus: {
        damaged: 0,
        short: 0,
    },
};

interface ConsolidationContextType {
    state: ConsolidationState;
    dispatch: Dispatch<ConsolidationAction>;
}

export const ConsolidationContext = createContext<ConsolidationContextType>({
    state: initialConsolidationState,
    dispatch: () => {},
});

export function ConsolidationProvider() {
    const [state, dispatch] = useReducer(consolidationReducer, initialConsolidationState);

    return (
        <ConsolidationContext.Provider value={{ state, dispatch }}>
            <Outlet />
        </ConsolidationContext.Provider>
    );
}
