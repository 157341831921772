import { cn } from '../../../utils/tailwind';
import { useNavigate } from 'react-router-dom';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useFetchReturnOrders } from '../../../hooks/returns/unloading/useFetchReturnOrders';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { Notifications } from '@jds/core';
import { useState } from 'react';
import { InfoModalSection } from '../../../components/putaway/InfoModal';

const manualInputProps = { name: 'air-way-bill', label: 'Air Way Bill / Return ID', type: 'text' };
export function ScanShipmentPage() {
    const navigate = useNavigate();
    const fetchReturnOrders = useFetchReturnOrders();
    const [showReceivingSuccessModal, setShowReceivingSuccessModal] = useState(false);

    function onScanSuccess(shipmentId: string) {
        fetchReturnOrders.mutate(shipmentId, {
            onSuccess: (data) => {
                const areAllProductsUnloaded = data?.data?.products.some(
                    (item) => item.product_status === 'RETURN_UNLOADING_COMPLETED'
                );
                if (
                    (data?.data?.return_status === 'RETURNS_UNLOADED' ||
                        data?.data?.return_status === 'RETURNS_RECEIVING_STARTED') &&
                    areAllProductsUnloaded
                ) {
                    navigate(`./../${shipmentId}/scan-jio-code`);
                } else if (data?.data?.return_status === 'RETURNS_RECEIVING_DONE') {
                    setShowReceivingSuccessModal(true);
                } else if (!areAllProductsUnloaded) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: 'Receiving is not allowed for this shipment',
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: 'Please Complete Unloading for this shipment',
                    });
                }
            },
        });
    }

    return (
        <div>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Air Way Bill/ Return ID"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />

                <main className={cn('mx-3 mt-4 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        stage="RETURNS"
                        type="returns_receiving"
                        onSuccess={onScanSuccess}
                    />
                </main>
                {showReceivingSuccessModal && (
                    <InfoModalSection
                        ModalHeader="Receiving of this shipment is already completed"
                        buttonText="Pick another shipment"
                        infoText=""
                        infoType="SUCCESS"
                        showCloseIcon={true}
                        onSuccess={() => {
                            setShowReceivingSuccessModal(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
}
