import { Route } from 'react-router-dom';
import { ExpiredProductTotePage } from '../../../../pages/outbound/packing/ExpiredProductTotePage';
import { FailedVerificationPage } from '../../../../pages/outbound/packing/FailedVerificationPage';
import { LowFreshnessTotePage } from '../../../../pages/outbound/packing/LowFreshnessTotePage';
import { PackingMarkShortPage } from '../../../../pages/outbound/packing/MarkShortPage';
import { PackagingSuggestionPage } from '../../../../pages/outbound/packing/PackagingSuggestionPage';
import { PackingMainPage } from '../../../../pages/outbound/packing/PackingMainPage';
import { PackingMultiHomePage } from '../../../../pages/outbound/packing/PackingHomePage';
import { PackingSuccessPage } from '../../../../pages/outbound/packing/PackingSuccessPage';
import { ScanCancelledItemsPage } from '../../../../pages/outbound/packing/ScanCancelledItemsPage';
import { ScanExcessItemsPage } from '../../../../pages/outbound/packing/ScanExcessItemsTotePage';
import { ScanPackagingIDPage } from '../../../../pages/outbound/packing/ScanPackagingId';
import { ScanPackingStation } from '../../../../pages/outbound/packing/ScanPackingStationPage';
import { VerifyDetailsPage } from '../../../../pages/outbound/packing/VerifyDetailsPage';
import { WrongProductTotePage } from '../../../../pages/outbound/packing/WrongProductTotePage';
import { DamagedProductTotePage } from '../../../../pages/outbound/packing/DamagedProductTotePage';

export const PackingRoutes = [
    <Route key="1" element={<ScanPackingStation />} path="" />,
    <Route key="2" element={<PackingMultiHomePage />} path=":stationCode/homepage" />,
    <Route
        key="3"
        element={<PackingMainPage />}
        path=":stationCode/:ContainerCode/:packingType/main"
    />,
    <Route
        key="4"
        element={<PackingMarkShortPage />}
        path=":stationCode/:ContainerCode/:packingType/mark-short"
    />,
    <Route
        key="5"
        element={<VerifyDetailsPage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/verify-details"
    />,
    <Route
        key="6"
        element={<FailedVerificationPage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/:lotId/verification-failed"
    />,
    <Route
        key="7"
        element={<PackagingSuggestionPage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/packaging-suggestion"
    />,
    <Route
        key="8"
        element={<ScanExcessItemsPage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/:lotId/drop-excess-items"
    />,
    <Route
        key="9"
        element={<ScanCancelledItemsPage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/:lotId/drop-cancelled-items"
    />,
    <Route
        key="10"
        element={<ExpiredProductTotePage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/:lotId/drop-expired-items"
    />,
    <Route
        key="11"
        element={<DamagedProductTotePage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/:lotId/drop-damaged-items"
    />,
    <Route
        key="12"
        element={<LowFreshnessTotePage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/:lotId/drop-lowfreshness-items"
    />,
    <Route
        key="13"
        element={<WrongProductTotePage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/:lotId/drop-wrong-products"
    />,
    <Route
        key="14"
        element={<ScanPackagingIDPage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/:packagingId/scan-packaging-id"
    />,
    <Route
        key="15"
        element={<PackingSuccessPage />}
        path=":stationCode/:ContainerCode/:packingType/success"
    />,
    <Route
        key="16"
        element={<PackingSuccessPage />}
        path=":stationCode/:ContainerCode/:packingType/:orderId/:shipmentId/:jioCode/success"
    />,
];
