import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../utils/urls';

export interface CompatibleListSchema {
    tote_code: string;
    storage_zone: string;
    putListId: number;
}

export function useCompatibleTotesList() {
    return useMutation(async (data: CompatibleListSchema) => {
        const response = await axios.get(
            urls.GET_COMPATIBLE_TOTES_LIST(data.tote_code, data.putListId)
        );
        return response.data;
    });
}
