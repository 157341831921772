import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';
import { JioCodesListSchema } from './useJioCodesList';

export function useFreeTote() {
    return useMutation(async (data: JioCodesListSchema) => {
        const response = await axios.post(urls.FREE_TOTE(data.toteId));
        return response.data;
    });
}
