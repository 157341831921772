import { useNavigate, useParams } from 'react-router-dom';
import { ScanTotePage } from '../../../components/inventory/stocktake/ScanTote';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';

export function ExcessItemTotePage() {
    const navigate = useNavigate();
    const { stockTakeId, binlocation } = useParams();
    const lotId = Number(useQueryParams().get('lotId'));

    return (
        <ScanTotePage
            locationId={binlocation}
            lotId={lotId}
            pageHeader="Assign Totes - Excess"
            showBackIon={false}
            toteType="excess-items"
            onSuccess={() => {
                navigate(`/inventory/stock-take/${stockTakeId}/${binlocation}/main`);
            }}
        />
    );
}
