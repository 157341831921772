import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Icon } from '@jds/core';
import { cn } from '../../../utils/tailwind';

import { PageHeader } from '../../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import ConfirmationDialog from '../../../components/shared/ConfirmationDialog';
import { useMapGtlPutawayStation } from '../../../hooks/gtl/useMapGtlPutawayStation';
import { toteSchema } from '../../../hooks/gtl/useUpdateGtlJioCodeStatus';
import { GenerateGtlPutList } from '../../../hooks/gtl/GenerateGtlPutList';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'tote-code', label: 'Scan Tote', type: 'text' };

const ScanPutawayTote = () => {
    const [apiErrors, setApiErrors] = useState({
        showError: false,
        errorMessage: '',
    });
    const [enabledPutAway, setEnabledPutAway] = useState(false);
    const [toteId, setToteId] = useState('');
    const navigate = useNavigate();
    const { locationId } = useParams();

    const [mapGtlPutawayStation, generatePutlist] = [
        useMapGtlPutawayStation(),
        GenerateGtlPutList(),
    ];
    const routePrefix = useLocation().pathname.split('/', 3).join('/');

    const toteList: toteSchema[] = JSON.parse(localStorage.getItem('gtlToteIds') || '[]');
    const toteIds: string[] = toteList.map((item) => item.tote_code);

    function onScanSuccess(toteCode: string) {
        if (toteList.length > 0 && toteIds.includes(toteCode)) {
            mapGtlPutawayStation.mutate(
                {
                    locationId: locationId ?? '',
                    toteId: toteCode,
                },
                {
                    onSuccess: () => {
                        setEnabledPutAway(true);
                        setToteId(toteCode);
                        const scannedTotesList: string[] = JSON.parse(
                            localStorage.getItem('scannedPutAwayTotes') || '[]'
                        );
                        scannedTotesList.push(toteCode);
                        localStorage.setItem(
                            'scannedPutAwayTotes',
                            JSON.stringify(scannedTotesList)
                        );
                    },
                }
            );
        } else {
            setApiErrors({
                errorMessage: 'Wrong tote. It is not matching with earlier one',
                showError: true,
            });
        }
    }

    const genaratePutAway = () => {
        generatePutlist.mutate(
            {
                user_name: sessionStorage.getItem('username') ?? '',
                totes: [toteId],
            },
            {
                onSuccess: () => {
                    navigate(`${routePrefix}/${locationId}/${toteId}/scan-jiocode`);
                },
            }
        );
    };

    return (
        <>
            <div className={cn(layoutStyles, 'mx-3 py-3')}>
                <PageHeader
                    title="Putaway"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <main className={cn('mx-2 mt-3')}>
                    <ScanOrEnterManuallyV2
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        onSuccess={(toteCode) => {
                            onScanSuccess(toteCode);
                        }}
                    />
                    <div className={cn('fixed inset-x-2 bottom-8 mx-4')}>
                        <Button
                            fullWidth
                            disabled={!enabledPutAway}
                            kind="primary"
                            onClick={() => {
                                genaratePutAway();
                            }}
                        >
                            Start Put Away
                        </Button>
                    </div>
                </main>
            </div>
            {apiErrors.showError && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcErrorColored"
                            size="xl"
                        />
                    }
                    isOpened={apiErrors.showError}
                    message={apiErrors.errorMessage}
                    primaryCTALabel="Pick Another Tote"
                    onPrimaryCTAClick={() => {
                        window.location.reload();
                        setApiErrors({
                            errorMessage: '',
                            showError: false,
                        });
                    }}
                />
            )}
        </>
    );
};

export default ScanPutawayTote;
