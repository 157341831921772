import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../utils/tailwind';
import { Button, Container, Notifications, Text } from '@jds/core';
import { useQueryClient } from '@tanstack/react-query';

import { PageHeader } from '../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { useUpdateGtlJioCodeStatus } from '../../hooks/gtl/useUpdateGtlJioCodeStatus';
import { useUpdateGtlShortDamageItemStatus } from '../../hooks/gtl/useUpdateGtlShortDamageItemStatus';
import { GtlTaskListResponse } from '../../hooks/gtl/useGetGtlTaskList';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputPropsJioCode = { name: 'jio-code', label: 'Jio Code', type: 'text' };

const GtlMarkDamage = () => {
    const [damageItems, setDamageItems] = useState<string[]>([]);
    const navigate = useNavigate();

    const { locationId } = useParams();
    const gtlTaskList = useQueryClient().getQueryData<GtlTaskListResponse>(['gtlTaskList']);
    const gtlLocationsList: any = gtlTaskList?.data.filter(
        (item) => item.sourceLocationId === locationId
    );
    const pickedGtlJioCodeIndex = Number(
        JSON.parse(localStorage.getItem('pickedGtlJioCodeIndex') || '0')
    );
    const currentJioCodeData: any =
        gtlLocationsList &&
        gtlLocationsList.length > pickedGtlJioCodeIndex &&
        gtlLocationsList[pickedGtlJioCodeIndex];
    const scannedItemQuantityTillNow = Number(
        JSON.parse(localStorage.getItem('scannedGtlItemQuantityTillNow') || '0')
    );
    const updateMarkShortItems = useUpdateGtlShortDamageItemStatus();

    const updateMarkDamageItems = () => {
        if (
            Number(damageItems?.length) <=
            currentJioCodeData?.quantity - scannedItemQuantityTillNow
        ) {
            const updatedTaskObj = { damagedQuantity: damageItems.length, shortQuantity: 0 };
            updateMarkShortItems.mutate(updatedTaskObj, {
                onSuccess() {
                    const totalItems = scannedItemQuantityTillNow + damageItems?.length;

                    localStorage.setItem(
                        'scannedGtlItemQuantityTillNow',
                        JSON.stringify(totalItems)
                    );
                    if (totalItems === currentJioCodeData?.quantity) {
                        const pickedJioCodeIndex =
                            Number(localStorage.getItem('pickedGtlJioCodeIndex')) + 1;
                        if (pickedJioCodeIndex === gtlLocationsList?.length) {
                            localStorage.setItem('gtlLocationCompleted', JSON.stringify(true));
                        } else {
                            localStorage.setItem(
                                'pickedGtlJioCodeIndex',
                                JSON.stringify(pickedJioCodeIndex)
                            );
                            localStorage.setItem('gtlJioCodeCompleted', JSON.stringify(true));
                        }
                    }
                    navigate(`../${locationId}/scan-gtl-jioCode`);
                },
            });
        } else {
            Notifications.toast.error({
                title: 'Error',
                description: 'Number of damage item is not correct.',
            });
        }
    };
    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <PageHeader
                subTitle="Damaged"
                title="Assign Totes -"
                onBackIconClick={() => {
                    navigate(-1);
                }}
            ></PageHeader>
            <main className={cn('mx-2 mt-4')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount={true}
                    inputProps={manualInputPropsJioCode}
                    onSuccess={(value) => {
                        const damagedItems = [...damageItems, value];
                        setDamageItems(damagedItems);
                    }}
                />
                <Container
                    background="primary-grey-20"
                    className={cn('my-4 space-y-3')}
                    pad="m"
                    rounded="large"
                >
                    <Text appearance="body-m" color="primary-grey-100">
                        Scanned Damaged{' '}
                        {damageItems.length > 1
                            ? `items ${damageItems.length}`
                            : `item ${damageItems.length}`}
                    </Text>
                    {damageItems?.length > 0 &&
                        damageItems.map((item) => {
                            return (
                                <Text key={item} appearance="body-m" color="primary-grey-60">
                                    {item}
                                </Text>
                            );
                        })}
                </Container>
                <div className={cn('fixed inset-x-2 bottom-8 mx-4')}>
                    <Button fullWidth kind="primary" onClick={() => updateMarkDamageItems()}>
                        Drop Complete
                    </Button>
                </div>
            </main>
        </div>
    );
};

export default GtlMarkDamage;
