import { urls } from '../../utils/urls';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';

export interface CubiscanDetails {
    uid: number;
    skuCode: string;
    jioCode: string;
    rfcId: number;
    weight: number;
    weightUnit: string;
    length: number;
    breadth: number;
    height: number;
    volumeUnit: string;
    volume: number;
    userName: string;
    cubiscanStatus: 'PENDING' | 'COMPLETED';
    toteCode: string;
    machineId: string;
    lotId: number;
}

export interface UserCubiscanStateResponse {
    data: CubiscanDetails;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useFetchUserCubiscanState() {
    const { data, ...rest } = useQuery<UserCubiscanStateResponse>({
        queryKey: ['cubiscanStateDetails'],
        queryFn: async () => {
            const response = await axios.get(urls.GET_USER_CUBISCAN_STATE());
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
    return { cubiscanStateDetails: data?.data, ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
