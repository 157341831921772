import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface FreeCrateSchema {
    crateId: string;
    crateStatus: 'FREE' | 'OCCUPIED' | 'UN_OPERATIONAL';
}

export function useFreeCrate() {
    return useMutation(async (data: FreeCrateSchema) => {
        const axiosOptions = Object.assign({});
        const response = await axios.put(
            urls.FREE_CRATE(data.crateId, data.crateStatus),
            axiosOptions,
            {
                headers: {
                    instanceCode: sessionStorage.getItem('rfc-id'),
                },
            }
        );
        return response.data;
    });
}
