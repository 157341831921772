import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Button, Notifications } from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { useState } from 'react';
import { usePicklistStateSave } from '../../../hooks/outbound/picklist/usePicklistStateSave';
import { useAssignPicklistTrolley } from '../../../hooks/outbound/picklist/useAssignTrolley';

const manualInputProps = { name: 'trolley-code', label: 'Trolley Code', type: 'text' };

const layoutStyles = 'max-w-screen-md md:mx-auto';

const ScanTrolleyCode = () => {
    const navigate = useNavigate();
    const { pickingId } = useParams();
    const [trolleyCode, setTrolleyCode] = useState('');
    const savePicklistactivity = usePicklistStateSave();
    const assignPicklistTrolley = useAssignPicklistTrolley();

    const validateInventory = useInventoryDetails();
    const handleValidateInventoryDetails = () => {
        validateInventory.mutate(
            { inventoryCode: trolleyCode, type: 'TROLLEY' },
            {
                onSuccess(res: any) {
                    if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                        Notifications.toast.error({
                            title: 'Error',
                            description:
                                res.meta.errors?.length > 0
                                    ? res.meta.errors.join(', ')
                                    : 'Invalid Trolley Code',
                        });
                    } else {
                        onAssignPicklistTrolley();
                    }
                },
            }
        );
    };

    function handleTrolleyScanState() {
        savePicklistactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                activity_name: 'TROLLEY_SCAN',
                status: 'COMPLETED',
            },
            {
                onSuccess: () => {
                    navigate(`../${pickingId}/${trolleyCode}/scan-tote`);
                },
            }
        );
    }

    function onAssignPicklistTrolley() {
        const data = {
            pickListId: Number(pickingId) || 0,
            trolleyId: trolleyCode || '',
            toteId: '',
            orderId: '',
            jioCode: '',
            requestType: 'TROLLEY',
        };

        assignPicklistTrolley.mutate(data, {
            onSuccess: () => handleTrolleyScanState(),
        });
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Trolley"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <main className={cn('mx-3 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        type="TROLLEY_CODE"
                        onSuccess={(value) => {
                            if (value) {
                                setTrolleyCode(value);
                            } else {
                                Notifications.toast.error({
                                    title: 'Error',
                                    description: 'Please enter trolley code.',
                                });
                            }
                        }}
                    />
                </main>
            </div>
            <div
                className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    disabled={trolleyCode === ''}
                    size="large"
                    onClick={handleValidateInventoryDetails}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default ScanTrolleyCode;
