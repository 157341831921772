import { Notifications } from '@jds/core';
import { useNavigate, useParams } from 'react-router-dom';
import { ScanTotePage } from '../../../components/outbound/packing/ScanTote';
import {
    UpdateDamagedProductsSchema,
    useUpdateDamagedProducts,
} from '../../../hooks/outbound/packing/UseUpdateDamagedProducts';
import { usePackingStateSave } from '../../../hooks/outbound/packing/usePackingStateSave';

export function ExpiredProductTotePage() {
    const navigate = useNavigate();
    const { stationCode, ContainerCode, orderId, shipmentId, jioCode, packingType, lotId } =
        useParams();
    const savePackingactivity = usePackingStateSave();

    const updateDamagedProducts = useUpdateDamagedProducts();
    const handleUpdateDamagedProducts = (data: UpdateDamagedProductsSchema[]) => {
        updateDamagedProducts.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    handleExpiredToteDropState();
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    function handleExpiredToteDropState() {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_TOTE_PACKING',
                status: 'COMPLETED',
                shipmentId: shipmentId,
                orderId: orderId,
                containerId: ContainerCode,
                containerType: packingType?.toUpperCase() === 'MULTI' ? 'crate' : 'tote',
                quantity: 1,
                jiocode: jioCode,
                shipmentType: packingType?.toUpperCase(),
                packingStation: stationCode,
                locationId: stationCode,
                productReceivingSource: 'PACKING_OUTBOUND_QC',
                productType: 'EXPIRED',
                lotId: Number(lotId) || 0,
            },
            {
                onSuccess: () => {
                    navigate(
                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/main`
                    );
                },
            }
        );
    }

    return (
        <ScanTotePage
            locationId={stationCode}
            lotId={Number(lotId)}
            pageHeader="Damaged and Expired Tote"
            showBackIon={true}
            toteType="damaged-expired-items"
            onSuccess={() => {
                handleUpdateDamagedProducts([
                    {
                        cancelled_quantity: 1,
                        cancellation_reason: 'expired',
                        order_id: orderId || '',
                        jio_code: jioCode || '',
                        shipment_id: shipmentId || '',
                        cancelledDuringPacking: true,
                    },
                ]);
            }}
        />
    );
}
