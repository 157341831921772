import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Divider, Icon, Notifications, Text } from '@jds/core';
import { useState } from 'react';
import { useTotesValidation } from '../../../hooks/putaway/useTotesValidation';
import {
    TotesValidationSchema,
    useGeneratePutList,
} from '../../../hooks/putaway/useGeneratePutList';
import { cn } from '../../../utils/tailwind';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import Accordion from '../../../components/putaway/Accordian';
import { pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { IcOrder } from '@jds/extended-icons';
import { useQueryClient } from '@tanstack/react-query';

const manualInputProps = { name: 'tote', label: 'Tote Code', type: 'text' };
interface ToteMapping {
    toteID: string;
    storageZone: string;
}
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function AddPutawayTotes() {
    const { stationId } = useParams();
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname.split('/', 3).join('/');
    const [scannedTotes, setScannedTotes] = useState<Array<ToteMapping>>([]);
    const [showWarning, setShowWarning] = useState(false);
    const totesList = useQueryClient().getQueryData<any>(['totes-list']);
    const toteCodeKeys = totesList
        ?.filter((item: { ready_for_put_away: boolean }) => item.ready_for_put_away === true)
        .map((item: { tote_code: string; ready_for_put_away: boolean }) => ({
            result: item.tote_code,
        }));
    const [unscannedTotes, setUnscannedTotes] = useState(toteCodeKeys);

    const addToScannedTotes = (data: { toteId: string; storageZone: string }) => {
        const val = scannedTotes.find((tote) => data.toteId === tote.toteID);
        if (!val) {
            setScannedTotes([
                ...scannedTotes,
                { toteID: data.toteId, storageZone: data.storageZone },
            ]);
            handleSecondaryCaseAutoSuggestions([
                ...scannedTotes,
                { toteID: data.toteId, storageZone: data.storageZone },
            ]);
        } else {
            Notifications.toast.error({
                title: 'Tote is already scanned',
            });
        }
    };

    const totesValidation = useTotesValidation();
    const onScanningTote = (data: TotesValidationSchema) => {
        totesValidation.mutate(data, {
            onSuccess(res) {
                if (res?.meta?.status === 'failure' || res?.data?.valid === false) {
                    setShowWarning(true);
                } else {
                    addToScannedTotes({
                        toteId: res?.data.tote_code,
                        storageZone: res?.data.storage_zone,
                    });
                }
            },
            onError(err: any) {
                Notifications.toast.error({
                    title: 'Error',
                    description: err?.response?.data?.meta?.errors
                        ? err.response.data.meta.errors.join(', ')
                        : err.message,
                });
            },
        });
    };

    const generatePutList = useGeneratePutList();
    const getScannedTotesArray = () => {
        return scannedTotes.map(({ toteID }) => toteID);
    };
    const queryClient = useQueryClient();
    const onStartPutAway = (data: TotesValidationSchema) => {
        generatePutList.mutate(data, {
            onSuccess(res) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'API Failed',
                        description: res.meta.errors.join(', '),
                    });
                } else if (res?.meta?.status === 'success') {
                    queryClient.setQueryData(['generated-totes-list'], res.data.tote_mappings);
                    localStorage.setItem('PutListId', res?.data?.uid);
                    navigate(`${routePrefix}/${stationId}/scan-tote`);
                }
            },
            onError(err: any) {
                Notifications.toast.error({
                    title: 'Error',
                    description: err?.response?.data?.meta?.errors
                        ? err.response.data.meta.errors.join(', ')
                        : err.message,
                });
            },
        });
    };

    const handleSecondaryCaseAutoSuggestions = (scannedList: any[] | undefined) => {
        const expectedTotes = totesList.map((item: any) => item.tote_code);
        const scannedTotes = scannedList?.map((item) => item.toteID);

        const notPresentInData = expectedTotes.filter((val: any) => !scannedTotes?.includes(val));
        const uniqueKeys = notPresentInData?.map((item: string) => ({
            result: item,
        }));
        setUnscannedTotes(uniqueKeys);
    };
    return (
        <>
            <div className={cn(pageHeaderStyle, 'pb-1')}>
                <PageHeaderV2 isExtended title="Scan totes" onBackIconClick={() => navigate(-1)} />
                <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        inputProps={manualInputProps}
                        searchableList={unscannedTotes}
                        onSuccess={(value) => {
                            if (value) {
                                onScanningTote({
                                    toteId: value,
                                    stationId: stationId || '',
                                    scannedTotes: [],
                                });
                            }
                        }}
                    />
                </div>
            </div>
            <div className={cn('mx-3 mb-16 grid max-w-screen-md grid-cols-1 py-3 md:mx-auto')}>
                <div className={cn('mx-3 mt-3 space-y-4')}>
                    {!!scannedTotes.length && (
                        <>
                            <Text
                                appearance="body-s-bold"
                                className={cn('w-full')}
                                color="secondary-grey-100"
                            >
                                {`Scanned Totes ${scannedTotes.length}`}
                            </Text>
                            <div className={cn('mt-4 space-y-4')}>
                                {scannedTotes.map((id) => (
                                    <Container
                                        key={id.toteID}
                                        className={cn(
                                            'grid grid-cols-2 space-x-3 rounded-lg border-2 border-[var(--color-secondary-grey-60)] p-4'
                                        )}
                                        style={{ boxShadow: 'inset -12px 0px 0px 0px #FFAA66' }}
                                    >
                                        <Icon
                                            className={cn('absolute left-4 bg-white')}
                                            ic={<IcOrder />}
                                        />
                                        <Text appearance="body-xs">Tote ID</Text>
                                        <Text
                                            appearance="body-xs"
                                            className={cn('!ml-6 !text-[#FFAA66]')}
                                        >
                                            Storage Zone
                                        </Text>
                                        <Text appearance="body-s-bold" className={cn('ml-5')}>
                                            {id.toteID}
                                        </Text>
                                        <Text
                                            appearance="body-s-bold"
                                            className={cn('!ml-6 !text-[#FFAA66]')}
                                        >
                                            {id.storageZone}
                                        </Text>
                                    </Container>
                                ))}
                            </div>
                        </>
                    )}
                    <Divider />
                </div>
                <Accordion />
            </div>
            <span
                className={cn(
                    'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                    layoutStyles
                )}
            >
                <Button
                    fullWidth
                    disabled={scannedTotes.length < 1}
                    onClick={() => {
                        onStartPutAway({
                            toteId: '',
                            stationId: stationId || '',
                            scannedTotes: getScannedTotesArray(),
                        });
                    }}
                >
                    Start Put Away
                </Button>
            </span>
            {showWarning && (
                <div className={cn('mt-14')}>
                    <InfoModalSection
                        ModalHeader="Tote not available for putaway"
                        buttonText="Pick Another Tote"
                        infoText=""
                        infoType="ERROR"
                        showCloseIcon={false}
                        onSuccess={() => setShowWarning(false)}
                    />
                </div>
            )}
        </>
    );
}
