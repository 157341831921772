import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';
import {
    ProductType,
    useValidateTote,
    ValidateToteOptions,
} from '../../../hooks/receiving/useValidateTote';
import { useCurrentPackage } from '../../../hooks/receiving/usePackages';
import { useContext } from 'react';
import { ReceivingContext } from '../../../provider/inbound/receiving';
import { reasonLabels } from './SummaryPage';
import { QuantityStatus } from '../../../provider/inbound/receiving/ReceivingReducer';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';

const manualInputProps = { name: 'drop-tote', label: 'Empty Tote', type: 'text' };
export type Reasons = Omit<QuantityStatus, 'short' | 'failed' | 'cubiscan'>;

export const reasonProductTypeMap: Record<keyof Reasons, ProductType> = {
    excess: 'EXCESS',
    expired: 'EXPIRED',
    damaged: 'DAMAGED',
    lowFreshness: 'LOW_FRESHNESS',
    batchIssue: 'BATCH_ISSUE',
    wrong: 'WRONG',
    good: 'GOOD',
};

export function ScanDropTotePage() {
    const { dispatch } = useContext(ReceivingContext);
    const validateTote = useValidateTote();
    const currentPackage = useCurrentPackage();
    const [navigate, queryParams] = [useNavigate(), useQueryParams()];
    const { quantity, stationId, reason, jioCode, primaryCaseId, secondaryCaseId } = useParams();
    const product_type = reasonProductTypeMap[reason as keyof Reasons];

    function onScanSuccess(tote_code: string) {
        if (!currentPackage) throw new Error('Current package is not defined');

        const validateToteOptions: ValidateToteOptions = {
            tote_code,
            quantity: Number(quantity),
            product_type: product_type,
            jio_code: jioCode ?? '',
            location_id: stationId ?? '',
            tote_purpose: 'INBOUND',
            lot_id: currentPackage.lotId,
            tote_params: {
                primaryId: primaryCaseId || '',
                secondaryId:
                    Number(secondaryCaseId) === -1 ? primaryCaseId || '' : secondaryCaseId || '',
            },
        };

        queryParams.set('activeToteId', tote_code);
        validateTote.mutate(validateToteOptions, {
            onSuccess: ({ data }) => {
                if (reason?.toLowerCase() === 'good')
                    dispatch({ type: 'SET_STORAGE_ZONE_ID', payload: data.storage_zone });
                navigate({ pathname: '../assign', search: queryParams.toString() });
            },
        });
    }
    return (
        <div className={cn('max-w-screen-md pb-3 md:mx-auto')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title={`${reasonLabels[reason as keyof QuantityStatus]} Quantity- Tote`}
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <main className={cn('mx-6 mt-3 mb-20 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        optionalValues={{
                            station_id: stationId ?? '',
                        }}
                        purpose={product_type}
                        type="TOTE_CODE"
                        onSuccess={onScanSuccess}
                    />
                </main>
            </div>
        </div>
    );
}
