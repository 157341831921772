import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../utils/urls';

export function useTotesList() {
    return useMutation(async (stationId: string) => {
        const response = await axios.get(urls.GET_TOTES_LIST(stationId));
        return response.data;
    });
}
