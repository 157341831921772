import { Container, Text } from '@jds/core';
import { cn } from '../../utils/tailwind';

import { ProductCardWrapper } from '../shared/ProductCard/ProductCardWrapper';

interface PicklistDropItemProps {
    dimensions: string;
    EAN: string;
    imageUrl?: string;
    jioCode?: string;
}

export function PicklistDropItem({ dimensions, EAN, imageUrl, jioCode }: PicklistDropItemProps) {
    return (
        <ProductCardWrapper>
            <Container
                background="primary-grey-20"
                className={cn('flex items-center space-x-2.5')}
                pad="xs"
                rounded="large"
            >
                {/* this src needs to swap once we get the actual data */}
                <img
                    alt="Product Image"
                    className={cn('h-[58px] w-[65px] rounded-2xl object-cover')}
                    src={imageUrl ?? 'https://via.placeholder.com/88'}
                />
                <div className="flex flex-col items-start space-y-0.5">
                    {jioCode && (
                        <Text appearance="body-xxs" color="primary-grey-80">
                            Jio Code {jioCode}
                        </Text>
                    )}
                    <Text appearance="body-xxs" color="primary-grey-80">
                        EAN {EAN}
                    </Text>
                    <Text appearance="body-xxs" color="primary-grey-80">
                        LBHW {dimensions}
                    </Text>
                </div>
            </Container>
        </ProductCardWrapper>
    );
}
