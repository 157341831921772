import { cn } from '../../../utils/tailwind';
import { layoutStyles } from '../../../styles/common';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Divider, Notifications, Spinner, Text } from '@jds/core';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductCard } from '../../../components/shared/ProductCard';
import { useCompatibleSKUs } from '../../../hooks/returns/receiving/useCompatibleSKUs';
import { useContext } from 'react';
import { ReceivingContext } from '../../../provider/returns/receiving';
import { useReturnProducts } from '../../../hooks/returns/unloading/useReturnProducts';

export function CompatibleSKUsPage() {
    const { completedJioCodes, storageZoneId } = useContext(ReceivingContext).state;
    const { data: returnProducts } = useReturnProducts();
    const { shipmentId, stationId } = useParams();
    const uncompletedProducts =
        returnProducts?.filter(({ jio_code }) => !completedJioCodes.includes(jio_code)) ?? [];
    const { data: skuList, isLoading } = useCompatibleSKUs(storageZoneId, uncompletedProducts);
    const navigate = useNavigate();

    return (
        <div className={cn(layoutStyles, 'm-6')}>
            <PageHeader
                subTitle={shipmentId}
                title="Receiving OPs -"
                onBackIconClick={() => navigate(-1)}
            />
            <main className={cn('mt-6')}>
                <ScanOrEnterManuallyV2
                    inputProps={{ name: 'jio-code', label: 'Jio Code' }}
                    onSuccess={(jioCode) => {
                        if (uncompletedProducts.find((product) => product.jio_code === jioCode)) {
                            const path = `/returns/receiving/${stationId}/${shipmentId}/${jioCode}/details`;
                            navigate(path);
                        } else {
                            Notifications.toast.error({
                                title: 'Invalid Jio Code',
                                description: 'Please scan or enter a valid Jio Code',
                            });
                        }
                    }}
                />
                <div className={cn('-mx-6')}>
                    <Divider padding="m" />
                </div>
                <div className={cn('space-y-4')}>
                    <div className={cn('flex items-center justify-between')}>
                        <Text appearance="body-s-bold" color="primary-grey-80">
                            Compatible SKU
                        </Text>
                        <Text appearance="body-xxs" color="primary-grey-80">
                            {stationId}
                        </Text>
                    </div>
                    {isLoading && (
                        <div className={cn('flex justify-center')}>
                            <Spinner size="small" />
                        </div>
                    )}
                    <ul className={cn('space-y-4')}>
                        {skuList?.map(({ expiry, ean, mfg, mrp, color, size }) => (
                            <li key={ean}>
                                <ProductCard
                                    BBD={expiry}
                                    EAN={ean}
                                    MFD={mfg}
                                    MRP={mrp}
                                    colorSize={`${color ?? '_'}, ${size ?? '_'}`}
                                />
                            </li>
                        ))}
                        {skuList?.length === 0 && !isLoading && (
                            <li>
                                <Text appearance="body-xs" color="primary-grey-80">
                                    No SKUs found
                                </Text>
                            </li>
                        )}
                    </ul>
                </div>
            </main>
        </div>
    );
}
