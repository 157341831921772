import { Spinner, Text } from '@jds/core';
import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { PickListItem, PickListItemProps } from '../../../components/picklist/PickListItem';
import { useGetPickList } from '../../../hooks/outbound/picklist/useGetPickList';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { usePicklistStateSave } from '../../../hooks/outbound/picklist/usePicklistStateSave';
import { useFetchUserPickingState } from '../../../hooks/outbound/picklist/useFetchUserPickingState';
import { QuantityStatus } from '../../../provider/outbound/picklist/PicklistReducer';
import { useContext, useEffect } from 'react';
import { PicklistContext } from '../../../provider/outbound/picklist';

const layoutStyles = 'max-w-screen-md md:mx-auto';

const PickList = () => {
    const navigate = useNavigate();
    const { pickList, isLoading, isError } = useGetPickList();
    const savePicklistactivity = usePicklistStateSave();
    const { pickingStateDetails } = useFetchUserPickingState();
    const { dispatch } = useContext(PicklistContext);

    function handlePicklistSelectState(picklistId: number) {
        savePicklistactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                activity_name: 'SELECT_PICKLIST',
                status: 'COMPLETED',
            },
            {
                onSuccess: () => {
                    navigate(`${picklistId}/scan-trolley`);
                },
            }
        );
    }

    useEffect(() => {
        if (pickingStateDetails === undefined) return;
        if (pickingStateDetails) {
            const updatedFormValues: QuantityStatus = {
                damaged: 0,
                short: pickingStateDetails?.picking_quantity_details?.notPickedQuantity,
            };
            dispatch({ type: 'SET_QUANTITY_STATUS', payload: updatedFormValues });
            dispatch({
                type: 'SET_SCANNED_GOOD_ITEMS',
                payload: pickingStateDetails?.picking_quantity_details?.pickedQuantity ?? 0,
            });
            dispatch({
                type: 'SET_SCANNED_TOTAL_ITEMS',
                payload: pickingStateDetails?.picking_quantity_details?.pickedQuantity ?? 0,
            });

            const defaultPathname = `${pickingStateDetails?.pick_list_id}/`;
            switch (pickingStateDetails?.session_info?.activity_name) {
                case 'SELECT_PICKLIST':
                    navigate(defaultPathname + `scan-trolley`);
                    break;
                case 'TROLLEY_SCAN':
                    navigate(defaultPathname + `${pickingStateDetails?.trolley_id}/scan-tote`);
                    break;
                case 'PICKING_TOTE_SCAN':
                    if (pickingStateDetails?.session_info.status === 'COMPLETED')
                        navigate(
                            defaultPathname +
                                `${pickingStateDetails?.trolley_id}/${pickingStateDetails?.tote_id}/scan-location`
                        );
                    else navigate(defaultPathname + `${pickingStateDetails?.trolley_id}/scan-tote`);
                    break;
                case 'GET_PICKING_LOCATION_DETAILS':
                    navigate(
                        defaultPathname +
                            `${pickingStateDetails?.trolley_id}/${pickingStateDetails?.tote_id}/scan-location`
                    );
                    break;
                case 'PICKING_JIO_CODE_SCAN':
                    if (pickingStateDetails?.session_info.status === 'IN_PROGRESS')
                        navigate(
                            defaultPathname +
                                `${pickingStateDetails?.trolley_id}/${pickingStateDetails?.tote_id}/scan-jio-code`
                        );
                    else if (
                        pickingStateDetails?.session_info.status === 'COMPLETED' &&
                        pickingStateDetails?.picking_complete
                    ) {
                        localStorage.setItem(
                            'picking_jio_code_scan_complete',
                            JSON.stringify(true)
                        );
                        navigate(
                            defaultPathname +
                                `${pickingStateDetails?.trolley_id}/${pickingStateDetails?.tote_id}/help?location_id=${pickingStateDetails?.item_details[0]?.location_id}`
                        );
                    } else if (
                        pickingStateDetails?.session_info.status === 'COMPLETED' &&
                        !pickingStateDetails?.picking_complete
                    ) {
                        navigate(
                            defaultPathname +
                                `${pickingStateDetails?.trolley_id}/${pickingStateDetails?.tote_id}/scan-jio-code`
                        );
                    }
                    break;
                case 'GET_PICKING_SUMMARY':
                    navigate(defaultPathname + `scan-drop-zone`);
                    break;
                default:
                    navigate('');
            }
        }
    }, [dispatch, navigate, pickingStateDetails]);

    return (
        <>
            <div className={cn(layoutStyles)}>
                <div className={cn(pageHeaderStyle)}>
                    <PageHeaderV2
                        isExtended
                        title="Select a pick list"
                        onBackIconClick={() => {
                            navigate('../../sections/outbound');
                        }}
                    />
                </div>
                <main className={cn('mx-2 mt-6')}>
                    {isLoading && (
                        <div className={cn('flex items-center justify-center')}>
                            <Spinner size="small" />
                        </div>
                    )}

                    {!isLoading && !isError && pickList?.length === 0 && (
                        <Text appearance="body-m" className={cn('pt-20 text-center')}>
                            No pick list is available.
                        </Text>
                    )}

                    {!isError && pickList?.length > 0 && (
                        <div>
                            {pickList.map((pickListItem: PickListItemProps) => {
                                return (
                                    <PickListItem
                                        key={pickListItem.id}
                                        label={`Pick list ${pickListItem.id} (${pickListItem.noOfLocations})`}
                                        onItemClick={() =>
                                            handlePicklistSelectState(pickListItem?.id ?? 0)
                                        }
                                    />
                                );
                            })}
                        </div>
                    )}
                </main>
            </div>
        </>
    );
};

export default PickList;
