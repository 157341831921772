import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Button, Notifications } from '@jds/core';
import { useContext, useState } from 'react';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';
import { ToteDropOptions, useDropTote } from '../../../hooks/receiving/useDropTote';
import { ValidateToteOptions, useValidateTote } from '../../../hooks/receiving/useValidateTote';
import {
    GetConsolidationStatusSchema,
    useGetConsolidationStatusList,
} from '../../../hooks/outbound/consolidation/useGetConsolidationStatus';
import { useConsolidationStateSave } from '../../../hooks/outbound/consolidation/useConsolidationStateSave';
import {
    UpdateDamagedProductsSchema,
    useUpdateDamagedProducts,
} from '../../../hooks/outbound/consolidation/useDamagedProducts';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'tote-code', label: 'Tote Code', type: 'text' };
export function ScanNewDamagedTote() {
    const navigate = useNavigate();
    const saveConsolidationToteDropactivity = useConsolidationStateSave();
    const { stationId, toteCode, jioCode, shipmentId, lotId } = useParams();
    const [validateTote, dropTote] = [useValidateTote(), useDropTote()];
    const [currentToteCode, setCurrentToteCode] = useState('');
    const { state, dispatch } = useContext(ConsolidationContext);
    const quantityCompletedTillNow = state.consolidationToteScannedQuantity;
    const consolidateJioCodesList = state.consolidationJioCodesList;
    const consolidateDamagedJioCodes = state.consolidationDamagedJioCodes;
    const jioCodeDetails = state?.consolidationJioCodesList?.find(
        (item) => item.shipmentId === shipmentId && item.jioCode === jioCode
    );
    const getJioCodeIndex = () => {
        const val = consolidateJioCodesList.findIndex((item) => {
            return item.shipmentId === shipmentId && item.jioCode === jioCode;
        });
        return val;
    };

    const updateDamagedProducts = useUpdateDamagedProducts();
    const handleUpdateDamagedProducts = (data: UpdateDamagedProductsSchema[]) => {
        updateDamagedProducts.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    dropTote.mutate(dropToteOptions, {
                        onSuccess: () => {
                            Notifications.toast.success({
                                title: 'Success',
                                description: 'Damaged item dropped',
                            });
                            const ind = getJioCodeIndex();
                            const quantity = consolidateJioCodesList[ind].scannedQuantity ?? 0;
                            consolidateJioCodesList[ind].scannedQuantity = quantity + 1;
                            dispatch({
                                type: 'SET_CONSOLIDATION_DAMAGED_TOTE_CODE',
                                payload: currentToteCode,
                            });
                            dispatch({
                                type: 'SET_CONSOLIDATION_JIOCODES_LIST',
                                payload: consolidateJioCodesList,
                            });
                            dispatch({
                                type: 'SET_CONSOLIDATION_DAMAGED_JIOCODES',
                                payload: [
                                    {
                                        jioCode: jioCode || '',
                                        lotId: Number(lotId) || 0,
                                    },
                                ],
                            });
                            dispatch({
                                type: 'SET_CONSOLIDATION_SCANNED_TOTE_QUANTITY',
                                payload: quantityCompletedTillNow + 1,
                            });
                            handleDamagedToteCodeScan();
                        },
                        onError: () => {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Failed to Drop Damaged item',
                            });
                        },
                    });
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    function onScanSuccess(tote_code: string) {
        setCurrentToteCode(tote_code);
        const data: ValidateToteOptions = {
            quantity: 1,
            tote_code,
            product_type: 'DAMAGED',
            jio_code: jioCode ?? '',
            tote_purpose: 'CONSOLIDATION_OUTBOUND_QC',
            lot_id: Number(lotId) ?? 0,
            location_id: stationId || '',
            tote_params: {
                orderId: jioCodeDetails?.orderId || '',
                shipmentId: shipmentId || '',
            },
        };
        validateTote.mutate(data, {
            onSuccess: () => {},
        });
    }

    const getConsolidationStatusList = useGetConsolidationStatusList();
    const handleConsolidationStatusList = (data: GetConsolidationStatusSchema[]) => {
        getConsolidationStatusList.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    if (res?.data?.length > 0) {
                        dispatch({ type: 'SET_CONSOLIDATED_ORDERS_DETAILS', payload: res.data });
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/bulk/success`);
                    } else {
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
                    }
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const dropToteOptions: ToteDropOptions = {
        jio_code: jioCode ?? '',
        product_type: 'DAMAGED',
        tote_purpose: 'CONSOLIDATION_OUTBOUND_QC',
        totes: [{ tote_code: currentToteCode ?? '', quantity: 1 }],
        location_id: stationId || '',
        lot_id: Number(lotId) ?? 0,
        tote_params: {
            orderId: jioCodeDetails?.orderId || '',
            shipmentId: shipmentId || '',
        },
    };

    const handleDamagedToteCodeScan = () => {
        saveConsolidationToteDropactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_TOTE_CONSOLIDATION',
                status: 'COMPLETED',
                shipmentId: shipmentId,
                jioCode: jioCode,
                toteId: toteCode || '',
                productReceivingSource: 'CONSOLIDATION_OUTBOUND_QC',
                productType: 'DAMAGED',
                quantity: 1,
                locationId: stationId,
                lotId: Number(jioCodeDetails?.lotId) || 0,
            },
            {
                onSuccess: () => {
                    handleConsolidationStatusList([
                        {
                            order_id: jioCodeDetails?.orderId || '',
                            jio_code: jioCode || '',
                            tote_code: currentToteCode,
                            shipment_id: shipmentId || '',
                        },
                    ]);
                },
            }
        );
    };

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    showBackIcon={true}
                    title="Damaged Tote Code"
                    onBackIconClick={() => {
                        if (consolidateDamagedJioCodes?.length > 0) {
                            navigate(-1);
                        } else {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Unable to navigate back',
                            });
                        }
                    }}
                />
                <main className={cn('mx-3 mt-4 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        optionalValues={{
                            station_id: stationId ?? '',
                        }}
                        purpose="DAMAGED"
                        type="TOTE_CODE"
                        onSuccess={(value) => {
                            setCurrentToteCode(value);
                            onScanSuccess(value);
                        }}
                    />
                </main>
            </div>
            <div
                className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    disabled={currentToteCode === ''}
                    size="large"
                    type="submit"
                    onClick={() => {
                        handleUpdateDamagedProducts([
                            {
                                cancelled_quantity: 1,
                                cancellation_reason: 'damaged',
                                order_id: jioCodeDetails?.orderId || '',
                                shipment_id: shipmentId || '',
                                jio_code: jioCode || '',
                                tote_code: toteCode || '',
                                cancelledDuringPacking: false,
                            },
                        ]);
                    }}
                >
                    Drop Complete
                </Button>
            </div>
        </div>
    );
}
