import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../utils/urls';

/**
 * This hook is used to fetch the current user details
 */
export function useCurrentUser() {
    const username = sessionStorage.getItem('username');
    return useQuery<UserDetails>({
        queryKey: ['user', username],
        queryFn: async () => {
            const response = await axios.get(urls.FETCH_USER_DETAILS(username ?? ''));
            return response.data;
        },
    });
}

interface UserDetails {
    uid: number;
    firstName: string;
    lastName: string;
    userName: string;
    contactNumber: string;
    address: string;
    email: string;
    rfcId: number;
    role: string;
}
