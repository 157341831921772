import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';
import { useContext } from 'react';
import { PicklistContext } from '../../../provider/outbound/picklist';
import { useParams } from 'react-router-dom';

const PickListSuccess = () => {
    const { dropZoneId } = useParams();
    const { dispatch } = useContext(PicklistContext);
    dispatch({ type: 'RESET_ALL' });
    localStorage.clear();

    return (
        <>
            <SuccessPageV2
                message={`Items are dropped at ${dropZoneId} location Picking Complete`}
            />
        </>
    );
};

export default PickListSuccess;
