import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { useContext, useState } from 'react';
import { Button, Container, Notifications, Text } from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useDropTote, ToteDropOptions } from '../../../hooks/receiving/useDropTote';
import { useValidateTote, ValidateToteOptions } from '../../../hooks/receiving/useValidateTote';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';
import { useConsolidationStateSave } from '../../../hooks/outbound/consolidation/useConsolidationStateSave';
import {
    GetConsolidationStatusSchema,
    useGetConsolidationStatusList,
} from '../../../hooks/outbound/consolidation/useGetConsolidationStatus';

const manualInputProps = { name: 'tote-id', label: 'Tote ID', type: 'text' };
export function UnknownItemToteDropPage() {
    const navigate = useNavigate();
    const { stationId, toteCode, jioCode, shipmentId, lotId } = useParams();
    const saveConsolidationToteDropactivity = useConsolidationStateSave();
    const [currentToteId, setCurrentToteId] = useState('');
    const { state, dispatch } = useContext(ConsolidationContext);
    const quantityCompletedTillNow = state.consolidationToteScannedQuantity;
    const consolidateJioCodesList = state.consolidationJioCodesList;

    const jioCodeDetails = state?.consolidationJioCodesList?.find(
        (item) => item.shipmentId === shipmentId && item.jioCode === jioCode
    );
    const getJioCodeIndex = () => {
        const val = consolidateJioCodesList.findIndex((item) => {
            return item.shipmentId === shipmentId && item.jioCode === jioCode;
        });
        return val;
    };
    const [validateTote, dropTote] = [useValidateTote(), useDropTote()];

    function onScanSuccess(tote_code: string) {
        const data: ValidateToteOptions = {
            quantity: 1,
            tote_code,
            product_type: 'GOOD',
            jio_code: jioCode ?? '',
            tote_purpose: 'CONSOLIDATION_CANCELLED_ORDERS',
            lot_id: Number(lotId) ?? 0,
            location_id: stationId || '',
            tote_params: {
                orderId: jioCodeDetails?.orderId || '',
                shipmentId: shipmentId || '',
            },
        };
        validateTote.mutate(data, {
            onSuccess: () => setCurrentToteId(tote_code),
            onError: () => setCurrentToteId(''),
        });
    }

    const dropToteOptions: ToteDropOptions = {
        jio_code: jioCode ?? '',
        product_type: 'GOOD',
        tote_purpose: 'CONSOLIDATION_CANCELLED_ORDERS',
        totes: [{ tote_code: currentToteId ?? '', quantity: 1 }],
        location_id: stationId || '',
        lot_id: Number(lotId) ?? 0,
        tote_params: {
            orderId: jioCodeDetails?.orderId || '',
            shipmentId: shipmentId || '',
        },
    };

    const getConsolidationStatusList = useGetConsolidationStatusList();
    const handleConsolidationStatusList = (data: GetConsolidationStatusSchema[]) => {
        getConsolidationStatusList.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    if (res?.data?.length > 0) {
                        dispatch({ type: 'SET_CONSOLIDATED_ORDERS_DETAILS', payload: res.data });
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/bulk/success`);
                    } else {
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
                    }
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const handleUnknownToteCodeScan = () => {
        saveConsolidationToteDropactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_TOTE_CONSOLIDATION',
                status: 'COMPLETED',
                locationId: stationId,
                shipmentId: shipmentId,
                jioCode: jioCode,
                toteId: toteCode || '',
                productReceivingSource: 'CONSOLIDATION_CANCELLED_ORDERS',
                productType: 'GOOD',
                quantity: 1,
                lotId: Number(jioCodeDetails?.lotId) || 0,
            },
            {
                onSuccess: () => {
                    const ind = getJioCodeIndex();
                    const quantity = consolidateJioCodesList[ind].scannedQuantity ?? 0;
                    consolidateJioCodesList[ind].scannedQuantity = quantity + 1;
                    dispatch({
                        type: 'SET_CONSOLIDATION_JIOCODES_LIST',
                        payload: consolidateJioCodesList,
                    });
                    dispatch({
                        type: 'SET_CONSOLIDATION_SCANNED_TOTE_QUANTITY',
                        payload: quantityCompletedTillNow + 1,
                    });
                    navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
                },
            }
        );
    };

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Unknown tote"
                    onBackIconClick={() => {
                        Notifications.toast.error({
                            title: 'Error',
                            description: 'Unable to navigate back',
                        });
                    }}
                />
                <div className={cn('mx-3 space-y-2 py-3 pl-8')}>
                    <Text appearance="body-xxs" color="primary-grey-100">
                        Tote ID
                    </Text>
                    <Text appearance="body-m-bold" color="primary-60">
                        {state.consolidationCurrentUnknownItemsToteCode}
                    </Text>
                </div>
            </div>
            <main className={cn('mx-4 mt-6')}>
                <Text appearance="body-xs-bold" className={cn('mx-2')} color="primary-grey-100">
                    Input Tote ID
                </Text>
                <Container
                    className={cn('bg-cronical-gradient my-4 px-5 pt-6 pb-3')}
                    rounded="medium"
                >
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        searchableList={[
                            { result: state.consolidationCurrentUnknownItemsToteCode },
                        ]}
                        onSuccess={(value) => {
                            if (value === state.consolidationCurrentUnknownItemsToteCode) {
                                onScanSuccess(value);
                            } else {
                                Notifications.toast.error({
                                    title: 'Error',
                                    description: 'Tote ID does not match',
                                });
                            }
                        }}
                    />
                </Container>
            </main>
            <div
                className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    className={cn('!mb-3')}
                    disabled={currentToteId !== ''}
                    kind="secondary"
                    size="large"
                    type="submit"
                    onClick={() => {
                        navigate(
                            `/outbound/consolidation/${stationId}/${toteCode}/${jioCode}/${shipmentId}/${lotId}`
                        );
                    }}
                >
                    Add New Unknown Tote
                </Button>
                <Button
                    fullWidth
                    disabled={currentToteId === ''}
                    size="large"
                    type="submit"
                    onClick={() => {
                        dropTote.mutate(dropToteOptions, {
                            onSuccess: () => {
                                handleUnknownToteCodeScan();
                            },
                        });
                    }}
                >
                    Drop Complete
                </Button>
            </div>
        </div>
    );
}
