import { cn } from '../../utils/tailwind';
import { Button, Divider, Icon, Spinner, Text } from '@jds/core';
import { ProductCard } from '../shared/ProductCard';
import { Package } from '../../types/receiving';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { IcChevronUp, IcChevronDown } from '@jds/core-icons';

interface CompatibleSKUsSectionProps {
    skuList: Package[];
    isLoading: boolean;
    enableShowMore?: boolean;
}
export function CompatibleSKUsSection({
    skuList,
    isLoading,
    enableShowMore = false,
}: CompatibleSKUsSectionProps) {
    const { stationId } = useParams();
    const [showMore, setShowMore] = useState(false);
    const toggle = () => {
        setShowMore(!showMore);
    };

    const getRenderedItems = () => {
        if (showMore || !enableShowMore) {
            return skuList;
        }
        return skuList.slice(0, 2);
    };

    return (
        <>
            <div className={cn('-mx-6')}>
                <Divider padding="m" />
            </div>
            <div className={cn('mx-3 space-y-4')}>
                <div className={cn('flex items-center justify-between')}>
                    <Text appearance="body-s-bold" color="primary-grey-80">
                        Compatible SKUs
                    </Text>
                    <Text appearance="body-xxs" color="primary-grey-80">
                        Station ID- {stationId}
                    </Text>
                </div>
                {isLoading && (
                    <div className={cn('flex justify-center')}>
                        <Spinner size="small" />
                    </div>
                )}
                <ul className={cn('space-y-4')}>
                    {getRenderedItems()?.map(
                        ({ bestBefore, ean, manufacturedDate, mrp, colour, size, imageUrls }) => (
                            <li key={ean}>
                                <ProductCard
                                    BBD={bestBefore}
                                    EAN={ean}
                                    MFD={manufacturedDate}
                                    MRP={mrp}
                                    colorSize={`${colour ?? '_'}, ${size ?? '_'}`}
                                    image={imageUrls?.at(0)}
                                />
                            </li>
                        )
                    )}
                    {enableShowMore && skuList?.length > 2 && (
                        <Button
                            fullWidth
                            aria-label="perform accordion level actions"
                            className={cn('mt-2 flex rounded p-1')}
                            kind="tertiary"
                            onClick={() => toggle()}
                        >
                            <div className={cn('flex')}>
                                <div className="flex items-center pl-20">
                                    <Text className={cn('font-bold')} color="primary-50">
                                        {!showMore ? 'show more' : 'show less'}
                                    </Text>
                                </div>
                                <div className={cn('flex items-center justify-center')}>
                                    {showMore ? (
                                        <Icon
                                            aria-label="hide-accordian-content"
                                            color="primary"
                                            ic={<IcChevronUp />}
                                            size="l"
                                            onClick={() => toggle()}
                                        />
                                    ) : (
                                        <Icon
                                            aria-label="show-accordian-content"
                                            color="primary"
                                            ic={<IcChevronDown />}
                                            size="l"
                                            onClick={() => toggle()}
                                        />
                                    )}
                                </div>
                            </div>
                        </Button>
                    )}
                    {skuList?.length === 0 && !isLoading && (
                        <li>
                            <Text appearance="body-xs" color="primary-grey-80">
                                No SKUs found
                            </Text>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
}
