import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { ErrorResponse, SuccessResponse, handleError } from './usePackingSuggestionList';
export interface UpdateScannedQuantitySchema {
    shipmentId: string;
    jiocode: string;
    quantity: number;
}

const defaultErrorMsg = 'Something went wrong.';
export function useUpdateScannedOrderQuantity() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, UpdateScannedQuantitySchema>(
        async (body: UpdateScannedQuantitySchema) => {
            const response = await axios.post(urls.UPDATE_PACKING_SCANNED_QUANTITY(), body);
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
