import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Button, Container, Notifications, ProgressBar, Spinner, Text } from '@jds/core';
import { useContext, useState } from 'react';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import {
    useAssignJioCode,
    AssignJioCodeSchema,
} from '../../../hooks/outbound/consolidation/useAssignJioCode';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { ProductCard } from '../../../components/shared/ProductCard';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';
import { useConsolidationProducts } from '../../../hooks/outbound/consolidation/useConsolidationProducts';
import {
    useUpdateDamagedProducts,
    UpdateDamagedProductsSchema,
} from '../../../hooks/outbound/consolidation/useDamagedProducts';
import {
    useGetConsolidationStatusList,
    GetConsolidationStatusSchema,
} from '../../../hooks/outbound/consolidation/useGetConsolidationStatus';
import { CancelledOrderList } from './MarkShortPage';
import {
    consolidationStateSaveSchema,
    useConsolidationStateSave,
} from '../../../hooks/outbound/consolidation/useConsolidationStateSave';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'jio-code', label: 'Jio Code', type: 'text' };

export function ConsolidationMainPage() {
    const navigate = useNavigate();
    const { stationId, toteCode } = useParams();
    const queryParams = useQueryParams();
    const { data: products, isLoading } = useConsolidationProducts();
    const [currentJioCode, setCurrentJioCode] = useState('');
    const [currentLotId, setCurrentLotId] = useState(0);
    const { state, dispatch } = useContext(ConsolidationContext);
    const jioCodeKeys = state?.consolidationJioCodesList
        ?.filter(
            (item) => item.scannedQuantity === undefined || item?.scannedQuantity < item?.quantity
        )
        .map((item) => ({
            result: item.jioCode,
        }));
    const saveConsolidationactivity = useConsolidationStateSave();

    const consolidateJioCodesList = state.consolidationJioCodesList;
    const totalQuantity = state.consolidationToteTotalQuantity;
    const [quantityCompletedTillNow, setQuantityCompletedTillNow] = useState(
        state.consolidationToteScannedQuantity
    );
    const [showWarning, setShowWarning] = useState(false);
    const [showToteDrop, setShowToteDrop] = useState(false);
    const [currentShipmentId, setcurrentShipmentId] = useState('');
    const [currentOrderId, setcurrentorderId] = useState('');
    const [markShort, setMarkShort] = useState(false);

    const handleMarkDamaged = () => {
        dispatch({
            type: 'SET_CONSOLIDATION_SCANNED_TOTE_QUANTITY',
            payload: quantityCompletedTillNow,
        });
        navigate(`/outbound/consolidation/${stationId}/${toteCode}/mark-damaged`);
    };

    const validateJioCode = (jioCode: string) => {
        const val = consolidateJioCodesList.findIndex(
            (item: { jioCode: string; scannedQuantity: number; quantity: number }) => {
                return item.jioCode === jioCode;
            }
        );
        return val;
    };

    const getLocation = useAssignJioCode();
    const getLocationDetails = (data: AssignJioCodeSchema) => {
        getLocation.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    if (res?.data?.consolidationStatus === 'WAIT_FOR_PTW_TO_BE_EMPTY') {
                        Notifications.toast.error({
                            title: 'Validation Failed',
                            description: 'Wait for PTW to be empty',
                        });
                    } else {
                        if (res?.data?.consolidationStatus === 'UNKNOWN') {
                            setShowToteDrop(true);
                            setShowWarning(true);
                            setcurrentShipmentId(data?.shipment_id ?? '');
                            setcurrentorderId(data?.order_id ?? '');
                        } else {
                            handleJioCodeScanState(
                                data,
                                `/outbound/consolidation/${stationId}/${toteCode}/${data.jio_code}/${res.data.shipmentId}/${res.data.ptwlocation}/${res.data.crateId}/location-details`,
                                'IN_PROGRESS'
                            );
                        }
                    }
                }
            },
            onError(error: any) {
                const defaultErrorMsg = 'Something went wrong';
                const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
                if (Array.isArray(errors)) {
                    errors.forEach((description) => {
                        Notifications.toast.error({ title: 'Error', description });
                    });
                } else if (error.response?.data?.message) {
                    const description = error.response?.data?.message ?? defaultErrorMsg;
                    Notifications.toast.error({ title: 'Error', description });
                } else {
                    const description = errors ?? defaultErrorMsg;
                    Notifications.toast.error({ title: 'Error', description });
                }
            },
        });
    };

    const updateDamagedProducts = useUpdateDamagedProducts();
    const handleUpdateDamagedProducts = (data: UpdateDamagedProductsSchema[]) => {
        updateDamagedProducts.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    handleJioCodeScanState(
                        {
                            jio_code: '',
                            request_type: '',
                            crate_id: '',
                            ptw_location: '',
                            index: 0,
                        },
                        null,
                        'COMPLETED'
                    );
                    handleConsolidationStatusList(data);
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const getShortItemsList = () => {
        const cancelledOrderList: CancelledOrderList[] = [];
        consolidateJioCodesList.map((item: any) => {
            const scannedquantity = item.scannedQuantity ?? 0;
            if (scannedquantity < item.quantity) {
                cancelledOrderList.push({
                    cancelled_quantity: item.quantity - scannedquantity,
                    cancellation_reason: 'not available',
                    order_id: item.orderId,
                    jio_code: item.jioCode,
                    tote_code: toteCode || '',
                    shipment_id: item.shipmentId,
                    cancelledDuringPacking: false,
                });
            }
        });
        return cancelledOrderList;
    };

    const getConsolidationStatusList = useGetConsolidationStatusList();
    const handleConsolidationStatusList = (data: GetConsolidationStatusSchema[]) => {
        getConsolidationStatusList.mutate(data, {
            onSuccess(res: any) {
                setMarkShort(false);
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    if (res?.data?.length > 0) {
                        dispatch({ type: 'SET_CONSOLIDATED_ORDERS_DETAILS', payload: res.data });
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/bulk/success`);
                    } else {
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/success`);
                    }
                }
            },
            onError: (error: any) => {
                setMarkShort(false);
                const defaultErrorMsg = 'Something went wrong';
                const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
                if (Array.isArray(errors)) {
                    errors.forEach((description) => {
                        Notifications.toast.error({ title: 'Error', description });
                    });
                } else if (error.response?.data?.message) {
                    const description = error.response?.data?.message ?? defaultErrorMsg;
                    Notifications.toast.error({ title: 'Error', description });
                } else {
                    const description = errors ?? defaultErrorMsg;
                    Notifications.toast.error({ title: 'Error', description });
                }
            },
        });
    };

    function handleJioCodeScanState(
        data: AssignJioCodeSchema,
        url: string | null,
        state: 'IN_PROGRESS' | 'COMPLETED'
    ) {
        const payload: consolidationStateSaveSchema = {
            username: sessionStorage.getItem('username') ?? '',
            rfc_code: sessionStorage.getItem('rfc-code') ?? '',
            rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
            activity_name: 'SCAN_JIO_CODE_CONSOLIDATION',
            status: state,
            toteId: toteCode,
            locationId: stationId,
            shipmentId: data.shipment_id,
        };
        if (data.jio_code !== '') {
            payload.jioCode = data.jio_code;
            payload.quantity = 1;
            payload.lotId = data.lotId;
        }
        saveConsolidationactivity.mutate(payload, {
            onSuccess: () => {
                if (url) navigate(url);
            },
        });
    }

    const handleExcessJioCodeScan = () => {
        saveConsolidationactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_TOTE_CONSOLIDATION',
                status: 'IN_PROGRESS',
                shipmentId: currentShipmentId,
                orderId: currentOrderId,
                jioCode: currentJioCode,
                toteId: toteCode || '',
                productReceivingSource: 'CONSOLIDATION_CANCELLED_ORDERS',
                productType: 'GOOD',
                quantity: 1,
                locationId: stationId,
                lotId: Number(currentLotId),
            },
            {
                onSuccess: () => {
                    if (state.consolidationCurrentUnknownItemsToteCode !== '')
                        navigate(
                            `/outbound/consolidation/${stationId}/${toteCode}/${currentJioCode}/${currentShipmentId}/${currentLotId}/drop-unknown-item`
                        );
                    else
                        navigate(
                            `/outbound/consolidation/${stationId}/${toteCode}/${currentJioCode}/${currentShipmentId}/${currentLotId}/scan-new-unknown-tote`
                        );
                },
            }
        );
    };

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Scan jio code"
                    onBackIconClick={() => {
                        if (queryParams.get('pendingActivity') === 'true') {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Unable to navigate back',
                            });
                        } else {
                            navigate(`../${stationId}/scan-tote`);
                        }
                    }}
                />
                <div className={cn('mx-3 mt-4 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        searchableList={jioCodeKeys}
                        onSuccess={(value) => {
                            setCurrentJioCode(value);
                            const val = validateJioCode(value);
                            if (val !== -1 && quantityCompletedTillNow <= totalQuantity) {
                                setCurrentLotId(consolidateJioCodesList[val].lotId);
                                getLocationDetails({
                                    jio_code: value,
                                    request_type: 'GET_CRATE',
                                    crate_id: '',
                                    ptw_location: '',
                                    shipment_id: consolidateJioCodesList[val].shipmentId,
                                    order_id: consolidateJioCodesList[val].orderId,
                                    tote_id: toteCode,
                                    index: val | 0,
                                    lotId: consolidateJioCodesList[val].lotId,
                                });
                            } else {
                                setShowToteDrop(false);
                                setShowWarning(true);
                                setcurrentShipmentId('');
                                setcurrentorderId('');
                            }
                        }}
                    />
                </div>
            </div>
            <main className={cn('mx-3 mt-4')}>
                <ProgressBar
                    className={cn('my-4 mx-1')}
                    label="Total Quantity in Tote"
                    value={quantityCompletedTillNow / totalQuantity}
                    valueLabel={`${quantityCompletedTillNow}/${totalQuantity}`}
                />
                <div className={cn('inset-x-0 bottom-0  space-y-2.5 bg-white py-5', layoutStyles)}>
                    <Container background="primary-grey-20" pad="m" rounded="large">
                        <Text className={cn('mb-4 flex justify-center')}>
                            Is the item damaged ?
                        </Text>
                        <Button
                            fullWidth
                            kind="secondary"
                            size="large"
                            onClick={() => handleMarkDamaged()}
                        >
                            Mark Damaged
                        </Button>
                    </Container>
                </div>
                <Text className={cn('mt-1')}>Total Items List</Text>
                <div className={cn('mb-20 mt-3 space-y-4')}>
                    {products?.length === 0 && (
                        <Text className={cn('text-center')}>No products found!</Text>
                    )}
                    {isLoading && <Spinner className={cn('flex justify-center')} size="small" />}
                    {products?.map((product) => (
                        <ProductCard
                            key={product.jio_code}
                            BBD={product.expiry}
                            EAN={product.ean ?? product.ean}
                            MFD={product.mfg}
                            MRP={product.mrp}
                            colorSize={`${product?.color ?? '-'}, ${product?.size ?? '-'}`}
                            image={product?.image_urls?.[0]}
                        />
                    ))}
                </div>
                <div
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                        layoutStyles
                    )}
                >
                    <Button
                        fullWidth
                        kind="primary"
                        size="large"
                        onClick={() => {
                            if (quantityCompletedTillNow !== totalQuantity) {
                                setMarkShort(true);
                            } else {
                                handleJioCodeScanState(
                                    {
                                        jio_code: '',
                                        request_type: '',
                                        crate_id: '',
                                        ptw_location: '',
                                        index: 0,
                                    },
                                    `/outbound/consolidation/${stationId}/${toteCode}/success`,
                                    'COMPLETED'
                                );
                            }
                        }}
                    >
                        Consolidation Complete
                    </Button>
                </div>
                {showWarning && (
                    <InfoModalSection
                        ModalHeader="Unknown Item"
                        buttonText={showToteDrop ? 'Drop in a tote' : 'Continue'}
                        infoText=""
                        infoType="ERROR"
                        showCloseIcon={true}
                        onSuccess={(value) => {
                            setShowWarning(false);
                            if (value !== 'closed' && showToteDrop) {
                                handleExcessJioCodeScan();
                            }
                        }}
                    />
                )}
                {markShort && (
                    <InfoModalSection
                        ModalHeader={`Mark ${
                            totalQuantity - quantityCompletedTillNow
                        } items short ?`}
                        buttonText="Confirm"
                        infoText=""
                        infoType="WARNING"
                        showCloseIcon={true}
                        showModalBool={markShort}
                        onSuccess={(value) => {
                            if (value !== 'closed') {
                                handleUpdateDamagedProducts(getShortItemsList());
                            } else {
                                setMarkShort(false);
                            }
                        }}
                    />
                )}
            </main>
        </div>
    );
}
