import { cn } from '../../utils/tailwind';
import { Container, Divider, Icon, Text } from '@jds/core';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { IcOrder } from '@jds/extended-icons';

interface ToteDetails {
    tote_code: string;
    storage_zone: string;
    ready_for_put_away: boolean;
    reason_for_not_put_away: string;
}

export function TotesInfo() {
    const [availableTotes, setAvailableTotes] = useState<Array<ToteDetails>>([]);
    const [unavailableTotes, setUnavailableTotes] = useState<string[]>([]);

    const segregateTotesList = (data: any[]) => {
        if (data?.length > 0) {
            const tempAvailableTotes: any[] | ((prevState: ToteDetails[]) => ToteDetails[]) = [];
            const tempUnavailableTotes: any[] | ((prevState: string[]) => string[]) = [];
            data.map((item) => {
                if (item.ready_for_put_away === true) tempAvailableTotes.push(item);
                else if (item.ready_for_put_away === false)
                    tempUnavailableTotes.push(item.tote_code);
            });
            setAvailableTotes(tempAvailableTotes);
            setUnavailableTotes(tempUnavailableTotes);
        }
        return [];
    };

    const totesList = useQueryClient().getQueryData<any>(['totes-list']); // change this any later
    useEffect(() => {
        segregateTotesList(totesList);
    }, [totesList]);

    const getColorForStorageZone = (zone: string) => {
        if (zone === '19') {
            return 'bg-green-300';
        } else {
            return 'bg-blue-300';
        }
    };

    return (
        <>
            <div className={cn('mx-3 mt-3')}>
                {availableTotes.length >= 0 && (
                    <>
                        <div className={cn('flex items-center space-x-4')}>
                            <Text appearance="body-s-bold" color="secondary-grey-100">
                                Totes available for putaway
                            </Text>
                            <Text appearance="body-s-bold" color="secondary-grey-100">
                                {availableTotes.length}
                            </Text>
                        </div>
                        <div className={cn('mt-2 space-y-4')}>
                            {availableTotes.map((id) => (
                                <Container
                                    key={id.tote_code}
                                    className={cn(
                                        'grid grid-cols-2 space-x-3 rounded-lg border border-[var(--color-secondary-grey-60)] p-4'
                                    )}
                                >
                                    <Icon
                                        className={cn('absolute left-4 mt-2 bg-white')}
                                        color="grey-60"
                                        ic={<IcOrder />}
                                    />
                                    <Text appearance="body-xs">Tote ID</Text>
                                    <Text
                                        appearance="body-xs"
                                        className={cn('!ml-6 !text-[#FFAA66]')}
                                    >
                                        Storage Zone
                                    </Text>
                                    <Text appearance="body-s-bold" className={cn('ml-5')}>
                                        {id.tote_code}
                                    </Text>
                                    <Text
                                        appearance="body-s-bold"
                                        className={cn('!ml-6 !text-[#FFAA66]')}
                                    >
                                        {id.storage_zone}
                                    </Text>
                                    <div
                                        className={cn(
                                            'absolute right-6 mt-[-17px] h-[78px] w-[11px] rounded-r-lg bg-[#FFAA66]'
                                        )}
                                    />
                                </Container>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {unavailableTotes.length >= 0 && (
                <Container
                    background="primary-grey-20"
                    className={cn('mt-4')}
                    pad="m"
                    rounded="large"
                >
                    <div className={cn('flex items-center space-x-4')}>
                        <Text appearance="body-s-bold" color="primary-grey-80">
                            Totes not available for putaway
                        </Text>
                        <Text appearance="body-s-bold" color="primary-grey-80">
                            {unavailableTotes.length}
                        </Text>
                    </div>
                    {unavailableTotes.map((id, index) => (
                        <>
                            <Container
                                key={id}
                                as="div"
                                className={cn(
                                    `flex space-x-4 ${
                                        index === unavailableTotes.length - 1 ? 'pt-3' : 'py-3'
                                    }`
                                )}
                            >
                                <Icon color="grey-60" ic={<IcOrder />} />
                                <Text appearance="body-s" color="primary-grey-60">
                                    {id}
                                </Text>
                            </Container>
                            {index !== unavailableTotes.length - 1 && <Divider />}
                        </>
                    ))}
                </Container>
            )}
        </>
    );
}
