import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useContext, useEffect } from 'react';
import { useUnloadingByUser } from '../../../hooks/returns/unloading/useUnloadingByUser';
import { UnloadingContext } from '../../../provider/returns/unloading';

const manualInputProps = { name: 'station-id', label: 'Station ID', type: 'text' };
export function ScanStation() {
    const navigate = useNavigate();
    const validateInventory = useInventoryDetails();
    const { mutate } = useUnloadingByUser();
    const { dispatch } = useContext(UnloadingContext);

    const onScanSuccess = (stationId: string) => {
        validateInventory.mutate(
            { inventoryCode: stationId, type: 'RECEIVING_STATION' },
            {
                onSuccess: () => navigate(`/returns/unloading/${stationId}`),
            }
        );
    };

    useEffect(() => {
        mutate(undefined, {
            onSuccess: (data: any) => {
                if (data) {
                    const completedProducts = data?.products.filter(
                        (item: any) => item.product_status === 'RETURN_UNLOADING_COMPLETED'
                    );
                    dispatch({
                        type: 'SET_COMPLETED_PRODUCTS',
                        payload: completedProducts.map((item: any) => item.jio_code),
                    });
                    dispatch({
                        type: 'SET_APPOINTMENT_TYPE',
                        payload: data?.return_type === 'RVP' ? 'C-Returns' : 'Return To Vendor',
                    });
                    navigate(
                        `./SKAB/${data.appointment_no}/${data.return_order_id}/products-to-return`
                    );
                }
            },
        });
    }, [dispatch, mutate, navigate]);

    return (
        <div className={cn(pageHeaderStyle)}>
            <PageHeaderV2 title="Station ID" onBackIconClick={() => navigate(-1)} />
            <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount
                    autoSuggest={true}
                    inputProps={manualInputProps}
                    type="RECEIVING_STATION"
                    onSuccess={onScanSuccess}
                />
            </div>
        </div>
    );
}
