import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Notifications, ProgressBar, Text } from '@jds/core';
import { Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledInput } from '../../../components/shared/jds';
import { useForm } from 'react-hook-form';
import {
    useUpdateDamagedProducts,
    UpdateDamagedProductsSchema,
} from '../../../hooks/outbound/consolidation/useDamagedProducts';
import {
    GetConsolidationStatusSchema,
    useGetConsolidationStatusList,
} from '../../../hooks/outbound/consolidation/useGetConsolidationStatus';

export interface CancelledOrderList {
    cancelled_quantity: number;
    cancellation_reason: string;
    order_id: string;
    jio_code: string;
    tote_code: string;
    shipment_id: string;
    cancelledDuringPacking: boolean;
}

const layoutStyles = 'max-w-screen-md md:mx-auto';
export const possibleReasons: { label: string; value: keyof MarkShortValidationSchema }[] = [
    { label: 'Short', value: 'shortQuantity' },
];

const markShortValidationSchema = yup.object({
    shortQuantity: yup.number().typeError('').min(0),
});
export type MarkShortValidationSchema = yup.InferType<typeof markShortValidationSchema>;

export function ConsolidationMarkShortPage() {
    const navigate = useNavigate();
    const { stationId, toteCode } = useParams();

    const { handleSubmit, watch, control } = useForm<MarkShortValidationSchema>({
        resolver: yupResolver(markShortValidationSchema),
    });

    const consolidateJioCodesListJson = localStorage.getItem('consolidate-jioCodes-list') || '[]';
    const consolidateJioCodesList = JSON.parse(consolidateJioCodesListJson);

    const totalQuantity = Number(localStorage.getItem('ConsolidationToteTotalQuantity'));
    const quantityCompletedTillNow = Number(
        localStorage.getItem('ConsolidationToteQuantityTillNow')
    );
    const [totalFormQuantity, setTotalFormQuantity] = useState(quantityCompletedTillNow);

    useEffect(() => {
        const subscription = watch(({ shortQuantity }) => {
            const finalQuantity = quantityCompletedTillNow + Number(shortQuantity);
            const isNotValid =
                Number.isNaN(finalQuantity) || finalQuantity < 0 || finalQuantity > totalQuantity;
            setTotalFormQuantity(isNotValid ? 0 : finalQuantity);
        });
        return () => subscription.unsubscribe();
    }, [quantityCompletedTillNow, totalQuantity, watch]);

    function onFormSubmit() {
        handleUpdateDamagedProducts(getShortItemsList());
    }

    const updateDamagedProducts = useUpdateDamagedProducts();
    const handleUpdateDamagedProducts = (data: UpdateDamagedProductsSchema[]) => {
        updateDamagedProducts.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    handleConsolidationStatusList(data);
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const getShortItemsList = () => {
        const cancelledOrderList: CancelledOrderList[] = [];
        consolidateJioCodesList.map((item: any) => {
            const scannedquantity = item.scannedQuantity ?? 0;
            if (scannedquantity < item.quantity) {
                cancelledOrderList.push({
                    cancelled_quantity: item.quantity - scannedquantity,
                    cancellation_reason: 'not available',
                    order_id: item.orderId,
                    jio_code: item.jioCode,
                    tote_code: toteCode || '',
                    shipment_id: item.shipmentId,
                    cancelledDuringPacking: false,
                });
            }
        });
        return cancelledOrderList;
    };

    const getConsolidationStatusList = useGetConsolidationStatusList();
    const handleConsolidationStatusList = (data: GetConsolidationStatusSchema[]) => {
        getConsolidationStatusList.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    localStorage.setItem(
                        'ConsolidationToteQuantityTillNow',
                        JSON.stringify(totalFormQuantity)
                    );
                    if (res?.data?.length > 0) {
                        localStorage.setItem(
                            'ConsolidatedOrdersTillNow',
                            JSON.stringify(res?.data)
                        );
                        navigate(`/consolidation/${stationId}/${toteCode}/bulk/success`);
                    } else {
                        navigate(`/consolidation/${stationId}/${toteCode}/main`);
                    }
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    return (
        <form className={cn(layoutStyles, 'py-3')} onSubmit={handleSubmit(onFormSubmit)}>
            <PageHeader
                title="Mark Short"
                onBackIconClick={() => {
                    navigate(-1);
                }}
            />
            <main className={cn('mx-6 mt-3 mb-20')}>
                <div className={cn('space-y-2.5')}>
                    <ProgressBar
                        label="Order Quantity"
                        value={totalFormQuantity / totalQuantity}
                        valueLabel={`${totalFormQuantity} / ${totalQuantity}`}
                    />
                </div>
                <div className={cn('mt-6 grid grid-cols-2 items-center gap-y-6')}>
                    {possibleReasons.map(({ label, value }) => (
                        <Fragment key={label + value}>
                            <label htmlFor={label}>
                                <Text appearance="body-m" as="span" color="primary-grey-100">
                                    {label}
                                </Text>
                            </label>
                            <div className={cn('w-full')}>
                                {/*
                                    Using important here to override the default margin given by JDS Input
                                    because we do not have a label in the figma design and JDS Input has a default margin-top
                                    set for the label.
                                */}
                                <div className={cn('mx-auto max-w-[52px]')}>
                                    <ControlledInput
                                        className={cn('!mt-1')}
                                        control={control}
                                        inputMode="numeric"
                                        label=""
                                        name={value}
                                        type="number"
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </main>
            <div className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}>
                <Button
                    fullWidth
                    disabled={totalFormQuantity !== totalQuantity}
                    size="large"
                    type="submit"
                >
                    Confirm Short
                </Button>
            </div>
        </form>
    );
}
