import { useContext } from 'react';
import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';
import { ReceivingContext } from '../../../provider/inbound/receiving';
import { useParams } from 'react-router-dom';
export function SuccessPage() {
    const { awbNo } = useParams();
    const { dispatch } = useContext(ReceivingContext);
    dispatch({ type: 'RESET_ALL' });
    localStorage.clear();
    return (
        <>
            <SuccessPageV2
                message={`Receiving task 
                completed successfully or Airway bill no. ${awbNo}`}
            />
        </>
    );
}
