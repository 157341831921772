import { cn } from '../../../utils/tailwind';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { Container, Divider, Icon, Notifications, ProgressBar, Text } from '@jds/core';
import { useContext, useEffect, useState } from 'react';
import { ReceivingContext } from '../../../provider/returns/receiving';
import { useNavigate, useParams } from 'react-router-dom';
import { IcSuccess } from '@jds/core-icons';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { DropListSection } from '../../../components/receiving/DropListSection';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import {
    QuantityDetails,
    useFetchDropListQuantity,
} from '../../../hooks/returns/receiving/useFetchDropListQuantity';
import { useProductDetails } from '../../../hooks/cubiscan/useProductDetails';
import { useReturnOrders } from '../../../hooks/returns/unloading/useReturnOrders';

export const gridStyles = 'grid grid-cols-[1fr_70px_1fr]';

export type DropQuantities = keyof ReturnType<typeof useDropQuantities>;
export function useDropQuantities() {
    const { quantityStatus } = useContext(ReceivingContext).state;
    const { DAMAGE_A, DAMAGE_A_PLUS, DAMAGE_B, LOW_FRESHNESS, goodQuantity, ...restReasons } =
        quantityStatus;
    const liquidation = DAMAGE_A + DAMAGE_A_PLUS + DAMAGE_B + LOW_FRESHNESS;
    const scrap = [...Object.values(restReasons)].reduce((acc, curr) => acc + curr, 0);
    return { liquidation, scrap, good: goodQuantity };
}

export function UnsealableSummaryPage() {
    const navigate = useNavigate();
    const { jioCode } = useParams();
    const { quantityStatus } = useContext(ReceivingContext).state;
    const { dropListQuantity } = useFetchDropListQuantity();
    const { dispatch } = useContext(ReceivingContext);
    const { data: returnOrders } = useReturnOrders(false);
    const product = returnOrders?.products.find(({ jio_code }) => jio_code === jioCode);
    const { data: currentProductDetails } = useProductDetails(
        jioCode ?? '',
        String(product?.lot_id) ?? 0
    );
    const dropCompletedInitialValues = {
        goodQuantity: 0,
        liquidationQuantity: 0,
        scrapQuantity: 0,
    };
    const [isLoading, setIsLoading] = useState(false);
    const [totalDroppedQty, setTotalDroppedQty] = useState(0);
    const [dropCompletedValues, setDropCompletedValues] = useState(dropCompletedInitialValues);
    const [showEANSuccessModal, setShowEANSuccessModal] = useState(false);

    useEffect(() => {
        const refetchBool = localStorage.getItem('refetch');
        if (dropListQuantity.length < 1 || refetchBool === 'true') return;
        setIsLoading(true);
        const updatedFormValues = {
            goodQuantity: 0,
            liquidationQuantity: 0,
            scrapQuantity: 0,
        };

        let isToteDropStarted = false;
        let startedToteType = '';
        let toteDropCount = 0;
        let isCurrentJioCodeDone = true;
        let startedToteQuantity = 0;
        dropListQuantity.map((value: QuantityDetails) => {
            isToteDropStarted = isToteDropStarted || value.tote_drop_status === 'TOTE_DROP_STARTED';
            startedToteType =
                value.tote_drop_status === 'TOTE_DROP_STARTED'
                    ? value.product_type
                    : startedToteType;
            if (startedToteType !== '' && startedToteQuantity === 0) {
                startedToteQuantity = value.total_quantity;
            }
            toteDropCount =
                value.tote_drop_status === 'TOTE_DROP_COMPLETED'
                    ? toteDropCount + 1
                    : toteDropCount;
            isCurrentJioCodeDone =
                value.jio_code === jioCode &&
                value.tote_drop_status === 'TOTE_DROP_COMPLETED' &&
                isCurrentJioCodeDone;
            if (value.product_type === 'GOOD') {
                updatedFormValues.goodQuantity = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.goodQuantity =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            } else if (value.product_type === 'LIQUIDATION') {
                updatedFormValues.liquidationQuantity =
                    value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.liquidationQuantity =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            } else if (value.product_type === 'SCRAP') {
                updatedFormValues.scrapQuantity = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.scrapQuantity =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            }
        });

        dispatch({ type: 'SET_QUANTITY_STATUS', payload: updatedFormValues });
        setTotalDroppedQty(
            dropCompletedValues.goodQuantity +
                dropCompletedValues.liquidationQuantity +
                dropCompletedValues.scrapQuantity
        );

        setDropCompletedValues(dropCompletedValues);
        let pathname = '';
        if (isToteDropStarted) {
            switch (startedToteType) {
                case 'GOOD':
                    pathname = `../assign-tote/goodQuantity/GOOD/${startedToteQuantity}`;
                    break;
                case 'LIQUIDATION':
                    pathname = `../assign-tote/liquidationQuantity/LIQUIDATION/${startedToteQuantity}`;
                    break;
                case 'SCRAP':
                    pathname = `../assign-tote/scrapQuantity/SCRAP/${startedToteQuantity}`;
                    break;
            }
        }
        setIsLoading(false);
        if (pathname !== '') {
            navigate(pathname + '/scan');
        }

        if (toteDropCount === dropListQuantity.length && isCurrentJioCodeDone) {
            setShowEANSuccessModal(true);
        }
    }, [dispatch, dropCompletedValues, dropListQuantity, jioCode, navigate]);

    return (
        <div className={cn(layoutStyles, 'pb-3')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Drop list"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                <div className={cn('mx-5 mt-3 flex pb-3')}>
                    <Icon color="primary" ic={<IcSuccess />} size="m" />
                    <Text appearance="body-s-bold" className={cn('ml-2')} color="primary-50">
                        Paste the labels and drop in totes
                    </Text>
                </div>
            </div>
            {isLoading && (
                <ProgressBar
                    className={cn('mx-6 mt-24')}
                    label="loading"
                    type="indeterminate"
                    value={0.3}
                />
            )}
            {!isLoading && (
                <main className={cn('mx-6 mt-3 mb-20')}>
                    <div className={cn('mt-5 grid items-center gap-y-6')}>
                        {quantityStatus.goodQuantity > 0 && (
                            <DropListSection
                                backgroundColour="sparkle-20"
                                successCallBack={() => {
                                    navigate(
                                        `../assign-tote/goodQuantity/GOOD/${quantityStatus.goodQuantity}/scan`
                                    );
                                }}
                                title="Good Quantity"
                                valueToDisplay={quantityStatus.goodQuantity}
                            />
                        )}
                        {quantityStatus.liquidationQuantity + quantityStatus.scrapQuantity > 0 && (
                            <Container
                                background="feedback-error-20"
                                className={cn('w-full')}
                                pad="s"
                                rounded="medium"
                            >
                                <>
                                    <div className={cn('mr-16 mb-6 flex justify-between')}>
                                        <Text appearance="body-s-bold" color="feedback-error-50">
                                            Unsaleable Quantity
                                        </Text>
                                        <div className={cn('flex justify-end')}>
                                            <Text
                                                appearance="body-s-bold"
                                                className={cn('mx-7')}
                                                color="feedback-error-50"
                                            >
                                                {dropCompletedValues.liquidationQuantity +
                                                    dropCompletedValues.scrapQuantity}
                                            </Text>
                                        </div>
                                    </div>
                                    {quantityStatus.liquidationQuantity > 0 && (
                                        <DropListSection
                                            backgroundColour="feedback-error-20"
                                            showPadding={false}
                                            successCallBack={() => {
                                                navigate(
                                                    `../assign-tote/liquidationQuantity/LIQUIDATION/${quantityStatus.liquidationQuantity}/scan`
                                                );
                                            }}
                                            title="Liquidation"
                                            valueToDisplay={quantityStatus.liquidationQuantity}
                                        />
                                    )}
                                    {quantityStatus.scrapQuantity > 0 && (
                                        <DropListSection
                                            backgroundColour="feedback-error-20"
                                            showPadding={false}
                                            successCallBack={() => {
                                                navigate(
                                                    `../assign-tote/scrapQuantity/SCRAP/${quantityStatus.scrapQuantity}/scan`
                                                );
                                            }}
                                            title="Scrap"
                                            valueToDisplay={quantityStatus.scrapQuantity}
                                        />
                                    )}
                                </>
                            </Container>
                        )}
                    </div>
                    {totalDroppedQty > 0 && (
                        <div className={cn('mt-6')}>
                            <Divider />
                            <Text
                                appearance="body-s-bold"
                                className={cn('mt-4 ml-3')}
                                color="primary-grey-80"
                            >
                                Drop Completed
                            </Text>
                        </div>
                    )}
                    <div className={cn('mt-5 grid items-center gap-y-6')}>
                        {dropCompletedValues.goodQuantity > 0 && (
                            <DropListSection
                                backgroundColour="sparkle-20"
                                showConfirmIcon={true}
                                showDrop={false}
                                successCallBack={() => {}}
                                title="Good Quantity"
                                valueToDisplay={dropCompletedValues.goodQuantity}
                            />
                        )}
                        {dropCompletedValues.liquidationQuantity +
                            dropCompletedValues.scrapQuantity >
                            0 && (
                            <Container background="feedback-error-20" pad="s" rounded="medium">
                                <>
                                    <div className={cn('mr-16 mb-6 flex justify-between')}>
                                        <Text appearance="body-s-bold" color="feedback-error-50">
                                            Unsaleable Quantity
                                        </Text>
                                        <div className={cn('flex justify-end')}>
                                            <Text
                                                appearance="body-s-bold"
                                                className={cn('mx-7')}
                                                color="feedback-error-50"
                                            >
                                                {dropCompletedValues.liquidationQuantity +
                                                    dropCompletedValues.scrapQuantity}
                                            </Text>
                                        </div>
                                    </div>
                                    {dropCompletedValues.liquidationQuantity > 0 && (
                                        <DropListSection
                                            backgroundColour="feedback-error-20"
                                            showConfirmIcon={true}
                                            showDrop={false}
                                            showPadding={false}
                                            successCallBack={() => {}}
                                            title="Liquidation"
                                            valueToDisplay={dropCompletedValues.liquidationQuantity}
                                        />
                                    )}
                                    {dropCompletedValues.scrapQuantity > 0 && (
                                        <DropListSection
                                            backgroundColour="feedback-error-20"
                                            showConfirmIcon={true}
                                            showDrop={false}
                                            showPadding={false}
                                            successCallBack={() => {}}
                                            title="Scrap"
                                            valueToDisplay={dropCompletedValues.scrapQuantity}
                                        />
                                    )}
                                </>
                            </Container>
                        )}
                    </div>
                </main>
            )}
            {showEANSuccessModal && (
                <InfoModalSection
                    ModalHeader={`Jio Code ${currentProductDetails?.jio_code} receiving completed successfully`}
                    buttonText="Continue"
                    infoText=""
                    infoType="SUCCESS"
                    showCloseIcon={true}
                    onSuccess={() => {
                        setShowEANSuccessModal(false);
                        localStorage.setItem('EAN_SCANNED_LIST', 'TRUE');
                        navigate('../../scan-jio-code');
                    }}
                />
            )}
        </div>
    );
}
