import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { Notifications } from '@jds/core';
import { ResponseType } from '../../../types/fetch';

export interface pickListStateSaveSchema {
    username: string;
    rfc_code: string;
    status: 'PENDING' | 'IN_PROGRESS' | 'COMPLETED';
    activity_name:
        | 'SELECT_PICKING_ACTIVITY'
        | 'SELECT_PICKLIST'
        | 'TROLLEY_SCAN'
        | 'PICKING_TOTE_SCAN'
        | 'GET_PICKING_LOCATION_DETAILS'
        | 'PICKING_JIO_CODE_SCAN'
        | 'PICKING_UPDATE_JIO_CODE_QUANTITY_IN_ADMIN'
        | 'PICKING_IN_PROGRESS'
        | 'PICKING_MARK_SHORT'
        | 'PICKING_MARK_DAMAGED'
        | 'GET_PICKING_SUMMARY'
        | 'PICKING_COMPLETE'
        | 'PICKING_DROP_ZONE_SCAN'
        | 'PICK_NEXT_TASK'
        | 'PICKING_ITEM_SHORT'
        | 'PICKING_ITEM_DAMAGED'
        | 'PICKING_TOTE_SCAN_TOTE_FULL';
}

export function usePicklistStateSave() {
    return useMutation<unknown, AxiosError<ResponseType<unknown>>, pickListStateSaveSchema>({
        mutationFn: async (payload) => {
            const response = await axios.post(urls.OUTBOUND_STATE_SAVE(), payload);
            return response.data;
        },
        onError: (error) => {
            const defaultError = 'Something went wrong';
            const messages = error.response?.data?.meta?.errors ?? [defaultError];
            messages.forEach((description) => {
                Notifications.toast.error({ title: 'Error', description });
            });
        },
    });
}
