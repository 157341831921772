import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';

export function PutawaySuccess() {
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname;
    const getSuccessMessage = () => {
        let inventory = '';
        if (routePrefix.includes('inventory')) {
            inventory = routePrefix.includes('gtl-putaway') ? 'GTL' : 'Stock Take';
        }
        // else {
        //     inventory = routePrefix.split('/')[1];
        // }
        return `${inventory} Put away list completed successfully`;
    };
    const queryClient = useQueryClient();
    queryClient.clear();

    return <SuccessPageV2 message={getSuccessMessage()} />;
}
