import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { useParams } from 'react-router-dom';
import { ResponseType } from '../../../types/fetch';
import { Notifications } from '@jds/core';

export function useProofOfReceipt() {
    const created_by = sessionStorage.getItem('username');
    const { appointmentNumber } = useParams();
    if (!appointmentNumber) throw new Error('Appointment Number not found');
    return useMutation<ResponseType<unknown>, AxiosError<ResponseType<never>>, string>({
        mutationFn: async (remarks) => {
            const body = { created_by, remarks };
            const response = await axios.post(urls.PROOF_OF_RECEIPT(appointmentNumber), body);
            return response.data;
        },
        onError: (error) => {
            const defaultErrorMessage = 'Something went wrong';
            const messages = error?.response?.data?.meta?.errors ?? [defaultErrorMessage];
            messages.forEach((message) => {
                Notifications.toast.error({
                    title: 'Error',
                    description: message,
                });
            });
        },
    });
}
