import { cn } from '../../utils/tailwind';
import { Spinner, Text } from '@jds/core';

import { PageHeader } from '../../components/shared/PageHeader';
import { GtlTaskItem, gtlTaskListProps } from '../../components/gtl/GtlTaskItem';
import { groupListByKey } from '../../utils/helper';
import { useGetGtlTaskList } from '../../hooks/gtl/useGetGtlTaskList';

const layoutStyles = 'max-w-screen-md md:mx-auto';

const GtlTaskListStatus = () => {
    const { gtlTaskList, isLoading, isError } = useGetGtlTaskList();
    const groupedDataObj = groupListByKey(gtlTaskList, 'reason');

    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <PageHeader showBackIcon={false} title="GTL" />
            <main className={cn('mx-2 mt-8')}>
                <div className={cn('mb-4 flex justify-between')}>
                    <Text appearance="body-m-bold" color="primary-grey-80">
                        Taks list
                    </Text>
                    <Text appearance="body-m" color="primary-grey-60">
                        Total -{' '}
                        {gtlTaskList.length > 1
                            ? `${gtlTaskList.length} items`
                            : `${gtlTaskList.length} item`}
                    </Text>
                </div>
                {isLoading && (
                    <div className={cn('flex items-center justify-center')}>
                        <Spinner size="small" />
                    </div>
                )}

                {!isLoading && !isError && gtlTaskList?.length === 0 && (
                    <Text appearance="body-m" className={cn('pt-20')}>
                        No task is available.
                    </Text>
                )}
                {groupedDataObj &&
                    Object.keys(groupedDataObj).map((key, keyIndex) => {
                        return (
                            <div key={key}>
                                <Text appearance="body-s-bold" color="primary-grey-100">
                                    {key}
                                </Text>

                                {groupedDataObj[key].map((taskItem: gtlTaskListProps) => {
                                    return (
                                        <GtlTaskItem
                                            key={taskItem.uid}
                                            sourceStorageZone={taskItem.sourceStorageZone}
                                            uid={taskItem.uid}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
            </main>
        </div>
    );
};

export default GtlTaskListStatus;
