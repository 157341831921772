import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { useFetchUserReceivingState } from '../../../hooks/receiving/useFetchUserReceivingState';
import { useContext, useEffect } from 'react';
import { ReceivingContext } from '../../../provider/inbound/receiving';
import { QuantityStatus } from '../../../provider/inbound/receiving/ReceivingReducer';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';

const manualInputProps = { name: 'station-id', label: 'Station ID', type: 'text' };
export function ReceivingHomePage() {
    const navigate = useNavigate();
    const { dispatch } = useContext(ReceivingContext);

    const { receivingStateDetails } = useFetchUserReceivingState();

    const validateInventory = useInventoryDetails();
    const onScanSuccess = (stationId: string) => {
        validateInventory.mutate(
            { inventoryCode: stationId, type: 'RECEIVING_STATION' },
            {
                onSuccess: () => navigate(stationId),
            }
        );
    };

    useEffect(() => {
        if (receivingStateDetails === undefined) return;
        if (receivingStateDetails) {
            const updatedFormValues: QuantityStatus = {
                good: receivingStateDetails?.goodQuantity ?? 0,
                batchIssue: receivingStateDetails?.batchIssueQuantity ?? 0,
                damaged: receivingStateDetails?.damagedQuantity ?? 0,
                excess: receivingStateDetails?.excessQuantity ?? 0,
                expired: receivingStateDetails?.expiredQuantity ?? 0,
                lowFreshness: receivingStateDetails?.lowFreshnessQuantity ?? 0,
                short: receivingStateDetails?.shortQuantity ?? 0,
                wrong: receivingStateDetails?.wrongQuantity ?? 0,
                failed: 0,
                cubiscan: receivingStateDetails.isCubiscanRequired === true ? 1 : 0,
            };
            updatedFormValues.failed =
                updatedFormValues.batchIssue +
                updatedFormValues.damaged +
                updatedFormValues.expired +
                updatedFormValues.lowFreshness +
                updatedFormValues.wrong;
            dispatch({ type: 'SET_QUANTITY_STATUS', payload: updatedFormValues });
        }
        const defaultPathname = `${receivingStateDetails?.stationId}/${receivingStateDetails?.appointmentId}/${receivingStateDetails?.secondaryCase}/${receivingStateDetails?.primaryCase}/${receivingStateDetails?.jioCode}`;
        switch (receivingStateDetails?.asnValidationStatus) {
            case 'Completed':
                localStorage.setItem('receiving_state', 'QC_COMPLETED');
                navigate(defaultPathname + '/verify-quantities/summary');
                break;
            case 'LABELS_PRINTING_DONE':
                navigate(defaultPathname + '/drop-list');
                break;
            default:
                navigate('');
        }
    }, [dispatch, navigate, receivingStateDetails]);

    return (
        <div>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Station ID"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />

                <main className={cn('mx-3 mt-4 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        type="RECEIVING_STATION"
                        onSuccess={onScanSuccess}
                    />
                </main>
            </div>
        </div>
    );
}
