import { Container, Text } from '@jds/core';
import { cn } from '../../utils/tailwind';

export interface PickListItemProps {
    id?: number;
    label: string;
    assignedUser?: string;
    type?: string;
    noOfLocations?: number;
    noOfSkus?: number;
    noOfUnits?: number;
    startPoint?: string;
    status?: string;
    currentLocation?: string;
    trolleyId?: string | null;
    rfcCode?: string;
    onItemClick?: () => void;
}
export function PickListItem({ label, onItemClick }: PickListItemProps) {
    return (
        <Container
            background="primary-grey-20"
            className={cn('my-4 flex items-center justify-center')}
            pad="s"
            rounded="xl"
            onClick={onItemClick}
        >
            <Text appearance="body-m">{label}</Text>
        </Container>
    );
}
