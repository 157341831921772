const WMS_ADMIN_URL = 'http://35.200.151.96:8080/wms/admin';
const WMS_INBOUND_URL = 'http://35.200.151.96:8080/wms/inbound';
const WMS_RETURNS_URL = 'http://35.200.151.96:8080/wms/returns';
const WMS_OUTBOUND_URL = 'http://35.200.151.96:8080/wms/outbound';

export const urls = {
    AUTHENTICATE_USER() {
        return `${WMS_ADMIN_URL}/user/authenticate`;
    },
    GET_USER_RECEIVING_STATE() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const userName = sessionStorage.getItem('username') ?? '';
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/user/${userName}/active_asn`;
    },
    GET_SECONDARY_CASES(rfcId: string, appointmentNumber: number) {
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentNumber}/secondary-cases`;
    },
    GET_PACKAGES(appointmentNumber: number, packageId?: string) {
        const endpoint = packageId ?? 'all';
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentNumber}/package/${endpoint}`;
    },
    GET_SCANNED_PACKAGES(appointmentNumber: number, packageId?: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentNumber}/scanned_packages?secondaryCase=${packageId}`;
    },
    GENERATE_LABEL(appointmentId: number, primaryCaseId: string, jioCode: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentId}/${primaryCaseId}/jio_code/${jioCode}/label`;
    },
    UPDATE_ASN_STATUS(appointmentId: number) {
        const rfcId = sessionStorage.getItem('rfcId');
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentId}/asn-status/labels`;
    },
    CUBISCAN_OVERRIDE_DETAILS() {
        const rfcId = sessionStorage.getItem('rfcId');
        return `${WMS_ADMIN_URL}/cubiscan/rfc/${rfcId}`;
    },
    RETURN_LABEL() {
        const rfcId = sessionStorage.getItem('rfcId');
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/labels/`;
    },
    GET_USER_CUBISCAN_STATE() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const userName = sessionStorage.getItem('username') ?? '';
        return `${WMS_ADMIN_URL}/cubiscan/rfc/${rfcId}/user-name/${userName}`;
    },
    GET_CUBISCAN_DETAILS(toteCode: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/cubiscan/rfc/${rfcId}/tote-code/${toteCode}`;
    },
    CUBISCAN_FLOW(toteCode: string, jioCode: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/cubiscan/rfc/${rfcId}/tote-code/${toteCode}/jio-code/${jioCode}`;
    },
    CUBISCAN_TO_RECEIVING(stationId: string, toteCode: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        if (!rfcId) throw new Error('rfc-Id not found');
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/map/station/${stationId}/tote/${toteCode}`;
    },
    COMPATIBLE_SKUS(storageZoneId: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        return `${WMS_ADMIN_URL}/sku_zone_mapping/compatible_skus/rfc_id/${rfcId}/storage_zone_id/${storageZoneId}`;
    },
    ASN_VALIDATION(appointmentNumber: number, jioCode: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentNumber}/asn-validation/${jioCode}`;
    },
    GET_DROPLIST_QUANTITIES(primaryCaseId: string, secondaryCaseId: string, jioCode: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/receiving/tote-drop/status/primary/${primaryCaseId}/secondary/${secondaryCaseId}/jiocode/${jioCode}`;
    },
    GET_DROPPED_TOTES_LIST(
        primaryCaseId: string,
        secondaryCaseId: string,
        jioCode: string,
        productType: string
    ) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/receiving/tote-drop/status/primary/${primaryCaseId}/secondary/${secondaryCaseId}/jiocode/${jioCode}/byType?type=${productType}`;
    },
    GET_TOTES_LIST(stationId: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/mapped-totes/station/${stationId}/totes`;
    },
    VALIDATE_TOTE(stationId: string, toteID: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/validate/station/${stationId}/tote/${toteID}`;
    },
    GET_VRN_STATUS(appointmentNumber: number) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentNumber}/vrn`;
    },
    GENERATE_PUT_LIST(stationId: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/generateputlist/station/${stationId}`;
    },
    CREATE_PUTAWAY_TASK() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const putListId = Number(localStorage.getItem('PutListId') || 0);
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/put_list/${putListId}/putawaytask`;
    },
    UPDATE_PUTAWAY_TASK() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const putListId = Number(localStorage.getItem('PutListId') || 0);
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/put_list/${putListId}/putawaytask/update`;
    },
    COMPLETE_PUTAWAY_TASK() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const putListId = Number(localStorage.getItem('PutListId') || 0);
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/put_list/${putListId}/putawaytask/complete`;
    },
    GET_PUTAWAY_BY_USER() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const userName = sessionStorage.getItem('username');
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/user/${userName}/active_put_away`;
    },
    GET_COMPATIBLE_TOTES_LIST(toteID: string, putlistId: number) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/compatible-totes/put_list/${putlistId}/tote/${toteID}/`;
    },
    GET_TOTES_PRODUCTS_LIST(toteId: string, putlistId: number) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/tote-products/put_list/${putlistId}/tote/${toteId}`;
    },
    GET_PRODUCT_DETAILS(jioCode: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/sku/product-details/rfc/${rfcId}/jio_code/${jioCode}`;
    },
    FREE_JIOCODE(toteID: string, jioCode: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/tote/${toteID}/jiocode/${jioCode}/free`;
    },
    FREE_CRATE(crateID: string, crateStatus: string) {
        return `${WMS_ADMIN_URL}/put_to_wall/crate_id/${crateID}/crate_status/${crateStatus}`;
    },
    FREE_TOTE(toteID: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/tote-drop/tote/${toteID}/free`;
    },
    DROP_TOTE() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/tote-drop/drop`;
    },
    TRANSFER_TOTE() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/tote-transfer/drop`;
    },
    VALIDATE_DROP_TOTE() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/tote-drop/validity`;
    },
    CUBISCAN_RESCAN(jioCode: string, primaryCaseId: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        if (!rfcId) throw new Error('rfc-id not found');
        const queryParams = new URLSearchParams();
        queryParams.set('jioCode', String(jioCode));
        if (primaryCaseId) queryParams.set('primaryCaseLabel', String(primaryCaseId));
        return `${WMS_ADMIN_URL}/cubiscan/rfc/${rfcId}/rescan?${queryParams.toString()}`;
    },
    UNLOADING_VERIFICATION(appointmentNumber: number) {
        const rfcId = sessionStorage.getItem('rfcId');
        if (!rfcId) throw new Error('rfc-id not found');
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentNumber}/unloading-verification`;
    },
    UNLOADING_VERIFICATION_BY_USER_NAME() {
        const rfcId = sessionStorage.getItem('rfcId');
        const userName = sessionStorage.getItem('username');
        if (!rfcId) throw new Error('rfc-id not found');
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/unloading-verification/${userName}`;
    },
    GENERATE_POD(rfcId: string, appointmentNumber: string) {
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentNumber}/document/`;
    },
    UPLOAD_SIGNED_POD(rfcId: string, appointmentNumber: string) {
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/appointment/${appointmentNumber}/uploadSignedFile`;
    },
    GET_USER_PICKING_STATE() {
        const userName = sessionStorage.getItem('username') ?? '';
        return `${WMS_OUTBOUND_URL}/api/v1/state/picking/get?userName=${userName}`;
    },
    GET_USER_CONSOLIDATION_STATE() {
        const userName = sessionStorage.getItem('username') ?? '';
        return `${WMS_OUTBOUND_URL}/api/v1/consolidation/state/get/${userName}`;
    },
    GET_CONSOLIDATION_JIOCODES_LIST() {
        return `${WMS_OUTBOUND_URL}/api/v1/order/consolidate/getJioCode`;
    },
    ASSIGN_JIOCODE() {
        return `${WMS_OUTBOUND_URL}/api/v1/order/consolidate/assignJioCode`;
    },
    ASSIGN_CRATE() {
        return `${WMS_OUTBOUND_URL}/api/v1/order/consolidate/assignCrate`;
    },
    UPDATE_CONSOLIDATION_QUANTITY() {
        return `${WMS_OUTBOUND_URL}/api/v1/order/consolidate/assignCrate`;
    },
    PLACE_ITEM_IN_TOTE() {
        return `${WMS_OUTBOUND_URL}/api/v1/order/consolidate/placeJioCodeInTote`;
    },
    GET_CONSOLIDATION_STATUS() {
        return `${WMS_OUTBOUND_URL}/api/v1/order/consolidate/checkOrderConsolidation`;
    },
    GET_RETURN_ORDERS(waybillNumber: string, platform: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        if (!rfcId) throw new Error('rfc-id not found');
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/return-orders/${waybillNumber}?stage=${platform}`;
    },
    RETURNS_UNLOADING(appointmentId: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        if (!rfcId) throw new Error('rfc-Id not found');
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/appointment/${appointmentId}/returns-unloading`;
    },
    PROOF_OF_RECEIPT(appointmentId: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        if (!rfcId) throw new Error('rfc-Id not found');
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/por/appointment=${appointmentId}`;
    },
    PRINT_Jio_CODE(jioCode: string, noOfCopies: number) {
        return `${WMS_ADMIN_URL}/print_jio_code/print_label/${jioCode}/${noOfCopies}`;
    },
    RETURNS_RECEIVING() {
        const rfcId = sessionStorage.getItem('rfcId');
        if (!rfcId) throw new Error('rfc-Id not found');
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/returns-receiving`;
    },
    UPDATE_LABEL_STATUS(returnOrderId: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/returns-receiving/return_order_id/${returnOrderId}/status/labels`;
    },
    GET_USER_RETURNS_RECEIVING_STATE() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const userName = sessionStorage.getItem('username') ?? '';
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/returns-receiving/user/${userName}`;
    },
    GENERATE_RETURNS_LABEL() {
        const rfcId = sessionStorage.getItem('rfcId');
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/labels`;
    },
    GET_DROPLIST_QUANTITIES_RETURNS(returnOrderId: string, jioCode: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/receiving/tote-drop/status/return-order-id/${returnOrderId}/jio-code/${jioCode}`;
    },
    GET_DROPPED_TOTES_LIST_RETURNS(returnOrderId: string, jioCode: string, productType: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/receiving/tote-drop/status/return-order-id/${returnOrderId}/jio-code/${jioCode}/byType?type=${productType}`;
    },
    PRODUCT_DETAILS(jioCode: string, lotId?: number) {
        const rfcId = sessionStorage.getItem('rfcId');
        if (!rfcId) throw new Error('rfc-Id not found');
        const queryParams = new URLSearchParams();
        if (lotId) queryParams.set('lot_id', String(lotId));
        return `${WMS_ADMIN_URL}/sku/product_details/rfc/${rfcId}/jio_code/${jioCode}?${queryParams.toString()}`;
    },
    GET_ORDER_LIST_FROM_CONTAINER(containerId: string, containerType: string) {
        return `${WMS_OUTBOUND_URL}/api/v1/order/container/${containerId}?type=${containerType}`;
    },
    GET_PACKING_SUGGESTION() {
        return `${WMS_OUTBOUND_URL}/api/v1/packing/material`;
    },
    UPDATE_DEFECTED_PRODUCTS() {
        return `${WMS_OUTBOUND_URL}/api/v1/packing/orderitem/defected`;
    },
    UPDATE_DAMAGED_PRODUCTS() {
        return `${WMS_OUTBOUND_URL}/api/v1/cancel/order/rfc`;
    },
    UPDATE_PACKING_SCANNED_QUANTITY() {
        return `${WMS_OUTBOUND_URL}/api/v1/packing/order/scanned/quantity/update`;
    },
    UPDATE_PACKAGING_SUGGESTION() {
        return `${WMS_OUTBOUND_URL}/api/v1/packing/order/update/`;
    },
    VALIDATE_PACKING_STATION() {
        return `${WMS_OUTBOUND_URL}/api/v1/packing/order/packingstation`;
    },
    GET_USER_PACKING_STATE() {
        const userName = sessionStorage.getItem('username') ?? '';
        return `${WMS_OUTBOUND_URL}/api/v1/packing/state/get/${userName}`;
    },
    GET_STOCK_TAKE_LIST() {
        const rfcId = sessionStorage.getItem('rfcId');
        const username = sessionStorage.getItem('username');
        if (!rfcId) throw new Error('rfc-Id not found');
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/stock-take/operator/${username}`;
    },
    SUBMIT_STOCK_TAKE(stock_take_id: string) {
        const rfcId = sessionStorage.getItem('rfcId');
        if (!rfcId) throw new Error('rfc-Id not found');
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/stock-take/${stock_take_id}`;
    },
    GENERATE_INVOICE_LABEL() {
        return `${WMS_OUTBOUND_URL}/api/v1/packing/order/confirm`;
    },
    GENERATE_SHIPPING_LABEL() {
        return `${WMS_OUTBOUND_URL}/api/v1/packing/order/shippinglabel`;
    },
    GET_PICK_LIST() {
        const userName = sessionStorage.getItem('username');
        return `${WMS_OUTBOUND_URL}/api/v1/picklist/assigned/get/${userName}`;
    },
    FETCH_USER_DETAILS(username: string) {
        return `${WMS_ADMIN_URL}/user/${username}`;
    },
    VALIDATE_TROLLEY_AND_TOTE_STATUS() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/trolley_tote_status`;
    },
    ASSIGN_PICKLIST_TROLLEY() {
        return `${WMS_OUTBOUND_URL}/api/v1/picklist/trolley/assign`;
    },
    GET_PICKLIST_LOCATION(pickListId: string) {
        return `${WMS_OUTBOUND_URL}/api/v1/picklist/location/get?pickListId=${pickListId}`;
    },
    UPDATE_JIOCODE_STATUS() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/binProduct`;
    },
    UPDATE_PICKLIST_STATUS() {
        return `${WMS_OUTBOUND_URL}/api/v1/picklist/update`;
    },
    MARK_SHORT_PICKLIST_ITEM() {
        return `${WMS_OUTBOUND_URL}/api/v1/cancel/order/rfc`;
    },
    UPDATE_PICKLIST_ITEM_QUANTITY_BY_ORDER_ID() {
        return `${WMS_OUTBOUND_URL}/api/v1/picklist/quantity/save`;
    },
    OUTBOUND_STATE_SAVE() {
        return `${WMS_OUTBOUND_URL}/api/v1/state/save`;
    },
    OUTBOUND_TOTE_DROP_STATE_SAVE() {
        return `${WMS_OUTBOUND_URL}/api/v1/state/toteDrop/save`;
    },
    GET_GTL_TASK_LIST() {
        const userName = sessionStorage.getItem('username');
        return `${WMS_ADMIN_URL}/gtl_task/${userName}/pending-tasks`;
    },
    GET_GTL_PRODUCT_DETAILS(locationId: string, jio_code: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/binProduct/jio_code/${jio_code}/location/${locationId}`;
    },
    VALIDATE_TOTE_FOR_GTL() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/tote-drop/validity`;
    },
    UPDATE_GTL_JIOCODE_STATUS() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/tote-drop/drop`;
    },
    UPDATE_GTL_TASK_STATUS(status: string, taskId: string) {
        return `${WMS_ADMIN_URL}/gtl_task/${taskId}/update-status?status=${status}`;
    },
    MAP_PUTAWAY_STATION_GTL(stationId: string, toteId: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/map/station/${stationId}/tote/${toteId}`;
    },
    UPDATE_GTL_SHORT_DAMAGE_ITEMS(taskId: string) {
        return `${WMS_ADMIN_URL}/gtl_task/${taskId}/update-failed-quantity`;
    },
    GET_INVENTORY_DETAILS(rfcId: string, inventoryCode: string, type: string) {
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/inventory/inventory-code/${inventoryCode}?type=${type}`;
    },
    GENERATE_GTL_PUTLIST(stationId: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/${rfcId}/putaway/generateputlist/station/${stationId}`;
    },
    TOTE_SEGREGATION_VALIDATION(toteId: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/stock-take/segregation-validation/tote/${toteId}`;
    },
    TOTE_TRANSFER_DROP() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/tote-transer/drop`;
    },
    GET_ADMIN_AUTO_SUGGESTIONS(
        type: string,
        search: string,
        purpose: string,
        optionalValues: { station_id: string }
    ) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const queryParams = new URLSearchParams();
        const filterKey = type === 'LOCATION' ? 'location_purpose' : 'product_type';
        if (type) queryParams.set('type', String(type));
        if (purpose) queryParams.set(filterKey, String(purpose));
        if (optionalValues.station_id) queryParams.set('station_id', optionalValues.station_id);
        queryParams.set('value', String(search));
        return `${WMS_ADMIN_URL}/rfc/${rfcId}/auto_suggestions?${queryParams.toString()}`;
    },
    GET_INBOUND_AUTO_SUGGESTIONS(type: string, search: string, purpose: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const queryParams = new URLSearchParams();
        if (type) queryParams.set('search_type', String(type));
        queryParams.set('search_term', String(search));
        return `${WMS_INBOUND_URL}/rfc/${rfcId}/${purpose}/auto-suggestions?${queryParams.toString()}`;
    },
    GET_RETURNS_AUTO_SUGGESTIONS(type: string, search: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const queryParams = new URLSearchParams();
        if (type) queryParams.set('type', String(type));
        queryParams.set('query', String(search));
        queryParams.set('size', String(5));
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/suggest/v1?${queryParams.toString()}`;
    },
    GET_OUTBOUND_AUTO_SUGGESTIONS() {
        return `${WMS_OUTBOUND_URL}/api/v1/suggest/container`;
    },
    RETURNS_UNLOADING_SHORT(appointmentId: string, returnId: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/returns-unloading/appointment/${appointmentId}/return_order_id/${returnId}/short`;
    },
    RETURNS_UNLOADING_JIOCODE(appointmentId: string, returnId: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/returns-unloading/appointment/${appointmentId}/return_order_id/${returnId}/jio_code`;
    },
    GET_COMPLETED_RETURN_ORDERS(appointmentId: string) {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/returns-unloading/appointment/${appointmentId}/awb`;
    },
    GET_RETURNS_UNLOADING_BY_USERNAME() {
        const rfcId = sessionStorage.getItem('rfcId') ?? 1;
        const userName = sessionStorage.getItem('username') ?? '';
        return `${WMS_RETURNS_URL}/rfc/${rfcId}/returns-unloading/${userName}`;
    },
};
