import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../utils/urls';
import { useParams } from 'react-router-dom';
import { Notifications } from '@jds/core';

export function useCubiscanCheck() {
    const { jioCode, primaryCaseId } = useParams();
    if (!jioCode) throw new Error('Jio code is required');

    return useMutation<boolean, AxiosError<ErrorResponse>>({
        mutationFn: async () => {
            const response = await axios.post(urls.CUBISCAN_RESCAN(jioCode, primaryCaseId || ''));
            return response.data ?? false;
        },
        onError: (error) => {
            const defaultMsg = 'Failed to check if cubiscan rescan is required';
            const description = error?.response?.data?.message ?? defaultMsg;
            Notifications.toast.error({ title: 'Cubiscan Check Failed', description });
        },
    });
}

interface ErrorResponse {
    timestamp: number;
    status: number;
    error: string;
    message: string;
    path: string;
}
