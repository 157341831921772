import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Container, Icon, Notifications, Space, Spinner, Text } from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useGetPicklistLocation } from '../../../hooks/outbound/picklist/useGetPicklistLocation';
import ConfirmationDialog from '../../../components/shared/ConfirmationDialog';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ProductCardV2 } from '../../../components/shared/ProductCardV2';
import { pageHeaderStyle } from '../../../styles/common';
import { IcKiranaStore } from '@jds/extended-icons';
import { useOutBoundProducts } from '../../../hooks/outbound/picklist/useOutBoundProducts';
import { PicklistContext } from '../../../provider/outbound/picklist';
import { usePicklistStateSave } from '../../../hooks/outbound/picklist/usePicklistStateSave';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'location-code', label: 'Location Code', type: 'text' };

const ScanLocation = () => {
    const { data: outboundOrders, isLoading } = useOutBoundProducts();
    const { state, dispatch } = useContext(PicklistContext);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const { pickingId, trolleyId, toteId } = useParams();
    const { pickingLocationsList } = useGetPicklistLocation();

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Picking Location"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                {pickingLocationsList && pickingLocationsList.length > 0 && (
                    <div className={cn(layoutStyles, 'mx-3 space-y-2 py-3 pl-8')}>
                        <Text appearance="body-xs" color="primary-grey-80">
                            Go to Storage zone
                        </Text>
                        <Text appearance="body-l" color="primary-grey-100">
                            {pickingLocationsList[0]?.storage_zone_id}
                        </Text>
                        <Text appearance="body-xs" color="primary-grey-80">
                            Location ID
                        </Text>
                        <Text appearance="heading-xs" color="primary-60">
                            {pickingLocationsList[0]?.location_id}
                        </Text>
                    </div>
                )}
            </div>
            <main className={cn(layoutStyles, 'mx-3 mb-10 mt-2')}>
                <Space value="s" />
                <>
                    <Text appearance="body-xs-bold" className={cn('ml-1 mb-2')}>
                        Jio Code
                    </Text>
                    {isLoading && <Spinner className={cn('flex justify-center')} size="small" />}
                    {outboundOrders?.map((product, index) => (
                        <div key={product?.jio_code} className={cn('my-5')}>
                            <ProductCardV2
                                BBD={product?.expiry}
                                EAN={product?.ean ?? '-'}
                                MFD={product?.mfg}
                                MRP={product?.mrp ?? '-'}
                                colorSize={`${product?.color ?? '-'}, ${product?.size ?? '-'}`}
                                image={product?.image_urls?.[0]}
                                jioCode={product.jio_code}
                                quantity={product?.expectedQuantity}
                                title={product?.product_title ?? '-'}
                            />
                        </div>
                    ))}
                </>
                <span className={cn('py-2.5 px-3', layoutStyles)}>
                    <Text appearance="body-xs-bold" className={cn('m-1')}>
                        Input Location ID
                    </Text>
                    <Container
                        className={cn('bg-cronical-gradient px-2 pt-6 pb-3')}
                        rounded="medium"
                    >
                        <ScanOrEnterManuallyV2
                            autoSuggest={true}
                            inputProps={manualInputProps}
                            searchableList={[
                                { result: pickingLocationsList[0]?.location_id || '' },
                            ]}
                            onSuccess={(value) => {
                                if (value && value === pickingLocationsList[0]?.location_id) {
                                    dispatch({
                                        type: 'SET_PICKLIST_INDEX',
                                        payload: state.picklistIndex,
                                    });
                                    navigate(
                                        `../${pickingId}/${trolleyId}/${toteId}/scan-jio-code`
                                    );
                                } else {
                                    setShowError(true);
                                }
                            }}
                        />
                    </Container>
                </span>
                <Container
                    className={cn(
                        'mx-2 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-3'
                    )}
                >
                    <Icon
                        className={cn('absolute left-2 bg-white')}
                        ic={<IcKiranaStore color="#000093" />}
                    />
                    <Text appearance="body-s">Tote Code</Text>
                    <Text
                        appearance="body-s-bold"
                        className={cn('text-center')}
                        color="primary-grey-80"
                    >
                        {toteId}
                    </Text>
                </Container>
                {showError && (
                    <ConfirmationDialog
                        icon={
                            <Icon
                                className={cn('mb-4')}
                                color="primary"
                                ic="IcErrorColored"
                                size="xl"
                            />
                        }
                        isOpened={showError}
                        message="Wrong location"
                        primaryCTALabel="Okay"
                        onPrimaryCTAClick={() => {
                            setShowError(false);
                        }}
                    />
                )}
            </main>
        </div>
    );
};

export default ScanLocation;
