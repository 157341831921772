import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Button, Container, Icon, Notifications, Text } from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useTrolleyAndToteValidation } from '../../../hooks/outbound/picklist/useTrolleyAndToteValidation';
import ConfirmationDialog from '../../../components/shared/ConfirmationDialog';
import { useAssignPicklistTrolley } from '../../../hooks/outbound/picklist/useAssignTrolley';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { IcKiranaStore } from '@jds/extended-icons';
import { usePicklistStateSave } from '../../../hooks/outbound/picklist/usePicklistStateSave';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'tote-code', label: 'Tote Code', type: 'text' };

const ScanToteCode = () => {
    const [apiErrors, setApiErrors] = useState({
        showError: false,
        errorMessage: '',
    });
    const navigate = useNavigate();
    const { pickingId, trolleyId } = useParams();
    const [toteCode, setToteCode] = useState('');

    const [validateTrolleyAndTote, assignPicklistTrolley, savePicklistactivity] = [
        useTrolleyAndToteValidation(),
        useAssignPicklistTrolley(),
        usePicklistStateSave(),
    ];

    function handleToteScanState() {
        savePicklistactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                activity_name: 'PICKING_TOTE_SCAN',
                status: 'COMPLETED',
            },
            {
                onSuccess: () => {
                    navigate(`../${pickingId}/${trolleyId}/${toteCode}/scan-location`);
                },
            }
        );
    }

    function onAssignPicklistTrolley(toteCode: string) {
        const data = {
            pickListId: Number(pickingId) || 0,
            trolleyId: trolleyId || '',
            toteId: toteCode,
            orderId: '',
            jioCode: '',
            requestType: 'TOTE',
        };

        assignPicklistTrolley.mutate(data, {
            onSuccess: () => handleToteScanState(),
        });
    }

    function onScanSuccess() {
        const data = {
            trolleyCode: trolleyId || '',
            toteCode,
            trolleyToteStatus: 'ACTIVE',
            lastUpdatedAt: `${Date.now()}`,
            lastScannedAt: `${Date.now()}`,
        };

        validateTrolleyAndTote.mutate(data, {
            onSuccess() {
                onAssignPicklistTrolley(toteCode);
            },
            onError(err: any) {
                if (err?.response?.data?.message) {
                    setApiErrors({
                        errorMessage: err.response.data.message,
                        showError: true,
                    });
                } else {
                    setApiErrors({
                        errorMessage: 'Tote not available for picking',
                        showError: true,
                    });
                }
            },
        });
    }

    return (
        <>
            <div className={cn(layoutStyles)}>
                <div className={cn(pageHeaderStyle)}>
                    <PageHeaderV2
                        isExtended
                        title="Tote Code"
                        onBackIconClick={() => {
                            navigate(-1);
                        }}
                    />
                    <div className={cn('mx-3 pb-3')}>
                        <ScanOrEnterManuallyV2
                            autoSuggest={true}
                            enableScannerOnMount={true}
                            inputProps={manualInputProps}
                            type="TOTE_CODE"
                            onSuccess={(toteCode) => {
                                if (toteCode) {
                                    setToteCode(toteCode);
                                } else {
                                    Notifications.toast.error({
                                        title: 'Error',
                                        description: 'Please enter tote code.',
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <main className={cn('mt-3')}>
                    <Container
                        className={cn(
                            'm-4 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-4'
                        )}
                    >
                        <Icon
                            className={cn('absolute left-1 bg-white')}
                            ic={<IcKiranaStore color="#000093" />}
                        />
                        <Text appearance="body-s">Trolley Code</Text>
                        <Text
                            appearance="body-s-bold"
                            className={cn('text-center')}
                            color="primary-grey-80"
                        >
                            {trolleyId}
                        </Text>
                    </Container>
                </main>
                <div
                    className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                    style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
                >
                    <Button
                        fullWidth
                        disabled={toteCode === ''}
                        size="large"
                        onClick={onScanSuccess}
                    >
                        Submit
                    </Button>
                </div>
            </div>
            {apiErrors.showError && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcErrorColored"
                            size="xl"
                        />
                    }
                    isOpened={apiErrors.showError}
                    message={apiErrors.errorMessage}
                    primaryCTALabel="Pick Another Tote"
                    onPrimaryCTAClick={() => {
                        setApiErrors({
                            errorMessage: '',
                            showError: false,
                        });
                    }}
                />
            )}
        </>
    );
};

export default ScanToteCode;
