import { useMutation } from '@tanstack/react-query';
import { ResponseType } from '../../../types/fetch';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { Notifications } from '@jds/core';

export function useFetchReturnOrders() {
    const platform = location.href.includes('/returns/receiving') ? '' : 'UNLOADING';
    return useMutation<ResponseType<ReturnOrder>, AxiosError<ResponseType<never>>, string>({
        mutationFn: async (waybill) => {
            const response = await axios.get(urls.GET_RETURN_ORDERS(waybill, platform));
            return response.data;
        },
        onError: (error) => {
            const defaultErrorMsg = 'Something went wrong while fetching return orders';
            const messages = error.response?.data?.meta?.errors ?? [defaultErrorMsg];
            messages.forEach((message) => {
                Notifications.toast.error({
                    title: 'Error',
                    description: message,
                });
            });
        },
    });
}

export interface ReturnProduct {
    jio_code: string;
    lot_id: number;
    expected_quantity: number;
    received_quantity: number;
    product_status: 'RETURN_UNLOADING_COMPLETED';
}

export interface ReturnOrder {
    products: ReturnProduct[];
    shipment_id: string;
    awb_no: string;
    order_number: string;
    return_order_id: string;
    rto_initiated_at: string;
    lsp: string;
    lot_id: number;
    return_status:
        | 'RTO Delivered'
        | 'RETURN_RECEIVED'
        | 'RETURNS_UNLOADED'
        | 'RETURNS_RECEIVING_DONE'
        | 'RETURNS_RECEIVING_STARTED';
    return_type: 'RVP' | 'RTO';
}
