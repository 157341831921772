import { cn } from '../../../utils/tailwind';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Icon, Input, Notifications, Text, debounce } from '@jds/core';
import { useVRNStatus } from '../../../hooks/returns/unloading/useVRNStatus';
import { RefObject, useCallback, useContext, useEffect, useState } from 'react';
import { UnloadingContext } from '../../../provider/returns/unloading';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { IcKiranaStore } from '@jds/extended-icons';
import { UseFormRegisterReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { urls } from '../../../utils/urls';

const appointmentSchema = yup.object({
    appointmentNumber: yup
        .number()
        .typeError('Appointment Number should be numeric')
        .required('Appointment Number is required'),
});

export type AppointmentSchema = yup.InferType<typeof appointmentSchema>;

function withForwardRef({ ref, ...fieldAttributes }: UseFormRegisterReturn) {
    return { ...fieldAttributes, forwardRef: ref as unknown as RefObject<HTMLElement> };
}
export function EnterAppointmentNumberPage() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<AppointmentSchema>({
        resolver: yupResolver(appointmentSchema),
    });
    const navigate = useNavigate();
    const { stationId } = useParams();
    const vrnStatus = useVRNStatus();
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(true);
    const { dispatch } = useContext(UnloadingContext);

    const getAutoSuggestions = (value: string) => {
        fetch(urls.GET_INBOUND_AUTO_SUGGESTIONS('APPOINTMENT_ID', value, 'RETURNS_UNLOADING'), {
            headers: {
                rfc_code: sessionStorage.getItem('rfc-code') ?? 'SKAB',
            },
        })
            .then((res) => res.json())
            .then((data: any) => {
                if (data?.data) {
                    const resultKeys = data.data.suggestions
                        .slice(0, 5)
                        .map((item: { id: string }) => ({
                            result: item.id,
                        }));
                    setSuggestions([...resultKeys]);
                }
            });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optimizedSearch = useCallback(debounce(getAutoSuggestions, 500), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeValue = (value: number) => {
        if (value) {
            optimizedSearch(value);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    function onSubmit(appointmentData: AppointmentSchema) {
        const appointmentNumber = `${appointmentData.appointmentNumber}`;
        if (!Number.isNaN(Number(appointmentNumber)) && Number(appointmentNumber) > 0) {
            vrnStatus.mutate(appointmentNumber, {
                onSuccess: ({ data }) => {
                    if (data?.dockOperationStatus !== 'Dock Assigned') {
                        Notifications.toast.error({
                            title: 'Dock not assigned',
                            description: 'Please try with a valid appointment number',
                        });
                    } else if (
                        !['C-Returns', 'Return To Vendor'].includes(data?.appointmentType ?? '')
                    ) {
                        Notifications.toast.error({
                            title: 'Invalid appointment type',
                            description: 'Please try with a valid appointment number',
                        });
                    } else {
                        dispatch({ type: 'SET_APPOINTMENT_TYPE', payload: data?.appointmentType });
                        navigate(`${appointmentNumber}/scan-air-way-bill`);
                    }
                },
            });
        } else {
            Notifications.toast.error({
                title: 'Invalid appointment number',
                description: 'Please enter a valid appointment number',
            });
        }
    }

    useEffect(() => {
        const subscription = watch(({ appointmentNumber }) => {
            onChangeValue(appointmentNumber || 0);
        });
        return () => subscription.unsubscribe();
    }, [onChangeValue, watch]);
    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Appointment Number"
                    onBackIconClick={() => navigate(-1)}
                />
                <div className={cn('max-w-screen-md px-9 py-5 md:mx-auto')}>
                    <form id="application-form" onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            aria-invalid={errors.appointmentNumber ? 'true' : 'false'}
                            aria-required="true"
                            autoComplete="appointmentNumber"
                            color="primary"
                            inputMode="numeric"
                            label="Enter Appointment Number"
                            state={errors.appointmentNumber ? 'error' : undefined}
                            stateConfig={{
                                errorText: errors.appointmentNumber?.message,
                            }}
                            {...withForwardRef(register('appointmentNumber'))}
                        />
                    </form>
                    {showSuggestions &&
                        suggestions?.map((suggestion: any) => (
                            <Container
                                key={suggestion.result}
                                className={cn(
                                    'my-2 flex border-b border-solid border-[#D1D1ED] p-4'
                                )}
                                onClick={() => {
                                    setShowSuggestions(false);
                                    onSubmit({ appointmentNumber: suggestion.result });
                                }}
                            >
                                <Text color="primary-30">{suggestion.result} </Text>
                            </Container>
                        ))}
                </div>
            </div>
            <Container
                className={cn(
                    'my-4 mx-6 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-4'
                )}
            >
                <Icon
                    className={cn('absolute left-4 bg-white')}
                    ic={<IcKiranaStore color="#000093" />}
                />
                <Text appearance="body-s"> Station ID </Text>
                <Text appearance="body-s-bold"> {stationId} </Text>
            </Container>
            <span className={cn('fixed inset-x-2 bottom-4')}>
                <Button fullWidth form="application-form" type="submit">
                    Submit
                </Button>
            </span>
        </>
    );
}
