import { Button, Container, Divider, Icon, Notifications, Text } from '@jds/core';
import { IcChevronDown, IcChevronUp } from '@jds/core-icons';
import { IcOrder } from '@jds/extended-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ProductCardV2 } from '../../../components/shared/ProductCardV2';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useGetProducts } from '../../../hooks/inventory/stockTake/useProductDetails';
import { useProductDetails } from '../../../hooks/putaway/useProductDetails';
import { PutawayTaskSchema, usePutawayTask } from '../../../hooks/putaway/UsePutawayTask';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { cn } from '../../../utils/tailwind';

const manualInputProps = { name: 'jio-code', label: 'Jio Code', type: 'text' };
export function ScanJiocode() {
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname.split('/', 3).join('/');
    const { stationId, toteId } = useParams();
    const queryClient = useQueryClient();
    const totesList = useQueryClient().getQueryData<any>(['generated-totes-list']);
    const toteCodeKeys = totesList?.find(
        (item: { tote_code: string | undefined }) => item.tote_code === toteId
    );
    const remainingItems = queryClient.getQueryData<any>(['tote-active-products']) || [];
    const completedItems = queryClient.getQueryData<any>(['tote-completed-products']) || [];
    const remainingJioCodes = remainingItems.length > 0 ? remainingItems : toteCodeKeys?.products;
    const jioCodeKeys = remainingJioCodes?.map((item: { jio_code: string }) => ({
        result: item.jio_code,
    }));
    const { data: products } = useGetProducts(
        completedItems?.map((item: any) => ({
            jioCode: item.jio_code,
            lotId: item.lot_id,
        }))
    );
    const completedProducts = products?.map((product: any) => {
        const completedItem = completedItems.find(
            (item: any) => item.jio_code === product.jio_code
        );
        return {
            ...product,
            total_quantity: completedItem.total_quantity,
        };
    });
    const [showRemainingItems, setShowRemainingItems] = useState(false);

    const putawayTask = usePutawayTask();
    const productDetails = useProductDetails();
    const handleScanJioCodeSuccess = (data: PutawayTaskSchema) => {
        putawayTask.mutate(data, {
            onSuccess(res) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'API Failed',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    localStorage.setItem(
                        'PutawayTotalQuantity',
                        res.data.total_quantity_kept_in_tote
                    );
                    localStorage.setItem('PutawayQuantityTillNow', '0');
                    localStorage.setItem('PutawayCurrentQuantity', '0');
                    localStorage.setItem('PutawayLocationsTillNow', '[]');
                    localStorage.setItem('PutawayTaskId', res.data.putaway_task_id);
                    localStorage.setItem('putawayStorageZone', res.data.suggested_storage_zone);
                    localStorage.setItem('lotId', res.data.lot_id);
                    productDetails.mutate(
                        { jioCode: data.jioCode, lotId: res.data.lot_id },
                        {
                            onSuccess(response) {
                                queryClient.setQueryData(['product-details'], response.data);
                                navigate(
                                    `${routePrefix}/${stationId}/${toteId}/${data.jioCode}/${res.data.suggested_bin_id}/scan-location`
                                );
                            },
                        }
                    );
                }
            },
            onError(err: any) {
                Notifications.toast.error({
                    title: 'Error',
                    description: err?.response?.data?.meta?.errors
                        ? err.response.data.meta.errors.join(', ')
                        : err.message,
                });
            },
        });
    };

    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Jio Code"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        inputProps={manualInputProps}
                        searchableList={jioCodeKeys}
                        onSuccess={(value) => {
                            const isValidJiocode = remainingItems
                                .map((item: any) => item.jio_code)
                                .includes(value);
                            if (isValidJiocode) {
                                handleScanJioCodeSuccess({
                                    toteId: toteId || '',
                                    jioCode: value || '',
                                    putaway_quantity: 0,
                                });
                            } else
                                Notifications.toast.error({
                                    title: 'Error',
                                    description: 'Scan valid jiocode',
                                });
                        }}
                    />
                </div>
            </div>
            <main className={cn('mx-3 mb-16 max-w-screen-md space-y-4 py-3 md:mx-auto')}>
                {remainingItems.length > 0 && (
                    <Container
                        className={cn(
                            'm-2 rounded-lg border-[1.4px] border-[var(--color-primary-60)] p-4'
                        )}
                    >
                        <Icon
                            className={cn('absolute left-2 mt-2 bg-white')}
                            ic={<IcOrder color="#000093" />}
                        />
                        <Container className={cn('grid grid-cols-2 gap-x-6 space-x-3')}>
                            <Text
                                appearance="body-xs"
                                className={cn('ml-3')}
                                color="primary-grey-80"
                            >
                                Active tote ID
                            </Text>
                            <Text appearance="body-xs" color="primary-grey-80">
                                Quantity
                            </Text>
                            <Text appearance="body-s-bold"> {toteId} </Text>
                            <div className={cn('flex justify-between')}>
                                <Text appearance="body-s-bold"> {remainingItems.length} </Text>
                                <Icon
                                    ic={showRemainingItems ? <IcChevronUp /> : <IcChevronDown />}
                                    onClick={() => setShowRemainingItems(!showRemainingItems)}
                                />
                            </div>
                        </Container>
                        {showRemainingItems && (
                            <>
                                <Divider className={cn('my-4')} />
                                <Container
                                    as="div"
                                    className={cn('ml-2 grid grid-cols-2 gap-y-3 break-words')}
                                >
                                    <Text appearance="body-s" color="secondary-grey-100">
                                        Jio code
                                    </Text>
                                    <Text
                                        appearance="body-s"
                                        className={cn('ml-6')}
                                        color="secondary-grey-100"
                                    >
                                        Quantity
                                    </Text>
                                    {remainingItems.map((item: any) => (
                                        <>
                                            <Text appearance="body-s" color="primary-grey-100">
                                                {item.jio_code}
                                            </Text>
                                            <Text
                                                appearance="body-s"
                                                className={cn('ml-9')}
                                                color="primary-grey-100"
                                            >
                                                {item.total_quantity}
                                            </Text>
                                        </>
                                    ))}
                                </Container>
                            </>
                        )}
                    </Container>
                )}
                {completedProducts && completedProducts.length > 0 && (
                    <div className={cn('m-2')}>
                        <>
                            <Text appearance="body-s-bold" className={cn('mb-2')}>
                                Putaway Completed Jio Codes ({completedProducts.length})
                            </Text>
                            {completedProducts.map((item: any) => (
                                <ProductCardV2
                                    key={item.jio_code}
                                    BBD={item.expiry}
                                    EAN={item.ean}
                                    MFD={item.mfg}
                                    MRP={item.mrp}
                                    colorSize={`${item.color ?? ''}, ${item.size ?? ''}`}
                                    image={item.image_urls?.length ? item.image_urls[0] : ''}
                                    jioCode={item.jio_code}
                                    quantity={item.total_quantity}
                                    title={item.product_title}
                                />
                            ))}
                        </>
                    </div>
                )}
            </main>
            {!remainingItems.length && (
                <span
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                        layoutStyles
                    )}
                >
                    <Button
                        fullWidth
                        onClick={() => {
                            localStorage.setItem('TotePutawayComplete', 'true');
                            navigate(`${routePrefix}/${stationId}/${toteId}/success`);
                        }}
                    >
                        Tote Putaway Complete
                    </Button>
                </span>
            )}
        </>
    );
}
