import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router';

import { urls } from '../../utils/urls';

interface SKUSchema {
    uid: number;
    skuCode: string;
    description: string;
    brand: string;
    ean: string;
    imageUrls: [];
}

interface ProductSchema {
    uid: number;
    jioCode: string;
    sku: SKUSchema;
    length: number;
    breadth: number;
    height: number;
    color: string;
    size: string;
    lengthUnitOfMeasurement: string;
}

interface lotInformationSchema {
    lotId: number;
    mfg: number;
    expiry: number;
    mrp: number;
}

export interface ProductDataSchema {
    product: ProductSchema;
    lotInformationDTO: lotInformationSchema;
    quantity: number;
    rfcCode: string;
    freshnessPercentage: number;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export function useGetProductDetails(jio_code: string) {
    const { locationId } = useParams();
    const { data, ...rest } = useQuery<ProductDataSchema>({
        queryKey: ['productDetails'],
        queryFn: async () => {
            const response = await axios.get(
                urls.GET_GTL_PRODUCT_DETAILS(locationId ?? '', jio_code)
            );
            return response.data;
        },
        cacheTime: Infinity,
        staleTime: Infinity,
    });
    return { productDetails: data, ...rest };
}
