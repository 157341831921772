import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Button, Notifications, Text } from '@jds/core';
import { layoutStyles } from '../../../styles/common';

export function ScanJioCodeOrEAN() {
    const navigate = useNavigate();
    const { dropZoneId } = useParams();
    const sourceTote = JSON.parse(localStorage.getItem('sourceToteData') ?? '{}');
    const isActive = sourceTote?.products?.some((item: any) => item.status === 'ACTIVE');

    const validateJioCode = (jioCode: string) => {
        const sourceTote = JSON.parse(localStorage.getItem('sourceToteData') || '{}');
        const product = sourceTote?.products?.find((item: any) => item.jio_code === jioCode);
        if (product) {
            if (product.current_quantity === 0) {
                Notifications.toast.error({
                    title: 'Error',
                    description: 'All products with this JioCode are scanned',
                });
            } else {
                navigate(`./../${jioCode}/${product.lot_id}/details`);
            }
        } else {
            Notifications.toast.error({
                title: 'Invalid JioCode',
                description: 'Please scan the right JioCode',
            });
        }
    };

    return (
        <div className={cn('mt-3 max-w-screen-md md:mx-auto')}>
            <PageHeader
                showBackIcon
                title="Stock take- Drop flow"
                onBackIconClick={() => navigate(-1)}
            />
            <main className={cn('mx-6 mt-6')}>
                <ScanOrEnterManuallyV2
                    inputProps={{ label: 'Jio Code', name: 'jiocode-id' }}
                    onSuccess={(jioCode) => {
                        if (jioCode) validateJioCode(jioCode);
                        else {
                            Notifications.toast.error({
                                title: 'Jio Code is required',
                                description: 'Please scan or enter a valid Jio Code',
                            });
                        }
                    }}
                />
                <div className={cn('mx-2 mt-4 flex items-center justify-between')}>
                    <Text className={cn('w-full')}>Drop Zone ID</Text>
                    <Text>{dropZoneId}</Text>
                </div>
            </main>
            {!isActive && (
                <div
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                        layoutStyles
                    )}
                >
                    <Button
                        fullWidth
                        size="large"
                        type="button"
                        onClick={() => {
                            Notifications.toast.success({
                                title: 'Success',
                                description: 'Drop completed successfully',
                            });
                            navigate('/inventory/stock-take-segregation');
                        }}
                    >
                        Drop Complete
                    </Button>
                </div>
            )}
        </div>
    );
}
