import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Notifications } from '@jds/core';
import { useQueryClient } from '@tanstack/react-query';
import {
    CompatibleListSchema,
    useCompatibleTotesList,
} from '../../../hooks/putaway/useCompatibleTotesList';
import { toteSchema } from '../../../hooks/gtl/useUpdateGtlJioCodeStatus';
import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';

export function TotePutawaySuccess() {
    const { stationId, toteId } = useParams();
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname.split('/', 3).join('/');

    const queryClient = useQueryClient();
    const compatibleTotesList = useCompatibleTotesList();
    const toteList: toteSchema[] = JSON.parse(localStorage.getItem('gtlToteIds') || '[]');
    const onSubmit = (data: CompatibleListSchema) => {
        compatibleTotesList.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    queryClient.setQueryData(['compatibletotes-list'], res.data.compatible_totes);
                    queryClient.setQueryData(
                        ['incompatibletotes-list'],
                        res.data.uncompatible_totes
                    );
                    if (!(res.data.compatible_totes.length || res.data.uncompatible_totes.length)) {
                        navigate(`${routePrefix}/${stationId}/success`);
                        localStorage.clear();
                    } else navigate(`${routePrefix}/${stationId}/scan-tote`);
                }
            },
            onError(err: any) {
                Notifications.toast.error({
                    title: 'Error',
                    description: err?.response?.data?.meta?.errors
                        ? err.response.data.meta.errors.join(', ')
                        : err.message,
                });
            },
        });
    };

    return (
        <SuccessPageV2
            buttonText="Putaway Next Tote"
            message={`Put away completed for all Jio codes in Tote ${toteId}`}
            onButtonClick={() => {
                if (routePrefix && !routePrefix.includes('putaway')) {
                    const scannedTotesList: string[] = JSON.parse(
                        localStorage.getItem('scannedPutAwayTotes') || '[]'
                    );
                    if (
                        scannedTotesList &&
                        scannedTotesList.length > 0 &&
                        scannedTotesList.length === toteList.length
                    ) {
                        navigate(`${routePrefix}/${stationId}/success`);
                    } else {
                        navigate(`${routePrefix}/${stationId}/scan-gtl-putaway-tote`);
                    }
                } else {
                    onSubmit({
                        tote_code: toteId || '',
                        storage_zone: '',
                        putListId: Number(localStorage.getItem('PutListId') || 0),
                    });
                }
            }}
        />
    );
}
