import { cn } from '../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Icon, Spinner, Text } from '@jds/core';
import { useCubiscanFlow } from '../../hooks/cubiscan/useCubiscanFlow';
import { IcOrders } from '@jds/extended-icons';
import { PageHeaderV2 } from '../../components/shared/PageHeaderV2';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { pageHeaderStyle } from '../../styles/common';
import { CubiscanContext } from '../../provider/inbound/cubiscan';
import { useContext } from 'react';

const manualInputProps = { name: 'cubiscan-label', label: 'Cubiscan Label', type: 'text' };
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ScanCubiscanLabelPage() {
    const navigate = useNavigate();
    const cubiscanFlow = useCubiscanFlow();
    const { sourceToteId, machineId } = useParams();
    const { state } = useContext(CubiscanContext);
    const jioCodeKeys = state?.toteDetails?.products?.map((item) => ({
        result: item.jio_code,
    }));
    return (
        <>
            <div className={cn(layoutStyles)}>
                <div className={cn(pageHeaderStyle)}>
                    <PageHeaderV2
                        isExtended
                        title="Cubiscan Label"
                        onBackIconClick={() => {
                            navigate(-1);
                        }}
                    />
                    <main className={cn('mx-6 mt-3 mb-7 pb-3')}>
                        <ScanOrEnterManuallyV2
                            autoSuggest={true}
                            enableScannerOnMount={true}
                            inputProps={manualInputProps}
                            searchableList={jioCodeKeys}
                            onSuccess={(jioCode) => {
                                cubiscanFlow.mutate(jioCode, {
                                    onSuccess: ({ data }) => navigate(`${data?.lot_id}/${jioCode}`),
                                });
                            }}
                        />
                    </main>
                </div>
            </div>
            <Container
                className={cn(
                    'm-4 grid grid-cols-2 space-x-4 rounded-lg border border-solid border-[var(--color-primary-60)] p-4'
                )}
            >
                <Icon
                    className={cn('absolute left-1 bg-white')}
                    ic={<IcOrders color="#000093" />}
                />
                <Text appearance="body-s"> Tote ID </Text>
                <Text appearance="body-s-bold"> {sourceToteId} </Text>
            </Container>
            <Container
                className={cn(
                    'm-4 grid grid-cols-2 space-x-4 rounded-lg border border-solid border-[var(--color-primary-60)] p-4'
                )}
            >
                <Icon
                    className={cn('absolute left-1 bg-white')}
                    ic={<IcOrders color="#000093" />}
                />
                <Text appearance="body-s"> Machine ID </Text>
                <Text appearance="body-s-bold"> {machineId} </Text>
            </Container>
            {cubiscanFlow.isLoading && (
                <div
                    className={cn(
                        'flex items-center justify-center',
                        'absolute inset-0 h-full min-h-screen bg-gray-200/50'
                    )}
                >
                    <Spinner />
                </div>
            )}
        </>
    );
}
