import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Notifications } from '@jds/core';
import {
    JioCodesListSchema,
    useJioCodesList,
} from '../../../hooks/outbound/consolidation/useJioCodesList';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { useContext } from 'react';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';
import { useConsolidationStateSave } from '../../../hooks/outbound/consolidation/useConsolidationStateSave';

const manualInputProps = { name: 'tote-code', label: 'Tote Code', type: 'text' };
export function ConsolidationHomePage() {
    const navigate = useNavigate();
    const { stationId } = useParams();
    const { dispatch } = useContext(ConsolidationContext);
    const saveConsolidationactivity = useConsolidationStateSave();

    const jioCodesList = useJioCodesList();
    const getConsolidateJioCodesList = (data: JioCodesListSchema) => {
        jioCodesList.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    dispatch({
                        type: 'SET_CONSOLIDATION_JIOCODES_LIST',
                        payload: res.data,
                    });
                    dispatch({
                        type: 'SET_CONSOLIDATION_TOTAL_TOTE_QUANTITY',
                        payload: getTotalConsolidationQuantity(res.data),
                    });
                    handleToteScanState(data.toteId);
                }
            },
            onError(error: any) {
                const defaultErrorMsg = 'Something went wrong';
                const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
                if (Array.isArray(errors)) {
                    errors.forEach((description) => {
                        Notifications.toast.error({ title: 'Error', description });
                    });
                } else if (error.response?.data?.message) {
                    const description = error.response?.data?.message ?? defaultErrorMsg;
                    Notifications.toast.error({ title: 'Error', description });
                } else {
                    const description = errors ?? defaultErrorMsg;
                    Notifications.toast.error({ title: 'Error', description });
                }
            },
        });
    };

    const getTotalConsolidationQuantity = (data: any[]) => {
        let quantity = 0;
        data.map((item: { quantity: number }) => {
            quantity += item.quantity;
        });
        return quantity;
    };

    function handleToteScanState(toteId: string) {
        saveConsolidationactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_TOTE_CONSOLIDATION',
                status: 'COMPLETED',
                toteId: toteId,
                locationId: stationId,
            },
            {
                onSuccess: () => {
                    navigate(`../${stationId}/${toteId}/main`);
                },
            }
        );
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Scan Tote"
                    onBackIconClick={() => {
                        navigate(`../`);
                    }}
                />
                <main className={cn('mx-2 mt-4 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        purpose="CONSOLIDATION"
                        stage="OUTBOUND"
                        type="TOTE"
                        onSuccess={(value) => {
                            getConsolidateJioCodesList({ toteId: value });
                        }}
                    />
                </main>
            </div>
        </div>
    );
}
