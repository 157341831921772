import { useContext } from 'react';
import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';
import { ReceivingContext } from '../../../provider/inbound/receiving';
export function SuccessPage() {
    const { dispatch } = useContext(ReceivingContext);
    dispatch({ type: 'RESET_ALL' });
    localStorage.clear();
    return (
        <>
            <SuccessPageV2
                message="Receiving task 
            completed successfully"
            />
        </>
    );
}
