import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface OrderListSchema {
    containerId: string;
    containerType: string;
}

export function usePackingOrderList() {
    return useMutation(async (data: OrderListSchema) => {
        const response = await axios.get(
            urls.GET_ORDER_LIST_FROM_CONTAINER(data.containerId, data.containerType)
        );
        return response.data;
    });
}
