import { cn } from '../../../utils/tailwind';
import { useRef, useState } from 'react';
import { PageHeader } from '../../shared/PageHeader';
import { Button, Container, Icon, Notifications, Text } from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../shared/ScanOrEnterManuallyV2';
import { IcInfo } from '@jds/core-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useValidateTote, ValidateToteOptions } from '../../../hooks/receiving/useValidateTote';
import { ToteDropOptions, useDropTote } from '../../../hooks/receiving/useDropTote';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';

const layoutStyles = 'max-w-screen-md md:mx-auto';

interface ScanToteProps {
    pageHeader: string;
    toteType: string;
    showBackIon: boolean;
    lotId?: number;
    locationId?: string;
    onSuccess: (value: string) => void;
}

const manualInputProps = { name: 'tote-code', label: 'Tote Code', type: 'text' };
export function ScanTotePage({
    pageHeader,
    toteType,
    showBackIon,
    lotId,
    locationId,
    onSuccess,
}: ScanToteProps) {
    const toteRef = useRef({
        currentToteId: '',
    });
    const navigate = useNavigate();
    const { jioCode } = useParams();
    const [validateTote, dropTote] = [useValidateTote(), useDropTote()];
    const currentQuantity = Number(useQueryParams().get('quantity'));

    const getProductType = () => {
        if (toteType === 'damaged-expired-items') return 'DAMAGED';
        else if (toteType === 'lowfreshness-items') return 'LOW_FRESHNESS';
        else if (toteType === 'wrongproduct-items') return 'GOOD';
        else if (toteType === 'excess-items') return 'EXCESS';
        else if (toteType === 'cancelled-items') return 'GOOD';
        else return 'EXPIRED';
    };

    function onScanSuccess(tote_code: string) {
        const data: ValidateToteOptions = {
            quantity: currentQuantity ?? 1,
            tote_code,
            product_type: getProductType(),
            jio_code: jioCode ?? '',
            tote_purpose: 'STOCK_TAKE',
            lot_id: lotId ?? 0,
            location_id: locationId ?? '',
            tote_params: null,
        };
        validateTote.mutate(data, {
            onSuccess: () => (toteRef.current.currentToteId = tote_code),
            onError: () => (toteRef.current.currentToteId = ''),
        });
    }

    const dropToteOptions: ToteDropOptions = {
        jio_code: jioCode ?? '',
        product_type: getProductType(),
        tote_purpose: 'STOCK_TAKE',
        totes: [{ tote_code: toteRef.current.currentToteId ?? '', quantity: currentQuantity ?? 1 }],
        lot_id: lotId ?? 0,
        location_id: locationId ?? '',
        tote_params: null,
    };

    const scannedItemsListJson = localStorage.getItem(toteType) || '{}';
    const scannedItemsList = JSON.parse(scannedItemsListJson);
    const toteId = Object.keys(scannedItemsList)[0];
    let itemsList = scannedItemsList[toteId] || [];
    const [isNewTote, setIsNewTote] = useState(!(itemsList.length > 0));
    const [dropOldTote, setDropOldTote] = useState(false);

    const updateItemsList = () => {
        if (dropOldTote === true) {
            itemsList = [];
        }
        if (!itemsList.includes(jioCode)) {
            itemsList.push(jioCode);
        }
        const itemObj = {
            [toteRef.current.currentToteId]: itemsList,
        };
        localStorage.setItem(toteType, JSON.stringify(itemObj));
    };

    const getManualProps = () => {
        if (isNewTote) {
            manualInputProps.label = 'Empty Tote Code';
        } else {
            manualInputProps.label = 'Tote Code';
        }
        return manualInputProps;
    };

    const getItemHeading = () => {
        if (toteType === 'damaged-expired-items') return 'Damaged';
        else if (toteType === 'lowfreshness-items') return 'Low freshness';
        else if (toteType === 'wrongproduct-items') return 'Wrong product';
        else if (toteType === 'excess-items') return 'Excess';
        else if (toteType === 'cancelled-items') return 'Cancelled';
    };

    return (
        <div className={cn('mx-5 max-w-screen-md py-3 md:mx-auto')}>
            <PageHeader
                showBackIcon={showBackIon}
                title={pageHeader}
                onBackIconClick={() => {
                    navigate(-1);
                }}
            />
            <main className={cn('mx-2 mt-6')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount={true}
                    inputProps={getManualProps()}
                    onSuccess={(value) => {
                        onScanSuccess(value);
                    }}
                />
                {toteType === 'damaged-expired-items' && isNewTote && (
                    <div className="mt-3 flex space-x-2 pr-6">
                        <Icon className={cn('pt-1')} color="grey-100" ic={<IcInfo />} size="m" />
                        <Text appearance="body-xs" color="primary-grey-80">
                            Damaged and Expired items go in the same tote
                        </Text>
                    </div>
                )}
                {!isNewTote && (
                    <Container
                        background="secondary-30"
                        className={cn('mt-6 grid grid-cols-2 items-end')}
                        rounded="small"
                    >
                        <Text
                            appearance="body-s-bold"
                            className={cn('mx-2')}
                            color="primary-grey-100"
                        >
                            {`${getItemHeading()} Tote ID`}
                        </Text>
                        <div className={cn('w-full')}>
                            <div className={cn('mr-auto ml-2 max-w-[52px]')}>
                                <Text appearance="body-s-bold" color="primary-grey-100">
                                    {toteId}
                                </Text>
                            </div>
                        </div>
                    </Container>
                )}
                {!isNewTote && (
                    <div className={cn('mx-2 mb-20 mt-6')}>
                        <Text appearance="body-s-bold" color="primary-grey-80">
                            {`Scanned ${getItemHeading()} items`}
                        </Text>
                        <div className={cn('mt-4 pt-0')}>
                            {itemsList.map((item: string) => (
                                <div key={item} className={cn('mt-2 grid grid-cols-2 items-end')}>
                                    <Text appearance="body-s" color="primary-grey-60">
                                        Jio code
                                    </Text>
                                    <div className={cn('w-full')}>
                                        <div className={cn('mr-auto max-w-[52px]')}>
                                            <Text appearance="body-m" color="primary-grey-60">
                                                {item}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </main>
            <div
                className={cn(
                    'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                    layoutStyles
                )}
            >
                {!isNewTote && (
                    <Button
                        fullWidth
                        kind="secondary"
                        size="large"
                        onClick={() => {
                            setDropOldTote(true);
                            setIsNewTote(true);
                        }}
                    >
                        {`Add new ${getItemHeading()} tote`}
                    </Button>
                )}
                <Button
                    fullWidth
                    kind="primary"
                    size="large"
                    onClick={() => {
                        if (toteRef.current.currentToteId !== toteId && !isNewTote) {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Tote ID does not match',
                            });
                        } else {
                            updateItemsList();
                            dropTote.mutate(dropToteOptions, {
                                onSuccess: () => onSuccess(toteRef.current.currentToteId),
                            });
                        }
                    }}
                >
                    Drop Complete
                </Button>
            </div>
        </div>
    );
}
