import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { useContext, useState } from 'react';
import { Button, Container, Notifications, Spinner, Text } from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import {
    UpdateDamagedProductsSchema,
    useUpdateDamagedProducts,
} from '../../../hooks/outbound/consolidation/useDamagedProducts';
import { useDropTote, ToteDropOptions } from '../../../hooks/receiving/useDropTote';
import { useValidateTote, ValidateToteOptions } from '../../../hooks/receiving/useValidateTote';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import {
    GetConsolidationStatusSchema,
    useGetConsolidationStatusList,
} from '../../../hooks/outbound/consolidation/useGetConsolidationStatus';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';
import { useConsolidationProducts } from '../../../hooks/outbound/consolidation/useConsolidationProducts';
import { ProductCard } from '../../../components/shared/ProductCard';
import { useConsolidationStateSave } from '../../../hooks/outbound/consolidation/useConsolidationStateSave';

const manualInputProps = { name: 'tote-id', label: 'Tote ID', type: 'text' };
export function DamagedTotePage() {
    const navigate = useNavigate();
    const saveConsolidationToteDropactivity = useConsolidationStateSave();
    const { stationId, toteCode, jioCode, shipmentId, lotId } = useParams();
    const { data: products, isLoading } = useConsolidationProducts();
    const [currentToteId, setCurrentToteId] = useState('');
    const { state, dispatch } = useContext(ConsolidationContext);
    const damagedJioCodesTillNow = state.consolidationDamagedJioCodes;
    const quantityCompletedTillNow = state.consolidationToteScannedQuantity;
    const consolidateJioCodesList = state.consolidationJioCodesList;

    const jioCodeDetails = state?.consolidationJioCodesList?.find(
        (item) => item.shipmentId === shipmentId && item.jioCode === jioCode
    );

    const getJioCodeIndex = () => {
        const val = consolidateJioCodesList.findIndex((item) => {
            return item.shipmentId === shipmentId && item.jioCode === jioCode;
        });
        return val;
    };

    const updateDamagedProducts = useUpdateDamagedProducts();
    const handleUpdateDamagedProducts = (data: UpdateDamagedProductsSchema[]) => {
        updateDamagedProducts.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    dropTote.mutate(dropToteOptions, {
                        onSuccess: () => {
                            Notifications.toast.success({
                                title: 'Success',
                                description: 'Damaged item dropped',
                            });
                            damagedJioCodesTillNow.push({
                                jioCode: jioCode || '',
                                lotId: Number(lotId) || 0,
                            });
                            const ind = getJioCodeIndex();
                            const quantity = consolidateJioCodesList[ind].scannedQuantity ?? 0;
                            consolidateJioCodesList[ind].scannedQuantity = quantity + 1;

                            dispatch({
                                type: 'SET_CONSOLIDATION_JIOCODES_LIST',
                                payload: consolidateJioCodesList,
                            });
                            dispatch({
                                type: 'SET_CONSOLIDATION_DAMAGED_JIOCODES',
                                payload: damagedJioCodesTillNow,
                            });
                            dispatch({
                                type: 'SET_CONSOLIDATION_SCANNED_TOTE_QUANTITY',
                                payload: quantityCompletedTillNow + 1,
                            });
                            handleToteDropStateSave(data);
                        },
                        onError: () => {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Failed to Drop Damaged item',
                            });
                        },
                    });
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const getConsolidationStatusList = useGetConsolidationStatusList();
    const handleConsolidationStatusList = (data: GetConsolidationStatusSchema[]) => {
        getConsolidationStatusList.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    if (res?.data?.length > 0) {
                        dispatch({ type: 'SET_CONSOLIDATED_ORDERS_DETAILS', payload: res.data });
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/bulk/success`);
                    } else {
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
                    }
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const [validateTote, dropTote] = [useValidateTote(), useDropTote()];

    function onScanSuccess(tote_code: string) {
        const data: ValidateToteOptions = {
            quantity: 1,
            tote_code,
            product_type: 'DAMAGED',
            jio_code: jioCode ?? '',
            tote_purpose: 'CONSOLIDATION_OUTBOUND_QC',
            lot_id: Number(lotId) ?? 0,
            location_id: stationId || '',
            tote_params: {
                orderId: jioCodeDetails?.orderId || '',
                shipmentId: shipmentId || '',
            },
        };
        validateTote.mutate(data, {
            onSuccess: () => setCurrentToteId(tote_code),
            onError: () => setCurrentToteId(''),
        });
    }

    const dropToteOptions: ToteDropOptions = {
        jio_code: jioCode ?? '',
        product_type: 'DAMAGED',
        tote_purpose: 'CONSOLIDATION_OUTBOUND_QC',
        totes: [{ tote_code: currentToteId ?? '', quantity: 1 }],
        location_id: stationId || '',
        lot_id: Number(lotId) ?? 0,
        tote_params: {
            orderId: jioCodeDetails?.orderId || '',
            shipmentId: shipmentId || '',
        },
    };

    const handleToteDropStateSave = (data: UpdateDamagedProductsSchema[]) => {
        saveConsolidationToteDropactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_TOTE_CONSOLIDATION',
                status: 'COMPLETED',
                locationId: stationId,
                shipmentId: shipmentId,
                jioCode: jioCode,
                toteId: toteCode || '',
                productReceivingSource: 'CONSOLIDATION_OUTBOUND_QC',
                productType: 'DAMAGED',
                quantity: 1,
                lotId: Number(jioCodeDetails?.lotId) || 0,
            },
            {
                onSuccess: () => {
                    handleConsolidationStatusList(data);
                },
            }
        );
    };

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Damaged tote"
                    onBackIconClick={() => {
                        Notifications.toast.error({
                            title: 'Error',
                            description: 'Unable to navigate back',
                        });
                    }}
                />
                <div className={cn('mx-3 space-y-2 py-3 pl-8')}>
                    <Text appearance="body-xxs" color="primary-grey-100">
                        Tote ID
                    </Text>
                    <Text appearance="body-m-bold" color="primary-60">
                        {state.consolidationCurrentDamagedToteCode}
                    </Text>
                </div>
            </div>
            <main className={cn('mx-4 mt-6')}>
                <Text appearance="body-xs-bold" className={cn('mx-2')} color="primary-grey-100">
                    Input Tote ID
                </Text>
                <Container
                    className={cn('bg-cronical-gradient my-4 px-5 pt-6 pb-3')}
                    rounded="medium"
                >
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        searchableList={[{ result: state.consolidationCurrentDamagedToteCode }]}
                        onSuccess={(value) => {
                            if (value === state.consolidationCurrentDamagedToteCode) {
                                onScanSuccess(value);
                            } else {
                                Notifications.toast.error({
                                    title: 'Error',
                                    description: 'Tote ID does not match',
                                });
                            }
                        }}
                    />
                </Container>
                <div className={cn('mx-1 mb-8 mt-6')}>
                    <Text appearance="body-s" className={cn('mx-2')} color="primary-grey-80">
                        Damaged Items List
                    </Text>
                    <div className={cn('mt-3 mb-44')}>
                        {products?.length === 0 && (
                            <Text className={cn('text-center')}>No products found!</Text>
                        )}
                        {isLoading && (
                            <Spinner className={cn('flex justify-center')} size="small" />
                        )}
                        {products?.map((product) => (
                            <ProductCard
                                key={product.jio_code}
                                BBD={product.expiry}
                                EAN={product.ean ?? product.ean}
                                MFD={product.mfg}
                                MRP={product.mrp}
                                colorSize={`${product?.color ?? '-'}, ${product?.size ?? '-'}`}
                                image={product?.image_urls?.[0]}
                            />
                        ))}
                    </div>
                </div>
            </main>
            <div
                className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    className={cn('!mb-3')}
                    disabled={currentToteId !== ''}
                    kind="secondary"
                    size="large"
                    type="submit"
                    onClick={() => {
                        navigate(
                            `/outbound/consolidation/${stationId}/${toteCode}/${jioCode}/${shipmentId}/${lotId}/scan-new-damaged-tote`
                        );
                    }}
                >
                    Add New Damaged Tote
                </Button>
                <Button
                    fullWidth
                    disabled={currentToteId === ''}
                    size="large"
                    type="submit"
                    onClick={() => {
                        handleUpdateDamagedProducts([
                            {
                                cancelled_quantity: 1,
                                cancellation_reason: 'damaged',
                                order_id: jioCodeDetails?.orderId || '',
                                shipment_id: shipmentId,
                                jio_code: jioCode || '',
                                tote_code: toteCode || '',
                                cancelledDuringPacking: false,
                            },
                        ]);
                    }}
                >
                    Drop Complete
                </Button>
            </div>
        </div>
    );
}
