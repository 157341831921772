import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Text, Space, Notifications } from '@jds/core';
import { cn } from '../../../utils/tailwind';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useQueryClient } from '@tanstack/react-query';
import { ProductInfo } from '../../../hooks/returns/unloading/useReturnProducts';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { useState } from 'react';
import { pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ProductCardV2 } from '../../../components/shared/ProductCardV2';
import { useProductDetails } from '../../../hooks/cubiscan/useProductDetails';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'location-id', label: 'Location ID', type: 'text' };
export function LocationAndProductDetails() {
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname.split('/', 3).join('/');
    const { stationId, toteId, jioCode, locationId } = useParams();
    const storageZone = localStorage.getItem('putawayStorageZone') || 'NA';
    const [showWarning, setShowWarning] = useState(false);
    const lotId = localStorage.getItem('lotId');
    const { data: productObj } = useProductDetails(jioCode ?? '', lotId ?? '0');
    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Putaway Location"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                <div className={cn(layoutStyles, 'mx-3 space-y-2 py-3 pl-8')}>
                    <Text appearance="body-xs" color="primary-grey-80">
                        Go to Storage zone
                    </Text>
                    <Text appearance="body-l" color="primary-grey-100">
                        {storageZone}
                    </Text>
                    <Text appearance="body-xs" color="primary-grey-80">
                        Location ID
                    </Text>
                    <Text appearance="heading-xs" color="primary-60">
                        {locationId}
                    </Text>
                </div>
            </div>
            <main className={cn(layoutStyles, 'mx-3 mt-2 mb-60')}>
                <Space value="s" />
                {productObj && (
                    <>
                        <Text appearance="body-xs-bold" className={cn('ml-1')}>
                            Scanned Jio Code
                        </Text>
                        <ProductCardV2
                            key={productObj.jio_code}
                            BBD={productObj.expiry}
                            EAN={productObj.ean}
                            MFD={productObj.mfg}
                            MRP={productObj.mrp}
                            colorSize={`${productObj.color ?? ''}, ${productObj.size ?? ''}`}
                            image={productObj.image_urls?.length ? productObj.image_urls[0] : ''}
                            jioCode={productObj.jio_code}
                            title={productObj.product_title}
                        />
                    </>
                )}
                <span
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-3',
                        layoutStyles
                    )}
                >
                    <Text appearance="body-xs-bold" className={cn('m-1')}>
                        Input Location ID
                    </Text>
                    <Container
                        className={cn('bg-cronical-gradient px-2 pt-6 pb-3')}
                        rounded="medium"
                    >
                        <ScanOrEnterManuallyV2
                            autoSuggest={true}
                            inputProps={manualInputProps}
                            searchableList={[{ result: locationId || '' }]}
                            onSuccess={(value) => {
                                if (value === locationId) {
                                    navigate(
                                        `${routePrefix}/${stationId}/${toteId}/${jioCode}/${locationId}/putaway-quantity`
                                    );
                                } else {
                                    setShowWarning(true);
                                }
                            }}
                        />
                    </Container>
                </span>
            </main>
            {showWarning && (
                <div className={cn('mt-14')}>
                    <InfoModalSection
                        showCloseIcon
                        ModalHeader="Location ID scanned is not compatible"
                        buttonText="Select another location"
                        infoText=""
                        infoType="WARNING"
                        onSuccess={() => setShowWarning(false)}
                    />
                </div>
            )}
        </>
    );
}
