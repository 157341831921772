import { cn } from '../../utils/tailwind';
import { Container, Icon, Input, Text } from '@jds/core';
import { IcAddCircle, IcCloseRemove, IcEditPen, IcMinimise } from '@jds/core-icons';
import { useEffect, useState } from 'react';
import { DetailsAccordion } from '../returns/receiving/DetailsAccordion';

interface IncrementDecrementProps {
    title: string;
    type?: string;
    defaultInputValue?: number;
    successCallBack: (value: number) => void;
    onChangeCallBack: (value: number) => void;
    onEditCallBack: (value: string) => void;
    onRemoveCallBack: (value: string) => void;
    isEditable?: boolean;
    borderColour?: string;
    backgroundColour?: string;
    showEditIcon?: boolean;
    inEditMode?: boolean;
    showRemoveIcon?: boolean;
    showDescription?: boolean;
    description?: string[];
}

export function IncrementDecrementSectionV3({
    title,
    type = '',
    defaultInputValue = 0,
    successCallBack,
    isEditable = true,
    showEditIcon = true,
    inEditMode = false,
    borderColour,
    backgroundColour,
    onChangeCallBack,
    onEditCallBack,
    showRemoveIcon = false,
    onRemoveCallBack,
    showDescription = false,
    description,
}: IncrementDecrementProps) {
    const [inputValue, setInputValue] = useState(defaultInputValue);
    const [editMode, setEditMode] = useState(inEditMode);

    const getBorderColor = () => {
        switch (borderColour) {
            case 'success':
                return 'border-[var(--color-feedback-success-50)]';
            case 'warning':
                return 'border-[var(--color-feedback-warning-50)]';
            case 'error':
                return 'border-[var(--color-feedback-error-50)]';
            case 'sparkleGrey':
                return 'border-[var(--color-sparkle-grey-60)]';
            default:
                return 'border-[var(--color-primary-60)]';
        }
    };

    useEffect(() => {
        if (isEditable) onChangeCallBack(inputValue);
    }, [inputValue, isEditable, onChangeCallBack]);

    useEffect(() => {
        setEditMode(inEditMode);
    }, [inEditMode]);

    return (
        <Container
            className={cn(
                'flex items-center space-x-2.5 !rounded-xl bg-[#9999FF]',
                [showDescription ? 'h-[100%]' : showEditIcon ? 'h-[72px]' : 'h-[56px]'],
                [
                    borderColour && {
                        [`${
                            showEditIcon ? 'border' : 'border-2'
                        } border-solid ${getBorderColor()}`]: !editMode,
                    },
                ]
            )}
            pad="s"
            rounded="medium"
        >
            <div className={cn('flex w-full flex-col')}>
                <div className={cn('flex justify-between')}>
                    <div className={cn('flex items-center text-center')}>
                        <Text
                            appearance="body-m-bold"
                            className={cn('items-center text-center')}
                            color={!editMode ? `primary-grey-80` : `primary-60`}
                        >
                            {title}
                        </Text>
                        {showRemoveIcon && (
                            <Icon
                                className={cn('ml-2 items-center')}
                                ic={
                                    <IcCloseRemove
                                        color="#000093"
                                        onClick={() => onRemoveCallBack('remove')}
                                    />
                                }
                                size="s"
                            />
                        )}
                    </div>

                    <div
                        className={cn([
                            'flex items-center justify-end',
                            !editMode && 'text-center',
                        ])}
                    >
                        {editMode && (
                            <>
                                <Icon
                                    aria-label="decrement"
                                    color="primary"
                                    ic={<IcMinimise />}
                                    onClick={() => {
                                        if (inputValue > 0) setInputValue(inputValue - 1);
                                    }}
                                />
                                <Input
                                    className={cn('mx-3 !mt-0 flex w-9 !pb-0 ')}
                                    color="primary-60"
                                    id="number"
                                    inputMode="numeric"
                                    label=""
                                    name=""
                                    type="number"
                                    value={inputValue.toString()}
                                    onChange={(event) => {
                                        const inputval = event.target.value;
                                        if (Number(inputval) >= 0) {
                                            setInputValue(Number(inputval));
                                        }
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === '.') event.preventDefault();
                                        if (event.key === 'Enter') {
                                            if (type !== 'failed') setEditMode(false);
                                            successCallBack(inputValue);
                                        }
                                    }}
                                />
                                <Icon
                                    aria-label="increment"
                                    color="primary"
                                    ic={<IcAddCircle />}
                                    onClick={() => setInputValue(inputValue + 1)}
                                />
                            </>
                        )}
                        {!editMode && (
                            <>
                                <Text
                                    appearance="body-s-bold"
                                    className={cn('mr-6 w-10')}
                                    color="primary-grey-80"
                                >
                                    {inputValue}
                                </Text>
                                {showEditIcon && (
                                    <Icon
                                        aria-label="edit"
                                        color="primary"
                                        ic={<IcEditPen />}
                                        onClick={() => {
                                            setEditMode(true);
                                            onEditCallBack(type);
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <DetailsAccordion
                        key={title}
                        description={description ?? ['']}
                        title="See Description"
                    />
                </div>
            </div>
        </Container>
    );
}
