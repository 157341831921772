import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../utils/urls';
import { useParams } from 'react-router-dom';
import { ResponseType } from '../../types/fetch';
import { Notifications } from '@jds/core';

export function useUploadSignedPOD() {
    const rfcId = sessionStorage.getItem('rfc-id') ?? '1';
    const { appointmentNumber } = useParams();
    return useMutation<ResponseType<unknown>, AxiosError<ResponseType<never>>, string>({
        mutationFn: async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('bucket_name', 'wms-files');
            formData.append('document_type', 'POD');
            const response = await axios.post(
                urls.UPLOAD_SIGNED_POD(rfcId, appointmentNumber ?? '1'),
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            return response.data;
        },
        onError: (error) => {
            const defaultErrorMessage = 'Something went wrong';
            const messages = error?.response?.data?.meta?.errors ?? [defaultErrorMessage];
            messages.forEach((message) => {
                Notifications.toast.error({
                    title: 'Error',
                    description: message,
                });
            });
        },
    });
}
