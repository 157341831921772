import { cn } from '../../utils/tailwind';
import { Button, Container, Icon, Text } from '@jds/core';
import { IcBack, IcInfo } from '@jds/core-icons';
import { pageHeaderStyle } from '../../styles/common';

interface PageHeaderV2Props {
    isExtended?: boolean;
    title?: string;
    imageUrl?: string;
    onBackIconClick?: () => void;
    showBackIcon?: boolean;
    showInfoIcon?: boolean;
    onInfoIconClick?: () => void;
    onImageClick?: () => void;
}

export function PageHeaderV2({
    isExtended = false,
    title,
    onBackIconClick,
    showBackIcon = true,
    showInfoIcon = true,
    onInfoIconClick,
    imageUrl,
    onImageClick,
}: PageHeaderV2Props) {
    return (
        <Container
            className={cn([
                `flex items-center justify-between ${!isExtended ? pageHeaderStyle : ''}`,
            ])}
        >
            <Container className={cn('flex items-center')}>
                {showBackIcon && (
                    <Button
                        aria-label="go back to previous page"
                        icon={<IcBack color="black" />}
                        kind="tertiary"
                        type="button"
                        onClick={onBackIconClick}
                    />
                )}
            </Container>
            <div className={cn(['flex items-center', !showBackIcon && 'mt-3'])}>
                {imageUrl && (
                    <img
                        alt="Product Image"
                        className={cn('mr-2 h-10 w-10 rounded-3xl')}
                        height={25}
                        src={imageUrl ?? 'https://via.placeholder.com/125'}
                        width={25}
                        onClick={onImageClick}
                    />
                )}
                {title && (
                    <Text appearance="body-s-bold" color="primary-grey-100">
                        {title}
                    </Text>
                )}
            </div>
            {showInfoIcon && (
                <Icon
                    aria-label="go to info page"
                    className={cn('mr-2 flex items-center')}
                    color="grey-80"
                    ic={<IcInfo className={cn([!showBackIcon && 'mt-3'])} />}
                    onClick={onInfoIconClick}
                />
            )}
        </Container>
    );
}
