import {
    Button,
    Container,
    Divider,
    Heading,
    Icon,
    Modal,
    Notifications,
    ProgressBar,
    Text,
} from '@jds/core';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import * as yup from 'yup';
import axios from 'axios';
import { urls } from '../../../utils/urls';
import { IcCalendarSchedule } from '@jds/extended-icons';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { IcChevronDown, IcChevronUp } from '@jds/core-icons';
import { EditScannedPackages } from '../../inbound/unloading/EditScannedPackages';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';

const markShortSchema = yup.object({
    short: yup.number().required(),
    excess: yup.number().required(),
});

export type MarkShortSchema = yup.InferType<typeof markShortSchema>;

export interface ScannedPack {
    label?: string;
    status?: string;
    checked?: boolean;
}

const manualInputProps = {
    name: 'secondaryPackage',
    label: 'Secondary Package',
    type: 'text',
};
export function ScanSecondaryCases() {
    const [markShortData, setMarkShortData] = useState({
        short: 0,
        excess: 0,
    });
    const navigate = useNavigate();
    const { appointmentNumber, stationId } = useParams();
    const [confirmScan, setConfirmScan] = useState(false);
    const [actualPackages, setActualPackages] = useState(
        JSON.parse(localStorage.getItem('scannedPackages') || '[]').length || 0
    );
    const expectedSecondaryPackages = JSON.parse(
        localStorage.getItem('expectedSecondaryPackages') || '[]'
    );
    const [scannedPackage, setScannedPackage] = useState('');
    const [allScannedPackages, setAllScannedPackages] = useState<any[]>(
        JSON.parse(localStorage.getItem('scannedPackages') || '[]')
    );

    const [editPackages, setEditPackages] = useState('');
    const [showApplicationDetails, setShowApplicationDetails] = useState(false);
    const expectedPackages = expectedSecondaryPackages?.length || 0;
    const sellerName = localStorage.getItem('sellerName') || '';
    const strNumber = localStorage.getItem('strNumber') || '';
    const invoiceNumber = localStorage.getItem('invoiceNumber') || '';
    const isBoxed = JSON.parse(localStorage.getItem('isBoxed') || 'false');
    const verifiedPackages = allScannedPackages.filter(
        (item) => item.unloading_status === 'Success'
    );
    const damagedPackages = allScannedPackages.filter(
        (item) => item.unloading_status === 'Damaged'
    );
    const secondaryCaseKeys = expectedSecondaryPackages.map((item: any) =>
        item.box_label ? item.box_label : item.package_label
    );
    const scannedLabels = allScannedPackages.map((item) => item.case_label);
    const notPresentInData = secondaryCaseKeys.filter((val: any) => !scannedLabels.includes(val));
    const uniqueKeys = notPresentInData?.map((item: string) => ({
        result: item,
    }));
    const [allUnScannedPackages, setAllUnScannedPackages] = useState(uniqueKeys);
    const getPayload = (packages: any) => {
        const payloadCases = packages.map((item: any) => {
            const label = isBoxed ? item.box_label : item.package_label;
            const caseId = isBoxed ? item.box_id : item.package_id;
            return {
                case_id: caseId,
                case_label: label,
                unloading_status: item.status,
                started_at: Date.now(),
                station_id: stationId,
            };
        });
        const payload = {
            is_boxed: isBoxed,
            appointment_id: appointmentNumber,
            unloaded_by: sessionStorage.getItem('username'),
            cases: payloadCases,
            excess_quantity: 0,
        };
        return payload;
    };

    const handleSubmitSecondayCases = async (data: any) => {
        try {
            const res = await axios.patch(
                urls.UNLOADING_VERIFICATION(Number(appointmentNumber ?? 1)),
                data
            );
            if (res?.data) {
                setConfirmScan(false);
                setActualPackages(actualPackages + 1);
                setAllScannedPackages([...allScannedPackages, ...data.cases]);
                localStorage.setItem(
                    'scannedPackages',
                    JSON.stringify([...allScannedPackages, ...data.cases])
                );
                handleSecondaryCaseAutoSuggestions([...allScannedPackages, ...data.cases]);
                localStorage.setItem('short-packages', JSON.stringify(markShortData));
            }
        } catch (error: any) {
            Notifications.toast.error({
                title: error?.response?.data?.meta?.errors[0] ?? 'Something went wrong',
            });
        }
    };
    const handleEditSecondaryCases = async (data: any, handleEditState: any) => {
        const cases = data.map((item: any) => {
            return {
                ...item,
                unloading_status: item.unloading_status === 'Damaged' ? 'Success' : 'Damaged',
            };
        });
        const payload = {
            is_boxed: isBoxed,
            appointment_id: appointmentNumber,
            unloaded_by: sessionStorage.getItem('username'),
            cases: cases,
            excess_quantity: 0,
        };
        try {
            const res = await axios.patch(
                urls.UNLOADING_VERIFICATION(Number(appointmentNumber ?? 1)),
                payload
            );
            if (res?.data) {
                const ids = payload.cases.map((item: any) => item.case_label);
                const newData = allScannedPackages.filter((item) => !ids.includes(item.case_label));
                setAllScannedPackages([...newData, ...payload.cases]);
                handleEditState();
                setEditPackages('');
                localStorage.setItem(
                    'scannedPackages',
                    JSON.stringify([...newData, ...payload.cases])
                );
                handleSecondaryCaseAutoSuggestions([...newData, ...payload.cases]);
                localStorage.setItem('short-packages', JSON.stringify(markShortData));
            }
        } catch (error: any) {
            Notifications.toast.error({
                title: error?.response?.data?.meta?.errors[0] ?? 'Something went wrong',
            });
        }
    };
    const handleConfirmScan = (type: string) => {
        const scannedCase = expectedSecondaryPackages.find((item: any) => {
            const label = isBoxed ? item.box_label : item.package_label;
            return label === scannedPackage;
        });
        const payloadCase = { ...scannedCase, status: type };
        const payload = getPayload([payloadCase]);
        handleSubmitSecondayCases(payload);
    };

    const handleSecondaryCaseAutoSuggestions = (scannedList: any[] | undefined) => {
        const expectedLabels = expectedSecondaryPackages.map((item: any) =>
            item.box_label ? item.box_label : item.package_label
        );
        const scannedLabels = scannedList?.map((item) => item.case_label);

        const notPresentInData = expectedLabels.filter((val: any) => !scannedLabels?.includes(val));
        const uniqueKeys = notPresentInData?.map((item: string) => ({
            result: item,
        }));
        setAllUnScannedPackages(uniqueKeys);
    };
    return !editPackages ? (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Secondary Packages"
                    onBackIconClick={() => {
                        allScannedPackages.length
                            ? Notifications.toast.error('Unable to navigate back')
                            : navigate(-1);
                    }}
                />
                <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        inputProps={manualInputProps}
                        searchableList={allUnScannedPackages}
                        onSuccess={(value: string) => {
                            const expectedLabels = expectedSecondaryPackages.map((item: any) =>
                                isBoxed ? item.box_label : item.package_label
                            );
                            const isExpectedPackage = expectedLabels.includes(value);
                            const scannedLabels = allScannedPackages.map((item) => item.case_label);
                            const checkPackage = scannedLabels.includes(value);
                            if (isExpectedPackage && !checkPackage) {
                                setConfirmScan(true);
                                setScannedPackage(value);
                            } else {
                                !isExpectedPackage
                                    ? Notifications.toast.error('Unexpected package')
                                    : Notifications.toast.error('Case is scanned already');
                            }
                        }}
                    />
                </div>
            </div>
            <main
                className={cn(
                    'mx-6 mb-14 flex max-w-screen-md flex-1 flex-col  justify-between py-3 md:mx-auto'
                )}
            >
                <ProgressBar
                    label="Packages"
                    value={actualPackages / expectedPackages}
                    valueLabel={`${actualPackages}/${expectedPackages}`}
                />
                {!!verifiedPackages.length && (
                    <Container
                        background="primary-grey-20"
                        className={cn('mt-4')}
                        pad="m"
                        rounded="large"
                    >
                        <Container as="div" className={cn('flex justify-between')}>
                            <div>
                                <Text appearance="heading-xs" as="span" color="primary-grey-80">
                                    Verified{' '}
                                </Text>
                                <Text appearance="body-xxs" as="span" color="primary-grey-80">
                                    ({verifiedPackages.length})
                                </Text>
                            </div>
                            <Button
                                className={cn('h-[33px] !w-[84px] !border-0 !bg-white')}
                                kind="secondary"
                                size="small"
                                onClick={() => setEditPackages('Verified Packages')}
                            >
                                Edit
                            </Button>
                        </Container>
                        {verifiedPackages.map((item, index) => (
                            <Container key={item.case_label} as="div">
                                <Text
                                    className={cn(
                                        `${index === verifiedPackages.length - 1 ? 'pt-3' : 'py-3'}`
                                    )}
                                    color="primary-grey-60"
                                >
                                    {item.case_label}
                                </Text>
                                {index !== verifiedPackages.length - 1 && <Divider />}
                            </Container>
                        ))}
                    </Container>
                )}
                {!!damagedPackages.length && (
                    <Container
                        background="primary-grey-20"
                        className={cn('mt-4')}
                        pad="m"
                        rounded="large"
                    >
                        <Container as="div" className={cn('flex justify-between')}>
                            <div>
                                <Text appearance="heading-xs" as="span" color="primary-grey-80">
                                    Damaged{' '}
                                </Text>
                                <Text appearance="body-xxs" as="span" color="primary-grey-80">
                                    ({damagedPackages.length})
                                </Text>
                            </div>
                            <Button
                                className={cn('h-[33px] !w-[84px] !border-0 !bg-white')}
                                kind="secondary"
                                size="small"
                                onClick={() => setEditPackages('Damaged Packages')}
                            >
                                Edit
                            </Button>
                        </Container>
                        {damagedPackages.map((item, index) => (
                            <Container key={item.case_label} as="div">
                                <Text
                                    className={cn(
                                        `${index === damagedPackages.length - 1 ? 'pt-3' : 'py-3'}`
                                    )}
                                    color="primary-grey-60"
                                >
                                    {item.case_label}
                                </Text>
                                {index !== damagedPackages.length - 1 && <Divider />}
                            </Container>
                        ))}
                    </Container>
                )}
                <Container
                    className={cn(
                        'mx-2 my-4 rounded-lg border border-[var(--color-primary-60)] p-4'
                    )}
                >
                    <Icon
                        className={cn('absolute left-5 bg-white')}
                        ic={<IcCalendarSchedule color="#000093" />}
                    />
                    <Container className={cn('grid grid-cols-2 space-x-2')}>
                        <Text appearance="body-s"> Appointment no. </Text>
                        <div className={cn('flex justify-between')}>
                            <Text appearance="body-s-bold"> {appointmentNumber} </Text>
                            <Icon
                                ic={showApplicationDetails ? <IcChevronUp /> : <IcChevronDown />}
                                onClick={() => setShowApplicationDetails(!showApplicationDetails)}
                            />
                        </div>
                    </Container>
                    {showApplicationDetails && (
                        <>
                            <Divider className={cn('my-4')} />
                            <Container className={cn('grid grid-cols-2 break-words')}>
                                <Text appearance="body-s"> Seller Name </Text>
                                <Text appearance="body-s"> {sellerName} </Text>
                                <Text appearance="body-s"> STR number </Text>
                                <Text appearance="body-s"> {strNumber} </Text>
                                <Text appearance="body-s"> Invoice number </Text>
                                <Text appearance="body-s"> {invoiceNumber} </Text>
                            </Container>
                        </>
                    )}
                </Container>
                <span
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                        layoutStyles
                    )}
                >
                    <Button
                        fullWidth
                        kind="secondary"
                        onClick={() => navigate('./../mark-short-excess')}
                    >
                        Continue
                    </Button>
                </span>
                <Modal closed={!confirmScan} size="m" onCloseCallback={() => setConfirmScan(false)}>
                    <div className={cn('text-center')}>
                        <Heading appearance="heading-xs">Verify the status of the package?</Heading>
                        <div className={cn('mt-12 flex space-x-2')}>
                            <Button kind="secondary" onClick={() => handleConfirmScan('Damaged')}>
                                Damaged
                            </Button>
                            <Button onClick={() => handleConfirmScan('Success')}>Verified</Button>
                        </div>
                    </div>
                </Modal>
            </main>
        </>
    ) : (
        <EditScannedPackages
            handleStatusChange={(packages, handleEditState) =>
                handleEditSecondaryCases(packages, handleEditState)
            }
            scannedPackages={
                editPackages === 'Verified Packages' ? verifiedPackages : damagedPackages
            }
            title={editPackages}
            onBackClick={() => setEditPackages('')}
        />
    );
}
