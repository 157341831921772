import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

type ProductBulkDetails = { jioCode: string; lotId: number }[];
async function fetchProductDetails(details: ProductBulkDetails) {
    const promises = details.map(({ jioCode, lotId }) => {
        return axios.get(urls.PRODUCT_DETAILS(jioCode, lotId));
    });
    const result = await Promise.all(promises);
    return result.map(({ data }) => data.data);
}

export interface ProductInfo {
    image_urls: string[] | null;
    branch: string;
    color: string;
    description: string;
    ean: string;
    expiry: number;
    jio_code: string;
    lot_id: number;
    mfg: number;
    mrp: number;
    product_title: string;
    seller_code: string;
    size: string;
    sku_code: string;
    quantity: number;
}

export function useGetProducts(productDetailsPayload: ProductBulkDetails) {
    const rfcId = sessionStorage.getItem('rfc-id');
    const keys = productDetailsPayload.map(({ jioCode }) => jioCode);
    return useQuery<ProductDetails[]>({
        queryKey: ['products', rfcId, keys],
        queryFn: async () => {
            const productInfo = await fetchProductDetails(productDetailsPayload);
            return combineProductDetail(productInfo);
        },
        staleTime: Infinity,
        cacheTime: Infinity,
    });
}

export interface ProductDetails extends ProductInfo {
    expectedQuantity: number;
}

function combineProductDetail(productDetails: ProductInfo[]) {
    return productDetails.map((product) => {
        const expectedQuantity = product.quantity ?? 0;
        return {
            ...product,
            expectedQuantity: expectedQuantity,
        };
    });
}
