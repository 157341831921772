import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from '../../utils/tailwind';
import { Spinner, Text } from '@jds/core';

import { PageHeader } from '../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { GtlTaskItem, gtlTaskListProps } from '../../components/gtl/GtlTaskItem';
import { groupListByKey } from '../../utils/helper';
import { useGetGtlTaskList } from '../../hooks/gtl/useGetGtlTaskList';
import { useUpdateGtlTaskStatus } from '../../hooks/gtl/useUpdateGtlTaskStatus';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'trolley-code', label: 'Trolley Code', type: 'text' };

const GtlHomePage = () => {
    const [trolleyCode, setTrolleyCode] = useState('');
    const navigate = useNavigate();
    const { gtlTaskList, isLoading, isError } = useGetGtlTaskList();
    const groupedDataObj = groupListByKey(gtlTaskList, 'reason');
    const updateTaskStatus = useUpdateGtlTaskStatus();
    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <PageHeader showBackIcon={false} title="GTL" />
            <main className={cn('mx-2 mt-6')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount={true}
                    inputProps={manualInputProps}
                    onSuccess={(value) => {
                        setTrolleyCode(value);
                    }}
                />
                <div className={cn('mt-12 mb-4 flex justify-between')}>
                    <Text appearance="body-m-bold" color="primary-grey-80">
                        Taks list
                    </Text>
                    <Text appearance="body-m" color="primary-grey-60">
                        Total -{' '}
                        {gtlTaskList.length > 1
                            ? `${gtlTaskList.length} items`
                            : `${gtlTaskList.length} item`}
                    </Text>
                </div>
                {isLoading && (
                    <div className={cn('flex items-center justify-center')}>
                        <Spinner size="small" />
                    </div>
                )}

                {!isLoading && !isError && gtlTaskList?.length === 0 && (
                    <Text appearance="body-m" className={cn('pt-20')}>
                        No task is available.
                    </Text>
                )}
                {groupedDataObj &&
                    Object.keys(groupedDataObj).map((key) => {
                        return (
                            <div key={key}>
                                <Text appearance="body-s-bold" color="primary-grey-100">
                                    {key}
                                </Text>

                                {groupedDataObj[key].map((taskItem: gtlTaskListProps) => {
                                    return (
                                        <GtlTaskItem
                                            key={taskItem.uid}
                                            sourceStorageZone={taskItem.sourceStorageZone}
                                            uid={taskItem.uid}
                                            onItemClick={() => {
                                                if (trolleyCode) {
                                                    updateTaskStatus.mutate(
                                                        {
                                                            taskId: taskItem.uid.toString(),
                                                            status: 'IN_PROGRESS',
                                                        },
                                                        {
                                                            onSuccess() {
                                                                localStorage.clear();
                                                                localStorage.setItem(
                                                                    'gtlTaskId',
                                                                    JSON.stringify(taskItem.uid)
                                                                );
                                                                navigate(
                                                                    `${taskItem.sourceLocationId}/scan-gtl-location`
                                                                );
                                                            },
                                                        }
                                                    );
                                                }
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
            </main>
        </div>
    );
};

export default GtlHomePage;
