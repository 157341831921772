import { Notifications } from '@jds/core';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../utils/urls';

interface Inventory {
    inventoryCode: string;
    type: string;
}

export function useInventoryDetails() {
    const rfcId = sessionStorage.getItem('rfcId');
    if (!rfcId) throw new Error('rfcId is not defined');
    return useMutation<unknown, AxiosError<ErrorResponse>, Inventory>(
        async ({ inventoryCode, type }) => {
            const response = await axios.get(
                urls.GET_INVENTORY_DETAILS(rfcId, inventoryCode, type)
            );
            return response.data;
        },
        {
            onError(error) {
                const defaultMsg = 'Something went wrong';
                const serverError = error.response?.data?.meta?.errors?.at(0);
                Notifications.toast.error({
                    title: 'Error',
                    description: serverError ?? error.response?.data?.message ?? defaultMsg,
                });
            },
        }
    );
}

interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
    message?: string;
}
