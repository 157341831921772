import { useMutation } from '@tanstack/react-query';
import { ProductFormSchema } from '../../pages/inbound/receiving';

export function useSaveProductDetails() {
    // todo: implement once the API is ready
    return useMutation<unknown, undefined, ProductFormSchema>(() => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });
    });
}
