import { Button, Heading, Icon, Modal } from '@jds/core';
import { cn } from '../../../utils/tailwind';
import { useNavigate } from 'react-router-dom';
import { IcSuccess } from '@jds/core-icons';
import { PrintProductInfo, usePrintLabels } from '../../../hooks/returns/receiving/usePrintLabels';
import { useUpdateLabelStatus } from '../../../hooks/returns/receiving/useUpdateLabelStatus';

export interface PrintLabelModalsProps {
    showSuccessModal: boolean;
    setSuccessModal: (value: boolean) => void;
    printLabelValues: PrintLabelBody;
}
export interface PrintLabelBody {
    jio_code: string;
    products: PrintProductInfo[];
    return_order_id: string;
}
export function PrintLabelModals({
    setSuccessModal,
    showSuccessModal,
    printLabelValues,
}: PrintLabelModalsProps) {
    const [navigate] = [useNavigate()];
    const printLabels = usePrintLabels();
    const updateLabelStatus = useUpdateLabelStatus();
    function onPrintLabelSuccess() {
        const pathname = '../drop-list';
        localStorage.setItem('refetch', 'true');
        navigate(pathname);
    }

    return (
        <Modal
            closed={!showSuccessModal}
            isClosable={false}
            onCloseCallback={() => setSuccessModal(false)}
        >
            <div className={cn('mb-2 text-center')}>
                <Icon color="primary" ic={<IcSuccess />} size="xxl" />
            </div>
            <div className={cn('mb-10 text-center')}>
                <Heading appearance="heading-xs">Labels generated and sent to printer</Heading>
            </div>
            <div className={cn('space-y-1')}>
                <Button
                    fullWidth
                    type="button"
                    onClick={() => {
                        updateLabelStatus.mutate(undefined, {
                            onSuccess: () => onPrintLabelSuccess(),
                        });
                    }}
                >
                    Okay
                </Button>
                <Button
                    fullWidth
                    kind="tertiary"
                    type="button"
                    onClick={() => {
                        printLabels.mutate(printLabelValues, {
                            onSuccess: () => {},
                        });
                    }}
                >
                    Labels Not Generated?
                </Button>
            </div>
        </Modal>
    );
}
