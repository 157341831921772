import { Notifications } from '@jds/core';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { urls } from '../../utils/urls';
import { ErrorResponse, SuccessResponse } from './useGetGtlTaskList';

export interface TrolleyAndToteValidationSchema {
    tote_code: string;
    jio_code: string;
    lot_id: number;
    quantity: number;
    product_type: string;
    location_id: string;
    tote_purpose: string;
}

export function useGtlToteValidation() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, TrolleyAndToteValidationSchema>(
        async (body: TrolleyAndToteValidationSchema) => {
            const response = await axios.post(urls.VALIDATE_TOTE_FOR_GTL(), body);
            return response.data;
        }
    );
}
