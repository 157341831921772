import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { ReceivingContext } from '../../../provider/returns/receiving';
import { cn } from '../../../utils/tailwind';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { Button, Container, Icon, Input, ProgressBar, Text } from '@jds/core';
import {
    CompletedQuantities,
    QuantityStatus,
} from '../../../provider/returns/receiving/ReceivingReducer';
import { DropQuantities } from './DropListPage';
import { ToteDropOptions, useDropTote } from '../../../hooks/receiving/useDropTote';
import { useReturnOrders } from '../../../hooks/returns/unloading/useReturnOrders';
import { ProductType } from '../../../hooks/receiving/useValidateTote';
import { IcAdd, IcConfirm } from '@jds/core-icons';
import { IcOrder, IcRecord } from '@jds/extended-icons';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';
import { useFetchDroppedTotesList } from '../../../hooks/returns/receiving/useFetchDroppedTotesList';
import { capitalizeFirstLetter } from '../../../utils/helper';

export function areMoreUnsealableQuantitiesLeft(
    { good, ...dropQuantities }: Record<DropQuantities, number>,
    completedQuantities: CompletedQuantities
) {
    return Object.entries(dropQuantities).some(([key, value]) => {
        return !completedQuantities.includes(key as DropQuantities) && value > 0;
    });
}

export function areMoreJioCodesLeft(jioCodes: string[], completedJioCodes: string[]) {
    return jioCodes.some((jioCode) => !completedJioCodes.includes(jioCode));
}

/**
 * A reusable page for assigning totes for good and unsealable quantities
 */
export function AssignTotePage() {
    const { reason, jioCode, stationId, shipmentId, quantity, quantityType } = useParams();
    const [navigate, queryParams] = [useNavigate(), useQueryParams()];
    const { data: returnOrders } = useReturnOrders(false);
    const currentProduct = returnOrders?.products.find(({ jio_code }) => jio_code === jioCode);
    const { state, dispatch } = useContext(ReceivingContext);
    const dropTote = useDropTote();
    const [toteValue, setToteValue] = useState(0);
    const [showDropSuccessModal, setShowDropSuccessModal] = useState(false);
    const { toteDropQuantityDetails } = useFetchDroppedTotesList();
    const dropProducts: { quantity: number; tote_code: string }[] = [];
    const activeToteId = queryParams.get('activeToteId');
    const toteDropQuantity = toteDropQuantityDetails?.tote_drop_quantity || 0;
    const totes = toteDropQuantityDetails?.tote_list || [];
    const enableDropComplete = Number(quantity) === toteDropQuantity + toteValue;
    const options: ToteDropOptions = {
        tote_purpose: 'RETURNS',
        product_type: reason?.toUpperCase() as ProductType,
        totes: dropProducts,
        jio_code: jioCode ?? '',
        location_id: stationId ?? '',
        lot_id: currentProduct?.lot_id || 0,
        tote_params: {
            returnOrderId: shipmentId ?? '',
        },
    };

    function onDropComplete() {
        const path = '../../../drop-list';
        const updatedFormValues: QuantityStatus = state.quantityStatus;
        updatedFormValues[quantityType || ''] = 0;
        dispatch({ type: 'SET_QUANTITY_STATUS', payload: updatedFormValues });

        queryParams.delete('activeToteId');
        localStorage.setItem('refetch', 'true');
        navigate(path);
    }

    return (
        <div className={cn(layoutStyles, 'pb-3')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title={`${capitalizeFirstLetter(reason || '') as ProductType} Quantity - Tote`}
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                {/*
            Make sure that the margin-bottom is greater than the height of the fixed bottom bar.
            Otherwise, the bottom bar will hide the main content
        */}
                <div className={cn('mx-6 mt-3 pb-3')}>
                    <ProgressBar
                        label="Quantity"
                        value={(toteDropQuantity + toteValue) / Number(quantity)}
                        valueLabel={`${toteDropQuantity + toteValue} / ${quantity}`}
                    />
                    <div className={cn('my-6 flex justify-between')}>
                        <Text appearance="heading-xs" color="primary-60">
                            {`${capitalizeFirstLetter(reason || '') as ProductType} Quantity`}
                            <Text appearance="body-xs" className={cn('mt-1')}>
                                Total - {quantity}
                            </Text>
                        </Text>
                        <div className={cn('flex items-center')}>
                            <Input
                                className={cn('!mt-0 max-w-[58px]')}
                                color="primary-50"
                                id="numberWithBigFont"
                                inputMode="numeric"
                                label=""
                                name="assigned-quantity"
                                size="medium"
                                type="number"
                                value={toteValue.toString()}
                                onChange={(e) => {
                                    if (
                                        Number(e.target.value) >= 0 &&
                                        Number(e.target.value) <=
                                            Number(quantity) - toteDropQuantity
                                    )
                                        setToteValue(Number(e.target.value));
                                }}
                            />
                        </div>
                    </div>
                    <Container
                        className={cn(
                            'my-4 grid grid-cols-2 space-x-4 rounded-lg border-2 border-[var(--color-primary-60)] p-4'
                        )}
                    >
                        <Icon
                            className={cn('bg-radial-gradient absolute left-3')}
                            ic={<IcOrder color="#000093" />}
                        />
                        <div className={cn('flex flex-col')}>
                            <Text appearance="body-xs" color="primary-60">
                                Active tote ID
                            </Text>
                            <Text appearance="body-xxs-bold" color="primary-60">
                                {activeToteId}
                            </Text>
                        </div>

                        <div className={cn('flex flex-row justify-around')}>
                            <div className={cn('flex flex-col items-center')}>
                                <Text appearance="body-xs" color="primary-60">
                                    Quantity
                                </Text>
                                <Text appearance="body-xxs-bold" color="primary-60">
                                    {toteValue}/{Number(quantity) - toteDropQuantity}
                                </Text>
                            </div>
                            <div className={cn('flex items-center')}>
                                <Icon ic={<IcRecord color="#25AB21" />} />
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            <main className={cn('mx-6 mt-3 mb-40')}>
                {toteValue > 0 && !enableDropComplete && (
                    <Button
                        fullWidth
                        disabled={toteValue === 0 || enableDropComplete}
                        icon={<IcAdd color="#000093" />}
                        kind="secondary"
                        size="large"
                        onClick={() => {
                            dropProducts.push({
                                quantity: toteValue,
                                tote_code: activeToteId || '',
                            });
                            dropTote.mutate(options, {
                                onSuccess: () => {
                                    queryParams.delete('activeToteId');
                                    navigate('../scan');
                                },
                            });
                        }}
                    >
                        Add New Tote
                    </Button>
                )}
                {enableDropComplete && (
                    <Button
                        fullWidth
                        disabled={!enableDropComplete}
                        isLoading={dropTote.isLoading}
                        size="large"
                        type="button"
                        onClick={() => {
                            dropProducts.push({
                                quantity: toteValue,
                                tote_code: activeToteId || '',
                            });
                            dropTote.mutate(options, {
                                onSuccess: () => {
                                    setShowDropSuccessModal(true);
                                },
                            });
                        }}
                    >
                        Drop Complete
                    </Button>
                )}
                {totes?.length > 0 && (
                    <Text appearance="body-xs" className={cn('mt-4')}>
                        Tote list ({totes.length})
                    </Text>
                )}
                <div className={cn('mt-2 grid items-center gap-y-6')}>
                    {totes.map(({ quantity, toteCode }) => {
                        return (
                            <Container
                                key={toteCode}
                                className={cn(
                                    'grid grid-cols-2 space-x-4 rounded-lg border-2 border-[var(--color-primary-60)] p-4'
                                )}
                            >
                                <Icon
                                    className={cn('absolute left-3 bg-white')}
                                    ic={<IcOrder color="#000093" />}
                                />
                                <div className={cn('flex flex-col')}>
                                    <Text appearance="body-xs">Tote ID </Text>
                                    <Text appearance="body-xxs-bold">{toteCode}</Text>
                                </div>

                                <div className={cn('flex flex-row justify-around')}>
                                    <div className={cn('flex flex-col items-center')}>
                                        <Text appearance="body-xs"> Quantity </Text>
                                        <Text appearance="body-xxs-bold">{quantity}</Text>
                                    </div>
                                    <div className={cn('flex items-center')}>
                                        <Icon ic={<IcConfirm color="#1E7B74" />} />
                                    </div>
                                </div>
                            </Container>
                        );
                    })}
                </div>
            </main>
            {showDropSuccessModal && (
                <InfoModalSection
                    ModalHeader={`Drop complete for ${
                        capitalizeFirstLetter(reason || '') as ProductType
                    } Quantity`}
                    buttonText="Continue"
                    infoText=""
                    infoType="SUCCESS"
                    showCloseIcon={true}
                    onSuccess={() => {
                        setShowDropSuccessModal(false);
                        onDropComplete();
                    }}
                />
            )}
        </div>
    );
}
