import { useQuery } from '@tanstack/react-query';
import { urls } from '../../utils/urls';
import { ProductDetails } from '../returns/unloading/useReturnProducts';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';
import { ResponseType } from '../../types/fetch';

export function useProductDetails(jioCode: string, lotId: string) {
    const rfcId = sessionStorage.getItem('rfcId');

    if (!jioCode) throw new Error('Jio code not found');

    return useQuery<ProductDetails | undefined, AxiosError<ErrorResponse>>({
        queryKey: ['product-details', rfcId, jioCode],
        queryFn: async () => {
            const response = await axios.get<ResponseType<ProductDetails>>(
                urls.PRODUCT_DETAILS(jioCode, Number(lotId))
            );
            const { data } = response.data;
            return data;
        },
        onError: (error) => {
            const defaultMsg = 'Something went wrong';
            const description = error?.response?.data?.meta?.errors ?? defaultMsg;
            Notifications.toast.error({ title: 'Error', description });
        },
    });
}

interface ErrorResponse {
    meta: {
        status: 'success' | 'failure';
        code: string;
        errors: string;
    };
}
