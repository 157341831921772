import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';
import { urls } from '../../utils/urls';
import { ResponseType } from '../../types/fetch';
import { ToteInfo } from '../../provider/inbound/cubiscan/CubiscanReducer';

export function useCubiscanToteDetails() {
    return useMutation<ResponseType<ToteInfo>, AxiosError<ErrorResponse>, string>(
        async (sourceToteId: string) => {
            if (!sourceToteId) throw new Error('Source tote ID is missing');
            const response = await axios.get(urls.GET_CUBISCAN_DETAILS(sourceToteId));
            return response?.data;
        },
        {
            onError(error) {
                const defaultMsg = 'Something went wrong';
                const serverError = error.response?.data?.meta?.errors?.at(0);
                Notifications.toast.error({
                    title: 'Error',
                    description: serverError ?? defaultMsg,
                });
            },
        }
    );
}

interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}
