import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Button, Text, Notifications } from '@jds/core';
import { ProductCard } from '../../../components/shared/ProductCard';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { useContext } from 'react';
import { PackingContext } from '../../../provider/outbound/packing';
import { useProductDetails } from '../../../hooks/cubiscan/useProductDetails';
import { usePackingStateSave } from '../../../hooks/outbound/packing/usePackingStateSave';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function FailedVerificationPage() {
    const navigate = useNavigate();
    const { state } = useContext(PackingContext);
    const { stationCode, ContainerCode, jioCode, orderId, shipmentId, packingType, lotId } =
        useParams();
    const { data: productObj } = useProductDetails(jioCode ?? '', lotId ?? '0');
    const packingOrderList = state.packingOrderList;
    const productData = packingOrderList.find((item) => item.jiocode === jioCode);
    const savePackingactivity = usePackingStateSave();

    function handleWrongProductToteDropState(
        productType: 'DAMAGED' | 'LOW_FRESHNESS' | 'WRONG' | 'EXPIRED'
    ) {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_TOTE_PACKING',
                status: 'IN_PROGRESS',
                shipmentId: shipmentId,
                orderId: orderId,
                containerId: ContainerCode,
                containerType: packingType?.toUpperCase() === 'MULTI' ? 'crate' : 'tote',
                quantity: 1,
                jiocode: jioCode,
                shipmentType: packingType?.toUpperCase(),
                packingStation: stationCode,
                locationId: stationCode,
                productReceivingSource: 'PACKING_OUTBOUND_QC',
                productType: productType,
                lotId: Number(lotId) || 0,
            },
            {
                onSuccess: () => {
                    switch (productType) {
                        case 'DAMAGED':
                            navigate(
                                `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${lotId}/drop-damaged-items`
                            );
                            break;
                        case 'LOW_FRESHNESS':
                            navigate(
                                `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${lotId}/drop-lowfreshness-items`
                            );
                            break;
                        case 'EXPIRED':
                            navigate(
                                `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${lotId}/drop-expired-items`
                            );
                            break;
                        case 'WRONG':
                            navigate(
                                `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${lotId}/drop-wrong-products`
                            );
                    }
                },
            }
        );
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Select Reason"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
            </div>
            <main className={cn('mx-3 mt-2 mb-20')}>
                <Space value="m" />
                <ProductCard
                    BBD={productObj?.expiry ?? 0}
                    EAN={productObj?.ean ?? '-'}
                    MFD={productObj?.mfg ?? 0}
                    MRP={productObj?.mrp ?? 0}
                    colorSize={`${productObj?.color ?? '-'}, ${productObj?.size ?? '-'}`}
                    image={productObj?.image_urls?.at(0)}
                    isLowFreshness={productData?.isLowFreshness}
                />
                <Space value="m" />
                <Text appearance="body-s-bold" color="primary-grey-100">
                    Select the reason
                </Text>
                <span className={cn('mx-4')}>
                    <Button
                        kind="secondary"
                        onClick={() => {
                            handleWrongProductToteDropState('DAMAGED');
                            // navigate(
                            //     `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${lotId}/drop-damaged-items`
                            // );
                        }}
                    >
                        Damage
                    </Button>
                    <Space value="s" />
                    <Button
                        kind="secondary"
                        onClick={() => {
                            handleWrongProductToteDropState('LOW_FRESHNESS');
                            // navigate(
                            //     `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${lotId}/drop-lowfreshness-items`
                            // );
                        }}
                    >
                        Low freshness
                    </Button>
                    <Space value="s" />
                    <Button
                        kind="secondary"
                        onClick={() => {
                            handleWrongProductToteDropState('EXPIRED');
                            // navigate(
                            //     `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${lotId}/drop-expired-items`
                            // );
                        }}
                    >
                        Expired Product
                    </Button>
                    <Space value="s" />
                    <Button
                        kind="secondary"
                        onClick={() => {
                            handleWrongProductToteDropState('WRONG');
                            // navigate(
                            //     `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/${lotId}/drop-wrong-products`
                            // );
                        }}
                    >
                        Wrong Product
                    </Button>
                </span>
            </main>
        </div>
    );
}
