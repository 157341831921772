import { Notifications } from '@jds/core';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { useTotesList } from '../../../hooks/putaway/useTotesList';
import { pageHeaderStyle } from '../../../styles/common';
import { cn } from '../../../utils/tailwind';
import { urls } from '../../../utils/urls';

const stationLabel = { name: 'station-id', label: 'Station ID', type: 'text' };
const dropZoneLabel = { name: 'drop-zone-id', label: 'Drop Zone ID', type: 'text' };
export function ScanStation() {
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname.split('/', 3).join('/');
    const manualInputProps = routePrefix.includes('inventory') ? dropZoneLabel : stationLabel;
    const getInventoryType = () => {
        if (routePrefix.includes('inventory')) {
            return routePrefix.includes('gtl-putaway') ? 'GTL_DROP_ZONE' : 'STOCK_TAKE_DROP_ZONE';
        } else {
            return 'RECEIVING_STATION';
        }
    };

    localStorage.clear();
    const queryClient = useQueryClient();

    const totesList = useTotesList();
    const validateInventory = useInventoryDetails();
    const onSubmit = (stationId: string) => {
        validateInventory.mutate(
            { inventoryCode: stationId, type: getInventoryType() },
            {
                onSuccess() {
                    totesList.mutate(stationId, {
                        onSuccess(res: any) {
                            if (res?.meta?.status === 'failure') {
                                Notifications.toast.error({
                                    title: 'API Failed',
                                    description: res.meta.errors.join(', '),
                                });
                            } else {
                                queryClient.setQueryData(['totes-list'], res.data);
                                navigate(`${routePrefix}/${stationId}/totes-list`);
                            }
                        },
                        onError(err: any) {
                            Notifications.toast.error({
                                title: 'Error',
                                description: err?.response?.data?.meta?.errors
                                    ? err.response.data.meta.errors.join(', ')
                                    : err.message,
                            });
                        },
                    });
                },
            }
        );
    };
    const getPutawayDetails = useCallback(async () => {
        try {
            const res = await axios.get(urls.GET_PUTAWAY_BY_USER());
            if (res?.data?.data) {
                const { adhocToteDtoList, putListId } = res.data.data;
                localStorage.setItem('PutListId', putListId);
                const putawayStartedTote = adhocToteDtoList.find(
                    (item: any) => item.tote_status === 'PUT_AWAY_STARTED'
                );
                queryClient.setQueryData(['generated-totes-list'], adhocToteDtoList);
                const stationId = adhocToteDtoList[0].mapped_station;
                if (putawayStartedTote) {
                    const stationId = putawayStartedTote?.mapped_station;
                    const { tote_code, put_away_task_dto, mapped_zone } = putawayStartedTote;
                    const isPendingJiocode = putawayStartedTote.is_jio_code_pending;
                    localStorage.setItem('lotId', put_away_task_dto?.lot_id);
                    if (!isPendingJiocode) {
                        const items = putawayStartedTote.products;
                        const remainingItems = items.filter(
                            (item: any) => item.status === 'ACTIVE'
                        );
                        const completedItems = items.filter(
                            (item: any) => item.status === 'INACTIVE'
                        );
                        queryClient.setQueryData(['tote-active-products'], remainingItems);
                        queryClient.setQueryData(['tote-completed-products'], completedItems);
                        navigate(`./${stationId}/${putawayStartedTote.tote_code}/scan-jiocode`);
                    } else {
                        const isPutawayPending = putawayStartedTote.is_put_away_pending;
                        if (isPutawayPending) {
                            localStorage.setItem(
                                'PutawayTaskId',
                                put_away_task_dto.putaway_task_id
                            );
                            const putawayTaskStatus =
                                putawayStartedTote.put_away_task_dto.put_away_task_status;
                            if (putawayTaskStatus === 'PUT_AWAY_TASK_CREATED') {
                                localStorage.setItem('putawayStorageZone', mapped_zone);
                                navigate(
                                    `./${stationId}/${tote_code}/${put_away_task_dto.jio_code}/${put_away_task_dto.suggested_bin_id}/scan-location`
                                );
                            } else {
                                localStorage.setItem(
                                    'PutawayTotalQuantity',
                                    put_away_task_dto.total_quantity_kept_in_tote
                                );
                                localStorage.setItem(
                                    'PutawayQuantityTillNow',
                                    `${
                                        put_away_task_dto.total_quantity_kept_in_tote -
                                        put_away_task_dto.quantity_remaining_in_tote
                                    }`
                                );
                                localStorage.setItem(
                                    'scannedQuantity',
                                    put_away_task_dto.put_away_task_quantity
                                );
                                // localStorage.setItem('PutawayLocationsTillNow', mapped_zone);
                                navigate(
                                    `./${stationId}/${tote_code}/${put_away_task_dto.jio_code}/${put_away_task_dto.suggested_bin_id}/putaway-quantity`
                                );
                            }
                        } else {
                            const product = putawayStartedTote.products.find(
                                (item: any) => item.current_quantity !== item.total_quantity
                            );
                            localStorage.setItem(
                                'PutawayQuantityTillNow',
                                `${product.total_quantity - product.current_quantity}`
                            );
                            navigate(
                                `./${stationId}/${tote_code}/${product.jio_code}/1/new-location`
                            );
                        }
                    }
                } else {
                    navigate(`./${stationId}/scan-tote`);
                }
            }
        } catch (error: any) {
            Notifications.toast.error({
                title: error?.response?.data?.meta?.errors[0] ?? 'Something went wrong',
            });
        }
    }, [navigate, queryClient]);
    useEffect(() => {
        getPutawayDetails();
    }, [getPutawayDetails]);
    return (
        <div className={cn(pageHeaderStyle)}>
            <PageHeaderV2 isExtended title="Station ID" onBackIconClick={() => navigate(-1)} />
            <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount
                    autoSuggest={true}
                    inputProps={manualInputProps}
                    type="RECEIVING_STATION"
                    onSuccess={(value) => {
                        onSubmit(value);
                    }}
                />
            </div>
        </div>
    );
}
