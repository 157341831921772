import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Divider, Icon, Notifications, ProgressBar, Text } from '@jds/core';
import { IcSuccess } from '@jds/core-icons';
import { useContext, useEffect, useState } from 'react';
import { ReceivingContext } from '../../../provider/inbound/receiving';
import { QuantityStatus } from '../../../provider/inbound/receiving/ReceivingReducer';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';
import { DropListSection } from '../../../components/receiving/DropListSection';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import {
    QuantityDetails,
    useFetchDropListQuantity,
} from '../../../hooks/receiving/useFetchDropListQuantity';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { useCurrentPackage } from '../../../hooks/receiving/usePackages';

const layoutStyles = 'max-w-screen-md md:mx-auto';

export const reasonLabels: Record<keyof QuantityStatus, string> = {
    good: 'Good Quantity',
    batchIssue: 'Batch Issue',
    damaged: 'Damage',
    short: 'Short',
    excess: 'Excess',
    lowFreshness: 'Low Freshness',
    expired: 'Expired',
    wrong: 'Title Mismatch',
    failed: 'Failed Quantity',
    cubiscan: 'Cubiscan',
};

export function DropListPage() {
    const [navigate, queryParams] = [useNavigate(), useQueryParams()];
    const { jioCode } = useParams();
    const { dropListQuantity } = useFetchDropListQuantity();
    const { quantityStatus } = useContext(ReceivingContext).state;
    const restQuantityKeys = Object.keys(quantityStatus) as (keyof QuantityStatus)[];
    const restQuantityWithValues = restQuantityKeys.filter((key) => quantityStatus[key] > 0);
    const { dispatch } = useContext(ReceivingContext);

    const dropCompletedInitialValues: QuantityStatus = {
        good: 0,
        damaged: 0,
        short: 0,
        batchIssue: 0,
        excess: 0,
        lowFreshness: 0,
        expired: 0,
        wrong: 0,
        failed: 0,
        cubiscan: 0,
    };
    const [isLoading, setIsLoading] = useState(false);
    const [dropCompletedValues, setDropCompletedValues] = useState(dropCompletedInitialValues);
    const [totalDroppedQty, setTotalDroppedQty] = useState(0);
    const currentPackage = useCurrentPackage();
    const [showEANSuccessModal, setShowEANSuccessModal] = useState(false);

    localStorage.removeItem('receiving_state');

    useEffect(() => {
        const refetchBool = localStorage.getItem('refetch');
        if (dropListQuantity.length < 1 || refetchBool === 'true') return;
        setIsLoading(true);
        const updatedFormValues: QuantityStatus = {
            good: 0,
            damaged: 0,
            short: 0,
            batchIssue: 0,
            excess: 0,
            lowFreshness: 0,
            expired: 0,
            wrong: 0,
            failed: 0,
            cubiscan: 0,
        };

        let isToteDropStarted = false;
        let startedToteType = '';
        let toteDropCount = 0;
        let isCurrentJioCodeDone = true;
        let startedToteQuantity = 0;
        dropListQuantity.map((value: QuantityDetails) => {
            isToteDropStarted = isToteDropStarted || value.tote_drop_status === 'TOTE_DROP_STARTED';
            startedToteType =
                value.tote_drop_status === 'TOTE_DROP_STARTED'
                    ? value.product_type
                    : startedToteType;
            if (startedToteType !== '' && startedToteQuantity === 0) {
                startedToteQuantity = value.total_quantity;
            }
            toteDropCount =
                value.tote_drop_status === 'TOTE_DROP_COMPLETED'
                    ? toteDropCount + 1
                    : toteDropCount;
            isCurrentJioCodeDone =
                value.jio_code === jioCode &&
                value.tote_drop_status === 'TOTE_DROP_COMPLETED' &&
                isCurrentJioCodeDone;
            if (value.product_type === 'GOOD') {
                updatedFormValues.good = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.good =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            } else if (value.product_type === 'DAMAGED') {
                updatedFormValues.damaged = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.damaged =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            } else if (value.product_type === 'LOW_FRESHNESS') {
                updatedFormValues.lowFreshness = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.lowFreshness =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            } else if (value.product_type === 'EXPIRED') {
                updatedFormValues.expired = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.expired =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            } else if (value.product_type === 'WRONG') {
                updatedFormValues.wrong = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.wrong =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            } else if (value.product_type === 'BATCH_ISSUE') {
                updatedFormValues.batchIssue = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.batchIssue =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            } else if (value.product_type === 'EXCESS') {
                updatedFormValues.excess = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.excess =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            } else if (value.product_type === 'CUBISCAN') {
                updatedFormValues.cubiscan = value.total_quantity - value.tote_drop_quantity;
                dropCompletedValues.cubiscan =
                    value.tote_drop_status === 'TOTE_DROP_COMPLETED' ? value.tote_drop_quantity : 0;
            }
        });

        updatedFormValues.failed =
            updatedFormValues.batchIssue +
            updatedFormValues.damaged +
            updatedFormValues.expired +
            updatedFormValues.lowFreshness +
            updatedFormValues.wrong;

        dispatch({ type: 'SET_QUANTITY_STATUS', payload: updatedFormValues });

        dropCompletedValues.failed =
            dropCompletedValues.batchIssue +
            dropCompletedValues.damaged +
            dropCompletedValues.expired +
            dropCompletedValues.lowFreshness +
            dropCompletedValues.wrong;
        setTotalDroppedQty(
            dropCompletedValues.failed +
                dropCompletedValues.good +
                dropCompletedValues.excess +
                dropCompletedValues.cubiscan
        );

        setDropCompletedValues(dropCompletedValues);
        let pathname = '';
        if (isToteDropStarted) {
            switch (startedToteType) {
                case 'GOOD':
                    pathname = `../assign-tote/good-quantity/good/${startedToteQuantity}`;
                    break;
                case 'DAMAGED':
                    pathname = `../assign-tote/failed-quantity/damaged/${startedToteQuantity}`;
                    break;
                case 'LOW_FRESHNESS':
                    pathname = `../assign-tote/failed-quantity/lowFreshness/${startedToteQuantity}`;
                    break;
                case 'EXPIRED':
                    pathname = `../assign-tote/failed-quantity/expired/${startedToteQuantity}`;
                    break;
                case 'WRONG':
                    pathname = `../assign-tote/failed-quantity/wrong/${startedToteQuantity}`;
                    break;
                case 'BATCH_ISSUE':
                    pathname = `../assign-tote/failed-quantity/batchIssue/${startedToteQuantity}`;
                    break;
                case 'EXCESS':
                    pathname = `../assign-tote/excess-quantity/excess/${startedToteQuantity}`;
                    break;
            }
        }
        setIsLoading(false);
        if (pathname !== '') {
            navigate(pathname + '/scan');
        }

        if (toteDropCount === dropListQuantity.length && isCurrentJioCodeDone) {
            setShowEANSuccessModal(true);
        }
    }, [dispatch, dropCompletedValues, dropListQuantity, jioCode, navigate]);

    return (
        <div className={cn(layoutStyles, 'pb-3')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Drop list"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                <div className={cn('mx-5 mt-3 flex pb-3')}>
                    <Icon color="primary" ic={<IcSuccess />} size="m" />
                    <Text appearance="body-s-bold" className={cn('ml-2')} color="primary-50">
                        Paste the labels and drop in totes
                    </Text>
                </div>
            </div>
            {isLoading && (
                <ProgressBar
                    className={cn('mx-6 mt-24')}
                    label="loading"
                    type="indeterminate"
                    value={0.3}
                />
            )}
            {!isLoading && (
                <main className={cn('mx-6 mt-3 mb-20')}>
                    <div className={cn('mt-5 grid items-center gap-y-6')}>
                        {quantityStatus.cubiscan > 0 && (
                            <DropListSection
                                backgroundColour="primary-grey-40"
                                successCallBack={() => {
                                    navigate(
                                        `../assign-tote/cubiscan-quantity/${quantityStatus.cubiscan}/scan`
                                    );
                                }}
                                title="Cubiscan Quantity"
                                valueToDisplay={quantityStatus.cubiscan}
                            />
                        )}
                        {quantityStatus.good > 0 && (
                            <DropListSection
                                backgroundColour="sparkle-20"
                                successCallBack={() => {
                                    navigate(
                                        `../assign-tote/good-quantity/good/${quantityStatus.good}/scan`
                                    );
                                }}
                                title="Good Quantity"
                                valueToDisplay={quantityStatus.good}
                            />
                        )}
                        {quantityStatus.excess > 0 && (
                            <DropListSection
                                backgroundColour="secondary-30"
                                successCallBack={() => {
                                    navigate(
                                        `../assign-tote/excess-quantity/excess/${quantityStatus.excess}/scan`
                                    );
                                }}
                                title="Excess Quantity"
                                valueToDisplay={quantityStatus.excess}
                            />
                        )}
                        {quantityStatus.failed > 0 && (
                            <Container background="feedback-error-20" pad="s" rounded="medium">
                                <>
                                    <div className={cn('mr-20 mb-6 flex justify-between')}>
                                        <Text appearance="body-s-bold" color="feedback-error-50">
                                            Failed Quantity
                                        </Text>
                                        <div className={cn('flex justify-end')}>
                                            <Text
                                                appearance="body-s-bold"
                                                className={cn('mx-7')}
                                                color="feedback-error-50"
                                            >
                                                {quantityStatus.failed}
                                            </Text>
                                        </div>
                                    </div>
                                    {restQuantityWithValues.map((item) => {
                                        return (
                                            item !== 'good' &&
                                            item !== 'short' &&
                                            item !== 'excess' &&
                                            item !== 'failed' &&
                                            item !== 'cubiscan' && (
                                                <div className={cn('my-3')}>
                                                    <DropListSection
                                                        key={item}
                                                        backgroundColour="feedback-error-20"
                                                        showPadding={false}
                                                        successCallBack={() => {
                                                            navigate(
                                                                `../assign-tote/failed-quantity/${item}/${quantityStatus[item]}/scan`
                                                            );
                                                        }}
                                                        title={reasonLabels[item]}
                                                        valueToDisplay={quantityStatus[item]}
                                                    />
                                                </div>
                                            )
                                        );
                                    })}
                                </>
                            </Container>
                        )}
                    </div>
                    {totalDroppedQty > 0 && (
                        <div className={cn('mt-6')}>
                            <Divider />
                            <Text
                                appearance="body-s-bold"
                                className={cn('mt-4 ml-3')}
                                color="primary-grey-80"
                            >
                                Drop Completed
                            </Text>
                        </div>
                    )}
                    <div className={cn('mt-5 grid items-center gap-y-6')}>
                        {dropCompletedValues.cubiscan > 0 && (
                            <DropListSection
                                backgroundColour="primary-grey-40"
                                showConfirmIcon={true}
                                showDrop={false}
                                successCallBack={() => {}}
                                title="Cubiscan Quantity"
                                valueToDisplay={dropCompletedValues.cubiscan}
                            />
                        )}
                        {dropCompletedValues.good > 0 && (
                            <DropListSection
                                backgroundColour="sparkle-20"
                                showConfirmIcon={true}
                                showDrop={false}
                                successCallBack={() => {}}
                                title="Good Quantity"
                                valueToDisplay={dropCompletedValues.good}
                            />
                        )}
                        {dropCompletedValues.excess > 0 && (
                            <DropListSection
                                backgroundColour="secondary-30"
                                showConfirmIcon={true}
                                showDrop={false}
                                successCallBack={() => {}}
                                title="Excess Quantity"
                                valueToDisplay={dropCompletedValues.excess}
                            />
                        )}
                        {dropCompletedValues.failed > 0 && (
                            <Container background="feedback-error-20" pad="s" rounded="medium">
                                <>
                                    <div className={cn('mr-16 mb-6 flex justify-between')}>
                                        <Text appearance="body-s-bold" color="feedback-error-50">
                                            Failed Quantity
                                        </Text>
                                        <div className={cn('flex justify-end')}>
                                            <Text
                                                appearance="body-s-bold"
                                                className={cn('mx-7')}
                                                color="feedback-error-50"
                                            >
                                                {dropCompletedValues.failed}
                                            </Text>
                                        </div>
                                    </div>
                                    {restQuantityKeys.map((item) => {
                                        return (
                                            item !== 'good' &&
                                            item !== 'short' &&
                                            item !== 'excess' &&
                                            item !== 'failed' &&
                                            item !== 'cubiscan' &&
                                            dropCompletedValues[item] > 0 && (
                                                <div className={cn('my-3')}>
                                                    <DropListSection
                                                        key={item}
                                                        backgroundColour="feedback-error-20"
                                                        showConfirmIcon={true}
                                                        showDrop={false}
                                                        showPadding={false}
                                                        successCallBack={() => {}}
                                                        title={reasonLabels[item]}
                                                        valueToDisplay={dropCompletedValues[item]}
                                                    />
                                                </div>
                                            )
                                        );
                                    })}
                                </>
                            </Container>
                        )}
                    </div>
                </main>
            )}
            {showEANSuccessModal && (
                <InfoModalSection
                    ModalHeader={`Ean ${currentPackage?.ean} receiving is completed successfully`}
                    buttonText="Continue"
                    infoText=""
                    infoType="SUCCESS"
                    showCloseIcon={true}
                    onSuccess={() => {
                        setShowEANSuccessModal(false);
                        localStorage.setItem('EAN_SCANNED_LIST', 'TRUE');
                        navigate('../../');
                    }}
                />
            )}
        </div>
    );
}
