import { Route } from 'react-router-dom';
import { AddNewLocation } from '../../../pages/returns/putaway/AddNewLocation';
import { AddPutawayTotes } from '../../../pages/returns/putaway/AddPutawayTotes';
import { LocationAndProductDetails } from '../../../pages/returns/putaway/LocationAndProductDetails';
import { PutawayJiocodeQuantity } from '../../../pages/returns/putaway/PutawayJiocodeQuantity';
import { PutawaySuccess } from '../../../pages/returns/putaway/PutawaySuccess';
import { ScanJiocode } from '../../../pages/returns/putaway/ScanJiocode';
import { ScanStation } from '../../../pages/returns/putaway/ScanStation';
import { ScanTote } from '../../../pages/returns/putaway/ScanTote';
import { TotePutawaySuccess } from '../../../pages/returns/putaway/TotePutawaySuccess';
import { TotesList } from '../../../pages/returns/putaway/TotesList';

export const ReturnsPutawayRoutes = [
    <Route key="1" element={<ScanStation />} path="" />,
    <Route key="2" element={<TotesList />} path=":stationId/totes-list" />,
    <Route key="3" element={<AddPutawayTotes />} path=":stationId/add-totes" />,
    <Route key="4" element={<ScanTote />} path=":stationId/scan-tote" />,
    <Route key="6" element={<ScanJiocode />} path=":stationId/:toteId/scan-jiocode" />,
    <Route
        key="7"
        element={<LocationAndProductDetails />}
        path=":stationId/:toteId/:jioCode/:locationId/scan-location"
    />,
    <Route
        key="8"
        element={<PutawayJiocodeQuantity />}
        path=":stationId/:toteId/:jioCode/:locationId/putaway-quantity"
    />,
    <Route
        key="9"
        element={<AddNewLocation />}
        path=":stationId/:toteId/:jioCode/:locationId/new-location"
    />,
    <Route key="10" element={<TotePutawaySuccess />} path=":stationId/:toteId/success" />,
    <Route key="11" element={<PutawaySuccess />} path=":stationId/success" />,
];
