import { useState } from 'react';
import { cn } from '../../utils/tailwind';
import { Button, Text } from '@jds/core';
import { IcAlignmentFilling } from '@jds/extended-icons';

interface ProductImageProps {
    image?: string;
}

export function ProductImage({ image }: ProductImageProps) {
    const [isImageExpanded, setIsImageExpanded] = useState(false);
    return (
        <div className={cn('space-y-3')}>
            <Text appearance="body-s" color="primary-grey-100">
                Image
            </Text>
            <div
                className={cn(
                    'transition-shape relative duration-500',
                    isImageExpanded ? 'h-[100%] w-[100%]' : 'h-[125px] w-[125px]'
                )}
            >
                <img
                    alt="Product Image"
                    className={cn('h-full rounded-3xl object-cover')}
                    height={isImageExpanded ? 125 : '100%'}
                    src={image ?? 'https://via.placeholder.com/125'}
                    width={isImageExpanded ? 125 : '100%'}
                />
                <span className={cn('absolute bottom-2 right-2')}>
                    <Button
                        aria-label="go back to previous page"
                        icon={<IcAlignmentFilling color="black" />}
                        kind="tertiary"
                        size="small"
                        type="button"
                        onClick={() => {
                            setIsImageExpanded(!isImageExpanded);
                        }}
                    />
                </span>
            </div>
        </div>
    );
}
