import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Package } from '../../types/receiving';
import { urls } from '../../utils/urls';
import { useParams } from 'react-router-dom';

export interface PackageResponse {
    data: Package[];
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

/**
 * Fetch all packages for a given appointment number
 */
export function usePackages() {
    const { appointmentNumber } = useParams();
    const rfcId = sessionStorage.getItem('rfcId');

    const { data, ...rest } = useQuery<PackageResponse>({
        queryKey: ['packages', rfcId, appointmentNumber],
        queryFn: async () => {
            if (!appointmentNumber) throw new Error('No appointment number provided');
            const response = await axios.get(urls.GET_PACKAGES(Number(appointmentNumber)));
            return response.data;
        },
    });
    return { packages: data?.data ?? [], ...rest };
}

export function usePackagesBySecondaryCase(secondaryCaseIdParam?: string) {
    const { packages, isLoading } = usePackages();
    const { secondaryCaseId } = useParams();
    const finalSecondaryCaseId = secondaryCaseIdParam ?? secondaryCaseId;
    const filteredPackages = packages.filter(
        (pkg) =>
            pkg.boxLabel === finalSecondaryCaseId ||
            pkg.packageLabel === finalSecondaryCaseId ||
            Number(secondaryCaseId) === -1
    );
    return { packages: filteredPackages, isLoading };
}

/**
 * Fetch all packages for a given primary case id and appointment number
 */
export function usePackagesByPrimaryCase(
    secondaryCaseIdParam?: string,
    primaryCaseIdParam?: string
) {
    const { packages, isLoading } = usePackagesBySecondaryCase(secondaryCaseIdParam);
    const { primaryCaseId, secondaryCaseId } = useParams();
    const finalPrimaryCaseId = primaryCaseIdParam ?? primaryCaseId;
    const finalSecondaryCaseId = secondaryCaseIdParam ?? secondaryCaseId;

    if (!finalPrimaryCaseId) throw new Error('No primary case id provided');
    if (!finalSecondaryCaseId) throw new Error('No secondary case id provided');

    const filteredPackages = packages.filter((pkg) => pkg.packageLabel === finalPrimaryCaseId);
    return { packages: filteredPackages, isLoading };
}

/**
 * Fetch package for a given jio code in the current primary case and appointment number
 */
export function useCurrentPackage() {
    const { jioCode } = useParams();

    if (!jioCode) throw new Error('No jio code provided');

    const { packages } = usePackagesByPrimaryCase();
    return packages.find((pkg) => pkg.jioCode === jioCode);
}
