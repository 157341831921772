import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Button, Text } from '@jds/core';
import { ProductCard } from '../../../components/shared/ProductCard';
import { useGetProducts } from '../../../hooks/inventory/stockTake/useProductDetails';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';

const productData = {
    ean: 'string',
    mrp: 1,
    expiry: 7605187200000,
    mfg: 1640995200000,
    color: 'Red',
    size: 'XL',
    image_urls: [],
};
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function UnsaleableItemPage() {
    const navigate = useNavigate();
    const { stockTakeId, binlocation, jioCode, lotId } = useParams();
    const currentQuantity = Number(useQueryParams().get('quantity'));
    const { data: currentPackage } = useGetProducts([
        { jioCode: jioCode || '', lotId: Number(lotId) },
    ]);
    const productInfo = currentPackage ? currentPackage[0] : productData;
    const StockTakeProductsJson = localStorage.getItem('stock-take-products-list') || '[]';
    const StockTakeProducts = JSON.parse(StockTakeProductsJson);
    const queryPrams = new URLSearchParams();
    queryPrams.append('lotId', lotId || '');
    queryPrams.append('quantity', currentQuantity.toString());

    const updateQuantityForStockTake = (jioCode: string, quantityType: string) => {
        const val = StockTakeProducts.findIndex(
            (item: { jio_code: string }) => item.jio_code === jioCode
        );
        const quantityName = getQuantityType(quantityType);
        const quantity = StockTakeProducts[val][quantityName] ?? 0;
        StockTakeProducts[val][quantityName] = quantity + currentQuantity;
        localStorage.setItem('stock-take-products-list', JSON.stringify(StockTakeProducts));
    };

    const getQuantityType = (quantityName: string) => {
        switch (quantityName) {
            case 'damaged':
                return 'damaged_actual';
            case 'expiry':
                return 'expired';
            case 'mismatch':
                return 'wrong_item';
            case 'batchIssue':
                return 'attributes_mis_match';
            case 'nearExpiry':
                return 'near_expiry';
            default:
                return 'wrong_item';
        }
    };

    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <PageHeader
                showBackIcon={true}
                title="Unsaleable Item"
                onBackIconClick={() => {
                    navigate(-1);
                }}
            />
            <main className={cn('mx-3 mt-4 mb-20')}>
                <ProductCard
                    BBD={productInfo.expiry}
                    EAN={productInfo.ean}
                    MFD={productInfo.mfg}
                    MRP={productInfo.mrp}
                    colorSize={'- ' + productInfo.color + ' & ' + productInfo.size}
                    image={productInfo.image_urls?.at(0)}
                />
                <Space value="m" />
                <Text appearance="body-s-bold" color="primary-grey-100">
                    Select the reason
                </Text>
                <span className={cn('mx-4')}>
                    <Button
                        kind="secondary"
                        onClick={() => {
                            updateQuantityForStockTake(jioCode || '', 'damaged');
                            navigate(
                                `/inventory/stock-take/${stockTakeId}/${binlocation}/${jioCode}/${lotId}/damaged-expired-items?${queryPrams.toString()}`
                            );
                        }}
                    >
                        Damaged
                    </Button>
                    <Space value="s" />
                    <Button
                        kind="secondary"
                        onClick={() => {
                            updateQuantityForStockTake(jioCode || '', 'expiry');
                            navigate(
                                `/inventory/stock-take/${stockTakeId}/${binlocation}/${jioCode}/${lotId}/damaged-expired-items?${queryPrams.toString()}`
                            );
                        }}
                    >
                        Expiry Issue
                    </Button>
                    <Space value="s" />
                    <Button
                        kind="secondary"
                        onClick={() => {
                            updateQuantityForStockTake(jioCode || '', 'mismatch');
                            navigate(
                                `/inventory/stock-take/${stockTakeId}/${binlocation}/${jioCode}/${lotId}/excess-items?${queryPrams.toString()}`
                            );
                        }}
                    >
                        Product mismatch / Excess
                    </Button>
                    <Space value="s" />
                    <Button
                        kind="secondary"
                        onClick={() => {
                            updateQuantityForStockTake(jioCode || '', 'batchIssue');
                            navigate(
                                `/inventory/stock-take/${stockTakeId}/${binlocation}/${jioCode}/${lotId}/excess-items?${queryPrams.toString()}`
                            );
                        }}
                    >
                        Batch Issue
                    </Button>
                    <Space value="s" />
                    <Button
                        kind="secondary"
                        onClick={() => {
                            updateQuantityForStockTake(jioCode || '', 'nearExpiry');
                            navigate(
                                `/inventory/stock-take/${stockTakeId}/${binlocation}/${jioCode}/${lotId}/damaged-expired-items?${queryPrams.toString()}`
                            );
                        }}
                    >
                        Near Expiry
                    </Button>
                </span>
            </main>
        </div>
    );
}
