import { cn } from '../../utils/tailwind';
import { Container, Icon, Input, Text } from '@jds/core';
import { IcAddCircle, IcEditPen, IcMinimise } from '@jds/core-icons';
import { useState } from 'react';

interface IncrementDecrementProps {
    title: string;
    defaultInputValue?: number;
    successCallBack: (value: number) => void;
    disableEdit?: boolean;
}

export function IncrementDecrementSection({
    title,
    defaultInputValue = 0,
    successCallBack,
    disableEdit = false,
}: IncrementDecrementProps) {
    const inputValue = defaultInputValue;
    const [editMode, setEditMode] = useState(false);
    return (
        <div className={cn([!editMode && 'rounded-lg border border-indigo-800'])}>
            <Container
                background={!editMode ? `primary-background` : `primary-20`}
                pad="m"
                rounded="medium"
            >
                <div className={cn('flex items-center justify-between')}>
                    <Text
                        appearance="body-s-bold"
                        color={!editMode ? `primary-grey-80` : `primary-60`}
                    >
                        {title}
                    </Text>
                    <div className={cn('flex items-center justify-end')}>
                        {editMode && (
                            <>
                                <Icon
                                    aria-label="decrement"
                                    ic={<IcMinimise />}
                                    style={{ color: '#7F7FFB' }}
                                    onClick={() => {
                                        if (inputValue > 0) {
                                            // setInputValue(inputValue - 1);
                                            successCallBack(inputValue - 1);
                                        }
                                    }}
                                />
                                <Input
                                    className={cn('mx-3 !mt-0 flex w-7 !pb-0')}
                                    color="primary-50"
                                    id="number"
                                    inputMode="numeric"
                                    label=""
                                    name=""
                                    type="number"
                                    value={inputValue.toString()}
                                    onChange={(event) => {
                                        // setInputValue(Number(event.target.value));
                                        successCallBack(Number(event.target.value));
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            setEditMode(false);
                                            successCallBack(inputValue);
                                        }
                                    }}
                                />
                                <Icon
                                    aria-label="increment"
                                    ic={<IcAddCircle />}
                                    style={{ color: '#7F7FFB' }}
                                    onClick={() => {
                                        // setInputValue(inputValue + 1);
                                        successCallBack(inputValue + 1);
                                    }}
                                />
                            </>
                        )}
                        {!editMode && (
                            <>
                                <Text
                                    appearance="body-s-bold"
                                    className={cn('mr-6')}
                                    color="primary-grey-80"
                                >
                                    {inputValue}
                                </Text>
                                <Icon
                                    aria-label="edit"
                                    className={cn(disableEdit ? 'pointer-events-none' : '')}
                                    color={disableEdit ? 'grey-80' : 'primary'}
                                    ic={<IcEditPen />}
                                    onClick={() => setEditMode(true)}
                                />
                            </>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}
