import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Notifications } from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';

const manualInputProps = { name: 'tote', label: 'Tote Code', type: 'text' };
export function ScanTotePage() {
    const navigate = useNavigate();
    const validateInventory = useInventoryDetails();
    const onScanSuccess = (toteId: string) => {
        validateInventory.mutate(
            { inventoryCode: toteId, type: 'TOTE' },
            {
                onSuccess: () => navigate(`./${toteId}/scan-receiving-station`),
            }
        );
    };

    return (
        <div className={cn(pageHeaderStyle)}>
            <PageHeaderV2 title="Scan Tote" onBackIconClick={() => navigate(-1)} />
            <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount
                    autoSuggest={true}
                    inputProps={manualInputProps}
                    purpose="CUBISCAN_MACHINE_OUT"
                    type="TOTE_CODE"
                    onSuccess={onScanSuccess}
                />
            </div>
        </div>
    );
}
