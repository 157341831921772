import { useNavigate, useParams } from 'react-router-dom';
import { Text, Container, Divider } from '@jds/core';
import { cn } from '../../../utils/tailwind';
import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';
import { useContext } from 'react';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ConsolidationSuccessPopups() {
    const navigate = useNavigate();
    const { stationId, toteCode } = useParams();
    const { state, dispatch } = useContext(ConsolidationContext);

    const consolidatedCrateCodesTillNow = state.consolidatedOrdersDetails;
    const consolidatedOrderDetails = consolidatedCrateCodesTillNow?.[0];
    const cratesList = consolidatedCrateCodesTillNow?.[0]?.crateIdList ?? [];

    const handleNextOrder = () => {
        if (consolidatedCrateCodesTillNow?.length > 0) {
            consolidatedCrateCodesTillNow.shift();
            dispatch({
                type: 'SET_CONSOLIDATED_ORDERS_DETAILS',
                payload: consolidatedCrateCodesTillNow,
            });
            consolidatedCrateCodesTillNow?.length > 0
                ? navigate(`/outbound/consolidation/${stationId}/${toteCode}/bulk/success`)
                : navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
        }
    };
    return (
        <div className={cn(layoutStyles)}>
            <div className={cn('flex')}>
                <SuccessPageV2
                    buttonText="Continue"
                    message="Order Consolidation completed, Send Listed crates to pack station"
                    onButtonClick={() => {
                        handleNextOrder();
                    }}
                />
            </div>
            <main className={cn('mx-1 mt-2 mb-20')}>
                {cratesList.length > 0 && (
                    <div className={cn('mb-20 mt-4')}>
                        <div className={cn('mx-4 mt-2 grid grid-cols-2 items-start')}>
                            <Text appearance="body-m" color="primary-grey-60">
                                Location Id
                            </Text>
                            <div className={cn('w-full')}>
                                <div className={cn('mr-auto ml-4 max-w-[52px]')}>
                                    <Text appearance="body-m" color="primary-grey-60">
                                        {consolidatedOrderDetails?.ptwlocation}
                                    </Text>
                                </div>
                            </div>
                        </div>
                        {cratesList.length > 0 && (
                            <Container
                                background="primary-grey-20"
                                className={cn('mx-2 mt-4')}
                                pad="m"
                                rounded="large"
                            >
                                <Text
                                    appearance="body-m-bold"
                                    className={cn('flex ')}
                                    color="primary-grey-80"
                                >
                                    Order related crates
                                </Text>
                                <Container>
                                    {cratesList.map((cratecode: string, index: number) => (
                                        <div key={cratecode}>
                                            <Container className={cn('my-4 flex justify-between')}>
                                                <Text color="primary-grey-60"> {cratecode} </Text>
                                            </Container>
                                            {index < cratesList.length - 1 && <Divider />}
                                        </div>
                                    ))}
                                </Container>
                            </Container>
                        )}
                    </div>
                )}
            </main>
        </div>
    );
}
