import { Button, Heading, Icon, Modal } from '@jds/core';
import { cn } from '../../utils/tailwind';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentValuesStatus } from '../../pages/inbound/receiving';
import { IcSuccess } from '@jds/core-icons';
import { usePrintLabels } from '../../hooks/receiving/usePrintLabels';
import { useUpdateAsnStatus } from '../../hooks/receiving/useUpdateAsnStatus';
import { ReceivingContext } from '../../provider/inbound/receiving';

interface PrintLabelModalsProps {
    showSuccessModal: boolean;
    setSuccessModal: (value: boolean) => void;
}
export function PrintLabelModals({ setSuccessModal, showSuccessModal }: PrintLabelModalsProps) {
    const [navigate] = [useNavigate()];
    const printLabels = usePrintLabels();
    const updateAsnStatus = useUpdateAsnStatus();
    const { hasOnlyLowFreshness } = useCurrentValuesStatus();
    const { quantityStatus } = useContext(ReceivingContext).state;

    const [showLowFreshnessModal, setLowFreshnessModal] = useState(
        hasOnlyLowFreshness(quantityStatus)
    );
    function onPrintLabelSuccess() {
        const pathname = '../drop-list';
        localStorage.setItem('refetch', 'true');
        navigate(pathname);
    }

    return (
        <>
            <Modal
                className={cn('text-center')}
                closed={!showLowFreshnessModal}
                header="All SKUs of batch are low freshness"
                primaryCTA={{
                    title: 'Verify',
                    onClick: () => {
                        setLowFreshnessModal(false);
                        setSuccessModal(false);
                    },
                }}
                onCloseCallback={() => {
                    setLowFreshnessModal(false);
                    setSuccessModal(false);
                }}
            />
            <Modal
                closed={!showSuccessModal}
                isClosable={false}
                onCloseCallback={() => setSuccessModal(false)}
            >
                <div className={cn('mb-2 text-center')}>
                    <Icon color="primary" ic={<IcSuccess />} size="xxl" />
                </div>
                <div className={cn('mb-10 text-center')}>
                    <Heading appearance="heading-xs">Labels generated and sent to printer</Heading>
                </div>
                <div className={cn('space-y-1')}>
                    <Button
                        fullWidth
                        type="button"
                        onClick={() => {
                            updateAsnStatus.mutate(undefined, {
                                onSuccess: () => onPrintLabelSuccess(),
                            });
                        }}
                    >
                        Okay
                    </Button>
                    <Button
                        fullWidth
                        kind="tertiary"
                        type="button"
                        onClick={() => {
                            printLabels.mutate(undefined, {
                                onSuccess: () => {},
                            });
                        }}
                    >
                        Labels Not Generated?
                    </Button>
                </div>
            </Modal>
        </>
    );
}
