import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Icon, Text } from '@jds/core';
import { useQueryClient } from '@tanstack/react-query';
import { cn } from '../../../utils/tailwind';
import { TotesInfo } from '../../../components/putaway/TotesInfo';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { IcKiranaStore } from '@jds/extended-icons';
import { useEffect } from 'react';

export function TotesList() {
    const { stationId } = useParams();
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname.split('/', 3).join('/');
    const totesList = useQueryClient().getQueryData<any>(['totes-list']);

    return (
        <>
            <div className={cn(pageHeaderStyle, 'pb-1')}>
                <PageHeaderV2 isExtended title="Tote list" onBackIconClick={() => navigate(-1)} />
                <Container
                    className={cn(
                        'm-6 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-4'
                    )}
                >
                    <Icon
                        className={cn('absolute left-4 bg-[#DEDEFC]')}
                        ic={<IcKiranaStore color="#000093" />}
                    />
                    <Text appearance="body-s"> Station ID </Text>
                    <Text appearance="body-s-bold"> {stationId} </Text>
                </Container>
            </div>
            <div className={cn('mx-3 !mb-20 max-w-screen-md py-3 md:mx-auto')}>
                <TotesInfo />
                <span
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                        layoutStyles
                    )}
                >
                    <Button
                        fullWidth
                        disabled={!totesList?.length}
                        onClick={() => {
                            navigate(`${routePrefix}/${stationId}/add-totes`);
                        }}
                    >
                        Start Scan
                    </Button>
                </span>
            </div>
        </>
    );
}
