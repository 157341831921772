import { createContext, Dispatch, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import { CubiscanAction, cubiscanReducer, CubiscanState } from './CubiscanReducer';

export const initialCubiscanState: CubiscanState = {
    toteDetails: {
        tote_code: '',
        mapped_zone: '',
        mapped_product_type: '',
        tote_purpose: '',
        products: [],
    },
};

interface CubiscanContextType {
    state: CubiscanState;
    dispatch: Dispatch<CubiscanAction>;
}

export const CubiscanContext = createContext<CubiscanContextType>({
    state: initialCubiscanState,
    dispatch: () => {},
});

export function CubiscanProvider() {
    const [state, dispatch] = useReducer(cubiscanReducer, initialCubiscanState);

    return (
        <CubiscanContext.Provider value={{ state, dispatch }}>
            <Outlet />
        </CubiscanContext.Provider>
    );
}
