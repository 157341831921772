import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../utils/urls';
import { useParams } from 'react-router-dom';
import { downloadFile } from '../../utils/fetchHelper';
import { Notifications } from '@jds/core';
import { ResponseType } from '../../types/fetch';

export function usePrintLabels() {
    const { appointmentNumber, primaryCaseId, jioCode } = useParams();
    if (!primaryCaseId) throw new Error('primaryCaseId is required to print labels');
    return useMutation<unknown, AxiosError<ResponseType<unknown>>>({
        mutationFn: async () => {
            const response = await axios({
                url: urls.GENERATE_LABEL(Number(appointmentNumber), primaryCaseId, jioCode || ''),
                responseType: 'blob',
            });
            if (!response.data) throw new Error('No data received from server');
            // downloadFile(response.data, 'labels.pdf'); todo: print instead of download
            return null;
        },
        onError: (error) => {
            const defaultError = 'Something went wrong';
            const messages = error.response?.data?.meta?.errors ?? [defaultError];
            messages.forEach((description) => {
                Notifications.toast.error({ title: 'Error', description });
            });
        },
    });
}
