import { Notifications } from '@jds/core';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { PutawayTaskSchema, usePutawayTask } from '../../../hooks/putaway/UsePutawayTask';
import { pageHeaderStyle } from '../../../styles/common';
import { cn } from '../../../utils/tailwind';

const manualInputProps = { name: 'location-id', label: 'Location ID', type: 'text' };
export function AddNewLocation() {
    const [showWarning, setShowWarning] = useState(false);
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname.split('/', 3).join('/');
    const { stationId, toteId, jioCode } = useParams();
    const quantityCompletedTillNow = Number(localStorage.getItem('PutawayQuantityTillNow')) || 0;
    const putawayTask = usePutawayTask();
    const handleScanLocationCodeSuccess = (data: PutawayTaskSchema) => {
        putawayTask.mutate(data, {
            onSuccess(res) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'API Failed',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    localStorage.setItem(
                        'PutawayTotalQuantity',
                        res.data.total_quantity_kept_in_tote
                    );
                    localStorage.setItem('PutawayTaskId', res.data.putaway_task_id);
                    localStorage.setItem('scannedQuantity', res.data.put_away_task_quantity);
                    navigate(
                        `${routePrefix}/${stationId}/${toteId}/${jioCode}/${data.location_code}/putaway-quantity`
                    );
                }
            },
            onError(err: any) {
                Notifications.toast.error({
                    title: 'Error',
                    description: err?.response?.data?.meta?.errors
                        ? err.response.data.meta.errors.join(', ')
                        : err.message,
                });
                setShowWarning(true);
            },
        });
    };
    return (
        <div className={cn(pageHeaderStyle)}>
            <PageHeaderV2
                isExtended
                title="New Location"
                onBackIconClick={() => {
                    Notifications.toast.error('Unable to navigate back');
                }}
            />
            <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                <ScanOrEnterManuallyV2
                    autoSuggest={true}
                    inputProps={manualInputProps}
                    purpose="PUT_AWAY_BIN"
                    type="LOCATION"
                    onSuccess={(value) => {
                        handleScanLocationCodeSuccess({
                            toteId: toteId || '',
                            jioCode: jioCode || '',
                            putaway_quantity: quantityCompletedTillNow,
                            location_code: value,
                        });
                    }}
                />
            </div>
            {showWarning && (
                <div className={cn('mt-14')}>
                    <InfoModalSection
                        showCloseIcon
                        ModalHeader="Location ID scanned is not compatible"
                        buttonText="Select another location"
                        infoText=""
                        infoType="WARNING"
                        onSuccess={() => setShowWarning(false)}
                    />
                </div>
            )}
        </div>
    );
}
