import { urls } from '../../utils/urls';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';

export interface ReceivingDetails {
    isBoxed: true | false;
    validationStartTime: number;
    isCubiscanRequired: boolean | null;
    stationId: string;
    primaryCase: string;
    secondaryCase: string;
    jioCode: string;
    goodQuantity: number;
    damagedQuantity: number;
    expiredQuantity: number;
    wrongQuantity: number;
    batchIssueQuantity: number;
    lowFreshnessQuantity: number;
    excessQuantity: number;
    shortQuantity: number;
    qcDoneBy: string;
    appointmentId: number;
    asnValidationStatus: string;
}

export interface UserReceivingStateResponse {
    data: ReceivingDetails;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useFetchUserReceivingState() {
    const { data, ...rest } = useQuery<UserReceivingStateResponse>({
        queryKey: ['receivingStateDetails'],
        queryFn: async () => {
            const response = await axios.get(urls.GET_USER_RECEIVING_STATE());
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
    return { receivingStateDetails: data?.data, ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
