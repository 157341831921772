import { useMutation } from '@tanstack/react-query';
import { ASNValidationSchema } from '../../pages/inbound/receiving';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { Notifications } from '@jds/core';
import { urls } from '../../utils/urls';
import { useQueryParams } from '../shared/useQueryParams';

/**
 * A hook to do the ASN validation of the quantities
 */
export function useVerifyQuantities() {
    const { primaryCaseId, appointmentNumber, stationId, jioCode } = useParams();
    const queryParams = useQueryParams();
    if (!jioCode) throw new Error('jioCode is not provided');
    const username = sessionStorage.getItem('username');
    return useMutation<unknown, AxiosError<ErrorResponse>, ASNValidationSchema>(
        async (formValues) => {
            const body = {
                ...formValues,
                primaryCase: primaryCaseId,
                qcDoneBy: username,
                appointmentId: appointmentNumber,
                jioCode,
                validationStartTime: JSON.parse(localStorage.getItem('EAN_START_TIME') ?? ' '),
                isCubiscanRequired: queryParams.get('cubiscanRequired') === 'true',
                stationId: stationId,
                serialOrIMEINumberMissing: 0,
                eyeBallCheckStatus: 'Passed',
            };
            const response = await axios.patch(
                urls.ASN_VALIDATION(Number(appointmentNumber), jioCode),
                body
            );
            return response.data;
        },
        {
            onSuccess() {
                Notifications.toast.success({
                    title: 'Success',
                    description: 'Quantities validated successfully',
                });
            },
            onError(error) {
                const defaultErrorMsg = 'An error occurred while validating quantities';
                const message = error?.response?.data?.meta?.errors?.at(0) ?? defaultErrorMsg;
                Notifications.toast.error({
                    title: 'Error',
                    description: message,
                });
            },
        }
    );
}

export interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}
