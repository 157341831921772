import { cn } from '../../../utils/tailwind';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useNavigate } from 'react-router-dom';
import { useFetchReturnOrders } from '../../../hooks/returns/unloading/useFetchReturnOrders';
import { Button, Container, Divider, Notifications, Spinner, Text } from '@jds/core';
import { useContext } from 'react';
import { UnloadingContext } from '../../../provider/returns/unloading';
import { pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { useGetCompletedReturnOrders } from '../../../hooks/returns/unloading/useGetCompletedReturnOrders';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ScanAirWayBillPage() {
    const fetchReturnOrders = useFetchReturnOrders();
    const { data } = useGetCompletedReturnOrders();
    const navigate = useNavigate();
    const { state } = useContext(UnloadingContext);
    const manualInputProps = {
        label: 'Air Way Bill / Return ID',
        name: 'airWayBill',
        disabled: fetchReturnOrders.isLoading,
    };

    function onScanSuccess(value: string) {
        fetchReturnOrders.mutate(value, {
            onSuccess: ({ data }) => {
                if (state.appointmentType === 'C-Returns' && data?.return_type === 'RVP')
                    navigate(`./../${data.return_order_id}/products-to-return`);
                else if (
                    state.appointmentType === 'Return To Vendor' &&
                    data?.return_type === 'RTO'
                )
                    navigate(`./../${data.return_order_id}/products-to-return`);
                else
                    Notifications.toast.error({
                        title: 'Invalid airway bill',
                        description: 'Please try with a valid airway bill',
                    });
            },
        });
    }

    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Returns Unloading"
                    onBackIconClick={() => navigate(-1)}
                />
                <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        enableScannerOnMount
                        autoSuggest={true}
                        inputProps={manualInputProps}
                        stage="RETURNS"
                        type="returns_unloading"
                        onSuccess={onScanSuccess}
                    />
                </div>
            </div>
            {state.completedAirwayBills.length > 0 && (
                <Container
                    background="primary-grey-20"
                    className={cn('mx-3 mt-4 mb-20')}
                    pad="m"
                    rounded="large"
                >
                    <Text appearance="body-s-bold" color="primary-grey-100">
                        Completed Airway bills ({state.completedAirwayBills.length})
                    </Text>
                    {state.completedAirwayBills.map((airwayBill, index) => (
                        <>
                            <Container
                                key={airwayBill.awb_no}
                                as="div"
                                className={cn(
                                    `flex space-x-4 ${
                                        index === state.completedAirwayBills.length - 1
                                            ? 'pt-3'
                                            : 'py-3'
                                    }`
                                )}
                            >
                                <Text appearance="body-s" color="primary-grey-100">
                                    {airwayBill.awb_no}
                                </Text>
                            </Container>
                            {index !== state.completedAirwayBills.length - 1 && <Divider />}
                        </>
                    ))}
                </Container>
            )}
            <div className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}>
                <Button
                    fullWidth
                    size="large"
                    onClick={() => {
                        navigate('./../generate-por');
                    }}
                >
                    Appointment Completed
                </Button>
            </div>
            {fetchReturnOrders.isLoading && (
                <div
                    className={cn(
                        'flex items-center justify-center',
                        'absolute inset-0 h-full min-h-screen bg-gray-200/50'
                    )}
                >
                    <Spinner />
                </div>
            )}
        </>
    );
}
