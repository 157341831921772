import { Button, Container, Icon, Input, Notifications, Text } from '@jds/core';
import { IcCamera, IcSuccess } from '@jds/core-icons';
import { IcRepeat } from '@jds/extended-icons';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { useUploadSignedPOD } from '../../../hooks/unloading/useUploadSignedPOD';
import { layoutStyles } from '../../../styles/common';
import { cn } from '../../../utils/tailwind';

export function UploadSignedPOD() {
    const { stationId, appointmentNumber } = useParams();
    const [source, setSource] = useState({
        preview: '',
        raw: '',
    });
    const navigate = useNavigate();
    const handleCapture = (target: any) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                if (file.type.includes('image/')) {
                    const imageUrl = URL.createObjectURL(file);
                    setSource({
                        preview: imageUrl,
                        raw: file,
                    });
                } else {
                    Notifications.toast.error('Only images are allowed');
                }
            }
        }
    };
    const allScannedPackages = JSON.parse(localStorage.getItem('scannedPackages') || '[]');
    const uploadPOD = useUploadSignedPOD();
    const handleSubmit = () => {
        if (source.raw) {
            uploadPOD.mutate(source.raw, {
                onSuccess: () => {
                    navigate(`../${stationId}/${appointmentNumber}/success`);
                    localStorage.clear();
                },
            });
        } else Notifications.toast.error('Image is required');
    };
    return (
        <>
            <PageHeaderV2
                title="Upload POD"
                onBackIconClick={() =>
                    allScannedPackages.length
                        ? Notifications.toast.error('Unable to navigate back')
                        : navigate(-1)
                }
            />
            <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                <main className={cn('mb-14 flex flex-col justify-between px-4 pt-3 pb-5')}>
                    <Text
                        appearance="heading-xs"
                        as="div"
                        className={cn('flex items-center space-x-2')}
                        color="primary-50"
                    >
                        <Icon ic={<IcSuccess />} />
                        <div> POD Generated </div>
                    </Text>
                    <Text appearance="body-s" className={cn('mt-4')} color="primary-grey-80">
                        Upload a Signed POD here
                    </Text>
                    <Text as="label" className={cn('relative !mt-1.5')}>
                        <Input
                            className={cn('!hidden')}
                            label=""
                            name=""
                            type="file"
                            onChange={(e) => handleCapture(e.target)}
                        />
                        <Container className={cn('h-[380px] rounded-2xl bg-[#E7EBF8] text-center')}>
                            {source.preview ? (
                                <>
                                    <img
                                        alt="snap"
                                        className={cn('h-[100%] rounded-2xl object-fill')}
                                        src={source.preview}
                                    />
                                    <Text
                                        as="div"
                                        className={cn(
                                            'absolute left-[45%] top-[75%] mt-2 h-[40px] w-[40px] rounded-3xl bg-white'
                                        )}
                                    >
                                        <Icon
                                            className={cn('pt-2')}
                                            color="primary"
                                            ic={<IcRepeat />}
                                            size="l"
                                        />
                                    </Text>
                                </>
                            ) : (
                                <div className={cn('absolute left-[45%] top-[45%]')}>
                                    <Icon color="primary" ic={<IcCamera />} size="l" />
                                </div>
                            )}
                        </Container>
                    </Text>
                </main>
                <span
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                        layoutStyles
                    )}
                >
                    <Button onClick={handleSubmit}> Submit </Button>
                </span>
            </div>
        </>
    );
}
