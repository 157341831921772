import { Text } from '@jds/core';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../utils/tailwind';

import { PickListHelpItem } from '../../components/picklist/PickListHelpItem';
import { PageHeader } from '../../components/shared/PageHeader';

const layoutStyles = 'max-w-screen-md md:mx-auto';

const GtlHelp = () => {
    const navigate = useNavigate();
    const { locationId } = useParams();
    const helpNavigationItems = [
        { label: 'Item short', navigation: `../${locationId}/gtl-mark-short` },
        { label: 'Item damaged', navigation: `../${locationId}/gtl-mark-damage` },
        { label: 'Tote full', navigation: `../${locationId}/gtl-add-tote` },
    ];

    return (
        <>
            <div className={cn(layoutStyles, 'mx-3 py-3')}>
                <PageHeader
                    showHelpIcon
                    breadcrumbTitle="Help"
                    title="GTL"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                    onHelpIconClick={() => navigate(`../gtl-task-status`)}
                ></PageHeader>
                <main className={cn('mx-2 mt-3')}>
                    <Text appearance="body-s-bold" className={cn('mt-8 ml-2 mb-5')}>
                        Select the issue being faced
                    </Text>
                    {helpNavigationItems.map((item) => {
                        return (
                            <PickListHelpItem
                                key={item.label}
                                label={item.label}
                                onItemClick={() => navigate(item.navigation)}
                            />
                        );
                    })}
                </main>
            </div>
        </>
    );
};

export default GtlHelp;
