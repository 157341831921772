export const groupListByKey = (dataList: any, keyName: string) => {
    return dataList.reduce((acc: any, obj: any) => {
        const key = obj[keyName];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
};

export function allowNumbersOnly(e: { which: any; keyCode: any; preventDefault: () => void }) {
    const code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
        e.preventDefault();
    }
}

export const capitalizeFirstLetter = (inputString: string) => {
    if (inputString === '') return '';
    return inputString[0].toUpperCase() + inputString.slice(1).toLowerCase();
};
