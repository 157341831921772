import { PageHeader } from '../../../components/shared/PageHeader';
import { cn } from '../../../utils/tailwind';
import { layoutStyles } from '../../../styles/common';
import { TextAccordion } from '../../../components/shared/TextAccordion';
import { useNavigate } from 'react-router-dom';

export function DescriptionDetailsPage() {
    const navigate = useNavigate();
    return (
        <div className={cn(layoutStyles, 'mt-3')}>
            <PageHeader title="Description Details" onBackIconClick={() => navigate(-1)} />
            <main className={cn('mx-6 mt-6 space-y-4')}>
                {reasonsDescription.map(({ title, description }) => (
                    <TextAccordion key={title} description={description} title={title} />
                ))}
            </main>
        </div>
    );
}

export const reasonsDescription = [
    {
        title: 'Damage A+',
        description: [
            'Seller packaging is damage, has dents or blemishes but company/brand seal is intact',
        ],
    },
    {
        title: 'Damaged A',
        description: [
            'Packing not intact/open',
            'Minimum box damage',
            'No parts missing and the item may have minor physical damage/blemishes/stain & Dirt but in working condition with no missing part or accessories',
        ],
    },
    {
        title: 'Damaged B',
        description: [
            'Brand seal not intact/open',
            'Outer box and inside packaging majorly damaged',
            'The item may have minor physical damage/blemishes/stain & Dirt but in working condition',
            'Accessories/warranty card may be missing, accessories not working properly',
        ],
    },
    {
        title: 'Low Freshness',
        description: [
            'Products that are less than 40% freshness (acceptable threshold for outbound) at the time of receiving the return',
        ],
    },
    {
        title: 'Damage C',
        description: [
            'Company seal is open by operator/ auditor',
            'Item is not working',
            'Inherited damage from seller end',
        ],
    },
    {
        title: 'IR*',
        description: [
            'Item that has significant damage',
            'Item is completely broken into pieces',
            'Item irreparable',
        ],
    },
    {
        title: 'Wrong Product',
        description: ['If the item image or brand is not matching with image'],
    },
    {
        title: 'Expired Product',
        description: ['If BBD is crossed and product is expired'],
    },
];
