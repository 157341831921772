import { Notifications } from '@jds/core';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export function useValidateSegregationTote() {
    return useMutation(
        async (toteId: string) => {
            const response = await axios.get(urls.TOTE_SEGREGATION_VALIDATION(toteId));
            return response.data;
        },
        {
            onError: () => {
                Notifications.toast.error({
                    title: 'Invalid Tote',
                    description: 'Please scan another tote',
                });
            },
        }
    );
}
