import { urls } from '../../../utils/urls';
import { Notifications } from '@jds/core';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { PackingSuggestionListResponseSchema } from '../../../pages/outbound/packing/PackagingSuggestionPage';

export interface packingSuggestionListSchema {
    order_id: string;
    jio_code: string;
    order_type: string;
    shipment_id: string;
}

export interface packingSuggestionResponse {
    data: PackingSuggestionListResponseSchema;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure' | 'FAILURE';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function usePackingSuggestionList(requestData: packingSuggestionListSchema) {
    const { data, ...rest } = useQuery<packingSuggestionResponse>({
        queryKey: ['packingMaterials'],
        queryFn: async () => {
            const body = {
                order_id: requestData.order_id,
                jio_code: requestData.jio_code,
                order_type: requestData.order_type,
                shipment_id: requestData.shipment_id,
                rfc_id: sessionStorage.getItem('rfc-id'),
            };
            const response = await axios.post(urls.GET_PACKING_SUGGESTION(), body);
            return response.data;
        },
        retry: false,
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });

    return { packingMaterials: data?.data, ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
