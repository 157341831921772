import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../utils/urls';

export interface PutawayTaskSchema {
    toteId: string;
    jioCode: string;
    putaway_quantity: number;
    location_code?: string;
}

export interface UpdatePutawayTaskSchema {
    toteId: string;
    jioCode: string;
    putaway_quantity: number;
    bin_id: string;
    put_away_task_id: number;
    skip_reason: string;
    put_list_id: number;
}

export function usePutawayTask() {
    return useMutation(async (data: PutawayTaskSchema) => {
        const body = {
            tote_code: data.toteId,
            jio_code: data.jioCode,
            kept_quantity_till_now: data.putaway_quantity,
            location_code: data.location_code || null,
        };
        const response = await axios.post(urls.CREATE_PUTAWAY_TASK(), body);
        return response.data;
    });
}

export function useUpdatePutawayTask() {
    return useMutation(async (data: UpdatePutawayTaskSchema) => {
        const body = {
            tote_code: data.toteId,
            jio_code: data.jioCode,
            quantity: data.putaway_quantity,
            putaway_task_id: data.put_away_task_id,
            bin_id: data.bin_id,
            skip_reason: data?.skip_reason !== '' ? data.skip_reason : null,
        };
        const response = await axios.patch(urls.UPDATE_PUTAWAY_TASK(), body);
        return response.data;
    });
}

export function useCompletePutawayTask() {
    return useMutation(async (data: UpdatePutawayTaskSchema) => {
        const body = {
            tote_code: data.toteId,
            jio_code: data.jioCode,
            quantity: data.putaway_quantity,
            putaway_task_id: data.put_away_task_id,
            bin_id: data.bin_id,
            skip_reason: data?.skip_reason !== '' ? data.skip_reason : null,
        };
        const response = await axios.patch(urls.COMPLETE_PUTAWAY_TASK(), body);
        return response.data;
    });
}
