import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ResponseType } from '../../types/fetch';
import axios from 'axios';
import { urls } from '../../utils/urls';
import { UnloadingItem } from './useUnloadingVerification';

export function useFetchSecondaryCases() {
    const { appointmentNumber } = useParams();
    const rfcId = sessionStorage.getItem('rfcId');
    if (!rfcId) throw new Error('rfcId is not defined');
    return useMutation<ResponseType<Response>>({
        mutationFn: async () => {
            const response = await axios.get(
                urls.GET_SECONDARY_CASES(rfcId, Number(appointmentNumber))
            );
            return response.data;
        },
    });
}

export function useFetchAllSecondaryCaseDetails() {
    const { appointmentNumber } = useParams();
    const rfcId = sessionStorage.getItem('rfcId');
    if (!rfcId) throw new Error('rfcId is not defined');
    return useQuery<ResponseType<UnloadingItem>>({
        queryKey: ['allSecondaryCaseDetails'],
        queryFn: async () => {
            const response = await axios.get(
                urls.UNLOADING_VERIFICATION(Number(appointmentNumber))
            );
            return response?.data;
        },
    });
}

interface SecondaryCase {
    uid: number;
    boxLabel: string | null;
    packageLabel: string | null;
    receiving_qc_status: string;
}

interface Response {
    appointment_id: number;
    is_boxed: boolean;
    secondary_cases: SecondaryCase[];
}
