import { Button, Container, Heading, Input, Modal, Text } from '@jds/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { cn } from '../../../utils/tailwind';

interface EditScannedPackagesProps {
    title?: string;
    scannedPackages?: Array<any>;
    onBackClick: () => void;
    handleStatusChange: (packages: any, handle: () => void) => void;
}
export function EditScannedPackages({
    title,
    scannedPackages,
    onBackClick,
    handleStatusChange,
}: EditScannedPackagesProps) {
    const [selectedPackages, setSelectedPackages] = useState<any[]>([]);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const navigate = useNavigate();
    const handleOnChange = (event: any, pack: any) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedPackages([...selectedPackages, pack]);
        } else {
            setSelectedPackages([
                ...selectedPackages.filter((item: any) => item.case_label !== pack.case_label),
            ]);
        }
    };
    const handleStateChange = () => {
        setSelectedPackages([]);
        setConfirmationDialog(false);
    };
    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2 isExtended title="Edit Mode" onBackIconClick={onBackClick} />
                <div className={cn('max-w-screen-md px-5 py-3 md:mx-auto')}>
                    <Container className={cn('flex items-center justify-between')}>
                        <Text appearance="body-m-bold" color="primary-grey-80">
                            {title}
                        </Text>
                        <Text appearance="body-xs-bold" color="primary-60">
                            {selectedPackages.length} selected
                        </Text>
                    </Container>
                    <Container className={cn('pt-3 pb-8')}>
                        {scannedPackages?.map((item) => (
                            <Container
                                key={item.case_label}
                                className={cn(
                                    'my-4 flex border-b border-solid border-blue-700 p-4'
                                )}
                            >
                                <Input
                                    label=""
                                    name=""
                                    type="checkbox"
                                    onChange={(event) => handleOnChange(event, item)}
                                />
                                <Text>{item.case_label}</Text>
                            </Container>
                        ))}
                    </Container>
                </div>
            </div>
            <span
                className={cn(
                    'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                    layoutStyles
                )}
            >
                <Button
                    fullWidth
                    disabled={!selectedPackages.length}
                    onClick={() => setConfirmationDialog(true)}
                >
                    {title === 'Verified Packages' ? 'Mark Damaged' : 'Mark Verified'}
                </Button>
            </span>
            <Modal
                closed={!confirmationDialog}
                size="m"
                onCloseCallback={() => setConfirmationDialog(false)}
            >
                <div className={cn('text-center')}>
                    <Heading appearance="heading-xs"> Are you sure? </Heading>
                    <div className={cn('mt-12 flex space-x-2')}>
                        <Button kind="secondary" onClick={() => setConfirmationDialog(false)}>
                            No
                        </Button>
                        <Button
                            onClick={() => handleStatusChange(selectedPackages, handleStateChange)}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
