import { Button, Notifications } from '@jds/core';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IncrementDecrementSection } from '../../../components/shared/IncrementDecrementSection';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { cn } from '../../../utils/tailwind';
import { urls } from '../../../utils/urls';

export function MarkShortAndExcess() {
    const { appointmentNumber, stationId } = useParams();
    const expectedSecondaryPackages = JSON.parse(
        localStorage.getItem('expectedSecondaryPackages') || '[]'
    );
    const scannedPackages = JSON.parse(localStorage.getItem('scannedPackages') || '[]');
    const isBoxed = JSON.parse(localStorage.getItem('isBoxed') || 'false');
    const [quantity, setQuantity] = useState({
        short: expectedSecondaryPackages.length - scannedPackages.length,
        excess: 0,
    });
    const navigate = useNavigate();
    const getPayload = (packages: any) => {
        const payloadCases = packages.map((item: any) => {
            const label = isBoxed ? item.box_label : item.package_label;
            const caseId = isBoxed ? item.box_id : item.package_id;
            return {
                case_id: caseId,
                case_label: label,
                unloading_status: item.status,
                started_at: Date.now(),
                station_id: stationId,
            };
        });
        const payload = {
            is_boxed: isBoxed,
            appointment_id: appointmentNumber,
            unloaded_by: sessionStorage.getItem('username'),
            cases: payloadCases,
            excess_quantity: quantity.excess,
            is_excess_quantity_captured: true,
        };
        return payload;
    };
    const handleSubmitShortOrExcess = async (data: any) => {
        try {
            const res = await axios.patch(
                urls.UNLOADING_VERIFICATION(Number(appointmentNumber ?? 1)),
                data
            );
            if (res?.data) {
                localStorage.setItem('shortPackages', JSON.stringify([...data.cases]));
                localStorage.setItem('excessPackages', `${data.excess_quantity}`);
                navigate('./../generate-pod');
            }
        } catch (error: any) {
            Notifications.toast.error({
                title: error?.response?.data?.meta?.errors[0] ?? 'Something went wrong',
            });
        }
    };

    const handleConfirm = () => {
        const ids = scannedPackages.map((item: any) => item.case_label);
        const missingPackages = expectedSecondaryPackages.filter(
            (item: any) => !ids.includes(isBoxed ? item.box_label : item.package_label)
        );
        const updatedMissing = missingPackages.map((item: any) => ({ ...item, status: 'Missing' }));
        const payload = getPayload([...updatedMissing]);
        handleSubmitShortOrExcess(payload);
    };
    return (
        <>
            <PageHeaderV2 title="Mark Short/Excess" onBackIconClick={() => navigate(-1)} />
            <div className={cn('max-w-screen-md space-y-4 p-3 md:mx-auto')}>
                <IncrementDecrementSection
                    defaultInputValue={quantity.short}
                    disableEdit={scannedPackages.length === expectedSecondaryPackages.length}
                    successCallBack={(value) => {
                        const threshold = expectedSecondaryPackages.length - scannedPackages.length;
                        if (value < threshold) {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Short is less than scanned',
                            });
                        } else if (value > threshold) {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Short is more than expected',
                            });
                        } else {
                            setQuantity({
                                ...quantity,
                                short: value,
                            });
                        }
                    }}
                    title="Short Quantity"
                />
                <IncrementDecrementSection
                    defaultInputValue={quantity.excess}
                    successCallBack={(value) =>
                        setQuantity({
                            ...quantity,
                            excess: value,
                        })
                    }
                    title="Excess Quantity"
                />
            </div>
            <span className={cn('fixed inset-x-2 bottom-4')}>
                <Button fullWidth onClick={handleConfirm}>
                    Confirm
                </Button>
            </span>
        </>
    );
}
