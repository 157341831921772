import { useNavigate } from 'react-router-dom';
import { SuccessPage } from '../../../components/shared/SucessPage';

export function StockTakeSuccessPage() {
    const navigate = useNavigate();

    return (
        <SuccessPage
            buttonText="Take a new task"
            successMessage="Stock take
            completed successfully"
            onSuccess={() => {
                localStorage.clear();
                navigate(`/inventory/stock-take/`);
            }}
        />
    );
}
