import { cn } from '../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Icon, Text } from '@jds/core';
import { PageHeaderV2 } from '../../components/shared/PageHeaderV2';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { pageHeaderStyle } from '../../styles/common';
import { IcOrders } from '@jds/extended-icons';

const manualInputProps = { name: 'cubiscan-machine', label: 'Cubiscan Machine', type: 'text' };
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ScanCubiscanMachinePage() {
    const navigate = useNavigate();
    const { sourceToteId } = useParams();
    return (
        <>
            <div className={cn(layoutStyles)}>
                <div className={cn(pageHeaderStyle)}>
                    <PageHeaderV2
                        isExtended
                        title="Cubiscan Machine"
                        onBackIconClick={() => {
                            navigate(-1);
                        }}
                    />
                    <main className={cn('mx-6 mt-3 mb-7 pb-3')}>
                        <ScanOrEnterManuallyV2
                            enableScannerOnMount={true}
                            inputProps={manualInputProps}
                            onSuccess={(value) => navigate(value)}
                        />
                    </main>
                </div>
            </div>
            <Container
                className={cn(
                    'm-4 grid grid-cols-2 space-x-4 rounded-lg border border-solid border-[var(--color-primary-60)] p-4'
                )}
            >
                <Icon
                    className={cn('absolute left-1 bg-white')}
                    ic={<IcOrders color="#000093" />}
                />
                <Text appearance="body-s"> Tote ID </Text>
                <Text appearance="body-s-bold"> {sourceToteId} </Text>
            </Container>
        </>
    );
}
