import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { urls } from '../../utils/urls';
import { ErrorResponse, handleError, SuccessResponse } from './useGetGtlTaskList';

export interface MapGtlPutawayStationSchema {
    locationId: string;
    toteId: string;
}

const defaultErrorMsg = 'Something went wrong.';
export function useMapGtlPutawayStation() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, MapGtlPutawayStationSchema>(
        async (body: MapGtlPutawayStationSchema) => {
            const response = await axios.get(
                urls.MAP_PUTAWAY_STATION_GTL(body.locationId ?? '', body.toteId)
            );
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
