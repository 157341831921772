import { useParams } from 'react-router-dom';
import { SuccessPageV2 } from '../../components/shared/SuccessPageV2';
export function SuccessPage() {
    const { jioCode } = useParams();
    localStorage.clear();
    return (
        <>
            <SuccessPageV2 message={`Drop Complete for Jio Code ${jioCode}`} />
        </>
    );
}
