import { Button, Container, Divider, Heading, Notifications, Text } from '@jds/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { urls } from '../../../utils/urls';
import { cn } from '../../../utils/tailwind';

export function GeneratePOD() {
    const { stationId, appointmentNumber } = useParams();
    const scannedPackages = JSON.parse(localStorage.getItem('scannedPackages') ?? '[]');
    const shortPackages = JSON.parse(localStorage.getItem('shortPackages') ?? '[]');
    const excessPackages = JSON.parse(localStorage.getItem('excessPackages') || '0');
    const verifiedPackages = scannedPackages.filter(
        (item: any) => item.unloading_status === 'Success'
    );
    const damagedPackages = scannedPackages.filter(
        (item: any) => item.unloading_status === 'Damaged'
    );
    const navigate = useNavigate();
    const { isLoading, mutate: podGeneration } = useMutation(async (payload: any) => {
        const rfcId = sessionStorage.getItem('rfc-id') ?? '1';
        return await axios.post(urls.GENERATE_POD(rfcId, appointmentNumber ?? ''), payload);
    });

    const generatePOD = () => {
        const payload = {
            appointment_id: appointmentNumber,
            document_type: 'POD',
            created_by: sessionStorage.getItem('username'),
            document_upload_details: {
                template_bucket_name: 'wms-files',
                template_path: 'generatePOD.html',
                output_bucket_name: 'wms-files',
                output_pdf_path: `PODs/Unsigned/app_${appointmentNumber}.pdf`,
                data: {
                    rfcName: sessionStorage.getItem('rfc-name'),
                },
            },
        };
        podGeneration(payload, {
            onSuccess() {
                navigate(`../${stationId}/${appointmentNumber}/upload-pod`);
                localStorage.removeItem('scanned-packages');
                localStorage.removeItem('short-packages');
            },
            onError(data: any) {
                const error = data?.response?.data?.meta?.errors[0] ?? 'Something is wrong';
                Notifications.toast.error({
                    title: error,
                });
            },
        });
    };
    return (
        <>
            <PageHeaderV2
                title="Unloading Summary"
                onBackIconClick={() =>
                    scannedPackages.length
                        ? Notifications.toast.error('Unable to navigate back')
                        : navigate(-1)
                }
            />
            <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                <main className={cn('flex  flex-1 flex-col justify-between pt-3 pb-5')}>
                    <Container background="primary-grey-20" pad="m" rounded="large">
                        <Heading appearance="heading-xs" color="primary-grey-80">
                            Scanned Packages
                        </Heading>
                        <Container className={cn('mx-4')}>
                            <Container className={cn('my-4 flex justify-between')}>
                                <Text> Verified </Text>
                                <Text>{verifiedPackages.length}</Text>
                            </Container>
                            <Divider />
                            <Container className={cn('mt-4 flex justify-between')}>
                                <Text> Damaged </Text>
                                <Text> {damagedPackages.length} </Text>
                            </Container>
                        </Container>
                    </Container>
                    <Container
                        background="primary-grey-20"
                        className={cn('mt-8')}
                        pad="m"
                        rounded="large"
                    >
                        <Heading appearance="heading-xs" color="primary-grey-80">
                            Short/Excess
                        </Heading>
                        <Container className={cn('mx-4')}>
                            <Container className={cn('my-4 flex justify-between')}>
                                <Text> Short </Text>
                                <Text> {shortPackages.length} </Text>
                            </Container>
                            <Divider />
                            <Container className={cn('mt-4 flex justify-between')}>
                                <Text> Excess </Text>
                                <Text> {excessPackages} </Text>
                            </Container>
                        </Container>
                    </Container>
                </main>
                <span className={cn('fixed inset-x-2 bottom-4')}>
                    <Button fullWidth isLoading={isLoading} onClick={generatePOD}>
                        Generate POD
                    </Button>
                </span>
            </div>
        </>
    );
}
