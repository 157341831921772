import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import {
    Button,
    Container,
    Divider,
    Icon,
    Notifications,
    ProgressBar,
    Spinner,
    Text,
} from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useAssignPicklistTrolley } from '../../../hooks/outbound/picklist/useAssignTrolley';
import ConfirmationDialog from '../../../components/shared/ConfirmationDialog';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ProductCardV2 } from '../../../components/shared/ProductCardV2';
import { useOutBoundProducts } from '../../../hooks/outbound/picklist/useOutBoundProducts';
import { IcKiranaStore } from '@jds/extended-icons';
import { IcAdd } from '@jds/core-icons';
import { PicklistContext } from '../../../provider/outbound/picklist';
import { usePicklistStateSave } from '../../../hooks/outbound/picklist/usePicklistStateSave';
import {
    useUpdateQuantityByOrderId,
    OrderItemsSchema,
} from '../../../hooks/outbound/picklist/useUpdateQuantityByOrderId';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const ScanJioCode = () => {
    const { data: outboundOrders, isLoading } = useOutBoundProducts();
    const [isAPICallLoading, setIsAPICallLoading] = useState(false);
    const { state, dispatch } = useContext(PicklistContext);
    const savePicklistactivity = usePicklistStateSave();
    const [scannedItemQuantity, setScannedItemQuantity] = useState(
        state.scannedItemQuantityTillNow
    );
    const [updateQuantityByOrderId] = [useUpdateQuantityByOrderId()];

    const [scannedGoodQuantity, setScannedGoodQuantity] = useState(state.scannedGoodPicklistItem);
    const [enableScannerOnMount, setEnableScannerOnMount] = useState(true);

    const [areItemsShort, setAreItemsShort] = useState(false);

    const [navigate] = [useNavigate()];
    const { pickingId, trolleyId, toteId } = useParams();
    const [assignPicklistTrolley] = [useAssignPicklistTrolley()];
    const pickedJioCodeIndex = state.picklistIndex;
    const currentJioCodeData: any =
        outboundOrders &&
        outboundOrders?.length > pickedJioCodeIndex &&
        outboundOrders[pickedJioCodeIndex];

    function onAssignPicklistTrolley(jioCode: string) {
        const data = {
            pickListId: Number(pickingId) || 0,
            trolleyId: trolleyId || '',
            toteId: toteId || '',
            orderId: currentJioCodeData?.order_id,
            jioCode: jioCode,
            shipmentId: currentJioCodeData?.shipment_id,
            requestType: 'JIO_CODE',
        };
        assignPicklistTrolley.mutate(data, {
            onError: () => {
                setIsAPICallLoading(false);
            },
        });
    }

    function handleScanNextJioCode() {
        navigate(`../${pickingId}/${trolleyId}/${toteId}/help`);
    }

    const handleOnScanJioCode = (jioCode: string) => {
        if (jioCode === currentJioCodeData?.jio_code) {
            const updatedQuantity = scannedItemQuantity + 1;
            const updatedGoodQuantity = scannedGoodQuantity + 1;
            if (
                updatedQuantity <= currentJioCodeData?.expectedQuantity &&
                outboundOrders &&
                outboundOrders.length > 0
            ) {
                setIsAPICallLoading(true);
                onAssignPicklistTrolley(jioCode);
                onUpdateJioCodeQuantity(updatedQuantity, updatedGoodQuantity);
                setEnableScannerOnMount(true);
            } else {
                Notifications.toast.error({
                    title: 'Error',
                    description: 'Jio Code is not matching.',
                });
            }
        } else {
            Notifications.toast.error({
                title: 'Error',
                description: 'Jio Code is not matching.',
            });
        }
    };

    function onUpdateJioCodeQuantity(updatedQuantity: number, updatedGoodQuantity: number) {
        const orderObj: OrderItemsSchema = {
            id: currentJioCodeData?.order_id,
            quantity: 1,
            jio_code: currentJioCodeData?.jio_code ?? '',
            shipment_id: currentJioCodeData?.shipment_id ?? '',
            lot_id: currentJioCodeData?.lot_id ?? 0,
            quantity_not_picked: 0,
            total_quantity: currentJioCodeData?.expectedQuantity ?? 0,
        };
        updateQuantityByOrderId.mutate(
            {
                picklist_id: Number(pickingId),
                status: 'IN_PROGRESS',
                orders: [orderObj],
                locationId: currentJioCodeData?.location_id,
            },
            {
                onSuccess: () => {
                    dispatch({ type: 'SET_SCANNED_TOTAL_ITEMS', payload: updatedQuantity });
                    dispatch({ type: 'SET_SCANNED_GOOD_ITEMS', payload: updatedGoodQuantity });
                    setScannedItemQuantity(updatedQuantity);
                    setScannedGoodQuantity(updatedGoodQuantity);
                    if (updatedGoodQuantity === 1) handleUpdateJioCodeScanState();
                    setIsAPICallLoading(false);
                },
                onError: () => {
                    setIsAPICallLoading(false);
                },
            }
        );
    }

    function handleUpdateJioCodeScanState() {
        savePicklistactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                activity_name: 'PICKING_JIO_CODE_SCAN',
                status: 'IN_PROGRESS',
            },
            {
                onError: () => {
                    setIsAPICallLoading(false);
                },
            }
        );
    }

    return (
        <>
            <div className={cn(layoutStyles)}>
                <div className={cn(pageHeaderStyle)}>
                    <PageHeaderV2
                        isExtended
                        title="Jio Code"
                        onBackIconClick={() => {
                            Notifications.toast.error('Unable to navigate back');
                        }}
                    />
                    <div className={cn('mx-3 pb-5')}>
                        <ScanOrEnterManuallyV2
                            autoSuggest={true}
                            enableScannerOnMount={enableScannerOnMount}
                            inputProps={{
                                name: 'jio-code',
                                label: 'Jio Code',
                                disabled: isAPICallLoading,
                            }}
                            searchableList={[{ result: currentJioCodeData?.jio_code }]}
                            onSuccess={(value) => {
                                handleOnScanJioCode(value);
                            }}
                        />
                        {isAPICallLoading && (
                            <Spinner className={cn('flex justify-center')} size="small" />
                        )}
                    </div>
                </div>

                <main className={cn('mx-5 mt-5 pb-3')}>
                    {isLoading && <Spinner className={cn('flex justify-center')} size="small" />}
                    {!isLoading && (
                        <ProgressBar
                            label="Total Quantity"
                            value={scannedItemQuantity / currentJioCodeData?.expectedQuantity}
                            valueLabel={`${scannedItemQuantity}/${currentJioCodeData?.expectedQuantity}`}
                        />
                    )}
                    <div className={cn('mt-5 mb-4')}>
                        <ProductCardV2
                            key={currentJioCodeData?.jio_code ?? '-'}
                            BBD={currentJioCodeData?.expiry}
                            EAN={currentJioCodeData?.ean ?? '-'}
                            MFD={currentJioCodeData?.mfg}
                            MRP={currentJioCodeData?.mrp ?? '-'}
                            colorSize={`${currentJioCodeData?.color ?? '-'}, ${
                                currentJioCodeData?.size ?? '-'
                            }`}
                            image={currentJioCodeData?.image_urls?.at(0)}
                            jioCode={currentJioCodeData?.jio_code ?? '-'}
                            title={currentJioCodeData?.product_title ?? '-'}
                        />
                    </div>
                    <Divider className={cn('my-6')} />
                    <div>
                        <Container
                            className={cn(
                                'grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-3'
                            )}
                        >
                            <Icon
                                className={cn('absolute left-2 bg-white')}
                                ic={<IcKiranaStore color="#000093" />}
                            />
                            <Text appearance="body-s">Tote Code</Text>
                            <Text
                                appearance="body-s-bold"
                                className={cn('text-center')}
                                color="primary-grey-80"
                            >
                                {toteId}
                            </Text>
                        </Container>
                    </div>
                    <div className={cn('mx-1 mt-5 mb-20')}>
                        <Button
                            fullWidth
                            disabled={
                                scannedItemQuantity === currentJioCodeData?.expectedQuantity ||
                                isAPICallLoading
                            }
                            icon={<IcAdd color="#000093" />}
                            kind="secondary"
                            size="large"
                            onClick={() => {
                                navigate(`../${pickingId}/${trolleyId}/add-tote`);
                            }}
                        >
                            Add New Tote
                        </Button>
                    </div>
                </main>
                <div
                    className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6 ', layoutStyles)}
                    style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
                >
                    <Button
                        fullWidth
                        disabled={isAPICallLoading}
                        kind="secondary"
                        size="large"
                        onClick={() => {
                            if (
                                scannedItemQuantity !== currentJioCodeData?.expectedQuantity &&
                                scannedItemQuantity <= currentJioCodeData?.expectedQuantity
                            )
                                setAreItemsShort(true);
                            else handleScanNextJioCode();
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </div>
            {areItemsShort && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcWarningColored"
                            size="xl"
                        />
                    }
                    isClosable={true}
                    isOpened={areItemsShort}
                    message={`Mark ${
                        currentJioCodeData?.expectedQuantity - scannedItemQuantity
                    } items short ?`}
                    primaryCTALabel="Confirm"
                    onCloseClick={() => setAreItemsShort(false)}
                    onPrimaryCTAClick={() => {
                        setAreItemsShort(false);
                        handleScanNextJioCode();
                    }}
                />
            )}
        </>
    );
};

export default ScanJioCode;
