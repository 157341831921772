import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { Notifications } from '@jds/core';
import { ResponseType } from '../../../types/fetch';

export interface consolidationStateSaveSchema {
    username: string;
    rfc_code: string;
    status: 'PENDING' | 'IN_PROGRESS' | 'COMPLETED';
    activity_name:
        | 'SCAN_DROP_ZONE_CONSOLIDATION'
        | 'SCAN_TOTE_CONSOLIDATION'
        | 'SCAN_JIO_CODE_CONSOLIDATION'
        | 'SCAN_CRATE_CONSOLIDATION'
        | 'TAG_NEW_CRATE_CONSOLIDATION'
        | 'SCAN_DROP_TOTE_CONSOLIDATION';
    rfcId?: number;
    crate_id?: string;
    ptw_location?: string;
    toteId?: string;
    shipmentId?: string;
    orderId?: string;
    shipmentType?: string;
    jioCode?: string;
    quantity?: number;
    lotId?: number;
    dropzoneId?: string;
    locationId?: string;
    productReceivingSource?: 'CONSOLIDATION_OUTBOUND_QC' | 'CONSOLIDATION_CANCELLED_ORDERS';
    productType?: 'GOOD' | 'DAMAGED';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong';
export function useConsolidationStateSave() {
    return useMutation<unknown, AxiosError<ResponseType<unknown>>, consolidationStateSaveSchema>({
        mutationFn: async (payload) => {
            const response = await axios.post(urls.OUTBOUND_STATE_SAVE(), payload);
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
