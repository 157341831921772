import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';
import { useContext } from 'react';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';

type ProductBulkDetails = { jioCode: string; lotId: number }[];
async function fetchProductDetails(details: ProductBulkDetails) {
    const promises = details.map(({ jioCode, lotId }) => {
        return axios.get(urls.PRODUCT_DETAILS(jioCode, lotId));
    });
    const result = await Promise.all(promises);
    return result.map(({ data }) => data.data);
}

export interface ProductInfo {
    image_urls?: string[] | null;
    brand: string;
    color: string;
    description: string;
    ean: string;
    expiry: number;
    jio_code: string;
    lot_id: number;
    mfg: number;
    mrp: number;
    product_title: string;
    seller_code: string;
    size: string;
    sku_code: string;
    length: number;
    breadth: number;
    height: number;
    weight: number | null;
}

export function useConsolidationProducts() {
    const { state } = useContext(ConsolidationContext);
    const productDetailsPayload =
        state.consolidationJioCodesList?.map(({ jioCode, lotId }) => ({
            jioCode: jioCode,
            lotId: lotId,
        })) ?? [];
    const rfcId = sessionStorage.getItem('rfc-id');
    const keys = productDetailsPayload.map(({ jioCode }) => jioCode);
    return useQuery<ProductInfo[]>({
        queryKey: ['products', rfcId, keys],
        queryFn: async () => {
            const productInfo = await fetchProductDetails(productDetailsPayload);
            return productInfo;
        },
    });
}

export function useConsolidationDamagedProducts() {
    const { state } = useContext(ConsolidationContext);
    const productDetailsPayload =
        state.consolidationDamagedJioCodes?.map(({ jioCode, lotId }) => ({
            jioCode: jioCode,
            lotId: lotId,
        })) ?? [];
    const rfcId = sessionStorage.getItem('rfc-id');
    const keys = productDetailsPayload.map(({ jioCode }) => jioCode);
    return useQuery<ProductInfo[]>({
        queryKey: ['products', rfcId, keys],
        queryFn: async () => {
            const productInfo = await fetchProductDetails(productDetailsPayload);
            return productInfo;
        },
    });
}
