import { Button, Input, Text } from '@jds/core';
import { ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '../../../components/shared/PageHeader';
import { cn } from '../../../utils/tailwind';
import { layoutStyles } from '../../../styles/common';

export function SelectInventory() {
    const navigate = useNavigate();
    const { dropZoneId, toteId, jioCode, lotId } = useParams();
    const [radioButtonValue, setRadioButtonValue] = useState('');

    const radioButtonClicked = (
        event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        setRadioButtonValue(event.target.value);
    };
    return (
        <div className={cn('mt-3 max-w-screen-md md:mx-auto')}>
            <PageHeader
                showBackIcon
                title="Stock take- Drop flow"
                onBackIconClick={() => navigate(-1)}
            />
            <main className={cn('mx-6 mt-3 mb-40')}>
                <Text className={cn('w-full')}>Good Inventory</Text>
                <div className={cn('mt-5 space-y-8')}>
                    <Input
                        checked={radioButtonValue === 'GOOD_PALLET'}
                        label="Good Inventory Pallet area"
                        name="palletArea"
                        type="radio"
                        value="GOOD_PALLET"
                        onChange={(e) => radioButtonClicked(e)}
                    />
                    <Input
                        checked={radioButtonValue === 'GOOD_SHELVING'}
                        label="Good Inventory Shelving area"
                        name="shelvingArea"
                        type="radio"
                        value="GOOD_SHELVING"
                        onChange={(e) => radioButtonClicked(e)}
                    />
                </div>
                <Text className={cn('mt-8 w-full')}>Damaged Inventory</Text>
                <div className={cn('mt-5 space-y-8')}>
                    <Input
                        checked={radioButtonValue === 'LIQUIDATION'}
                        label="Liquidation area"
                        name="liquidationArea"
                        type="radio"
                        value="LIQUIDATION"
                        onChange={(e) => radioButtonClicked(e)}
                    />
                    <Input
                        checked={radioButtonValue === 'SCRAP'}
                        label="Scrap area"
                        name="scrapArea"
                        type="radio"
                        value="SCRAP"
                        onChange={(e) => radioButtonClicked(e)}
                    />
                </div>
            </main>
            <div
                className={cn(
                    'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                    layoutStyles
                )}
            >
                <Button
                    fullWidth
                    disabled={!radioButtonValue}
                    size="large"
                    type="button"
                    onClick={() => {
                        navigate(
                            `../${dropZoneId}/${toteId}/${jioCode}/${lotId}/${radioButtonValue}/inventory-tote`
                        );
                    }}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
}
