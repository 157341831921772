import { useMutation } from '@tanstack/react-query';
import { UnsalableReasons } from '../../../pages/returns/receiving';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';
import { ResponseType } from '../../../types/fetch';
import { urls } from '../../../utils/urls';
import { downloadFile } from '../../../utils/fetchHelper';

export interface PrintProductInfo {
    quantity: number;
    qc_reason_code: UnsalableReasons | 'GOOD';
    qc_status: 'SALEABLE' | 'UNSALEABLE';
}

export interface PrintBody {
    jio_code: string;
    return_order_id: string;
    products: PrintProductInfo[];
}

export function usePrintLabels() {
    return useMutation<unknown, AxiosError<ResponseType<unknown>>, PrintBody>({
        mutationFn: async (body) => {
            const response = await axios({
                url: urls.RETURN_LABEL(),
                responseType: 'blob',
                method: 'POST',
                data: body,
            });
            // downloadFile(response.data, 'labels.pdf'); todo: Print instead of download
            return null;
        },
        onSuccess: () => {
            Notifications.toast.success({
                title: 'Success',
                description: 'Labels printed successfully',
            });
        },
        onError: (error) => {
            const defaultMessage = 'Failed to print labels. Please try again';
            const messages = error?.response?.data?.meta?.errors ?? [defaultMessage];
            messages.forEach((description) => {
                Notifications.toast.error({ title: 'Error', description });
            });
        },
    });
}
