import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Button, Container, Icon, Notifications, Text } from '@jds/core';
import { useContext, useState } from 'react';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { useQueryClient } from '@tanstack/react-query';
import {
    OrderListSchema,
    usePackingOrderList,
} from '../../../hooks/outbound/packing/usePackingOrderList';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { IcKiranaStore } from '@jds/extended-icons';
import { PackingContext } from '../../../provider/outbound/packing';
import { usePackingStateSave } from '../../../hooks/outbound/packing/usePackingStateSave';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'tote-crate-code', label: 'Tote Code / Crate Code', type: 'text' };

export function PackingMultiHomePage() {
    const navigate = useNavigate();
    const { stationCode } = useParams();
    const { dispatch } = useContext(PackingContext);
    const savePackingactivity = usePackingStateSave();
    const [containerType, setContainerType] = useState('');
    const [currentContainerCode, setCurrentContainerCode] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const [crateOrderList, setCrateOrderList] = useState([]);

    const queryClient = useQueryClient();

    const getTotalScannedQuantity = (data: any[]) => {
        let scannedQty = 0;
        data?.map((item) => {
            const qty = item?.tobePackedQuantity - item?.processedQuantity ?? 0;
            scannedQty += qty;
        });
        return scannedQty;
    };

    const packingOrderList = usePackingOrderList();
    const handleGetPackingOrderList = (data: OrderListSchema) => {
        packingOrderList.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    if (res?.meta?.code === 'OUTBOUND40002') setShowWarning(true);
                    else {
                        Notifications.toast.error({
                            title: 'Error',
                            description: res.meta.errors.join(', '),
                        });
                    }
                } else {
                    queryClient.setQueryData(['packing-order-info'], res.data.orderInfo);
                    if (data.containerType === 'crate') {
                        setContainerType('crate');
                        dispatch({
                            type: 'SET_PACKING_ORDER_LIST',
                            payload: res.data.orderInfo[0].packingOrderItemDetails,
                        });
                        setCrateOrderList(res.data.crateOrderEntityList);
                        const scannedQtyTillNow = getTotalScannedQuantity(
                            res.data.orderInfo[0].packingOrderItemDetails
                        );
                        dispatch({
                            type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
                            payload: scannedQtyTillNow,
                        });
                        const TotalQty = getTotalOrderedQuantity(
                            res.data.orderInfo[0]?.packingOrderItemDetails
                        );
                        dispatch({
                            type: 'SET_IS_ORDER_QUANTITY_COMPLETED',
                            payload: scannedQtyTillNow === TotalQty,
                        });
                    } else {
                        const convertedList = getOrderItemsListForSingleOrder(res.data.orderInfo);
                        dispatch({
                            type: 'SET_PACKING_ORDER_LIST',
                            payload: convertedList,
                        });
                        const scannedQtyTillNow = getTotalScannedQuantity(convertedList);
                        dispatch({
                            type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
                            payload: scannedQtyTillNow,
                        });
                        dispatch({
                            type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
                            payload: scannedQtyTillNow,
                        });
                        dispatch({
                            type: 'SET_IS_ORDER_QUANTITY_COMPLETED',
                            payload: scannedQtyTillNow === convertedList?.length,
                        });
                        handleContainerScanState(data.containerId, 'tote', 'Single');
                        // navigate(
                        //     `/outbound/packing/${stationCode}/${data.containerId}/Single/main`
                        // );
                    }
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    if (err?.response?.data?.meta?.code === 'OUTBOUND40002') setShowWarning(true);
                    else {
                        Notifications.toast.error({
                            title: 'Error',
                            description: err.response.data.meta.errors.join(', '),
                        });
                    }
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const getTotalOrderedQuantity = (data: any[]) => {
        let orderedQty = 0;
        data?.map((item) => {
            const qty = item?.tobePackedQuantity ?? 0;
            orderedQty += qty;
        });
        return orderedQty;
    };

    const validateContainer = useInventoryDetails();
    const handleValidateInventoryDetails = (containerId: string) => {
        validateContainer.mutate(
            { inventoryCode: containerId, type: '' },
            {
                onSuccess(res: any) {
                    if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                        Notifications.toast.error({
                            title: 'Error',
                            description:
                                res.meta.errors?.length > 0
                                    ? res.meta.errors.join(', ')
                                    : 'Invalid Tote / Trolley Code',
                        });
                    } else {
                        handleGetPackingOrderList({
                            containerId: containerId,
                            containerType: res.data.inventoryType.toLowerCase(),
                        });
                    }
                },
            }
        );
    };

    const getOrderItemsListForSingleOrder = (data: any[]) => {
        return data.map((item) => {
            return {
                orderId: item.orderId,
                shipmentId: item.shipmentId,
                type: item.type,
                status: item.status,
                orderedQuantity: item.orderedQuantity,
                scannedQuantity:
                    item?.packingOrderItemDetails[0].tobePackedQuantity -
                        item?.packingOrderItemDetails[0].processedQuantity ?? 0,
                ...item.packingOrderItemDetails[0],
            };
        });
    };

    function handleContainerScanState(
        containerId: string,
        containerType: string,
        orderType: string
    ) {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_CONTAINER_PACKING',
                status: 'COMPLETED',
                packingStation: stationCode,
                locationId: stationCode,
                containerId: containerId,
                containerType: containerType,
            },
            {
                onSuccess: () => {
                    navigate(`/outbound/packing/${stationCode}/${containerId}/${orderType}/main`);
                },
            }
        );
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Scan Tote / Crate"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <div className={cn('mx-3 mt-6 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        purpose="PACKING"
                        stage="OUTBOUND"
                        type="TOTE"
                        onSuccess={(value) => {
                            setCurrentContainerCode(value);
                            handleValidateInventoryDetails(value);
                        }}
                    />
                </div>
            </div>
            <main className={cn('mx-3 mt-6')}>
                {containerType === 'crate' && (
                    <div>
                        {crateOrderList?.length > 0 && (
                            <div>
                                <div className={cn('mx-3 mb-20 mt-6')}>
                                    <Text
                                        appearance="body-s-bold"
                                        className={cn('ml-2 flex')}
                                        color="primary-grey-100"
                                    >
                                        Order related crates
                                        <Text
                                            appearance="body-xs-bold"
                                            className={cn('ml-1 mt-[3px]')}
                                            color="primary-grey-100"
                                        >
                                            ({crateOrderList.length})
                                        </Text>
                                    </Text>
                                    <div className={cn('mt-0 pt-0')}>
                                        {crateOrderList.map((crateInfo: any) => (
                                            <Container
                                                key={crateInfo.id}
                                                className={cn(
                                                    'my-3 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-2'
                                                )}
                                            >
                                                <Icon
                                                    className={cn('absolute left-3 bg-white')}
                                                    ic={<IcKiranaStore color="#000093" />}
                                                />
                                                <Text appearance="body-s">Crate ID</Text>
                                                <Text
                                                    appearance="body-s-bold"
                                                    className={cn('text-center')}
                                                    color="primary-grey-80"
                                                >
                                                    {crateInfo.crateId}
                                                </Text>
                                            </Container>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className={cn(
                                'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                                layoutStyles
                            )}
                            style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
                        >
                            <Button
                                fullWidth
                                kind="primary"
                                size="large"
                                onClick={() => {
                                    handleContainerScanState(
                                        currentContainerCode,
                                        'crate',
                                        'Multi'
                                    );
                                    // navigate(
                                    //     `/outbound/packing/${stationCode}/${currentContainerCode}/Multi/main`
                                    // );
                                }}
                            >
                                Start Packing
                            </Button>
                        </div>
                    </div>
                )}
                {showWarning && (
                    <InfoModalSection
                        ModalHeader="Tote not processed
                        Send tote back to PTW station"
                        buttonText="Pick another tote"
                        infoText=""
                        infoType="ERROR"
                        showCloseIcon={true}
                        onSuccess={() => {
                            setShowWarning(false);
                        }}
                    />
                )}
            </main>
        </div>
    );
}
