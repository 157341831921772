import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Notifications } from '@jds/core';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { useContext, useEffect } from 'react';
import { useFetchUserConsolidationState } from '../../../hooks/outbound/consolidation/useFetchUserConsolidationState';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';
import { useConsolidationStateSave } from '../../../hooks/outbound/consolidation/useConsolidationStateSave';

const manualInputProps = { name: 'drop-zone', label: 'PTW Drop Zone', type: 'text' };
export function ScanDropZone() {
    const navigate = useNavigate();
    const { consolidationStateDetails } = useFetchUserConsolidationState();
    const saveConsolidationactivity = useConsolidationStateSave();
    const { dispatch } = useContext(ConsolidationContext);

    const validateInventory = useInventoryDetails();
    const onSubmit = (stationId: string) => {
        validateInventory.mutate(
            { inventoryCode: stationId, type: 'PTW_DROP_ZONE' },
            {
                onSuccess(res: any) {
                    if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                        Notifications.toast.error({
                            title: 'Error',
                            description: res.meta.errors.join(', '),
                        });
                    } else {
                        handleDropZoneScanState(stationId);
                        navigate(`${stationId}/scan-tote`);
                    }
                },
            }
        );
    };
    const getTotalConsolidationQuantity = (data: any[]) => {
        let quantity = 0;
        data.map((item: { quantity: number }) => {
            quantity += item.quantity;
        });
        return quantity;
    };

    function handleDropZoneScanState(stationId: string) {
        saveConsolidationactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_ZONE_CONSOLIDATION',
                status: 'COMPLETED',
                locationId: stationId,
                dropzoneId: stationId,
            },
            {
                onSuccess: () => {
                    navigate(`${stationId}/scan-tote`);
                },
            }
        );
    }

    useEffect(() => {
        if (consolidationStateDetails === undefined) return;
        if (consolidationStateDetails) {
            dispatch({
                type: 'SET_CONSOLIDATION_TOTAL_TOTE_QUANTITY',
                payload: getTotalConsolidationQuantity(consolidationStateDetails.jioCodesItemLists),
            });
            dispatch({
                type: 'SET_CONSOLIDATION_JIOCODES_LIST',
                payload: consolidationStateDetails.jioCodesItemLists,
            });
        }
        const orderDetails = consolidationStateDetails?.orderConsolidationResponseList?.[0];
        const defaultPathname = `${consolidationStateDetails?.dropzoneId}/${consolidationStateDetails?.toteId}/`;
        switch (consolidationStateDetails?.lastActivity) {
            case 'SCAN_DROP_ZONE_CONSOLIDATION':
                navigate(`${consolidationStateDetails?.dropzoneId}/scan-tote`);
                break;
            case 'SCAN_TOTE_CONSOLIDATION':
                navigate(defaultPathname + 'main?pendingActivity=true');
                break;
            case 'SCAN_JIO_CODE_CONSOLIDATION':
                navigate(defaultPathname + 'main?pendingActivity=true');
                break;
            case 'SCAN_CRATE_CONSOLIDATION':
                navigate(defaultPathname + 'main?pendingActivity=true');
                break;
            case 'TAG_NEW_CRATE_CONSOLIDATION':
                dispatch({
                    type: 'SET_CONSOLIDATION_PARKED_CRATECODES',
                    payload: consolidationStateDetails?.orderConsolidationResponseList,
                });
                navigate(
                    defaultPathname +
                        `${orderDetails?.jioCode}/${orderDetails?.shipmentId}/${orderDetails?.ptwlocation}/${orderDetails?.crateId}/location-details?pendingActivity=true`
                );
                break;
            case 'SCAN_DROP_TOTE_CONSOLIDATION':
                if (consolidationStateDetails?.status === 'IN_PROGRESS') {
                    switch (consolidationStateDetails?.toteDropDetail?.productReceivingSource) {
                        case 'CONSOLIDATION_OUTBOUND_QC':
                            navigate(
                                defaultPathname +
                                    `${consolidationStateDetails?.toteDropDetail?.jioCode}/${consolidationStateDetails?.toteDropDetail?.shipmentId}/${consolidationStateDetails?.toteDropDetail?.lotId}/scan-new-damaged-tote`
                            );
                            break;
                        case 'CONSOLIDATION_CANCELLED_ORDERS':
                            navigate(
                                defaultPathname +
                                    `${consolidationStateDetails?.toteDropDetail?.jioCode}/${consolidationStateDetails?.toteDropDetail?.shipmentId}/${consolidationStateDetails?.toteDropDetail?.lotId}/scan-new-unknown-tote`
                            );
                            break;
                    }
                } else {
                    navigate(defaultPathname + 'main?pendingActivity=true');
                }
                break;
            default:
                navigate('');
        }
    }, [dispatch, navigate, consolidationStateDetails]);

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Consolidation"
                    onBackIconClick={() => {
                        navigate('../../sections/outbound');
                    }}
                />
                <main className={cn('mx-3 mt-6 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        type="PTW_DROP_ZONE"
                        onSuccess={(value) => {
                            onSubmit(value);
                        }}
                    />
                </main>
            </div>
        </div>
    );
}
