import { cn } from '../../utils/tailwind';
import { Button, Container, Icon, Spinner, Text } from '@jds/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useValidateTote, ValidateToteOptions } from '../../hooks/receiving/useValidateTote';
import { useDropTote } from '../../hooks/receiving/useDropTote';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { PageHeaderV2 } from '../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../styles/common';
import { IcOrders } from '@jds/extended-icons';
import { ToteTransferOptions, useTransferTote } from '../../hooks/receiving/useTransferTote';

const manualInputProps = { name: 'tote-id', label: 'Enter Tote ID', type: 'text' };
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ScanDestinationTote() {
    const { sourceToteId, machineId, jioCode, lotId } = useParams();
    const navigate = useNavigate();
    const [toteId, setToteId] = useState<string | null>(null);
    const [validateTote, transferTote] = [useValidateTote(), useTransferTote()];

    function onScanSuccess(tote_code: string) {
        const validateToteOptions: ValidateToteOptions = {
            quantity: 1,
            tote_code,
            product_type: 'CUBISCAN',
            jio_code: jioCode ?? '',
            tote_purpose: 'CUBISCAN_RETURNED',
            location_id: machineId ?? '',
            lot_id: Number(lotId),
            tote_params: null,
        };
        validateTote.mutate(validateToteOptions, {
            onSuccess: () => setToteId(tote_code),
            onError: () => setToteId(null),
        });
    }

    const toteTransferOptions: ToteTransferOptions = {
        source_tote_code: sourceToteId ?? '',
        target_tote_code: toteId ?? '',
        source_jio_code: jioCode ?? '',
        target_jio_code: jioCode ?? '',
        source_lot_id: Number(lotId) ?? 0,
        target_lot_id: Number(lotId) ?? 0,
        location_id: machineId ?? '',
        source_product_type: 'CUBISCAN',
        source_tote_purpose: 'ABOUT_TO_CUBISCAN',
        target_product_type: 'CUBISCAN',
        target_tote_purpose: 'CUBISCAN_RETURNED',
        quantity: 1,
    };

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Cubiscan Tote"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <main className={cn('mx-6 mt-3 mb-10 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        optionalValues={{ station_id: machineId ?? '' }}
                        purpose="CUBISCAN_MACHINE_UNLOAD"
                        type="TOTE_CODE"
                        onSuccess={onScanSuccess}
                    />
                </main>
            </div>
            {toteId && (
                <Container
                    className={cn(
                        'm-4 grid grid-cols-2 space-x-4 rounded-lg border border-solid border-[var(--color-primary-60)] p-4'
                    )}
                >
                    <Icon
                        className={cn('absolute left-1 bg-white')}
                        ic={<IcOrders color="#000093" />}
                    />
                    <Text appearance="body-s"> Tote ID </Text>
                    <Text appearance="body-s-bold"> {toteId} </Text>
                </Container>
            )}
            <div
                className={cn('fixed inset-x-0 bottom-0 bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    disabled={!toteId}
                    isLoading={transferTote.isLoading}
                    size="large"
                    type="button"
                    onClick={() => {
                        transferTote.mutate(toteTransferOptions, {
                            onSuccess: () => navigate(`success`),
                        });
                    }}
                >
                    Drop Complete
                </Button>
            </div>
            {validateTote.isLoading && (
                <div
                    className={cn(
                        'flex items-center justify-center',
                        'absolute inset-0 h-full min-h-screen bg-gray-200/50'
                    )}
                >
                    <Spinner />
                </div>
            )}
        </div>
    );
}
