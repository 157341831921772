import { urls } from '../../../utils/urls';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';

export interface packingDetails {
    toteId: string;
    toteDropDetail: toteDropDetail;
    status: 'IN_PROGRESS' | 'COMPLETED';
    lastActivity:
        | 'SCAN_STATION_PACKING'
        | 'SCAN_CONTAINER_PACKING'
        | 'SCAN_JIO_CODE_PACKING'
        | 'VERIFY_JIOCODE_SUCCESS_PACKING'
        | 'VERIFY_JIOCODE_FAILURE_PACKING'
        | 'SUGGEST_PACKING_MATERIAL'
        | 'SAVE_PACKING_ID'
        | 'SAVE_PACK_CONFIRMATION'
        | 'SAVE_SHIPPING_LABEL_GENERATION'
        | 'SCAN_DROP_TOTE_PACKING';
    crateOrders: any;
    containerId: string;
    packingStation: string;
    packingMaterialResponse: null;
    packingId: string;
    productType: 'GOOD' | 'DAMAGED' | 'LOW_FRESHNESS' | 'WRONG' | 'EXPIRED';
    userPackingStateHistoryResponseList: any[];
}
export interface orderPackingProductsResponse {
    color: string;
    description: string;
    ean: string;
    exp: number;
    jioCode: string;
    locationId: string;
    lotId: number;
    mfg: number;
    mrp: string;
    orderId: string;
    quantity: number;
    shipmentId: string;
    size: string;
    url: string;
    scannedQuantity: 0;
    tobePackedQuantity: number;
}

export interface orderPackingResponse {
    orderId: string;
    shipmentId: string;
    jioCode: string;
    packingStatus: string;
    orderStatus: string;
    crateId: string;
    lotId: number;
    crateIdList: string[];
    ptwlocation: string;
}

export interface toteDropDetail {
    jioCode: string;
    shipmentId: string;
    rfcCode: string;
    lotId: number;
    productReceivingSource: string;
    productReceivingGradationDtoList: productReceivingGradationDtoListSchema[];
}

export interface productReceivingGradationDtoListSchema {
    toteDropProductType: 'GOOD' | 'DAMAGED';
    quantity: number;
}

export interface UserPackingStateResponse {
    data: packingDetails;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useFetchUserPackingState() {
    const { data, ...rest } = useQuery<UserPackingStateResponse>({
        queryKey: ['packingStateDetails'],
        queryFn: async () => {
            const response = await axios.get(urls.GET_USER_PACKING_STATE());
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
    return { packingStateDetails: data?.data, ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
