import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';
import { urls } from '../../utils/urls';

export type ProductType =
    | 'GOOD'
    | 'DAMAGED'
    | 'EXPIRED'
    | 'EXCESS'
    | 'CUBISCAN'
    | 'LOW_FRESHNESS'
    | 'WRONG'
    | 'BATCH_ISSUE'
    | 'LIQUIDATION'
    | 'SCRAP'
    | 'GOOD_PALLET'
    | 'GOOD_SHELVING';

export type TotePurpose =
    | 'INBOUND'
    | 'CUBISCAN_RETURNED'
    | 'ABOUT_TO_CUBISCAN'
    | 'RETURNS'
    | 'STOCK_TAKE'
    | 'PACKING_OUTBOUND_QC'
    | 'CONSOLIDATION_OUTBOUND_QC'
    | 'PACKING_CANCELLED_ORDERS'
    | 'CONSOLIDATION_CANCELLED_ORDERS'
    | 'STOCK_TAKE_PUT_AWAY';

export interface ValidateToteOptions {
    tote_purpose: TotePurpose;
    jio_code: string;
    lot_id: number;
    tote_code: string;
    quantity: number;
    product_type: ProductType;
    location_id: string;
    tote_params: Toteparams | null;
}

export interface Toteparams {
    primaryId?: string;
    secondaryId?: string;
    returnOrderId?: string;
    orderId?: string;
    shipmentId?: string;
}

const defaultErrorMsg = 'Failed to validate tote. Please try again';
export function useValidateTote() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, ValidateToteOptions>(
        async (options) => {
            const response = await axios.post(urls.VALIDATE_DROP_TOTE(), {
                tote_code: options.tote_code,
                quantity: options.quantity,
                jio_code: options.jio_code,
                lot_id: options.lot_id,
                product_type: options.product_type,
                tote_purpose: options.tote_purpose,
                location_id: options.location_id,
                tote_params: options.tote_params,
            });
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? [defaultErrorMsg];
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}

interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: {
        valid: boolean;
        tote_code: string;
        storage_zone: string;
        errors: string[];
    };
}

interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}
