import { urls } from '../../../utils/urls';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';

export interface consolidationDetails {
    toteId: string;
    orderConsolidationResponseList: orderConsolidationResponse[];
    jioCodesItemLists: orderConsolidationProductsResponse[];
    toteDropDetail: toteDropDetail;
    dropzoneId: string;
    status: 'IN_PROGRESS' | 'COMPLETED';
    lastActivity:
        | 'SCAN_DROP_ZONE_CONSOLIDATION'
        | 'SCAN_TOTE_CONSOLIDATION'
        | 'SCAN_JIO_CODE_CONSOLIDATION'
        | 'SCAN_CRATE_CONSOLIDATION'
        | 'TAG_NEW_CRATE_CONSOLIDATION'
        | 'SCAN_DROP_TOTE_CONSOLIDATION';
}
export interface orderConsolidationProductsResponse {
    color: string;
    description: string;
    ean: string;
    exp: number;
    jioCode: string;
    locationId: string;
    lotId: number;
    mfg: number;
    mrp: string;
    orderId: string;
    quantity: number;
    shipmentId: string;
    size: string;
    url: string;
    scannedQuantity: 0;
}

export interface orderConsolidationResponse {
    orderId: string;
    shipmentId: string;
    jioCode: string;
    consolidationStatus: string;
    orderStatus: string;
    crateId: string;
    lotId: number;
    crateIdList: string[];
    ptwlocation: string;
}

export interface toteDropDetail {
    jioCode: string;
    shipmentId: string;
    rfcCode: string;
    lotId: number;
    productReceivingSource: string;
    productReceivingGradationDtoList: productReceivingGradationDtoListSchema[];
}

export interface productReceivingGradationDtoListSchema {
    toteDropProductType: 'GOOD' | 'DAMAGED';
    quantity: number;
}

export interface UserConsolidationStateResponse {
    data: consolidationDetails;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useFetchUserConsolidationState() {
    const { data, ...rest } = useQuery<UserConsolidationStateResponse>({
        queryKey: ['consolidationStateDetails'],
        queryFn: async () => {
            const response = await axios.get(urls.GET_USER_CONSOLIDATION_STATE());
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
    return { consolidationStateDetails: data?.data, ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
