import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface AssignJioCodeSchema {
    jio_code: string;
    request_type: string;
    crate_id: string;
    ptw_location: string;
    order_id?: string;
    rfc_id?: string;
    tote_id?: string;
    shipment_id?: string;
    index: number | 0;
    lotId?: number;
}

export function useAssignJioCode() {
    return useMutation(async (data: AssignJioCodeSchema) => {
        const body = {
            jio_code: data.jio_code,
            request_type: data.request_type,
            crate_id: data.crate_id,
            ptw_location: data.ptw_location,
            order_id: data.order_id,
            shipment_id: data.shipment_id,
            rfc_id: Number(sessionStorage.getItem('rfcId')),
            tote_id: data.tote_id,
            rfc_code: sessionStorage.getItem('rfc-code'),
        };
        const response = await axios.post(urls.ASSIGN_JIOCODE(), body);
        return response.data;
    });
}
