import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { useQueryClient } from '@tanstack/react-query';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Notifications } from '@jds/core';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { usePackingStateSave } from '../../../hooks/outbound/packing/usePackingStateSave';
import { useFetchUserPackingState } from '../../../hooks/outbound/packing/useFetchUserPackingState';
import { useContext, useEffect } from 'react';
import { PackingContext } from '../../../provider/outbound/packing';

const manualInputProps = { name: 'packing-station', label: 'Packing Station', type: 'text' };
export function ScanPackingStation() {
    const navigate = useNavigate();
    const { packingStateDetails } = useFetchUserPackingState();
    const savePackingactivity = usePackingStateSave();
    const { dispatch } = useContext(PackingContext);
    const queryClient = useQueryClient();

    const validateInventory = useInventoryDetails();
    const handleValidateInventoryDetails = (stationId: string) => {
        validateInventory.mutate(
            { inventoryCode: stationId, type: 'PACKING_STATION' },
            {
                onSuccess(res: any) {
                    if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                        Notifications.toast.error({
                            title: 'Error',
                            description:
                                res.meta.errors?.length > 0
                                    ? res.meta.errors.join(', ')
                                    : 'Invalid Packing Station ID',
                        });
                    } else {
                        handlePackingStationScanState(stationId);
                        // navigate(`/outbound/packing/${stationId}/homepage`);
                    }
                },
            }
        );
    };

    function handlePackingStationScanState(stationId: string) {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_STATION_PACKING',
                status: 'COMPLETED',
                packingStation: stationId,
                locationId: stationId,
            },
            {
                onSuccess: () => {
                    navigate(`/outbound/packing/${stationId}/homepage`);
                },
            }
        );
    }

    const getOrderItemsListForSingleOrder = (data: any[]) => {
        return data.map((item) => {
            return {
                orderId: item.orderId,
                shipmentId: item.shipmentId,
                type: item.type,
                status: item.status,
                orderedQuantity: item.orderedQuantity,
                scannedQuantity:
                    item?.packingOrderItemDetails[0].tobePackedQuantity -
                        item?.packingOrderItemDetails[0].processedQuantity ?? 0,
                ...item.packingOrderItemDetails[0],
            };
        });
    };

    const getTotalScannedQuantity = (data: any[]) => {
        let scannedQty = 0;
        data?.map((item) => {
            const qty = item?.tobePackedQuantity - item?.processedQuantity ?? 0;
            scannedQty += qty;
        });
        return scannedQty;
    };

    const getTotalOrderedQuantity = (data: any[]) => {
        let orderedQty = 0;
        data?.map((item) => {
            const qty = item?.tobePackedQuantity ?? 0;
            orderedQty += qty;
        });
        return orderedQty;
    };

    useEffect(() => {
        if (packingStateDetails === undefined) return;
        const packingHistory = packingStateDetails?.userPackingStateHistoryResponseList;
        const containerType = packingHistory?.[0]?.container_type === 'tote' ? 'Single' : 'Multi';

        let scannedQtyTillNow = 0;
        let TotalQty = 0;
        if (packingStateDetails) {
            queryClient.setQueryData(
                ['packing-order-info'],
                packingStateDetails?.crateOrders?.orderInfo
            );
            if (containerType === 'Single') {
                const convertedlist = getOrderItemsListForSingleOrder(
                    packingStateDetails?.crateOrders?.orderInfo
                );
                dispatch({
                    type: 'SET_PACKING_ORDER_LIST',
                    payload: convertedlist,
                });
                scannedQtyTillNow = getTotalScannedQuantity(convertedlist);
                TotalQty = packingStateDetails?.crateOrders?.orderInfo?.length;
                dispatch({
                    type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
                    payload: scannedQtyTillNow,
                });
            } else {
                dispatch({
                    type: 'SET_PACKING_ORDER_LIST',
                    payload:
                        packingStateDetails?.crateOrders?.orderInfo[0]?.packingOrderItemDetails,
                });
                scannedQtyTillNow = getTotalScannedQuantity(
                    packingStateDetails?.crateOrders?.orderInfo[0]?.packingOrderItemDetails
                );
                TotalQty = getTotalOrderedQuantity(
                    packingStateDetails?.crateOrders?.orderInfo[0]?.packingOrderItemDetails
                );
                dispatch({
                    type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
                    payload: scannedQtyTillNow,
                });
            }
        }

        const defaultPathname = `${packingStateDetails?.packingStation}/${packingStateDetails?.containerId}/`;
        switch (packingStateDetails?.lastActivity) {
            case 'SCAN_STATION_PACKING':
                navigate(`${packingStateDetails?.packingStation}/homepage`);
                break;
            case 'SCAN_CONTAINER_PACKING':
                navigate(defaultPathname + `${containerType}/main?pendingActivity=true`);
                break;
            case 'SCAN_JIO_CODE_PACKING':
                navigate(
                    defaultPathname +
                        `${containerType}/${packingHistory[0].order_id}/${packingHistory[0].shipment_id}/${packingHistory[0].jiocode}/verify-details?pendingActivity=true`
                );
                break;
            case 'VERIFY_JIOCODE_SUCCESS_PACKING':
                if (scannedQtyTillNow === TotalQty) {
                    dispatch({
                        type: 'SET_IS_ORDER_QUANTITY_COMPLETED',
                        payload: true,
                    });
                    navigate(
                        defaultPathname +
                            `${containerType}/${packingHistory[0].order_id}/${packingHistory[0].shipment_id}/${packingHistory[0].jiocode}/packaging-suggestion?pendingActivity=true`
                    );
                    break;
                } else navigate(defaultPathname + `${containerType}/main?pendingActivity=true`);
                break;
            case 'VERIFY_JIOCODE_FAILURE_PACKING':
                navigate(
                    defaultPathname +
                        `${containerType}/${packingHistory[0].order_id}/${packingHistory[0].shipment_id}/${packingHistory[0].jiocode}/${packingHistory[0].lotId}/verification-failed?pendingActivity=true`
                );
                break;
            case 'SAVE_PACKING_ID':
                navigate(
                    defaultPathname +
                        `${containerType}/${packingHistory[0].order_id}/${packingHistory[0].shipment_id}/${packingHistory[0].jiocode}/${packingStateDetails?.packingId}/scan-packaging-id?pendingActivity=true`
                );
                break;
            case 'SAVE_PACK_CONFIRMATION':
                navigate(
                    defaultPathname +
                        `${containerType}/${packingHistory[0].order_id}/${packingHistory[0].shipment_id}/${packingHistory[0].jiocode}/success`
                );
                break;
            case 'SCAN_DROP_TOTE_PACKING':
                if (packingStateDetails?.status === 'IN_PROGRESS') {
                    const urlPath = `${containerType}/${packingHistory[0].order_id}/${packingStateDetails?.toteDropDetail?.shipmentId}/${packingStateDetails?.toteDropDetail?.jioCode}/${packingStateDetails?.toteDropDetail?.lotId}`;
                    switch (packingStateDetails?.toteDropDetail?.productReceivingSource) {
                        case 'PACKING_OUTBOUND_QC':
                            switch (packingStateDetails?.productType) {
                                case 'DAMAGED':
                                    navigate(defaultPathname + `${urlPath}/drop-damaged-items`);
                                    break;
                                case 'EXPIRED':
                                    navigate(defaultPathname + `${urlPath}/drop-expired-items`);
                                    break;
                                case 'LOW_FRESHNESS':
                                    navigate(
                                        defaultPathname + `${urlPath}/drop-lowfreshness-items`
                                    );
                                    break;
                                case 'WRONG':
                                    navigate(defaultPathname + `${urlPath}/drop-wrong-products`);
                                    break;
                            }
                            break;
                        case 'PACKING_CANCELLED_ORDERS':
                            navigate(defaultPathname + `${urlPath}/drop-excess-items`);
                            break;
                    }
                } else {
                    navigate(defaultPathname + `${containerType}/main?pendingActivity=true`);
                }
                break;
            default:
                navigate('');
        }
    }, [dispatch, navigate, packingStateDetails, queryClient]);

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Packing Station"
                    onBackIconClick={() => {
                        navigate('../../sections/outbound');
                    }}
                />
                <main className={cn('mx-3 mt-7 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        type="PACKING_STATION"
                        onSuccess={(value) => {
                            handleValidateInventoryDetails(value);
                        }}
                    />
                </main>
            </div>
        </div>
    );
}
