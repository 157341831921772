import { useState } from 'react';
import { Icon, Spinner, Text } from '@jds/core';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../utils/tailwind';
import { useQueryClient } from '@tanstack/react-query';

import { PageHeader } from '../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { ProductCard } from '../../components/shared/ProductCard';
import { useGetProductDetails } from '../../hooks/gtl/useGetProductDetails';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';
import { GtlTaskListResponse } from '../../hooks/gtl/useGetGtlTaskList';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'location-code', label: 'Location Code', type: 'text' };

const ScanGtlLocation = () => {
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const { locationId } = useParams();

    const gtlTaskList = useQueryClient().getQueryData<GtlTaskListResponse>(['gtlTaskList']);
    const gtlLocationsList: any = gtlTaskList?.data.filter(
        (item) => item.sourceLocationId === locationId
    );
    if (gtlLocationsList && gtlLocationsList.length > 0) {
        localStorage.setItem(
            'pickedGtlJioCodeIndex',
            localStorage.getItem('pickedGtlJioCodeIndex') ?? '0'
        );
    }
    const { productDetails, isLoading } = useGetProductDetails(gtlLocationsList[0]?.jioCode);

    return (
        <>
            <div className={cn(layoutStyles, 'mx-3 py-3')}>
                <PageHeader
                    showHelpIcon
                    showBackIcon={false}
                    title="GTL"
                    onHelpIconClick={() => navigate(`../gtl-task-status`)}
                />
                <main className={cn('mx-2 mt-3')}>
                    <Text appearance="body-m" className={cn('mt-8')} color="primary-grey-80">
                        Go to Location ID and scan
                    </Text>
                    <Text appearance="heading-m" className={cn('mt-3 mb-8')} color="primary-50">
                        {gtlLocationsList?.length >= 1 && gtlLocationsList[0]?.sourceLocationId}
                    </Text>
                    {isLoading && (
                        <div className={cn('flex items-center justify-center')}>
                            <Spinner size="small" />
                        </div>
                    )}
                    {productDetails && (
                        <div className={cn('flex items-center')}>
                            <div className={cn('w-10/12')}>
                                <ProductCard
                                    key={productDetails && productDetails?.product?.uid}
                                    BBD={productDetails?.lotInformationDTO?.expiry}
                                    EAN={productDetails?.product?.sku?.ean}
                                    MFD={productDetails?.lotInformationDTO?.mfg}
                                    MRP={productDetails?.lotInformationDTO?.mrp}
                                    colorSize={`${productDetails?.product?.color}, ${productDetails?.product?.size}`}
                                    title={productDetails?.product?.sku?.description}
                                />
                            </div>
                            <div className={cn('flex w-2/12 items-center justify-center')}>
                                <Text
                                    appearance="body-s"
                                    className={cn('mt-3 mb-6')}
                                    color="primary-grey-80"
                                >
                                    X{' '}
                                    {gtlLocationsList.length >= 1 && gtlLocationsList[0]?.quantity}
                                </Text>
                            </div>
                        </div>
                    )}

                    <div className={cn('fixed inset-x-2 bottom-8 mx-4')}>
                        <ScanOrEnterManuallyV2
                            enableScannerOnMount={false}
                            inputProps={manualInputProps}
                            onSuccess={(value) => {
                                if (value && value === gtlLocationsList[0]?.sourceLocationId) {
                                    navigate(`../${locationId}/scan-gtl-tote`);
                                } else {
                                    setShowError(true);
                                }
                            }}
                        />
                    </div>
                </main>
            </div>
            {showError && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcErrorColored"
                            size="xl"
                        />
                    }
                    isOpened={showError}
                    message="Wrong location"
                    primaryCTALabel="Okay"
                    onPrimaryCTAClick={() => {
                        setShowError(false);
                    }}
                />
            )}
        </>
    );
};

export default ScanGtlLocation;
