import { Notifications } from '@jds/core';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../utils/urls';
import { AppointmentSchema } from '../../pages/inbound/receiving';

export function useUnloadingVerification() {
    return useMutation<unknown, AxiosError<ErrorResponse>, AppointmentSchema>(
        async (data: AppointmentSchema) => {
            const response = await axios.get(
                urls.UNLOADING_VERIFICATION(data.appointmentNumber ?? 1)
            );
            return response.data;
        },
        {
            onError(error) {
                const defaultMsg = 'Something went wrong';
                const serverError = error.response?.data?.meta?.errors?.at(0);
                Notifications.toast.error({
                    title: 'Error',
                    description: serverError ?? defaultMsg,
                });
            },
        }
    );
}

interface UnloadingPackages {
    status: string;
    unloadedAt: string;
    username: string;
    appointment_id: number;
    box_id: number;
    package_id: number;
}

interface UnloadingSuccessData {
    data: UnloadingPackages[];
    meta: {
        status: 'success' | 'failure';
        code: string;
        errors?: string[];
    };
}

interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}
