import { urls } from '../../../utils/urls';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';
import { PicklistLocationItemData } from './useGetPicklistLocation';

export interface PickingDetails extends PicklistLocationItemData {
    session_info: {
        username: string;
        rfc_code: string;
        activity_name: string;
        status: string;
    };
    short: number;
    damaged: number;
    trolley_id: string;
    tote_id: string;
    pick_list_id: number;
    picking_quantity_details: {
        id: number;
        orderId: string;
        shipmentId: string;
        jioCode: string;
        pickedQuantity: number;
        notPickedQuantity: number;
        totalQuantity: number;
        pickingLocation: string;
        rfcCode: string;
    };
}

export interface rejectReasonDetails {
    rejectReasonCodeEnum: string;
    quantity: number;
}
export interface toteDropDetails {
    toteDropProductType: 'LIQUIDATION' | 'SCRAP' | 'GOOD';
    quantity: number;
}

export interface UserPickingStateResponse {
    data: PickingDetails;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useFetchUserPickingState() {
    const { data, ...rest } = useQuery<UserPickingStateResponse>({
        queryKey: ['pickingStateDetails'],
        queryFn: async () => {
            const response = await axios.get(urls.GET_USER_PICKING_STATE());
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
    return { pickingStateDetails: data?.data, ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
