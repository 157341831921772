import { cn } from '../../utils/tailwind';
import { Button, Container, Icon, Text } from '@jds/core';
import { IcConfirm } from '@jds/core-icons';

interface DropListSectionProps {
    title: string;
    showPadding?: boolean;
    valueToDisplay: number;
    successCallBack: (value: string) => void;
    borderColour?: string;
    backgroundColour?: string;
    showDrop?: boolean;
    showConfirmIcon?: boolean;
}

export function DropListSection({
    title,
    valueToDisplay,
    successCallBack,
    showPadding = true,
    borderColour,
    backgroundColour,
    showDrop = true,
    showConfirmIcon = false,
}: DropListSectionProps) {
    return (
        <Container
            background={backgroundColour ?? 'primary-background'}
            className={cn(
                [`flex h-[66px] items-center space-x-2.5 !rounded-xl ${backgroundColour}`],
                [
                    borderColour && {
                        [`outline outline-[var(${borderColour})]`]: true,
                    },
                ]
            )}
            pad={showPadding ? 's' : undefined}
            rounded="medium"
        >
            <div className={cn('flex w-full items-center justify-between')}>
                <Text appearance="body-m-bold" color="primary-grey-80">
                    {title}
                </Text>
                <div className={cn('flex items-center justify-end')}>
                    <Text appearance="body-s-bold" className={cn('mr-6')} color="primary-grey-80">
                        {valueToDisplay}
                    </Text>
                    {showDrop && (
                        <Button
                            className={cn('h-10 !w-20 !bg-white text-lg')}
                            color="primary-grey-80"
                            kind="secondary"
                            size="small"
                            onClick={() => successCallBack('clicked')}
                        >
                            Drop
                        </Button>
                    )}
                    {showConfirmIcon && (
                        <Icon className={cn('mr-6 ml-5')} ic={<IcConfirm color="#1E7B74" />} />
                    )}
                </div>
            </div>
        </Container>
    );
}
