import { Button, Container, Icon, Input, Notifications, ProgressBar, Text } from '@jds/core';
import { IcAddCircle, IcLocation, IcMinimise } from '@jds/core-icons';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { PageHeader } from '../../../components/shared/PageHeader';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ProductCard } from '../../../components/shared/ProductCard';
import { ProductCardV2 } from '../../../components/shared/ProductCardV2';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useProductDetails } from '../../../hooks/cubiscan/useProductDetails';
import {
    UpdatePutawayTaskSchema,
    useCompletePutawayTask,
    useUpdatePutawayTask,
} from '../../../hooks/putaway/UsePutawayTask';
import { ProductInfo } from '../../../hooks/returns/unloading/useReturnProducts';
import { pageHeaderStyle } from '../../../styles/common';
import { cn } from '../../../utils/tailwind';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'jio-code', label: 'Jio Code', type: 'text' };
export function PutawayJiocodeQuantity() {
    const navigate = useNavigate();
    const routePrefix = useLocation().pathname.split('/', 3).join('/');
    const { stationId, toteId, jioCode, locationId } = useParams();
    const totalQuantity = Number(localStorage.getItem('PutawayTotalQuantity')) || 10;
    const quantityCompletedTillNow = Number(localStorage.getItem('PutawayQuantityTillNow')) || 0;
    const remainingTotalQuantity = totalQuantity - quantityCompletedTillNow;
    const putawayLocationsTillNowJson = localStorage.getItem('PutawayLocationsTillNow') || '[]';
    const putawayLocationsTillNow = JSON.parse(putawayLocationsTillNowJson);
    const [radioButtonValue, setRadioButtonValue] = useState('');
    const [jioCodePutawaySuccess, setJioCodePutawaySuccess] = useState(false);
    const queryClient = useQueryClient();
    const lotId = localStorage.getItem('lotId');
    const { data: productObj } = useProductDetails(jioCode ?? '', lotId ?? '0');
    const updatePutawayTask = useUpdatePutawayTask();
    const completePutawayTask = useCompletePutawayTask();
    const putListId = Number(localStorage.getItem('PutListId') || 0);
    const storedScannedQuantity = Number(localStorage.getItem('scannedQuantity')) || 0;
    const [scannedQuantity, setScannedQuantity] = useState(storedScannedQuantity);
    const [currentQuantity, setCurrentQuantity] = useState(storedScannedQuantity);

    const radioButtonClicked = (
        event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        setRadioButtonValue(event.target.value);
    };

    const handleInputQuantityChange = (value: any) => {
        const quantity = Number(value);
        if (
            quantity >= 0 &&
            quantity - scannedQuantity <= remainingTotalQuantity &&
            quantity >= scannedQuantity
        ) {
            setCurrentQuantity(quantity);
        }
    };
    const handleJioCodePutawayComplete = (data: UpdatePutawayTaskSchema, reason?: string) => {
        completePutawayTask.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    localStorage.setItem('JioCodePutawayComplete', 'true');
                    const items = res.data.tote_products_list;
                    const remainingItems = items.filter((item: any) => item.status === 'ACTIVE');
                    const completedItems = items.filter((item: any) => item.status === 'INACTIVE');
                    queryClient.setQueryData(['tote-active-products'], remainingItems);
                    queryClient.setQueryData(['tote-completed-products'], completedItems);
                    if (reason === 'ADD_NEW_LOCATION') {
                        putawayLocationsTillNow.push({
                            locationId: locationId,
                            quantity: currentQuantity,
                        });
                        localStorage.setItem(
                            'PutawayLocationsTillNow',
                            JSON.stringify(putawayLocationsTillNow)
                        );
                        localStorage.setItem(
                            'PutawayQuantityTillNow',
                            `${
                                res.data.total_quantity_kept_in_tote -
                                res.data.quantity_remaining_in_tote
                            }`
                        );
                        navigate(
                            `${routePrefix}/${stationId}/${toteId}/${jioCode}/${locationId}/new-location`
                        );
                    } else {
                        setJioCodePutawaySuccess(true);
                    }
                }
            },
            onError(err: any) {
                Notifications.toast.error({
                    title: 'Error',
                    description: err?.response?.data?.meta?.errors
                        ? err.response.data.meta.errors.join(', ')
                        : err.message,
                });
            },
        });
    };

    const handleJioCodePutawayUpdate = (data: UpdatePutawayTaskSchema, reason?: string) => {
        updatePutawayTask.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    localStorage.setItem('JioCodePutawayComplete', 'true');
                    const items = res.data.tote_products_list;
                    const remainingItems = items.filter((item: any) => item.status === 'ACTIVE');
                    const completedItems = items.filter((item: any) => item.status === 'INACTIVE');
                    queryClient.setQueryData(['tote-active-products'], remainingItems);
                    queryClient.setQueryData(['tote-completed-products'], completedItems);
                    putawayLocationsTillNow.push({
                        locationId: locationId,
                        quantity: currentQuantity,
                    });
                    localStorage.setItem(
                        'PutawayLocationsTillNow',
                        JSON.stringify(putawayLocationsTillNow)
                    );
                    localStorage.setItem(
                        'PutawayQuantityTillNow',
                        `${
                            res.data.total_quantity_kept_in_tote -
                            res.data.quantity_remaining_in_tote
                        }`
                    );
                    setScannedQuantity(scannedQuantity + 1);
                    setCurrentQuantity(currentQuantity + 1);
                    if (res.data.quantity_remaining_in_tote === 0) {
                        setJioCodePutawaySuccess(true);
                    }
                }
            },
            onError(err: any) {
                Notifications.toast.error({
                    title: 'Error',
                    description: err?.response?.data?.meta?.errors
                        ? err.response.data.meta.errors.join(', ')
                        : err.message,
                });
            },
        });
    };

    const handleAddNewLocation = () => {
        handleJioCodePutawayComplete(
            {
                toteId: toteId || '',
                jioCode: jioCode || '',
                putaway_quantity: currentQuantity - scannedQuantity,
                bin_id: locationId || '',
                put_away_task_id: Number(localStorage.getItem('PutawayTaskId') || 0),
                skip_reason: radioButtonValue,
                put_list_id: putListId,
            },
            'ADD_NEW_LOCATION'
        );
    };
    const scrollableStyle = currentQuantity === remainingTotalQuantity ? `h-[560px]` : `h-[850px]`;
    return (
        <div className={cn(scrollableStyle)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Jio Code"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                <div className={cn('mx-3 max-w-screen-md overflow-auto py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        inputProps={manualInputProps}
                        searchableList={[{ result: jioCode || '' }]}
                        onSuccess={(value) => {
                            if (value === jioCode) {
                                if (currentQuantity < totalQuantity) {
                                    handleJioCodePutawayUpdate({
                                        toteId: toteId || '',
                                        jioCode: jioCode || '',
                                        putaway_quantity: 1,
                                        bin_id: locationId || '',
                                        put_away_task_id: Number(
                                            localStorage.getItem('PutawayTaskId') || 0
                                        ),
                                        skip_reason: '',
                                        put_list_id: putListId,
                                    });
                                }
                            } else {
                                Notifications.toast.error('Scan valid Jio Code');
                            }
                        }}
                    />
                </div>
            </div>
            <div className={cn('mx-3 max-w-screen-md overflow-auto py-3 md:mx-auto')}>
                <main className={cn('mx-3 mt-4 mb-52 space-y-4')}>
                    <ProgressBar
                        label="Total Quantity"
                        value={
                            (currentQuantity + quantityCompletedTillNow - scannedQuantity) /
                            totalQuantity
                        }
                        valueLabel={`${
                            currentQuantity + quantityCompletedTillNow - scannedQuantity
                        }/${totalQuantity}`}
                    />
                    <Container background="primary-20" pad="m" rounded="large">
                        <div className={cn('flex items-center justify-between')}>
                            <Text appearance="body-m-bold" color="primary-60">
                                Putaway Quantity
                            </Text>
                            <div className={cn('flex items-center justify-end')}>
                                <>
                                    <Icon
                                        aria-label="decrement"
                                        ic={<IcMinimise />}
                                        style={{ color: '#7F7FFB' }}
                                        onClick={() => {
                                            handleInputQuantityChange(currentQuantity - 1);
                                        }}
                                    />
                                    <Input
                                        className={cn('mx-3 !mt-0 flex w-7 !pb-0')}
                                        color="primary-60"
                                        id="number"
                                        inputMode="numeric"
                                        label=""
                                        name=""
                                        type="number"
                                        value={currentQuantity}
                                        onChange={(event) => {
                                            handleInputQuantityChange(event.target.value);
                                        }}
                                    />
                                    <Icon
                                        aria-label="increment"
                                        ic={<IcAddCircle />}
                                        style={{ color: '#7F7FFB' }}
                                        onClick={() =>
                                            handleInputQuantityChange(currentQuantity + 1)
                                        }
                                    />
                                </>
                            </div>
                        </div>
                    </Container>
                    <div className={cn('mt-3')}>
                        {productObj && (
                            <ProductCardV2
                                key={productObj.jio_code}
                                BBD={productObj.expiry}
                                EAN={productObj.ean}
                                MFD={productObj.mfg}
                                MRP={productObj.mrp}
                                colorSize={`${productObj.color ?? ''}, ${productObj.size ?? ''}`}
                                image={
                                    productObj.image_urls?.length ? productObj.image_urls[0] : ''
                                }
                                jioCode={productObj.jio_code}
                                title={productObj.product_title}
                            />
                        )}
                    </div>
                    <Container
                        className={cn(
                            'm-1 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-4'
                        )}
                    >
                        <Icon
                            className={cn('absolute left-4 bg-white')}
                            ic={<IcLocation color="#000093" />}
                        />
                        <Text appearance="body-s"> Location ID </Text>
                        <Text appearance="body-s-bold"> {locationId} </Text>
                    </Container>
                </main>
                {currentQuantity - scannedQuantity !== remainingTotalQuantity && (
                    <div
                        className={cn(
                            'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                            layoutStyles
                        )}
                    >
                        <Container
                            background="primary-grey-20"
                            className={cn('flex flex-col items-center')}
                            pad="base"
                            rounded="large"
                        >
                            <Text appearance="body-m" className={cn('text-center')}>
                                Select a reason to add <br /> new location
                            </Text>
                            <div className={cn('mb-5 mt-2 flex items-center space-x-8')}>
                                <Input
                                    checked={radioButtonValue === 'BIN_IS_DAMAGED'}
                                    disabled={!!currentQuantity}
                                    label="Bin Damaged"
                                    name="BinDamaged"
                                    type="radio"
                                    value="BIN_IS_DAMAGED"
                                    onChange={(e) => radioButtonClicked(e)}
                                />
                                <Input
                                    checked={radioButtonValue === 'BIN_IS_FULL'}
                                    label="Bin Full"
                                    name="BinFull"
                                    type="radio"
                                    value="BIN_IS_FULL"
                                    onChange={(e) => radioButtonClicked(e)}
                                />
                            </div>
                            <Button
                                fullWidth
                                disabled={!radioButtonValue}
                                kind="secondary"
                                size="medium"
                                onClick={handleAddNewLocation}
                            >
                                Add New Location
                            </Button>
                        </Container>
                    </div>
                )}
                {currentQuantity - scannedQuantity === remainingTotalQuantity && (
                    <div
                        className={cn(
                            'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                            layoutStyles
                        )}
                    >
                        <Button
                            fullWidth
                            kind="primary"
                            size="large"
                            onClick={() =>
                                handleJioCodePutawayComplete({
                                    toteId: toteId || '',
                                    jioCode: jioCode || '',
                                    putaway_quantity: currentQuantity - scannedQuantity,
                                    bin_id: locationId || '',
                                    put_away_task_id: Number(
                                        localStorage.getItem('PutawayTaskId') || 0
                                    ),
                                    skip_reason: '',
                                    put_list_id: putListId,
                                })
                            }
                        >
                            Putaway Complete
                        </Button>
                    </div>
                )}
                {jioCodePutawaySuccess && (
                    <div className={cn('mt-14')}>
                        <InfoModalSection
                            ModalHeader={`Put away complete Jiocode ${jioCode}`}
                            buttonText="Continue"
                            infoText=""
                            infoType="SUCCESS"
                            showCloseIcon={false}
                            onSuccess={() =>
                                navigate(`${routePrefix}/${stationId}/${toteId}/scan-jiocode`)
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
