import { useParams } from 'react-router-dom';
import { urls } from '../../utils/urls';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';

export interface ScannedPackagesResponse {
    data: string[];
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong.';
export function useFetchScannedPackages() {
    const { appointmentNumber, secondaryCaseId, primaryCaseId } = useParams();
    const packageId = Number(secondaryCaseId) === -1 ? primaryCaseId || '' : secondaryCaseId || '';
    const { data, ...rest } = useQuery<ScannedPackagesResponse>({
        queryKey: ['scannedPackages'],
        queryFn: async () => {
            const response = await axios.get(
                urls.GET_SCANNED_PACKAGES(Number(appointmentNumber), packageId)
            );
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
    return { scannedPackages: data?.data ?? [], ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
