import { cn } from '../../utils/tailwind';
import AccordionLayout from './AccordianLayout';
import { TotesInfo } from './TotesInfo';

const Accordion = () => {
    return (
        <div className={cn('flex flex-col justify-center')}>
            <AccordionLayout index={1} title="See all totes list">
                <TotesInfo />
            </AccordionLayout>
        </div>
    );
};

export default Accordion;
