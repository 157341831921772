import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';
import { urls } from '../../utils/urls';
import { ResponseType } from '../../types/fetch';

export function useCubiscanFlow() {
    const { sourceToteId } = useParams();
    return useMutation<ResponseType<SuccessResponse>, AxiosError<ErrorResponse>, string>(
        async (jioCode: string) => {
            if (!sourceToteId) throw new Error('Source tote ID is missing');
            const response = await axios.get(urls.CUBISCAN_FLOW(sourceToteId, jioCode));
            return response.data;
        },
        {
            onError(error) {
                const defaultMsg = 'Something went wrong';
                const serverError = error.response?.data?.meta?.errors?.at(0);
                Notifications.toast.error({
                    title: 'Error',
                    description: serverError ?? defaultMsg,
                });
            },
        }
    );
}

interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

interface SuccessResponse {
    jio_code: string;
    lot_id: number;
    total_quantity: number;
    current_quantity: number;
    status: 'ACTIVE' | 'INACTIVE';
}
