import { Notifications } from '@jds/core';
import { useNavigate, useParams } from 'react-router-dom';
import { ScanTotePage } from '../../../components/outbound/packing/ScanTote';
import {
    useUpdateDamagedProducts,
    UpdateDamagedProductsSchema,
} from '../../../hooks/outbound/packing/UseUpdateDamagedProducts';

export function ScanCancelledItemsPage() {
    const navigate = useNavigate();
    const { stationCode, orderId, shipmentId, jioCode, ContainerCode, packingType, lotId } =
        useParams();

    const updateDamagedProducts = useUpdateDamagedProducts();
    const handleUpdateDamagedProducts = (data: UpdateDamagedProductsSchema[]) => {
        updateDamagedProducts.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    navigate(
                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/main`
                    );
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    return (
        <ScanTotePage
            locationId={stationCode}
            lotId={Number(lotId)}
            pageHeader="Cancelled Items Tote"
            showBackIon={true}
            toteType="cancelled-items"
            onSuccess={() => {
                handleUpdateDamagedProducts([
                    {
                        cancelled_quantity: 1,
                        cancellation_reason: 'damaged',
                        order_id: orderId || '',
                        jio_code: jioCode || '',
                        shipment_id: shipmentId || '',
                        cancelledDuringPacking: true,
                    },
                ]);
            }}
        />
    );
}
