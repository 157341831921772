import { Route } from 'react-router-dom';

import PickList from '../../../../pages/outbound/picklist/PickList';
import PickListHelp from '../../../../pages/outbound/picklist/PickListHelp';
import ScanTrolleyCode from '../../../../pages/outbound/picklist/ScanTrolleyCode';
import ScanToteCode from '../../../../pages/outbound/picklist/ScanToteCode';
import ScanLocation from '../../../../pages/outbound/picklist/ScanLocation';
import ScanJioCode from '../../../../pages/outbound/picklist/ScanJioCode';
import MarkShort from '../../../../pages/outbound/picklist/MarkShort';
import ScanDropZone from '../../../../pages/outbound/picklist/ScanDropZone';
import PickListSuccess from '../../../../pages/outbound/picklist/PickListSuccess';
import MarkDamage from '../../../../pages/outbound/picklist/MarkDamage';
import PickListStatus from '../../../../pages/outbound/picklist/PickListStatus';
import AddNewTote from '../../../../pages/outbound/picklist/AddNewTote';

export const PicklistRoutes = [
    <Route key="1" element={<PickList />} path="" />,
    <Route key="2" element={<ScanTrolleyCode />} path=":pickingId/scan-trolley" />,
    <Route key="3" element={<ScanToteCode />} path=":pickingId/:trolleyId/scan-tote" />,
    <Route key="4" element={<ScanLocation />} path=":pickingId/:trolleyId/:toteId/scan-location" />,
    <Route key="5" element={<ScanJioCode />} path=":pickingId/:trolleyId/:toteId/scan-jio-code" />,
    <Route key="6" element={<PickListHelp />} path=":pickingId/:trolleyId/:toteId/help" />,
    <Route key="7" element={<MarkShort />} path=":pickingId/mark-short" />,
    <Route key="8" element={<MarkDamage />} path=":pickingId/mark-damage" />,
    <Route key="9" element={<ScanDropZone />} path=":pickingId/scan-drop-zone" />,
    <Route key="10" element={<PickListSuccess />} path=":pickingId/:dropZoneId/success" />,
    <Route key="11" element={<PickListStatus />} path=":pickingId/status" />,
    <Route key="12" element={<AddNewTote />} path=":pickingId/:trolleyId/add-tote" />,
];
