import { cn } from '../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Icon, Modal, Notifications } from '@jds/core';
import { IcSuccess } from '@jds/core-icons';
import { useQueryParams } from '../../hooks/shared/useQueryParams';
import { PageHeaderV2 } from '../../components/shared/PageHeaderV2';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { pageHeaderStyle } from '../../styles/common';

const manualInputProps = { name: 'jio-code', label: 'Jio Code', type: 'text' };
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ScanJioCodePage() {
    const { cubiscanLabel } = useParams();
    const showSuccessDialog = useQueryParams().get('isCorrectDimension') === 'true';
    const navigate = useNavigate();
    function onScanSuccess(jioCode: string) {
        // Scanned Jio Code should match the Cubiscan Label
        if (jioCode !== cubiscanLabel)
            Notifications.toast.error({
                title: 'Invalid Jio Code',
                description: 'Please scan the correct Jio Code',
            });
        else navigate(jioCode);
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Cubiscan drop"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                <main className={cn('mx-6 mt-3 mb-20 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        searchableList={[{ result: cubiscanLabel || '' }]}
                        onSuccess={onScanSuccess}
                    />
                </main>
            </div>
            {showSuccessDialog && (
                <Modal
                    closed={false}
                    header={{
                        top: (
                            <div className={cn('text-center')}>
                                <Icon color="primary" ic={<IcSuccess />} size="xxl" />
                                <Heading appearance="heading-xs">Cubiscan Details Matched</Heading>
                            </div>
                        ),
                    }}
                />
            )}
        </div>
    );
}
