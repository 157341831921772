import { useState } from 'react';
import ConfirmationDialog from '../../../components/shared/ConfirmationDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Icon, Notifications } from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useTrolleyAndToteValidation } from '../../../hooks/outbound/picklist/useTrolleyAndToteValidation';
import { useAssignPicklistTrolley } from '../../../hooks/outbound/picklist/useAssignTrolley';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'tote-code', label: 'Tote Code', type: 'text' };

const AddNewTote = () => {
    const [apiErrors, setApiErrors] = useState({
        showError: false,
        errorMessage: '',
    });
    const navigate = useNavigate();
    const { pickingId, trolleyId } = useParams();

    const [validateTrolleyAndTote, assignPicklistTrolley] = [
        useTrolleyAndToteValidation(),
        useAssignPicklistTrolley(),
    ];

    function onAssignPicklistTrolley(toteCode: string) {
        const data = {
            pickListId: Number(pickingId) || 0,
            trolleyId: trolleyId || '',
            toteId: toteCode,
            orderId: '',
            jioCode: '',
            requestType: 'TOTE',
        };

        assignPicklistTrolley.mutate(data, {
            onSuccess: () => navigate(`../${pickingId}/${trolleyId}/${toteCode}/scan-jio-code`),
        });
    }

    function onScanSuccess(toteCode: string) {
        const data = {
            trolleyCode: trolleyId || '',
            toteCode,
            trolleyToteStatus: 'ACTIVE',
            lastUpdatedAt: `${Date.now()}`,
            lastScannedAt: `${Date.now()}`,
        };

        validateTrolleyAndTote.mutate(data, {
            onSuccess() {
                onAssignPicklistTrolley(toteCode);
            },
            onError(err: any) {
                if (err?.response?.data?.message) {
                    setApiErrors({
                        errorMessage: err.response.data.message,
                        showError: true,
                    });
                } else {
                    setApiErrors({
                        errorMessage: 'Tote not available for picking',
                        showError: true,
                    });
                }
            },
        });
    }

    return (
        <>
            <div className={cn(layoutStyles)}>
                <div className={cn(pageHeaderStyle)}>
                    <PageHeaderV2
                        isExtended
                        title="Add New tote"
                        onBackIconClick={() => {
                            navigate(-1);
                        }}
                    />
                    <main className={cn('mx-3 mt-3 pb-3')}>
                        <ScanOrEnterManuallyV2
                            autoSuggest={true}
                            inputProps={manualInputProps}
                            type="TOTE_CODE"
                            onSuccess={(toteCode) => {
                                if (toteCode) {
                                    onScanSuccess(toteCode);
                                } else {
                                    Notifications.toast.error({
                                        title: 'Error',
                                        description: 'Please enter tote code.',
                                    });
                                }
                            }}
                        />
                    </main>
                </div>
            </div>
            {apiErrors.showError && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcErrorColored"
                            size="xl"
                        />
                    }
                    isOpened={apiErrors.showError}
                    message={apiErrors.errorMessage}
                    primaryCTALabel="Pick Another Tote"
                    onPrimaryCTAClick={() => {
                        setApiErrors({
                            errorMessage: '',
                            showError: false,
                        });
                    }}
                />
            )}
        </>
    );
};

export default AddNewTote;
