import { cn } from '../../../utils/tailwind';
import { useNavigate } from 'react-router-dom';
import { Button } from '@jds/core';
import { useContext, useState } from 'react';
import { ReceivingContext } from '../../../provider/inbound/receiving';
import { QuantityStatus } from '../../../provider/inbound/receiving/ReceivingReducer';
import { usePrintLabels } from '../../../hooks/receiving/usePrintLabels';
import { PrintLabelModals } from '../../../components/receiving/PrintLabelModals';
import {
    usePackagesByPrimaryCase,
    usePackagesBySecondaryCase,
} from '../../../hooks/receiving/usePackages';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';
import { IncrementDecrementSectionV2 } from '../../../components/shared/IncrementDecrementSectionV2';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ASNValidationSchema } from './VerifyQuantitiesPage';
import { useVerifyQuantities } from '../../../hooks/receiving/useVerifyQuantities';

const layoutStyles = 'max-w-screen-md md:mx-auto';

export const reasonLabels: Record<keyof QuantityStatus, string> = {
    good: 'Good',
    batchIssue: 'Batch Issue',
    damaged: 'Damage',
    short: 'Short',
    excess: 'Excess',
    lowFreshness: 'Low Freshness',
    expired: 'Expired',
    wrong: 'Title Mismatch',
    failed: 'Failed',
    cubiscan: 'Cubiscan',
};

export function useCurrentValuesStatus() {
    const { quantityStatus, completedJioCodes, completedPrimaryCases } =
        useContext(ReceivingContext).state;
    const { packages: packagesByPrimaryCase } = usePackagesByPrimaryCase();
    const { packages: packagesBySecondaryCase } = usePackagesBySecondaryCase();
    const reasonKeys = Object.keys(quantityStatus) as Array<keyof QuantityStatus>;
    function hasOnlyLowFreshness(quantityStatusParam?: QuantityStatus) {
        const finalQuantityStatus = quantityStatusParam ?? quantityStatus;
        const hasLowFreshness = finalQuantityStatus.lowFreshness > 0;
        const hasNoOtherReasons = reasonKeys.every(
            (key) => key === 'lowFreshness' || key === 'failed' || finalQuantityStatus[key] === 0
        );
        return hasLowFreshness && hasNoOtherReasons;
    }
    function hasOnlyShortQuantity(quantityStatusParam?: QuantityStatus) {
        const finalQuantityStatus = quantityStatusParam ?? quantityStatus;
        const short = finalQuantityStatus.short > 0;
        const hasNoOtherReasons = reasonKeys.every(
            (key) => key === 'short' || finalQuantityStatus[key] === 0
        );
        return short && hasNoOtherReasons;
    }

    function hasAssignableFaultyQuantity(quantityStatusParam?: QuantityStatus) {
        const finalQuantityStatus = quantityStatusParam ?? quantityStatus;
        return reasonKeys.some(
            (key) => key !== 'good' && key !== 'short' && finalQuantityStatus[key] > 0
        );
    }

    function hasNoQuantityToDropOff(quantityStatusParam?: QuantityStatus) {
        const finalQuantityStatus = quantityStatusParam ?? quantityStatus;
        return reasonKeys.every((key) => key === 'short' || finalQuantityStatus[key] === 0);
    }

    function uncompletedJioCodes(completedJioCodesParam?: string[]) {
        const finalCompletedJioCodes = completedJioCodesParam ?? completedJioCodes;
        return packagesByPrimaryCase.filter(
            ({ jioCode }) => !finalCompletedJioCodes.includes(jioCode)
        );
    }

    function uncompletedPrimaryCases(completedPrimaryCasesParam?: string[]) {
        const finalCompletedPrimaryCases = completedPrimaryCasesParam ?? completedPrimaryCases;
        return packagesBySecondaryCase.filter(
            ({ packageLabel }) => !finalCompletedPrimaryCases.includes(packageLabel)
        );
    }

    return {
        hasOnlyLowFreshness,
        hasOnlyShortQuantity,
        hasAssignableFaultyQuantity,
        hasNoQuantityToDropOff,
        uncompletedJioCodes,
        uncompletedPrimaryCases,
    };
}

export function SummaryPage() {
    const [navigate, queryParams] = [useNavigate(), useQueryParams()];
    const printLabels = usePrintLabels();
    const {
        state: { quantityStatus },
    } = useContext(ReceivingContext);

    const InboundflowStatus = localStorage.getItem('receiving_state');
    const [verifyQuantities] = [useVerifyQuantities()];
    const [showSuccessModal, setSuccessModal] = useState(
        InboundflowStatus === 'QC_COMPLETED' ?? false
    );
    const reasonKeys = Object.keys(quantityStatus) as Array<keyof QuantityStatus>;
    const shouldDisableSubmit = reasonKeys.every((key) => quantityStatus[key] === 0);
    const { hasOnlyShortQuantity } = useCurrentValuesStatus();

    function onFormSubmit() {
        const asnPayload: ASNValidationSchema = {
            goodQuantity: quantityStatus.good ?? 0,
            batchIssueQuantity: quantityStatus.batchIssue ?? 0,
            damagedQuantity: quantityStatus.damaged ?? 0,
            excessQuantity: quantityStatus.excess ?? 0,
            expiredQuantity: quantityStatus.expired ?? 0,
            lowFreshnessQuantity: quantityStatus.lowFreshness ?? 0,
            shortQuantity: quantityStatus.short ?? 0,
            wrongQuantity: quantityStatus.wrong ?? 0,
        };

        verifyQuantities.mutate(asnPayload, {
            onSuccess() {
                if (hasOnlyShortQuantity()) {
                    navigate('../../');
                } else {
                    printLabels.mutate(undefined, {
                        onSuccess: () => setSuccessModal(true),
                        onError: () => setSuccessModal(true),
                    });
                }
            },
        });
    }
    return (
        <div className={cn(layoutStyles, 'pb-3')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Summary"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
            </div>
            <main className={cn('mx-6 mt-3 mb-20')}>
                <div className={cn('mt-5 grid items-center gap-y-6')}>
                    {queryParams.get('cubiscanRequired') === 'true' && (
                        <IncrementDecrementSectionV2
                            backgroundColour="primary-grey-40"
                            borderColour=""
                            defaultInputValue={1}
                            isEditable={false}
                            showEditIcon={false}
                            successCallBack={() => {}}
                            title="Cubiscan Quantity"
                            onChangeCallBack={() => {}}
                            onEditCallBack={() => {}}
                        />
                    )}
                    <IncrementDecrementSectionV2
                        borderColour="success"
                        defaultInputValue={
                            quantityStatus.good - quantityStatus.cubiscan >= 0
                                ? quantityStatus.good - quantityStatus.cubiscan
                                : 0
                        }
                        isEditable={false}
                        showEditIcon={false}
                        successCallBack={() => {}}
                        title="Good Quantity"
                        onChangeCallBack={() => {}}
                        onEditCallBack={() => {}}
                    />
                    <IncrementDecrementSectionV2
                        borderColour="warning"
                        defaultInputValue={quantityStatus.excess}
                        isEditable={false}
                        showEditIcon={false}
                        successCallBack={() => {}}
                        title="Excess Quantity"
                        onChangeCallBack={() => {}}
                        onEditCallBack={() => {}}
                    />
                    <IncrementDecrementSectionV2
                        borderColour="sparkleGrey"
                        defaultInputValue={quantityStatus.short}
                        isEditable={false}
                        showEditIcon={false}
                        successCallBack={() => {}}
                        title="Short Quantity"
                        onChangeCallBack={() => {}}
                        onEditCallBack={() => {}}
                    />
                    <IncrementDecrementSectionV2
                        borderColour="error"
                        defaultInputValue={
                            quantityStatus.damaged +
                            quantityStatus.batchIssue +
                            quantityStatus.expired +
                            quantityStatus.lowFreshness +
                            quantityStatus.wrong
                        }
                        isEditable={false}
                        showEditIcon={false}
                        successCallBack={() => {}}
                        title="Failed Quantity"
                        onChangeCallBack={() => {}}
                        onEditCallBack={() => {}}
                    />
                </div>
            </main>
            <PrintLabelModals
                setSuccessModal={setSuccessModal}
                showSuccessModal={showSuccessModal}
            />
            <div
                className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    disabled={shouldDisableSubmit}
                    isLoading={printLabels.isLoading}
                    size="large"
                    onClick={() => {
                        onFormSubmit();
                    }}
                >
                    Confirm and Print Labels
                </Button>
            </div>
        </div>
    );
}
