import { Route } from 'react-router-dom';
import { ProductDetails } from '../../../../pages/inventory/stock-segregation/ProductDetails';
import { ScanDropZone } from '../../../../pages/inventory/stock-segregation/ScanDropZone';
import { ScanInventoryTote } from '../../../../pages/inventory/stock-segregation/ScanInventoryTote';
import { ScanJioCodeOrEAN } from '../../../../pages/inventory/stock-segregation/ScanJioCodeOrEAN';
import { ScanTote } from '../../../../pages/inventory/stock-segregation/ScanTote';
import { SelectInventory } from '../../../../pages/inventory/stock-segregation/SelectInventory';

export const StockTakeSegregationRoutes = [
    <Route key="1" element={<ScanDropZone />} path="" />,
    <Route key="2" element={<ScanTote />} path=":dropZoneId/scan-tote" />,
    <Route key="3" element={<ScanJioCodeOrEAN />} path=":dropZoneId/:toteId/scan-jiocode" />,
    <Route
        key="4"
        element={<ProductDetails />}
        path=":dropZoneId/:toteId/:jioCode/:lotId/details"
    />,
    <Route
        key="5"
        element={<SelectInventory />}
        path=":dropZoneId/:toteId/:jioCode/:lotId/select-inventory"
    />,
    <Route
        key="6"
        element={<ScanInventoryTote />}
        path=":dropZoneId/:toteId/:jioCode/:lotId/:inventoryType/inventory-tote"
    />,
];
