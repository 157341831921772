import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';
import { ResponseType } from '../../../types/fetch';
import { ReturnOrder } from './useFetchReturnOrders';
import { useParams } from 'react-router-dom';

export function useReturnOrders(stage: boolean) {
    const platform = location.href.includes('/returns/receiving')
        ? stage === true
            ? 'RECEIVING'
            : ''
        : '';
    const rfcId = sessionStorage.getItem('rfcId');
    const { shipmentId } = useParams();

    if (!shipmentId) throw new Error('Shipment ID not found');

    return useQuery<ReturnOrder>(['return-orders', rfcId, shipmentId], async () => {
        const response = await axios.get(urls.GET_RETURN_ORDERS(shipmentId, platform));
        const { data } = response.data as ResponseType<ReturnOrder>;
        if (!data) throw new Error('No data found');
        return filterProducts(data, platform, stage);
    });
}

function filterProducts(data: ReturnOrder, platform: string, stage: boolean) {
    if (platform === 'UNLOADING' || stage === false) return data;
    const filteredProducts = data.products.filter(
        (product) =>
            product.product_status === 'RETURN_UNLOADING_COMPLETED' ||
            product.product_status === 'RETURNS_UNLOADED'
    );
    return { ...data, products: filteredProducts };
}
