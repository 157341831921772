import { ReactNode } from 'react';
import { cn } from '../../../utils/tailwind';

interface ProductCardWrapperProps {
    children: ReactNode;
    onClick?: () => void;
}
export function ProductCardWrapper({ children, onClick }: ProductCardWrapperProps) {
    if (onClick) {
        return (
            <button className={cn('w-full')} type="button" onClick={onClick}>
                {children}
            </button>
        );
    }
    return <>{children}</>;
}
