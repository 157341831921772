import { Route } from 'react-router-dom';
import {
    ProductDetailsPage,
    RePrintJioCodePage,
    ScanJioCodePage,
    ScanShipmentPage,
    VerifyQuantitiesPage,
    UnsealableSummaryPage,
    AssignTotePage,
    AssignNewTotePage,
    CompatibleSKUsPage,
    SuccessPage,
    ScanStationIdPage,
} from '../../../../pages/returns/receiving';
import { DescriptionDetailsPage } from '../../../../pages/returns/receiving/DescriptionDetailsPage';

export const ReceivingRoutes = [
    <Route key="1" element={<ScanStationIdPage />} path="" />,
    <Route key="2" element={<ScanShipmentPage />} path=":stationId/scan-airway-bill" />,
    <Route key="3" path=":stationId/:shipmentId">
        <Route element={<ScanJioCodePage />} path="scan-jio-code" />
        <Route element={<RePrintJioCodePage />} path="reprint-jio-code" />
        <Route path=":jioCode">
            <Route element={<ProductDetailsPage />} path="details" />
            <Route element={<VerifyQuantitiesPage />} path="verify-quantities" />
            <Route element={<DescriptionDetailsPage />} path="verify-quantities/description" />
            <Route element={<UnsealableSummaryPage />} path="drop-list" />
            <Route element={<CompatibleSKUsPage />} path="compatible-skus" />
            <Route path="assign-tote">
                <Route path=":quantityType/:reason/:quantity">
                    <Route element={<AssignNewTotePage />} path="scan" />
                    <Route element={<AssignTotePage />} path="assign" />
                </Route>
            </Route>
        </Route>
        <Route element={<SuccessPage />} path=":awbNo/success" />
    </Route>,
];
