import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import {
    Button,
    Container,
    Heading,
    Icon,
    Modal,
    Notifications,
    ProgressBar,
    Spinner,
    Text,
} from '@jds/core';
import { useContext, useEffect, useState } from 'react';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { useQueryClient } from '@tanstack/react-query';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { ProductCard } from '../../../components/shared/ProductCard';
import { IcWarningColored } from '@jds/core-icons';
import {
    useUpdateDamagedProducts,
    UpdateDamagedProductsSchema,
} from '../../../hooks/outbound/packing/UseUpdateDamagedProducts';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { PackingContext } from '../../../provider/outbound/packing';
import { usePackingProducts } from '../../../hooks/outbound/packing/usePackingProducts';
import { usePackingStateSave } from '../../../hooks/outbound/packing/usePackingStateSave';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'jio-code', label: 'Jio Code', type: 'text' };
export function PackingMainPage() {
    const navigate = useNavigate();
    const { stationCode, ContainerCode, packingType } = useParams();
    const { data: packingOrders, isLoading } = usePackingProducts();
    const { state, dispatch } = useContext(PackingContext);
    const [currentShipmentId, setcurrentShipmentId] = useState('');
    const [currentOrderId, setCurrentOrderId] = useState('');
    const [currentJioCode, setCurrentJioCode] = useState('');
    const [currentLotId, setCurrentLotId] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const [showToteDrop, setShowToteDrop] = useState(false);
    const [showCancelledItemWarning, setShowCancelledItemWarning] = useState(false);
    const [markShortItems, setMarkShortItems] = useState(false);
    const savePackingactivity = usePackingStateSave();

    const getTotalOrderedQuantity = (data: any[]) => {
        let orderedQty = 0;
        data?.map((item) => {
            const qty = item?.tobePackedQuantity ?? 0;
            orderedQty += qty;
        });
        return orderedQty;
    };

    const packingOrderList = state.packingOrderList;
    const packingOrderInfo = useQueryClient().getQueryData<any>(['packing-order-info']) || [];
    const multiPackingOrderInfo = packingOrderInfo?.[0];
    const totalQuantity =
        packingType === 'Multi'
            ? getTotalOrderedQuantity(multiPackingOrderInfo?.packingOrderItemDetails)
            : packingOrderInfo?.length;

    const [quantityCompletedTillNow, setQuantityCompletedTillNow] = useState(
        state.PackingOrderQuantityTillNow
    );

    const jioCodeKeys = packingOrderList
        ?.filter((item) =>
            item?.scannedQuantity
                ? item?.scannedQuantity < item?.tobePackedQuantity
                : item?.processedQuantity > 0
        )
        ?.map((item) => ({
            result: item.jiocode,
        }));

    const handleMarkShort = () => {
        dispatch({
            type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
            payload: quantityCompletedTillNow,
        });
        navigate(`/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/mark-short`);
    };

    const updateDamagedProducts = useUpdateDamagedProducts();
    const handleUpdateShortQuantity = (data: UpdateDamagedProductsSchema[]) => {
        updateDamagedProducts.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    dispatch({
                        type: 'SET_IS_TOTE_QUANTITY_COMPLETED',
                        payload: true,
                    });
                    navigate(
                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/success`
                    );
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const validateJioCode = (jioCode: string) => {
        let val = -1;
        if (packingType === 'Multi') {
            val = packingOrderList.findIndex((item) => item.jiocode === jioCode);
        } else {
            val = packingOrderInfo.findIndex((data: { packingOrderItemDetails: any[] }) => {
                const found = data.packingOrderItemDetails.find((item: { jiocode: string }) => {
                    return item.jiocode === jioCode;
                });
                return found?.jiocode === jioCode;
            });
        }
        return val;
    };

    const getMarkShortList = () => {
        const cancelledOrderList: {
            cancelled_quantity: number;
            cancellation_reason: string;
            order_id: string;
            jio_code: string;
            cancelledDuringPacking: boolean;
        }[] = [];
        const scannedOrderList = state.scannedOrderList;
        packingOrderInfo.map(
            (item: { orderId: string; packingOrderItemDetails: { jiocode: any }[] }) => {
                const val = scannedOrderList.findIndex((order_id) => order_id === item.orderId);
                if (val === -1) {
                    cancelledOrderList.push({
                        cancelled_quantity: 1,
                        cancellation_reason: 'not available',
                        order_id: item.orderId,
                        jio_code: item.packingOrderItemDetails[0].jiocode,
                        cancelledDuringPacking: true,
                    });
                }
            }
        );
        return cancelledOrderList;
    };

    const handleScannedJioCode = (value: string) => {
        setCurrentJioCode(value);
        const val = validateJioCode(value);
        if (val >= 0 && quantityCompletedTillNow < totalQuantity) {
            if (packingType === 'Multi') {
                setCurrentLotId(packingOrderList[val].lotId);
                packingOrderList[val].scannedQuantity =
                    packingOrderList[val].scannedQuantity ??
                    packingOrderList[val]?.tobePackedQuantity -
                        packingOrderList[val]?.processedQuantity;
                if (
                    packingOrderList[val].scannedQuantity < packingOrderList[val].tobePackedQuantity
                ) {
                    handleJioCodeScanState(
                        value,
                        multiPackingOrderInfo.shipmentId,
                        multiPackingOrderInfo.orderId,
                        packingOrderList[val].lotId
                    );
                } else {
                    setCurrentOrderId(multiPackingOrderInfo.orderId);
                    setcurrentShipmentId(multiPackingOrderInfo.shipmentId);
                    setCurrentJioCode(value);
                    setCurrentLotId(packingOrderList[val].lotId);
                    setShowToteDrop(true);
                    setShowWarning(true);
                }
            } else if (packingType === 'Single') {
                setCurrentLotId(packingOrderList[val].lotId);
                packingOrderList[val].scannedQuantity =
                    packingOrderList[val].scannedQuantity ??
                    packingOrderList[val]?.tobePackedQuantity -
                        packingOrderList[val]?.processedQuantity;
                if (
                    packingOrderList[val].scannedQuantity < packingOrderList[val].tobePackedQuantity
                ) {
                    handleJioCodeScanState(
                        value,
                        packingOrderList[val].shipmentId,
                        packingOrderList[val].orderId,
                        packingOrderList[val].lotId
                    );
                } else {
                    setCurrentOrderId(packingOrderList[val].orderId);
                    setcurrentShipmentId(packingOrderList[val].shipmentId);
                    setCurrentJioCode(value);
                    setCurrentLotId(packingOrderList[val].lotId);
                    setShowToteDrop(true);
                    setShowWarning(true);
                }
            }
        } else {
            setShowWarning(true);
        }
    };

    const handleUpdateLocalStorage = () => {
        dispatch({
            type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW',
            payload: quantityCompletedTillNow,
        });
        dispatch({ type: 'SET_PACKING_ORDER_LIST', payload: packingOrderList });
    };

    function handleJioCodeScanState(
        jioCode: string,
        shipmentId: string,
        orderId: string,
        lotId: number
    ) {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_JIO_CODE_PACKING',
                status: 'IN_PROGRESS',
                shipmentId: shipmentId,
                orderId: orderId,
                containerId: ContainerCode,
                containerType: packingType?.toUpperCase() === 'MULTI' ? 'crate' : 'tote',
                quantity: 1,
                jiocode: jioCode,
                shipmentType: packingType?.toUpperCase(),
                packingStation: stationCode,
                locationId: stationCode,
                lotId: lotId,
            },
            {
                onSuccess: () => {
                    navigate(
                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/verify-details`
                    );
                },
            }
        );
    }

    function handleExcessItemsToteDropState() {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_TOTE_PACKING',
                status: 'IN_PROGRESS',
                shipmentId: currentShipmentId,
                orderId: currentOrderId,
                containerId: ContainerCode,
                containerType: packingType?.toUpperCase() === 'MULTI' ? 'crate' : 'tote',
                quantity: 1,
                jiocode: currentJioCode,
                shipmentType: packingType?.toUpperCase(),
                packingStation: stationCode,
                locationId: stationCode,
                productReceivingSource: 'PACKING_CANCELLED_ORDERS',
                productType: 'GOOD',
                lotId: Number(currentLotId) || 0,
            },
            {
                onSuccess: () => {
                    navigate(
                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${currentOrderId}/${currentShipmentId}/${currentJioCode}/${currentLotId}/drop-excess-items`
                    );
                },
            }
        );
    }

    useEffect(() => {
        const isComplete = state.isOrderQuantityCompleted;
        if (isComplete) {
            navigate(
                `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${multiPackingOrderInfo.orderId}/${multiPackingOrderInfo.shipmentId}/${multiPackingOrderInfo.packingOrderItemDetails[0].jiocode}/packaging-suggestion`
            );
        }
    }, [
        navigate,
        multiPackingOrderInfo.orderId,
        packingType,
        stationCode,
        ContainerCode,
        multiPackingOrderInfo.packingOrderItemDetails,
        state.isOrderQuantityCompleted,
        multiPackingOrderInfo.shipmentId,
    ]);

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Scan Jio Code"
                    onBackIconClick={() => {
                        if (quantityCompletedTillNow > 0)
                            Notifications.toast.error('Unable to navigate back');
                        else navigate(-1);
                    }}
                />
                <div className={cn('mx-3 mt-4 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        searchableList={jioCodeKeys}
                        onSuccess={(value) => {
                            handleScannedJioCode(value);
                        }}
                    />
                </div>
            </div>
            <main className={cn('mx-3 mt-4')}>
                <ProgressBar
                    className={cn('my-5 mx-2')}
                    label={packingType === 'Multi' ? 'Order Quantity' : 'Total Quantity in tote'}
                    value={quantityCompletedTillNow / totalQuantity}
                    valueLabel={`${quantityCompletedTillNow}/${totalQuantity}`}
                />
                {packingType === 'Multi' && (
                    <div
                        className={cn(
                            'inset-x-0 bottom-0  space-y-2.5 bg-white py-5',
                            layoutStyles
                        )}
                    >
                        <Container background="primary-grey-20" pad="m" rounded="large">
                            <Text className={cn('mb-4 flex justify-center')}>
                                Is the item short ?
                            </Text>
                            <Button kind="secondary" size="large" onClick={() => handleMarkShort()}>
                                Mark short
                            </Button>
                        </Container>
                    </div>
                )}
                {packingOrderList.length > 0 && (
                    <Text className={cn('mt-1')}>
                        {packingType === 'Single' ? 'Scanned Packaging' : 'Total Items'}
                    </Text>
                )}
                {isLoading && <Spinner className={cn('flex justify-center')} size="small" />}
                <div className={cn('mb-20 mt-3 space-y-4')}>
                    {packingOrders?.map((product) => (
                        <ProductCard
                            key={product.ean}
                            BBD={product.expiry}
                            EAN={product.ean}
                            MFD={product.mfg}
                            MRP={product.mrp}
                            colorSize={`${product.color}, ${product.size}`}
                            image={product?.image_urls?.[0]}
                            //showSuccessIcon={product.scannedQuantity === product.processedQuantity}
                        />
                    ))}
                </div>
                {packingType === 'Single' && (
                    <div
                        className={cn(
                            'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                            layoutStyles
                        )}
                    >
                        <Button
                            fullWidth
                            kind="primary"
                            size="large"
                            onClick={() => {
                                if (quantityCompletedTillNow !== totalQuantity)
                                    setMarkShortItems(true);
                                else {
                                    dispatch({
                                        type: 'SET_IS_TOTE_QUANTITY_COMPLETED',
                                        payload: true,
                                    });
                                    navigate(
                                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/success`
                                    );
                                }
                            }}
                        >
                            Tote Packaging Completed
                        </Button>
                    </div>
                )}
                <Modal
                    closed={!markShortItems}
                    size="m"
                    onCloseCallback={() => {
                        setMarkShortItems(false);
                    }}
                >
                    <div className={cn('text-center')}>
                        <span className={cn('flex justify-center')}>
                            <Icon ic={<IcWarningColored />} size="xxl" />
                        </span>
                        <Heading
                            appearance="heading-xs"
                            className={cn('flex justify-center text-center')}
                        >
                            {`Mark ${totalQuantity - quantityCompletedTillNow} items short ?
                            Items/orders will be cancelled`}
                        </Heading>
                        <div className={cn('mt-12 flex space-x-2')}>
                            <Button
                                kind="secondary"
                                onClick={() => {
                                    setMarkShortItems(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    handleUpdateShortQuantity(getMarkShortList());
                                }}
                            >
                                Mark Short
                            </Button>
                        </div>
                    </div>
                </Modal>
                {showWarning && (
                    <InfoModalSection
                        ModalHeader="Excess/Wrong item"
                        buttonText={showToteDrop ? 'Drop in a tote' : 'Continue'}
                        infoText=""
                        infoType="ERROR"
                        showCloseIcon={true}
                        onSuccess={(value) => {
                            setShowWarning(false);
                            if (value !== 'closed' && showToteDrop) {
                                handleUpdateLocalStorage();
                                handleExcessItemsToteDropState();
                            }
                        }}
                    />
                )}
                {showCancelledItemWarning && (
                    <InfoModalSection
                        ModalHeader="Customer cancelled!
                        Drop it in a tote"
                        buttonText="Okay"
                        infoText=""
                        infoType="ERROR"
                        showCloseIcon={false}
                        onSuccess={() => {
                            setShowCancelledItemWarning(false);
                            handleUpdateLocalStorage();
                            navigate(
                                `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${currentOrderId}/${currentShipmentId}/${currentJioCode}/${currentLotId}/drop-cancelled-items`
                            );
                        }}
                    />
                )}
            </main>
        </div>
    );
}
