import { initialPackingState } from './PackingProvider';

export interface PackingState {
    packingOrderList: {
        id: string;
        jiocode: string;
        ean: string;
        quantity: number;
        total_quantity?: number;
        shipment_id: string;
        lotId: number;
        quantity_not_picked: number;
        islowFreshness?: boolean;
        order_id: string;
        scannedQuantity: number;
        processedQuantity: number;
        cancelledQuantity: number;
        expectedQuantity: number;
        defectedQuantity: number;
        isHVIProduct: string;
        isLowFreshness: true;
        itemStatus: string;
        orderId: string;
        shipmentId: string;
        type: string;
        status: string;
        orderedQuantity: number;
        tobePackedQuantity: number;
    }[];
    scannedOrderList: string[];
    packingOrderInfo: {
        id: string;
        jio_code: string;
        quantity: number;
        total_quantity?: number;
        shipment_id: string;
        lot_id: number;
        quantity_not_picked: number;
    }[];
    PackingFailedJioCodesTillNow: {
        [key: string]: {
            toteCode: string;
            jioCodesList: { jioCode: string; lotId: number }[];
        };
    };
    PackingDamagedJioCodesTillNow: string[];
    PackingOrderTotalQuantity: number;
    PackingOrderQuantityTillNow: number;
    PackingToteQuantityTillNow: number;
    isToteQuantityCompleted: boolean;
    isOrderQuantityCompleted: boolean;
}

export interface QuantityStatus {
    damaged: number;
    short: number;
}

export type PackingAction =
    | { type: 'SET_PACKING_ORDER_TOTAL_QUANTITY'; payload: number }
    | { type: 'RESET_PACKING_ORDER_TOTAL_QUANTITY' }
    | { type: 'SET_PACKING_ORDER_QUANTITY_TILL_NOW'; payload: number }
    | { type: 'RESET_PACKING_ORDER_QUANTITY_TILL_NOW' }
    | { type: 'SET_PACKING_TOTE_QUANTITY_TILL_NOW'; payload: number }
    | { type: 'RESET_PACKING_TOTE_QUANTITY_TILL_NOW' }
    | { type: 'SET_IS_TOTE_QUANTITY_COMPLETED'; payload: boolean }
    | { type: 'RESET_IS_TOTE_QUANTITY_COMPLETED' }
    | { type: 'SET_IS_ORDER_QUANTITY_COMPLETED'; payload: boolean }
    | { type: 'RESET_IS_ORDER_QUANTITY_COMPLETED' }
    | {
          type: 'SET_PACKING_ORDER_LIST';
          payload: {
              id: string;
              jiocode: string;
              ean: string;
              quantity: number;
              total_quantity?: number;
              shipment_id: string;
              lotId: number;
              quantity_not_picked: number;
              islowFreshness?: boolean;
              order_id: string;
              scannedQuantity: number;
              processedQuantity: number;
              cancelledQuantity: number;
              expectedQuantity: number;
              isHVIProduct: string;
              isLowFreshness: true;
              itemStatus: string;
              orderId: string;
              shipmentId: string;
              type: string;
              status: string;
              orderedQuantity: number;
              defectedQuantity: number;
              tobePackedQuantity: number;
          }[];
      }
    | { type: 'RESET_PACKING_ORDER_LIST' }
    | {
          type: 'SET_SCANNED_ORDER_LIST';
          payload: string[];
      }
    | { type: 'RESET_SCANNED_ORDER_LIST' }
    | {
          type: 'SET_PACKING_ORDER_INFO';
          payload: {
              id: string;
              jio_code: string;
              quantity: number;
              total_quantity?: number;
              shipment_id: string;
              lot_id: number;
              quantity_not_picked: number;
          }[];
      }
    | { type: 'RESET_PACKING_ORDER_INFO' }
    | {
          type: 'SET_PACKING_DAMAGED_JIO_CODES';
          payload: string[];
      }
    | { type: 'RESET_PACKING_DAMAGED_JIO_CODES' }
    | {
          type: 'SET_PACKING_FAILED_JIO_CODES';
          payload: {
              [key: string]: {
                  toteCode: string;
                  jioCodesList: { jioCode: string; lotId: number }[];
              };
          };
      }
    | { type: 'RESET_PACKING_FAILED_JIO_CODES' }
    | { type: 'RESET_ALL' };

export function packingReducer(state: PackingState, action: PackingAction): PackingState {
    switch (action.type) {
        case 'SET_PACKING_ORDER_TOTAL_QUANTITY':
            return { ...state, PackingOrderTotalQuantity: action.payload };
        case 'RESET_PACKING_ORDER_TOTAL_QUANTITY':
            return { ...state, PackingOrderTotalQuantity: 0 };
        case 'SET_PACKING_ORDER_QUANTITY_TILL_NOW':
            return { ...state, PackingOrderQuantityTillNow: action.payload };
        case 'RESET_PACKING_ORDER_QUANTITY_TILL_NOW':
            return { ...state, PackingOrderQuantityTillNow: 0 };
        case 'SET_PACKING_TOTE_QUANTITY_TILL_NOW':
            return { ...state, PackingToteQuantityTillNow: action.payload };
        case 'RESET_PACKING_TOTE_QUANTITY_TILL_NOW':
            return { ...state, PackingToteQuantityTillNow: 0 };
        case 'SET_IS_TOTE_QUANTITY_COMPLETED':
            return { ...state, isToteQuantityCompleted: action.payload };
        case 'RESET_IS_TOTE_QUANTITY_COMPLETED':
            return { ...state, isToteQuantityCompleted: false };
        case 'SET_IS_ORDER_QUANTITY_COMPLETED':
            return { ...state, isOrderQuantityCompleted: action.payload };
        case 'RESET_IS_ORDER_QUANTITY_COMPLETED':
            return { ...state, isOrderQuantityCompleted: false };
        case 'SET_PACKING_ORDER_LIST':
            return { ...state, packingOrderList: action.payload };
        case 'RESET_PACKING_ORDER_LIST':
            return { ...state, packingOrderList: [] };
        case 'SET_SCANNED_ORDER_LIST':
            return { ...state, scannedOrderList: action.payload };
        case 'RESET_SCANNED_ORDER_LIST':
            return { ...state, scannedOrderList: [] };
        case 'SET_PACKING_ORDER_INFO':
            return { ...state, packingOrderInfo: action.payload };
        case 'RESET_PACKING_ORDER_INFO':
            return { ...state, packingOrderInfo: [] };
        case 'SET_PACKING_DAMAGED_JIO_CODES':
            return { ...state, PackingDamagedJioCodesTillNow: action.payload };
        case 'RESET_PACKING_DAMAGED_JIO_CODES':
            return { ...state, PackingDamagedJioCodesTillNow: [] };
        case 'SET_PACKING_FAILED_JIO_CODES':
            return { ...state, PackingFailedJioCodesTillNow: action.payload };
        case 'RESET_PACKING_FAILED_JIO_CODES':
            return { ...state, PackingFailedJioCodesTillNow: {} };
        case 'RESET_ALL':
            return initialPackingState;
        default:
            throw new Error('Unhandled action type');
    }
}
