import { Notifications } from '@jds/core';
import { urls } from '../../../utils/urls';
import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { UnloadingContext } from '../../../provider/returns/unloading';

export function useGetCompletedReturnOrders() {
    const { appointmentNumber } = useParams();
    const { dispatch } = useContext(UnloadingContext);
    return useQuery<unknown, AxiosError<any>>({
        queryFn: async () => {
            const response = await axios.get<any>(
                urls.GET_COMPLETED_RETURN_ORDERS(appointmentNumber ?? '')
            );
            const { data } = response.data;
            if (data) {
                dispatch({
                    type: 'SET_COMPLETED_AIRWAY_BILLS',
                    payload: [...data],
                });
            }
            return data;
        },
    });
}
