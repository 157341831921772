import { cn } from '../../utils/tailwind';
import { Modal } from '@jds/core';
import { useRef, useState } from 'react';
import { ProductCardInModal } from '../shared/ProductCard';

interface PrductDetailsModalProps {
    EAN: string;
    MRP: number;
    MFD: number;
    BBD: number;
    jioCode: string;
    colorSize: string;
    title?: string;
    isLowFreshness?: boolean;
    image?: string;
    onSuccess: (value: string) => void;
}

export function ProductDetailsModal({
    BBD,
    colorSize,
    EAN,
    MFD,
    MRP,
    title,
    jioCode,
    isLowFreshness,
    image,
    onSuccess,
}: PrductDetailsModalProps) {
    const [showModal, setShowModal] = useState(true);
    const modalInputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            {showModal && (
                <Modal
                    closed={!showModal}
                    isClosable={true}
                    primaryCTA={{
                        onClick: () => {
                            const value = modalInputRef.current?.value ?? '';
                            onSuccess(value);
                            setShowModal(false);
                        },
                    }}
                    onCloseCallback={() => {
                        onSuccess('closed');
                        setShowModal(false);
                    }}
                >
                    <ProductCardInModal
                        BBD={BBD}
                        EAN={EAN}
                        MFD={MFD}
                        MRP={MRP}
                        colorSize={colorSize}
                        image={image}
                        jioCode={jioCode}
                        title={title}
                    />
                </Modal>
            )}
        </>
    );
}
