import { useState } from 'react';
import { cn } from '../../../utils/tailwind';
import { Container, Divider, Icon, Text } from '@jds/core';
import { IcChevronDown, IcChevronUp } from '@jds/core-icons';

interface DetailsAccordionProps {
    title: string;
    description: string[];
}
export function DetailsAccordion({ title, description }: DetailsAccordionProps) {
    const [expanded, setExpanded] = useState(false);
    return (
        <div>
            <button
                aria-label={expanded ? 'Collapse' : 'Expand'}
                className={cn('w-full')}
                type="button"
                onClick={() => setExpanded(!expanded)}
            >
                <Container rounded="small">
                    <div className={cn('flex items-center')}>
                        <Text appearance="body-xxs" color="primary-50">
                            {title}
                        </Text>
                        <Icon
                            ic={
                                expanded ? (
                                    <IcChevronUp color="#3535F3" />
                                ) : (
                                    <IcChevronDown color="#3535F3" />
                                )
                            }
                        />
                    </div>
                </Container>
            </button>
            {expanded && (
                <div>
                    <ul
                        className={cn('ml-5 list-[square] pb-2')}
                        style={{ color: '#3535F3', fontSize: '0.75rem' }}
                    >
                        {description.map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
