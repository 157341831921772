import { Route } from 'react-router-dom';
import {
    CubiscanVerificationPage,
    ScanCubiscanLabelPage,
    ScanCubiscanMachinePage,
    ScanDestinationTote,
    ScanJioCodePage,
    ScanSourceTote,
    SuccessPage,
} from '../../../../pages/cubiscan';

export const CubiscanRoutes = [
    <Route key="1" element={<ScanSourceTote />} path="" />,
    <Route key="2" element={<ScanCubiscanMachinePage />} path=":sourceToteId" />,
    <Route key="3" element={<ScanCubiscanLabelPage />} path=":sourceToteId/:machineId" />,
    <Route
        key="3"
        element={<CubiscanVerificationPage />}
        path=":sourceToteId/:machineId/:lotId/:cubiscanLabel"
    />,
    <Route
        key="4"
        element={<ScanJioCodePage />}
        path=":sourceToteId/:machineId/:lotId/:cubiscanLabel/scan-jio-code"
    />,
    <Route
        key="4"
        element={<ScanDestinationTote />}
        path=":sourceToteId/:machineId/:lotId/:cubiscanLabel/scan-jio-code/:jioCode"
    />,
    <Route
        key="5"
        element={<SuccessPage />}
        path=":sourceToteId/:machineId/:lotId/:cubiscanLabel/scan-jio-code/:jioCode/success"
    />,
];
