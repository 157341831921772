import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { Notifications } from '@jds/core';
import { ResponseType } from '../../../types/fetch';

export interface packingStateSaveSchema {
    username: string;
    rfc_code: string;
    rfcId?: number;
    status: 'PENDING' | 'IN_PROGRESS' | 'COMPLETED';
    activity_name:
        | 'SCAN_STATION_PACKING'
        | 'SCAN_CONTAINER_PACKING'
        | 'SCAN_JIO_CODE_PACKING'
        | 'VERIFY_JIOCODE_SUCCESS_PACKING'
        | 'VERIFY_JIOCODE_FAILURE_PACKING'
        | 'SUGGEST_PACKING_MATERIAL'
        | 'SAVE_PACKING_ID'
        | 'SAVE_PACK_CONFIRMATION'
        | 'SAVE_SHIPPING_LABEL_GENERATION'
        | 'DAMAGED_JIOCODE_PACKING'
        | 'LOW_FRESHNESS_JIOCODE_PACKING'
        | 'WRONG_PRODUCT_JIOCODE_PACKING'
        | 'EXCESS_JIOCODE_PACKING'
        | 'SCAN_DROP_TOTE_PACKING';

    containerId?: string;
    containerType?: string;
    shipmentId?: string;
    shipmentType?: string;
    jiocode?: string;
    quantity?: number;
    orderId?: string;
    lotId?: number;
    toteId?: string;
    packingStation?: string;
    packingId?: number;
    awbRefNumber?: string;
    locationId?: string;
    productReceivingSource?: 'PACKING_OUTBOUND_QC' | 'PACKING_CANCELLED_ORDERS';
    productType?: 'GOOD' | 'DAMAGED' | 'LOW_FRESHNESS' | 'WRONG' | 'EXPIRED';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

const defaultErrorMsg = 'Something went wrong';
export function usePackingStateSave() {
    return useMutation<unknown, AxiosError<ResponseType<unknown>>, packingStateSaveSchema>({
        mutationFn: async (payload) => {
            const response = await axios.post(urls.OUTBOUND_STATE_SAVE(), payload);
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
