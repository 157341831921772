import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { urls } from '../../../utils/urls';
import { ErrorResponse, handleError, SuccessResponse } from './useGetPickList';

export interface AssignPicklistTrolleySchema {
    pickListId: number;
    trolleyId: string;
    toteId: string;
    orderId: string;
    jioCode: string;
    shipmentId?: string;
}

const defaultErrorMsg = 'Something went wrong.';
export function useAssignPicklistTrolley() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, AssignPicklistTrolleySchema>(
        async (body: AssignPicklistTrolleySchema) => {
            const response = await axios.post(urls.ASSIGN_PICKLIST_TROLLEY(), body, {
                headers: {
                    rfcCode: sessionStorage.getItem('rfc-code'),
                },
            });
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
