import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { useState } from 'react';
import { PageHeader } from '../../../components/shared/PageHeader';
import { Button, Heading, Notifications, Text } from '@jds/core';
import {
    AssignCrateSchema,
    useAssignCrate,
} from '../../../hooks/outbound/consolidation/useAssignCrate';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'crate-location', label: 'Empty Crate Location', type: 'text' };
export function CrateLocationPage() {
    const navigate = useNavigate();
    const { stationId, toteCode, ptwLocation, crateId, shipmentId } = useParams();
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const assignCrate = useAssignCrate();
    const assignCrateOnSubmit = (data: AssignCrateSchema) => {
        assignCrate.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    Notifications.toast.success({
                        title: `Item dropped in new crate ${crateId}`,
                        description: ``,
                    });
                    if (res.data.orderStatus === 'COMPLETED') setShowSuccessPopup(true);
                    else navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
                }
            },
            onError(error: any) {
                const defaultErrorMsg = 'Something went wrong';
                const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
                if (Array.isArray(errors)) {
                    errors.forEach((description) => {
                        Notifications.toast.error({ title: 'Error', description });
                    });
                } else if (error.response?.data?.message) {
                    const description = error.response?.data?.message ?? defaultErrorMsg;
                    Notifications.toast.error({ title: 'Error', description });
                } else {
                    const description = errors ?? defaultErrorMsg;
                    Notifications.toast.error({ title: 'Error', description });
                }
            },
        });
    };

    return (
        <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
            <PageHeader showBackIcon={false} title="Scan crate location" />
            <main className={cn('mx-3 mt-6')}>
                <div className={cn('mx-2 mt-2 mb-6 grid grid-cols-2 items-end')}>
                    <Text appearance="body-m" color="primary-grey-80">
                        Crate ID
                    </Text>
                    <div className={cn('w-full')}>
                        <div className={cn('mr-auto max-w-[52px]')}>
                            <Heading as="h5" color="primary-grey-100">
                                {crateId}
                            </Heading>
                        </div>
                    </div>
                </div>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount={true}
                    inputProps={manualInputProps}
                    onSuccess={(value) => {
                        assignCrateOnSubmit({
                            ptw_location: ptwLocation || '',
                            order_id: '',
                            shipment_id: shipmentId || '',
                            crate_id: crateId || '',
                            type: 'ASSIGN',
                        });
                    }}
                />
                <div
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                        layoutStyles
                    )}
                >
                    <Button
                        fullWidth
                        kind="primary"
                        size="large"
                        onClick={() => {
                            navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
                        }}
                    >
                        Drop complete
                    </Button>
                </div>
            </main>
        </div>
    );
}
