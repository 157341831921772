import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../utils/urls';

export interface TotesValidationSchema {
    toteId: string;
    stationId: string;
    scannedTotes: Array<string>;
}

export function useGeneratePutList() {
    return useMutation(async (data: TotesValidationSchema) => {
        const body = { totes: data.scannedTotes };
        const response = await axios.post(urls.GENERATE_PUT_LIST(data.stationId), body);
        return response.data;
    });
}
