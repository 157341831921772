import { Route } from 'react-router-dom';
import { UnloadingHome } from '../../../../pages/inbound/unloading/UnloadingHome';
import { AppointmentForm } from '../../../../pages/inbound/unloading/AppointmentForm';
import { ScanSecondaryCases } from '../../../../pages/inbound/unloading/ScanSecondaryCases';
import { GeneratePOD } from '../../../../pages/inbound/unloading/GeneratePOD';
import { UploadSignedPOD } from '../../../../pages/inbound/unloading/UploadSignedPOD';
import { MarkShortAndExcess } from '../../../../pages/inbound/unloading/MarkShortAndExcess';
import { SuccessPageV2 } from '../../SuccessPageV2';

export const UnloadingRoutes = [
    <Route key="1" element={<UnloadingHome />} path="" />,
    <Route key="2" element={<AppointmentForm />} path=":stationId" />,
    <Route
        key="3"
        element={<ScanSecondaryCases />}
        path=":stationId/:appointmentNumber/scan-secondary"
    />,
    <Route
        key="4"
        element={<MarkShortAndExcess />}
        path=":stationId/:appointmentNumber/mark-short-excess"
    />,
    <Route key="5" element={<GeneratePOD />} path=":stationId/:appointmentNumber/generate-pod" />,
    <Route key="6" element={<UploadSignedPOD />} path=":stationId/:appointmentNumber/upload-pod" />,
    <Route
        key="7"
        element={<SuccessPageV2 message="STR Unloading completed successfully" />}
        path=":stationId/:appointmentNumber/success"
    />,
];
