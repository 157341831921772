import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import {
    Button,
    Container,
    Heading,
    Icon,
    Input,
    Modal,
    Notifications,
    ProgressBar,
    Text,
} from '@jds/core';
import { IcAddCircle, IcMinimise, IcWarningColored } from '@jds/core-icons';
import { useContext, useState } from 'react';
import { UnloadingContext } from '../../../provider/returns/unloading';
import { useQueryClient } from '@tanstack/react-query';
import { pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ProductCardV2 } from '../../../components/shared/ProductCardV2';
import { useUpdateJiocodeQuantity } from '../../../hooks/returns/unloading/useUpdateJiocodeQuantity';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function VerifyQuantity() {
    const queryClient = useQueryClient();
    const updateJiocode = useUpdateJiocodeQuantity();
    const { dispatch, state } = useContext(UnloadingContext);
    const products = state.products;
    const [quantity, setQuantity] = useState(0);
    const [showMarkShortDialog, setShowMarkShortDialog] = useState(false);
    const [navigate, { ean: productEAN }] = [useNavigate(), useParams()];
    const product = products?.find(({ ean }) => ean === productEAN);
    const shortQuantity = Number(product?.expectedQuantity) - Number(quantity);

    if (!product) throw new Error('Product not found');

    const handleInputQuantityChange = (value: any) => {
        if (Number(value) >= 0) {
            const valueAsNumber = Number(value);
            const isValid =
                !Number.isNaN(valueAsNumber) && valueAsNumber <= product?.expectedQuantity;
            if (isValid) setQuantity(valueAsNumber);
        }
    };

    const handleConfirm = () => {
        updateJiocode.mutate(
            { jio_code: product.jio_code, quantity },
            {
                onSuccess: () => {
                    dispatch({
                        type: 'SET_COMPLETED_PRODUCTS',
                        payload: [...state.completedProducts, product?.jio_code],
                    });
                    updateQuantity();
                    navigate('./../../products-to-return');
                },
            }
        );
    };

    function updateQuantity() {
        const rfcId = sessionStorage.getItem('rfcId');
        const keys = products?.map(({ jio_code }) => jio_code) ?? [];
        const updatedProducts = products?.map((product) => {
            if (product.ean === productEAN) return { ...product, expectedQuantity: quantity };
            return product;
        });
        queryClient.setQueryData(['products', rfcId, keys], updatedProducts);
    }

    function onScanSuccess(scannedEAN: string) {
        if (scannedEAN !== product?.ean) {
            Notifications.toast.error({
                title: 'Invalid EAN',
                description: 'Please scan the correct EAN',
            });
        } else {
            Notifications.toast.success({
                title: 'Success',
                description: `EAN ${scannedEAN} X ${Number(quantity) + 1} scanned successfully`,
            });
            setQuantity(Math.min(Number(quantity) + 1, product?.expectedQuantity));
        }
    }

    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Returns Unloading"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <div className={cn('mx-3 max-w-screen-md overflow-auto py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        inputProps={{
                            disabled: quantity === product?.expectedQuantity,
                            label: 'EAN',
                            name: 'ean',
                        }}
                        searchableList={[{ result: productEAN || '' }]}
                        onSuccess={onScanSuccess}
                    />
                </div>
            </div>
            <main className={cn('mx-3 mt-6 mb-20')}>
                <div className={cn('mt-6 space-y-6')}>
                    <ProgressBar
                        label="Packages"
                        value={Number(quantity) / product?.expectedQuantity}
                        valueLabel={`${quantity} / ${product?.expectedQuantity}`}
                    />
                    <Container background="primary-20" pad="m" rounded="large">
                        <div className={cn('flex items-center justify-between')}>
                            <Text appearance="body-m-bold" color="primary-60">
                                Unloading Quantity
                            </Text>
                            <div className={cn('flex items-center justify-end')}>
                                <>
                                    <Icon
                                        aria-label="decrement"
                                        ic={<IcMinimise />}
                                        style={{ color: '#7F7FFB' }}
                                        onClick={() => handleInputQuantityChange(quantity - 1)}
                                    />
                                    <Input
                                        className={cn('mx-3 !mt-0 flex w-7 !pb-0')}
                                        color="primary-60"
                                        id="number"
                                        inputMode="numeric"
                                        label=""
                                        name=""
                                        type="number"
                                        value={quantity}
                                        onChange={(event) =>
                                            handleInputQuantityChange(event.target.value)
                                        }
                                    />
                                    <Icon
                                        aria-label="increment"
                                        ic={<IcAddCircle />}
                                        style={{ color: '#7F7FFB' }}
                                        onClick={() => handleInputQuantityChange(quantity + 1)}
                                    />
                                </>
                            </div>
                        </div>
                    </Container>
                    <ProductCardV2
                        key={product.jio_code}
                        BBD={product.expiry}
                        EAN={product.ean}
                        MFD={product.mfg}
                        MRP={product.mrp}
                        colorSize={`${product.color ?? ''}, ${product.size ?? ''}`}
                        image={product.image_urls?.length ? product.image_urls[0] : ''}
                        jioCode={product.jio_code}
                        title={product.product_title}
                    />
                </div>
            </main>
            <div className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}>
                {quantity === product?.expectedQuantity ? (
                    <Button fullWidth size="large" type="button" onClick={handleConfirm}>
                        Confirm
                    </Button>
                ) : (
                    <Button
                        fullWidth
                        kind="secondary"
                        size="large"
                        type="button"
                        onClick={() => setShowMarkShortDialog(true)}
                    >
                        Mark Short
                    </Button>
                )}
            </div>
            <Modal
                closed={!showMarkShortDialog}
                header={{
                    top: (
                        <div className={cn('text-center')}>
                            <Icon ic={<IcWarningColored />} size="xl" />
                            <Heading appearance="heading-xs">
                                Mark {shortQuantity} items short ?
                            </Heading>
                        </div>
                    ),
                }}
                primaryCTA={{
                    title: 'Confirm',
                    onClick: () => {
                        handleConfirm();
                    },
                }}
                onCloseCallback={() => setShowMarkShortDialog(false)}
            />
        </>
    );
}
