import { cn } from '../../../utils/tailwind';
import { Container, Icon, Text } from '@jds/core';
import { format } from 'date-fns';
import { ProductCardWrapper } from './ProductCardWrapper';
import { IcSuccessColored, IcWarningColored } from '@jds/core-icons';

interface ProductCardProps {
    EAN: string;
    MRP: number;
    MFD: number;
    BBD: number;
    colorSize: string;
    title?: string;
    onClick?: () => void;
    showSuccessIcon?: boolean;
    isLowFreshness?: boolean;
    image?: string;
}

export function ProductCard({
    BBD,
    colorSize,
    EAN,
    MFD,
    MRP,
    title,
    onClick,
    showSuccessIcon,
    isLowFreshness,
    image,
}: ProductCardProps) {
    return (
        <ProductCardWrapper onClick={onClick}>
            <Container
                background="primary-grey-20"
                className={cn('flex justify-between space-x-2.5')}
                pad="xs"
                rounded="large"
            >
                <div className={cn('flex')}>
                    <img
                        alt="Product Image"
                        className={cn('h-[88px] w-[88px] rounded-2xl object-cover')}
                        src={image ? image : 'https://via.placeholder.com/88'}
                    />
                    <div className={cn('flex flex-col items-start space-y-0.5 pl-3')}>
                        {title && (
                            <Text
                                appearance="body-xs-link"
                                className={cn('line-clamp-2')}
                                color="primary-grey-80"
                                title={title}
                            >
                                {title}
                            </Text>
                        )}
                        <Text appearance="body-xxs" color="primary-grey-80">
                            EAN {EAN}
                        </Text>
                        <Text appearance="body-xxs" color="primary-grey-80">
                            MRP {MRP}
                        </Text>
                        <Text appearance="body-xxs" color="primary-grey-80">
                            MFD {MFD ? format(MFD, 'dd-MM-yyyy') : '_'}
                        </Text>
                        <Text appearance="body-xxs" color="primary-grey-80">
                            BBD {BBD ? format(BBD, 'dd-MM-yyyy') : '_'}
                        </Text>
                        <Text appearance="body-xxs" color="primary-grey-80">
                            Color & Size {colorSize}
                        </Text>
                        {isLowFreshness && (
                            <Text
                                appearance="body-xxs"
                                className={cn('flex')}
                                color="primary-grey-80"
                            >
                                <Icon ic={<IcWarningColored className={cn('mr-1')} />} size="s" />{' '}
                                Low Freshness
                            </Text>
                        )}
                    </div>
                </div>
                {showSuccessIcon && (
                    <div>
                        <Icon color="primary" ic={<IcSuccessColored />} size="s" />
                    </div>
                )}
            </Container>
        </ProductCardWrapper>
    );
}
