import { cn } from '../../utils/tailwind';
import { Button, Text } from '@jds/core';
import { IcBack } from '@jds/core-icons';
import { useCurrentUser } from '../../hooks/shared/useCurrentUser';
import { useNavigate } from 'react-router-dom';

interface SecondaryPageHeaderProps {
    showBackButton?: boolean;
}
export function SecondaryPageHeader({ showBackButton = true }: SecondaryPageHeaderProps) {
    const [{ data: user }, navigate] = [useCurrentUser(), useNavigate()];

    return (
        <header
            className={cn('flex h-12 items-center', [
                showBackButton ? 'justify-between' : 'justify-end',
            ])}
        >
            {showBackButton && (
                <Button
                    aria-label="go back to previous page"
                    icon={<IcBack color="black" />}
                    kind="tertiary"
                    type="button"
                    onClick={() => navigate('/')}
                />
            )}
            <div className={cn('flex items-center space-x-2.5')}>
                <div>
                    <Text appearance="body-xxs" color="primary-60">
                        {user?.userName}
                    </Text>
                    <Text appearance="body-xxs" color="primary-60">
                        {user?.firstName ?? ''} {user?.lastName ?? ''}
                    </Text>
                </div>
                <img
                    alt="profile"
                    className={cn('h-8 w-8 rounded-full object-cover')}
                    height={32}
                    src="https://picsum.photos/id/64/128/128"
                    width={32}
                />
            </div>
        </header>
    );
}
