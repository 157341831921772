import { Notifications } from '@jds/core';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { ProductType, TotePurpose } from '../../receiving/useValidateTote';

export interface ToteTransferOptions {
    source_tote_code: string;
    target_tote_code: string;
    source_jio_code: string;
    target_jio_code: string;
    source_lot_id: string;
    target_lot_id: string;
    location_id: string;
    source_product_type: ProductType;
    source_tote_purpose: TotePurpose;
    target_product_type: string;
    target_tote_purpose: string;
}

export function useToteTransfer() {
    return useMutation<any, AxiosError<ErrorResponse>, any>(
        async (payload: any) => {
            const response = await axios.post(urls.TOTE_TRANSFER_DROP(), payload);
            return response.data;
        },
        {
            onError(error) {
                const defaultErrorMsg = 'An error occurred while dropping';
                const message = error?.response?.data?.meta?.errors?.at(0) ?? defaultErrorMsg;
                Notifications.toast.error({
                    title: 'Error',
                    description: message,
                });
            },
        }
    );
}

interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}
