import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@jds/core';
import { cn } from '../../../utils/tailwind';
import { FreeCrateSchema, useFreeCrate } from '../../../hooks/outbound/consolidation/useFreeCrate';
import { useFreeTote } from '../../../hooks/outbound/consolidation/useFreeTote';
import { JioCodesListSchema } from '../../../hooks/outbound/consolidation/useJioCodesList';
import { useContext } from 'react';
import { PackingContext } from '../../../provider/outbound/packing';
import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';

export function PackingSuccessPage() {
    const navigate = useNavigate();
    const { stationCode, packingType, orderId, ContainerCode, jioCode, shipmentId } = useParams();
    const queryClient = useQueryClient();
    const { state, dispatch } = useContext(PackingContext);
    const isToteQuantityCompleted = state.isToteQuantityCompleted;

    const getButtonText = () => {
        if (packingType === 'Single' && isToteQuantityCompleted) {
            return 'Pick a new task';
        } else if (packingType === 'Single') {
            return 'Pick a new item';
        } else return 'Pick a new task';
    };

    const freeTote = useFreeTote();
    const handleFreeTote = (data: JioCodesListSchema) => {
        freeTote.mutate(data, {
            onSuccess() {
                navigate('/');
            },
            onError() {
                navigate('/');
            },
        });
    };

    const freeCrate = useFreeCrate();
    const handleFreeCrate = (data: FreeCrateSchema) => {
        freeCrate.mutate(data, {
            onSuccess() {
                navigate('/');
            },
            onError() {
                navigate('/');
            },
        });
    };

    return (
        <>
            <SuccessPageV2
                buttonText={getButtonText()}
                message={
                    (packingType === 'Single' && !isToteQuantityCompleted) ||
                    packingType === 'Multi'
                        ? 'Invoice, Shipping label generated successfully'
                        : `Tote is completed successfully. Invoice, Shipping label generated successfully.`
                }
                onButtonClick={() => {
                    if (packingType === 'Single' && !isToteQuantityCompleted) {
                        navigate(
                            `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/main`
                        );
                    } else {
                        queryClient.clear();
                        localStorage.clear();
                        dispatch({ type: 'RESET_ALL' });
                        packingType === 'Single'
                            ? handleFreeTote({
                                  toteId: ContainerCode || '',
                              })
                            : handleFreeCrate({
                                  crateId: ContainerCode || '',
                                  crateStatus: 'FREE',
                              });
                    }
                }}
            />
            {jioCode !== undefined && orderId !== undefined && (
                <span
                    className={cn(
                        'fixed inset-x-0  bottom-0 mt-3 flex space-x-2 bg-white  py-2.5 px-6'
                    )}
                >
                    <Button
                        kind="tertiary"
                        onClick={() => {
                            navigate(
                                `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/packaging-suggestion`
                            );
                        }}
                    >
                        Did not print labels?
                    </Button>
                </span>
            )}
        </>
    );
}
