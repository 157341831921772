import { Heading, Modal } from '@jds/core';
import { cn } from '../../utils/tailwind';

interface ConfirmationDialogProps {
    icon?: JSX.Element;
    isClosable?: boolean;
    isOpened: boolean;
    message: string;
    primaryCTALabel: string;
    onPrimaryCTAClick: () => void;
    onCloseClick?: () => void;
}

const ConfirmationDialog = ({
    icon,
    isClosable = false,
    isOpened,
    message,
    primaryCTALabel,
    onPrimaryCTAClick,
    onCloseClick,
}: ConfirmationDialogProps) => {
    return (
        <Modal
            closed={!isOpened}
            header={{
                top: (
                    <div className="flex flex-col items-center justify-center">
                        {icon}
                        <Heading appearance="heading-xs" as="h5" className={cn('text-center')}>
                            {message}
                        </Heading>
                    </div>
                ),
            }}
            isClosable={isClosable}
            kind="acknowledgement"
            primaryCTA={{
                title: primaryCTALabel,
                onClick: onPrimaryCTAClick,
            }}
            onCloseCallback={onCloseClick}
        />
    );
};
export default ConfirmationDialog;
