import { useParams } from 'react-router-dom';
import { urls } from '../../../utils/urls';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Notifications } from '@jds/core';
import { QuantityDetails } from '../../receiving/useFetchDropListQuantity';

export interface DroppedTotesListResponse {
    data: QuantityDetails;
    meta: {
        code: string;
        data: string;
        status: 'SUCCESS' | 'FAILURE';
        errors?: string[];
    };
}

export interface ToteList {
    toteCode: string;
    quantity: number;
}

export interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

export interface ErrorResponse {
    message: string;
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}

export const productTypeLabels: Record<keyof any, string> = {
    good: 'GOOD',
    batchIssue: 'BATCH_ISSUE',
    damaged: 'DAMAGED',
    short: 'SHORT',
    excess: 'EXCESS',
    lowFreshness: 'LOW_FRESHNESS',
    expired: 'EXPIRED',
    wrong: 'WRONG',
    failed: 'FAILED',
};

const defaultErrorMsg = 'Something went wrong.';
export function useFetchDroppedTotesList() {
    const { jioCode, shipmentId, reason } = useParams();
    const { data, ...rest } = useQuery<DroppedTotesListResponse>({
        queryKey: ['toteDropQuantityDetails'],
        queryFn: async () => {
            const response = await axios.get(
                urls.GET_DROPPED_TOTES_LIST_RETURNS(shipmentId || '', jioCode || '', reason || '')
            );
            return response.data;
        },
        onError: (error: any) => handleError(error, defaultErrorMsg),
    });
    return { toteDropQuantityDetails: data?.data, ...rest };
}

export function handleError(error: AxiosError<ErrorResponse>, defaultErrorMsg: string) {
    const errors = error.response?.data?.meta?.errors ?? defaultErrorMsg;
    if (Array.isArray(errors)) {
        errors.forEach((description) => {
            Notifications.toast.error({ title: 'Error', description });
        });
    } else if (error.response?.data?.message) {
        const description = error.response?.data?.message ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    } else {
        const description = errors ?? defaultErrorMsg;
        Notifications.toast.error({ title: 'Error', description });
    }
}
