import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Button, Input, Notifications, Text } from '@jds/core';
import { ChangeEvent, useState } from 'react';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import {
    SubmitStockTakeSchema,
    useSubmitStockTake,
} from '../../../hooks/inventory/stockTake/useSubmitStockTake';
import { useQueryClient } from '@tanstack/react-query';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'jio-code', label: 'Jio Code', type: 'text' };
export function StockTakeMainPage() {
    const navigate = useNavigate();
    const { stockTakeId, binlocation } = useParams();
    const [showSubmitStockTake, setShowSubmitStockTake] = useState(false);

    const StockTakeQuantityTillNow = Number(localStorage.getItem('StockTakeQuantityTillNow')) || 0;
    const [currentQuantity, setCurrentQuantity] = useState(1);

    const StockTakeProductsJson = localStorage.getItem('stock-take-products-list') || '[]';
    const StockTakeProducts = JSON.parse(StockTakeProductsJson);

    const stockTakeList = useQueryClient().getQueryData<any>(['stock-take-list']) || [];

    const handleInputQuantityChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        const quantity = Number(e.target.value);
        if (quantity > 0) {
            setCurrentQuantity(quantity);
        } else {
            setCurrentQuantity(0);
        }
    };

    const validateJioCode = (jioCode: string) => {
        const val = StockTakeProducts.findIndex(
            (item: { jio_code: string }) => item.jio_code === jioCode
        );
        return val;
    };

    const submitStockTake = useSubmitStockTake();
    const handleSubmitStockTake = (data: SubmitStockTakeSchema) => {
        submitStockTake.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    navigate(`/inventory/stock-take/${stockTakeId}/${binlocation}/success`);
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', '),
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    const handleNullValues = () => {
        StockTakeProducts.map(
            (info: {
                non_damaged_actual: number;
                attributes_mis_match: number;
                damaged_actual: number;
                near_expiry: number;
                wrong_item: number;
                expired: number;
                positive_variance_at_warehouse: number;
                positive_variance_on_damaged_tote: number;
                positive_variance_on_excess_tote: number;
                positive_variance_on_location: number;
                negative_variance_on_location: number;
                negative_variance_at_warehouse: number;
            }) => {
                info.non_damaged_actual = info.non_damaged_actual ?? 0;
                info.attributes_mis_match = info.attributes_mis_match ?? 0;
                info.damaged_actual = info.damaged_actual ?? 0;
                info.near_expiry = info.near_expiry ?? 0;
                info.wrong_item = info.wrong_item ?? 0;
                info.expired = info.expired ?? 0;
                info.positive_variance_at_warehouse = info.positive_variance_at_warehouse ?? 0;
                info.positive_variance_on_damaged_tote =
                    info.positive_variance_on_damaged_tote ?? 0;
                info.positive_variance_on_excess_tote = info.positive_variance_on_excess_tote ?? 0;
                info.positive_variance_on_location = info.positive_variance_on_location ?? 0;
                info.negative_variance_on_location = info.negative_variance_on_location ?? 0;
                info.negative_variance_at_warehouse = info.negative_variance_at_warehouse ?? 0;
            }
        );
    };

    return (
        <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
            <PageHeader showBackIcon title="Stock take" onBackIconClick={() => navigate(-1)} />
            <main className={cn('mx-4 mt-6')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount={true}
                    inputProps={manualInputProps}
                    onSuccess={(value) => {
                        const val = validateJioCode(value);
                        if (val !== -1) {
                            localStorage.setItem(
                                'StockTakeQuantityTillNow',
                                JSON.stringify(StockTakeQuantityTillNow + 1)
                            );
                            const queryPrams = new URLSearchParams();
                            queryPrams.append('quantity', currentQuantity.toString());
                            navigate(
                                `/inventory/stock-take/${stockTakeId}/${binlocation}/${value}/${
                                    StockTakeProducts[val].lot_id
                                }/verify-details?${queryPrams.toString()}`
                            );
                        } else {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'JioCode Not Found',
                            });
                        }
                    }}
                />
                <div className={cn('mt-12 ml-6 flex items-center justify-start gap-y-6')}>
                    <Text appearance="body-m" color="primary-grey-100">
                        Total Scanned Quantity
                    </Text>
                    <div className={cn('mr-auto ml-6 flex justify-center')}>
                        <div className={cn('flex items-center')}>
                            <Input
                                className={cn('!mt-0 max-w-[37px]')}
                                inputMode="numeric"
                                label=""
                                name="assigned-quantity"
                                type="number"
                                value={currentQuantity || ''}
                                onChange={(event) => {
                                    handleInputQuantityChange(event);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                        layoutStyles
                    )}
                >
                    <Button
                        fullWidth
                        disabled={currentQuantity < 1}
                        kind="primary"
                        size="large"
                        onClick={() => {
                            setShowSubmitStockTake(true);
                        }}
                    >
                        Submit
                    </Button>
                </div>
                {showSubmitStockTake && (
                    <InfoModalSection
                        ModalHeader={`Submit Stock take for Bin ${stockTakeId}`}
                        buttonText="Confirm"
                        infoText=""
                        infoType="WARNING"
                        showCloseIcon={true}
                        onSuccess={(value) => {
                            setShowSubmitStockTake(false);
                            if (value !== 'closed') {
                                handleNullValues();
                                const currentStocktakeData = stockTakeList[0];
                                currentStocktakeData.stock_take_products = StockTakeProducts;
                                handleSubmitStockTake(currentStocktakeData);
                            }
                        }}
                    />
                )}
            </main>
        </div>
    );
}
