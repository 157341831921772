import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';

export function useCompatibleSKUs<T extends { ean: string }[]>(storageZoneId: string, products: T) {
    const rfcId = sessionStorage.getItem('rfcId');
    const sku_list = products.map(({ ean }) => ean);
    return useQuery<T[number][], AxiosError<ErrorResponse>>({
        queryKey: ['compatible-skus', rfcId, storageZoneId, sku_list],
        queryFn: async () => {
            if (storageZoneId === '') return [];
            const response = await axios({
                url: urls.COMPATIBLE_SKUS(storageZoneId),
                headers: { sku_list },
            });
            const data = response.data as SuccessResponse;
            if (!data["Compatible Sku's"]) return [];
            return products.filter(({ ean }) => data["Compatible Sku's"].includes(ean));
        },
    });
}

interface ErrorResponse {
    meta: { errors: string };
}

interface SuccessResponse {
    "Compatible Sku's": string[];
}
