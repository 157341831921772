import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Button, Notifications } from '@jds/core';
import { layoutStyles } from '../../../styles/common';
import { useValidateTote, ValidateToteOptions } from '../../../hooks/receiving/useValidateTote';
import { useState } from 'react';
import { useToteTransfer } from '../../../hooks/inventory/stock-take-segregation/useToteTransfer';

export function ScanInventoryTote() {
    const navigate = useNavigate();
    const { dropZoneId, toteId, jioCode, lotId, inventoryType } = useParams();
    const [validateTote, dropComplete] = [useValidateTote(), useToteTransfer()];
    const [targetToteCode, setTargetToteCode] = useState('');

    const getProductType = () => {
        if (inventoryType === 'GOOD_PALLET') return 'GOOD_PALLET';
        else if (inventoryType === 'GOOD_SHELVING') return 'GOOD_SHELVING';
        else if (inventoryType === 'LIQUIDATION') return 'LIQUIDATION';
        else if (inventoryType === 'SCRAP') return 'SCRAP';
        else return 'EXPIRED';
    };

    const onScanSuccess = (tote_code: string) => {
        const data: ValidateToteOptions = {
            quantity: 1,
            tote_code,
            product_type: getProductType(),
            jio_code: jioCode ?? '',
            tote_purpose: 'STOCK_TAKE_PUT_AWAY',
            lot_id: Number(lotId) ?? 0,
            location_id: dropZoneId ?? '',
            tote_params: null,
        };
        validateTote.mutate(data, {
            onSuccess: () => setTargetToteCode(tote_code),
            onError: () => {
                Notifications.toast.error({
                    title: 'Invalid tote',
                    description: 'Please scan valid tote',
                });
            },
        });
    };

    const onDropComplete = () => {
        const sourceTote = JSON.parse(localStorage.getItem('sourceToteData') ?? '{}');
        const payload = {
            source_tote_code: toteId,
            target_tote_code: targetToteCode,
            source_jio_code: jioCode,
            target_jio_code: jioCode,
            source_lot_id: lotId,
            target_lot_id: lotId,
            location_id: dropZoneId,
            source_product_type: inventoryType,
            source_tote_purpose: 'STOCK_TAKE_PUT_AWAY',
            target_product_type: sourceTote.mapped_product_type,
            target_tote_purpose: sourceTote.tote_purpose,
            quantity: 1,
        };
        dropComplete.mutate(payload, {
            onSuccess(res) {
                localStorage.setItem('sourceToteData', JSON.stringify(res.data.source));
                localStorage.setItem('targetToteData', JSON.stringify(res.data.target));
                navigate(`./../${toteId}/scan-jiocode`);
            },
        });
    };

    return (
        <div className={cn('mt-3 max-w-screen-md md:mx-auto')}>
            <PageHeader
                showBackIcon
                title="Good Inventory Pallet Area"
                onBackIconClick={() => navigate(-1)}
            />
            <main className={cn('mx-6 mt-6')}>
                <ScanOrEnterManuallyV2
                    inputProps={{ label: 'Tote Code', name: 'tote-id' }}
                    onSuccess={(toteId) => {
                        if (toteId) onScanSuccess(toteId);
                        else {
                            Notifications.toast.error({
                                title: 'Tote ID is required',
                                description: 'Please scan or enter a valid Tote ID',
                            });
                        }
                    }}
                />
            </main>
            <div
                className={cn(
                    'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                    layoutStyles
                )}
            >
                <Button
                    fullWidth
                    disabled={!targetToteCode}
                    size="large"
                    type="button"
                    onClick={onDropComplete}
                >
                    Drop Complete
                </Button>
            </div>
        </div>
    );
}
