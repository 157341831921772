import { cn } from '../../../utils/tailwind';
import { Button, Divider, Heading, Icon, Text } from '@jds/core';
import { IcSuccess } from '@jds/core-icons';
import { ReturnItem } from '../../../components/returns/Unloading';
import { useReturnProducts } from '../../../hooks/returns/unloading/useReturnProducts';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { UnloadingContext } from '../../../provider/returns/unloading';
import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ReturnsUnloadingSuccessPage() {
    const [navigate, { appointmentNumber, shipmentId, stationId }] = [useNavigate(), useParams()];
    const { data: products, isLoading } = useReturnProducts();
    const { state, dispatch } = useContext(UnloadingContext);
    const completedProducts =
        products?.filter(({ jio_code }) => state.completedProducts.includes(jio_code)) ?? [];

    if (!shipmentId) throw new Error('Shipment ID not found in the params');

    return (
        <SuccessPageV2
            buttonText="Scan Next Airway Bill"
            message="Returns unloading completed successfully"
            onButtonClick={() => {
                dispatch({ type: 'RESET_COMPLETED_PRODUCTS' });
                dispatch({ type: 'RESET_ALL_PRODUCTS' });
                navigate(`/returns/unloading/${stationId}/${appointmentNumber}/scan-air-way-bill`);
            }}
        />
        /* Commented this as it is part of figma, will remove after confirmation */
        // <div className={cn(layoutStyles, 'm-6')}>
        //     <main className={cn('mb-20')}>
        //         <div className={cn('flex')}>
        //             <Icon color="primary" ic={<IcSuccess />} size="l" />
        //             <Heading appearance="heading-xs" className={cn('ml-2')} color="primary-50">
        //                 Returns Unloading Successful
        //             </Heading>
        //         </div>
        //         <div className={cn('mt-6')}>
        //             <div className={cn('space-y-2')}>
        //                 <Text appearance="body-s-bold">List - Items - completed</Text>
        //                 {!isLoading && completedProducts?.length === 0 && (
        //                     <Text appearance="body-s" color="primary-grey-80">
        //                         No items completed
        //                     </Text>
        //                 )}
        //             </div>
        //             <ul className={cn('mt-6 space-y-6')}>
        //                 {completedProducts?.map((product, index) => (
        //                     <li key={product.jio_code}>
        //                         <ReturnItem showCompletedIcon product={product} />
        //                         {completedProducts?.length !== index + 1 && (
        //                             <Divider className={cn('-mx-6 -mb-6')} padding="m" />
        //                         )}
        //                     </li>
        //                 ))}
        //             </ul>
        //         </div>
        //     </main>
        //     <div className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}>
        //         <Button
        //             fullWidth
        //             size="large"
        //             type="button"
        //             onClick={() => {
        //                 dispatch({ type: 'RESET_COMPLETED_PRODUCTS' });
        //                 dispatch({
        //                     type: 'SET_COMPLETED_AIRWAY_BILLS',
        //                     payload: [...state.completedAirwayBills, shipmentId],
        //                 });
        //                 navigate(`/returns/unloading/${appointmentNumber}/scan-air-way-bill`);
        //             }}
        //         >
        //             Scan Next Airway Bill
        //         </Button>
        //     </div>
        // </div>
    );
}
