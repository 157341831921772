import { Button, Container, Icon, Text } from '@jds/core';
import { useNavigate } from 'react-router-dom';
import { pageHeaderStyle } from '../../styles/common';
import { cn } from '../../utils/tailwind';
import confirmedTick from '../../assets/confirmed-tick.gif';
import { PsJioMart } from '@jds/product-logo-icons';

interface SuccessPageV2 {
    message: string;
    buttonText?: string;
    onButtonClick?: () => void;
}

export function SuccessPageV2({
    message,
    buttonText = 'Pick a New Task',
    onButtonClick,
}: SuccessPageV2) {
    const navigate = useNavigate();

    return (
        <div className={cn('mx-3 mt-4 max-w-screen-md py-3 md:mx-auto')}>
            <Container
                background="primary-grey-20"
                className={cn('mt-8 space-y-6 text-center', pageHeaderStyle)}
                pad="m"
                rounded="large"
            >
                <img
                    className={cn('absolute left-36 top-6 w-[72px] rounded-[40px]')}
                    src={confirmedTick}
                />
                <Text appearance="heading-xs" color="primary-50">
                    {message}
                </Text>
                <Button onClick={() => (onButtonClick ? onButtonClick() : navigate('/'))}>
                    {buttonText}
                </Button>
            </Container>
            <Container
                as="div"
                className={cn(
                    'absolute bottom-16 left-[6.5rem] flex flex-col items-center justify-between'
                )}
            >
                <Icon ic={<PsJioMart className={cn('opacity-40')} />} size="l" />
                <Text appearance="body-s-bold" color="primary-grey-60">
                    JioMart Warehouse
                </Text>
                <Text appearance="body-xxs" color="primary-grey-60">
                    by Bifrost
                </Text>
            </Container>
        </div>
    );
}
