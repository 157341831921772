import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input } from '@jds/core';
import { cn } from '../../utils/tailwind';

import { PageHeader } from '../../components/shared/PageHeader';
import { useUpdateGtlTaskStatus } from '../../hooks/gtl/useUpdateGtlTaskStatus';

const layoutStyles = 'max-w-screen-md md:mx-auto';

const SelectGtlPutawayLocation = () => {
    const [radioValue, setRadioValue] = useState('');
    const navigate = useNavigate();
    const { locationId } = useParams();
    const updateTaskStatus = useUpdateGtlTaskStatus();
    const taskId = JSON.parse(localStorage.getItem('gtlTaskId') || '');

    const onSelectDropzone = () => {
        updateTaskStatus.mutate(
            {
                taskId: taskId,
                status: 'DROP_IN_PROGRESS',
            },
            {
                onSuccess: () => {
                    navigate(`../${locationId}/scan-drop-zone`);
                },
            }
        );
    };
    return (
        <>
            <div className={cn(layoutStyles, 'mx-3 py-3')}>
                <PageHeader
                    title="Putaway location"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />

                <main className={cn('mx-2 mt-12 space-y-8')}>
                    <Input
                        id="dropZone"
                        label="Drop Zone"
                        name="dropZone"
                        type="radio"
                        onChange={() => {
                            setRadioValue('dropZone');
                        }}
                    />
                    <Input
                        id="putaway"
                        label="Putaway"
                        name="dropZone"
                        type="radio"
                        onChange={() => {
                            setRadioValue('putaway');
                        }}
                    />
                    <div className={cn('fixed inset-x-2 bottom-8 mx-4 space-y-2')}>
                        <Button
                            fullWidth
                            disabled={!radioValue}
                            kind="primary"
                            onClick={() => {
                                if (radioValue == 'dropZone') {
                                    onSelectDropzone();
                                } else {
                                    navigate(`../${locationId}/scan-gtl-putaway-tote`);
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </main>
            </div>
        </>
    );
};

export default SelectGtlPutawayLocation;
