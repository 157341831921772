import { cn } from '../../utils/tailwind';
import { Modal, Text, Heading, Icon, Container } from '@jds/core';
import { useRef, useState } from 'react';
import { IcErrorColored, IcInfo, IcSuccessColored, IcWarningColored } from '@jds/core-icons';

interface InfoModalSectionProps {
    /**
     * Callback function that will be called onsuccess
     */
    onSuccess: (value: string) => void;
    /**
     * Header for the modal that will be displayed on the top of the modal
     */
    ModalHeader: string;
    /**
     * Information text for the modal that will be displayed as part of children of the modal
     */
    infoText: string;
    /**
     * Information Type for the modal that will be display the icon based on its value
     */
    infoType: string;
    /**
     * Button Text that will be displayed on the button of the modal
     */
    buttonText: string;
    /**
     * Boolean to make sure the existence of close icon on the top right corner of the modal
     */
    showCloseIcon: boolean;
    /**
     * Boolean to make sure the existence of  modal
     */
    showModalBool?: boolean;
    /**
     * Boolean to make sure the existence of  modal
     */
    isToteInfo?: boolean;
}

export function InfoModalSection({
    infoText,
    ModalHeader,
    infoType,
    showCloseIcon,
    buttonText,
    onSuccess,
    showModalBool,
    isToteInfo = false,
}: InfoModalSectionProps) {
    const [showModal, setShowModal] = useState(true);
    const modalInputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            {showModal && (
                <Modal
                    closed={!showModal}
                    isClosable={showCloseIcon}
                    primaryCTA={{
                        title: buttonText,
                        onClick: () => {
                            const value = modalInputRef.current?.value ?? '';
                            onSuccess(value);
                            if (!showModalBool) setShowModal(false);
                        },
                    }}
                    onCloseCallback={() => {
                        onSuccess('closed');
                        if (!showModalBool) setShowModal(false);
                    }}
                >
                    <span className={cn('flex justify-center')}>
                        {infoType === 'ERROR' && <Icon ic={<IcErrorColored />} size="xxl" />}
                        {infoType === 'SUCCESS' && <Icon ic={<IcSuccessColored />} size="xxl" />}
                        {infoType === 'WARNING' && <Icon ic={<IcWarningColored />} size="xxl" />}
                    </span>
                    <Heading
                        appearance="heading-xs"
                        className={cn('flex justify-center text-center')}
                    >
                        {ModalHeader}
                    </Heading>
                    {infoText && <Text>{infoText}</Text>}
                    {isToteInfo && (
                        <Container className={cn(' mt-6 flex justify-center')}>
                            <Icon color="grey-80" ic={<IcInfo />} size="s" />
                            <Text
                                appearance="body-xxs"
                                className={cn('mx-2 flex underline')}
                                color="primary-grey-80"
                            >
                                Reasons of incompatibility
                            </Text>
                        </Container>
                    )}
                </Modal>
            )}
        </>
    );
}
