import { createContext, Dispatch, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import { PackingAction, packingReducer, PackingState } from './PackingReducer';

export const initialPackingState: PackingState = {
    packingOrderList: [],
    scannedOrderList: [],
    packingOrderInfo: [],
    PackingDamagedJioCodesTillNow: [],
    PackingFailedJioCodesTillNow: {},
    PackingOrderTotalQuantity: 0,
    PackingOrderQuantityTillNow: 0,
    PackingToteQuantityTillNow: 0,
    isToteQuantityCompleted: false,
    isOrderQuantityCompleted: false,
};

interface PackingContextType {
    state: PackingState;
    dispatch: Dispatch<PackingAction>;
}

export const PackingContext = createContext<PackingContextType>({
    state: initialPackingState,
    dispatch: () => {},
});

export function PackingProvider() {
    const [state, dispatch] = useReducer(packingReducer, initialPackingState);

    return (
        <PackingContext.Provider value={{ state, dispatch }}>
            <Outlet />
        </PackingContext.Provider>
    );
}
