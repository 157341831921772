import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Container, Heading, Icon, Modal, Notifications, Text } from '@jds/core';
import { IcArrowNext } from '@jds/core-icons';
import { useReceivingStationDrop } from '../../../hooks/cubiscan/useReceivingStationDrop';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { IcOrder } from '@jds/extended-icons';

const manualInputProps = {
    name: 'receiving-station',
    label: 'Receiving Station',
    type: 'text',
};

export function ScanReceivingStation() {
    const navigate = useNavigate();
    const { toteId } = useParams();
    const receivingStationDrop = useReceivingStationDrop();
    function onScanSuccess(stationId: string) {
        receivingStationDrop.mutate(stationId, { onSuccess: () => navigate('./../../success') });
    }

    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Receiving Station"
                    onBackIconClick={() => navigate(-1)}
                />
                <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        enableScannerOnMount
                        autoSuggest={true}
                        inputProps={manualInputProps}
                        type="RECEIVING_STATION"
                        onSuccess={onScanSuccess}
                    />
                </div>
            </div>
            <Container
                className={cn(
                    'my-4 mx-6 grid grid-cols-2 space-x-3 rounded-lg border border-[var(--color-primary-60)] p-4'
                )}
            >
                <Icon className={cn('absolute left-4 bg-white')} ic={<IcOrder color="#000093" />} />
                <Text appearance="body-s"> Tote ID </Text>
                <Text appearance="body-s-bold"> {toteId} </Text>
            </Container>
            <Modal
                closed={false}
                header={{
                    top: (
                        <div className={cn('text-center')}>
                            <Icon
                                color="primary"
                                ic={<IcArrowNext />}
                                kind="background-bold"
                                size="xxl"
                            />
                            <Heading appearance="heading-xs">
                                Go to nearest receiving station
                            </Heading>
                        </div>
                    ),
                }}
            />
        </>
    );
}
