import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Icon, Input, InputDate, Space, Spinner, Text } from '@jds/core';
import { cn } from '../../../utils/tailwind';
import { ProductImage } from '../../../components/receiving/ProductImage';
import { useReturnProducts } from '../../../hooks/returns/unloading/useReturnProducts';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { useState } from 'react';
import { ProductDetailsHeader } from '../../../components/receiving/ProductDetailsHeader';
import { IcKiranaStore } from '@jds/extended-icons';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ProductDetailsPage() {
    const [navigate, { jioCode }] = [useNavigate(), useParams()];
    const { data: products, isLoading } = useReturnProducts();
    const [isEditMode, setIsEditMode] = useState(false);
    const product = products?.find(({ jio_code }) => jio_code === jioCode);
    localStorage.removeItem('returns_receiving_state');
    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="EANs"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <div className={cn('mx-7 mt-3 pb-3')}>
                    <ProductDetailsHeader
                        enableEditMode={false}
                        isEditMode={isEditMode}
                        isLoading={false}
                        setIsEditMode={setIsEditMode}
                    />
                </div>
                {!isEditMode && (
                    <Container
                        className={cn(
                            'm-1 mx-5 grid grid-cols-2 space-x-4 rounded-lg border border-[var(--color-primary-60)] p-3'
                        )}
                    >
                        <Icon
                            className={cn('bg-radial-gradient absolute left-2')}
                            ic={<IcKiranaStore color="#000093" />}
                        />
                        <Text appearance="body-s"> EAN </Text>
                        <Text appearance="body-s-bold" className={cn('flex justify-center')}>
                            {product?.ean}
                        </Text>
                    </Container>
                )}
                <Space value="m" />
            </div>
            <div className={cn('mx-6 mb-28 mt-2')}>
                {isEditMode && (
                    <fieldset disabled={!isEditMode}>
                        <Container as="div" background="primary-grey-20" pad="base" rounded="large">
                            <Input
                                disabled={isEditMode}
                                label="EAN"
                                name="ean"
                                placeholder="Enter EAN"
                                type="text"
                            />
                        </Container>
                    </fieldset>
                )}
                <Space value="m" />
                <ProductImage
                    image={product?.image_urls?.[0] ?? 'https://via.placeholder.com/88'}
                />
                <fieldset disabled={true}>
                    <Space value="m" />
                    <Input
                        label="Description"
                        name="description"
                        placeholder="Enter Description"
                        type="text"
                        value={product?.description}
                    />
                    <Space value="m" />
                    <Input
                        label="MRP"
                        name="mrp"
                        placeholder="Enter MRP"
                        type="text"
                        value={product?.mrp}
                    />
                    <Space value="m" />
                    <InputDate
                        label="MFD Date"
                        placeholder="Enter MFD Date"
                        value={product ? new Date(product.mfg) : undefined}
                    />
                    <Space value="m" />
                    <InputDate
                        label="Best Before"
                        placeholder="Enter BBD Date"
                        value={product ? new Date(product.expiry) : undefined}
                    />
                    <Space value="m" />
                    <Input
                        label="Color"
                        name="color"
                        placeholder="Enter color of the product"
                        type="text"
                        value={product?.color}
                    />
                    <Space value="m" />
                    <Input
                        label="Size"
                        name="size"
                        placeholder="Enter size of the product"
                        type="text"
                        value={product?.size}
                    />
                    <Input
                        label="Expected Quantity"
                        name="expectedQuantity"
                        placeholder="Enter Expected Quantity of the product"
                        type="text"
                        value={product?.expectedQuantity}
                    />
                </fieldset>
            </div>
            <div
                className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    size="large"
                    type="button"
                    onClick={() => navigate('./../verify-quantities')}
                >
                    Mark Quantity
                </Button>
            </div>
            {isLoading && (
                <div
                    className={cn(
                        'flex items-center justify-center',
                        'absolute inset-0 h-full min-h-screen bg-gray-200/50'
                    )}
                >
                    <Spinner />
                </div>
            )}
        </div>
    );
}
