import { cn } from '../../../utils/tailwind';
import { useNavigate } from 'react-router-dom';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Button, Container, Divider, Notifications, Spinner, Text } from '@jds/core';
import { useReturnProducts } from '../../../hooks/returns/unloading/useReturnProducts';
import { ReturnItem } from '../../../components/returns/Unloading';
import { useContext, useEffect, useState } from 'react';
import { UnloadingContext } from '../../../provider/returns/unloading';
import { useReturnsUnloading } from '../../../hooks/returns/unloading/useReturnsUnloading';
import { pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { InfoModalSection } from '../../../components/putaway/InfoModal';
import { useMarkShortProducts } from '../../../hooks/returns/unloading/useMarkShortProducts';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ProductsToReturnPage() {
    const [markShort, setMarkShort] = useState(false);
    const navigate = useNavigate();
    const returnsUnloading = useReturnsUnloading();
    const markShortProducts = useMarkShortProducts();
    const { dispatch, state } = useContext(UnloadingContext);
    const completedJioCodes = state.completedProducts;
    const { data: products, isLoading } = useReturnProducts();
    const uncompletedProducts =
        products?.filter(({ jio_code }) => !completedJioCodes.includes(jio_code)) ?? [];
    const eanKeys = uncompletedProducts?.map((item) => ({
        result: item.ean,
    }));

    function onScanSuccess(ean: string) {
        const isCorrectEAN = products?.some((product) => product.ean === ean);
        if (isCorrectEAN) {
            dispatch({ type: 'SET_ALL_PRODUCTS', payload: products ? [...products] : [] });
            navigate(`./../${ean}/verify-quantity`);
        } else {
            Notifications.toast.error({
                title: 'Error',
                description: 'Please scan the correct EAN',
            });
        }
    }

    const handleMarkShort = () => {
        setMarkShort(false);
        markShortProducts.mutate(undefined, {
            onSuccess: () => {
                setMarkShort(false);
                navigate('./../returns-unloading-success');
            },
        });
    };

    useEffect(() => {
        if (products?.length && !uncompletedProducts.length) {
            navigate('./../returns-unloading-success');
        }
    }, [navigate, products, uncompletedProducts.length]);

    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Returns Unloading"
                    onBackIconClick={() => navigate(-1)}
                />
                <div className={cn('mx-3 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        enableScannerOnMount
                        autoSuggest={true}
                        inputProps={{
                            label: 'EAN',
                            name: 'ean',
                            disabled: uncompletedProducts?.length === 0,
                        }}
                        searchableList={eanKeys}
                        onSuccess={onScanSuccess}
                    />
                </div>
            </div>
            {isLoading && (
                <div className={cn('flex justify-center')}>
                    <Spinner size="small" />
                </div>
            )}
            {!!uncompletedProducts.length && (
                <Container
                    background="primary-grey-20"
                    className={cn('mx-3 mb-20 mt-6 space-y-6')}
                    pad="m"
                    rounded="large"
                >
                    <div className={cn('space-y-2')}>
                        <Text appearance="body-s-bold">
                            Return ID - Items Pending {`(${uncompletedProducts.length})`}
                        </Text>
                    </div>
                    {uncompletedProducts?.map((product, index) => (
                        <div key={product.jio_code}>
                            <ReturnItem product={product} />
                            {uncompletedProducts?.length !== index + 1 && (
                                <Divider className={cn('-mx-6 -mb-6')} padding="m" />
                            )}
                        </div>
                    ))}
                </Container>
            )}
            <div className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}>
                <Button
                    fullWidth
                    isLoading={returnsUnloading.isLoading}
                    kind={uncompletedProducts.length ? 'secondary' : 'primary'}
                    size="large"
                    type="button"
                    onClick={() => {
                        if (uncompletedProducts.length > 0) setMarkShort(true);
                    }}
                >
                    Mark Short
                </Button>
            </div>
            {markShort && (
                <InfoModalSection
                    showCloseIcon
                    ModalHeader={`Mark ${uncompletedProducts.length} EAN short ?`}
                    buttonText="Confirm"
                    infoText=""
                    infoType="WARNING"
                    onSuccess={handleMarkShort}
                />
            )}
        </>
    );
}
