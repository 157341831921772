import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { gtlTaskListProps } from '../../components/gtl/GtlTaskItem';

import { urls } from '../../utils/urls';
import { ErrorResponse, handleError, SuccessResponse } from './useGetGtlTaskList';

interface UpdateGtlShortDamageItemStatusSchema {
    damagedQuantity: number;
    shortQuantity: number;
}

const defaultErrorMsg = 'Something went wrong.';
export function useUpdateGtlShortDamageItemStatus() {
    return useMutation<
        SuccessResponse,
        AxiosError<ErrorResponse>,
        UpdateGtlShortDamageItemStatusSchema
    >(
        async (body: UpdateGtlShortDamageItemStatusSchema) => {
            const response = await axios.patch(urls.UPDATE_GTL_SHORT_DAMAGE_ITEMS('1'), body);
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
