import { Text } from '@jds/core';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';

import { PageHeader } from '../../../components/shared/PageHeader';
import {
    PicklistLocationItemProps,
    PickListLocationResponse,
} from '../../../hooks/outbound/picklist/useGetPicklistLocation';

const layoutStyles = 'max-w-screen-md md:mx-auto';

const PickListStatus = () => {
    const navigate = useNavigate();

    const pickListLocationsData = useQueryClient().getQueryData<PickListLocationResponse>([
        'pickListLocationsData',
    ]);
    const pickingLocationsList = pickListLocationsData?.data?.item_details;
    const pickedJioCodeIndex = Number(localStorage.getItem('pickedJioCodeIndex'));
    const scannedItemQuantityTillNow = Number(localStorage.getItem('scannedItemQuantityTillNow'));
    const pickedJioCodesList = JSON.parse(localStorage.getItem('pickedJioCodesObjList') || '[]');

    const getTotalQuantity = (currentJioCodedata: any) => {
        const output = pickedJioCodesList.find(
            (item: any) =>
                item.id === currentJioCodedata.order_id &&
                item.jio_code === currentJioCodedata.jio_code
        );
        return output?.total_quantity ?? 0;
    };

    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <PageHeader
                title="Picking list status"
                onBackIconClick={() => {
                    navigate(-1);
                }}
            />
            <main className={cn('mt-3')}>
                <div className={cn('m-6 flex')}>
                    <Text appearance="body-m-bold" color="primary-grey-100">
                        {`JIO CODE - `}
                    </Text>
                    <Text appearance="body-m-bold" color="primary-grey-80">
                        Picked
                    </Text>
                </div>
                {pickingLocationsList &&
                    pickingLocationsList
                        .slice(0, pickedJioCodeIndex)
                        .map((item: PicklistLocationItemProps) => {
                            return (
                                <div
                                    key={item?.jio_code}
                                    className={cn('m-6 flex justify-between')}
                                >
                                    <Text appearance="body-m" color="primary-grey-100">
                                        {item?.jio_code}
                                    </Text>
                                    <Text appearance="body-m" color="primary-grey-100">
                                        {`${getTotalQuantity(item)}/${item?.quantity}`}
                                    </Text>
                                </div>
                            );
                        })}
                <div className={cn('m-6 flex')}>
                    <Text appearance="body-m-bold" color="primary-grey-100">
                        {`JIO CODE - `}
                    </Text>
                    <Text appearance="body-m-bold" color="primary-grey-80">
                        Incomplete
                    </Text>
                </div>

                {pickingLocationsList &&
                    pickingLocationsList
                        .slice(pickedJioCodeIndex)
                        .map((item: PicklistLocationItemProps, index) => {
                            return (
                                <div
                                    key={item?.jio_code}
                                    className={cn('m-6 flex justify-between')}
                                >
                                    <Text appearance="body-m" color="primary-grey-100">
                                        {item?.jio_code}
                                    </Text>
                                    <Text appearance="body-m" color="primary-grey-100">
                                        {`${
                                            index === 0
                                                ? scannedItemQuantityTillNow
                                                : getTotalQuantity(item)
                                        }/${item?.quantity}`}
                                    </Text>
                                </div>
                            );
                        })}
            </main>
        </div>
    );
};

export default PickListStatus;
