import { ComponentPropsWithoutRef, RefObject } from 'react';
import { InputDate } from '@jds/core';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type JDSDateInputProps = ComponentPropsWithoutRef<typeof InputDate>;
interface ControlledDateInputProps<T extends FieldValues> extends JDSDateInputProps {
    control: Control<T>;
    name: Path<T>;
    editMode?: boolean;
}

export function ControlledDateInput<T extends FieldValues>({
    control,
    name,
    editMode = false,
    ...inputProps
}: ControlledDateInputProps<T>) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <InputDate
                    error={!!error}
                    errorText={error?.message}
                    forwardRef={ref as unknown as RefObject<Date>}
                    {...inputProps}
                    {...field}
                    datePicker={editMode}
                />
            )}
        />
    );
}
