import React from 'react';
import { RoutesManager } from './components/shared/RoutesManager';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Notifications } from '@jds/core';
import { applyDefaultHeaders } from './utils/fetchHelper';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();
applyDefaultHeaders();
export function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <Notifications.Container />
            <RoutesManager />
        </QueryClientProvider>
    );
}
