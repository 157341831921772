import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoginPage } from '../../../pages/LoginPage';
import { HomePage } from '../../../pages/HomePage';
import { RequireAuth } from '../../auth/RequireAuth';
import { NotFoundPage } from '../../../pages/NotFoundPage';
import { ReceivingProvider as InboundReceivingProvider } from '../../../provider/inbound/receiving';
import { UnloadingProvider as ReturnsUnloadingProvider } from '../../../provider/returns/unloading';
import { ReceivingProvider as ReturnsReceivingProvider } from '../../../provider/returns/receiving';
import { ReceivingRoutes } from './inbound/ReceivingRoutes';
import { UnloadingRoutes } from './inbound/UnloadingRoutes';
import { PicklistRoutes } from './outbound/PicklistRoutes';
import { GtlRoutes } from './GtlRoutes';
import { ConsolidationRoutes } from './outbound/ConsolidationRoutes';
import { CubiscanToReceivingRoutes } from './inbound/CubiscanToReceivingRoutes';
import { CubiscanRoutes } from './inbound/CubiscanRoutes';
import { UnloadingRoutes as ReturnsUnloadingRoutes } from './returns/UnloadingRoutes';
import { ReturnsPutawayRoutes } from './ReturnsPutawayRoutes';
import { PackingRoutes } from './outbound/PackingRoutes';
import { ReceivingRoutes as ReturnsReceivingRoutes } from './returns/ReceivingRoutes';
import { StockTakeRoutes } from './inventory/StockTakeRoutes';
import { SubSectionsPage } from '../../../pages/SubSectionsPage';
import { StockTakeSegregationRoutes } from './inventory/StockTakeSegregationRoutes';
import { CubiscanProvider } from '../../../provider/inbound/cubiscan/CubiscanProvider';
import { PicklistProvider } from '../../../provider/outbound/picklist';
import { ConsolidationProvider } from '../../../provider/outbound/consolidation';
import { PackingProvider } from '../../../provider/outbound/packing';

export function RoutesManager() {
    return (
        <BrowserRouter>
            <Routes>
                {/*Just to make sure that a logged-in user can't access the login page*/}
                <Route element={<RequireAuth shouldUserBeLoggedIn={false} />}>
                    <Route element={<LoginPage />} path="/login" />
                </Route>
                <Route element={<RequireAuth />}>
                    <Route element={<HomePage />} path="" />
                    <Route element={<SubSectionsPage />} path="sections/:module" />
                    <Route path="/unloading">{UnloadingRoutes}</Route>
                    <Route path="/inbound">
                        <Route element={<InboundReceivingProvider />} path="receiving">
                            {ReceivingRoutes}
                        </Route>
                        <Route path="putaway">{ReturnsPutawayRoutes}</Route>
                    </Route>
                    <Route element={<CubiscanProvider />} path="/cubiscan">
                        {CubiscanRoutes}
                    </Route>
                    <Route path="/cubiscan-to-receiving-station">{CubiscanToReceivingRoutes}</Route>
                    <Route path="/outbound">
                        <Route element={<PicklistProvider />} path="picklist">
                            {PicklistRoutes}
                        </Route>
                        <Route element={<ConsolidationProvider />} path="consolidation">
                            {ConsolidationRoutes}
                        </Route>
                        <Route element={<PackingProvider />} path="packing">
                            {PackingRoutes}
                        </Route>
                    </Route>
                    <Route path="/returns">
                        <Route element={<ReturnsUnloadingProvider />} path="unloading">
                            {ReturnsUnloadingRoutes}
                        </Route>
                        <Route element={<ReturnsReceivingProvider />} path="receiving">
                            {ReturnsReceivingRoutes}
                        </Route>
                        <Route path="putaway">{ReturnsPutawayRoutes}</Route>
                    </Route>
                    <Route path="/inventory">
                        <Route path="stock-take">{StockTakeRoutes}</Route>
                        <Route path="stock-take-putaway">{ReturnsPutawayRoutes}</Route>
                        <Route path="gtl-putaway">{ReturnsPutawayRoutes}</Route>
                        <Route path="gtl">{GtlRoutes}</Route>
                        <Route path="stock-take-segregation">{StockTakeSegregationRoutes}</Route>
                    </Route>
                </Route>
                <Route element={<NotFoundPage />} path="*" />
            </Routes>
        </BrowserRouter>
    );
}
