import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { urls } from '../../../utils/urls';
import { ErrorResponse, handleError, SuccessResponse } from './useGetPickList';

export interface MarkShortItemSchema {
    cancelled_quantity: number;
    cancellation_reason: string;
    order_id: string;
    jio_code: string;
}

const defaultErrorMsg = 'Something went wrong.';
export function useMarkShortItem() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, MarkShortItemSchema[]>(
        async (body: MarkShortItemSchema[]) => {
            const response = await axios.post(urls.MARK_SHORT_PICKLIST_ITEM(), body, {
                headers: {
                    rfcCode: sessionStorage.getItem('rfc-code'),
                },
            });
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
