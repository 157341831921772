import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Container, Icon, Input, Text, debounce } from '@jds/core';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { cn } from '../../../utils/tailwind';
import { useFetchPackages } from '../../../hooks/receiving/useFetchPackages';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { IcKiranaStore } from '@jds/extended-icons';
import { urls } from '../../../utils/urls';

const appointmentSchema = yup.object({
    appointmentNumber: yup
        .number()
        .required('Appointment Number is required')
        .typeError('Appointment Number should be numeric'),
});

export type AppointmentSchema = yup.InferType<typeof appointmentSchema>;

export function withForwardRef({ ref, ...fieldAttributes }: UseFormRegisterReturn) {
    return { ...fieldAttributes, forwardRef: ref as unknown as RefObject<HTMLElement> };
}
export function EnterAppointmentNumberPage() {
    const navigate = useNavigate();
    const { stationId } = useParams();
    const fetchPackages = useFetchPackages();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<AppointmentSchema>({
        resolver: yupResolver(appointmentSchema),
    });

    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(true);

    const getAutoSuggestions = (value: string) => {
        fetch(urls.GET_INBOUND_AUTO_SUGGESTIONS('APPOINTMENT_ID', value, 'INBOUND_RECEIVING'), {
            headers: {
                rfc_code: sessionStorage.getItem('rfc-code') ?? 'SKAB',
            },
        })
            .then((res) => res.json())
            .then((data: any) => {
                if (data?.data) {
                    const resultKeys = data.data.suggestions
                        .slice(0, 5)
                        .map((item: { id: string }) => ({
                            result: item.id,
                        }));
                    setSuggestions([...resultKeys]);
                }
            });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optimizedSearch = useCallback(debounce(getAutoSuggestions, 500), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeValue = (value: number) => {
        if (value) {
            optimizedSearch(value);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const onSubmit = ({ appointmentNumber }: AppointmentSchema) => {
        fetchPackages.mutate(appointmentNumber, {
            onSuccess: (res) => {
                if (res.data?.length === 1 && res.data?.[0].boxLabel === null)
                    navigate(`${appointmentNumber}/-1`);
                else navigate(`${appointmentNumber}`);
            },
        });
    };

    useEffect(() => {
        const subscription = watch(({ appointmentNumber }) => {
            onChangeValue(appointmentNumber || 0);
        });
        return () => subscription.unsubscribe();
    }, [onChangeValue, watch]);

    return (
        <>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Appointment Number"
                    onBackIconClick={() => navigate(-1)}
                />
                <div className={cn('max-w-screen-md px-9 py-5 md:mx-auto')}>
                    <form id="application-form" onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            aria-invalid={errors.appointmentNumber ? 'true' : 'false'}
                            aria-required="true"
                            autoComplete="appointmentNumber"
                            color="primary"
                            inputMode="numeric"
                            label="Enter Appointment Number"
                            state={errors.appointmentNumber ? 'error' : undefined}
                            stateConfig={{
                                errorText: errors.appointmentNumber?.message,
                            }}
                            {...withForwardRef(register('appointmentNumber'))}
                        />
                    </form>
                    {showSuggestions &&
                        suggestions?.map((suggestion: any) => (
                            <Container
                                key={suggestion.result}
                                className={cn(
                                    'my-2 flex border-b border-solid border-[#D1D1ED] p-4'
                                )}
                                onClick={() => {
                                    setShowSuggestions(false);
                                    onSubmit({ appointmentNumber: suggestion.result });
                                }}
                            >
                                <Text color="primary-30">{suggestion.result} </Text>
                            </Container>
                        ))}
                </div>
            </div>
            <Container
                className={cn(
                    'm-4 grid grid-cols-2 space-x-4 rounded-lg border-2 border-[var(--color-primary-60)] p-4'
                )}
            >
                <Icon
                    className={cn('absolute left-2 bg-white')}
                    ic={<IcKiranaStore color="#000093" />}
                />
                <Text appearance="body-s"> Station ID </Text>
                <Text appearance="body-s-bold"> {stationId} </Text>
            </Container>
            <span
                className={cn('fixed inset-x-2 bottom-4')}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button fullWidth form="application-form" type="submit">
                    Submit
                </Button>
            </span>
        </>
    );
}
