import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { useContext, useEffect } from 'react';
import { ReceivingContext } from '../../../provider/returns/receiving';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useFetchUserReturnsReceivingState } from '../../../hooks/returns/receiving/useFetchUserReceivingState';

const manualInputProps = { name: 'station-id', label: 'Station ID', type: 'text' };
export function ScanStationIdPage() {
    const navigate = useNavigate();
    const { dispatch } = useContext(ReceivingContext);
    localStorage.removeItem('EAN_SCANNED_LIST');

    const { returnsReceivingStateDetails } = useFetchUserReturnsReceivingState();

    const validateInventory = useInventoryDetails();
    const onScanSuccess = (stationId: string) => {
        validateInventory.mutate(
            { inventoryCode: stationId, type: 'RECEIVING_STATION' },
            {
                onSuccess: () => navigate(`${stationId}/scan-airway-bill`),
            }
        );
    };

    useEffect(() => {
        if (returnsReceivingStateDetails === undefined) return;
        if (returnsReceivingStateDetails) {
            const updatedFormValues = {
                goodQuantity: 0,
                liquidationQuantity: 0,
                scrapQuantity: 0,
            };
            returnsReceivingStateDetails.quantities_list_by_zone.map((value) => {
                if (value.toteDropProductType === 'GOOD') {
                    updatedFormValues.goodQuantity = value.quantity;
                } else if (value.toteDropProductType === 'LIQUIDATION') {
                    updatedFormValues.liquidationQuantity = value.quantity;
                } else if (value.toteDropProductType === 'SCRAP') {
                    updatedFormValues.scrapQuantity = value.quantity;
                }
            });
            dispatch({ type: 'SET_QUANTITY_STATUS', payload: updatedFormValues });
        }
        const defaultPathname = `${returnsReceivingStateDetails?.station_id}/${returnsReceivingStateDetails?.return_order_id}/${returnsReceivingStateDetails?.jio_code}`;
        switch (returnsReceivingStateDetails?.return_receiving_status) {
            case 'RETURN_QC_DONE':
                localStorage.setItem('returns_receiving_state', 'QC_COMPLETED');
                navigate(defaultPathname + '/verify-quantities');
                break;
            case 'LABELS_PRINTING_DONE':
                localStorage.removeItem('returns_receiving_state');
                navigate(defaultPathname + '/drop-list');
                break;
            default:
                navigate('');
        }
    }, [dispatch, navigate, returnsReceivingStateDetails]);

    return (
        <div>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Station ID"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />

                <main className={cn('mx-3 mt-4 max-w-screen-md py-3 md:mx-auto')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        type="RECEIVING_STATION"
                        onSuccess={onScanSuccess}
                    />
                </main>
            </div>
        </div>
    );
}
