import { Route } from 'react-router-dom';
import { DamagedItemTotePage } from '../../../../pages/inventory/stocktake/DamagedItemTotePage';
import { ExcessItemTotePage } from '../../../../pages/inventory/stocktake/ExcessItemTotePage';
import { StockTakeHomePage } from '../../../../pages/inventory/stocktake/StockTakeHomepage';
import { StockTakeListPage } from '../../../../pages/inventory/stocktake/StockTakeListPage';
import { StockTakeMainPage } from '../../../../pages/inventory/stocktake/StockTakeMainPage';
import { StockTakeSuccessPage } from '../../../../pages/inventory/stocktake/StockTakeSuccessPage';
import { StockTakeVerifyDetailsPage } from '../../../../pages/inventory/stocktake/StockTakeVerifyDetailsPage';
import { UnsaleableItemPage } from '../../../../pages/inventory/stocktake/UnsaleableItemPage';

export const StockTakeRoutes = [
    <Route key="1" element={<StockTakeListPage />} path="" />,
    <Route
        key="2"
        element={<StockTakeHomePage />}
        path=":stockTakeId/:binlocation/scan-bin-location"
    />,
    <Route key="3" element={<StockTakeMainPage />} path=":stockTakeId/:binlocation/main" />,
    <Route
        key="4"
        element={<StockTakeVerifyDetailsPage />}
        path=":stockTakeId/:binlocation/:jioCode/:lotId/verify-details"
    />,
    <Route
        key="5"
        element={<UnsaleableItemPage />}
        path=":stockTakeId/:binlocation/:jioCode/:lotId/unsaleable-item"
    />,
    <Route
        key="6"
        element={<DamagedItemTotePage />}
        path=":stockTakeId/:binlocation/:jioCode/:lotId/damaged-expired-items"
    />,
    <Route
        key="7"
        element={<ExcessItemTotePage />}
        path=":stockTakeId/:binlocation/:jioCode/:lotId/excess-items"
    />,
    <Route key="8" element={<StockTakeSuccessPage />} path=":stockTakeId/:binlocation/success" />,
];
