import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { ResponseType } from '../../../types/fetch';
import { PrintBody } from './usePrintLabels';
import { Notifications } from '@jds/core';

export function useReturnsReceiving() {
    return useMutation<unknown, AxiosError<ResponseType<unknown>>, PrintBody>({
        mutationFn: async (body) => {
            const response = await axios.post(urls.RETURNS_RECEIVING(), body);
            return response.data;
        },
        onError: (error) => {
            const defaultMessage = 'Failed to perform receiving. Please try again';
            const messages = error?.response?.data?.meta?.errors ?? [defaultMessage];
            messages.forEach((description) => {
                Notifications.toast.error({ title: 'Error', description });
            });
        },
    });
}
