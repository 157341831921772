import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, Text } from '@jds/core';
import { Fragment } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useGetStockTakeList } from '../../../hooks/inventory/stockTake/useStockTakeList';

const layoutStyles = 'max-w-screen-md md:mx-auto';

export interface StockTakelistSchema {
    stock_take_id: number;
    created_date_time: string;
    start_date_time: string;
    time_elapsed: number;
    status: string;
    completed_at: string;
    location_id: string;
    storage_zone_id: string;
    operator: string;
    stock_take_reason: string;
    stock_take_type: string;
    rfc_id: number;
    storage_area: number;
    stock_take_products: StockTakeSchema[];
}
export interface StockTakeSchema {
    stock_take_id: number;
    jio_code: string;
    lot_id: number;
    sku: string;
    ideal_items: number;
    non_damaged_actual: number;
    damaged_actual: number;
    wrong_item: number;
    excess_jio_code: number;
    attributes_mis_match: number;
}

export function StockTakeListPage() {
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    localStorage.clear();

    const { stocktakeList, isLoading, isError } = useGetStockTakeList();

    const handleStartStockTake = () => {
        localStorage.setItem(
            'stock-take-products-list',
            JSON.stringify(stocktakeList[0]?.stock_take_products)
        );
        queryClient.setQueryData(['stock-take-list'], stocktakeList);
        navigate(
            `/inventory/stock-take/${stocktakeList[0]?.stock_take_id}/${stocktakeList[0]?.location_id}/scan-bin-location`
        );
    };

    return (
        <div className={cn('mt-3 max-w-screen-md md:mx-auto')}>
            <PageHeader showBackIcon title="Stock take" onBackIconClick={() => navigate(-1)} />
            <div className={cn('mx-2 mt-6')}>
                {!isLoading && !isError && stocktakeList?.length === 0 && (
                    <Text
                        appearance="body-m"
                        className={cn('flex items-center justify-center pt-20')}
                    >
                        No Stocktake is available.
                    </Text>
                )}
                {isLoading && (
                    <div className={cn('flex items-center justify-center')}>
                        <Spinner size="small" />
                    </div>
                )}
                {!isLoading && !isError && stocktakeList?.length > 0 && (
                    <main className={cn('mx-3 grid grid-cols-[1fr_1fr] gap-y-6 text-center')}>
                        <Text appearance="body-m" color="primary-grey-80">
                            List Stock take IDs
                        </Text>
                        <div className={cn('text-center')}>
                            <Text appearance="body-m" color="primary-grey-80">
                                Bin location
                            </Text>
                        </div>

                        {stocktakeList?.map(({ stock_take_id, location_id }, index) => (
                            <Fragment key={stock_take_id}>
                                <td className={cn(index == 0 && 'bg-slate-100')}>
                                    <Text
                                        appearance="body-m"
                                        color={index === 0 ? 'primary-grey-100' : 'primary-grey-60'}
                                    >
                                        {stock_take_id}
                                    </Text>
                                </td>
                                <td className={cn(index == 0 && 'bg-slate-100')}>
                                    <div className={cn('text-center')}>
                                        <Text
                                            appearance="body-m"
                                            color={
                                                index === 0 ? 'primary-grey-100' : 'primary-grey-60'
                                            }
                                        >
                                            {location_id}
                                        </Text>
                                    </div>
                                </td>
                            </Fragment>
                        ))}
                        <div
                            className={cn(
                                'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                                layoutStyles
                            )}
                        >
                            <Button
                                fullWidth
                                kind="primary"
                                size="large"
                                onClick={() => {
                                    handleStartStockTake();
                                }}
                            >
                                Start Stock take
                            </Button>
                        </div>
                    </main>
                )}
            </div>
        </div>
    );
}
