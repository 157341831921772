import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { useParams } from 'react-router-dom';
import { Notifications } from '@jds/core';
import { ResponseType } from '../../../types/fetch';

export function useUpdateLabelStatus() {
    const { shipmentId, jioCode } = useParams();
    return useMutation<unknown, AxiosError<ResponseType<unknown>>>({
        mutationFn: async () => {
            const data = {
                status: 'LABELS_PRINTING_DONE',
                return_order_id: shipmentId,
                jio_code: jioCode,
            };
            const response = await axios.patch(urls.UPDATE_LABEL_STATUS(shipmentId ?? ''), data);
            return response.data;
        },
        onError: (error) => {
            const defaultError = 'Something went wrong';
            const messages = error.response?.data?.meta?.errors ?? [defaultError];
            messages.forEach((description) => {
                Notifications.toast.error({ title: 'Error', description });
            });
        },
    });
}
