import { MainRoutes } from '../HomePage';
import { cn } from '../../utils/tailwind';
import { layoutStyles } from '../../styles/common';
import { Button, Container, Icon, Text } from '@jds/core';
import { useNavigate, useParams } from 'react-router-dom';
import { IcLogout } from '@jds/core-icons';
import { useLogoutUser } from '../../hooks/auth/useLogoutUser';
import { subSections } from './sub-sections';
import { SecondaryPageHeader } from '../../components/shared/SecondaryPageHeader';
import { useQueryClient } from '@tanstack/react-query';

export function SubSectionsPage() {
    const queryClient = useQueryClient();
    const [navigate, { module }] = [useNavigate(), useParams()];
    const logoutUser = useLogoutUser();
    const subRoutes = subSections[module as MainRoutes];

    if (!subRoutes) throw new Error('Invalid route defined!');

    return (
        <div className={cn(layoutStyles, 'min-h-screen')}>
            <div className={cn('mr-6')}>
                <SecondaryPageHeader />
            </div>
            <main className={cn('mx-6 mt-10 mb-20 flex-1')}>
                <Text appearance="body-l-bold" color="primary-60">
                    <span className={cn('capitalize')}>{module} Tasks</span>
                </Text>
                <div className={cn('mt-4 grid grid-cols-2 gap-6')}>
                    {subRoutes.map((route) => (
                        <button
                            key={route.path}
                            aria-label={`go to ${route.title} page`}
                            type="button"
                            onClick={() => {
                                // just to make sure we don't have any stale data
                                // before starting an independent task
                                queryClient.clear();
                                navigate(route.path);
                            }}
                        >
                            <Container background="primary-20" rounded="xl">
                                <div
                                    className={cn(
                                        'flex h-32 items-center justify-center p-1 text-center'
                                    )}
                                >
                                    <Text appearance="body-s-bold" color="primary-50">
                                        {route.title}
                                    </Text>
                                </div>
                            </Container>
                        </button>
                    ))}
                </div>
            </main>
            <div className={cn('fixed inset-x-0 bottom-0 bg-white py-2.5 px-6', layoutStyles)}>
                <Button fullWidth kind="tertiary" type="button" onClick={logoutUser}>
                    <div className={cn('flex items-center justify-center space-x-3')}>
                        <Text color="primary-grey-80">Logout</Text>
                        <Icon color="grey-80" ic={<IcLogout />} size="m" />
                    </div>
                </Button>
            </div>
        </div>
    );
}
