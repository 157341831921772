import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { usePackagesBySecondaryCase } from '../../../hooks/receiving/usePackages';
import { Button, Container, Divider, Notifications, ProgressBar, Text } from '@jds/core';
import { useContext, useEffect } from 'react';
import { ReceivingContext } from '../../../provider/inbound/receiving';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { useMarkShort } from '../../../hooks/receiving/useMarkShort';
import { useFetchScannedPackages } from '../../../hooks/receiving/useFetchScannedPackages';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';

const manualInputProps = { name: 'primary-case-id', label: 'Primary Case', type: 'text' };
export function ScanPrimaryCasePage() {
    const markShort = useMarkShort();
    const { packages } = usePackagesBySecondaryCase();
    const { scannedPackages } = useFetchScannedPackages();
    const allUniquePrimaryCases = Array.from(
        new Set(packages.map(({ packageLabel }) => packageLabel))
    );
    const completedPrimaryCases = packages
        .filter((packageItem) => packageItem.packageQCStatus === 'Completed')
        .map((packageItem) => packageItem);
    const incompletePrimaryCases = packages
        .filter((packageItem) => packageItem.packageQCStatus !== 'Completed')
        .map((packageItem) => packageItem);
    const completedUniquePrimaryCases = Array.from(
        new Set(completedPrimaryCases.map(({ packageLabel }) => packageLabel))
    );
    const incompleteUniquePrimaryCases = Array.from(
        new Set(incompletePrimaryCases.map(({ packageLabel }) => packageLabel))
    );
    const primaryCaseKeys = incompleteUniquePrimaryCases?.map((item: string) => ({
        result: item,
    }));
    const { dispatch } = useContext(ReceivingContext);
    const [navigate, { stationId, appointmentNumber }] = [useNavigate(), useParams()];

    function onScanSuccess(primaryCaseId: string) {
        const primaryCase = packages.find(({ packageLabel }) => packageLabel === primaryCaseId);
        if (primaryCase) navigate(primaryCaseId);
        else {
            Notifications.toast.error({
                title: 'Error',
                description: 'Primary case not found for given secondary case.',
            });
        }
    }

    useEffect(() => {
        if (allUniquePrimaryCases.length !== 0 || completedUniquePrimaryCases.length !== 0)
            allUniquePrimaryCases?.length === completedUniquePrimaryCases?.length
                ? navigate(`../../${stationId}/${appointmentNumber}/success`)
                : '';
    }, [
        navigate,
        completedUniquePrimaryCases,
        allUniquePrimaryCases,
        stationId,
        appointmentNumber,
    ]);

    return (
        <div className={cn(layoutStyles, 'pb-3 md:mx-auto')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Primary Case"
                    onBackIconClick={() => {
                        localStorage.getItem('PRIMARY_CASE_SCANNED_LIST')
                            ? Notifications.toast.error('Unable to navigate back')
                            : navigate(-1);
                    }}
                />
                <div className={cn('mx-3 mt-3 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        searchableList={primaryCaseKeys}
                        onSuccess={onScanSuccess}
                    />
                </div>
            </div>
            <main className={cn('mx-3 mt-3 mb-20')}>
                <div className={cn('mx-3 mt-6')}>
                    <ProgressBar
                        label="Quantity"
                        value={completedUniquePrimaryCases.length / allUniquePrimaryCases.length}
                        valueLabel={`${completedUniquePrimaryCases.length} / ${allUniquePrimaryCases.length}`}
                    />
                </div>
                {scannedPackages.length > 0 && (
                    <Container
                        background="primary-grey-20"
                        className={cn('mx-3 mt-8')}
                        pad="m"
                        rounded="large"
                    >
                        <Text
                            appearance="body-m-bold"
                            className={cn(' flex ')}
                            color="primary-grey-80"
                        >
                            Scanned Cases
                            <Text
                                appearance="body-xs"
                                className={cn('ml-2 mt-1')}
                                color="primary-grey-80"
                            >
                                ({scannedPackages.length})
                            </Text>
                        </Text>
                        <Container>
                            {scannedPackages.map((packageId, index) => (
                                <div key={packageId}>
                                    <Container className={cn('my-4 flex justify-between')}>
                                        <Text color="primary-grey-60"> {packageId} </Text>
                                    </Container>
                                    {index < scannedPackages.length - 1 && <Divider />}
                                </div>
                            ))}
                        </Container>
                    </Container>
                )}
            </main>
            <div
                className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    disabled={completedPrimaryCases.length === allUniquePrimaryCases.length}
                    isLoading={markShort.isLoading}
                    kind="secondary"
                    size="large"
                    type="button"
                    onClick={() => {
                        markShort.mutate(undefined, {
                            onSuccess: () => {
                                dispatch({ type: 'RESET_ALL' });
                                navigate('./../success');
                            },
                        });
                    }}
                >
                    Mark Short
                </Button>
            </div>
        </div>
    );
}
