import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Space, Text } from '@jds/core';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { ControlledInput } from '../../../components/shared/jds';
import { ProductImage } from '../../../components/receiving/ProductImage';
import {
    ProductDetails,
    useGetProducts,
} from '../../../hooks/inventory/stockTake/useProductDetails';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';
import { useEffect } from 'react';

const productFormSchema = yup.object({
    ean: yup.string(),
    image: yup.string(),
    description: yup.string(),
    mrp: yup.number(),
    mfd: yup.string(),
    bestBefore: yup.string(),
    color: yup.string(),
    size: yup.string(),
    expectedQuantity: yup.number(),
});

function getDefaultValue(product?: ProductDetails): ProductFormSchema | undefined {
    if (!product) return;
    return {
        ean: product.ean,
        image: product.image_urls?.at(0) ?? 'https://via.placeholder.com/125',
        description: product.description ?? 'product description',
        mfd: product.mfg ? format(product.mfg, 'yyyy-MM-dd') : '2023-12-12',
        bestBefore: product.expiry ? format(product.expiry, 'yyyy-MM-dd') : '2023-12-12',
        mrp: product.mrp,
        color: product.color ?? '',
        size: product.size ?? '',
        expectedQuantity: product.expectedQuantity ?? 10,
    };
}

export type ProductFormSchema = yup.InferType<typeof productFormSchema>;
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function StockTakeVerifyDetailsPage() {
    const navigate = useNavigate();

    const { stockTakeId, binlocation, jioCode, lotId } = useParams();

    const { data: currentPackage } = useGetProducts([
        { jioCode: jioCode || '', lotId: Number(lotId) },
    ]);
    const { control, reset, watch } = useForm<ProductFormSchema>({
        defaultValues: getDefaultValue(currentPackage ? currentPackage[0] : undefined),
    });

    const currentQuantity = Number(useQueryParams().get('quantity'));
    const StockTakeQuantityTillNow = Number(localStorage.getItem('StockTakeQuantityTillNow')) || 0;
    const StockTakeProductsJson = localStorage.getItem('stock-take-products-list') || '[]';
    const StockTakeProducts = JSON.parse(StockTakeProductsJson);

    const validateJioCode = (jioCode: string) => {
        const val = StockTakeProducts.findIndex(
            (item: { jio_code: string }) => item.jio_code === jioCode
        );
        return val;
    };

    useEffect(() => {
        const defaults = getDefaultValue(currentPackage ? currentPackage[0] : undefined);
        reset(defaults);
    }, [currentPackage, reset]);

    return (
        <form className={cn(layoutStyles, 'py-3')}>
            <PageHeader
                title="Stock take"
                onBackIconClick={() => {
                    localStorage.setItem(
                        'StockTakeQuantityTillNow',
                        JSON.stringify(StockTakeQuantityTillNow - 1)
                    );
                    navigate(-1);
                }}
            />
            <div className={cn('mx-6 mt-3 mb-20')}>
                <Text
                    appearance="body-s-bold"
                    className={cn('flex items-center justify-between')}
                    color="primary-grey-100"
                >
                    Scanned Item details
                </Text>
                <fieldset disabled={true}>
                    <Space value="m" />
                    <Container as="div" background="primary-20" pad="base" rounded="large">
                        <ControlledInput
                            control={control}
                            label="EAN"
                            name="ean"
                            placeholder="Enter EAN"
                            type="text"
                        />
                    </Container>
                </fieldset>
                <Space value="m" />
                <ProductImage image={watch('image')} />
                <fieldset className={cn('mx-2 ')} disabled={true}>
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="Description"
                        name="description"
                        placeholder="Enter Description"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="MRP"
                        name="mrp"
                        placeholder="Enter MRP"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="MFD Date"
                        name="mfd"
                        placeholder="Enter MFD Date"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="Best Before"
                        name="bestBefore"
                        placeholder="Enter BBD Date"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="Color"
                        name="color"
                        placeholder="Enter color of the product"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        label="Size"
                        name="size"
                        placeholder="Enter size of the product"
                        type="text"
                    />
                    <Space value="m" />
                    <ControlledInput
                        control={control}
                        inputMode="numeric"
                        label="Expected quantity"
                        name="expectedQuantity"
                        placeholder="Enter quantity"
                        type="number"
                    />
                </fieldset>
            </div>
            <div
                className={cn('fixed inset-x-0 bottom-0 mt-3 flex space-x-2 bg-white py-2.5 px-6')}
            >
                <Button
                    kind="secondary"
                    onClick={() => {
                        const queryPrams = new URLSearchParams();
                        queryPrams.append('quantity', currentQuantity.toString());
                        navigate(
                            `/inventory/stock-take/${stockTakeId}/${binlocation}/${jioCode}/${lotId}/unsaleable-item?${queryPrams.toString()}`
                        );
                    }}
                >
                    Unsaleable
                </Button>
                <Button
                    kind="secondary"
                    onClick={() => {
                        const index = validateJioCode(jioCode || '');
                        const goodQuantity = StockTakeProducts[index].non_damaged_actual ?? 0;
                        StockTakeProducts[index].non_damaged_actual =
                            goodQuantity + currentQuantity;
                        localStorage.setItem(
                            'stock-take-products-list',
                            JSON.stringify(StockTakeProducts)
                        );
                        navigate(`/inventory/stock-take/${stockTakeId}/${binlocation}/main`);
                    }}
                >
                    Mark Good
                </Button>
            </div>
        </form>
    );
}
