import { Button, Icon, Input, Text } from '@jds/core';
import { cn } from '../utils/tailwind';
import * as yup from 'yup';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardEvent, RefObject, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IcVisible, IcVisibleOff } from '@jds/core-icons';

import { useLoginUser } from '../hooks/auth/useLoginUser';

const loginSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
});

export type LoginSchema = yup.InferType<typeof loginSchema>;

function withForwardRef({ ref, ...fieldAttributes }: UseFormRegisterReturn) {
    return { ...fieldAttributes, forwardRef: ref as unknown as RefObject<HTMLElement> };
}
const layoutStyles = 'max-w-screen-md md:mx-auto';
export function LoginPage() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginSchema>({
        resolver: yupResolver(loginSchema),
    });
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const loginUser = useLoginUser();

    const onSubmit = (data: LoginSchema) => {
        loginUser.mutate(data, {
            onSuccess: () => navigate('/'),
        });
    };

    const [toteValue, setToteValue] = useState(0);
    return (
        <div className={cn(layoutStyles, 'mx-8')}>
            <div className={cn('mt-12 flex flex-col items-center justify-center')}>
                <Text className={cn('text-4xl font-normal tracking-widest')} color="primary-60">
                    WMS
                </Text>
                <Text className={cn('text-[10px] font-normal tracking-widest')} color="primary-70">
                    Jio Warehouses
                </Text>
            </div>
            <form className="mt-16" onSubmit={handleSubmit(onSubmit)}>
                <Input
                    aria-invalid={errors.username ? 'true' : 'false'}
                    aria-required="true"
                    autoComplete="username"
                    className={cn('mb-16')}
                    label="Username *"
                    state={errors.username ? 'error' : undefined}
                    stateConfig={{ errorText: errors.username?.message }}
                    {...withForwardRef(register('username'))}
                />
                <Input
                    aria-invalid={errors.password ? 'true' : 'false'}
                    aria-required="true"
                    autoComplete="current-password"
                    className={cn('mb-16')}
                    label="Password *"
                    size="small"
                    state={errors.password ? 'error' : undefined}
                    stateConfig={{ errorText: errors.password?.message }}
                    suffix={
                        <Icon
                            aria-label={showPassword ? 'Hide Password' : 'Show Password'}
                            color="grey-80"
                            ic={showPassword ? <IcVisibleOff /> : <IcVisible />}
                            size="l"
                        />
                    }
                    type={showPassword ? 'text' : 'password'}
                    onSuffixClick={() => setShowPassword(!showPassword)}
                    {...withForwardRef(register('password'))}
                />
                <div className={cn('mt-10 flex flex-col items-center space-y-4')}>
                    <Button fullWidth isLoading={loginUser.isLoading}>
                        Login
                    </Button>
                </div>
            </form>

            <div className={cn('flex items-center')}>
                <Input
                    className={cn('!mt-0 max-w-[58px]')}
                    color="primary-50"
                    id="numberWithBigFont"
                    inputMode="numeric"
                    label=""
                    name="assigned-quantity"
                    size="medium"
                    textMask="###########"
                    // type="number"
                    value={toteValue.toString()}
                    onChange={(e) => {
                        setToteValue(Number(e.target.value));
                    }}
                />
            </div>
        </div>
    );
}
