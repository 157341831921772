import { cn } from '../../utils/tailwind';
import { Button, Icon, Text } from '@jds/core';
import { IcBack, IcHelp } from '@jds/core-icons';

interface PageHeaderProps {
    title?: string;
    subTitle?: string;
    onBackIconClick?: () => void;
    showBackIcon?: boolean;
    showHelpIcon?: boolean;
    breadcrumbTitle?: string;
    onHelpIconClick?: () => void;
}

export function PageHeader({
    subTitle,
    title,
    onBackIconClick,
    showBackIcon = true,
    showHelpIcon = false,
    breadcrumbTitle = '',
    onHelpIconClick,
}: PageHeaderProps) {
    return (
        <div className={cn('flex justify-between')}>
            <div className={cn('flex items-center')}>
                {showBackIcon && (
                    <Button
                        aria-label="go back to previous page"
                        icon={<IcBack color="black" />}
                        kind="tertiary"
                        type="button"
                        onClick={onBackIconClick}
                    />
                )}
                <div className={cn(['flex items-center space-x-2', !showBackIcon && 'ml-6'])}>
                    {title && (
                        <Text appearance="body-s-bold" color="primary-grey-100">
                            {title}
                        </Text>
                    )}
                    {subTitle && (
                        <Text appearance="body-m-bold" color="primary-grey-80">
                            {subTitle}
                        </Text>
                    )}
                </div>
                {breadcrumbTitle && (
                    <div className={cn('flex items-center')}>
                        <Icon className={cn('mx-2')} ic={<IcBack color="black" />} size="m" />
                        <Text appearance="body-s-bold" color="primary-grey-100">
                            {breadcrumbTitle}
                        </Text>
                    </div>
                )}
            </div>
            {showHelpIcon && (
                <Button
                    aria-label="go to status page"
                    className={cn('mr-2 flex items-center')}
                    color="grey-100"
                    icon={<IcHelp color="black" />}
                    kind="tertiary"
                    type="button"
                    onClick={onHelpIconClick}
                />
            )}
        </div>
    );
}
