import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { useState } from 'react';
import { Button, Container, Notifications, Space } from '@jds/core';
import {
    AssignCrateSchema,
    useAssignCrate,
} from '../../../hooks/outbound/consolidation/useAssignCrate';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { useConsolidationStateSave } from '../../../hooks/outbound/consolidation/useConsolidationStateSave';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const crateManualInputProps = { name: 'Crate-id', label: 'Crate ID', type: 'text' };
const ptwManualInputProps = { name: 'ptw-location', label: 'PTW location', type: 'text' };
export function NewCratePage() {
    const navigate = useNavigate();
    const queryParams = useQueryParams();
    const { stationId, toteCode, jioCode, ptwLocation, shipmentId } = useParams();
    const [currentCrateId, setCurrentCrateId] = useState('');
    const [currentPtwLocation, setCurrentPtwLocation] = useState('');
    const saveConsolidationactivity = useConsolidationStateSave();

    const assignCrate = useAssignCrate();
    const assignCrateOnSubmit = (data: AssignCrateSchema) => {
        assignCrate.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status === 'failure') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    handleNewCrateScanState();
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', '),
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    function handleNewCrateScanState() {
        saveConsolidationactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'TAG_NEW_CRATE_CONSOLIDATION',
                status: 'COMPLETED',
                locationId: stationId,
                shipmentId: shipmentId,
                crate_id: currentCrateId,
                ptw_location: ptwLocation,
                toteId: toteCode,
                jioCode: jioCode,
                lotId: Number(queryParams.get('lotId')) ?? '',
            },
            {
                onSuccess: () => {
                    navigate(
                        `/outbound/consolidation/${stationId}/${toteCode}/${jioCode}/${shipmentId}/${ptwLocation}/${currentCrateId}/location-details`
                    );
                },
            }
        );
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Tag New Crate"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
            </div>
            <main className={cn('mx-2 mt-6')}>
                <Container
                    className={cn('bg-cronical-gradient mb-6 px-5 pt-6 pb-3')}
                    rounded="medium"
                >
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={crateManualInputProps}
                        type="CRATE_CODE"
                        onSuccess={(value) => {
                            setCurrentCrateId(value);
                        }}
                    />
                </Container>
                <Space value="m" />
                <Container
                    className={cn('bg-cronical-gradient mb-6 px-5 pt-6 pb-3')}
                    rounded="medium"
                >
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        inputProps={ptwManualInputProps}
                        searchableList={[{ result: ptwLocation || '' }]}
                        onSuccess={(value) => {
                            if (value != ptwLocation) {
                                setCurrentPtwLocation('');
                                Notifications.toast.error({
                                    title: 'Error',
                                    description: 'Invalid PTW location',
                                });
                            } else {
                                setCurrentPtwLocation(value);
                            }
                        }}
                    />
                </Container>
                <div
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                        layoutStyles
                    )}
                >
                    <Button
                        fullWidth
                        disabled={currentCrateId === '' || currentPtwLocation === ''}
                        kind="primary"
                        size="large"
                        onClick={() => {
                            assignCrateOnSubmit({
                                ptw_location: ptwLocation || '',
                                order_id: '',
                                crate_id: currentCrateId,
                                type: 'ASSIGN',
                                shipment_id: shipmentId || '',
                            });
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </main>
        </div>
    );
}
