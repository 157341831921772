import { Dispatch, SetStateAction } from 'react';
import { cn } from '../../utils/tailwind';
import { Button, Text } from '@jds/core';

interface ProductDetailsHeaderProps {
    isEditMode: boolean;
    setIsEditMode: Dispatch<SetStateAction<boolean>>;
    isLoading: boolean;
    enableEditMode?: boolean;
}
const buttonStyles = cn('h-10 w-full max-w-[110px]');
export function ProductDetailsHeader({
    isEditMode,
    setIsEditMode,
    isLoading,
    enableEditMode = true,
}: ProductDetailsHeaderProps) {
    return (
        <div className={cn('flex items-center justify-between')}>
            <Text appearance="body-s-bold" color="primary-grey-100">
                Details
            </Text>
            {enableEditMode && isEditMode && (
                <Button
                    className={buttonStyles}
                    color="primary-grey-100"
                    disabled={isLoading}
                    kind="primary"
                    size="small"
                    type="submit"
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            )}
            {enableEditMode && !isEditMode && (
                <Button
                    className={cn('h-10 w-full max-w-[110px] !bg-white')}
                    color="primary-grey-100"
                    kind="secondary"
                    size="small"
                    type="button"
                    onClick={() => setIsEditMode(true)}
                >
                    Edit
                </Button>
            )}
        </div>
    );
}
