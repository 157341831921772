import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface JioCodesListSchema {
    toteId: string;
}

export function useJioCodesList() {
    return useMutation(async (data: JioCodesListSchema) => {
        const response = await axios.get(urls.GET_CONSOLIDATION_JIOCODES_LIST(), {
            headers: { toteId: data.toteId },
        });
        return response.data;
    });
}
