import { Heading, Icon, Modal, Spinner, Table } from '@jds/core';
import { cn } from '../../utils/tailwind';
import { IcWarning } from '@jds/core-icons';
import { useOverrideDetails, VerificationDetails } from '../../hooks/cubiscan/useOverrideDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { useProductDetails } from '../../hooks/cubiscan/useProductDetails';

interface CubiscanVerificationModalProps {
    showDialog: boolean;
    setShowDialog: (show: boolean) => void;
    details: VerificationDetails;
}
export function CubiscanVerificationModal({
    showDialog,
    setShowDialog,
    details,
}: CubiscanVerificationModalProps) {
    const { cubiscanLabel, lotId } = useParams();
    const { data: product } = useProductDetails(cubiscanLabel ?? '', lotId ?? '');
    const overrideDetails = useOverrideDetails();
    const navigate = useNavigate();
    return (
        <>
            <Modal
                className={cn('!h-[100%]')}
                closed={!showDialog}
                header={{
                    top: (
                        <div className={cn('text-center')} id="tableModal">
                            <Icon color="primary" ic={<IcWarning />} size="xxl" />
                            <Heading appearance="heading-xs">
                                Cubiscan details don’t match with seller info
                            </Heading>
                            <div className={cn('mt-8')}>
                                <Table
                                    className={cn('w-full text-center')}
                                    density="condensed"
                                    headerBackground={false}
                                    rowStyle="zebra"
                                    rows={[
                                        { cubiscan: 'Cubiscan', seller: 'Seller' },
                                        { cubiscan: details.length, seller: product?.length },
                                        { cubiscan: details.breadth, seller: product?.breadth },
                                        { cubiscan: details.height, seller: product?.height },
                                        {
                                            cubiscan: details.weight,
                                            seller: product?.weight ?? 0,
                                        },
                                    ]}
                                    style={{
                                        borderWidth: '0px',
                                    }}
                                />
                            </div>
                        </div>
                    ),
                }}
                primaryCTA={{
                    title: 'Override Details',

                    onClick: () => {
                        overrideDetails.mutate(details, {
                            onSuccess: () => navigate('scan-jio-code'),
                        });
                    },
                }}
                onCloseCallback={() => {
                    setShowDialog(false);
                }}
            />
            {overrideDetails.isLoading && (
                <div
                    className={cn(
                        'z-[9999] flex items-center justify-center',
                        'absolute inset-0 h-full min-h-screen bg-gray-200/50'
                    )}
                >
                    <Spinner />
                </div>
            )}
        </>
    );
}
