import { Button, Icon, Text } from '@jds/core';
import { IcSuccess } from '@jds/core-icons';
import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';

const layoutStyles = 'max-w-screen-md md:mx-auto';

const GtlDropzoneCompleted = () => {
    const navigate = useNavigate();

    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <div className={cn('my-8 ml-2 flex items-start')}>
                <div className={cn('mt-1')}>
                    <Icon color="primary" ic={<IcSuccess />} size="m" />
                </div>

                <Text appearance="heading-xs" className={cn('ml-2')} color="primary-50">
                    Drop Completed for GTL
                </Text>
            </div>

            <Button fullWidth kind="secondary" onClick={() => navigate('/')}>
                <Text appearance="body-s" color="primary-50">
                    Pick a new task
                </Text>
            </Button>
        </div>
    );
};

export default GtlDropzoneCompleted;
