import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { useUnloadingVerification } from '../../../hooks/receiving/useUnloadingVerification';
import { Container, Icon, Notifications, Text } from '@jds/core';
import {
    useFetchAllSecondaryCaseDetails,
    useFetchSecondaryCases,
} from '../../../hooks/receiving/useFetchSecondaryCases';
import { IcKiranaStore } from '@jds/extended-icons';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';

const manualInputProps = { name: 'secondary-case-id', label: 'Secondary packages', type: 'text' };
export function ScanSecondaryCasePage() {
    const [navigate, { stationId, appointmentNumber }] = [useNavigate(), useParams()];
    const unloadingVerification = useUnloadingVerification();
    const secondaryCasesMutation = useFetchSecondaryCases();
    const { data: allSecondaryCaseDetails } = useFetchAllSecondaryCaseDetails();

    const secondaryCaseKeys = allSecondaryCaseDetails?.data?.unloading_verification_details
        ?.filter(
            (item) =>
                item.receiving_qc_status?.toLowerCase() !== 'completed' &&
                item?.status === 'Success'
        )
        .map((item: any) => ({
            result: item.box_label ? item.box_label : item.package_label,
        }));

    async function checkIfValidSecondaryCase(secondaryCaseId: string) {
        const secondaryCases = await secondaryCasesMutation.mutateAsync();
        const checkBoxLabel = secondaryCases.data?.secondary_cases.find(
            ({ boxLabel }) => boxLabel === secondaryCaseId
        );
        const checkPackageLabel = secondaryCases.data?.secondary_cases.find(
            ({ packageLabel }) => packageLabel === secondaryCaseId
        );
        if (!checkBoxLabel && !checkPackageLabel) {
            Notifications.toast.error({
                title: 'Error',
                description: 'Invalid secondary case ID',
            });
            return false;
        }
        return true;
    }
    /**
     * Once the secondary case is scanned, we need to check if it is faulty or not.
     * A secondary case is faulty if it's status is either `missing` or `damaged`.
     * One thing to note here is that the secondary case could be a `box_label` or a `package_label`.
     */
    async function onScanSuccess(secondaryCaseId: string) {
        const isValidSecondaryCase = await checkIfValidSecondaryCase(secondaryCaseId);
        if (!isValidSecondaryCase) return;
        const response = await unloadingVerification.mutateAsync(Number(appointmentNumber));
        const packageByBoxLabel = response.data?.unloading_verification_details?.find(
            ({ box_label }) => box_label === secondaryCaseId
        );
        const packageByPackageLabel = response.data?.unloading_verification_details?.find(
            ({ package_label }) => package_label === secondaryCaseId
        );
        const boxLabelStatus = packageByBoxLabel?.status?.toLowerCase() ?? '';
        const packageLabelStatus = packageByPackageLabel?.status?.toLowerCase() ?? '';
        const boxLabelReceivingStatus = packageByBoxLabel?.receiving_qc_status?.toLowerCase() ?? '';
        const packageLabelReceivingStatus =
            packageByPackageLabel?.receiving_qc_status?.toLowerCase() ?? '';
        const isFaultyBoxLabel = ['missing', 'damaged'].includes(boxLabelStatus);
        const isFaultyPackageLabel = ['missing', 'damaged'].includes(packageLabelStatus);

        if (
            boxLabelReceivingStatus === 'completed' ||
            packageLabelReceivingStatus === 'completed'
        ) {
            Notifications.toast.error({
                title: 'Error',
                description: 'Receiving already completed for this secondary case',
            });
        } else if (isFaultyBoxLabel) {
            Notifications.toast.error({
                title: 'Error',
                description: `Receiving cannot happen as the secondary case is ${boxLabelStatus}`,
            });
        } else if (isFaultyPackageLabel) {
            Notifications.toast.error({
                title: 'Error',
                description: `Receiving cannot happen as the secondary case is ${packageLabelStatus}`,
            });
        } else navigate(secondaryCaseId);
    }

    return (
        <div className={cn('max-w-screen-md pb-3 md:mx-auto')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Secondary packages"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <div className={cn('mx-3 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        searchableList={secondaryCaseKeys}
                        onSuccess={onScanSuccess}
                    />
                </div>
            </div>
            <main className={cn('mx-3 mt-3')}>
                <Container
                    className={cn(
                        'm-4 grid grid-cols-2 space-x-4 rounded-lg border-2 border-[var(--color-primary-60)] p-4'
                    )}
                >
                    <Icon
                        className={cn('absolute left-4 bg-white')}
                        ic={<IcKiranaStore color="#000093" />}
                    />
                    <Text appearance="body-s"> Station ID </Text>
                    <Text appearance="body-s-bold"> {stationId} </Text>
                </Container>
            </main>
        </div>
    );
}
