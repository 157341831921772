import { useMutation } from '@tanstack/react-query';
import { Notifications } from '@jds/core';
import axios, { AxiosError } from 'axios';
import { useProductDetails } from './useProductDetails';
import { urls } from '../../utils/urls';
import { useParams } from 'react-router-dom';

export interface VerificationDetails {
    length: number;
    breadth: number;
    height: number;
    weight: number;
}

export function useOverrideDetails() {
    const { sourceToteId, machineId, cubiscanLabel, lotId } = useParams();
    const { data: product } = useProductDetails(cubiscanLabel ?? '', lotId ?? '');
    const username = sessionStorage.getItem('username');

    return useMutation<
        unknown,
        AxiosError<ErrorResponse | Record<number, string>>,
        VerificationDetails
    >({
        mutationFn: async (details) => {
            const response = await axios.put(urls.CUBISCAN_OVERRIDE_DETAILS(), {
                skuCode: product?.sku_code,
                jioCode: product?.jio_code,
                rfcId: Number(sessionStorage.getItem('rfcId')) ?? 0,
                weight: details.weight === 0 ? 0.1 : details.weight,
                weightUnit: 'g',
                length: details.length === 0 ? 0.1 : details.length,
                breadth: details.breadth === 0 ? 0.1 : details.breadth,
                height: details.height === 0 ? 0.1 : details.height,
                volumeUnit: 'cm',
                userName: username,
                cubiscanStatus: 'COMPLETED',
                toteCode: sourceToteId,
                machineId: machineId,
            });
            return response.data;
        },
        onSuccess: () => {
            Notifications.toast.success({
                title: 'Success',
                description: 'Details have been overridden',
            });
        },
        onError: (error) => {
            const defaultMessage = 'Something went wrong';
            const messageLocation1 = (error?.response?.data as ErrorResponse)?.detail;
            const messageLocation2 = (error?.response?.data as Record<number, string>)?.[0];
            Notifications.toast.error({
                title: 'Error',
                description: messageLocation1 ?? messageLocation2 ?? defaultMessage,
            });
        },
    });
}

interface ErrorResponse {
    title: string;
    detail: string;
}
