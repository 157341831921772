import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../utils/tailwind';
import { Button, Icon, Notifications, ProgressBar, Text } from '@jds/core';

import { PageHeader } from '../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { ProductCard } from '../../components/shared/ProductCard';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';
import { ProductDataSchema } from '../../hooks/gtl/useGetProductDetails';
import { toteSchema, useUpdateGtlJioCodeStatus } from '../../hooks/gtl/useUpdateGtlJioCodeStatus';
import { GtlTaskListResponse } from '../../hooks/gtl/useGetGtlTaskList';
import { useUpdateGtlTaskStatus } from '../../hooks/gtl/useUpdateGtlTaskStatus';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'jio-code', label: 'Jio Code', type: 'text' };

const ScanGtlJioCode = () => {
    const [scannedItemQuantity, setScannedItemQuantity] = useState(
        Number(JSON.parse(localStorage.getItem('scannedGtlItemQuantityTillNow') || '0'))
    );
    const [enableScannerOnMount, setEnableScannerOnMount] = useState(true);
    const [locationScanCompleted, setLocationScanCompleted] = useState(
        Boolean(JSON.parse(localStorage.getItem('gtlLocationCompleted') || 'false'))
    );
    const [jioCodeScanCompleted, setJioCodeScanCompleted] = useState(
        Boolean(JSON.parse(localStorage.getItem('gtlJioCodeCompleted') || 'false'))
    );
    const navigate = useNavigate();
    const { locationId } = useParams();
    const gtlTaskList = useQueryClient().getQueryData<GtlTaskListResponse>(['gtlTaskList']);
    const gtlLocationsList: any = gtlTaskList?.data.filter(
        (item) => item.sourceLocationId === locationId
    );
    const pickedGtlJioCodeIndex = Number(
        JSON.parse(localStorage.getItem('pickedGtlJioCodeIndex') || '0')
    );
    const currentJioCodeData: any =
        gtlLocationsList &&
        gtlLocationsList.length > pickedGtlJioCodeIndex &&
        gtlLocationsList[pickedGtlJioCodeIndex];
    const productDetails: any = useQueryClient().getQueryData<ProductDataSchema>([
        'productDetails',
    ]);
    const toteList: toteSchema[] = JSON.parse(localStorage.getItem('gtlToteIds') || '[]');
    const noOfTotes = toteList.length;
    const [updateJioCodeStatus, updateTaskStatus] = [
        useUpdateGtlJioCodeStatus(),
        useUpdateGtlTaskStatus(),
    ];
    const taskId = JSON.parse(localStorage.getItem('gtlTaskId') || '');

    function onUpdateJioCodeStatus() {
        if (
            noOfTotes > 0 &&
            Number(JSON.parse(localStorage.getItem('gtlToteItemQuantity') ?? '0')) > 0
        ) {
            const toteObj: toteSchema = toteList[noOfTotes - 1];
            const updatedToteObj = {
                ...toteObj,
                quantity: Number(JSON.parse(localStorage.getItem('gtlToteItemQuantity') || '0')),
            };
            toteList[noOfTotes - 1] = updatedToteObj;
            localStorage.setItem('gtlToteIds', JSON.stringify(toteList));
            localStorage.setItem('gtlToteItemQuantity', '0');
        }
        const data = {
            jio_code: currentJioCodeData?.jioCode,
            product_type: 'GOOD',
            lot_id: productDetails?.lotInformationDTO?.lotId,
            location_id: currentJioCodeData?.sourceLocationId,
            tote_purpose: 'GTL_PUT_AWAY',
            totes: toteList,
        };

        updateJioCodeStatus.mutate(data, {
            onSuccess() {
                if (locationScanCompleted) {
                    updateTaskStatus.mutate(
                        {
                            taskId,
                            status: 'PICKUP_COMPLETE',
                        },
                        {
                            onSuccess: () => {
                                setScannedItemQuantity(0);
                                localStorage.setItem(
                                    'scannedGtlItemQuantityTillNow',
                                    JSON.stringify(0)
                                );
                                localStorage.setItem('gtlLocationCompleted', JSON.stringify(false));
                                setLocationScanCompleted(false);
                                localStorage.setItem('pickedGtlJioCodeIndex', '0');
                                navigate(`../${locationId}/gtl-putaway-location`);
                            },
                        }
                    );
                } else {
                    setScannedItemQuantity(0);
                    localStorage.setItem('scannedGtlItemQuantityTillNow', JSON.stringify(0));
                    localStorage.setItem('gtlJioCodeCompleted', JSON.stringify(false));
                    setJioCodeScanCompleted(false);
                    const pickedJioCodeIndex =
                        Number(JSON.parse(localStorage.getItem('pickedGtlJioCodeIndex') || '0')) +
                        1;
                    if (gtlLocationsList.length > pickedJioCodeIndex) {
                        localStorage.setItem(
                            'pickedGtlJioCodeIndex',
                            JSON.stringify(pickedJioCodeIndex)
                        );
                    }
                }
            },
            onError(err: any) {
                if (err?.response?.data?.message) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: 'Something went wrong.',
                    });
                }
            },
        });
    }

    const handleOnScanJioCode = (jioCode: string) => {
        if (jioCode === currentJioCodeData?.jioCode) {
            const updatedQuantity = scannedItemQuantity + 1;
            if (
                updatedQuantity <= (currentJioCodeData && currentJioCodeData?.quantity) &&
                gtlLocationsList &&
                gtlLocationsList?.length > 0
            ) {
                setScannedItemQuantity(updatedQuantity);
                if (updatedQuantity === currentJioCodeData.quantity) {
                    const pickedJioCodeIndex =
                        Number(JSON.parse(localStorage.getItem('pickedGtlJioCodeIndex') || '0')) +
                        1;
                    if (pickedJioCodeIndex === gtlLocationsList.length) {
                        setLocationScanCompleted(true);
                        setEnableScannerOnMount(false);
                    } else {
                        setJioCodeScanCompleted(true);
                    }
                }
                localStorage.setItem(
                    'scannedGtlItemQuantityTillNow',
                    JSON.stringify(updatedQuantity)
                );
                localStorage.setItem(
                    'gtlToteItemQuantity',
                    JSON.stringify(
                        Number(JSON.parse(localStorage.getItem('gtlToteItemQuantity') || '0')) + 1
                    )
                );
            }
        } else {
            Notifications.toast.error({
                title: 'Error',
                description: 'Jio Code is not matching.',
            });
        }
    };
    return (
        <>
            <div className={cn(layoutStyles, 'mx-3 py-3')}>
                <PageHeader
                    showHelpIcon
                    showBackIcon={false}
                    subTitle={
                        noOfTotes && noOfTotes > 1
                            ? `Tote#${noOfTotes} (${toteList[noOfTotes - 1].tote_code})`
                            : ''
                    }
                    title="GTL"
                    onHelpIconClick={() => navigate(`../gtl-task-status`)}
                />
                <main className={cn('mx-2 mt-6')}>
                    <ScanOrEnterManuallyV2
                        enableScannerOnMount={enableScannerOnMount}
                        inputProps={manualInputProps}
                        onSuccess={(value) => {
                            handleOnScanJioCode(value);
                        }}
                    />

                    <ProgressBar
                        className={cn('mt-8')}
                        label="Pick Quantity"
                        value={scannedItemQuantity / currentJioCodeData?.quantity}
                        valueLabel={`${scannedItemQuantity}/${currentJioCodeData?.quantity}`}
                    />
                    <div className={cn('mt-8 mb-4')}>
                        <Button
                            fullWidth
                            kind="secondary"
                            onClick={() => navigate(`../${locationId}/gtl-help`)}
                        >
                            Need Help?
                        </Button>
                    </div>

                    <ProductCard
                        key={productDetails?.product?.uid}
                        BBD={productDetails?.lotInformationDTO?.expiry}
                        EAN={productDetails?.product?.sku?.ean}
                        MFD={productDetails?.lotInformationDTO?.mfg}
                        MRP={productDetails?.lotInformationDTO?.mrp}
                        colorSize={`${productDetails?.product?.color}, ${productDetails?.product?.size}`}
                        title={productDetails?.product?.sku?.description}
                    />
                    {noOfTotes && noOfTotes > 1 ? (
                        <div>
                            <div className={cn('mt-8 flex justify-between')}>
                                <Text appearance="body-m" color="primary-grey-60">
                                    {`Tote ID #${noOfTotes - 1}`}
                                </Text>
                                <Text appearance="body-m" color="primary-grey-60">
                                    {toteList[noOfTotes - 2].tote_code}
                                </Text>
                            </div>
                            <div className={cn('mt-8 flex justify-between')}>
                                <Text appearance="body-m" color="primary-grey-60">
                                    Putaway Quantity
                                </Text>
                                <Text appearance="body-m" color="primary-grey-60">
                                    {toteList[noOfTotes - 2].quantity}
                                </Text>
                            </div>
                        </div>
                    ) : null}
                </main>
            </div>
            {jioCodeScanCompleted && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcSuccessColored"
                            size="xl"
                        />
                    }
                    isOpened={jioCodeScanCompleted}
                    message={`${scannedItemQuantity} / ${currentJioCodeData?.quantity} Jio Code ${currentJioCodeData?.jioCode} Picked successfully`}
                    primaryCTALabel="Okay"
                    onPrimaryCTAClick={() => {
                        onUpdateJioCodeStatus();
                    }}
                ></ConfirmationDialog>
            )}

            {locationScanCompleted && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcSuccessColored"
                            size="xl"
                        />
                    }
                    isOpened={locationScanCompleted}
                    message={`All Jio Codes are picked from
                Location ${currentJioCodeData?.sourceLocationId}`}
                    primaryCTALabel="Continue"
                    onPrimaryCTAClick={() => {
                        onUpdateJioCodeStatus();
                    }}
                ></ConfirmationDialog>
            )}
        </>
    );
};

export default ScanGtlJioCode;
