import { cn } from '../../utils/tailwind';
import { IcChevronUp, IcChevronDown } from '@jds/core-icons';
import { Text, Icon, Button } from '@jds/core';
import { useState } from 'react';

interface AccordionProps {
    title?: string;
    children?: React.ReactNode;
    index: number;
}

const AccordionLayout = ({ title, children, index }: AccordionProps) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSetIndex = (index: number) => {
        if (activeIndex !== index) setActiveIndex(index);
        else setActiveIndex(0);
    };

    return (
        <>
            <Button
                fullWidth
                aria-label="perform accordion level actions"
                className={cn('mt-2 flex rounded p-1')}
                kind="tertiary"
                onClick={() => handleSetIndex(index)}
            >
                <div className={cn('flex')}>
                    <div className="flex items-center pl-20">
                        <Text className={cn('font-bold')} color="primary-50">
                            {title}
                        </Text>
                    </div>
                    <div className={cn('flex items-center justify-center')}>
                        {activeIndex === index ? (
                            <Icon
                                aria-label="hide-accordian-content"
                                color="primary"
                                ic={<IcChevronUp />}
                                size="l"
                                onClick={() => handleSetIndex(0)}
                            />
                        ) : (
                            <Icon
                                aria-label="show-accordian-content"
                                color="primary"
                                ic={<IcChevronDown />}
                                size="l"
                                onClick={() => handleSetIndex(1)}
                            />
                        )}
                    </div>
                </div>
            </Button>

            {activeIndex === index && <div>{children}</div>}
        </>
    );
};

export default AccordionLayout;
