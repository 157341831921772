import { Button, Icon, Notifications, ProgressBar } from '@jds/core';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { useContext, useState } from 'react';
import { usePicklistPendingLocationStatus } from '../../../hooks/outbound/picklist/useGetPicklistLocation';
import { IncrementDecrementSectionV2 } from '../../../components/shared/IncrementDecrementSectionV2';
import { useUpdateJioCodeStatus } from '../../../hooks/outbound/picklist/useUpdateJioCodeStatus';
import {
    useUpdateQuantityByOrderId,
    OrderItemsSchema,
} from '../../../hooks/outbound/picklist/useUpdateQuantityByOrderId';
import { PicklistContext } from '../../../provider/outbound/picklist';
import ConfirmationDialog from '../../../components/shared/ConfirmationDialog';
import { usePicklistStateSave } from '../../../hooks/outbound/picklist/usePicklistStateSave';
import { useOutBoundProducts } from '../../../hooks/outbound/picklist/useOutBoundProducts';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';
import {
    useUpdateDamagedProducts,
    UpdateDamagedProductsSchema,
} from '../../../hooks/outbound/consolidation/useDamagedProducts';

const layoutStyles = 'max-w-screen-md md:mx-auto';

const PickListHelp = () => {
    const [navigate, queryParams] = [useNavigate(), useQueryParams()];
    const [isAPICallLoading, setIsAPICallLoading] = useState(false);
    const { pickingId, trolleyId, toteId } = useParams();
    const { data: outboundOrders } = useOutBoundProducts();
    const { state, dispatch } = useContext(PicklistContext);
    const savePicklistactivity = usePicklistStateSave();
    const isScanCompleted = JSON.parse(
        localStorage.getItem('picking_jio_code_scan_complete') ?? 'false'
    );
    const [damagedQuantity, setDamagedQuantity] = useState(state.quantityStatus.damaged);
    const [editModes, setEditModes] = useState({
        short: false,
        damaged: false,
    });
    const [jioCodeScanCompleted, setJioCodeScanCompleted] = useState(false);
    const scannedItemQuantity = state.scannedGoodPicklistItem;
    const pickedJioCodeIndex = state.picklistIndex;
    const currentJioCodeData: any =
        outboundOrders &&
        outboundOrders?.length > pickedJioCodeIndex &&
        outboundOrders[pickedJioCodeIndex];
    const [shortQuantity, setShortQuantity] = useState(
        currentJioCodeData?.quantity - scannedItemQuantity > 0
            ? currentJioCodeData?.quantity - scannedItemQuantity - damagedQuantity
            : state.quantityStatus.short
    );

    const [updateJioCodeStatus, updateQuantityByOrderId, pickingLocationStatus] = [
        useUpdateJioCodeStatus(),
        useUpdateQuantityByOrderId(),
        usePicklistPendingLocationStatus(),
    ];

    const [locationScanCompleted, setLocationScanCompleted] = useState(isScanCompleted ?? false);
    function onUpdateJioCodeStatus() {
        setIsAPICallLoading(true);
        const data = {
            bin_code: currentJioCodeData?.location_id,
            jio_code: currentJioCodeData?.jio_code,
            lot_id: currentJioCodeData?.lot_id,
            quantity: scannedItemQuantity,
            operation_type: 'REMOVE',
        };

        updateJioCodeStatus.mutate(data, {
            onSuccess: () => {
                const orderObj: OrderItemsSchema = {
                    id: currentJioCodeData?.order_id,
                    quantity: 0,
                    jio_code: currentJioCodeData?.jio_code ?? '',
                    shipment_id: currentJioCodeData?.shipment_id ?? '',
                    lot_id: currentJioCodeData?.lot_id ?? 0,
                    quantity_not_picked: shortQuantity + damagedQuantity,
                    total_quantity: currentJioCodeData?.quantity ?? 0,
                };

                const pickedOrderObj = orderObj;
                const pickedJioCodesList = state.pickedJioCodesObjList;
                pickedJioCodesList.push(pickedOrderObj);
                dispatch({ type: 'SET_PICKLIST_JIOCODES', payload: pickedJioCodesList });

                updateQuantityByOrderId.mutate(
                    {
                        picklist_id: Number(pickingId),
                        status: 'COMPLETED',
                        orders: [orderObj],
                        locationId: currentJioCodeData?.location_id,
                    },
                    {
                        onSuccess: () => {
                            if (shortQuantity + damagedQuantity > 0) {
                                const requestPayload = [];
                                if (shortQuantity > 0) {
                                    requestPayload.push({
                                        cancelled_quantity: shortQuantity,
                                        cancellation_reason: 'not available',
                                        order_id: currentJioCodeData?.order_id || '',
                                        jio_code: currentJioCodeData?.jio_code || '',
                                        tote_code: '',
                                        shipment_id: currentJioCodeData?.shipment_id,
                                    });
                                }
                                if (damagedQuantity > 0) {
                                    requestPayload.push({
                                        cancelled_quantity: damagedQuantity,
                                        cancellation_reason: 'damaged',
                                        order_id: currentJioCodeData?.order_id || '',
                                        jio_code: currentJioCodeData?.jio_code || '',
                                        tote_code: '',
                                        shipment_id: currentJioCodeData?.shipment_id,
                                        cancelledDuringPacking: false,
                                    });
                                }
                                handleUpdateDamagedProducts(requestPayload);
                            } else {
                                pickingLocationStatus.mutate(pickingId ?? '', {
                                    onSuccess: (response) => {
                                        dispatch({ type: 'RESET_PICKLIST_ORDER' });
                                        dispatch({ type: 'RESET_QUANTITY_STATUS' });
                                        dispatch({
                                            type: 'SET_PICKLIST_INDEX',
                                            payload: 0,
                                        });
                                        handleMarkQuantityState(response?.data?.picking_complete);
                                    },
                                    onError: () => {
                                        setIsAPICallLoading(false);
                                    },
                                });
                            }
                        },
                        onError: () => {
                            setIsAPICallLoading(false);
                        },
                    }
                );
            },
            onError: () => {
                setIsAPICallLoading(false);
            },
        });
    }

    function handleMarkQuantityState(pickingCompleted: boolean) {
        savePicklistactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                activity_name: 'PICKING_JIO_CODE_SCAN',
                status: 'COMPLETED',
            },
            {
                onSuccess: () => {
                    setIsAPICallLoading(false);
                    if (pickingCompleted) {
                        setLocationScanCompleted(true);
                    } else {
                        setJioCodeScanCompleted(true);
                    }
                },
                onError: () => {
                    setIsAPICallLoading(false);
                },
            }
        );
    }

    const updateDamagedProducts = useUpdateDamagedProducts();
    const handleUpdateDamagedProducts = (data: UpdateDamagedProductsSchema[]) => {
        updateDamagedProducts.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    pickingLocationStatus.mutate(pickingId ?? '', {
                        onSuccess: (response) => {
                            dispatch({ type: 'RESET_PICKLIST_ORDER' });
                            dispatch({ type: 'RESET_QUANTITY_STATUS' });
                            dispatch({
                                type: 'SET_PICKLIST_INDEX',
                                payload: 0,
                            });
                            handleMarkQuantityState(response?.data?.picking_complete);
                        },
                    });
                }
            },
            onError(err: any) {
                setIsAPICallLoading(false);
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    function handlePickingSummaryState() {
        savePicklistactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                activity_name: 'GET_PICKING_SUMMARY',
                status: 'COMPLETED',
            },
            {
                onSuccess: () => {
                    dispatch({ type: 'RESET_SCANNED_GOOD_ITEMS' });
                    dispatch({ type: 'RESET_SCANNED_TOTAL_ITEMS' });
                    navigate(`../${pickingId}/scan-drop-zone`);
                },
            }
        );
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Mark Short/Damaged"
                    onBackIconClick={() => {
                        dispatch({
                            type: 'SET_QUANTITY_STATUS',
                            payload: { damaged: damagedQuantity, short: shortQuantity },
                        });
                        dispatch({
                            type: 'SET_SCANNED_TOTAL_ITEMS',
                            payload: scannedItemQuantity + shortQuantity + damagedQuantity,
                        });
                        navigate(-1);
                    }}
                />

                <div className={cn('mx-8 mt-4 pb-5')}>
                    <ProgressBar
                        label="Total Quantity"
                        value={
                            (scannedItemQuantity + shortQuantity + damagedQuantity) /
                            currentJioCodeData?.quantity
                        }
                        valueLabel={`${scannedItemQuantity + shortQuantity + damagedQuantity}/${
                            currentJioCodeData?.quantity
                        }`}
                    />
                </div>
            </div>
            <main className={cn('mx-6 mt-3 mb-20')}>
                <div className={cn('mt-6 items-center gap-y-6')}>
                    <div className={cn('my-3')}>
                        <IncrementDecrementSectionV2
                            borderColour="--color-primary-60"
                            defaultInputValue={shortQuantity}
                            inEditMode={editModes.short}
                            successCallBack={() => {}}
                            title="Short Quantity"
                            onChangeCallBack={(val) => {
                                setShortQuantity(val);
                            }}
                            onEditCallBack={() => {
                                setEditModes({
                                    short: true,
                                    damaged: false,
                                });
                            }}
                        />
                    </div>
                    <div className={cn('my-3')}>
                        <IncrementDecrementSectionV2
                            borderColour="--color-primary-60"
                            defaultInputValue={damagedQuantity}
                            inEditMode={editModes.damaged}
                            successCallBack={() => {}}
                            title="Damaged Quantity"
                            onChangeCallBack={(val) => {
                                setDamagedQuantity(val);
                            }}
                            onEditCallBack={() => {
                                setEditModes({
                                    short: false,
                                    damaged: true,
                                });
                            }}
                        />
                    </div>
                </div>
                <div
                    className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                    style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
                >
                    <Button
                        fullWidth
                        disabled={
                            scannedItemQuantity + shortQuantity + damagedQuantity !==
                            currentJioCodeData?.quantity
                        }
                        isLoading={isAPICallLoading}
                        size="large"
                        onClick={onUpdateJioCodeStatus}
                    >
                        Confirm
                    </Button>
                </div>
            </main>
            {jioCodeScanCompleted && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcSuccessColored"
                            size="xl"
                        />
                    }
                    isOpened={jioCodeScanCompleted}
                    message={`${scannedItemQuantity} / ${currentJioCodeData?.quantity} Jio Code ${currentJioCodeData?.jio_code} Picked successfully`}
                    primaryCTALabel="Okay"
                    onPrimaryCTAClick={() => {
                        dispatch({ type: 'RESET_SCANNED_GOOD_ITEMS' });
                        dispatch({ type: 'RESET_SCANNED_TOTAL_ITEMS' });
                        navigate(`../${pickingId}/${trolleyId}/${toteId}/scan-location`);
                    }}
                ></ConfirmationDialog>
            )}
            {locationScanCompleted && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcSuccessColored"
                            size="xl"
                        />
                    }
                    isOpened={locationScanCompleted}
                    //     message={`All Jio Codes are picked from
                    // Location ${currentJioCodeData?.location_id ?? queryParams.get('location_id')} `}
                    message="All Jio Codes are Picked Successfully"
                    primaryCTALabel="Continue"
                    onPrimaryCTAClick={() => {
                        localStorage.removeItem('picking_jio_code_scan_complete');
                        handlePickingSummaryState();
                    }}
                ></ConfirmationDialog>
            )}
        </div>
    );
};

export default PickListHelp;
