import { useNavigate, useParams } from 'react-router-dom';
import { ScanTotePage } from '../../../components/outbound/packing/ScanTote';
import { usePackingStateSave } from '../../../hooks/outbound/packing/usePackingStateSave';

export function ScanExcessItemsPage() {
    const navigate = useNavigate();
    const { stationCode, ContainerCode, packingType, orderId, shipmentId, jioCode, lotId } =
        useParams();
    const savePackingactivity = usePackingStateSave();
    function handleExcessItemsToteDropState() {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SCAN_DROP_TOTE_PACKING',
                status: 'COMPLETED',
                shipmentId: shipmentId,
                orderId: orderId,
                containerId: ContainerCode,
                containerType: packingType?.toUpperCase() === 'MULTI' ? 'crate' : 'tote',
                quantity: 1,
                jiocode: jioCode,
                shipmentType: packingType?.toUpperCase(),
                packingStation: stationCode,
                locationId: stationCode,
                productReceivingSource: 'PACKING_CANCELLED_ORDERS',
                productType: 'GOOD',
                lotId: Number(lotId) || 0,
            },
            {
                onSuccess: () => {
                    navigate(
                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/main`
                    );
                },
            }
        );
    }

    return (
        <ScanTotePage
            locationId={stationCode}
            lotId={Number(lotId)}
            pageHeader="Excess Items Tote"
            showBackIon={true}
            toteType="excess-items"
            onSuccess={() => {
                handleExcessItemsToteDropState();
            }}
        />
    );
}
