import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { handleError } from './useValidateTote';
import { Notifications } from '@jds/core';
import { urls } from '../../utils/urls';

export interface ToteTransferOptions {
    source_tote_code: string;
    target_tote_code: string;
    source_jio_code: string;
    target_jio_code: string;
    source_lot_id: number;
    target_lot_id: number;
    location_id: string;
    source_product_type: 'CUBISCAN';
    source_tote_purpose: 'ABOUT_TO_CUBISCAN';
    target_product_type: 'CUBISCAN';
    target_tote_purpose: 'CUBISCAN_RETURNED';
    quantity: number;
}

const defaultErrorMsg = 'Failed to drop tote. Please try again';
export function useTransferTote() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, ToteTransferOptions>(
        async (options) => {
            const response = await axios.post(urls.TRANSFER_TOTE(), {
                source_tote_code: options.source_tote_code,
                target_tote_code: options.target_tote_code,
                source_jio_code: options.source_jio_code,
                target_jio_code: options.target_jio_code,
                source_lot_id: options.source_lot_id,
                target_lot_id: options.target_lot_id,
                location_id: options.location_id,
                source_product_type: options.source_product_type,
                source_tote_purpose: options.source_tote_purpose,
                target_product_type: options.target_product_type,
                target_tote_purpose: options.target_tote_purpose,
                quantity: options.quantity,
            });
            return response.data;
        },
        {
            onSuccess() {
                Notifications.toast.success({
                    title: 'Success',
                    description: 'Tote dropped successfully',
                });
            },
            onError: (error) => handleError(error, defaultErrorMsg),
        }
    );
}

interface SuccessResponse {
    meta: {
        status: 'success';
        code: string;
    };
    data: 'success';
}

interface ErrorResponse {
    meta: {
        status: 'failure';
        code: string;
        errors: string[];
    };
}
