import { useNavigate, useParams } from 'react-router-dom';
import { useFreeTote } from '../../../hooks/outbound/consolidation/useFreeTote';
import { JioCodesListSchema } from '../../../hooks/outbound/consolidation/useJioCodesList';
import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';
import { useContext } from 'react';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';

export function ConsolidationSuccessPage() {
    const navigate = useNavigate();
    const { toteCode } = useParams();
    const { dispatch } = useContext(ConsolidationContext);

    const freeTote = useFreeTote();
    const handleFreeTote = (data: JioCodesListSchema) => {
        freeTote.mutate(data, {
            onSuccess() {
                navigate(`/`);
            },
            onError() {
                navigate('/');
            },
        });
    };

    return (
        <SuccessPageV2
            buttonText="Pick next task"
            message={`Items of tote ${toteCode}
            consolidated successfully`}
            onButtonClick={() => {
                dispatch({ type: 'RESET_ALL' });
                handleFreeTote({ toteId: toteCode || '' });
            }}
        />
    );
}
