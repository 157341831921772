import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Button, Container, Notifications, Text } from '@jds/core';

import { PageHeader } from '../../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { ProductCard } from '../../../components/shared/ProductCard';
import { PickListLocationResponse } from '../../../hooks/outbound/picklist/useGetPicklistLocation';
import { useMarkShortItem } from '../../../hooks/outbound/picklist/useMarkShortItem';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'jio-code', label: 'Jio Code', type: 'text' };

const MarkDamage = () => {
    const [damageItems, setDamageItems] = useState<string[]>([]);
    const navigate = useNavigate();
    const { pickingId } = useParams();

    const pickListLocationsData = useQueryClient().getQueryData<PickListLocationResponse>([
        'pickListLocationsData',
    ]);
    const pickingLocationsList = pickListLocationsData?.data?.item_details;
    const pickedJioCodeIndex = Number(localStorage.getItem('pickedJioCodeIndex'));
    const currentJioCodeData: any =
        pickingLocationsList &&
        pickingLocationsList?.length > pickedJioCodeIndex &&
        pickingLocationsList[pickedJioCodeIndex];
    const scannedItemQuantityTillNow = Number(
        JSON.parse(localStorage.getItem('scannedItemQuantityTillNow') || '0')
    );
    const toteId = localStorage.getItem('toteId');
    const trolleyId = localStorage.getItem('trolleyId');
    const markDamagedItems = useMarkShortItem();

    const updateMarkDamageItems = () => {
        if (
            Number(damageItems?.length) <=
            currentJioCodeData?.quantity - scannedItemQuantityTillNow
        ) {
            const data = [
                {
                    cancelled_quantity: damageItems?.length,
                    cancellation_reason: 'Item Damaged',
                    order_id: currentJioCodeData?.order_id,
                    jio_code: currentJioCodeData?.jio_code,
                },
            ];

            markDamagedItems.mutate(data, {
                onSuccess: () => {
                    const totalItems = scannedItemQuantityTillNow + damageItems?.length;

                    localStorage.setItem('scannedItemQuantityTillNow', JSON.stringify(totalItems));
                    if (totalItems === currentJioCodeData?.quantity) {
                        const pickedJioCodeIndex =
                            Number(localStorage.getItem('pickedJioCodeIndex')) + 1;
                        if (pickedJioCodeIndex === pickingLocationsList?.length) {
                            localStorage.setItem('locationCompleted', JSON.stringify(true));
                        } else {
                            localStorage.setItem(
                                'pickedJioCodeIndex',
                                JSON.stringify(pickedJioCodeIndex)
                            );
                            localStorage.setItem('jioCodeCompleted', JSON.stringify(true));
                        }
                    }
                    navigate(`../${pickingId}/${trolleyId}/${toteId}/scan-jio-code`);
                },
            });
        } else {
            Notifications.toast.error({
                title: 'Error',
                description: 'Number of short item is not correct.',
            });
        }
    };

    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <PageHeader
                title="Mark Damaged"
                onBackIconClick={() => {
                    navigate(-1);
                }}
            ></PageHeader>
            <main className={cn('mx-2 mt-4')}>
                <ScanOrEnterManuallyV2
                    enableScannerOnMount={true}
                    inputProps={manualInputProps}
                    onSuccess={(value) => {
                        const damagedItems = [...damageItems, value];
                        setDamageItems(damagedItems);
                    }}
                />
                <Container
                    background="primary-grey-20"
                    className={cn('my-4 space-y-3')}
                    pad="m"
                    rounded="large"
                >
                    <Text appearance="body-m" color="primary-grey-100">
                        Scanned Damaged{' '}
                        {damageItems.length > 1
                            ? `items ${damageItems.length}`
                            : `item ${damageItems.length}`}
                    </Text>
                    {damageItems?.length > 0 &&
                        damageItems.map((item) => {
                            return (
                                <Text key={item} appearance="body-m" color="primary-grey-60">
                                    {item}
                                </Text>
                            );
                        })}
                </Container>
                <div className={cn('mt-8')}>
                    <ProductCard
                        key={currentJioCodeData?.jio_code ?? '-'}
                        BBD={currentJioCodeData?.expiry_date}
                        EAN={currentJioCodeData?.ean ?? '-'}
                        MFD={currentJioCodeData?.manufacture_date}
                        MRP={currentJioCodeData?.mrp ?? '-'}
                        colorSize={`${currentJioCodeData?.color ?? '-'}, ${
                            currentJioCodeData?.size ?? '-'
                        }`}
                        image={currentJioCodeData?.image_urls?.at(0)}
                        title={currentJioCodeData?.product_name ?? '-'}
                    />
                </div>
                <div className={cn('fixed inset-x-2 bottom-8 mx-4')}>
                    <Button
                        fullWidth
                        kind="primary"
                        onClick={() => {
                            updateMarkDamageItems();
                        }}
                    >
                        Save Damaged
                    </Button>
                </div>
            </main>
        </div>
    );
};

export default MarkDamage;
