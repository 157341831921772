import { initialPicklistState } from './PicklistProvider';

export interface PicklistState {
    quantityStatus: QuantityStatus;
    picklistOrderObjList: {
        id: string;
        jio_code: string;
        quantity: number;
        total_quantity?: number;
        shipment_id: string;
        lot_id: number;
        quantity_not_picked: number;
    }[];
    pickedJioCodesObjList: {
        id: string;
        jio_code: string;
        quantity: number;
        total_quantity?: number;
        shipment_id: string;
        lot_id: number;
        quantity_not_picked: number;
    }[];
    scannedGoodPicklistItem: number;
    scannedItemQuantityTillNow: number;
    picklistIndex: number;
}

export interface QuantityStatus {
    damaged: number;
    short: number;
}

export type PicklistAction =
    | { type: 'SET_QUANTITY_STATUS'; payload: QuantityStatus }
    | { type: 'RESET_QUANTITY_STATUS' }
    | {
          type: 'SET_PICKLIST_ORDER';
          payload: {
              id: string;
              jio_code: string;
              quantity: number;
              total_quantity?: number;
              shipment_id: string;
              lot_id: number;
              quantity_not_picked: number;
          }[];
      }
    | { type: 'RESET_PICKLIST_ORDER' }
    | {
          type: 'SET_PICKLIST_JIOCODES';
          payload: {
              id: string;
              jio_code: string;
              quantity: number;
              total_quantity?: number;
              shipment_id: string;
              lot_id: number;
              quantity_not_picked: number;
          }[];
      }
    | { type: 'RESET_PICKLIST_JIOCODES' }
    | { type: 'SET_SCANNED_GOOD_ITEMS'; payload: number }
    | { type: 'RESET_SCANNED_GOOD_ITEMS' }
    | { type: 'SET_SCANNED_TOTAL_ITEMS'; payload: number }
    | { type: 'RESET_SCANNED_TOTAL_ITEMS' }
    | { type: 'SET_PICKLIST_INDEX'; payload: number }
    | { type: 'RESET_PICKLIST_INDEX' }
    | { type: 'RESET_ALL' };

export function picklistReducer(state: PicklistState, action: PicklistAction): PicklistState {
    switch (action.type) {
        case 'SET_QUANTITY_STATUS':
            return { ...state, quantityStatus: action.payload };
        case 'RESET_QUANTITY_STATUS':
            return { ...state, quantityStatus: initialPicklistState.quantityStatus };
        case 'SET_PICKLIST_ORDER':
            return { ...state, picklistOrderObjList: action.payload };
        case 'RESET_PICKLIST_ORDER':
            return { ...state, picklistOrderObjList: [] };
        case 'SET_PICKLIST_JIOCODES':
            return { ...state, pickedJioCodesObjList: action.payload };
        case 'RESET_PICKLIST_JIOCODES':
            return { ...state, pickedJioCodesObjList: [] };
        case 'SET_SCANNED_GOOD_ITEMS':
            return { ...state, scannedGoodPicklistItem: action.payload };
        case 'RESET_SCANNED_GOOD_ITEMS':
            return { ...state, scannedGoodPicklistItem: 0 };
        case 'SET_SCANNED_TOTAL_ITEMS':
            return { ...state, scannedItemQuantityTillNow: action.payload };
        case 'RESET_SCANNED_TOTAL_ITEMS':
            return { ...state, scannedItemQuantityTillNow: 0 };
        case 'SET_PICKLIST_INDEX':
            return { ...state, picklistIndex: action.payload };
        case 'RESET_PICKLIST_INDEX':
            return { ...state, picklistIndex: 0 };
        case 'RESET_ALL':
            return initialPicklistState;
        default:
            throw new Error('Unhandled action type');
    }
}
