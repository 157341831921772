import { useNavigate, useParams } from 'react-router-dom';
import { Text, Container, Divider } from '@jds/core';
import { cn } from '../../../utils/tailwind';
import { SuccessPageV2 } from '../../../components/shared/SuccessPageV2';
import { useContext } from 'react';
import { ConsolidationContext } from '../../../provider/outbound/consolidation';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function ParkedListPage() {
    const navigate = useNavigate();
    const { stationId, toteCode, shipmentId } = useParams();
    const { state, dispatch } = useContext(ConsolidationContext);
    const parkedCrateCodesTillNow = state.consolidationParkedCrateCodesList;

    const finalParkedCrateCodesList = parkedCrateCodesTillNow.find(
        (item) => item.shipmentId === shipmentId
    );

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn('flex')}>
                <SuccessPageV2
                    buttonText="Continue"
                    message="Order Consolidation completed, Send Listed crates to pack station"
                    onButtonClick={() => {
                        dispatch({ type: 'RESET_CONSOLIDATION_PARKED_CRATECODES' });
                        navigate(`/outbound/consolidation/${stationId}/${toteCode}/main`);
                    }}
                />
            </div>
            <main className={cn('mb-20')}>
                {parkedCrateCodesTillNow.length > 0 && (
                    <Container
                        background="primary-grey-20"
                        className={cn('mx-3 mt-4')}
                        pad="m"
                        rounded="large"
                    >
                        <Text
                            appearance="body-l-bold"
                            className={cn('flex ')}
                            color="primary-grey-100"
                        >
                            Order related crates
                        </Text>
                        <Container>
                            {finalParkedCrateCodesList?.crateIdList?.map((crateId, index) => (
                                <div key={crateId}>
                                    <Container className={cn('my-3 flex justify-between')}>
                                        <Text color="primary-grey-60"> {crateId} </Text>
                                    </Container>
                                    {index < parkedCrateCodesTillNow.length - 1 && <Divider />}
                                </div>
                            ))}
                        </Container>
                    </Container>
                )}
            </main>
        </div>
    );
}
