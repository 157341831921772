import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../utils/urls';
import { PackageResponse } from './usePackages';
import { Notifications } from '@jds/core';
import { useUnloadingVerification } from './useUnloadingVerification';
import { createAxiosError } from '../../utils/fetchHelper';

const defaultErrorMsg = 'An error occurred while fetching packages for given appointment number';
export function useFetchPackages() {
    const unloadingVerification = useUnloadingVerification();
    return useMutation<PackageResponse, AxiosError<PackageResponse>, number>(
        async (appointmentNumber: number) => {
            const unloadingResponse = await unloadingVerification.mutateAsync(appointmentNumber);
            const isUnloadingDone =
                unloadingResponse?.data?.inbound_flow_status?.toUpperCase() ===
                    'APPOINTMENT_UNLOADING_DONE' ||
                unloadingResponse?.data?.inbound_flow_status?.toUpperCase() ===
                    'APPOINTMENT_RECEIVING_STARTED';
            const isReceivingDone =
                unloadingResponse?.data?.inbound_flow_status?.toUpperCase() ===
                'APPOINTMENT_RECEIVING_DONE';
            if (isReceivingDone)
                throw createAxiosError('Receiving is already done for this appointment');
            else if (!isUnloadingDone) {
                throw createAxiosError('Unloading is not done for this appointment');
            }
            const response = await axios.get(urls.GET_PACKAGES(appointmentNumber));
            return response.data;
        },
        {
            onError(error) {
                const messages = error.response?.data?.meta?.errors ?? [defaultErrorMsg];
                messages.forEach((description) => {
                    Notifications.toast.error({ title: 'Error', description });
                });
            },
        }
    );
}
