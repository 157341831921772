import { Button, Heading, Icon, Modal, Text } from '@jds/core';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { cn } from '../utils/tailwind';
import { useLogoutUser } from '../hooks/auth/useLogoutUser';
import { layoutStyles } from '../styles/common';
import { IcLogout } from '@jds/core-icons';
import { SecondaryPageHeader } from '../components/shared/SecondaryPageHeader';

export type MainRoutes = 'inbound' | 'outbound' | 'returns' | 'inventory';

export function HomePage() {
    const routes: MainRoutes[] = ['inbound', 'outbound', 'returns', 'inventory'];
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);
    const logoutUser = useLogoutUser();

    return (
        <div className={cn(layoutStyles, 'min-h-screen')}>
            <div className={cn('mr-6')}>
                <SecondaryPageHeader showBackButton={false} />
            </div>
            <main className={cn('mx-6 mt-10 mb-20 flex flex-1 flex-col justify-center space-y-6')}>
                {routes.map((route) => (
                    <Link
                        key={route}
                        aria-label={`see all sections for ${route}`}
                        to={`/sections/${route}`}
                    >
                        <div
                            className={cn(
                                'w-full rounded-full py-6 text-center',
                                'border-2 border-[var(--color-primary-50)] '
                            )}
                        >
                            <Text appearance="body-s-bold" color="primary-50">
                                <span className={cn('capitalize')}>{route}</span>
                            </Text>
                        </div>
                    </Link>
                ))}
            </main>
            <div className={cn('fixed inset-x-0 bottom-0 bg-white py-2.5 px-6', layoutStyles)}>
                <Button
                    fullWidth
                    kind="tertiary"
                    type="button"
                    onClick={() => setShowLogoutDialog(true)}
                >
                    <div className={cn('flex items-center justify-center space-x-3')}>
                        <Text color="primary-grey-80">Logout</Text>
                        <Icon color="grey-80" ic={<IcLogout />} size="m" />
                    </div>
                </Button>
            </div>
            <Modal
                closed={!showLogoutDialog}
                size="m"
                onCloseCallback={() => setShowLogoutDialog(false)}
            >
                <div className={cn('text-center')}>
                    <Heading appearance="heading-xs"> Are you sure, you want to logout? </Heading>
                    <div className={cn('mt-12 flex space-x-2')}>
                        <Button kind="secondary" onClick={() => setShowLogoutDialog(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setShowLogoutDialog(false);
                                logoutUser();
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
