import { useCallback, useState } from 'react';

type MockScannerSuccessCallback = (value: string) => void;
export function mockScanner(callback: MockScannerSuccessCallback) {
    setTimeout(() => {
        callback('station50');
    }, 1000);
}

const mockScannerValues = {
    RECEIVING_PRIMARY_CASE_ID: '3',
    TOTE_ID: 'tote100',
    JIO_CODE: 'jio100',
    LOCATION_ID: 'location',
    RECEIVING_TOTE_ID_1: 'tote',
    RANDOM: `${Date.now()}`,
} as const;

/**
 * Start removing this as we have moved to the real scanner
 */
export type MockScannerKey = keyof typeof mockScannerValues;

export function useScanner() {
    const [loading, setLoading] = useState<boolean>(false);

    const scan = useCallback(function scan(
        callback: MockScannerSuccessCallback,
        scanTimeout?: number
    ) {
        setLoading(true);
        window.EB.Barcode.take({ allDecoders: true, scanTimeout }, ({ status, barcode }) => {
            setLoading(false);
            if (status === 'cancel') callback('');
            callback(barcode);
        });
    },
    []);

    return { loading, scan };
}
