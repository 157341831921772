import { MainRoutes } from '../HomePage';

interface RouteItem {
    title: string;
    path: string;
}
export const subSections: Record<MainRoutes, RouteItem[]> = {
    inbound: [
        {
            title: 'Unloading',
            path: '/unloading',
        },
        {
            title: 'Receiving',
            path: '/inbound/receiving',
        },
        {
            title: 'Cubiscan',
            path: '/cubiscan',
        },
        {
            title: 'Cubiscan To Receiving',
            path: '/cubiscan-to-receiving-station',
        },
        {
            title: 'Putaway',
            path: '/inbound/putaway',
        },
    ],
    outbound: [
        {
            title: 'Picklist',
            path: '/outbound/picklist',
        },
        {
            title: 'Consolidation',
            path: '/outbound/consolidation',
        },
        {
            title: 'Packing',
            path: '/outbound/packing',
        },
    ],
    returns: [
        {
            title: 'Unloading',
            path: '/returns/unloading',
        },
        {
            title: 'Receiving',
            path: '/returns/receiving',
        },
        {
            title: 'Putaway',
            path: '/returns/putaway',
        },
    ],
    inventory: [
        {
            title: 'Stock Take',
            path: '/inventory/stock-take',
        },
        {
            title: 'Stock Take Segregation',
            path: '/inventory/stock-take-segregation',
        },
        {
            title: 'Stock Take Putaway',
            path: '/inventory/stock-take-putaway',
        },
        {
            title: 'GTL Putaway',
            path: '/inventory/gtl-putaway',
        },
        {
            title: 'GTL',
            path: '/inventory/gtl',
        },
    ],
};
