import { cn } from '../../../utils/tailwind';
import { Container, Icon, Text } from '@jds/core';
import { format } from 'date-fns';
import { ProductCardWrapper } from './ProductCardWrapper';
import { IcSuccessColored } from '@jds/core-icons';

interface ProductCardInModalProps {
    EAN: string;
    MRP: number;
    MFD: number;
    BBD: number;
    jioCode: string;
    colorSize: string;
    title?: string;
    isLowFreshness?: boolean;
    image?: string;
}

export function ProductCardInModal({
    BBD,
    colorSize,
    EAN,
    MFD,
    MRP,
    jioCode,
    title,
    isLowFreshness,
    image,
}: ProductCardInModalProps) {
    return (
        <ProductCardWrapper>
            <Container className={cn('flex justify-between space-x-2.5')} pad="xs" rounded="large">
                <div className={cn('flex')}>
                    <img
                        alt="Product Image"
                        className={cn('h-[140px] w-[130px] rounded-2xl object-cover')}
                        src={image ? image : 'https://via.placeholder.com/88'}
                    />
                    <div className={cn('flex flex-col items-start space-y-0.5 pl-3')}>
                        <Text
                            appearance="body-xs-bold"
                            className={cn('mb-2')}
                            color="primary-grey-100"
                        >
                            EAN - {EAN}
                        </Text>
                        {title && (
                            <Text appearance="body-xs-link" color="primary-grey-80" title={title}>
                                {title}
                            </Text>
                        )}
                        <Text appearance="body-xxs" color="primary-grey-80">
                            JIOCODE {jioCode}
                        </Text>
                        <Text appearance="body-xxs" color="primary-grey-80">
                            MRP {MRP}
                        </Text>
                        <Text appearance="body-xxs" color="primary-grey-80">
                            MFD {MFD ? format(MFD, 'dd-MM-yyyy') : '_'}
                        </Text>
                        <Text appearance="body-xxs" color="primary-grey-80">
                            BBD {BBD ? format(BBD, 'dd-MM-yyyy') : '_'}
                        </Text>
                        <Text appearance="body-xxs" color="primary-grey-80">
                            Color & Size {colorSize}
                        </Text>
                        {isLowFreshness && (
                            <Text appearance="body-xxs" color="primary-grey-80">
                                low Freshness
                            </Text>
                        )}
                    </div>
                </div>
            </Container>
        </ProductCardWrapper>
    );
}
