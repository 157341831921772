import { Container, Text } from '@jds/core';
import { cn } from '../../utils/tailwind';

export interface gtlTaskListProps {
    uid: number;
    sourceLocationId?: string;
    sourceStorageArea?: string;
    sourceStorageZone: string;
    jioCode?: string;
    sellerCode?: string;
    destinationStorageArea?: string;
    destinationStorageZone?: string;
    destinationLocationId?: string;
    reason?: string;
    status?: string;
    assignedUser?: number;
    rfcId?: number;
    quantity?: number;
    damagedQuantity?: number;
    shortQuantity?: number;
    onItemClick?: () => void;
}

export function GtlTaskItem({ onItemClick, sourceStorageZone, uid }: gtlTaskListProps) {
    return (
        <Container
            as="div"
            background="primary-grey-20"
            className={cn('my-2 flex flex-col justify-center space-y-3')}
            pad="s"
            rounded="large"
            onClick={onItemClick}
        >
            <Text appearance="body-xs" color="primary-grey-60">
                Task ID - {uid}
            </Text>
            <Text appearance="body-xs" color="primary-grey-60">
                Storage zone - {sourceStorageZone}
            </Text>
        </Container>
    );
}
