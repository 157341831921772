import { Route } from 'react-router-dom';
import {
    ScanTotePage,
    ScanReceivingStation,
} from '../../../../pages/inbound/cubiscan-to-receiving';
import { SuccessPageV2 } from '../../SuccessPageV2';

export const CubiscanToReceivingRoutes = [
    <Route key="1" element={<ScanTotePage />} path="" />,
    <Route key="2" element={<ScanReceivingStation />} path=":toteId/scan-receiving-station" />,
    <Route key="3" element={<SuccessPageV2 message="Cubiscan task completed" />} path="success" />,
];
