import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { Button, Input, Notifications, ProgressBar, Text } from '@jds/core';
import { useQueryClient } from '@tanstack/react-query';

import { PageHeader } from '../../../components/shared/PageHeader';
import { ProductCard } from '../../../components/shared/ProductCard';
import { PickListLocationResponse } from '../../../hooks/outbound/picklist/useGetPicklistLocation';
import { useMarkShortItem } from '../../../hooks/outbound/picklist/useMarkShortItem';

const layoutStyles = 'max-w-screen-md md:mx-auto';

const MarkShort = () => {
    const navigate = useNavigate();
    const { pickingId } = useParams();
    const [shortQuantity, setShortQuantity] = useState(0);
    const [error, setError] = useState(false);
    const pickListLocationsData = useQueryClient().getQueryData<PickListLocationResponse>([
        'pickListLocationsData',
    ]);
    const pickingLocationsList = pickListLocationsData?.data?.item_details;
    const pickedJioCodeIndex = Number(localStorage.getItem('pickedJioCodeIndex'));
    const currentJioCodeData: any =
        pickingLocationsList &&
        pickingLocationsList?.length > pickedJioCodeIndex &&
        pickingLocationsList[pickedJioCodeIndex];
    const scannedItemQuantityTillNow = Number(
        JSON.parse(localStorage.getItem('scannedItemQuantityTillNow') || '0')
    );
    const toteId = localStorage.getItem('toteId');
    const trolleyId = localStorage.getItem('trolleyId');
    const markShortItems = useMarkShortItem();
    const updateMarkShortItems = () => {
        if (
            Number(shortQuantity) > 0 &&
            Number(shortQuantity) <= currentJioCodeData?.quantity - scannedItemQuantityTillNow
        ) {
            const data = [
                {
                    cancelled_quantity: shortQuantity,
                    cancellation_reason: 'Item Short',
                    order_id: currentJioCodeData?.order_id,
                    jio_code: currentJioCodeData?.jio_code,
                },
            ];

            markShortItems.mutate(data, {
                onSuccess: () => {
                    const totalItems = scannedItemQuantityTillNow + shortQuantity;
                    localStorage.setItem('scannedItemQuantityTillNow', JSON.stringify(totalItems));
                    if (totalItems === currentJioCodeData?.quantity) {
                        const pickedJioCodeIndex =
                            Number(localStorage.getItem('pickedJioCodeIndex')) + 1;
                        if (pickedJioCodeIndex === pickingLocationsList?.length) {
                            localStorage.setItem('locationCompleted', JSON.stringify(true));
                        } else {
                            localStorage.setItem(
                                'pickedJioCodeIndex',
                                JSON.stringify(pickedJioCodeIndex)
                            );
                            localStorage.setItem('jioCodeCompleted', JSON.stringify(true));
                        }
                    }
                    navigate(`../${pickingId}/${trolleyId}/${toteId}/scan-jio-code`);
                },
            });
        } else {
            Notifications.toast.error({
                title: 'Error',
                description: 'Number of short item is not correct.',
            });
        }
    };
    return (
        <div className={cn(layoutStyles, 'mx-3 py-3')}>
            <PageHeader
                title="Mark Short"
                onBackIconClick={() => {
                    navigate(-1);
                }}
            ></PageHeader>
            <main className={cn('mx-2 mt-4')}>
                <div className={cn('mr-10 flex items-center justify-between')}>
                    <Text appearance="body-m" color="primary-grey-100">
                        Short Quantity
                    </Text>
                    <Input
                        className={cn('max-w-[51px]')}
                        inputMode="numeric"
                        label=""
                        name="assigned-quantity"
                        state={error ? 'error' : 'success'}
                        type="number"
                        value={shortQuantity || ''}
                        onChange={(event) => {
                            if (
                                Number(event.target.value) <=
                                currentJioCodeData?.quantity - scannedItemQuantityTillNow
                            ) {
                                setError(false);
                                setShortQuantity(Number(event.target.value));
                            } else {
                                setError(true);
                                Notifications.toast.error({
                                    title: 'Error',
                                    description: 'Wrong number of short quantity entered.',
                                });
                            }
                        }}
                    />
                </div>
                <ProgressBar
                    className={cn('mt-8')}
                    label="Packages"
                    state="error"
                    value={
                        (shortQuantity + scannedItemQuantityTillNow) / currentJioCodeData?.quantity
                    }
                    valueLabel={`${shortQuantity + scannedItemQuantityTillNow}/${
                        currentJioCodeData?.quantity
                    }`}
                />
                <div className={cn('mt-8')}>
                    <ProductCard
                        key={currentJioCodeData?.jio_code ?? '-'}
                        BBD={currentJioCodeData?.expiry_date}
                        EAN={currentJioCodeData?.ean ?? '-'}
                        MFD={currentJioCodeData?.manufacture_date}
                        MRP={currentJioCodeData?.mrp ?? '-'}
                        colorSize={`${currentJioCodeData?.color ?? '-'}, ${
                            currentJioCodeData?.size ?? '-'
                        }`}
                        image={currentJioCodeData?.image_urls?.at(0)}
                        title={currentJioCodeData?.product_name ?? '-'}
                    />
                </div>
                <div className={cn('fixed inset-x-2 bottom-8 mx-4')}>
                    <Button fullWidth kind="primary" onClick={() => updateMarkShortItems()}>
                        Save
                    </Button>
                </div>
            </main>
        </div>
    );
};

export default MarkShort;
