import { initialCubiscanState } from './CubiscanProvider';

export interface CubiscanState {
    toteDetails: ToteInfo;
}

export interface ToteInfo {
    tote_code: string;
    mapped_zone: string;
    mapped_product_type: string;
    tote_purpose: string;
    products: productInfo[];
}

interface productInfo {
    jio_code: string;
    lot_id: number;
    total_quantity: number;
    current_quantity: number;
    status: string;
}

export type CubiscanAction =
    | { type: 'SET_TOTE_DETAILS'; payload: ToteInfo }
    | { type: 'RESET_TOTE_DETAILS' }
    | { type: 'RESET_ALL' };

export function cubiscanReducer(state: CubiscanState, action: CubiscanAction): CubiscanState {
    switch (action.type) {
        case 'SET_TOTE_DETAILS':
            return { ...state, toteDetails: action.payload };
        case 'RESET_ALL':
            return { ...initialCubiscanState };
        default:
            throw new Error('Unhandled action type');
    }
}
