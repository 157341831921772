import { cn } from '../../../utils/tailwind';
import { PageHeader } from '../../../components/shared/PageHeader';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Spinner, Text } from '@jds/core';
import { useReturnProducts } from '../../../hooks/returns/unloading/useReturnProducts';
import { ReturnItem } from '../../../components/returns/Unloading';
import { useContext } from 'react';
import { UnloadingContext } from '../../../provider/returns/unloading';
import { useReturnsUnloading } from '../../../hooks/returns/unloading/useReturnsUnloading';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function MarkShortPage() {
    const navigate = useNavigate();
    const returnsUnloading = useReturnsUnloading();
    const { data: products, isLoading } = useReturnProducts();
    const { completedProducts: completedJioCodes } = useContext(UnloadingContext).state;
    const uncompletedProducts =
        products?.filter(({ jio_code }) => !completedJioCodes.includes(jio_code)) ?? [];
    const completedProducts =
        products?.filter(({ jio_code }) => completedJioCodes.includes(jio_code)) ?? [];

    return (
        <div className={cn(layoutStyles, 'mt-3')}>
            <PageHeader title="Return's Unloading" onBackIconClick={() => navigate(-1)} />
            <main className={cn('mx-6 mt-6 mb-20')}>
                <div className={cn('space-y-6')}>
                    <Text appearance="body-s-bold">List - Items returned</Text>
                    {isLoading && (
                        <div className={cn('flex justify-center')}>
                            <Spinner size="small" />
                        </div>
                    )}
                    {uncompletedProducts?.map((product, index) => (
                        <div key={product.jio_code}>
                            <ReturnItem product={product} />
                            {uncompletedProducts?.length !== index + 1 && (
                                <Divider className={cn('-mx-6 -mb-6')} padding="m" />
                            )}
                        </div>
                    ))}
                </div>
            </main>
            <div className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}>
                <Button
                    fullWidth
                    isLoading={returnsUnloading.isLoading}
                    size="large"
                    type="button"
                    onClick={() => {
                        returnsUnloading.mutate(
                            { completedProducts, uncompletedProducts },
                            { onSuccess: () => navigate('./../returns-unloading-success') }
                        );
                    }}
                >
                    Mark short
                </Button>
            </div>
        </div>
    );
}
