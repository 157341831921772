import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../utils/tailwind';
import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import { Notifications } from '@jds/core';
import {
    UpdatePackagingSuggestionSchema,
    useUpdatePackingSuggestion,
} from '../../../hooks/outbound/packing/useUpdatePackingSuggestion';
import {
    usePrintPackingLabels,
    PackingLabelsSchema,
} from '../../../hooks/outbound/packing/usePrintLabels';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { layoutStyles, pageHeaderStyle } from '../../../styles/common';
import { usePackingStateSave } from '../../../hooks/outbound/packing/usePackingStateSave';

const manualInputProps = { name: 'packaging-id', label: 'Packaging ID', type: 'text' };
export function ScanPackagingIDPage() {
    const navigate = useNavigate();
    const { stationCode, ContainerCode, packingType, orderId, shipmentId, packagingId, jioCode } =
        useParams();
    const savePackingactivity = usePackingStateSave();
    const updatePackingSuggestion = useUpdatePackingSuggestion();
    const handlePackageSuggestion = (data: UpdatePackagingSuggestionSchema) => {
        updatePackingSuggestion.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status?.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    handlePackagingIdScanState(data.awbRefNumber);
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };
    const generatePackingLabels = usePrintPackingLabels();
    const handleInvoiceLabels = (data: PackingLabelsSchema) => {
        generatePackingLabels.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    Notifications.toast.success({
                        title: 'Success',
                        description: 'Invoice Label Generated successfully',
                    });
                    handleShippingLabels({
                        shipmentId: data.shipmentId,
                        packagingId: data.packagingId,
                        labelType: 'SHIPPING',
                        awbRefNumber: data.awbRefNumber,
                    });
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };
    const handleShippingLabels = (data: PackingLabelsSchema) => {
        generatePackingLabels.mutate(data, {
            onSuccess(res: any) {
                if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                    Notifications.toast.error({
                        title: 'Error',
                        description: res.meta.errors.join(', '),
                    });
                } else {
                    handlePackageSuggestion({
                        shipmentId: data.shipmentId,
                        packageId: data.packagingId,
                        awbRefNumber: data.awbRefNumber,
                        containerId: ContainerCode,
                        containerType: packingType?.toUpperCase() === 'MULTI' ? 'crate' : 'tote',
                    });
                    Notifications.toast.success({
                        title: 'Success',
                        description: 'Shippping Label Generated successfully',
                    });
                }
            },
            onError(err: any) {
                if (err?.response?.data?.meta?.errors) {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.response.data.meta.errors.join(', ') ?? err.message,
                    });
                } else {
                    Notifications.toast.error({
                        title: 'Error',
                        description: err.message,
                    });
                }
            },
        });
    };

    function handlePackagingIdScanState(awbRefNumber: string) {
        savePackingactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                rfcId: Number(sessionStorage.getItem('rfc-id')) ?? '',
                activity_name: 'SAVE_PACK_CONFIRMATION',
                status: 'COMPLETED',
                shipmentId: shipmentId,
                orderId: orderId,
                containerId: ContainerCode,
                containerType: packingType?.toUpperCase() === 'MULTI' ? 'crate' : 'tote',
                quantity: 1,
                jiocode: jioCode,
                shipmentType: packingType?.toUpperCase(),
                packingStation: stationCode,
                locationId: stationCode,
                packingId: Number(packagingId) || 0,
                awbRefNumber: awbRefNumber,
            },
            {
                onSuccess: () => {
                    navigate(
                        `/outbound/packing/${stationCode}/${ContainerCode}/${packingType}/${orderId}/${shipmentId}/${jioCode}/success`
                    );
                },
            }
        );
    }

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Packaging ID"
                    onBackIconClick={() => Notifications.toast.error('Unable to navigate back')}
                />

                <main className={cn('mt-6 pb-3')}>
                    <ScanOrEnterManuallyV2
                        autoSuggest={true}
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        searchableList={[{ result: packagingId || '' }]}
                        onSuccess={(value) => {
                            // add validation
                            if (packagingId === value) {
                                handleInvoiceLabels({
                                    labelType: 'INVOICE',
                                    shipmentId: shipmentId || '',
                                    packagingId: Number(value),
                                    awbRefNumber: (+new Date()).toString(36).slice(-10),
                                });
                            } else
                                Notifications.toast.error({
                                    title: 'Error',
                                    description: 'Invalid Packaging ID',
                                });
                        }}
                    />
                </main>
            </div>
        </div>
    );
}
