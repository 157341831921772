import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../utils/tailwind';
import { Icon } from '@jds/core';
import { useQueryClient } from '@tanstack/react-query';

import { PageHeader } from '../../components/shared/PageHeader';
import { ScanOrEnterManuallyV2 } from '../../components/shared/ScanOrEnterManuallyV2';
import { useGtlToteValidation } from '../../hooks/gtl/useGtlToteValidation';
import { gtlTaskListProps } from '../../components/gtl/GtlTaskItem';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';
import { toteSchema } from '../../hooks/gtl/useUpdateGtlJioCodeStatus';
import { GtlTaskListResponse } from '../../hooks/gtl/useGetGtlTaskList';
import { ProductDataSchema } from '../../hooks/gtl/useGetProductDetails';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const manualInputProps = { name: 'tote-code', label: 'Scan Tote', type: 'text' };

const ScanGtlTote = () => {
    const [apiErrors, setApiErrors] = useState({
        showError: false,
        errorMessage: '',
    });
    const navigate = useNavigate();
    const { locationId } = useParams();

    const validateGtlTote = useGtlToteValidation();
    const gtlTaskList = useQueryClient().getQueryData<GtlTaskListResponse>(['gtlTaskList']);
    const gtlLocationsList: any = gtlTaskList?.data.filter(
        (item) => item.sourceLocationId === locationId
    );
    const pickedGtlJioCodeIndex = Number(localStorage.getItem('pickedGtlJioCodeIndex'));
    const currentJioCodeData: gtlTaskListProps = gtlLocationsList[pickedGtlJioCodeIndex];
    const productDetails: any = useQueryClient().getQueryData<ProductDataSchema>([
        'productDetails',
    ]);

    function onScanSuccess(toteCode: string) {
        const data = {
            tote_code: toteCode,
            jio_code: currentJioCodeData?.jioCode ?? '',
            lot_id: productDetails?.lotInformationDTO?.lotId,
            quantity: 1,
            product_type: 'GOOD',
            location_id: currentJioCodeData?.sourceLocationId ?? '',
            tote_purpose: 'GTL_PUT_AWAY',
        };

        validateGtlTote.mutate(data, {
            onSuccess: () => {
                navigate(`../${locationId}/scan-gtl-jioCode`);
                const toteObj: toteSchema = { tote_code: toteCode, quantity: 0 };
                localStorage.setItem('gtlToteIds', JSON.stringify([toteObj]));
            },
            onError(err: any) {
                if (err?.response?.data?.message) {
                    setApiErrors({
                        errorMessage: err.response.data.message,
                        showError: true,
                    });
                } else {
                    setApiErrors({
                        errorMessage: 'Tote not available for picking',
                        showError: true,
                    });
                }
            },
        });
    }

    return (
        <>
            <div className={cn(layoutStyles, 'mx-3 py-3')}>
                <PageHeader
                    showHelpIcon
                    title="GTL"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                    onHelpIconClick={() => navigate(`../gtl-task-status`)}
                />
                <main className={cn('mx-2 mt-3')}>
                    <ScanOrEnterManuallyV2
                        enableScannerOnMount={true}
                        inputProps={manualInputProps}
                        onSuccess={(toteCode) => {
                            onScanSuccess(toteCode);
                        }}
                    />
                </main>
            </div>
            {apiErrors.showError && (
                <ConfirmationDialog
                    icon={
                        <Icon
                            className={cn('mb-4')}
                            color="primary"
                            ic="IcErrorColored"
                            size="xl"
                        />
                    }
                    isOpened={apiErrors.showError}
                    message={apiErrors.errorMessage}
                    primaryCTALabel="Pick Another Tote"
                    onPrimaryCTAClick={() => {
                        setApiErrors({
                            errorMessage: '',
                            showError: false,
                        });
                    }}
                />
            )}
        </>
    );
};

export default ScanGtlTote;
