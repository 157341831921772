import { Route } from 'react-router-dom';
import { ConsolidationHomePage } from '../../../../pages/outbound/consolidation/ConsolidationHomePage';
import { ConsolidationLocationPage } from '../../../../pages/outbound/consolidation/ConsolidationLocation';
import { ConsolidationMainPage } from '../../../../pages/outbound/consolidation/ConsolidationMainPage';
import { ConsolidationSuccessPage } from '../../../../pages/outbound/consolidation/ConsolidationSuccessPage';
import { ConsolidationSuccessPopups } from '../../../../pages/outbound/consolidation/ConsolidationSuccessPopups';
import { CrateLocationPage } from '../../../../pages/outbound/consolidation/CrateLocation';
import { DamagedTotePage } from '../../../../pages/outbound/consolidation/DamagedTote';
import { DropUnknownItemPage } from '../../../../pages/outbound/consolidation/DropUnknownItemPage';
import { MarkDamagedPage } from '../../../../pages/outbound/consolidation/MarkdamagedPage';
import { ConsolidationMarkShortPage } from '../../../../pages/outbound/consolidation/MarkShortPage';
import { NewCratePage } from '../../../../pages/outbound/consolidation/NewCratePage';
import { ParkedListPage } from '../../../../pages/outbound/consolidation/ParkedListPage';
import { ScanDropZone } from '../../../../pages/outbound/consolidation/ScanDropZonePage';
import { ScanNewDamagedTote } from '../../../../pages/outbound/consolidation/ScanNewDamagedTotePage';
import { UnknownItemToteDropPage } from '../../../../pages/outbound/consolidation/UnknownItemToteDropPage';
import { ScanNewUnknownItemTotePage } from '../../../../pages/outbound/consolidation/ScanNewUnknownItemPage';

export const ConsolidationRoutes = [
    <Route key="1" element={<ScanDropZone />} path="" />,
    <Route key="2" element={<ConsolidationHomePage />} path=":stationId/scan-tote" />,
    <Route key="3" element={<ConsolidationMainPage />} path=":stationId/:toteCode/main" />,
    <Route key="4" element={<MarkDamagedPage />} path=":stationId/:toteCode/mark-damaged" />,
    <Route
        key="5"
        element={<ConsolidationMarkShortPage />}
        path=":stationId/:toteCode/mark-short"
    />,
    <Route
        key="6"
        element={<DamagedTotePage />}
        path=":stationId/:toteCode/:jioCode/:shipmentId/:lotId/drop-damaged-item"
    />,
    <Route
        key="7"
        element={<ScanNewDamagedTote />}
        path=":stationId/:toteCode/:jioCode/:shipmentId/:lotId/scan-new-damaged-tote"
    />,
    <Route
        key="8"
        element={<UnknownItemToteDropPage />}
        path=":stationId/:toteCode/:jioCode/:shipmentId/:lotId/drop-unknown-item"
    />,
    <Route
        key="9"
        element={<ScanNewUnknownItemTotePage />}
        path=":stationId/:toteCode/:jioCode/:shipmentId/:lotId/scan-new-unknown-tote"
    />,
    <Route
        key="10"
        element={<ConsolidationLocationPage />}
        path=":stationId/:toteCode/:jioCode/:shipmentId/:ptwLocation/:crateId/location-details"
    />,
    <Route
        key="11"
        element={<NewCratePage />}
        path=":stationId/:toteCode/:jioCode/:shipmentId/:ptwLocation/new-crate"
    />,
    <Route
        key="12"
        element={<CrateLocationPage />}
        path=":stationId/:toteCode/:jioCode/:shipmentId/:ptwLocation/:crateId/scan-crate-location"
    />,
    <Route key="13" element={<ParkedListPage />} path=":stationId/:toteCode/:shipmentId/success" />,
    <Route
        key="14"
        element={<ConsolidationSuccessPopups />}
        path=":stationId/:toteCode/bulk/success"
    />,
    <Route key="15" element={<ConsolidationSuccessPage />} path=":stationId/:toteCode/success" />,
];
