import { createContext, Dispatch, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import { PicklistAction, picklistReducer, PicklistState } from './PicklistReducer';

export const initialPicklistState: PicklistState = {
    quantityStatus: {
        damaged: 0,
        short: 0,
    },
    picklistOrderObjList: [],
    pickedJioCodesObjList: [],
    scannedGoodPicklistItem: 0,
    scannedItemQuantityTillNow: 0,
    picklistIndex: 0,
};

interface PicklistContextType {
    state: PicklistState;
    dispatch: Dispatch<PicklistAction>;
}

export const PicklistContext = createContext<PicklistContextType>({
    state: initialPicklistState,
    dispatch: () => {},
});

export function PicklistProvider() {
    const [state, dispatch] = useReducer(picklistReducer, initialPicklistState);

    return (
        <PicklistContext.Provider value={{ state, dispatch }}>
            <Outlet />
        </PicklistContext.Provider>
    );
}
