import { cn } from '../../../utils/tailwind';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Icon, ProgressBar, Text } from '@jds/core';
import { IcEditPen, IcHelp, IcWarningColored } from '@jds/core-icons';
import { useContext, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReceivingContext } from '../../../provider/inbound/receiving';
import { QuantityStatus } from '../../../provider/inbound/receiving/ReceivingReducer';
import { useForm } from 'react-hook-form';
import { useCurrentPackage } from '../../../hooks/receiving/usePackages';
import { useQueryParams } from '../../../hooks/shared/useQueryParams';
import { Package } from '../../../types/receiving';
import { IncrementDecrementSectionV2 } from '../../../components/shared/IncrementDecrementSectionV2';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { ProductDetailsModal } from '../../../components/receiving/ProductDetailsModal';

const layoutStyles = 'max-w-screen-md md:mx-auto';
const possibleReasons: { label: string; value: keyof ASNValidationSchema }[] = [
    { label: 'Damage', value: 'damagedQuantity' },
    { label: 'Batch Issue', value: 'batchIssueQuantity' },
    { label: 'Title Mismatch', value: 'wrongQuantity' },
    { label: 'Low Freshness', value: 'lowFreshnessQuantity' },
    { label: 'Expired', value: 'expiredQuantity' },
];

function getDefaultValues(
    lowFreshnessOrExpired: string | null,
    product?: Package,
    quantityStatus?: QuantityStatus
) {
    const defaultValues = possibleReasons.reduce((acc, { value }) => {
        acc[value] = 0;
        return acc;
    }, {} as ASNValidationSchema);
    if (lowFreshnessOrExpired === 'LOW_FRESHNESS')
        defaultValues.lowFreshnessQuantity = product?.itemQuantity ?? 0;
    else defaultValues.lowFreshnessQuantity = quantityStatus?.lowFreshness;
    if (lowFreshnessOrExpired === 'EXPIRED')
        defaultValues.expiredQuantity = product?.itemQuantity ?? quantityStatus?.expired;
    else defaultValues.expiredQuantity = quantityStatus?.expired;
    return defaultValues;
}

const asnValidationSchema = yup.object({
    goodQuantity: yup.number().typeError('').min(0),
    damagedQuantity: yup.number().typeError('').min(0),
    wrongQuantity: yup.number().typeError('').min(0),
    expiredQuantity: yup.number().typeError('').min(0),
    batchIssueQuantity: yup.number().typeError('').min(0),
    lowFreshnessQuantity: yup.number().typeError('').min(0),
    shortQuantity: yup.number().typeError('').min(0),
    excessQuantity: yup.number().typeError('').min(0),
});
export type ASNValidationSchema = yup.InferType<typeof asnValidationSchema>;

export function VerifyQuantitiesPage() {
    const [navigate, queryParams] = [useNavigate(), useQueryParams()];
    const [productModal, setProductModal] = useState(false);
    const [editModes, setEditModes] = useState({
        good: false,
        short: false,
        excess: false,
        failed: false,
    });
    const [currentPackage] = [useCurrentPackage()];
    const {
        dispatch,
        state: { quantityStatus },
    } = useContext(ReceivingContext);

    const lowFreshnessOrExpired = queryParams.get('isLowFreshnessOrExpired') ?? '';
    const isLowFreshnessOrExpired = ['EXPIRED', 'LOW_FRESHNESS'].includes(lowFreshnessOrExpired);
    const totalQuantity = currentPackage?.itemQuantity ?? 0;
    const defaultTotalQuantity = isLowFreshnessOrExpired ? totalQuantity : 0;
    const [totalFailedQuantity, setTotalFailedQuantity] = useState(defaultTotalQuantity);
    const [totalFormQuantity, setTotalFormQuantity] = useState(defaultTotalQuantity);
    const isQuantityValid = totalFormQuantity === totalQuantity;
    const { handleSubmit, control } = useForm<ASNValidationSchema>({
        resolver: yupResolver(asnValidationSchema),
        defaultValues: {
            ...getDefaultValues(lowFreshnessOrExpired, currentPackage, quantityStatus),
            goodQuantity: quantityStatus.good,
            excessQuantity: quantityStatus.excess,
            shortQuantity: quantityStatus.short,
            damagedQuantity: quantityStatus.damaged,
            wrongQuantity: quantityStatus.wrong,
            batchIssueQuantity: quantityStatus.batchIssue,
        },
    });

    const handleTotalFormQuantity = () => {
        const formQuantity = Object.values(control._formValues).reduce(
            (acc, value) => acc + Number(value),
            0
        );
        const isNotValid = Number.isNaN(formQuantity) || formQuantity < 0;
        setTotalFormQuantity(isNotValid ? 0 : formQuantity - control._formValues['excessQuantity']);

        const totalFailedQuantity = possibleReasons.reduce(
            (acc, { value }) =>
                acc + Number(control._formValues[value as keyof typeof control._formValues]),
            0
        );
        const isNotValidFailedQuantity =
            Number.isNaN(totalFailedQuantity) || totalFailedQuantity < 0;
        setTotalFailedQuantity(isNotValidFailedQuantity ? 0 : totalFailedQuantity);
    };

    function onFormSubmit(formValues: ASNValidationSchema) {
        const updatedFormValues: QuantityStatus = {
            good: formValues.goodQuantity ?? 0,
            batchIssue: formValues.batchIssueQuantity ?? 0,
            damaged: formValues.damagedQuantity ?? 0,
            excess: formValues.excessQuantity ?? 0,
            expired: formValues.expiredQuantity ?? 0,
            lowFreshness: formValues.lowFreshnessQuantity ?? 0,
            short: formValues.shortQuantity ?? 0,
            wrong: formValues.wrongQuantity ?? 0,
            failed: 0,
            cubiscan: queryParams.get('cubiscanRequired') === 'true' ? 1 : 0,
        };
        updatedFormValues.failed =
            updatedFormValues.batchIssue +
            updatedFormValues.damaged +
            updatedFormValues.expired +
            updatedFormValues.lowFreshness +
            updatedFormValues.wrong;
        dispatch({ type: 'SET_QUANTITY_STATUS', payload: updatedFormValues });
        navigate({ pathname: 'summary', search: queryParams.toString() });
    }

    return (
        <>
            <form className={cn(layoutStyles, 'pb-3')} onSubmit={handleSubmit(onFormSubmit)}>
                <div className={cn(pageHeaderStyle)}>
                    <PageHeaderV2
                        isExtended
                        imageUrl={
                            currentPackage?.imageUrls?.[0] ??
                            'https://i.ibb.co/whKwM3C/51-Zjm7a-DMa-L-SY450.jpg'
                        }
                        title="EANs"
                        onBackIconClick={() => {
                            navigate(-1);
                        }}
                        onImageClick={() => setProductModal(true)}
                    />
                    <div className={cn('mx-6 mt-3 space-y-2.5 pb-5')}>
                        <ProgressBar
                            label="Quantity"
                            value={totalFormQuantity / totalQuantity}
                            valueLabel={`${totalFormQuantity} / ${totalQuantity}`}
                        />
                        {queryParams.get('cubiscanRequired') === 'true' && (
                            <div className="flex items-center space-x-1">
                                <Text appearance="body-xs-link" color="primary-30">
                                    Cubiscan needed for the batch
                                </Text>
                                <Icon ic={<IcHelp color="#9999FF" />} size="s" />
                            </div>
                        )}
                    </div>
                </div>
                <main className={cn('mx-6 mt-3 mb-20')}>
                    <div className={cn('mt-6 items-center gap-y-6')}>
                        <div className={cn('my-3')}>
                            <IncrementDecrementSectionV2
                                borderColour="--color-primary-60"
                                defaultInputValue={control._formValues['goodQuantity']}
                                inEditMode={editModes.good}
                                successCallBack={() => {}}
                                title="Good Quantity"
                                type="good"
                                onChangeCallBack={(val) => {
                                    control._formValues['goodQuantity'] = val;
                                    handleTotalFormQuantity();
                                }}
                                onEditCallBack={() => {
                                    setEditModes({
                                        short: false,
                                        excess: false,
                                        failed: false,
                                        good: true,
                                    });
                                }}
                            />
                        </div>
                        <div className={cn('my-3')}>
                            <IncrementDecrementSectionV2
                                borderColour="--color-primary-60"
                                defaultInputValue={control._formValues['shortQuantity']}
                                inEditMode={editModes.short}
                                successCallBack={() => {}}
                                title="Short Quantity"
                                onChangeCallBack={(val) => {
                                    control._formValues['shortQuantity'] = val;
                                    handleTotalFormQuantity();
                                }}
                                onEditCallBack={() => {
                                    setEditModes({
                                        short: true,
                                        excess: false,
                                        failed: false,
                                        good: false,
                                    });
                                }}
                            />
                        </div>
                        <div className={cn('my-3')}>
                            <IncrementDecrementSectionV2
                                borderColour="--color-primary-60"
                                defaultInputValue={control._formValues['excessQuantity']}
                                inEditMode={editModes.excess}
                                successCallBack={() => {}}
                                title="Excess Quantity"
                                onChangeCallBack={(val) => {
                                    control._formValues['excessQuantity'] = val;
                                    handleTotalFormQuantity();
                                }}
                                onEditCallBack={() => {
                                    setEditModes({
                                        short: false,
                                        excess: true,
                                        failed: false,
                                        good: false,
                                    });
                                }}
                            />
                        </div>
                        <Container
                            background={!editModes.failed ? `primary-background` : `primary-20`}
                            className={cn('space-x-2.5 !rounded-xl  border-solid', {
                                [`border border-[var(--color-primary-60)]`]: !editModes.failed,
                            })}
                            pad="s"
                            rounded="medium"
                        >
                            <div className={cn('flex justify-between')}>
                                <label className={cn('flex')} htmlFor="failed-quantity">
                                    <Icon
                                        aria-label="edit"
                                        color="primary"
                                        ic={<IcWarningColored />}
                                        size="m"
                                        onClick={() => {}}
                                    />
                                    <Text appearance="body-s" as="span" color="primary-grey-100">
                                        {`Failed Quantity (${totalFailedQuantity})`}
                                    </Text>
                                </label>
                                {!editModes.failed && (
                                    <Icon
                                        aria-label="edit"
                                        color="primary"
                                        ic={<IcEditPen />}
                                        onClick={() => {
                                            setEditModes({
                                                short: false,
                                                excess: false,
                                                failed: true,
                                                good: false,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                            <div className={cn('mt-8 grid gap-y-6')}>
                                {possibleReasons.map(({ label, value }) => (
                                    <div key={value}>
                                        <IncrementDecrementSectionV2
                                            borderColour={undefined}
                                            defaultInputValue={control._formValues[value]}
                                            inEditMode={editModes.failed}
                                            showEditIcon={false}
                                            successCallBack={() => {}}
                                            title={label}
                                            type="failed"
                                            onChangeCallBack={(val) => {
                                                control._formValues[value] = val;
                                                handleTotalFormQuantity();
                                            }}
                                            onEditCallBack={() => {}}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Container>
                    </div>
                </main>
                <div
                    className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}
                    style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
                >
                    <Button fullWidth disabled={!isQuantityValid} size="large" type="submit">
                        Submit
                    </Button>
                </div>
            </form>
            {productModal && (
                <ProductDetailsModal
                    BBD={currentPackage?.bestBefore || 0}
                    EAN={currentPackage?.ean || ''}
                    MFD={currentPackage?.manufacturedDate || 0}
                    MRP={currentPackage?.mrp || 0}
                    colorSize={'- ' + currentPackage?.colour + ' & ' + currentPackage?.size}
                    image={currentPackage?.imageUrls?.at(0)}
                    jioCode={currentPackage?.jioCode || ''}
                    title={currentPackage?.title || ''}
                    onSuccess={() => {
                        setProductModal(false);
                    }}
                />
            )}
        </>
    );
}
