import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';

export interface GetConsolidationStatusSchema {
    order_id: string;
    jio_code: string;
    tote_code: string;
    shipment_id?: string;
}

export function useGetConsolidationStatusList() {
    return useMutation(async (data: GetConsolidationStatusSchema[]) => {
        const body = data.map((info) => ({
            order_id: info.order_id,
            jio_code: info.jio_code,
            tote_id: info.tote_code,
            shipment_id: info.shipment_id,
        }));
        const response = await axios.post(urls.GET_CONSOLIDATION_STATUS(), body);
        return response.data;
    });
}
