import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { urls } from '../../../utils/urls';
import { Notifications } from '@jds/core';
export interface PackingLabelsSchema {
    shipmentId: string;
    packagingId: number;
    labelType: 'SHIPPING' | 'INVOICE';
    awbRefNumber: string;
}
export function usePrintPackingLabels() {
    return useMutation<unknown, AxiosError<ErrorResponse>, PackingLabelsSchema>({
        mutationFn: async (data: PackingLabelsSchema) => {
            const body = {
                shipmentId: data.shipmentId,
                packageId: data.packagingId,
                awbRefNumber: data.awbRefNumber,
            };
            const response = await axios({
                url:
                    data.labelType === 'SHIPPING'
                        ? urls.GENERATE_SHIPPING_LABEL()
                        : urls.GENERATE_INVOICE_LABEL(),
                responseType: 'blob',
                method: 'POST',
                data: body,
            });
            //downloadFile(response.data, `invoice-label.pdf`); // todo: print instead of download
            return null;
        },
        onError: (error) => {
            Notifications.toast.error({
                title: 'Error',
                description: error.response?.data.message ?? 'Something went wrong',
            });
        },
    });
}
interface ErrorResponse {
    timestamp: number;
    status: number;
    error: string;
    message: string;
    path: string;
}
