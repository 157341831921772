import { cn } from '../../../utils/tailwind';
import { useContext, useRef, useState } from 'react';
import { Button, Container, Icon, Notifications, Spinner, Text } from '@jds/core';
import { ScanOrEnterManuallyV2 } from '../../shared/ScanOrEnterManuallyV2';
import { IcAdd, IcInfo } from '@jds/core-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useValidateTote, ValidateToteOptions } from '../../../hooks/receiving/useValidateTote';
import { ToteDropOptions, useDropTote } from '../../../hooks/receiving/useDropTote';
import { pageHeaderStyle } from '../../../styles/common';
import { PageHeaderV2 } from '../../shared/PageHeaderV2';
import { PackingContext } from '../../../provider/outbound/packing';
import { usePackingFailedProducts } from '../../../hooks/outbound/packing/usePackingProducts';
import { ProductCard } from '../../shared/ProductCard';

const layoutStyles = 'max-w-screen-md md:mx-auto';

interface ScanToteProps {
    pageHeader: string;
    toteType: string;
    showBackIon: boolean;
    lotId?: number;
    locationId?: string;
    onSuccess: (value: string) => void;
}

const manualInputProps = { name: 'tote-code', label: 'Tote Code', type: 'text' };
export function ScanTotePage({
    pageHeader,
    toteType,
    showBackIon,
    lotId,
    locationId,
    onSuccess,
}: ScanToteProps) {
    const toteRef = useRef({
        currentToteId: '',
    });
    const navigate = useNavigate();
    const { stationCode, jioCode, orderId, shipmentId } = useParams();
    const [validateTote, dropTote] = [useValidateTote(), useDropTote()];
    const platform = location.href.includes('/consolidation') ? 'CONSOLIDATION' : 'PACKING';
    const { state, dispatch } = useContext(PackingContext);
    const { data: products, isLoading } = usePackingFailedProducts(toteType);

    const getProductType = () => {
        if (toteType === 'damaged-expired-items') return 'DAMAGED';
        else if (toteType === 'lowfreshness-items') return 'LOW_FRESHNESS';
        else if (toteType === 'wrongproduct-items') return 'GOOD';
        else if (toteType === 'excess-items') return 'GOOD';
        else if (toteType === 'cancelled-items') return 'GOOD';
        else if (toteType === 'unknown-items') return 'GOOD';
        else return 'EXPIRED';
    };

    function onScanSuccess(tote_code: string) {
        const data: ValidateToteOptions = {
            quantity: 1,
            tote_code,
            product_type: getProductType(),
            jio_code: jioCode ?? '',
            tote_purpose:
                toteType === 'unknown-items' ||
                toteType === 'excess-items' ||
                toteType === 'cancelled-items'
                    ? `${platform}_CANCELLED_ORDERS`
                    : `${platform}_OUTBOUND_QC`,
            lot_id: lotId ?? 0,
            location_id: locationId ?? '',
            tote_params: {
                orderId: orderId || '',
                shipmentId: shipmentId || '',
            },
        };
        validateTote.mutate(data, {
            onSuccess: () => (toteRef.current.currentToteId = tote_code),
            onError: () => (toteRef.current.currentToteId = ''),
        });
    }

    const dropToteOptions: ToteDropOptions = {
        jio_code: jioCode ?? '',
        product_type: getProductType(),
        tote_purpose:
            toteType === 'unknown-items' ||
            toteType === 'excess-items' ||
            toteType === 'cancelled-items'
                ? `${platform}_CANCELLED_ORDERS`
                : `${platform}_OUTBOUND_QC`,
        totes: [{ tote_code: toteRef.current.currentToteId ?? '', quantity: 1 }],
        lot_id: lotId ?? 0,
        location_id: locationId ?? '',
        tote_params: {
            orderId: orderId || '',
            shipmentId: shipmentId || '',
        },
    };

    const scannedItemsList = state.PackingFailedJioCodesTillNow[toteType];
    const toteId = scannedItemsList?.toteCode ?? '';
    let itemsList = scannedItemsList?.jioCodesList ?? [];
    const [isNewTote, setIsNewTote] = useState(itemsList.length === 0);
    const [dropOldTote, setDropOldTote] = useState(false);

    const updateItemsList = () => {
        const currentFailedJodesTotes = state.PackingFailedJioCodesTillNow;
        if (dropOldTote) {
            itemsList = [];
        }

        const val = itemsList?.findIndex((data) => data.jioCode === jioCode);
        if (val === -1) {
            itemsList.push({ jioCode: jioCode || '', lotId: lotId || 0 });
        }
        const itemObj = {
            toteCode: toteRef.current.currentToteId,
            jioCodesList: itemsList,
        };

        currentFailedJodesTotes[toteType] = itemObj;
        dispatch({
            type: 'SET_PACKING_FAILED_JIO_CODES',
            payload: currentFailedJodesTotes,
        });
    };

    const getManualProps = () => {
        if (isNewTote) {
            manualInputProps.label = 'Empty Tote Code';
        } else {
            manualInputProps.label = 'Tote Code';
        }
        return manualInputProps;
    };

    const getItemHeading = () => {
        if (toteType === 'damaged-expired-items') return 'Damaged';
        else if (toteType === 'lowfreshness-items') return 'Low Freshness';
        else if (toteType === 'wrongproduct-items') return 'Wrong Product';
        else if (toteType === 'excess-items') return 'Excess';
        else if (toteType === 'cancelled-items') return 'Cancelled';
        else if (toteType === 'unknown-items') return 'Unknown';
    };

    const getToteType = () => {
        if (toteType === 'damaged-expired-items') return 'DAMAGED';
        else if (toteType === 'lowfreshness-items') return 'LOW_FRESHNESS';
        else if (toteType === 'wrongproduct-items') return 'WRONG';
        else if (toteType === 'excess-items') return 'EXCESS';
        else if (toteType === 'cancelled-items') return 'EXCESS';
        else if (toteType === 'unknown-items') return 'EXCESS';
    };

    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    showBackIcon={showBackIon}
                    title={pageHeader}
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                {isNewTote && (
                    <div className={cn('mx-2 mt-6 pb-3')}>
                        <ScanOrEnterManuallyV2
                            autoSuggest={true}
                            enableScannerOnMount={true}
                            inputProps={manualInputProps}
                            optionalValues={{
                                station_id: stationCode ?? '',
                            }}
                            purpose={getToteType()}
                            type="TOTE_CODE"
                            onSuccess={(value) => {
                                onScanSuccess(value);
                            }}
                        />
                    </div>
                )}
                {!isNewTote && (
                    <div className={cn('mx-3 space-y-2 py-3 pl-8 pb-6')}>
                        <Text appearance="body-xxs" color="primary-grey-100">
                            Tote ID
                        </Text>
                        <Text appearance="body-m-bold" color="primary-60">
                            {toteId}
                        </Text>
                    </div>
                )}
            </div>
            <main className={cn('mx-2 mt-2')}>
                {!isNewTote && (
                    <>
                        <Text
                            appearance="body-xs-bold"
                            className={cn('mx-2 my-4')}
                            color="primary-grey-100"
                        >
                            Input Tote ID
                        </Text>
                        <Container
                            className={cn('bg-cronical-gradient px-2 pt-6 pb-3')}
                            rounded="medium"
                        >
                            <ScanOrEnterManuallyV2
                                autoSuggest={true}
                                enableScannerOnMount={true}
                                inputProps={getManualProps()}
                                searchableList={[{ result: toteId }]}
                                onSuccess={(value) => {
                                    onScanSuccess(value);
                                }}
                            />
                        </Container>
                    </>
                )}
                {toteType === 'damaged-expired-items' && (
                    <div className="mt-3 flex space-x-2 pr-6">
                        <Icon className={cn('pt-1')} color="grey-100" ic={<IcInfo />} size="m" />
                        <Text appearance="body-xs" color="primary-grey-80">
                            Damaged and Expired items go in the same tote
                        </Text>
                    </div>
                )}
                {!isNewTote && (
                    <div className={cn('mx-1 mb-8 mt-6')}>
                        <Text appearance="body-s" className={cn('mx-2')} color="primary-grey-80">
                            {`${getItemHeading()} Items List`}
                        </Text>
                        <div className={cn('mt-3 mb-44')}>
                            {products?.length === 0 && (
                                <Text className={cn('text-center')}>No products found!</Text>
                            )}
                            {isLoading && (
                                <Spinner className={cn('flex justify-center')} size="small" />
                            )}
                            {products?.map((product) => (
                                <ProductCard
                                    key={product.jio_code}
                                    BBD={product.expiry}
                                    EAN={product.ean ?? product.ean}
                                    MFD={product.mfg}
                                    MRP={product.mrp}
                                    colorSize={`${product?.color ?? '-'}, ${product?.size ?? '-'}`}
                                    image={product?.image_urls?.[0]}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </main>
            <div
                className={cn(
                    'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-6',
                    layoutStyles
                )}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                {!isNewTote && (
                    <Button
                        fullWidth
                        icon={<IcAdd color="#000093" />}
                        kind="secondary"
                        size="large"
                        onClick={() => {
                            setDropOldTote(true);
                            setIsNewTote(true);
                        }}
                    >
                        Add New Tote
                    </Button>
                )}
                <Button
                    fullWidth
                    kind="primary"
                    size="large"
                    onClick={() => {
                        if (toteRef.current.currentToteId !== toteId && !isNewTote) {
                            Notifications.toast.error({
                                title: 'Error',
                                description: 'Tote ID does not match',
                            });
                        } else {
                            updateItemsList();
                            dropTote.mutate(dropToteOptions, {
                                onSuccess: () => onSuccess(toteRef.current.currentToteId),
                            });
                        }
                    }}
                >
                    Drop Complete
                </Button>
            </div>
        </div>
    );
}
