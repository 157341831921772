import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, SearchBox, Spinner, Text } from '@jds/core';
import { useState } from 'react';
import { ProductListItem } from '../../../components/returns/receiving/ProductListItem';
import { useReturnProducts } from '../../../hooks/returns/receiving/useReturnProducts';
import { usePrintJioCodes } from '../../../hooks/returns/receiving/usePrintJioCodes';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function RePrintJioCodePage() {
    const [activeJioCode, setActiveJioCode] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const printJioCode = usePrintJioCodes({ showSuccessMessage: false });
    const { data: returnProducts, isLoading } = useReturnProducts();
    const filteredProducts = returnProducts?.filter(({ ean }) => ean.includes(searchQuery)) ?? [];
    const [navigate] = [useNavigate(), useParams()];

    return (
        <div className={cn('flex min-h-screen max-w-screen-md flex-col pb-3 md:mx-auto')}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Product list"
                    onBackIconClick={() => {
                        navigate(-1);
                    }}
                />
                <div className={cn('mx-3 mt-3 pb-6')}>
                    <SearchBox
                        label="Search Product List"
                        name="search-box"
                        onChange={(event) => {
                            setSearchQuery((event.target as HTMLInputElement).value);
                        }}
                    />
                </div>
            </div>
            <main className={cn('mx-3 mb-20')}>
                <div className={cn('mt-4 space-y-4')}>
                    {isLoading && (
                        <div className={cn('flex justify-center')}>
                            <Spinner size="small" />
                        </div>
                    )}
                    {filteredProducts.length === 0 && !isLoading && (
                        <Text className={cn('text-center')}>No products found!</Text>
                    )}
                    {filteredProducts.map((product) => (
                        <ProductListItem
                            key={product.jio_code}
                            activeJioCode={activeJioCode}
                            bestBefore={product.expiry}
                            color={product.color}
                            ean={product.ean}
                            image={product?.image_urls?.[0]}
                            jioCode={product.jio_code}
                            manufacturedDate={product.mfg}
                            mrp={product.mrp}
                            setActiveJioCode={setActiveJioCode}
                            size={product.size}
                        />
                    ))}
                </div>
            </main>
            <div
                className={cn('fixed inset-x-0 bottom-0 bg-white py-2.5 px-6', layoutStyles)}
                style={{ boxShadow: '0px -4px 8px rgba(189, 189, 189, 0.25)' }}
            >
                <Button
                    fullWidth
                    disabled={activeJioCode === null}
                    isLoading={printJioCode.isLoading}
                    size="large"
                    type="button"
                    onClick={() => navigate(`./../${activeJioCode}/details`)}
                >
                    Confirm
                </Button>
            </div>
        </div>
    );
}
