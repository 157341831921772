import axios, { InternalAxiosRequestConfig } from 'axios';
import { urls } from './urls';

export function applyDefaultHeaders() {
    axios.interceptors.request.use((config) => {
        // do not attach headers to login request
        if (config.url === urls.AUTHENTICATE_USER()) return config;
        const headers = {
            rfcId: sessionStorage.getItem('rfc-id'),
            rfc_code: sessionStorage.getItem('rfc-code'),
            rfcCode: sessionStorage.getItem('rfc-code'),
            instanceCode: sessionStorage.getItem('rfc-code'),
            token: sessionStorage.getItem('user-auth-token'),
            user_name: sessionStorage.getItem('username'),
        };
        const newConfig = { ...config, headers: { ...config.headers, ...headers } };
        return newConfig as unknown as InternalAxiosRequestConfig;
    });
}

export function createAxiosError(message: string) {
    return { response: { data: { meta: { errors: [message] } } } };
}

export function downloadFile(content: Blob, filename: string) {
    // create file link in browser's memory
    const href = URL.createObjectURL(content);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}
