import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../../utils/urls';
import { Notifications } from '@jds/core';
import { useParams } from 'react-router-dom';

export function useUpdateJiocodeQuantity() {
    const { appointmentNumber, shipmentId } = useParams();
    return useMutation<unknown, any, any>({
        mutationFn: async (body) => {
            const response = await axios.post<any>(
                urls.RETURNS_UNLOADING_JIOCODE(appointmentNumber ?? '', shipmentId ?? ''),
                body
            );
            const { data } = response.data;
            return data;
        },
        onError: (error) => {
            const defaultMsg = 'Something went wrong';
            const description = error?.response?.data?.meta?.errors ?? defaultMsg;
            Notifications.toast.error({ title: 'Error', description });
        },
    });
}
