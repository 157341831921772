import { Dispatch, SetStateAction } from 'react';
import { cn } from '../../../utils/tailwind';
import { Container, Input, Text } from '@jds/core';
import { format } from 'date-fns';

interface ProductListItemProps {
    setActiveJioCode: Dispatch<SetStateAction<string | null>>;
    activeJioCode: string | null;
    jioCode: string;
    ean: string;
    mrp: number;
    manufacturedDate: number;
    bestBefore: number;
    image?: string;
    color?: string;
    size?: string;
}
export function ProductListItem({
    setActiveJioCode,
    activeJioCode,
    ean,
    mrp,
    manufacturedDate,
    bestBefore,
    jioCode,
    image,
    color,
    size,
}: ProductListItemProps) {
    return (
        <label className={cn('block w-full cursor-pointer')}>
            <Container
                background="primary-grey-20"
                className={cn('flex space-x-2.5', {
                    'outline outline-[var(--color-primary-50)]': jioCode === activeJioCode,
                })}
                pad="s"
                rounded="large"
            >
                <div>
                    <Input
                        checked={jioCode === activeJioCode}
                        label=""
                        name="jio-code"
                        type="radio"
                        onChange={(event) => {
                            const isChecked = (event.target as HTMLInputElement).checked;
                            if (isChecked) setActiveJioCode(jioCode);
                        }}
                    />
                </div>
                <img
                    alt="Product Image"
                    className={cn('h-[102px] w-[88px] rounded-2xl object-cover')}
                    src={image ?? 'https://via.placeholder.com/88'}
                />
                <div className="flex flex-col items-start space-y-0.5">
                    <Text appearance="body-xxs" color="primary-grey-80">
                        EAN {ean}
                    </Text>
                    <Text appearance="body-xxs" color="primary-grey-80">
                        Jio Code {jioCode}
                    </Text>
                    <Text appearance="body-xxs" color="primary-grey-80">
                        MRP {mrp ?? '-'}
                    </Text>
                    <Text appearance="body-xxs" color="primary-grey-80">
                        MFD {manufacturedDate ? format(manufacturedDate, 'dd-MM-yyyy') : '-'}
                    </Text>
                    <Text appearance="body-xxs" color="primary-grey-80">
                        BBD {bestBefore ? format(bestBefore, 'dd-MM-yyyy') : '-'}
                    </Text>
                    <Text appearance="body-xxs" color="primary-grey-80">
                        Color {color ?? '-'}
                    </Text>
                    <Text appearance="body-xxs" color="primary-grey-80">
                        Size {size ?? '-'}
                    </Text>
                </div>
            </Container>
        </label>
    );
}
