import { Container, Icon, Notifications, Text } from '@jds/core';
import { IcSuccess } from '@jds/core-icons';
import { cn } from '../../../utils/tailwind';
import { useNavigate, useParams } from 'react-router-dom';

import { ScanOrEnterManuallyV2 } from '../../../components/shared/ScanOrEnterManuallyV2';
import {
    PicklistLocationItemProps,
    useGetPicklistLocation,
} from '../../../hooks/outbound/picklist/useGetPicklistLocation';
import { useUpdatePickListStatus } from '../../../hooks/outbound/picklist/useUpdatePickListStatus';
import { PicklistDropItem } from '../../../components/picklist/PicklistDropItem';
import { groupListByKey } from '../../../utils/helper';
import { useInventoryDetails } from '../../../hooks/putaway/useInventoryDetails';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { pageHeaderStyle } from '../../../styles/common';
import { useContext } from 'react';
import { PicklistContext } from '../../../provider/outbound/picklist';
import { usePicklistStateSave } from '../../../hooks/outbound/picklist/usePicklistStateSave';

const manualInputProps = { name: 'drop-location-code', label: 'Drop Location Code', type: 'text' };

const layoutStyles = 'max-w-screen-md md:mx-auto';

const ScanDropZone = () => {
    const { pickingLocationsList } = useGetPicklistLocation();
    const { state } = useContext(PicklistContext);
    const updatePickListStatus = useUpdatePickListStatus();
    const { pickingId } = useParams();
    const navigate = useNavigate();
    const savePicklistactivity = usePicklistStateSave();

    const groupedPickListLocationsData = groupListByKey(pickingLocationsList, 'pick_zone_id');

    const pickedJioCodesList = state.pickedJioCodesObjList;

    const validateInventory = useInventoryDetails();
    const handleValidateInventoryDetails = (dropZoneId: string) => {
        validateInventory.mutate(
            { inventoryCode: dropZoneId, type: 'PICKING_DROP_ZONE' },
            {
                onSuccess(res: any) {
                    if (res?.meta?.status.toUpperCase() === 'FAILURE') {
                        Notifications.toast.error({
                            title: 'Error',
                            description:
                                res.meta.errors?.length > 0
                                    ? res.meta.errors.join(', ')
                                    : 'Invalid Picking Drop Zone',
                        });
                    } else {
                        onUpdateJioCodeStatus(dropZoneId);
                    }
                },
            }
        );
    };

    function onUpdateJioCodeStatus(dropZoneId: string) {
        const data = {
            pickListId: Number(pickingId),
            status: 'COMPLETED',
            dropZoneId: dropZoneId,
        };

        updatePickListStatus.mutate(data, {
            onSuccess: () => handleDropZoneScanState(dropZoneId),
        });
    }

    const getPickedQuantity = (currentJioCodedata: any) => {
        const output = pickedJioCodesList.find(
            (item: any) =>
                item.id === currentJioCodedata.order_id &&
                item.jio_code === currentJioCodedata.jio_code
        );
        return output?.quantity ?? 0;
    };

    function handleDropZoneScanState(dropZoneId: string) {
        savePicklistactivity.mutate(
            {
                username: sessionStorage.getItem('username') ?? '',
                rfc_code: sessionStorage.getItem('rfc-code') ?? '',
                activity_name: 'PICKING_DROP_ZONE_SCAN',
                status: 'COMPLETED',
            },
            {
                onSuccess: () => {
                    navigate(`../${pickingId}/${dropZoneId}/success`);
                },
            }
        );
    }
    return (
        <div className={cn(layoutStyles)}>
            <div className={cn(pageHeaderStyle)}>
                <PageHeaderV2
                    isExtended
                    title="Picking Drop Location"
                    onBackIconClick={() => {
                        Notifications.toast.error('Unable to navigate back');
                    }}
                />
                <div className={cn('mx-5 mt-4 flex items-start pb-3')}>
                    <div className={cn('mt-1')}>
                        <Icon color="success" ic={<IcSuccess />} size="xxl" />
                    </div>

                    <Text appearance="heading-xs" className={cn('mx-4')}>
                        Picked all items Go to nearest drop area or conveyor belt
                    </Text>
                </div>
            </div>
            <div className={cn('mx-6 mb-60 mt-6')}>
                <span
                    className={cn(
                        'fixed inset-x-0 bottom-0  space-y-2.5 bg-white py-2.5 px-3',
                        layoutStyles
                    )}
                >
                    <Text appearance="body-xs-bold" className={cn('m-1')}>
                        Input Location ID
                    </Text>
                    <Container
                        className={cn('bg-cronical-gradient px-2 pt-6 pb-3')}
                        rounded="medium"
                    >
                        <ScanOrEnterManuallyV2
                            autoSuggest={true}
                            inputProps={manualInputProps}
                            searchableList={[
                                { result: pickingLocationsList[0]?.location_id || '' },
                            ]}
                            type="PICKING_DROP_ZONE"
                            onSuccess={(value) => {
                                handleValidateInventoryDetails(value);
                            }}
                        />
                    </Container>
                </span>
                <Text appearance="body-m" className={cn('mx-1 my-4')} color="primary-grey-60">
                    Total - {pickingLocationsList && pickingLocationsList.length} items
                </Text>

                <div className={cn('mb-40 flex w-full')}>
                    {Object.keys(groupedPickListLocationsData).map((key, index) => {
                        return (
                            <div key={key} className={cn('flex w-full')}>
                                <span
                                    className={cn('w-1.5')}
                                    style={{
                                        backgroundColor:
                                            groupedPickListLocationsData[key].length > 0 &&
                                            groupedPickListLocationsData[key][0].color !== 'string'
                                                ? groupedPickListLocationsData[key][0].color
                                                : 'green',
                                    }}
                                />
                                <div className={cn('flex w-full flex-col')}>
                                    <Text
                                        appearance="body-s"
                                        className={cn(['ml-2', index !== 0 ? 'mt-8' : ''])}
                                        style={{
                                            color:
                                                groupedPickListLocationsData[key].length > 0 &&
                                                groupedPickListLocationsData[key][0].color !==
                                                    'string'
                                                    ? groupedPickListLocationsData[key][0].color
                                                    : 'green',
                                        }}
                                    >
                                        {`Zone - ${key}`}
                                    </Text>

                                    {groupedPickListLocationsData[key].map(
                                        (currentJioCodeData: PicklistLocationItemProps) => {
                                            return (
                                                <div
                                                    key={currentJioCodeData.jio_code}
                                                    className={cn('w-full')}
                                                >
                                                    <div className={cn('flex items-center')}>
                                                        <Text
                                                            appearance="body-xs"
                                                            className={cn('my-2 ml-2')}
                                                            color="primary-grey-60"
                                                        >
                                                            {currentJioCodeData.location_id}
                                                        </Text>
                                                        <Icon
                                                            className={cn('ml-2')}
                                                            color="success"
                                                            ic={<IcSuccess />}
                                                            size="s"
                                                        />
                                                    </div>
                                                    <div className={cn('ml-2 flex')}>
                                                        <div className={cn('w-10/12')}>
                                                            <PicklistDropItem
                                                                key={currentJioCodeData?.jio_code}
                                                                EAN={currentJioCodeData?.ean ?? '-'}
                                                                dimensions={
                                                                    currentJioCodeData?.dimensions ??
                                                                    '-'
                                                                }
                                                                imageUrl={
                                                                    currentJioCodeData?.image_url
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className={cn(
                                                                'flex w-2/12 items-center justify-center'
                                                            )}
                                                        >
                                                            <Text
                                                                appearance="body-s"
                                                                className={cn('mt-3 mb-5')}
                                                                color="primary-grey-80"
                                                            >
                                                                X{' '}
                                                                {/* {getPickedQuantity(
                                                                    currentJioCodeData
                                                                )} */}
                                                                {currentJioCodeData?.quantity}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ScanDropZone;
