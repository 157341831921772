import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { urls } from '../../../utils/urls';
import { ErrorResponse, handleError, SuccessResponse } from './useGetPickList';

export interface OrderItemsSchema {
    id: string;
    quantity: number;
    jio_code: string;
    total_quantity?: number;
    shipment_id: string;
    lot_id: number;
    quantity_not_picked: number;
}

export interface UpdateQuantityByOrderIdSchema {
    picklist_id: number;
    orders: OrderItemsSchema[];
    locationId: string;
    status?: 'IN_PROGRESS' | 'COMPLETED';
}

const defaultErrorMsg = 'Something went wrong.';
export function useUpdateQuantityByOrderId() {
    return useMutation<SuccessResponse, AxiosError<ErrorResponse>, UpdateQuantityByOrderIdSchema>(
        async (body: UpdateQuantityByOrderIdSchema) => {
            const response = await axios.post(
                urls.UPDATE_PICKLIST_ITEM_QUANTITY_BY_ORDER_ID(),
                body,
                {
                    headers: {
                        instanceCode: sessionStorage.getItem('rfc-code'),
                    },
                }
            );
            return response.data;
        },
        { onError: (error) => handleError(error, defaultErrorMsg) }
    );
}
