import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { urls } from '../../utils/urls';
import { ResponseType } from '../../types/fetch';

export function useUnloadingVerification() {
    const rfcId = sessionStorage.getItem('rfcId');
    if (!rfcId) throw new Error('rfcId is not defined');
    return useMutation<ResponseType<UnloadingItem>, unknown, number>({
        mutationFn: async (appointmentNumber: number) => {
            const response = await axios.get(urls.UNLOADING_VERIFICATION(appointmentNumber));
            return response.data;
        },
    });
}

export interface UnloadingItem {
    appointment_id: number;
    excess_quantity: number;
    inbound_flow_status: string;
    invoice_number: string;
    is_boxed: false;
    number_of_cases: 1;
    seller_name: string;
    short_quantity: 0;
    station_id: string | null;
    str_number: string;
    unloading_flow_status: string | null;
    unloading_verification_details: UnloadingVerificationDetails[];
}

interface UnloadingVerificationDetails {
    box_id: null;
    box_label: null;
    is_damage_re_marked: false;
    package_id: number;
    package_label: string;
    started_at: number;
    station_id: string;
    status: string;
    unloaded_at: number;
    unloaded_by: string;
    receiving_qc_status: string;
}
