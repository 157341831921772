import { cn } from '../../../utils/tailwind';
import { Button } from '@jds/core';
import { useNavigate } from 'react-router-dom';
import { useProofOfReceipt } from '../../../hooks/returns/unloading/useProofOfReceipt';
import { useContext, useRef } from 'react';
import { UnloadingContext } from '../../../provider/returns/unloading';
import { PageHeaderV2 } from '../../../components/shared/PageHeaderV2';
import { AirwayBillCard } from '../../../components/returns/Unloading/AirwayBillCard';

const layoutStyles = 'max-w-screen-md md:mx-auto';
export function GeneratePOR() {
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);
    const proofOfReceipt = useProofOfReceipt();
    const { dispatch, state } = useContext(UnloadingContext);
    const airwayBills = state.completedAirwayBills;

    function onSuccessCallback() {
        const remark = inputRef.current?.value ?? '';
        proofOfReceipt.mutate(remark, {
            onSuccess: () => {
                dispatch({ type: 'RESET_COMPLETED_AIRWAY_BILLS' });
                navigate('./../success');
            },
        });
    }
    return (
        <>
            <PageHeaderV2 title="Generate POR" onBackIconClick={() => navigate(-1)} />
            <main className={cn('mx-6 mt-4 mb-20')}>
                <div className={cn('mt-2 grid items-center gap-y-6')}>
                    {airwayBills.map((item: any) => (
                        <AirwayBillCard key={item.airwayBill} item={item} />
                    ))}
                </div>
            </main>
            <div className={cn('fixed inset-x-0 bottom-0  bg-white py-2.5 px-6', layoutStyles)}>
                <Button
                    fullWidth
                    isLoading={proofOfReceipt.isLoading}
                    size="large"
                    type="button"
                    onClick={onSuccessCallback}
                >
                    Generate POR
                </Button>
            </div>
        </>
    );
}
